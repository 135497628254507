import React from 'react';
import classNames from 'classnames';
import ReactSelect, { components } from 'react-select';

import styles from './styles.module.scss';

type OptionType = {
  value: string;
  label: string;
};

type Props = {
  assignee: string;
  onSetAssignee: (newAssignee: OptionType) => void;
  selectOptions: any;
};

const ChatAssigneeSelect: React.FC<Props> = ({ assignee, onSetAssignee, selectOptions }) => {
  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  return (
    <div className={styles.customAssigneeSelect}>
      <ReactSelect
        options={selectOptions}
        isSearchable
        closeMenuOnSelect
        onFocus={setBodyClassForMobileSafari}
        onBlur={removeBodyClassForMobileSafari}
        placeholder={assignee}
        name="assignees"
        className="w-full"
        components={{ Option, ClearIndicator }}
        value={assignee}
        hideSelectedOptions={false}
        controlShouldRenderValue={false}
        classNames={{
          control: () => styles.control,
          menu: () => styles.menu,
          option: () => styles.option,
          clearIndicator: () => styles.clearIndicator,
          input: () => styles.input,
          placeholder: () => styles.placeholder,
        }}
        onChange={value => {
          onSetAssignee(value as any);
        }}
      />
    </div>
  );
};

export default ChatAssigneeSelect;

const Option = (props: any) => {
  const { data, isSelected } = props;

  return (
    <div>
      <components.Option {...props} className={classNames(styles.costomOption, { [styles.isSelected]: isSelected })}>
        <p>{data.label}</p>
      </components.Option>
    </div>
  );
};

const ClearIndicator = (props: any) => {
  return <components.ClearIndicator {...props}>Leeren</components.ClearIndicator>;
};
