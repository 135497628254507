import React from 'react';
import classNames from 'classnames';
import styles from './style.module.scss';

type Props = {
  isOpen?: boolean;
  closeDrawer?: (value: boolean) => void;
  children: React.ReactNode;
};

const Drawer: React.FC<Props> = ({ isOpen = false, closeDrawer = () => {}, children }) => {
  const close = () => {
    closeDrawer(false);
  };

  return (
    <>
      <aside
        className={classNames(styles.drawerContainer, { 'translate-x-0': isOpen }, { 'translate-x-full': !isOpen })}
      >
        <div className="absolute w-full bg-gradient-to-r from-accentColorDark to-accentColor bottom-0 z-10 py-10">
          <div
            onClick={() => close()}
            onKeyDown={() => close()}
            aria-hidden="true"
            className="text-center font-bold text-20 py-5 cursor-pointer text-buttonTextColor"
          >
            Filter anwenden
          </div>
        </div>
        <div className="h-full overflow-auto custom-scrollbar pl-20 p-45">
          <div className="pl-10 md:pl-20">{children}</div>
        </div>
      </aside>
      {isOpen && <div className={styles.backDrop} aria-hidden="true" onClick={close} />}
    </>
  );
};

export default Drawer;
