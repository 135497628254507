import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { AuthContext } from 'providers/AuthProvider';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { savePurchaseListToFirebase } from 'shared/functions/global';
import moment from 'moment';
import { MainContext } from 'providers/MainProvider';
import styles from './styles.module.scss';

type Props = {
  thisId?: number;
  recipeItem?: any;
};

const RecipeListSubItem: React.FC<Props> = ({ thisId, recipeItem }) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { userData, tenant } = useContext(AuthContext);
  const [isActive, setIsActive] = useState(false);
  const { purchaseList } = useContext(MainContext);

  const handleActiveStatus = () => {
    if (isActive) {
      if (purchaseList) {
        const newColumns = {
          ...purchaseList[0],
          date: moment().utc().unix(),
          listType: 'recipe',
          recipes:
            purchaseList.length > 0
              ? [...purchaseList[0].recipes.filter((item: any) => item.id !== recipeItem.id)]
              : [],
          ingredients:
            purchaseList.length > 0
              ? [...purchaseList[0].ingredients.filter((item: any) => item.recipeId !== recipeItem.id)]
              : [],
        };

        savePurchaseListToFirebase(userData, newColumns, authContext.user?.uid, tenant);

        setIsActive(false);
      }
    } else if (purchaseList) {
      const thisRecipeObject = {
        name: recipeItem.name,
        id: recipeItem.id,
      };

      const thisNewIngredientObject = recipeItem.ingredients.map((item: any) => {
        return {
          ...item,
          deleted: false,
          recipeId: recipeItem.id,
          amount:
            item.piece !== 'g' && item.piece !== 'ml'
              ? parseFloat(item.amount) * parseFloat(item.preselect_g)
              : parseFloat(item.amount),
        };
      });

      const thisIngredientObject = [...purchaseList[0].ingredients, ...thisNewIngredientObject];

      const newColumns = {
        ...purchaseList[0],
        date: moment().utc().unix(),
        listType: 'recipe',
        recipes: purchaseList.length > 0 ? [thisRecipeObject, ...purchaseList[0].recipes] : [thisRecipeObject],
        ingredients: purchaseList.length > 0 ? thisIngredientObject : [],
      };

      savePurchaseListToFirebase(userData, newColumns, authContext.user?.uid, tenant);
      setIsActive(true);
    }
  };

  useEffect(() => {
    let isRecipeActive = false;

    console.log('1');
    console.log(purchaseList);
    if (recipeItem !== undefined) {
      console.log(recipeItem.id);
      console.log(recipeItem.name);
    }
    console.log('2');

    if (purchaseList !== undefined && purchaseList?.length > 0) {
      purchaseList[0].recipes.map(item => {
        if (recipeItem !== undefined) {
          if (item.id === recipeItem.id) {
            setIsActive(true);
            isRecipeActive = true;
          }
        }

        return 0;
      });

      if (isRecipeActive === false) {
        setIsActive(false);
      }
    }
  }, [purchaseList]);

  return (
    <>
      {recipeItem !== undefined && (
        <>
          <div className="relative">
            {isActive && (
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <CheckCircleIcon width={35} height={35} className="text-accentColor" />
              </div>
            )}

            <div className="flex border-transparent">
              <div
                className={classNames(styles.contentItem, isActive && styles.active)}
                onClick={() => handleActiveStatus()}
                onKeyDown={() => handleActiveStatus()}
                aria-hidden="true"
              >
                <div className={styles.description}>
                  <div className="py-15 pl-5 pr-5">
                    <div>
                      <div className={styles.popupNutritionItem}>{recipeItem.name}</div>
                      <div className="font-extralight text-10 pb-5">
                        {recipeItem.amount} {recipeItem.piece} ({recipeItem.portion_g}g)
                      </div>
                      <div className={styles.category}>
                        <div className={styles.item}>
                          <div className="text-12">{recipeItem.kcal_total}</div>
                          <div>KCAL</div>
                        </div>
                        <div className={styles.item}>
                          <div className="text-12">{recipeItem.carbohydrates_total}</div>
                          <div>KH</div>
                        </div>
                        <div className={styles.item}>
                          <div className="text-12">{recipeItem.protein_total}</div>
                          <div>EW</div>
                        </div>
                        <div className={styles.item}>
                          <div className="text-12">{recipeItem.fat_total}</div>
                          <div>Fett</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.imageWrapper}>
                  <img
                    src={recipeItem.imageUrl}
                    alt=""
                    width={45}
                    height={45}
                    loading="lazy"
                    className={styles.image}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RecipeListSubItem;
