import { InformationCircleIcon, XMarkIcon as XIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface InfoPopUpProps {
  infoText: string;
}

const InfoPopUp: React.FC<InfoPopUpProps> = ({ infoText }) => {
  const [showPopUp, setshowPopUp] = useState(false);

  return (
    <div data-pdf-infoicon>
      <span className="relative">
        <button type="button" className="my-auto" onClick={() => setshowPopUp(true)}>
          <InformationCircleIcon width={20} height={15} className="text-textColor" />
        </button>

        <div className={classNames(styles.tooltip, { [styles.show]: showPopUp })}>
          <button type="button" className="mb-15">
            <XIcon
              width={25}
              height={25}
              className="text-accentColor mx-auto cursor-pointer"
              onClick={() => setshowPopUp(false)}
            />
          </button>

          <div>
            <p className="text-13 font-light">{infoText}</p>
          </div>
        </div>
      </span>
    </div>
  );
};

export default InfoPopUp;
