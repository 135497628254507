import React, { useEffect, useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import firebase from 'services/firebase';
import { toast } from 'react-toast';
import Button from 'components/Button';
import CoachingDashboardPlanNutritionItem from '../CoachingDashboardPlanNutritionItem';

type Props = {
  planObject?: any;
  userId?: any;
  planUid: string;
  startIndex: number;
  selectedWeek: number;
  noPlan?: boolean;
  noPlanDate?: string;
};

const CoachingDashboardPlanItemEmpty: React.FC<Props> = ({
  planObject,
  userId,
  planUid,
  startIndex,
  selectedWeek,
  noPlan,
  noPlanDate,
}) => {
  const [impersonateLoading, setImpersonateLoading] = useState(false);

  const [thisPlanObject, setThisPlanObject] = useState<any>(planObject);

  const localPlanObject = {
    breakfast: {
      ingredients: [],
      recipes: [],
      manualItems: [],
      kcal_total: 0,
      carbohydrates_total: 0,
      protein_total: 0,
      fat_total: 0,
      label: 'Frühstück',
    },
    lunch: {
      ingredients: [],
      recipes: [],
      manualItems: [],
      kcal_total: 0,
      carbohydrates_total: 0,
      protein_total: 0,
      fat_total: 0,
      label: 'Mittagessen',
    },
    dinner: {
      ingredients: [],
      recipes: [],
      manualItems: [],
      kcal_total: 0,
      carbohydrates_total: 0,
      protein_total: 0,
      fat_total: 0,
      label: 'Abendessen',
    },
    snacks: {
      ingredients: [],
      recipes: [],
      manualItems: [],
      kcal_total: 0,
      carbohydrates_total: 0,
      protein_total: 0,
      fat_total: 0,
      label: 'Snacks',
    },
    id: 1,
    name: 'Tag 1',
    kcal_total: 0,
    carbohydrates_total: 0,
    protein_total: 0,
    fat_total: 0,
    date: {
      seconds: 1234,
    },
  } as any;

  const editDay = async () => {
    setImpersonateLoading(true);
    const token = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/getUserToken`, {
      userId,
    });

    const impersonateToken = token.data?.token;

    if (impersonateToken) {
      const currentUserId = firebase.auth().currentUser?.uid;
      const currentUserToken = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/getUserToken`, {
        userId: currentUserId,
      });

      if (currentUserToken) {
        localStorage.setItem('currentUserToken', currentUserToken.data?.token);
        if (currentUserId) {
          localStorage.setItem('currentUserTokenID', currentUserId);
        }

        localStorage.setItem('coachingDashBoardArea', 'plan');
        localStorage.setItem('coachingDashBoardPlanStartIndex', startIndex.toString());
        localStorage.setItem('coachingDashBoardPlanSelectedWeek', selectedWeek.toString());

        localStorage.setItem(
          'coachingDashBoardDashboardDate',
          moment(noPlanDate, 'DD.MM.YY').subtract(1, 'days').format('DD.MM.YYYY')
        );

        localStorage.setItem('coachingDashBoardEmptyPlan', 'true');

        firebase
          .auth()
          .signInWithCustomToken(impersonateToken)
          .catch(error => {
            console.log(error);
          });
      }
    } else {
      toast.error('Es ist leider etwas schief gelaufen. Bitte versuchen Sie es später erneut!');
    }
  };

  return (
    <>
      <div className="bg-lightGray rounded-xl px-20 pb-20 h-full">
        <div className="pt-20">
          <div className="flex justify-between flex-wrap">
            <div>
              <div className="font-bold my-auto">
                {moment(noPlanDate, 'DD.MM.YY').subtract(1, 'days').format('dddd')}
              </div>
              <div className="font-extralight text-12">
                {moment(noPlanDate, 'DD.MM.YY').subtract(1, 'days').format('DD.MM.YYYY')}
              </div>
            </div>
            <div className="flex gap-20 flex-wrap my-auto">
              <div className="opacity-50">
                <div className="text-12 text-center">0</div>
                <div className="text-8 font-extralight text-center">kcal</div>
              </div>
              <div className="opacity-50">
                <div className="text-12 text-center">0</div>
                <div className="text-8 font-extralight text-center">KH</div>
              </div>
              <div className="opacity-50">
                <div className="text-12 text-center">0</div>
                <div className="text-8 font-extralight text-center">EW</div>
              </div>
              <div className="opacity-50">
                <div className="text-12 text-center">0</div>
                <div className="text-8 font-extralight text-center">FT</div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end pt-10">
          <Button className="text-12 py-5" onClick={() => editDay()} isPending={impersonateLoading}>
            Tag bearbeiten
          </Button>
        </div>
        <div className="pt-15">
          <CoachingDashboardPlanNutritionItem
            mealLabel="Frühstück"
            mealIdentifier="breakfast"
            dayId={localPlanObject?.id}
            mealObject={localPlanObject?.breakfast}
            userId={userId}
            planUid="12345"
            setPlanObject={setThisPlanObject}
          />
          <CoachingDashboardPlanNutritionItem
            mealLabel="Mittagessen"
            mealIdentifier="lunch"
            dayId={localPlanObject?.id}
            mealObject={localPlanObject?.lunch}
            userId={userId}
            planUid="12345"
            setPlanObject={setThisPlanObject}
          />
          <CoachingDashboardPlanNutritionItem
            mealLabel="Abendessen"
            mealIdentifier="dinner"
            dayId={localPlanObject?.id}
            mealObject={localPlanObject?.dinner}
            userId={userId}
            planUid="12345"
            setPlanObject={setThisPlanObject}
          />
          <CoachingDashboardPlanNutritionItem
            mealLabel="Snacks"
            mealIdentifier="snacks"
            dayId={localPlanObject?.id}
            mealObject={localPlanObject?.snacks}
            userId={userId}
            planUid="12345"
            setPlanObject={setThisPlanObject}
          />
        </div>
      </div>
    </>
  );
};

export default CoachingDashboardPlanItemEmpty;
