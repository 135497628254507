import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { PencilIcon } from '@heroicons/react/24/outline';
import styles from './styles.module.scss';

interface TransparentInputProps {
  className?: string;
  pencilSize?: number;
  value: string;
  onInput?: React.ChangeEventHandler<HTMLInputElement>;
  useIcon?: boolean;
  placeholder?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

const TransparentInput: React.FC<TransparentInputProps> = ({
  className,
  pencilSize = 25,
  value,
  onInput,
  useIcon = true,
  placeholder,
  onKeyDown,
  onBlur,
}) => {
  const [content, setContent] = useState(value);
  const [width, setWidth] = useState(0);
  const span = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setContent(value);
  }, [value]);

  useEffect(() => {
    const textToMeasure = content || placeholder || '';
    setWidth(textToMeasure.length > 0 ? Number(span.current?.offsetWidth) + 20 : 100);
  }, [content, placeholder]);

  const changeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setContent(evt.target.value);
    onInput?.(evt);
  };

  return (
    <label htmlFor={`input-${value}`} className={styles.label}>
      <input
        type="text"
        style={{ width }}
        className={classNames(styles.input, className)}
        onInput={changeHandler}
        onBlur={onBlur}
        value={content}
        id={`input-${value}`}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
      />

      {/* Hidden span to get the width for the input */}
      <span ref={span} className={classNames(styles.input, styles.span, className)}>
        {placeholder || content}
      </span>

      {useIcon && <PencilIcon width={pencilSize} height={pencilSize} className={styles.pencilIcon} />}
    </label>
  );
};

export default TransparentInput;
