import React, { useContext, useState, useEffect, useRef } from 'react';
import { ChevronDownIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ResponsiveContainer, ComposedChart, Line, Area, XAxis, YAxis, Tooltip } from 'recharts';
import { AuthContext } from 'providers/AuthProvider';
import { defaultAccentColor, defaultBgColor, defaultTextColor, defaultLightGrayDarker } from 'layouts/Theme';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { toast } from 'react-toast';
import Overlay from 'components/Overlay';
import {
  updateAnalyzeToFirebase,
  addAnalyzeToFirebase,
  deleteAnalyzeItemToFirebase,
  updateAnalyzeItemToFirebase,
  generateRandomUid,
} from 'shared/functions/global';
import DatePicker from 'react-datepicker';
import moment from 'moment';

type Props = {
  userData: UserInfo;
  analyzeType: string;
  analyzePiece: string;
  analyzeLabel: string;
  startDate: string;
  endDate: string;
  userId: string;
};

const BodyDataGraph: React.FC<Props> = ({
  userData,
  analyzeType,
  analyzePiece,
  analyzeLabel,
  startDate,
  endDate,
  userId,
}) => {
  const { t } = useTranslation();

  const addNewValueRef = useRef<HTMLInputElement>(null);

  const { theme, tenant } = useContext(AuthContext);
  const [addNewValue, setAddNewValue] = useState(false);
  const [updateGraphTrigger, setUpdateGraphTrigger] = useState(0);

  const [startDateAddValue, setStartDateAddValue] = useState(null);
  const [currentAnalyzeNewValue, setCurrentAnalyzeNewValue] = useState(0);
  const [currentBMI, setCurrentBMI] = useState(0);

  const [deleteItemPopupClass, setDeleteItemPopupClass] = useState('hidden');
  const [deleteItemDateString, setDeleteItemDateString] = useState('');

  const [historyChevronClass, setHistoryChevronClass] = useState('text-accentColor');
  const [historyClass, setHistoryClass] = useState('hidden');

  const [currentDataObject, setCurrentDataObject] = useState<any>([{}]);
  const [currentHistoryDataObject, setCurrentHistoryDataObject] = useState<any>([]);

  const CustomTooltip = ({ active, payload, label }: { active: any; label: any; payload: any }) => {
    if (active) {
      return (
        <div className="bg-lightGray text-textColor px-30 py-30">
          <div className="font-semibold">{label}</div>
          <div className="text-accentColor font-bold text-30">
            {payload[0]?.value?.toString().replaceAll('.', ',')} {analyzeType !== 'BMI' && analyzePiece}
          </div>
        </div>
      );
    }

    return null;
  };

  // Toggle form of nutrition area
  const toggleHistory = (): void => {
    if (historyClass === 'hidden') {
      setHistoryClass('block');
      setHistoryChevronClass('text-accentColor transition duration-300 transform rotate-180');
    } else {
      setHistoryClass('hidden');
      setHistoryChevronClass('text-accentColor transition duration-300 transform rotate-360');
    }
  };

  const changeAnalyzeDate = (dates: any) => {
    setStartDateAddValue(dates);
  };

  const addNewValueBtn = () => {
    setStartDateAddValue(null);
    setAddNewValue(true);
    setTimeout(() => {
      if (addNewValueRef.current) {
        addNewValueRef.current.select();
      }
    }, 1);
  };

  const calculateBMI = () => {
    if (userData?.bodyWeight !== undefined && userData?.bodySize) {
      const getBodySizeCM = parseFloat(userData?.bodySize) / 100;
      const getBodySize = getBodySizeCM * getBodySizeCM;
      const getBmi = parseFloat(userData?.bodyWeight) / getBodySize;
      const getBmiFixed = parseFloat(getBmi.toFixed(1));

      addAnalyzeToFirebase(
        userData,
        userId,
        false,
        'BMI',
        'bmi',
        'kg/m²',
        moment().locale('de').format('DD. MMM YYYY'),
        getBmiFixed,
        moment().format('YYYY-MM-DD'),
        true,
        tenant
      );

      setCurrentBMI(getBmiFixed);
    } else {
      toast.error('Es ist kein Gewicht in deinem Profil vorhanden. Bitte prüfe deine Profilangaben!');
    }
  };

  const calculateNewBMI = () => {
    if (userData?.bodyWeight !== undefined && userData?.bodySize) {
      const isValueOnDayExists = userData?.analyze?.items
        ?.filter(element => element.name === 'bmi')[0]
        .itemValues?.filter(item => item.date === moment().format('YYYY-MM-DD'));

      const getBodySizeCM = parseFloat(userData?.bodySize) / 100;
      const getBodySize = getBodySizeCM * getBodySizeCM;
      const getBmi = parseFloat(userData?.bodyWeight) / getBodySize;
      const getBmiFixed = parseFloat(getBmi.toFixed(1));

      if (isValueOnDayExists?.length === 0) {
        setCurrentBMI(getBmiFixed);
        saveNewValue('bmi', getBmiFixed);
      } else {
        toast.error('An diesem Tag gibt es schon einen Wert!');
      }
    } else {
      toast.error('Es ist kein Gewicht in deinem Profil vorhanden. Bitte prüfe deine Profilangaben!');
    }
  };

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  const saveInitialValue = () => {
    const generateName = generateRandomUid();

    let thisName = analyzeType;

    if (thisName.length === 0) {
      thisName = generateName;
    }

    if (startDateAddValue !== null) {
      if (currentAnalyzeNewValue > 0) {
        addAnalyzeToFirebase(
          userData,
          userId,
          false,
          analyzeLabel,
          thisName,
          analyzePiece,
          moment(startDateAddValue).locale('de').format('DD. MMM YYYY'),
          currentAnalyzeNewValue,
          moment(startDateAddValue).format('YYYY-MM-DD'),
          true,
          tenant
        );

        setAddNewValue(false);
      } else {
        toast.error('Bitte gebe einen Wert ein!');
      }
    } else if (currentAnalyzeNewValue > 0) {
      addAnalyzeToFirebase(
        userData,
        userId,
        false,
        analyzeLabel,
        thisName,
        analyzePiece,
        moment().locale('de').format('DD. MMM YYYY'),
        currentAnalyzeNewValue,
        moment().format('YYYY-MM-DD'),
        true,
        tenant
      );

      setAddNewValue(false);
    } else {
      toast.error('Bitte gebe einen Wert ein!');
    }
  };

  const saveNewValue = (name: string, newValue: number) => {
    let isValueOnDayExists = userData?.analyze?.items
      ?.filter(element => element.name === name)[0]
      .itemValues?.filter(item => item.date === moment().format('YYYY-MM-DD'));

    if (startDateAddValue !== null) {
      isValueOnDayExists = userData?.analyze?.items
        ?.filter(element => element.name === name)[0]
        .itemValues?.filter(item => item.date === moment(startDateAddValue).format('YYYY-MM-DD'));
    }

    if (newValue > 0) {
      if (isValueOnDayExists?.length === 0) {
        if (startDateAddValue !== null) {
          updateAnalyzeToFirebase(
            userData,
            name,
            userId,
            moment(startDateAddValue).locale('de').format('DD. MMM YYYY'),
            newValue,
            moment(startDateAddValue).format('YYYY-MM-DD'),
            tenant
          );
        } else {
          updateAnalyzeToFirebase(
            userData,
            name,
            userId,
            moment().locale('de').format('DD. MMM YYYY'),
            newValue,
            moment().format('YYYY-MM-DD'),
            tenant
          );
        }

        setUpdateGraphTrigger(updateGraphTrigger + 1);
      } else {
        toast.error('An diesem Tag hast du schon einen Wert eingetragen!');
      }

      setAddNewValue(false);
    } else {
      toast.error('Bitte gebe einen Wert ein!');
    }
  };

  const deleteItem = (dateString: string) => {
    setDeleteItemPopupClass('block');
    setDeleteItemDateString(dateString);
  };

  const deleteItemExecute = () => {
    const newColumns = [
      ...currentDataObject
        .filter((item: any) => item.date !== deleteItemDateString)
        .slice()
        .sort((a: any, b: any) => parseFloat(b.date.replaceAll('-', '')) - parseFloat(a.date.replaceAll('-', ''))),
    ];

    const newColumnsGraph = [
      ...currentDataObject
        .filter((item: any) => item.date !== deleteItemDateString)
        .slice()
        .sort((a: any, b: any) => parseFloat(a.date.replaceAll('-', '')) - parseFloat(b.date.replaceAll('-', ''))),
    ];

    setCurrentDataObject([newColumnsGraph]);
    setCurrentHistoryDataObject(newColumns);

    if (newColumns.length === 0) {
      deleteAnalyzeItemToFirebase(userData, analyzeType, userId, tenant);
    } else {
      updateAnalyzeItemToFirebase(userData, analyzeType, userId, newColumns, tenant);
    }

    setDeleteItemPopupClass('hidden');
  };

  useEffect(() => {
    const thisItem = userData?.analyze?.items.filter(element => element.name === analyzeType);
    const thisItem2 = userData?.analyze?.items.filter(element => element.name === analyzeType);

    if (thisItem !== undefined && thisItem2 !== undefined) {
      if (thisItem[0]?.itemValues.length > 0 && thisItem2[0]?.itemValues.length > 0) {
        if (startDate.length > 0 && endDate.length > 0) {
          setCurrentHistoryDataObject(
            thisItem2[0].itemValues
              .slice()
              .sort((a: any, b: any) => parseFloat(b.date.replaceAll('-', '')) - parseFloat(a.date.replaceAll('-', '')))
              .filter(
                item =>
                  moment(item.date, 'YYYY-MM-DD').unix() >= moment(startDate, 'DD.MM.YYYY').unix() &&
                  moment(item.date, 'YYYY-MM-DD').unix() <= moment(endDate, 'DD.MM.YYYY').unix()
              )
          );

          setCurrentDataObject(
            thisItem[0].itemValues
              .slice()
              .sort((a: any, b: any) => parseFloat(a.date.replaceAll('-', '')) - parseFloat(b.date.replaceAll('-', '')))
              .filter(
                item =>
                  moment(item.date, 'YYYY-MM-DD').unix() >= moment(startDate, 'DD.MM.YYYY').unix() &&
                  moment(item.date, 'YYYY-MM-DD').unix() <= moment(endDate, 'DD.MM.YYYY').unix()
              )
          );
        } else {
          setCurrentHistoryDataObject(
            thisItem2[0].itemValues
              .slice()
              .sort((a: any, b: any) => parseFloat(b.date.replaceAll('-', '')) - parseFloat(a.date.replaceAll('-', '')))
          );

          setCurrentDataObject(
            thisItem[0].itemValues
              .slice()
              .sort((a: any, b: any) => parseFloat(a.date.replaceAll('-', '')) - parseFloat(b.date.replaceAll('-', '')))
          );
        }
      } else {
        setCurrentDataObject([]);
      }
    } else {
      setCurrentDataObject([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, userData]);

  return (
    <div className="bg-lightGray rounded-3xl p-20">
      <Overlay
        overlayClass={deleteItemPopupClass}
        setOverlayClass={setDeleteItemPopupClass}
        headline="Eintrag löschen"
        icon={<TrashIcon width={25} height={25} className="text-accentColor mx-auto" />}
        withoutCloseButton
        top0
      >
        <div className="px-20 pb-60">
          <div className="pt-30 font-extralight text-base">
            <div>Möchtest du diesen Eintrag wirklich löschen?</div>
            <div className="pt-10">Diese Aktion kann nicht rückgängig gemacht werden.</div>
          </div>
          <div className="pt-30 flex justify-between flex-wrap">
            <Button onClick={() => deleteItemExecute()}>Löschen</Button>
            <Button onClick={() => setDeleteItemPopupClass('hidden')}>Abbrechen</Button>
          </div>
        </div>
      </Overlay>

      <div className="flex gap-10 justify-between">
        {addNewValue ? (
          <div className="font-bold text-14 pb-20">{analyzeLabel}</div>
        ) : (
          <div className="font-bold text-20 my-auto">{analyzeLabel}</div>
        )}

        {addNewValue ? (
          <div className="my-auto static hidden" />
        ) : (
          <div className="my-auto">
            <div className="flex space-x-20">
              {analyzeType === 'bmi' && (
                <div>
                  <Button
                    className="py-5 text-13"
                    onClick={() =>
                      userData?.analyze?.items &&
                      userData?.analyze?.items?.filter(item => item.name === 'bmi')?.length > 0
                        ? calculateNewBMI()
                        : calculateBMI()
                    }
                  >
                    BMI neu berechnen
                  </Button>
                </div>
              )}
              <div>
                {analyzeType === 'bmi' ? (
                  <Button className="py-5 text-13" buttonStyle="white" onClick={() => addNewValueBtn()}>
                    Wert manuell eintragen
                  </Button>
                ) : (
                  <Button className="py-5 text-13" onClick={() => addNewValueBtn()}>
                    Neuen Wert eintragen
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {addNewValue && (
        <div className="my-auto static">
          <div className="flex space-x-10">
            <div className="font-semibold pb-15">Datum:</div>
            <div className="font-extralight pb-15">
              {startDateAddValue === null ? (
                <div>Heute, {moment().format('DD.MM.YYYY')}</div>
              ) : (
                <div>{moment(startDateAddValue).format('DD.MM.YYYY')}</div>
              )}
            </div>
            <div className="">
              <DatePicker
                className="my-auto"
                dateFormat="dd.MM.yyyy"
                selected={startDateAddValue}
                locale="de"
                withPortal
                onChange={changeAnalyzeDate}
                calendarStartDay={1}
                customInput={<PencilIcon width={20} height={20} className="text-accentColor cursor-pointer my-auto" />}
                onFocus={e => e.target.blur()}
              />
            </div>
          </div>
          <div className="md:flex md:flex-wrap block gap-10">
            <div className="flex py-2">
              <div className="rounded-md border-solid border border-textColor border-opacity-30 py-2">
                <input
                  id="calories"
                  name="calories"
                  onFocus={setBodyClassForMobileSafari}
                  onBlur={removeBodyClassForMobileSafari}
                  onChange={e => setCurrentAnalyzeNewValue(parseFloat(e.target.value))}
                  type="number"
                  ref={addNewValueRef}
                  className="w-100 appearance-none block text-12 font-semibold placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor text-center"
                />
              </div>
              <div className="pl-10 font-semibold my-auto">{analyzePiece}</div>
            </div>
            <div className=" md:pt-0 md:pl-20 my-auto">
              <Button
                onClick={() =>
                  userData?.analyze?.items &&
                  userData?.analyze?.items?.filter(item => item.name === analyzeType)?.length > 0
                    ? saveNewValue(analyzeType, currentAnalyzeNewValue)
                    : saveInitialValue()
                }
                className="py-5 text-13"
              >
                Speichern
              </Button>
            </div>
            <div className=" md:pt-0 my-auto">
              <Button
                onClick={() => setAddNewValue(false)}
                className="py-5 text-13 bg-lightGrayDarker"
                buttonStyle="white"
              >
                Abbrechen
              </Button>
            </div>
          </div>
        </div>
      )}

      {currentDataObject.length > 0 ? (
        <div>
          <ResponsiveContainer aspect={1.5}>
            <ComposedChart
              width={800}
              height={500}
              data={currentDataObject}
              margin={{ top: 25, right: 30, left: 0, bottom: 5 }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={defaultLightGrayDarker} stopOpacity={0.4} />
                  <stop offset="95%" stopColor={defaultLightGrayDarker} stopOpacity={0.4} />
                </linearGradient>
              </defs>

              <XAxis
                dataKey="name"
                ticks={
                  currentDataObject.length > 1
                    ? [currentDataObject[0]?.name, currentDataObject[currentDataObject.length - 1]?.name]
                    : [currentDataObject[0]?.name]
                }
                dy={6}
                tick={{ fontSize: 14, fontWeight: 'normal', fill: theme?.textColor ?? defaultTextColor }}
              />
              <YAxis
                tickCount={5}
                allowDecimals
                domain={['dataMin', 'dataMax']}
                tick={{
                  transform: 'translate(-2, -2)',
                  fontSize: 14,
                  fontWeight: 'normal',
                  fill: theme?.textColor ?? defaultTextColor,
                }}
              />

              <Tooltip content={<CustomTooltip active={undefined} payload={undefined} label={undefined} />} />

              <Area
                type="monotone"
                stroke={theme?.textColor ?? defaultTextColor}
                dataKey="value"
                strokeWidth={0}
                fill="url(#colorUv)"
              />

              <Line
                type="monotone"
                strokeLinecap="round"
                strokeWidth={4}
                dataKey="value"
                stroke={theme?.accentColor ?? defaultAccentColor}
                dot={{
                  stroke: theme?.textColor ?? defaultTextColor,
                  strokeWidth: 2,
                  fill: theme?.bgColor ?? defaultBgColor,
                  r: 6,
                }}
              />
            </ComposedChart>
          </ResponsiveContainer>

          <div className="my-auto pt-20">
            <div
              className="flex gap-10 flex-wrap pb-15 font-bold text-16 cursor-pointer"
              onClick={() => toggleHistory()}
              aria-hidden
            >
              <div className="">Verlauf</div>
              <ChevronDownIcon className={historyChevronClass} height={26} width={26} />
            </div>
            <div className={historyClass}>
              {currentHistoryDataObject.map((item: any, index: number) => (
                <div
                  className="rounded-3xl h-full mb-20 px-20 py-10 bg-lightGrayDarker w-full desktop:w-3/4 relative"
                  key={index}
                >
                  <div className="flex justify-between">
                    <div>{item.name}</div>
                    <div className="flex space-x-20">
                      <div className="font-extralight pr-20">
                        {item.value?.toString().replaceAll('.', ',')}{' '}
                        {analyzeType !== 'BMI' && analyzePiece.toLocaleLowerCase()}
                      </div>
                    </div>
                    <TrashIcon
                      onClick={() => deleteItem(item.date)}
                      width={25}
                      height={25}
                      className="text-accentColor cursor-pointer"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="font-extralight pt-20 pb-20">Für diesen Zeitraum sind noch keine Daten vorhanden!</div>
      )}
    </div>
  );
};

export default BodyDataGraph;
