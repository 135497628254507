import React from 'react';
import {
  CheckIcon,
  ArrowSmallUpIcon as ArrowSmUpIcon,
  ExclamationTriangleIcon as ExclamationIcon,
} from '@heroicons/react/24/outline';

type Props = {
  percent?: number;
  minValue?: number;
  maxValue?: number;
  value?: number;
};

const MakroIcons: React.FC<Props> = ({ percent = 0, minValue = 0, maxValue = 0, value = 0 }) => {
  if (value < minValue) {
    return <ArrowSmUpIcon width={20} height={20} className="text-accentColor" />;
  }
  if (value >= minValue && value <= maxValue) {
    return <CheckIcon width={20} height={20} className="text-accentColor" />;
  }
  if (value > maxValue) {
    return <ExclamationIcon width={20} height={20} className="text-accentColor" />;
  }
  return <CheckIcon width={20} height={20} className="text-accentColor" />;
  /* if (percent < 85) {
    return <ArrowSmUpIcon width={20} height={20} className="text-accentColor" />;
  }
  if (percent > 85 && percent < 110) {
    return <CheckIcon width={20} height={20} className="text-accentColor" />;
  }
  if (percent > 115) {
    return <ExclamationIcon width={20} height={20} className="text-accentColor" />;
  }
  return <CheckIcon width={20} height={20} className="text-accentColor" />; */
};

export default MakroIcons;
