import React, { useContext } from 'react';
import { MainContext } from 'providers/MainProvider';
import { savePurchaseListToFirebase } from 'shared/functions/global';
import { AuthContext } from 'providers/AuthProvider';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

type Props = {
  thisId?: number;
  itemObject?: any;
};

const ShoppingListItem: React.FC<Props> = ({ thisId, itemObject }) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { userData, tenant } = useContext(AuthContext);
  const { purchaseList } = useContext(MainContext);

  const deleteIngredient = (deleteName: any) => {
    if (purchaseList !== undefined) {
      const newObject = {
        ...purchaseList[0],
        ingredients: purchaseList[0].ingredients.map(ingridients => {
          if (ingridients.name !== deleteName) return ingridients;
          return {
            ...ingridients,
            deleted: !ingridients.deleted,
          };
        }),
      };

      savePurchaseListToFirebase(userData, newObject, authContext.user?.uid, tenant);
    }
  };

  return (
    <>
      <div className="rounded-3xl h-full mb-30 p-20 shadow-2xl bg-lightGray relative">
        <div className="text-20 font-semibold pb-20">{itemObject[0]?.category}</div>
        {itemObject?.map((ingridients: any, index: number) => (
          <div
            key={index}
            className="flex justify-between mb-20 cursor-pointer"
            onClick={() => deleteIngredient(ingridients.name)}
            onKeyDown={() => deleteIngredient(ingridients.name)}
            aria-hidden="true"
          >
            <div className="flex">
              {ingridients.deleted === true ? (
                <CheckCircleIcon width={25} height={25} className="mr-10 my-auto text-accentColor" />
              ) : (
                <div className="rounded-full w-20 h-20 border-2 border-accentColor ml-1 mr-10 my-auto">
                  <></>
                </div>
              )}

              <div className={ingridients.deleted === true ? 'my-auto line-through' : 'my-auto'}>
                {ingridients.name}
              </div>
            </div>
            <div
              className={
                ingridients.deleted === true ? 'my-auto font-extralight line-through' : 'my-auto font-extralight'
              }
            >
              {(parseFloat(ingridients.amount).toFixed(2) as any) % 1 === 0
                ? parseFloat(ingridients.amount).toFixed(0)
                : parseFloat(ingridients.amount).toFixed(2)}{' '}
              g
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ShoppingListItem;
