import React from 'react';
import classNames from 'classnames';
import Container from 'components/Container';
import Sidebar from 'components/Sidebar';
import CookieBanner from 'components/CookieBanner';
import ImpersonateHeader from 'components/ImpersonateHeader';
import styles from './style.module.scss';

type Props = {
  children: React.ReactNode;
};

const MainLayout: React.FC<Props> = ({ children }) => {
  return (
    <Container>
      <ImpersonateHeader />
      <Sidebar />
      {window.location.hostname === 'app.tortija.de' && <CookieBanner />}
      <div className={styles.wrapper}>
        <div className={classNames(styles.container, 'custom-scrollbar')} data-pdf-maincontainer id="mainContainer">
          {children}
          {/* <Offline>
            <div className="h-full w-full md:flex block">
              <div className={styles.loading}>
                <div className="pb-30">
                  <WifiIcon width={150} height={150} className="text-accentColor mx-auto cursor-pointer" />
                </div>
                <div className="text-center font-bold text-25">Du hast aktuell keine Internetverbindung!</div>
                <div className="text-center">
                  Sobald deine Verbindung wieder verfügbar ist werden die Inhalte direkt dargestellt!
                </div>
              </div>
            </div>
  </Offline> */}
        </div>
      </div>
    </Container>
  );
};

export default MainLayout;
