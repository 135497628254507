import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AdjustmentsVerticalIcon as AdjustmentsIcon,
  ArrowPathIcon as RefreshIcon,
  XMarkIcon as XIcon,
  CalendarIcon,
} from '@heroicons/react/24/outline';
import Drawer from 'components/Drawer';
import Headline from 'components/Headline';
import ButtonGroup from 'components/ButtonGroup';
import DatePicker from 'react-datepicker';
import styles from './styles.module.scss';

type Props = {
  isFilterOpen?: boolean;
  filterCategoriesFunction: Function;
  filterCategoriesValues: string[];
  startDateValue: any;
  startDateFunction: Function;
  endDateValue: any;
  endDateFunction: Function;
  closeFilterOpen: (value: boolean) => void;
};

const categories: string[] = [
  'Anamnese',
  'Arztberichte',
  'Bilder',
  'Datenschutz',
  'Körper-Analyse',
  'Sonstiges',
  'Vertragliches',
  'Nachrichten',
];

const FilterDrawerDocuments: React.FC<Props> = ({
  isFilterOpen = false,
  closeFilterOpen,
  filterCategoriesFunction,
  filterCategoriesValues,
  startDateValue,
  startDateFunction,
  endDateFunction,
  endDateValue,
}) => {
  const { t } = useTranslation();

  const onChange = (dates: any) => {
    startDateFunction(dates);
  };

  const onChangeEndDate = (dates: any) => {
    endDateFunction(dates);
  };

  const resetFilter = () => {
    filterCategoriesFunction([]);

    startDateFunction(null);
    endDateFunction(null);
  };

  const CustomDateInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
    return (
      <label>
        <div className="flex w-full">
          <div className="flex-1">
            <input {...props} />
          </div>
          <div className="ml-10 my-auto">
            <CalendarIcon width={25} height={25} className="text-accentColor mx-auto cursor-pointer" />
          </div>
        </div>
      </label>
    );
  };

  return (
    <Drawer isOpen={isFilterOpen} closeDrawer={closeFilterOpen}>
      <div className="relative">
        <div className={styles.header}>
          <AdjustmentsIcon width={28} height={28} className={styles.filterIcon} />
          <Headline level={3}>{t('Filter')}</Headline>
        </div>
        <div className="absolute right-10 top-10">
          <XIcon
            onClick={() => closeFilterOpen(false)}
            width={25}
            height={25}
            className="text-accentColor mx-auto cursor-pointer"
          />
        </div>
        <div
          className="pb-20 flex gap-5 flex-wrap cursor-pointer"
          onClick={() => resetFilter()}
          onMouseDown={() => resetFilter()}
          aria-hidden="true"
        >
          <div className="my-auto">
            <RefreshIcon width={20} height={20} className="text-accentColor mx-auto cursor-pointer" />
          </div>
          <div className="hover:underline">Alles zurücksetzen</div>
        </div>
        <div className={styles.wrapper}>
          <>
            <ButtonGroup
              options={categories}
              initialOption={filterCategoriesValues}
              label="Kategorie"
              itemsClassName="grid-cols-3"
              changeSelectedItem={filterCategoriesFunction}
            />
            <hr />
            <div>
              <div className="my-auto text-18 font-semibold">Zeitraum</div>
              <div className="flex gap-50 flex-wrap justify-between pt-5">
                <div className="flex-1">
                  <div className="font-extralight pb-1">Von:</div>
                  <div className="w-full font-light left text-xs border-opacity-30">
                    <DatePicker
                      className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                      selected={startDateValue}
                      onChange={onChange}
                      startDate={startDateValue}
                      locale="de"
                      dateFormat="dd.MM.yyyy"
                      customInput={React.createElement(React.forwardRef(CustomDateInput))}
                      calendarStartDay={1}
                      onFocus={e => e.target.blur()}
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <div className="font-extralight pb-1">Bis:</div>
                  <div className="font-light left text-xs border-opacity-30 w-full">
                    <DatePicker
                      className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                      selected={endDateValue}
                      onChange={onChangeEndDate}
                      startDate={endDateValue}
                      disabled={startDateValue === null}
                      locale="de"
                      minDate={startDateValue}
                      dateFormat="dd.MM.yyyy"
                      customInput={React.createElement(React.forwardRef(CustomDateInput))}
                      calendarStartDay={1}
                      onFocus={e => e.target.blur()}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </>
        </div>
      </div>
    </Drawer>
  );
};

export default FilterDrawerDocuments;
