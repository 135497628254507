import React, { useContext, useState, useEffect } from 'react';
import { MainContext } from 'providers/MainProvider';
import { AuthContext } from 'providers/AuthProvider';
import { useHistory } from 'react-router-dom';
import { PlusIcon, SunIcon, PencilIcon, ArrowPathIcon as RefreshIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import styles from './styles.module.scss';

type Props = {
  label: string;
  mealLabel?: string;
  mealCounter?: number;
  type?: string;
  kcal_value?: number;
  carbohydrates_value: number;
  protein_value: number;
  fat_value?: number;
  imageUrl?: string;
  onClick?: any;
  mealType?: string;
  setMealType: Function;
  openIngredientSearchPopupClass?: any;
  mealAndIngredientCount?: number;
  dayItem: any;
  openIngredientPopup?: any;
  openGenerateMealPopup: Function;
  setMenuClass: Function;
  menuClassValue: string;
  setCheckBoxArray: Function;
  checkBoxArray: any;
  dayId: any;
  planUid: any;
  openManualItemPopup: Function;
  isDesktop?: boolean;
  setDesktopPopupClass?: Function;
  desktopPopupClassValue?: string;
};

const DashboardMealCardHorizontal: React.FC<Props> = ({
  label,
  mealLabel = '',
  mealCounter = 0,
  kcal_value = 0,
  carbohydrates_value = 0,
  protein_value = 0,
  fat_value = 0,
  mealAndIngredientCount = 0,
  imageUrl = '/static/media/logo.1cff5e99.svg',
  type = 'add',
  onClick,
  mealType,
  openIngredientSearchPopupClass,
  dayItem,
  openIngredientPopup,
  openGenerateMealPopup,
  setMealType,
  setMenuClass,
  menuClassValue,
  checkBoxArray,
  setCheckBoxArray,
  dayId,
  planUid,
  openManualItemPopup,
  isDesktop = false,
  setDesktopPopupClass,
  desktopPopupClassValue,
}) => {
  const { setTempRecipeObject } = useContext(MainContext);
  const history = useHistory();
  const [mealFull, setMealFull] = useState(false);
  const { userData, theme, tenantData } = useContext(AuthContext);

  const openGenerateMeal = () => {
    setMealType(mealType);
    openGenerateMealPopup();
  };

  const openDesktopNewItem = () => {
    if (setDesktopPopupClass !== undefined) {
      setDesktopPopupClass('hidden');
    }

    openIngredientSearchPopupClass(mealType);
  };

  const addAllMeals = () => {
    let dayRecipeCount = 0;
    let dayIngredientCount = 0;
    let dayManualItemsCount = 0;

    dayRecipeCount = dayItem[mealType as string]?.recipes?.length as number;
    dayIngredientCount = dayItem[mealType as string]?.ingredients?.length as number;

    if (dayItem[mealType as string]?.manualItems !== undefined) {
      dayManualItemsCount = dayItem[mealType as string].manualItems.length as number;
    }
    const dayTotalCount = dayRecipeCount + dayIngredientCount + dayManualItemsCount;

    const checkBoxRecipeCount = checkBoxArray.filter(
      (item: any) => item.type === 'recipes' && item.mealType === mealType
    ).length as number;
    const checkBoxIngredientCount = checkBoxArray.filter(
      (item: any) => item.type === 'ingredients' && item.mealType === mealType
    ).length as number;
    const checkBoxManualItemsCount = checkBoxArray.filter(
      (item: any) => item.type === 'manualItems' && item.mealType === mealType
    ).length as number;
    const checkBoxTotalCount = checkBoxRecipeCount + checkBoxIngredientCount + checkBoxManualItemsCount;

    if (dayTotalCount === checkBoxTotalCount) {
      setMealFull(false);
      setCheckBoxArray(checkBoxArray.filter((item: any) => item.mealType !== mealType));
    } else {
      const thisArray = checkBoxArray.filter((item: any) => item.mealType !== mealType);

      dayItem[mealType as string]?.recipes?.forEach(function (key: any) {
        thisArray.push({ id: key.id, type: 'recipes', mealType, deactivate: false });
      });

      dayItem[mealType as string]?.ingredients?.forEach(function (key: any) {
        thisArray.push({ id: key.id, type: 'ingredients', mealType, deactivate: false });
      });

      dayItem[mealType as string]?.manualItems?.forEach(function (key: any) {
        thisArray.push({ id: key.id, type: 'manualItems', mealType, deactivate: false });
      });

      setMealFull(true);

      setCheckBoxArray([...thisArray]);
    }
  };

  const clickMealCheckbox = (mealItem: any, clickType: string, deactivate?: boolean) => {
    if (checkBoxArray.findIndex((item: any) => item.id.toString() === mealItem.id.toString()) === -1) {
      const thisNewArray = [
        ...checkBoxArray,
        deactivate !== undefined
          ? { id: mealItem.id, type: clickType, mealType, deactivate: true }
          : { id: mealItem.id, type: clickType, mealType, deactivate: false },
      ];
      setCheckBoxArray(thisNewArray);

      let dayRecipeCount = 0;
      let dayIngredientCount = 0;
      let dayManualItemsCount = 0;

      dayRecipeCount = dayItem[mealType as string]?.recipes?.length as number;
      dayIngredientCount = dayItem[mealType as string]?.ingredients?.length as number;
      if (dayItem[mealType as string]?.manualItems !== undefined) {
        dayManualItemsCount = dayItem[mealType as string].manualItems.length as number;
      }

      const dayTotalCount = dayRecipeCount + dayIngredientCount + dayManualItemsCount;

      const checkBoxRecipeCount = thisNewArray.filter(
        (item: any) => item.type === 'recipes' && item.mealType === mealType && item.deactivate === false
      ).length;
      const checkBoxIngredientCount = thisNewArray.filter(
        (item: any) => item.type === 'ingredients' && item.mealType === mealType && item.deactivate === false
      ).length;
      const checkBoxManualItemsCount = thisNewArray.filter(
        (item: any) => item.type === 'manualItems' && item.mealType === mealType && item.deactivate === false
      ).length;
      const checkBoxTotalCount = checkBoxRecipeCount + checkBoxIngredientCount + checkBoxManualItemsCount;

      if (dayTotalCount === checkBoxTotalCount) {
        setMealFull(true);
      } else {
        setMealFull(false);
      }
    } else {
      const thisNewArray = checkBoxArray.filter((item: any) => item.id.toString() !== mealItem.id.toString());
      setCheckBoxArray(thisNewArray);

      let dayRecipeCount = 0;
      let dayIngredientCount = 0;
      let dayManualItemsCount = 0;

      dayRecipeCount = dayItem[mealType as string]?.recipes?.length as number;
      dayIngredientCount = dayItem[mealType as string]?.ingredients?.length as number;
      dayManualItemsCount = dayItem[mealType as string]?.manualItems?.length as number;
      const dayTotalCount = dayRecipeCount + dayIngredientCount + dayManualItemsCount;

      const checkBoxRecipeCount = thisNewArray.filter(
        (item: any) => item.type === 'recipes' && item.mealType === mealType && item.deactivate === false
      ).length as number;
      const checkBoxIngredientCount = thisNewArray.filter(
        (item: any) => item.type === 'ingredients' && item.mealType === mealType && item.deactivate === false
      ).length as number;
      const checkBoxManualItemsCount = thisNewArray.filter(
        (item: any) => item.type === 'manualItems' && item.mealType === mealType && item.deactivate === false
      ).length as number;
      const checkBoxTotalCount = checkBoxRecipeCount + checkBoxIngredientCount + checkBoxManualItemsCount;

      if (dayTotalCount === checkBoxTotalCount) {
        setMealFull(true);
      } else {
        setMealFull(false);
      }
    }
  };

  function jumpToRecipe(recipeItem: any) {
    setTempRecipeObject(recipeItem);
    const fromPath = 'dashboard';

    if (isDesktop) {
      history.push({
        pathname: `/nutrition/recipes/${recipeItem.uid}`,
        state: { from: fromPath, uid: planUid, type: mealType, id: dayId, label: mealLabel },
      });
    } else {
      history.push({
        pathname: `/nutrition/recipes/${recipeItem.uid}`,
        state: { from: fromPath, uid: planUid, type: mealType, id: dayId, label: 'mobile' },
      });
    }
  }

  useEffect(() => {
    if (checkBoxArray.length > 0) {
      setMenuClass('block');
    } else {
      setMenuClass('hidden');
    }
  }, [checkBoxArray]);

  useEffect(() => {
    setMealFull(false);
  }, [dayItem, desktopPopupClassValue]);

  return (
    <>
      {mealAndIngredientCount === 0 && isDesktop === false ? (
        <div
          onClick={() => openIngredientSearchPopupClass(mealType)}
          onKeyDown={() => openIngredientSearchPopupClass(mealType)}
          aria-hidden="true"
        >
          <div className="rounded-3xl bg-lightGray py-15 mb-10 flex justify-between">
            <div className="flex pl-20 my-auto">
              <div className="my-auto">
                <SunIcon width={20} height={20} className="text-accentColor" />
              </div>
              <div className="pl-10 font-bold">{label}</div>
            </div>
            <div className="pr-30 my-auto">
              <PlusIcon width={30} height={30} className="text-accentColor" />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="rounded-3xl bg-lightGray py-15 mb-10">
            <div className="flex justify-between">
              <div className="flex pl-15 my-auto">
                {kcal_value === 0 ? (
                  <></>
                ) : mealFull ? (
                  <div
                    className="my-auto cursor-pointer"
                    onClick={() => addAllMeals()}
                    onKeyDown={() => addAllMeals()}
                    aria-hidden="true"
                  >
                    <CheckCircleIcon width={20} height={20} className="my-auto text-lightGreen" />
                  </div>
                ) : (
                  <div
                    className="my-auto"
                    onClick={() => addAllMeals()}
                    onKeyDown={() => addAllMeals()}
                    aria-hidden="true"
                  >
                    <div className="rounded-full w-20 h-20 border-2 border-accentColor my-auto cursor-pointer" />
                  </div>
                )}

                <div className="pl-10 font-bold">{label}</div>
              </div>
              <div className="pr-30 my-auto flex space-x-10 cursor-pointer">
                {(userData?.role === 2 ||
                  tenantData?.settings?.functions?.recipeGenerator === undefined ||
                  userData?.isAdminUser ||
                  userData?.isTrainerUser ||
                  userData?.isLocationAdminUser ||
                  localStorage.getItem('currentUserToken') ||
                  tenantData?.settings?.functions?.recipeGenerator === true) && (
                  <RefreshIcon
                    onClick={() => openGenerateMeal()}
                    width={25}
                    height={25}
                    className="text-accentColor my-auto"
                  />
                )}
                <PlusIcon onClick={() => openDesktopNewItem()} width={30} height={30} className="text-accentColor" />
              </div>
            </div>
            <div className="pt-5">
              <div className="pl-50 flex justify-between font-extralight text-12 pr-100">
                <div>
                  <div className="font-semibold text-12 text-center">{kcal_value}</div>
                  <div className="text-center text-10">kcal</div>
                </div>
                <div>
                  <div className="font-semibold text-12 text-center">{carbohydrates_value}g</div>
                  <div className="text-center text-10">Kohlenhydrate</div>
                </div>
                <div>
                  <div className="font-semibold text-12 text-center">{protein_value}g</div>
                  <div className="text-center text-10">Eiweiß</div>
                </div>
                <div>
                  <div className="font-semibold text-12 text-center">{fat_value}g</div>
                  <div className="text-center text-10">Fett</div>
                </div>
              </div>
            </div>
            {dayItem?.[mealType!]?.recipes.map((item: any, index: number) => (
              <div key={index} className="flex justify-between">
                {checkBoxArray.findIndex((arrayItem: any) => arrayItem.id.toString() === item.id.toString()) === -1 ? (
                  <div
                    className="rounded-full ml-15 w-15 h-15 border-2 border-accentColor my-auto cursor-pointer"
                    onClick={() => clickMealCheckbox(item, 'recipes')}
                    onKeyDown={() => clickMealCheckbox(item, 'recipes')}
                    aria-hidden="true"
                  >
                    <></>
                  </div>
                ) : (
                  <div
                    className="ml-15 my-auto cursor-pointer"
                    onClick={() => clickMealCheckbox(item, 'recipes')}
                    onKeyDown={() => clickMealCheckbox(item, 'recipes')}
                    aria-hidden="true"
                  >
                    <CheckCircleIcon width={15} height={15} className="my-auto text-lightGreen" />
                  </div>
                )}

                <div
                  className="rounded-2xl bg-lightGray bg-opacity-10 mb-5 ml-15 mr-10 py-10 mt-10 border-textColor border-2 hover:border-accentColor flex-1 cursor-pointer"
                  onClick={() => jumpToRecipe(item)}
                  onKeyDown={() => jumpToRecipe(item)}
                  aria-hidden="true"
                >
                  <div className="flex justify-between">
                    <div className="flex">
                      <div className={styles.trackingImage}>
                        <img
                          src={
                            item.imageUrl !== undefined && item.imageUrl?.length > 0
                              ? item.imageUrl
                              : theme?.logo !== undefined && userData?.role !== 2
                              ? theme.logo
                              : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
                          }
                          className="rounded-2xl w-50 h-50 object-cover"
                          alt="zz"
                        />
                      </div>
                      <div className="pl-10 pr-10">
                        <div className="font-semibold text-14">{item.name}</div>
                        <div className="font-extralight text-12 flex space-x-7">
                          <div className="text-accentColor font-bold">{item.kcal_total} kcal</div>
                          <div className="rounded-full ml-1 w-1 h-1 border-2 border-textColor my-auto">
                            <></>
                          </div>
                          <div>
                            {item.amount} {item.piece}
                            {item.piece !== 'g' && item.piece !== 'ml' && item.portion_g !== undefined ? (
                              ` (${item.portion_g}g)`
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pr-20 my-auto">
                      <PencilIcon width={20} height={20} className="text-accentColor" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {dayItem?.[mealType!]?.ingredients.map((item: any, index: number) => (
              <div key={index} className="flex justify-between">
                {checkBoxArray.findIndex((arrayItem: any) => arrayItem.id.toString() === item.id.toString()) === -1 ? (
                  <div
                    className="rounded-full ml-15 w-15 h-15 border-2 border-accentColor my-auto cursor-pointer"
                    onClick={() => clickMealCheckbox(item, 'ingredients')}
                    onKeyDown={() => clickMealCheckbox(item, 'ingredients')}
                    aria-hidden="true"
                  >
                    <></>
                  </div>
                ) : (
                  <div
                    className="ml-15 my-auto cursor-pointer"
                    onClick={() => clickMealCheckbox(item, 'ingredients')}
                    onKeyDown={() => clickMealCheckbox(item, 'ingredients')}
                    aria-hidden="true"
                  >
                    <CheckCircleIcon width={15} height={15} className="my-auto text-lightGreen" />
                  </div>
                )}
                <div
                  className="rounded-2xl bg-lightGray bg-opacity-10 mb-5 ml-15 mr-10 py-10 mt-10 border-textColor border-2 hover:border-accentColor flex-1 cursor-pointer"
                  onClick={() => openIngredientPopup !== undefined && openIngredientPopup(mealType, item)}
                  onKeyDown={() => openIngredientPopup !== undefined && openIngredientPopup(mealType, item)}
                  aria-hidden="true"
                >
                  <div className="flex justify-between">
                    <div className="flex">
                      <div className={styles.trackingImage}>
                        <img
                          src={
                            item.imageUrl !== undefined && item.imageUrl?.length > 0
                              ? item.imageUrl
                              : theme?.logo !== undefined && userData?.role !== 2
                              ? theme.logo
                              : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
                          }
                          className="rounded-2xl w-50 h-50 object-cover"
                          alt="zz"
                        />
                      </div>
                      <div className="pl-10 pr-10">
                        <div className="font-semibold text-14">{item.name}</div>
                        <div className="font-extralight text-12 flex space-x-7">
                          <div className="text-accentColor font-bold">{item.kcal_total} kcal</div>
                          <div className="rounded-full ml-1 w-1 h-1 border-2 border-textColor my-auto">
                            <></>
                          </div>
                          <div>
                            {item.amount} {item.piece}
                            {item.piece !== 'g' && item.piece !== 'ml' && item.portion_g !== undefined ? (
                              `(${item.portion_g}g)`
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pr-20 my-auto">
                      <PencilIcon width={20} height={20} className="text-accentColor" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {dayItem?.[mealType!].manualItems?.map((item: any, index: number) => (
              <div key={index} className="flex justify-between">
                {checkBoxArray.findIndex((arrayItem: any) => arrayItem.id.toString() === item.id.toString()) === -1 ? (
                  <div
                    className="rounded-full ml-15 w-15 h-15 border-2 border-accentColor my-auto cursor-pointer"
                    onClick={() => clickMealCheckbox(item, 'manualItems')}
                    onKeyDown={() => clickMealCheckbox(item, 'manualItems')}
                    aria-hidden="true"
                  >
                    <></>
                  </div>
                ) : (
                  <div
                    className="ml-15 my-auto cursor-pointer"
                    onClick={() => clickMealCheckbox(item, 'manualItems')}
                    onKeyDown={() => clickMealCheckbox(item, 'manualItems')}
                    aria-hidden="true"
                  >
                    <CheckCircleIcon width={15} height={15} className="my-auto text-lightGreen" />
                  </div>
                )}
                <div
                  className="rounded-2xl bg-lightGray bg-opacity-10 mb-5 ml-15 mr-10 py-10 mt-10 border-textColor border-2 hover:border-accentColor flex-1 cursor-pointer"
                  onClick={() => openManualItemPopup !== undefined && openManualItemPopup(mealType, item)}
                  onKeyDown={() => openManualItemPopup !== undefined && openManualItemPopup(mealType, item)}
                  aria-hidden="true"
                >
                  <div className="flex justify-between">
                    <div className="flex">
                      <div className={styles.trackingImage}>
                        <img
                          src={
                            item.imageUrl !== undefined && item.imageUrl?.length > 0
                              ? item.imageUrl
                              : theme?.logo !== undefined && userData?.role !== 2
                              ? theme.logo
                              : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
                          }
                          className="rounded-2xl w-50 h-50 object-cover"
                          alt="zz"
                        />
                      </div>
                      <div className="pl-10 pr-10">
                        <div className="font-semibold text-14">{item.description}</div>
                        <div className="font-extralight text-12">
                          <span className="text-accentColor font-bold pr-10">{item.kcal_total} kcal</span>
                          {item.amount} {item.piece}
                          {item.piece !== 'g' && item.piece !== 'ml' && item.portion_g !== undefined ? (
                            `(${item.portion_g}g)`
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="pr-20 my-auto">
                      <PencilIcon width={20} height={20} className="text-accentColor" />
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {dayItem?.[mealType!]?.deactivatedItems !== undefined && (
              <>
                {dayItem?.[mealType!]?.deactivatedItems?.recipes?.length > 0 ||
                dayItem?.[mealType!]?.deactivatedItems?.ingredients?.length > 0 ||
                dayItem?.[mealType!]?.deactivatedItems?.manualItems?.length > 0 ? (
                  <div className="font-extralight pl-20 text-12 pt-10">Deaktiviert</div>
                ) : (
                  <></>
                )}
              </>
            )}

            {dayItem?.[mealType!]?.deactivatedItems?.recipes?.map((item: any, index: number) => (
              <div key={index} className="flex justify-between opacity-50">
                {checkBoxArray.findIndex((arrayItem: any) => arrayItem.id.toString() === item.id.toString()) === -1 ? (
                  <div
                    className="rounded-full ml-15 w-15 h-15 border-2 border-brownSemiDark my-auto cursor-pointer"
                    onClick={() => clickMealCheckbox(item, 'recipes', true)}
                    onKeyDown={() => clickMealCheckbox(item, 'recipes', true)}
                    aria-hidden="true"
                  >
                    <></>
                  </div>
                ) : (
                  <div
                    className="ml-15 my-auto cursor-pointer"
                    onClick={() => clickMealCheckbox(item, 'recipes', true)}
                    onKeyDown={() => clickMealCheckbox(item, 'recipes', true)}
                    aria-hidden="true"
                  >
                    <CheckCircleIcon width={15} height={15} className="my-auto text-lightGreen" />
                  </div>
                )}

                <div
                  onClick={() => clickMealCheckbox(item, 'recipes', true)}
                  onKeyDown={() => clickMealCheckbox(item, 'recipes', true)}
                  aria-hidden="true"
                  className="rounded-2xl bg-white bg-opacity-10 mb-5 ml-15 mr-10 py-10 mt-10 border-transparent border-2 hover:border-brownSemiDark flex-1 cursor-pointer"
                >
                  <div className="flex justify-between">
                    <div className="flex">
                      <div className={styles.trackingImage}>
                        <img
                          src={
                            item.imageUrl !== undefined && item.imageUrl?.length > 0
                              ? item.imageUrl
                              : theme?.logo !== undefined && userData?.role !== 2
                              ? theme.logo
                              : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
                          }
                          className="rounded-2xl w-50 h-50 object-cover"
                          alt="zz"
                        />
                      </div>
                      <div className="pl-10 pr-10">
                        <div className="font-semibold text-14">{item.name}</div>
                        <div className="font-extralight text-12 flex space-x-7">
                          <div className="text-brownSemiDark font-bold">{item.kcal_total} kcal</div>
                          <div className="rounded-full ml-1 w-1 h-1 border-2 border-white my-auto">
                            <></>
                          </div>
                          <div>
                            {item.amount} {item.piece}
                            {item.piece !== 'g' && item.piece !== 'ml' && item.portion_g !== undefined ? (
                              ` (${item.portion_g}g)`
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {dayItem?.[mealType!]?.deactivatedItems?.ingredients?.map((item: any, index: number) => (
              <div key={index} className="flex justify-between opacity-50">
                {checkBoxArray.findIndex((arrayItem: any) => arrayItem.id.toString() === item.id.toString()) === -1 ? (
                  <div
                    className="rounded-full ml-15 w-15 h-15 border-2 border-brownSemiDark my-auto cursor-pointer"
                    onClick={() => clickMealCheckbox(item, 'ingredients', true)}
                    onKeyDown={() => clickMealCheckbox(item, 'ingredients', true)}
                    aria-hidden="true"
                  >
                    <></>
                  </div>
                ) : (
                  <div
                    className="ml-15 my-auto cursor-pointer"
                    onClick={() => clickMealCheckbox(item, 'ingredients', true)}
                    onKeyDown={() => clickMealCheckbox(item, 'ingredients', true)}
                    aria-hidden="true"
                  >
                    <CheckCircleIcon width={15} height={15} className="my-auto text-lightGreen" />
                  </div>
                )}
                <div
                  onClick={() => clickMealCheckbox(item, 'ingredients', true)}
                  onKeyDown={() => clickMealCheckbox(item, 'ingredients', true)}
                  aria-hidden="true"
                  className="rounded-2xl bg-white bg-opacity-10 mb-5 ml-15 mr-10 py-10 mt-10 border-transparent border-2 hover:border-brownSemiDark flex-1 cursor-pointer"
                >
                  <div className="flex justify-between">
                    <div className="flex">
                      <div className={styles.trackingImage}>
                        <img
                          src={
                            item.imageUrl !== undefined && item.imageUrl?.length > 0
                              ? item.imageUrl
                              : theme?.logo !== undefined && userData?.role !== 2
                              ? theme.logo
                              : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
                          }
                          className="rounded-2xl w-50 h-50 object-cover"
                          alt="zz"
                        />
                      </div>
                      <div className="pl-10 pr-10">
                        <div className="font-semibold text-14">{item.name}</div>
                        <div className="font-extralight text-12 flex space-x-7">
                          <div className="text-brownSemiDark font-bold">{item.kcal_total} kcal</div>
                          <div className="rounded-full ml-1 w-1 h-1 border-2 border-white my-auto">
                            <></>
                          </div>
                          <div>
                            {item.amount} {item.piece}
                            {item.piece !== 'g' && item.piece !== 'ml' && item.portion_g !== undefined ? (
                              `(${item.portion_g}g)`
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {dayItem?.[mealType!].deactivatedItems?.manualItems?.map((item: any, index: number) => (
              <div key={index} className="flex justify-between opacity-50">
                {checkBoxArray.findIndex((arrayItem: any) => arrayItem.id.toString() === item.id.toString()) === -1 ? (
                  <div
                    className="rounded-full ml-15 w-15 h-15 border-2 border-brownSemiDark my-auto cursor-pointer"
                    onClick={() => clickMealCheckbox(item, 'manualItems', true)}
                    onKeyDown={() => clickMealCheckbox(item, 'manualItems', true)}
                    aria-hidden="true"
                  >
                    <></>
                  </div>
                ) : (
                  <div
                    className="ml-15 my-auto cursor-pointer"
                    onClick={() => clickMealCheckbox(item, 'manualItems', true)}
                    onKeyDown={() => clickMealCheckbox(item, 'manualItems', true)}
                    aria-hidden="true"
                  >
                    <CheckCircleIcon width={15} height={15} className="my-auto text-lightGreen" />
                  </div>
                )}
                <div
                  className="rounded-2xl bg-white bg-opacity-10 mb-5 ml-15 mr-10 py-10 mt-10 border-transparent border-2 hover:border-brownSemiDark flex-1 cursor-pointer"
                  onClick={() => clickMealCheckbox(item, 'manualItems', true)}
                  onKeyDown={() => clickMealCheckbox(item, 'manualItems', true)}
                  aria-hidden="true"
                >
                  <div className="flex justify-between">
                    <div className="flex">
                      <div className={styles.trackingImage}>
                        <img
                          src={
                            item.imageUrl !== undefined && item.imageUrl?.length > 0
                              ? item.imageUrl
                              : theme?.logo !== undefined && userData?.role !== 2
                              ? theme.logo
                              : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
                          }
                          className="rounded-2xl w-50 h-50 object-cover"
                          alt="zz"
                        />
                      </div>
                      <div className="pl-10 pr-10">
                        <div className="font-semibold text-14">{item.description}</div>
                        <div className="font-extralight text-12">
                          <span className="text-brownSemiDark font-bold pr-10">{item.kcal_total} kcal</span>
                          {item.amount} {item.piece}
                          {item.piece !== 'g' && item.piece !== 'ml' && item.portion_g !== undefined ? (
                            `(${item.portion_g}g)`
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardMealCardHorizontal;
