import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { XMarkIcon as XIcon, ShareIcon, ShoppingCartIcon, PlusIcon } from '@heroicons/react/24/outline';
import ButtonBack from 'components/ButtonBack';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import {
  updateTourToFirebase,
  isPurchaseListCollectionExists,
  savePurchaseListToFirebase,
  handleOpenClosePopups,
  shareToFirebase,
} from 'shared/functions/global';
import { MainContext } from 'providers/MainProvider';
import { AuthContext } from 'providers/AuthProvider';
import firebase from 'services/firebase';
import TransitionContainer from 'components/TransitionContainer';
import { useLocation } from 'react-router-dom';

import moment from 'moment';
import ReactLoading from 'react-loading';

import { toast } from 'react-toast';
import { defaultTextColor } from 'layouts/Theme';
import RecipeList from './components/recipeList';
import DateList from './components/dateList';

import styles from './style.module.scss';

const ShoppingList: React.FC = () => {
  const { t } = useTranslation();
  const { purchaseList } = useContext(MainContext);
  const authContext = useContext(AuthContext);
  const { userData, tenant, theme } = useContext(AuthContext);
  const location = useLocation<LocationState>();

  const tourRefRecipe = useRef<HTMLDivElement>(null);

  const sharePopupRecipeInputRef = useRef<HTMLSpanElement>(null);

  const [shareMailArray, setShareMailArray] = useState<any>([]);

  const initialPurchaseListObject = {
    date: moment().utc().unix(),
    recipes: [],
    ingredients: [],
  };

  const [shareLoading, setShareLoading] = useState(false);

  const triggerShare = async () => {
    setShareLoading(true);
    await shareToFirebase(
      'purchaseList',
      purchaseList !== undefined ? purchaseList[0] : undefined,
      currentShareMail,
      setSharePlanOverlayClass,
      userData,
      tenant,
      authContext.user?.uid
    );
    setShareLoading(false);
  };

  const [currentSection, setCurrentSection] = useState('start');
  const [currentDayLoaded, setCurrentDayLoaded] = useState(false);
  const [openPurchaseListOnLoad, setOpenPurchaseListOnLoad] = useState(false);
  const [purchaseListOnCurrentDayExists, setPurchaseListOnCurrentDayExists] = useState(false);

  const [sharePlanOverlayClass, setSharePlanOverlayClass] = useState('hidden');
  const [currentShareMail, setCurrentShareMail] = useState('');

  const handleSetShareMail = (mailString: string) => {
    if (sharePopupRecipeInputRef.current) {
      sharePopupRecipeInputRef.current.innerHTML = mailString;
      setCurrentShareMail(mailString);
    }
  };

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
    },
    scrollTo: true,
    classes: 'shepherd-theme-dark',
    useModalOverlay: true,
  };

  function TourButtonRecipe() {
    const tourRecipe = useContext(ShepherdTourContext);

    return (
      <div
        className="hidden"
        ref={tourRefRecipe}
        onClick={() => userData?.role === 2 && tourRecipe?.start()}
        onKeyDown={() => userData?.role === 2 && tourRecipe?.start()}
        aria-hidden="true"
      >
        <div>Test</div>
      </div>
    );
  }

  const changeShoppingListType = (listType: string) => {
    if (purchaseList !== undefined) {
      const thisNewObject = {
        ...initialPurchaseListObject,
        uid: purchaseList[0]?.uid,
        listType,
      };

      setOpenPurchaseListOnLoad(false);
      savePurchaseListToFirebase(userData, thisNewObject, authContext.user?.uid, tenant);
      setCurrentSection(listType);
    } else {
      toast.error('Es ist leider ein Fehler aufgetreten!');
    }
  };

  const jumpBack = () => {
    setCurrentSection('start');
  };

  const changePlanShareEmail = (event: any) => {
    const thisCurrentEmail = event.currentTarget.textContent;

    setCurrentShareMail(thisCurrentEmail.toLowerCase());
  };

  const openSharePopup = async () => {
    const getShareMails = await firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .doc(authContext.user?.uid)
      .collection('messages-emails')
      .limit(5)
      .get();

    if (getShareMails.size > 0) {
      const sharedMailArray = [] as any;

      const getSharedMailsDocuments = getShareMails.docs.map(doc => {
        sharedMailArray.push(doc.data());

        return 0;
      });

      setShareMailArray(sharedMailArray);
    }

    setCurrentShareMail('');
    setSharePlanOverlayClass('block');
  };

  const resetPurchaseList = () => {
    if (purchaseList !== undefined) {
      const thisNewObject = {
        recipes: [],
        ingredients: [],
        uid: purchaseList[0]?.uid,
      };

      setPurchaseListOnCurrentDayExists(false);
      setOpenPurchaseListOnLoad(false);
      setCurrentSection('start');
      savePurchaseListToFirebase(userData, thisNewObject, authContext.user?.uid, tenant);
    } else {
      toast.error('Es ist leider ein Fehler aufgetreten!');
    }
  };

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  useEffect(() => {
    if (purchaseList !== undefined && purchaseList?.length > 0) {
      if (moment().utc().format('YYYYMMDD') === moment.unix(purchaseList[0].date).format('YYYYMMDD')) {
        setCurrentSection(purchaseList[0].listType);
        setPurchaseListOnCurrentDayExists(true);
        setOpenPurchaseListOnLoad(true);
      }
      setCurrentDayLoaded(true);
    } else {
      const checkIfCollectionExists = isPurchaseListCollectionExists(authContext.user?.uid, tenant).then(result => {
        if (result === false) {
          setCurrentDayLoaded(true);
        }
        return result;
      });
    }
  }, [purchaseList]);

  useEffect(() => {
    if (location.state?.from !== undefined) {
      if (location.state.from === 'shared') {
        setCurrentSection('recipe');
      }
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (currentSection === 'recipe') {
        if (tourRefRecipe.current && (userData?.cookieBannerType !== 'initial' || userData?.role === 5)) {
          if (userData?.tour?.purchaseListRecipe === undefined) {
            tourRefRecipe.current.click();
          }
        }
      }
    }, 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSection]);

  return (
    <>
      <div className={sharePlanOverlayClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <ShareIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>

                <div className="text-xl my-auto font-light">Einkaufsliste teilen</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setSharePlanOverlayClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className={styles.editPictureIngridientPopupContent}>
                <div className="mx-20 pt-20 pb-40 font-extralight">
                  <div>
                    <div className="pt-20 font-extralight pb-1">
                      Bitte gebe die Email Adresse der Person ein mit der du diese Einkaufsliste teilen möchtest:
                    </div>
                    <div className="flex">
                      <div className="w-3/4">
                        <div className="font-light left text-xs border-opacity-30 w-full">
                          <span
                            className="appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                            role="textbox"
                            contentEditable
                            onFocus={setBodyClassForMobileSafari}
                            onBlur={removeBodyClassForMobileSafari}
                            ref={sharePopupRecipeInputRef}
                            suppressContentEditableWarning
                            onInput={e => changePlanShareEmail(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pt-20 font-extralight pb-1">
                      Die Einkaufsliste erscheint dann bei der Person im Profil unter "Nachrichten".
                    </div>
                    {shareMailArray.length > 0 && <div className="font-bold pt-10">Zuletzt:</div>}
                    {shareMailArray.map((item: any, index: number) => (
                      <div
                        key={index}
                        className="flex gap-10 pt-5 cursor-pointer"
                        onClick={() => handleSetShareMail(item.mail)}
                        aria-hidden="true"
                      >
                        <div>
                          <PlusIcon
                            width={25}
                            height={25}
                            className="text-accentColor mx-auto cursor-pointer rotate-180"
                          />
                        </div>
                        <div>{item.mail}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="pl-20 pr-10 pb-40">
                  <Button className="w-full" isPending={shareLoading} onClick={() => triggerShare()}>
                    Einkaufsliste teilen
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {currentDayLoaded ? (
        <TransitionContainer isShown={currentDayLoaded}>
          <div>
            {currentSection !== 'start' && (
              <div className="pt-60" onClick={() => jumpBack()} onKeyDown={() => jumpBack()} aria-hidden="true">
                <ButtonBack
                  text="Zurück zur Startseite"
                  className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                />
              </div>
            )}

            {currentSection === 'start' && (
              <div className="pt-50 md:pt-60">
                <div className="block md:flex">
                  <div>
                    <div className="w-full">
                      <img
                        className={styles.mainImage}
                        src="https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fgrocery_5.jpeg?alt=media&token=a27cc321-1575-4251-82d5-ca5919627000"
                        alt="Grocery List"
                      />
                    </div>
                  </div>
                  <div className="md:pl-40 pt-40">
                    <div className="font-bold text-4xl">Einkaufsliste</div>
                    <div className="font-extralight pt-20 w-full md:w-3/4 pb-60">
                      Du kannst dir bei den unteren Buttons aussuchen, wie du deine Einkaufsliste erstellen möchtest.
                    </div>
                    {purchaseListOnCurrentDayExists && purchaseList !== undefined && (
                      <div className="flex gap-20 flex-wrap pb-60">
                        <div className="relative">
                          <div>
                            <Button className="pr-40" onClick={() => setCurrentSection(purchaseList[0].listType)}>
                              Zur aktuellen Einkaufsliste
                            </Button>
                          </div>
                          <div className="absolute top-1/2 right-15 transform -translate-y-1/2">
                            <ShoppingCartIcon width={17} height={17} className="text-buttonTextColor" />
                          </div>
                        </div>
                      </div>
                    )}
                    <div>
                      <Button className="w-full md:w-3/4" onClick={() => changeShoppingListType('recipe')}>
                        Neue Einkaufsliste nach Rezepten erstellen
                      </Button>
                    </div>
                    <div className="pt-20 pb-40">
                      <Button className="w-full md:w-3/4" onClick={() => changeShoppingListType('date')}>
                        Neue Einkaufsliste nach Datum erstellen
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {currentSection === 'recipe' && (
              <>
                <ShepherdTour
                  steps={[
                    {
                      id: 'intro',
                      attachTo: { element: '#recipeList', on: 'top' },
                      buttons: [
                        {
                          classes: 'shepherd-button-secondary',
                          text: 'Beenden',
                          type: 'cancel',
                        },
                        {
                          classes: 'shepherd-button-primary',
                          text: 'Weiter',
                          type: 'next',
                        },
                      ],
                      classes: 'custom-class-name-1 custom-class-name-2',
                      highlightClass: 'highlight',
                      scrollTo: true,
                      cancelIcon: {
                        enabled: true,
                      },
                      title: 'List der Rezepte!',
                      text: [
                        '<p>In diesem Abschnitt werden dir die eingeplanten Rezepte der nächsten 7 Tage angezeigt.</p><p>Wenn du ein Rezept angeklickt hast, wird dieses automatisch zu deiner Einkaufsliste hinzugefügt.</p>Wenn du für alle Rezepte eines Tages einkaufen möchtest, kannst du auch den Button <strong>Alle hinzufügen</strong> anklicken.<p></p>',
                      ],
                      when: {
                        destroy() {
                          updateTourToFirebase(userData, 'purchaseListRecipe', authContext.user?.uid!, tenant);
                          setTimeout(() => {
                            document.getElementById('mainContainer')?.scrollIntoView();
                          }, 1);
                        },
                      },
                    },
                    {
                      id: 'intro',
                      attachTo: { element: '#toCartRecipe', on: 'top' },
                      buttons: [
                        {
                          classes: 'shepherd-button-primary',
                          text: 'Zurück',
                          type: 'back',
                        },
                        {
                          classes: 'shepherd-button-primary',
                          text: 'Beenden',
                          type: 'next',
                        },
                      ],
                      classes: 'custom-class-name-1 custom-class-name-2',
                      highlightClass: 'highlight',
                      scrollTo: true,
                      cancelIcon: {
                        enabled: true,
                      },
                      title: 'Einkaufliste öffnen!',
                      text: [
                        '<p>Wenn du alle Rezepte angeklickt hast, für die du einkaufen möchtest, öffnest du die Einkaufsliste über diesen Button.</p>',
                      ],
                    },
                  ]}
                  tourOptions={tourOptions}
                >
                  <TourButtonRecipe />
                </ShepherdTour>

                <div id="recipeList">
                  <RecipeList
                    resetFunction={resetPurchaseList}
                    openPurchaseListOnLoad={openPurchaseListOnLoad}
                    openSharePopup={openSharePopup}
                  />
                </div>
              </>
            )}
            {currentSection === 'date' && (
              <>
                <div>
                  <DateList resetFunction={resetPurchaseList} openSharePopup={openSharePopup} />
                </div>
              </>
            )}
          </div>
        </TransitionContainer>
      ) : (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className={styles.loading}>
            <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
          </div>
        </div>
      )}
    </>
  );
};

export default ShoppingList;
