import React, { useState, useContext } from 'react';
import { ChevronRightIcon, CalendarIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import InBodyLogo from 'assets/img/inbody.png';
import Button from 'components/Button';
import DatePicker from 'react-datepicker';
import { AuthContext } from 'providers/AuthProvider';
import InBodyOverview from './components/InBodyOverview';
import BodyDataGraph from './components/BodyDataGraph';

type Props = {
  userData?: any;
  openCreateNewAnalyzePopup: Function;
  userId: string;
};

const CoachingDashboardBodyData: React.FC<Props> = ({ userData, openCreateNewAnalyzePopup, userId }) => {
  const { tenantData } = useContext(AuthContext);

  const customItems = userData?.analyze?.items?.filter((element: any) => element.isCustom === true);

  const [startDate, setStartDate] = useState<any>(null);
  const [formattedStartDate, setFormattedStartDate] = useState('');

  const [showInbodyView, setShowInbodyView] = useState(false);

  const [startEndDate, setStartEndDate] = useState<any>(null);
  const [formattedStartEndDate, setFormattedStartEndDate] = useState('');

  const onChange = (dates: any) => {
    setStartDate(dates);

    const startDateFormatted = moment(dates).format('DD.MM.YYYY');
    setFormattedStartDate(startDateFormatted);
  };

  const onChangeEndDate = (dates: any) => {
    setStartEndDate(dates);

    const endDateFormatted = moment(dates).format('DD.MM.YYYY');
    setFormattedStartEndDate(endDateFormatted);
  };

  const clearDates = () => {
    setStartDate(null);
    setStartEndDate(null);

    setFormattedStartDate('');
    setFormattedStartEndDate('');
  };

  const CustomInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
    return (
      <label>
        <div className="flex w-full">
          <div className="flex-1">
            <input {...props} />
          </div>
          <div className="ml-10 my-auto">
            <CalendarIcon width={25} height={25} className="text-accentColor mx-auto cursor-pointer" />
          </div>
        </div>
      </label>
    );
  };

  return (
    <div>
      {showInbodyView ? (
        <InBodyOverview userData={userData} userId={userId} setShowInbodyView={setShowInbodyView} />
      ) : (
        <div className="pt-30">
          <div className="flex justify-between flex-wrap gap-10 gap-y-10 mb-20">
            {tenantData?.features?.inBody !== undefined && tenantData?.features?.inBody === true && (
              <div>
                <div
                  className="relative cursor-pointer border-textColor border-2 hover:border-accentColor px-40 xl:px-80 py-5 h-full w-full flex justify-center items-center text-center rounded-20 filter text-17 leading-20 font-bold bg-white text-textColor"
                  onClick={() => setShowInbodyView(true)}
                  aria-hidden
                >
                  <div>
                    <div className="pt-5">
                      <img className="mx-auto w-50 xl:w-100" src={InBodyLogo} alt="Logo" />
                    </div>
                    <div className="text-black text-12 xl:text-14 pt-5">Körperanalyse</div>
                  </div>
                </div>
              </div>
            )}
            <div>
              <div className="flex gap-10 xl:gap-30">
                <div>
                  <div className="font-extralight">Startdatum</div>
                  <DatePicker
                    className="w-150 appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                    locale="de"
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    dateFormat="dd.MM.yyyy"
                    withPortal
                    customInput={React.createElement(React.forwardRef(CustomInput))}
                    calendarStartDay={1}
                    onFocus={e => e.target.blur()}
                  />
                </div>
                <div>
                  <div className="font-extralight">Enddatum</div>
                  <DatePicker
                    className="w-150 appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                    locale="de"
                    selected={startEndDate}
                    onChange={onChangeEndDate}
                    startDate={startEndDate}
                    dateFormat="dd.MM.yyyy"
                    withPortal
                    customInput={React.createElement(React.forwardRef(CustomInput))}
                    calendarStartDay={1}
                    onFocus={e => e.target.blur()}
                  />
                </div>
              </div>
              {(startDate !== null || startEndDate !== null) && (
                <div
                  className="pt-5 font-extralight flex gap-5 hover:underline cursor-pointer"
                  onClick={() => clearDates()}
                  aria-hidden
                >
                  <div>Zurücksetzen</div>
                  <div className="my-auto">
                    <ChevronRightIcon width={20} height={20} className="text-accentColor mx-auto cursor-pointer" />
                  </div>
                </div>
              )}
            </div>

            <div className="my-auto">
              <Button className="py-5 xl:py-10 text-12 xl:text-14" onClick={() => openCreateNewAnalyzePopup()}>
                Eigene Analyse anlegen
              </Button>
            </div>
          </div>
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-30 gap-y-30">
            <BodyDataGraph
              userData={userData}
              analyzeType="weight"
              analyzePiece="KG"
              analyzeLabel="Gewicht"
              startDate={formattedStartDate}
              endDate={formattedStartEndDate}
              userId={userId}
            />
            <BodyDataGraph
              userData={userData}
              analyzeType="bodyFat"
              analyzePiece="%"
              analyzeLabel="Körperfettanteil"
              startDate={formattedStartDate}
              endDate={formattedStartEndDate}
              userId={userId}
            />
            <BodyDataGraph
              userData={userData}
              analyzeType="waist"
              analyzePiece="CM"
              analyzeLabel="Bauchumfang"
              startDate={formattedStartDate}
              endDate={formattedStartEndDate}
              userId={userId}
            />
            <BodyDataGraph
              userData={userData}
              analyzeType="muscleMass"
              analyzePiece="KG"
              analyzeLabel="Muskelmasse"
              startDate={formattedStartDate}
              endDate={formattedStartEndDate}
              userId={userId}
            />
            <BodyDataGraph
              userData={userData}
              analyzeType="bmi"
              analyzePiece="kg/m²"
              analyzeLabel="BMI"
              startDate={formattedStartDate}
              endDate={formattedStartEndDate}
              userId={userId}
            />
            <BodyDataGraph
              userData={userData}
              analyzeType="bloodSugar"
              analyzePiece="mg/dl"
              analyzeLabel="Blutzucker"
              startDate={formattedStartDate}
              endDate={formattedStartEndDate}
              userId={userId}
            />
          </div>
          {customItems?.length > 0 && (
            <div className="pt-30 font-bold">
              <div className="pb-10 text-20">Eigene Analysen</div>
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-30 gap-y-30">
                {customItems?.map((item: any, index: number) => (
                  <div key={index}>
                    <BodyDataGraph
                      userData={userData}
                      analyzeType={item.name}
                      analyzePiece={item.piece}
                      analyzeLabel={item.label}
                      startDate={formattedStartDate}
                      endDate={formattedStartEndDate}
                      userId={userId}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CoachingDashboardBodyData;
