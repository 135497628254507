import React from 'react';
import { useTranslation } from 'react-i18next';
import TransitionContainer from 'components/TransitionContainer';
import Headline from 'components/Headline';
import CoachingDashBoardDocuments from 'pages/CompanyMember/detail/components/CoachingDashBoardDocuments';

import styles from './styles.module.scss';

type Props = {
  isShown: boolean;
  title: string;
  goBack?: React.MouseEventHandler<HTMLButtonElement>;
};

const MyDocuments: React.FC<Props> = ({ isShown, title, goBack = () => {} }) => {
  const { t } = useTranslation();

  return (
    <TransitionContainer isShown={isShown}>
      <div className={styles.header}>
        <Headline level={1} className="mb-20">
          {t(title)}
        </Headline>
        <div className={styles.buttons}>
          <Headline level={4} displayBackBtn goBack={goBack}>
            {t('Return')}
          </Headline>
        </div>
      </div>
      <div>
        <CoachingDashBoardDocuments />
      </div>
    </TransitionContainer>
  );
};

export default MyDocuments;
