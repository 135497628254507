import React, { useContext, useState, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { ResponsiveContainer, ComposedChart, Line, Area, XAxis, YAxis, Tooltip } from 'recharts';
import { AuthContext } from 'providers/AuthProvider';
import ReactLoading from 'react-loading';
import firebase from 'services/firebase';
import { defaultAccentColor, defaultBgColor, defaultTextColor, defaultLightGrayDarker } from 'layouts/Theme';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styles from './styles.module.scss';

type Props = {
  analyzeLabel: string;
  startDate: string;
  endDate: string;
  userId: string;
};

const WaterGraph: React.FC<Props> = ({ analyzeLabel, startDate, endDate, userId }) => {
  const { t } = useTranslation();

  const { theme, tenant } = useContext(AuthContext);

  const [historyChevronClass, setHistoryChevronClass] = useState('text-accentColor');
  const [historyClass, setHistoryClass] = useState('hidden');

  const [dataLoading, setDataLoading] = useState(true);

  const [waterAverage, setWaterAverage] = useState(0);

  const [currentDataObject, setCurrentDataObject] = useState<any>([]);
  const [currentHistoryDataObject, setCurrentHistoryDataObject] = useState<any>([]);

  const CustomTooltip = ({ active, payload, label }: { active: any; label: any; payload: any }) => {
    if (active) {
      return (
        <div className="bg-lightGray text-textColor px-30 py-30">
          <div className="font-semibold">{label}</div>
          <div className="flex gap-5">
            <div className="text-accentColor font-bold text-30">{payload[0]?.value?.toString()} ml</div>
          </div>
        </div>
      );
    }

    return null;
  };

  // Toggle form of nutrition area
  const toggleHistory = (): void => {
    if (historyClass === 'hidden') {
      setHistoryClass('block');
      setHistoryChevronClass('text-accentColor transition duration-300 transform rotate-180');
    } else {
      setHistoryClass('hidden');
      setHistoryChevronClass('text-accentColor transition duration-300 transform rotate-360');
    }
  };

  const getWaterValues = async () => {
    const waterRef = await firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .doc(userId)
      .collection('waterCount')
      .orderBy('date', 'asc')
      .get();

    if (waterRef.size === 0) {
      setDataLoading(false);
      return;
    }

    const analyzeArray = [] as any;

    waterRef.docs.map(waterDoc => {
      const thisWaterData = waterDoc.data();

      const newObject = {
        name: moment(thisWaterData.date).format('DD.MM.YYYY'),
        value: thisWaterData.waterCount,
      };

      analyzeArray.push(newObject);

      return true;
    });

    let sum = 0;
    let count = 0;
    waterRef.forEach(doc => {
      sum += doc.data().waterCount;
      count += 1;
    });

    const getAverage = count > 0 ? Math.round(parseFloat((sum / count).toFixed(1))) : 0;

    setWaterAverage(getAverage);

    setCurrentDataObject(
      analyzeArray.filter(
        (item: any) =>
          moment(item.name, 'DD.MM.YYYY').unix() >= moment(startDate, 'DD.MM.YYYY').unix() &&
          moment(item.name, 'DD.MM.YYYY').unix() <= moment(endDate, 'DD.MM.YYYY').unix()
      )
    );
    setCurrentHistoryDataObject(
      analyzeArray.filter(
        (item: any) =>
          moment(item.name, 'DD.MM.YYYY').unix() >= moment(startDate, 'DD.MM.YYYY').unix() &&
          moment(item.name, 'DD.MM.YYYY').unix() <= moment(endDate, 'DD.MM.YYYY').unix()
      )
    );
    setDataLoading(false);
  };

  useEffect(() => {
    getWaterValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <div>
      {dataLoading ? (
        <div className="bg-lightGray rounded-3xl p-20 relative">
          <div className="pb-20">
            <div className="font-bold text-20 pb-40">{analyzeLabel}</div>
          </div>
          <div className={styles.loading}>
            <ReactLoading type="bars" width={20} height={20} color="#ffffff" className="mx-auto" />
          </div>
        </div>
      ) : (
        <div className="bg-lightGray rounded-3xl p-20 h-full">
          <div className="pb-20">
            <div className="flex justify-between">
              <div>
                <div className="font-bold text-20">{analyzeLabel}</div>
                {currentDataObject.length > 0 && <div className="opacity-50">Ø {waterAverage} ml</div>}
              </div>
              <div className="pl-20 text-14 opacity-50 font-extralight my-auto">
                {moment(startDate).format('DD[.]MM[.]YY')} - {moment(endDate).format('DD[.]MM[.]YY')}
              </div>
            </div>
          </div>
          {currentDataObject.length > 0 ? (
            <div>
              <ResponsiveContainer aspect={1.5}>
                <ComposedChart
                  width={800}
                  height={500}
                  data={currentDataObject}
                  margin={{ top: 25, right: 30, left: 0, bottom: 5 }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor={defaultLightGrayDarker} stopOpacity={0.4} />
                      <stop offset="95%" stopColor={defaultLightGrayDarker} stopOpacity={0.4} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey="name"
                    ticks={
                      currentDataObject.length > 1
                        ? [currentDataObject[0]?.name, currentDataObject[currentDataObject.length - 1]?.name]
                        : [currentDataObject[0]?.name]
                    }
                    dy={6}
                    tick={{ fontSize: 14, fontWeight: 'normal', fill: theme?.textColor ?? defaultTextColor }}
                  />
                  <YAxis
                    tickCount={6}
                    allowDecimals
                    domain={[0, 5]}
                    tick={{
                      transform: 'translate(-2, -2)',
                      fontSize: 14,
                      fontWeight: 'normal',
                      fill: theme?.textColor ?? defaultTextColor,
                    }}
                  />
                  <Tooltip content={<CustomTooltip active={undefined} payload={undefined} label={undefined} />} />

                  <Area
                    type="monotone"
                    stroke={theme?.textColor ?? defaultTextColor}
                    dataKey="value"
                    strokeWidth={0}
                    fill="url(#colorUv)"
                  />

                  <Line
                    type="monotone"
                    strokeLinecap="round"
                    strokeWidth={4}
                    dataKey="value"
                    stroke={theme?.accentColor ?? defaultAccentColor}
                    dot={{
                      stroke: theme?.textColor ?? defaultTextColor,
                      strokeWidth: 2,
                      fill: theme?.bgColor ?? defaultBgColor,
                      r: 6,
                    }}
                  />
                </ComposedChart>
              </ResponsiveContainer>
              <div
                className="flex gap-10 flex-wrap pt-10 pb-10 font-bold text-16 cursor-pointer"
                onClick={() => toggleHistory()}
                aria-hidden
              >
                <div className="">Verlauf</div>
                <ChevronDownIcon className={historyChevronClass} height={26} width={26} />
              </div>
              <div className={historyClass}>
                {currentHistoryDataObject.map((item: any, index: number) => (
                  <div
                    className="rounded-3xl h-full mb-20 px-20 py-10 bg-lightGrayDarker w-full desktop:w-1/2 relative"
                    key={index}
                  >
                    <div className="flex justify-between">
                      <div>{item.name}</div>
                      <div className="flex space-x-20">
                        <div className="font-extralight pr-20">{item.value?.toString().replaceAll('.', ',')} ml</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="font-extralight pb-20">Für diesen Zeitraum sind noch keine Daten vorhanden!</div>
          )}
        </div>
      )}
    </div>
  );
};

export default WaterGraph;
