import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import ReactLoading from 'react-loading';
import axios from 'axios';
import moment from 'moment';
import firebase from 'services/firebase';
import { EyeIcon, EyeSlashIcon as EyeOffIcon } from '@heroicons/react/24/solid';
import Headline from 'components/Headline';
import { AuthContext } from 'providers/AuthProvider';
import Button from 'components/Button';
import { defaultTextColor } from 'layouts/Theme';

import { toast } from 'react-toast';
import styles from './styles.module.scss';

type Props = {};

const LocationAdminDashboard: React.FC<Props> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const { theme, tenant, userData, setTenantData } = useContext(AuthContext);
  const [isInvitedMembersLoaded, setIsInvitedMembersLoaded] = useState(false);
  const [invitedMembersCount, setInvitedMembersCount] = useState(0);
  const [activeMembersCount, setActiveMembersCount] = useState(0);
  const [hideNotificationLoading, setHideNotificationLoading] = useState(false);
  const [jumpNotificationLoading, setJumpNotificationLoading] = useState(false);
  const [latestUserData, setLatestUserData] = useState<any>([]);
  const [notificationData, setNotificationData] = useState<any>([]);
  const [isLatestUsersLoaded, setIsLatestUsersLoaded] = useState(false);
  const [isActiveUsersLoaded, setIsActiveUsersLoaded] = useState(false);
  const [isNotificationsLoaded, setIsNotificationsLoaded] = useState(false);
  const [latestLoginUserData, setLatestLoginUserData] = useState<any>([]);
  const [isLatestLoginUsersLoaded, setIsLatestLoginUsersLoaded] = useState(false);
  const [isRegisterSevenDaysLoaded, setIsRegisterSevenDaysLoaded] = useState(false);
  const [registerSevenDaysCount, setRegisterSevenDaysCount] = useState(0);

  useEffect(() => {
    const unsubscribeInvitations = firebase
      .firestore()
      .collection('user-invitations')
      .where('tenant', '==', tenant)
      .where('location', '==', userData?.tenantLocation)
      .orderBy('created', 'desc')
      .onSnapshot(
        snapshot => {
          const inviteData: any = [];

          snapshot.forEach(doc => {
            inviteData.push({ ...doc.data(), uid: doc.id, type: 'invite' });
          });

          setInvitedMembersCount(snapshot.size);
          setIsInvitedMembersLoaded(true);
        },
        error => {
          setIsInvitedMembersLoaded(true);
          console.error('Error getting user-invitations for tenant: ', error);
        }
      );

    const getLatestUsers = firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .where('tenantLocation', '==', userData?.tenantLocation)
      .orderBy('created', 'desc')
      .limit(5)
      .onSnapshot(
        snapshot => {
          const inviteData: any = [];

          snapshot.forEach(doc => {
            inviteData.push({ ...doc.data(), uid: doc.id });
          });

          setLatestUserData(inviteData);
          setIsLatestUsersLoaded(true);
        },
        error => {
          setIsLatestUsersLoaded(true);
          console.error('Error getting user-invitations for tenant: ', error);
        }
      );

    const getActiveUsers = firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .where('tenantLocation', '==', userData?.tenantLocation)
      .onSnapshot(
        snapshot => {
          setActiveMembersCount(snapshot.size);
          setIsActiveUsersLoaded(true);
        },
        error => {
          setIsLatestUsersLoaded(true);
          console.error('Error getting user-invitations for tenant: ', error);
        }
      );

    const getLatestLoginUsers = firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .where('tenantLocation', '==', userData?.tenantLocation)
      .orderBy('lastLoginDate', 'desc')
      .limit(5)
      .onSnapshot(
        snapshot => {
          const inviteData: any = [];

          snapshot.forEach(doc => {
            inviteData.push({ ...doc.data(), uid: doc.id });
          });

          setLatestLoginUserData(inviteData);
          setIsLatestLoginUsersLoaded(true);
        },
        error => {
          setIsLatestLoginUsersLoaded(true);
          console.error('Error getting user-invitations for tenant: ', error);
        }
      );

    const momentSevenDays = moment().subtract(7, 'days').format('DD-MM-YYYY').split('-');
    const currentDay = momentSevenDays[0];
    const currentMonth = momentSevenDays[1];
    const currentYear = momentSevenDays[2];

    const getLast7DaysRegisterCount = firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .where('tenantLocation', '==', userData?.tenantLocation)
      .where('created', '>', new Date(`${currentYear}-${currentMonth}-${currentDay}`))
      .onSnapshot(
        snapshot => {
          const inviteData: any = [];

          snapshot.forEach(doc => {
            inviteData.push({ ...doc.data(), uid: doc.id, type: 'invite' });
          });

          setRegisterSevenDaysCount(snapshot.size);
          setIsRegisterSevenDaysLoaded(true);
        },
        error => {
          setIsRegisterSevenDaysLoaded(true);
          console.error('Error getting user-invitations for tenant: ', error);
        }
      );

    getTenantData();

    // eslint-disable-next-line
  }, []);

  const getTenantData = async () => {
    const tenantInfo = (await firebase.firestore().collection(`tenants`).doc(tenant).get()).data();
    setTenantData(tenantInfo as TenantInfo);
  };

  const handleImpersonate = async (user: any) => {
    const token = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/getUserToken`, {
      userId: user.uid,
    });

    const impersonateToken = token.data?.token;

    if (impersonateToken) {
      const currentUserId = firebase.auth().currentUser?.uid;
      const currentUserToken = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/getUserToken`, {
        userId: currentUserId,
      });

      if (currentUserToken) {
        localStorage.setItem('currentUserToken', currentUserToken.data?.token);
        if (currentUserId) {
          localStorage.setItem('currentUserTokenID', currentUserId);
        }
        await firebase
          .auth()
          .signInWithCustomToken(impersonateToken)
          .catch(error => {
            console.log(error);
            return toast.error('Es ist leider etwas schief gelaufen! Bitte versuche es erneut!');
          });
        window.location.reload();
      }
    } else {
      toast.error('Es ist leider etwas schief gelaufen. Bitte versuchen Sie es später erneut!');
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.buttons}>
            <Headline level={1}>{t('Dashboard')}</Headline>
          </div>
          <div className="flex flex-wrap justify-between font-extralight pt-20 opacity-50">
            <div>Hallo {userData?.fullName}</div>
            <div>{moment().format('dddd, DD.MM.YYYY')}</div>
          </div>
        </div>
        <div className="grid grid-cols-1 desktop:grid-cols-3 gap-20">
          <div className="bg-lightGray p-20 rounded-3xl">
            <label className="block text-18 font-regular opacity-50 mb-1">Registrierungen in den letzten 7 Tagen</label>
            {!isRegisterSevenDaysLoaded ? (
              <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
            ) : (
              <Headline level={3}>{registerSevenDaysCount}</Headline>
            )}
          </div>
          <div className="bg-lightGray p-20 rounded-3xl">
            <label className="block text-18 font-regular opacity-50 mb-1">Aktive Standort-Nutzer</label>
            {!isActiveUsersLoaded ? (
              <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
            ) : (
              <Headline level={3}>{activeMembersCount}</Headline>
            )}
          </div>
          <div className="bg-lightGray p-20 rounded-3xl">
            <label className="block text-18 font-regular opacity-50 mb-1">Eingeladene Standort-Nutzer</label>
            {!isInvitedMembersLoaded ? (
              <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
            ) : (
              <Headline level={3}>{invitedMembersCount}</Headline>
            )}
          </div>

          <div className="bg-lightGray p-20 rounded-3xl">
            <label className="block text-18 font-regular opacity-50 mb-1">Zuletzt eingeloggt</label>
            {!isLatestLoginUsersLoaded ? (
              <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
            ) : (
              <>
                {latestLoginUserData.map((item: any, index: number) => {
                  return (
                    <div key={index}>
                      <div className="flex pb-2 pt-2 w-full justify-between">
                        <div className="my-auto">
                          <div className="opacity-50 text-14">
                            {moment(item.lastLoginDate?.toDate()).format('DD[.]MM[.]YY')}
                          </div>

                          <Link
                            to={{
                              pathname: `/member/detail/${item?.uid ?? ''}/?from=location-dashboard`,
                            }}
                          >
                            <div>{item.fullName}</div>
                          </Link>
                        </div>
                        <div className="pr-10 my-auto">
                          {item.email === userData?.email ? (
                            <div className="bg-accentColor text-buttonTextColor rounded-3xl px-10 py-1 text-12">
                              Admin
                            </div>
                          ) : item.shareProfileType !== undefined && item.shareProfileType === 'share' ? (
                            <EyeIcon
                              width={25}
                              height={25}
                              className="text-accentColor mx-auto cursor-pointer"
                              onClick={() => handleImpersonate(item)}
                            />
                          ) : (
                            <EyeOffIcon width={25} height={25} className="text-accentColor mx-auto" />
                          )}
                        </div>
                      </div>
                      <hr className="border-textColor" />
                    </div>
                  );
                })}
                <div className="mt-20">
                  <Link to="/member">
                    <Button className="py-5 text-14">Alle Nutzer</Button>
                  </Link>
                </div>
              </>
            )}
          </div>
          <div className="bg-lightGray p-20 rounded-3xl">
            <label className="block text-18 font-regular opacity-50 mb-1">Neueste Nutzer</label>
            {!isLatestUsersLoaded ? (
              <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
            ) : (
              <>
                {latestUserData.map((item: any, index: number) => {
                  return (
                    <div key={index}>
                      <div className="flex pb-2 pt-2 w-full justify-between">
                        <div className="my-auto">
                          <div className="opacity-50 text-14">
                            {moment(item.created?.toDate()).format('DD[.]MM[.]YY')}
                          </div>
                          <div>{item.fullName}</div>
                        </div>
                        <div className="pr-10 my-auto">
                          {item.email === userData?.email ? (
                            <div className="bg-accentColor text-buttonTextColor rounded-3xl px-10 py-1 text-12">
                              Admin
                            </div>
                          ) : item.shareProfileType !== undefined && item.shareProfileType === 'share' ? (
                            <EyeIcon
                              width={25}
                              height={25}
                              className="text-accentColor mx-auto cursor-pointer"
                              onClick={() => handleImpersonate(item)}
                            />
                          ) : (
                            <EyeOffIcon width={25} height={25} className="text-accentColor mx-auto" />
                          )}
                        </div>
                      </div>
                      <hr className="border-textColor" />
                    </div>
                  );
                })}
                <div className="mt-20">
                  <Link to="/member">
                    <Button className="py-5 text-14">Alle Nutzer</Button>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationAdminDashboard;
