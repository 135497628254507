import React, { useState, useRef, useContext, useEffect, useMemo } from 'react';
import {
  TrashIcon,
  EllipsisVerticalIcon as DotsVerticalIcon,
  XMarkIcon as XIcon,
  DocumentDuplicateIcon,
  ArrowPathIcon as RefreshIcon,
  CogIcon,
  ArrowsRightLeftIcon as SwitchHorizontalIcon,
} from '@heroicons/react/24/outline';
import CustomUserInput from 'components/CustomUserInput';
import { MainContext } from 'providers/MainProvider';
import _ from 'lodash';
import { AuthContext } from 'providers/AuthProvider';
import ReactLoading from 'react-loading';
import moment from 'moment';
import CustomSelect from 'components/CustomSelect';
import { handleOpenClosePopups, scrollToElement, savePlanChangesToFirebase } from 'shared/functions/global';
import { useHistory } from 'react-router-dom';
import { intolerancesOption, formOfNutrition } from 'shared/constants/profile-wizard';
import { toast } from 'react-toast';
import { defaultTextColor } from 'layouts/Theme';

import styles from './styles.module.scss';

type Props = {
  itemObject?: any;
  type?: string;
  planState?: any;
  planStateValue?: any;
  dayId?: number;
  mealType?: any;
  moveRecipePopupOpener?: any;
  copyRecipePopupOpener?: any;
  changeRecipePopupOpener?: any;
  itemChangeTypeState?: any;
  itemChangeIdState?: any;
  scrollRef?: any;
  moveIngredientPopupClass?: any;
  setMealType?: any;
  setCurrentChangeItemId?: any;
  copyIngredientPopupClass?: any;
  openRecipePopupClass?: any;
  isLastObject?: boolean;
};

const EditMealItem: React.FC<Props> = ({
  itemObject,
  type,
  planState,
  planStateValue,
  dayId,
  mealType,
  moveRecipePopupOpener,
  copyRecipePopupOpener,
  changeRecipePopupOpener,
  itemChangeTypeState,
  itemChangeIdState,
  scrollRef,
  moveIngredientPopupClass,
  setMealType,
  setCurrentChangeItemId,
  copyIngredientPopupClass,
  openRecipePopupClass,
  isLastObject = false,
}) => {
  const [actionClass, setActionClass] = useState('hidden');
  const [piecePopupClass, setPiecePopupClass] = useState('hidden');
  const [piecePopupClassDesktop, setPiecePopupClassDesktop] = useState('hidden');
  const [currentUpdateType, setCurrentUpdateType] = useState<string>();
  const [currentUpdatePiece, setCurrentUpdatePiece] = useState('');
  const [recipePiece, setRecipePiece] = useState('Portion');

  const [isLoadingIngridientAmountChange, setIsLoadingIngridientAmountChange] = useState(false);
  const [isLoadingRecipeAmountChange, setIsLoadingRecipeAmountChange] = useState(false);

  const [currentTypedAmount, setCurrentTypedAmount] = useState(itemObject?.amount);
  const [currentRecipeAmountTyped, setCurrentRecipeAmountTyped] = useState(itemObject?.amount);

  const [currentRecipePortionValues, setCurrentRecipePortionValues] = useState<any>([
    { value: 'Portion', label: `Portion (${itemObject?.portion_g}g)` },
    { value: 'g', label: 'Gramm' },
  ]);

  const editIngridientRef = useRef<HTMLInputElement>(null);
  const editIngridientRefDesktop = useRef<HTMLInputElement>(null);
  const { ingredientList } = useContext(MainContext);
  const getIngridientData = useContext(MainContext).ingredientList?.find(
    item => item.name.toLowerCase() === itemObject.name.toLowerCase()
  ) as any;
  const { userData, tenant, theme } = useContext(AuthContext);
  const authContext = useContext(AuthContext);
  const { setTempRecipeObject } = useContext(MainContext);

  const recipeLength = planStateValue?.dayEntries.filter((item: any) => item.id === dayId)[0][mealType].recipes.length;
  const ingredientsLength = planStateValue?.dayEntries.filter((item: any) => item.id === dayId)[0][mealType].ingredients
    .length;
  const objectSum = parseFloat(recipeLength) + parseFloat(ingredientsLength);

  const history = useHistory();

  const currentDayItem = planStateValue?.dayEntries.filter((item: any) => item.id === dayId)[0];

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const wrapperRefDesktop = useRef(null);
  useOutsideAlerter(wrapperRefDesktop);

  let thisType = '';

  if (type === 'Rezept') {
    thisType = 'recipes';
  } else {
    thisType = 'ingredients';
  }

  function clickIngridientAmount(breakpoint: string) {
    if (breakpoint === 'desktop') {
      if (editIngridientRefDesktop.current) {
        editIngridientRefDesktop.current.select();
      }
    } else if (editIngridientRef.current) {
      editIngridientRef.current.select();
    }
  }

  const mapRecipePiece = () => {
    if (itemObject?.recipe_piece !== undefined) {
      const dropDownFilter = currentRecipePortionValues.filter(
        (st: any) => st.value === itemObject?.recipe_piece.toLowerCase()
      );

      if (dropDownFilter.length > 0) {
        return currentRecipePortionValues.filter((st: any) => st.value === itemObject?.recipe_piece.toLowerCase());
      }
      return currentRecipePortionValues[0];
    }
    return currentRecipePortionValues[0];
  };

  // Change general recipe piece
  const changeRecipePiece = (value: any) => {
    let thisPortionValue = '';
    if (itemObject?.recipe_portion) {
      thisPortionValue = itemObject.recipe_portion.toString();
    } else {
      thisPortionValue = '1';
    }

    setRecipePiece(value.value);
    editAmount(thisPortionValue, '', value.value);
  };

  function getPrimaryPieceValue(ingridientData: any, getThisIngridientData: any, thisPieceType: string) {
    if (thisPieceType === 'API') {
      let thisPieceValue = '';

      if (ingridientData.serving_data.serving && ingridientData.serving_data.serving instanceof Array) {
        ingridientData.serving_data.serving.map((serving: any, index: any) => {
          if (
            serving.measurement_description !== 'g' &&
            serving.measurement_description !== 'ml' &&
            serving.serving_description !== '100ml' &&
            serving.serving_description !== '100g' &&
            serving.is_default === '1' &&
            !serving.serving_description.includes('(')
          ) {
            thisPieceValue =
              (serving.serving_description.includes('1 ') &&
                serving.serving_description.substr(2, serving.serving_description.length)) ||
              `${serving.serving_description} `;

            if (
              serving.measurement_description !== 'g' &&
              serving.measurement_description !== 'ml' &&
              !serving.measurement_description.includes('g)') &&
              !serving.measurement_description.includes('ml)')
            ) {
              thisPieceValue = `${thisPieceValue} (${serving.metric_serving_amount.substr(
                0,
                serving.metric_serving_amount.indexOf('.')
              )} ${serving.metric_serving_unit})`;
            }
          }

          if (
            serving.measurement_description !== 'g' &&
            serving.measurement_description !== 'ml' &&
            serving.serving_description !== '100ml' &&
            serving.serving_description !== '100g' &&
            serving.is_default === '1' &&
            serving.serving_description.includes('(')
          ) {
            thisPieceValue =
              (serving.serving_description.includes('1 ') &&
                serving.serving_description.substr(2, serving.serving_description.indexOf('(') - 3)) ||
              `${serving.serving_description.substr(0, serving.serving_description.indexOf('(') - 3)} `;

            if (
              serving.measurement_description !== 'g' &&
              serving.measurement_description !== 'ml' &&
              !serving.measurement_description.includes('g)') &&
              !serving.measurement_description.includes('ml)')
            ) {
              thisPieceValue = `${thisPieceValue} (${serving.metric_serving_amount.substr(
                0,
                serving.metric_serving_amount.indexOf('.')
              )} ${serving.metric_serving_unit})`;
            }
          }

          return '';
        });

        return thisPieceValue;
      }

      if (
        ingridientData.serving_data.serving &&
        !(ingridientData.serving_data.serving instanceof Array) &&
        ingridientData.serving_data.serving.serving_description !== '100ml' &&
        ingridientData.serving_data.serving.serving_description !== '100g' &&
        !ingridientData.serving_data.serving.serving_description.includes('(')
      ) {
        thisPieceValue =
          (ingridientData.serving_data.serving.serving_description.includes('1 ') &&
            ingridientData.serving_data.serving.serving_description.substr(
              2,
              ingridientData.serving_data.serving.serving_description.length
            )) ||
          ingridientData.serving_data.serving.serving_description;

        if (
          ingridientData.serving_data.serving.measurement_description !== 'g' &&
          ingridientData.serving_data.serving.measurement_description !== 'ml' &&
          !ingridientData.serving_data.serving.measurement_description.includes('g)') &&
          !ingridientData.serving_data.serving.measurement_description.includes('ml)') &&
          ingridientData.serving_data.serving.metric_serving_amount
        ) {
          thisPieceValue = `${thisPieceValue} (${ingridientData.serving_data.serving.metric_serving_amount.substr(
            0,
            ingridientData.serving_data.serving.metric_serving_amount.indexOf('.')
          )} ${ingridientData.serving_data.serving.metric_serving_unit})`;
        }

        return thisPieceValue;
      }

      if (
        ingridientData.serving_data.serving &&
        !(ingridientData.serving_data.serving instanceof Array) &&
        ingridientData.serving_data.serving.serving_description !== '100ml' &&
        ingridientData.serving_data.serving.serving_description !== '100g' &&
        ingridientData.serving_data.serving.serving_description.includes('(')
      ) {
        thisPieceValue =
          (ingridientData.serving_data.serving.serving_description.includes('1 ') &&
            ingridientData.serving_data.serving.serving_description.substr(
              2,
              ingridientData.serving_data.serving.serving_description.indexOf('(') - 3
            )) ||
          ingridientData.serving_data.serving.serving_description.substr(
            0,
            ingridientData.serving_data.serving.serving_description.indexOf('(') - 3
          );

        if (
          ingridientData.serving_data.serving.measurement_description !== 'g' &&
          ingridientData.serving_data.serving.measurement_description !== 'ml' &&
          !ingridientData.serving_data.serving.measurement_description.includes('g)') &&
          !ingridientData.serving_data.serving.measurement_description.includes('ml)') &&
          ingridientData.serving_data.serving.metric_serving_amount
        ) {
          thisPieceValue = `${thisPieceValue} (${ingridientData.serving_data.serving.metric_serving_amount.substr(
            0,
            ingridientData.serving_data.serving.metric_serving_amount.indexOf('.')
          )} ${ingridientData.serving_data.serving.metric_serving_unit})`;
        }

        return thisPieceValue;
      }
    }

    if (ingridientData?.piece !== 'g' && ingridientData?.piece !== 'ml') {
      if (ingridientData?.default_piece !== undefined) {
        return `${ingridientData?.piece} (${ingridientData?.preselect_g}${ingridientData?.default_piece})`;
      }
      return `${ingridientData?.piece} (${ingridientData?.preselect_g}g)`;
    }
    if (getThisIngridientData?.default_piece !== undefined) {
      return `${getThisIngridientData?.preselect_type} (${getThisIngridientData?.preselect_g}${getThisIngridientData?.default_piece})`;
    }
    if (getThisIngridientData?.piece === 'ml') {
      return `${getThisIngridientData?.preselect_type} (${getThisIngridientData?.preselect_g}ml)`;
    }
    return `${getThisIngridientData?.preselect_type} (${getThisIngridientData?.preselect_g}g)`;
  }

  function getActionsMenuStyle() {
    if (recipeLength > 1 && type === 'Lebensmittel') {
      return 'top-40';
    }
    if (objectSum > 1 && isLastObject) {
      return '-top-100';
    }
    return 'top-40';
  }

  function editIngridientPiece(
    value: string | undefined,
    id: any,
    ingridientData: any,
    getIngridient: any,
    popupClass: any
  ) {
    const thisCurrentAmount = ingridientData.amount;
    let currentCalculatedKcal = 0;
    let currentCalculatedKH = 0;
    let currentCalculatedEW = 0;
    let currentCalculatedFT = 0;
    let ingridientKcal = 0;
    let ingridientKh = 0;
    let ingridientEw = 0;
    let ingridientFt = 0;
    let ingridientKcalNew = 0;
    let ingridientKhNew = 0;
    let ingridientEwNew = 0;
    let ingridientFtNew = 0;
    let currentKcal = 0;
    let currentKH = 0;
    let currentEW = 0;
    let currentFT = 0;

    let thisPiece = value;

    if (thisPiece?.includes('(')) {
      thisPiece = thisPiece.substr(0, thisPiece.indexOf('(') - 1);
    }

    if (ingridientData.piece !== 'g' && ingridientData.piece !== 'ml') {
      currentCalculatedKcal =
        ((parseFloat(ingridientData.amount) * parseFloat(ingridientData.preselect_g)) / 100) *
        parseFloat(getIngridient.kcal_100g);

      currentCalculatedKH =
        ((parseFloat(ingridientData.amount) * parseFloat(ingridientData.preselect_g)) / 100) *
        parseFloat(getIngridient.carbohydrates_100g);

      currentCalculatedEW =
        ((parseFloat(ingridientData.amount) * parseFloat(ingridientData.preselect_g)) / 100) *
        parseFloat(getIngridient.protein_100g);

      currentCalculatedFT =
        ((parseFloat(ingridientData.amount) * parseFloat(ingridientData.preselect_g)) / 100) *
        parseFloat(getIngridient.fat_100g);
    } else {
      currentCalculatedKcal = (parseFloat(ingridientData.amount) / 100) * parseFloat(getIngridient.kcal_100g);

      currentCalculatedKH = (parseFloat(ingridientData.amount) / 100) * parseFloat(getIngridient.carbohydrates_100g);

      currentCalculatedEW = (parseFloat(ingridientData.amount) / 100) * parseFloat(getIngridient.protein_100g);

      currentCalculatedFT = (parseFloat(ingridientData.amount) / 100) * parseFloat(getIngridient.fat_100g);
    }

    if (value !== 'g' && value !== 'ml') {
      ingridientKcal = Math.round(currentCalculatedKcal);
      ingridientKcalNew = Math.round(
        ((parseFloat(thisCurrentAmount) * parseFloat(ingridientData.preselect_g)) / 100) *
          parseFloat(getIngridient.kcal_100g)
      );

      ingridientKh = Math.round(currentCalculatedKH);
      ingridientKhNew = Math.round(
        ((parseFloat(thisCurrentAmount) * parseFloat(ingridientData.preselect_g)) / 100) *
          parseFloat(getIngridient.carbohydrates_100g)
      );

      ingridientEw = Math.round(currentCalculatedEW);
      ingridientEwNew = Math.round(
        ((parseFloat(thisCurrentAmount) * parseFloat(ingridientData.preselect_g)) / 100) *
          parseFloat(getIngridient.protein_100g)
      );

      ingridientFt = Math.round(currentCalculatedFT);
      ingridientFtNew = Math.round(
        ((parseFloat(thisCurrentAmount) * parseFloat(ingridientData.preselect_g)) / 100) *
          parseFloat(getIngridient.fat_100g)
      );
    } else {
      ingridientKcal = Math.round(currentCalculatedKcal);
      ingridientKcalNew = Math.round((parseFloat(getIngridient.kcal_100g) / 100) * parseFloat(thisCurrentAmount));

      ingridientKh = Math.round(currentCalculatedKH);
      ingridientKhNew = Math.round(
        (parseFloat(getIngridient.carbohydrates_100g) / 100) * parseFloat(thisCurrentAmount)
      );

      ingridientEw = Math.round(currentCalculatedEW);
      ingridientEwNew = Math.round((parseFloat(getIngridient.protein_100g) / 100) * parseFloat(thisCurrentAmount));

      ingridientFt = Math.round(currentCalculatedFT);
      ingridientFtNew = Math.round((parseFloat(getIngridient.fat_100g) / 100) * parseFloat(thisCurrentAmount));
    }

    currentKcal = Math.round(currentKcal - ingridientKcal + ingridientKcalNew);
    currentKH = Math.round(currentKH - ingridientKh + ingridientKhNew);
    currentEW = Math.round(currentEW - ingridientEw + ingridientEwNew);
    currentFT = Math.round(currentFT - ingridientFt + ingridientFtNew);

    const newColumns = {
      ...planStateValue,
      dayEntries: planStateValue?.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== dayId) return entries;
        return {
          ...entries,
          kcal_total:
            entries.kcal_total - ingridientKcal < 0
              ? 0 + ingridientKcalNew
              : parseFloat(entries.kcal_total) - ingridientKcal + ingridientKcalNew,
          carbohydrates_total:
            entries.carbohydrates_total - ingridientKh < 0
              ? 0 + ingridientKhNew
              : entries.carbohydrates_total - ingridientKh + ingridientKhNew,
          protein_total:
            entries.protein_total - ingridientEw < 0
              ? 0 + ingridientEwNew
              : entries.protein_total - ingridientEw + ingridientEwNew,
          fat_total:
            entries.fat_total - ingridientFt < 0
              ? 0 + ingridientFtNew
              : entries.fat_total - ingridientFt + ingridientFtNew,
          [mealType]: {
            ...entries[mealType],
            kcal_total:
              entries[mealType].kcal_total - ingridientKcal < 0
                ? 0 + ingridientKcalNew
                : parseFloat(entries[mealType].kcal_total) - ingridientKcal + ingridientKcalNew,
            carbohydrates_total:
              entries[mealType].carbohydrates_total - ingridientKh < 0
                ? 0 + ingridientKhNew
                : entries[mealType].carbohydrates_total - ingridientKh + ingridientKhNew,
            protein_total:
              entries[mealType].protein_total - ingridientEw < 0
                ? 0 + ingridientEwNew
                : entries[mealType].protein_total - ingridientEw + ingridientEwNew,
            fat_total:
              entries[mealType].fat_total - ingridientFt < 0
                ? 0 + ingridientFtNew
                : entries[mealType].fat_total - ingridientFt + ingridientFtNew,
            [thisType]: entries[mealType][thisType].map((item: any, index: any) => {
              if (parseFloat(item.id) !== parseFloat(itemObject.id)) return item;
              return {
                ...item,
                initial_piece: item.piece,
                piece: thisPiece,
                kcal_total:
                  item.kcal_total - ingridientKcal < 0
                    ? 0 + ingridientKcalNew
                    : parseFloat(item.kcal_total) - ingridientKcal + ingridientKcalNew,
                carbohydrates_total:
                  item.carbohydrates_total - ingridientKh < 0
                    ? 0 + ingridientKhNew
                    : item.carbohydrates_total - ingridientKh + ingridientKhNew,
                protein_total:
                  item.protein_total - ingridientEw < 0
                    ? 0 + ingridientEwNew
                    : item.protein_total - ingridientEw + ingridientEwNew,
                fat_total:
                  item.fat_total - ingridientFt < 0
                    ? 0 + ingridientFtNew
                    : item.fat_total - ingridientFt + ingridientFtNew,
              };
            }),
          },
        };
      }),
    };

    popupClass('hidden');
    savePlanChangesToFirebase(userData, newColumns, newColumns.uid, authContext.user?.uid, false, planState, tenant);
    // planState(newColumns);
  }

  // Called if a user will edit a API ingridient (recipe and ingridient list) and go to the change amount and piece modal. If the user there change the piece this function will calculate new values
  function editIngridientPieceAPI(
    value: string | undefined,
    id: any,
    ingridientData: any,
    popupClass: any,
    serving_data: any,
    serving_id: string
  ) {
    let ingridientKcal = 0;
    let ingridientKh = 0;
    let ingridientEw = 0;
    let ingridientFt = 0;
    let getIngridientServingData: any;
    let thisServingDataArray = [] as any;
    let currentKcal = 0;
    let currentKH = 0;
    let currentEW = 0;
    let currentFT = 0;

    thisServingDataArray = serving_data;

    if (serving_data instanceof Array) {
      if (value === 'g' || value === 'ml') {
        getIngridientServingData = [thisServingDataArray[0]];
      } else {
        getIngridientServingData = thisServingDataArray.filter((item: any) => item.serving_id === serving_id);
      }
    } else {
      getIngridientServingData = [serving_data];
    }

    let thisPiece = '';

    if (value === 'g' || value === 'ml') {
      thisPiece = value;
    } else if (getIngridientServingData[0].serving_description.includes('1 ')) {
      if (
        getIngridientServingData[0].serving_description.includes('g)') ||
        getIngridientServingData[0].serving_description.includes('ml)')
      ) {
        thisPiece = getIngridientServingData[0].serving_description.substr(
          2,
          getIngridientServingData[0].serving_description.indexOf(' (') - 2
        );
      } else {
        thisPiece = getIngridientServingData[0].serving_description.substr(
          2,
          getIngridientServingData[0].serving_description.length
        );
      }
    } else {
      thisPiece = getIngridientServingData[0].serving_description;
    }

    if (thisPiece.includes('(')) {
      thisPiece = thisPiece.substr(0, thisPiece.indexOf('(') - 1);
    }

    if (value !== 'g' && value !== 'ml') {
      ingridientKcal = Math.round(parseFloat(ingridientData.amount) * parseFloat(getIngridientServingData[0].calories));

      ingridientKh = Math.round(
        parseFloat(ingridientData.amount) * parseFloat(getIngridientServingData[0].carbohydrate)
      );

      ingridientEw = Math.round(parseFloat(ingridientData.amount) * parseFloat(getIngridientServingData[0].protein));

      ingridientFt = Math.round(parseFloat(ingridientData.amount) * parseFloat(getIngridientServingData[0].fat));
    } else if (getIngridientServingData[0].metric_serving_amount) {
      let thisMetricAmountValue = 1;

      if (
        parseFloat(
          getIngridientServingData[0].metric_serving_amount.substr(
            0,
            getIngridientServingData[0].metric_serving_amount.indexOf('.')
          )
        ) !== 0
      ) {
        thisMetricAmountValue = parseFloat(
          getIngridientServingData[0].metric_serving_amount.substr(
            0,
            getIngridientServingData[0].metric_serving_amount.indexOf('.')
          )
        );
      }

      ingridientKcal = Math.round(
        (parseFloat(ingridientData.amount) / thisMetricAmountValue) * parseFloat(getIngridientServingData[0].calories)
      );
      ingridientKh = Math.round(
        (parseFloat(ingridientData.amount) / thisMetricAmountValue) *
          parseFloat(getIngridientServingData[0].carbohydrate)
      );
      ingridientEw = Math.round(
        (parseFloat(ingridientData.amount) / thisMetricAmountValue) * parseFloat(getIngridientServingData[0].protein)
      );
      ingridientFt = Math.round(
        (parseFloat(ingridientData.amount) / thisMetricAmountValue) * parseFloat(getIngridientServingData[0].fat)
      );
    } else {
      ingridientKcal = Math.round(
        (parseFloat(ingridientData.amount) / 100) * parseFloat(getIngridientServingData[0].calories)
      );
      ingridientKh = Math.round(
        (parseFloat(ingridientData.amount) / 100) * parseFloat(getIngridientServingData[0].carbohydrate)
      );
      ingridientEw = Math.round(
        (parseFloat(ingridientData.amount) / 100) * parseFloat(getIngridientServingData[0].protein)
      );
      ingridientFt = Math.round(
        (parseFloat(ingridientData.amount) / 100) * parseFloat(getIngridientServingData[0].fat)
      );
    }

    currentKcal = Math.round(currentKcal - ingridientData.kcal_total + ingridientKcal);
    currentKH = Math.round(currentKH - ingridientData.carbohydrates_total + ingridientKh);
    currentEW = Math.round(currentEW - ingridientData.protein_total + ingridientEw);
    currentFT = Math.round(currentFT - ingridientData.fat_total + ingridientFt);

    const newColumns = {
      ...planStateValue,
      kcal_total: currentKcal,
      carbohydrates_total: currentKH,
      protein_total: currentEW,
      fat_total: currentFT,
      ingredients: planStateValue.ingredients.map((ingridients: any) => {
        if (parseFloat(ingridients.id) !== parseFloat(id)) return ingridients;
        return {
          ...ingridients,
          piece: thisPiece,
          kcal_total: ingridientKcal,
          carbohydrates_total: ingridientKh,
          protein_total: ingridientEw,
          fat_total: ingridientFt,
        };
      }),
    };

    popupClass('hidden');
    savePlanChangesToFirebase(userData, newColumns, newColumns.uid, authContext.user?.uid, false, planState, tenant);
    // planState(newColumns);
  }

  const getIngridientMacros = (ingridientData: any, getIngridient: any, thisIngridientType: string) => {
    if (ingridientData.piece !== 'g' && ingridientData.piece !== 'ml') {
      if (thisIngridientType === 'kcal' || thisIngridientType === 'kcal-initial') {
        if (getIngridient !== undefined) {
          if (thisIngridientType === 'kcal') {
            return Math.round(
              ((parseFloat(ingridientData.amount) * parseFloat(ingridientData.preselect_g)) / 100) *
                parseFloat(getIngridient.kcal_100g)
            );
          }
          return Math.round(
            ((parseFloat(ingridientData.initial_amount) * parseFloat(ingridientData.preselect_g)) / 100) *
              parseFloat(getIngridient.kcal_100g)
          );
        }
        if (ingridientData.serving_data) {
          return ingridientData.kcal_total;
        }
        return 'N/A';
      }

      if (thisIngridientType === 'carbohydrates' || thisIngridientType === 'carbohydrates-initial') {
        if (getIngridient !== undefined) {
          if (thisIngridientType === 'carbohydrates') {
            return Math.round(
              ((parseFloat(ingridientData.amount) * parseFloat(ingridientData.preselect_g)) / 100) *
                parseFloat(getIngridient.carbohydrates_100g)
            );
          }
          return Math.round(
            ((parseFloat(ingridientData.initial_amount) * parseFloat(ingridientData.preselect_g)) / 100) *
              parseFloat(getIngridient.carbohydrates_100g)
          );
        }
        if (ingridientData.serving_data) {
          return Math.round(ingridientData.carbohydrates_total);
        }
        return 'N/A';
      }

      if (thisIngridientType === 'protein' || thisIngridientType === 'protein-initial') {
        if (getIngridient !== undefined) {
          if (thisIngridientType === 'protein') {
            return Math.round(
              ((parseFloat(ingridientData.amount) * parseFloat(ingridientData.preselect_g)) / 100) *
                parseFloat(getIngridient.protein_100g)
            );
          }
          return Math.round(
            ((parseFloat(ingridientData.initial_amount) * parseFloat(ingridientData.preselect_g)) / 100) *
              parseFloat(getIngridient.protein_100g)
          );
        }
        if (ingridientData.serving_data) {
          return Math.round(ingridientData.protein_total);
        }
        return 'N/A';
      }

      if (thisIngridientType === 'fat' || thisIngridientType === 'fat-initial') {
        if (getIngridient !== undefined) {
          if (thisIngridientType === 'fat') {
            return Math.round(
              ((parseFloat(ingridientData.amount) * parseFloat(ingridientData.preselect_g)) / 100) *
                parseFloat(getIngridient.fat_100g)
            );
          }
          return Math.round(
            ((parseFloat(ingridientData.initial_amount) * parseFloat(ingridientData.preselect_g)) / 100) *
              parseFloat(getIngridient.fat_100g)
          );
        }
        if (ingridientData.serving_data) {
          return Math.round(ingridientData.fat_total);
        }
        return 'N/A';
      }
    }

    if (thisIngridientType === 'kcal' || thisIngridientType === 'kcal-initial') {
      if (getIngridient !== undefined) {
        if (thisIngridientType === 'kcal') {
          return Math.round((parseFloat(ingridientData.amount) / 100) * parseFloat(getIngridient.kcal_100g));
        }
        return Math.round((parseFloat(ingridientData.initial_amount) / 100) * parseFloat(getIngridient.kcal_100g));
      }
      if (ingridientData.serving_data) {
        return ingridientData.kcal_total;
      }
      return 'N/A';
    }

    if (thisIngridientType === 'carbohydrates' || thisIngridientType === 'carbohydrates-initial') {
      if (getIngridient !== undefined) {
        if (thisIngridientType === 'carbohydrates') {
          return Math.round((parseFloat(ingridientData.amount) / 100) * parseFloat(getIngridient.carbohydrates_100g));
        }
        return Math.round(
          (parseFloat(ingridientData.initial_amount) / 100) * parseFloat(getIngridient.carbohydrates_100g)
        );
      }
      if (ingridientData.serving_data) {
        return Math.round(ingridientData.carbohydrates_total);
      }
      return 'N/A';
    }

    if (thisIngridientType === 'protein' || thisIngridientType === 'protein-initial') {
      if (getIngridient !== undefined) {
        if (thisIngridientType === 'protein') {
          return Math.round((parseFloat(ingridientData.amount) / 100) * parseFloat(getIngridient.protein_100g));
        }
        return Math.round((parseFloat(ingridientData.initial_amount) / 100) * parseFloat(getIngridient.protein_100g));
      }
      if (ingridientData.serving_data) {
        return Math.round(ingridientData.protein_total);
      }
      return 'N/A';
    }

    if (thisIngridientType === 'fat' || thisIngridientType === 'fat-initial') {
      if (getIngridient !== undefined) {
        if (thisIngridientType === 'fat') {
          return Math.round((parseFloat(ingridientData.amount) / 100) * parseFloat(getIngridient.fat_100g));
        }
        return Math.round((parseFloat(ingridientData.initial_amount) / 100) * parseFloat(getIngridient.fat_100g));
      }
      if (ingridientData.serving_data) {
        return Math.round(ingridientData.fat_total);
      }
      return 'N/A';
    }

    return '0';
  };

  function deleteItem() {
    const newColumns = {
      ...planStateValue,
      dayEntries: planStateValue?.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== dayId) return entries;
        return {
          ...entries,
          kcal_total: entries.kcal_total - itemObject.kcal_total < 0 ? 0 : entries.kcal_total - itemObject.kcal_total,
          carbohydrates_total:
            entries.carbohydrates_total - itemObject.carbohydrates_total < 0
              ? 0
              : entries.carbohydrates_total - itemObject.carbohydrates_total,
          protein_total:
            entries.protein_total - itemObject.protein_total < 0 ? 0 : entries.protein_total - itemObject.protein_total,
          fat_total: entries.fat_total - itemObject.fat_total < 0 ? 0 : entries.fat_total - itemObject.fat_total,
          [mealType]: {
            ...entries[mealType],
            kcal_total:
              entries[mealType].kcal_total - itemObject.kcal_total < 0
                ? 0
                : entries[mealType].kcal_total - itemObject.kcal_total,
            carbohydrates_total:
              entries[mealType].carbohydrates_total - itemObject.carbohydrates_total < 0
                ? 0
                : entries[mealType].carbohydrates_total - itemObject.carbohydrates_total,
            protein_total:
              entries[mealType].protein_total - itemObject.protein_total < 0
                ? 0
                : entries[mealType].protein_total - itemObject.protein_total,
            fat_total:
              entries[mealType].fat_total - itemObject.fat_total < 0
                ? 0
                : entries[mealType].fat_total - itemObject.fat_total,
            [thisType]: [
              ...entries[mealType][thisType].filter((item: any) => parseFloat(item.id) !== parseFloat(itemObject.id)),
            ],
          },
        };
      }),
    };

    const checkIncompabilityArray = [] as any;
    const checkFormOfNutritionArray = [] as any;

    intolerancesOption.forEach(function (key) {
      newColumns.dayEntries.forEach(function (dayKey: any) {
        // Check recipes
        const checkBreakfastRecipes = dayKey.breakfast.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkLunchRecipes = dayKey.lunch.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkDinnerRecipes = dayKey.dinner.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkSnacksRecipes = dayKey.snacks.recipes?.filter((item: any) => item[key.toLowerCase()] === true);

        if (
          checkBreakfastRecipes.length > 0 ||
          checkLunchRecipes.length > 0 ||
          checkDinnerRecipes.length > 0 ||
          checkSnacksRecipes.length > 0
        ) {
          checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
        } else {
          // Check ingredients
          dayKey.breakfast.ingredients?.forEach(function (ingredientKey: any) {
            const getIngridient = ingredientList?.find(item => item.name === ingredientKey.name);

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.lunch.ingredients?.forEach(function (ingredientKey: any) {
            const getIngridient = ingredientList?.find(item => item.name === ingredientKey.name);

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.dinner.ingredients?.forEach(function (ingredientKey: any) {
            const getIngridient = ingredientList?.find(item => item.name === ingredientKey.name);

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.snacks.ingredients?.forEach(function (ingredientKey: any) {
            const getIngridient = ingredientList?.find(item => item.name === ingredientKey.name);

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });
        }
      });
    });

    formOfNutrition.forEach(function (key) {
      newColumns.dayEntries.forEach(function (dayKey: any) {
        // Check recipes
        const checkBreakfastRecipes = dayKey.breakfast.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkLunchRecipes = dayKey.lunch.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkDinnerRecipes = dayKey.dinner.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkSnacksRecipes = dayKey.snacks.recipes?.filter((item: any) => item[key.toLowerCase()] === true);

        if (
          checkBreakfastRecipes.length > 0 ||
          checkLunchRecipes.length > 0 ||
          checkDinnerRecipes.length > 0 ||
          checkSnacksRecipes.length > 0
        ) {
          checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
        } else {
          // Check ingredients
          dayKey.breakfast.ingredients?.forEach(function (ingredientKey: any) {
            const getIngridient = ingredientList?.find(item => item.name === ingredientKey.name);

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.lunch.ingredients?.forEach(function (ingredientKey: any) {
            const getIngridient = ingredientList?.find(item => item.name === ingredientKey.name);

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.dinner.ingredients?.forEach(function (ingredientKey: any) {
            const getIngridient = ingredientList?.find(item => item.name === ingredientKey.name);

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.snacks.ingredients?.forEach(function (ingredientKey: any) {
            const getIngridient = ingredientList?.find(item => item.name === ingredientKey.name);

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });
        }
      });
    });

    // Copy newColumns to variable because this needs to be updated in the following sections
    let thisRecipeData = { ...newColumns };

    // First set all to false
    for (let f = 0; f < intolerancesOption.length; f += 1) {
      thisRecipeData = { ...thisRecipeData, [intolerancesOption[f].toLowerCase()]: false };
    }
    for (let h = 0; h < formOfNutrition.length; h += 1) {
      thisRecipeData = { ...thisRecipeData, [formOfNutrition[h].toLowerCase()]: false };
    }

    // Set elements true if incompatibility exists
    for (let g = 0; g < checkIncompabilityArray.length; g += 1) {
      thisRecipeData = { ...thisRecipeData, [checkIncompabilityArray[g].name]: true };
    }

    // Set elements true if incompatibility exists
    for (let g = 0; g < checkFormOfNutritionArray.length; g += 1) {
      thisRecipeData = { ...thisRecipeData, [checkFormOfNutritionArray[g].name]: true };
    }

    scrollToElement(scrollRef);
    toast.success('Das Rezept wurde erfolgreich gelöscht!');
    savePlanChangesToFirebase(
      userData,
      thisRecipeData,
      thisRecipeData.uid,
      authContext.user?.uid,
      false,
      planState,
      tenant
    );
    // planState(thisRecipeData);
  }

  function changeAmountValue(event: any) {
    setCurrentTypedAmount(event.target.value);
    setCurrentRecipeAmountTyped(event.target.value);
    setIsLoadingIngridientAmountChange(true);
    debouncedChangeHandler(event);
  }

  const changeHandler = (event: any) => {
    editAmount(event, type, itemObject?.piece);
  };

  const debouncedChangeHandler = useMemo(
    () => _.debounce(changeHandler, 1000),
    [getIngridientData, planStateValue, ingredientList, itemObject]
  );

  function editAmount(event: any, updateType: string | undefined, thisRecipePiece: string) {
    let thisCurrentAmount = '';
    if (event.target?.value !== undefined) {
      thisCurrentAmount = event.target.value;
    } else {
      thisCurrentAmount = event;
    }

    let thisKcalGesamt = 0;
    let thisKHGesamt = 0;
    let thisEWGesamt = 0;
    let thisFTGesamt = 0;
    let isRecipeValid = true;

    // Replace Comma with Point for calculating results
    thisCurrentAmount = thisCurrentAmount.replace(',', '.');

    // Replace all text characters
    thisCurrentAmount = thisCurrentAmount.replace(/[^.0-9]/g, '');

    if (Number.isNaN(parseFloat(thisCurrentAmount))) {
      thisCurrentAmount = '';
    }

    setCurrentTypedAmount(thisCurrentAmount);

    if (thisCurrentAmount === '') {
      thisCurrentAmount = '1';
    }

    if (type === 'Rezept') {
      const newColumns = {
        ...planStateValue,
      };

      if (thisRecipePiece === 'Portion' && itemObject.initial_kcal_total === undefined) {
        thisKcalGesamt = parseFloat(itemObject.kcal_total) * parseFloat(thisCurrentAmount);
        thisKHGesamt = parseFloat(itemObject.carbohydrates_total) * parseFloat(thisCurrentAmount);
        thisEWGesamt = parseFloat(itemObject.protein_total) * parseFloat(thisCurrentAmount);
        thisFTGesamt = parseFloat(itemObject.fat_total) * parseFloat(thisCurrentAmount);
      } else if (thisRecipePiece === 'Portion' && itemObject.initial_kcal_total !== undefined) {
        thisKcalGesamt = parseFloat(itemObject.initial_kcal_total) * parseFloat(thisCurrentAmount);
        thisKHGesamt = parseFloat(itemObject.initial_carbohydrates_total) * parseFloat(thisCurrentAmount);
        thisEWGesamt = parseFloat(itemObject.initial_protein_total) * parseFloat(thisCurrentAmount);
        thisFTGesamt = parseFloat(itemObject.initial_fat_total) * parseFloat(thisCurrentAmount);
      } else if (thisRecipePiece === 'g' && itemObject.initial_kcal_total === undefined) {
        thisKcalGesamt =
          (parseFloat(itemObject.kcal_total) * parseFloat(thisCurrentAmount)) / parseFloat(itemObject.portion_g);
        thisKHGesamt =
          (parseFloat(itemObject.carbohydrates_total) * parseFloat(thisCurrentAmount)) /
          parseFloat(itemObject.portion_g);
        thisEWGesamt =
          (parseFloat(itemObject.protein_total) * parseFloat(thisCurrentAmount)) / parseFloat(itemObject.portion_g);
        thisFTGesamt =
          (parseFloat(itemObject.fat_total) * parseFloat(thisCurrentAmount)) / parseFloat(itemObject.portion_g);
      } else if (thisRecipePiece === 'g' && itemObject.initial_kcal_total !== undefined) {
        thisKcalGesamt =
          (parseFloat(itemObject.initial_kcal_total) * parseFloat(thisCurrentAmount)) /
          parseFloat(itemObject.portion_g);
        thisKHGesamt =
          (parseFloat(itemObject.initial_carbohydrates_total) * parseFloat(thisCurrentAmount)) /
          parseFloat(itemObject.portion_g);
        thisEWGesamt =
          (parseFloat(itemObject.initial_protein_total) * parseFloat(thisCurrentAmount)) /
          parseFloat(itemObject.portion_g);
        thisFTGesamt =
          (parseFloat(itemObject.fat_total) * parseFloat(thisCurrentAmount)) / parseFloat(itemObject.portion_g);
      } else {
        isRecipeValid = false;
      }

      thisKcalGesamt = Math.round(thisKcalGesamt);
      thisKHGesamt = Math.round(thisKHGesamt);
      thisEWGesamt = Math.round(thisEWGesamt);
      thisFTGesamt = Math.round(thisFTGesamt);

      const newColumnsPartTwo = {
        ...newColumns,
        dayEntries: newColumns?.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) !== dayId) return entries;
          return {
            ...entries,
            kcal_total: Math.round(
              entries.kcal_total - itemObject.kcal_total < 0
                ? 0 + thisKcalGesamt
                : entries.kcal_total - itemObject.kcal_total + thisKcalGesamt
            ),
            carbohydrates_total: Math.round(
              entries.carbohydrates_total - itemObject.carbohydrates_total < 0
                ? 0 + thisKHGesamt
                : entries.carbohydrates_total - itemObject.carbohydrates_total + thisKHGesamt
            ),
            protein_total: Math.round(
              entries.protein_total - itemObject.protein_total < 0
                ? 0 + thisEWGesamt
                : entries.protein_total - itemObject.protein_total + thisEWGesamt
            ),
            fat_total: Math.round(
              entries.fat_total - itemObject.fat_total < 0
                ? 0 + thisFTGesamt
                : entries.fat_total - itemObject.fat_total + thisFTGesamt
            ),
            [mealType]: {
              ...entries[mealType],
              kcal_total: Math.round(
                entries[mealType].kcal_total - itemObject.kcal_total < 0
                  ? 0 + thisKcalGesamt
                  : entries[mealType].kcal_total - itemObject.kcal_total + thisKcalGesamt
              ),
              carbohydrates_total: Math.round(
                entries[mealType].carbohydrates_total - itemObject.carbohydrates_total < 0
                  ? 0 + thisKHGesamt
                  : entries[mealType].carbohydrates_total - itemObject.carbohydrates_total + thisKHGesamt
              ),
              protein_total: Math.round(
                entries[mealType].protein_total - itemObject.protein_total < 0
                  ? 0 + thisEWGesamt
                  : entries[mealType].protein_total - itemObject.protein_total + thisEWGesamt
              ),
              fat_total: Math.round(
                entries[mealType].fat_total - itemObject.fat_total < 0
                  ? 0 + thisFTGesamt
                  : entries[mealType].fat_total - itemObject.fat_total + thisFTGesamt
              ),
              [thisType]: entries[mealType][thisType].map((item: any, index: any) => {
                if (parseFloat(item.id) !== parseFloat(itemObject.id)) return item;
                return {
                  ...item,
                  amount: thisCurrentAmount,
                  kcal_total: Math.round(thisKcalGesamt),
                  carbohydrates_total: Math.round(thisKHGesamt),
                  protein_total: Math.round(thisEWGesamt),
                  fat_total: Math.round(thisFTGesamt),
                  initial_kcal_total:
                    itemObject.initial_kcal_total !== undefined ? itemObject.initial_kcal_total : itemObject.kcal_total,
                  initial_carbohydrates_total:
                    itemObject.initial_carbohydrates_total !== undefined
                      ? itemObject.initial_carbohydrates_total
                      : itemObject.carbohydrates_total,
                  initial_protein_total:
                    itemObject.initial_protein_total !== undefined
                      ? itemObject.initial_protein_total
                      : itemObject.protein_total,
                  initial_fat_total:
                    itemObject.initial_fat_total !== undefined ? itemObject.initial_fat_total : itemObject.fat_total,
                  recipe_portion: parseFloat(thisCurrentAmount),
                  recipe_piece: thisRecipePiece,
                  piece: thisRecipePiece,
                };
              }),
            },
          };
        }),
      };

      if (isRecipeValid) {
        savePlanChangesToFirebase(
          userData,
          newColumnsPartTwo,
          newColumnsPartTwo.uid,
          authContext.user?.uid,
          false,
          planState,
          tenant
        );
        // planState(newColumnsPartTwo);
      } else {
        toast.error('In diesem Rezept wurde ein Fehler gefunden und konnte leider nicht gespeichert werden!');
      }

      setIsLoadingIngridientAmountChange(false);
    } else {
      let currentCalculatedKcal = 0;
      let currentCalculatedKH = 0;
      let currentCalculatedEW = 0;
      let currentCalculatedFT = 0;
      let ingridientKcal = 0;
      let ingridientKh = 0;
      let ingridientEw = 0;
      let ingridientFt = 0;
      let ingridientKcalNew = 0;
      let ingridientKhNew = 0;
      let ingridientEwNew = 0;
      let ingridientFtNew = 0;

      if (itemObject.piece !== 'g' && itemObject.piece !== 'ml') {
        currentCalculatedKcal =
          ((parseFloat(itemObject.amount) * parseFloat(itemObject.preselect_g)) / 100) *
          parseFloat(itemObject.kcal_100g);

        currentCalculatedKH =
          ((parseFloat(itemObject.amount) * parseFloat(itemObject.preselect_g)) / 100) *
          parseFloat(itemObject.carbohydrates_100g);

        currentCalculatedEW =
          ((parseFloat(itemObject.amount) * parseFloat(itemObject.preselect_g)) / 100) *
          parseFloat(itemObject.protein_100g);

        currentCalculatedFT =
          ((parseFloat(itemObject.amount) * parseFloat(itemObject.preselect_g)) / 100) *
          parseFloat(itemObject.fat_100g);
      } else {
        currentCalculatedKcal = (parseFloat(itemObject.amount) / 100) * parseFloat(itemObject.kcal_100g);

        currentCalculatedKH = (parseFloat(itemObject.amount) / 100) * parseFloat(itemObject.carbohydrates_100g);

        currentCalculatedEW = (parseFloat(itemObject.amount) / 100) * parseFloat(itemObject.protein_100g);

        currentCalculatedFT = (parseFloat(itemObject.amount) / 100) * parseFloat(itemObject.fat_100g);
      }

      if (itemObject.piece !== 'g' && itemObject.piece !== 'ml') {
        ingridientKcal = Math.round(currentCalculatedKcal);
        ingridientKcalNew = Math.round(
          ((parseFloat(thisCurrentAmount) * parseFloat(itemObject.preselect_g)) / 100) *
            parseFloat(itemObject.kcal_100g)
        );

        ingridientKh = Math.round(currentCalculatedKH);
        ingridientKhNew = Math.round(
          ((parseFloat(thisCurrentAmount) * parseFloat(itemObject.preselect_g)) / 100) *
            parseFloat(itemObject.carbohydrates_100g)
        );

        ingridientEw = Math.round(currentCalculatedEW);
        ingridientEwNew = Math.round(
          ((parseFloat(thisCurrentAmount) * parseFloat(itemObject.preselect_g)) / 100) *
            parseFloat(itemObject.protein_100g)
        );

        ingridientFt = Math.round(currentCalculatedFT);
        ingridientFtNew = Math.round(
          ((parseFloat(thisCurrentAmount) * parseFloat(itemObject.preselect_g)) / 100) * parseFloat(itemObject.fat_100g)
        );
      } else {
        ingridientKcal = Math.round(currentCalculatedKcal);
        ingridientKcalNew = Math.round((parseFloat(itemObject.kcal_100g) / 100) * parseFloat(thisCurrentAmount));

        ingridientKh = Math.round(currentCalculatedKH);
        ingridientKhNew = Math.round((parseFloat(itemObject.carbohydrates_100g) / 100) * parseFloat(thisCurrentAmount));

        ingridientEw = Math.round(currentCalculatedEW);
        ingridientEwNew = Math.round((parseFloat(itemObject.protein_100g) / 100) * parseFloat(thisCurrentAmount));

        ingridientFt = Math.round(currentCalculatedFT);
        ingridientFtNew = Math.round((parseFloat(itemObject.fat_100g) / 100) * parseFloat(thisCurrentAmount));
      }

      const newColumns = {
        ...planStateValue,
        dayEntries: planStateValue?.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) !== dayId) return entries;
          return {
            ...entries,
            kcal_total:
              entries.kcal_total - ingridientKcal < 0
                ? 0 + ingridientKcalNew
                : parseFloat(entries.kcal_total) - ingridientKcal + ingridientKcalNew,
            carbohydrates_total:
              entries.carbohydrates_total - ingridientKh < 0
                ? 0 + ingridientKhNew
                : entries.carbohydrates_total - ingridientKh + ingridientKhNew,
            protein_total:
              entries.protein_total - ingridientEw < 0
                ? 0 + ingridientEwNew
                : entries.protein_total - ingridientEw + ingridientEwNew,
            fat_total:
              entries.fat_total - ingridientFt < 0
                ? 0 + ingridientFtNew
                : entries.fat_total - ingridientFt + ingridientFtNew,
            [mealType]: {
              ...entries[mealType],
              kcal_total:
                entries[mealType].kcal_total - ingridientKcal < 0
                  ? 0 + ingridientKcalNew
                  : parseFloat(entries[mealType].kcal_total) - ingridientKcal + ingridientKcalNew,
              carbohydrates_total:
                entries[mealType].carbohydrates_total - ingridientKh < 0
                  ? 0 + ingridientKhNew
                  : entries[mealType].carbohydrates_total - ingridientKh + ingridientKhNew,
              protein_total:
                entries[mealType].protein_total - ingridientEw < 0
                  ? 0 + ingridientEwNew
                  : entries[mealType].protein_total - ingridientEw + ingridientEwNew,
              fat_total:
                entries[mealType].fat_total - ingridientFt < 0
                  ? 0 + ingridientFtNew
                  : entries[mealType].fat_total - ingridientFt + ingridientFtNew,
              [thisType]: entries[mealType][thisType].map((item: any, index: any) => {
                if (parseFloat(item.id) !== parseFloat(itemObject.id)) return item;
                return {
                  ...item,
                  amount: thisCurrentAmount,
                  kcal_total:
                    item.kcal_total - ingridientKcal < 0
                      ? 0 + ingridientKcalNew
                      : parseFloat(item.kcal_total) - ingridientKcal + ingridientKcalNew,
                  carbohydrates_total:
                    item.carbohydrates_total - ingridientKh < 0
                      ? 0 + ingridientKhNew
                      : item.carbohydrates_total - ingridientKh + ingridientKhNew,
                  protein_total:
                    item.protein_total - ingridientEw < 0
                      ? 0 + ingridientEwNew
                      : item.protein_total - ingridientEw + ingridientEwNew,
                  fat_total:
                    item.fat_total - ingridientFt < 0
                      ? 0 + ingridientFtNew
                      : item.fat_total - ingridientFt + ingridientFtNew,
                };
              }),
            },
          };
        }),
      };

      savePlanChangesToFirebase(userData, newColumns, newColumns.uid, authContext.user?.uid, false, planState, tenant);
      setIsLoadingIngridientAmountChange(false);
      // planState(newColumns);
    }
  }

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (piecePopupClass === 'block') {
            setPiecePopupClass('hidden');
          }
          if (piecePopupClassDesktop === 'block') {
            setPiecePopupClassDesktop('hidden');
          }
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  function jumpToRecipe() {
    setTempRecipeObject(itemObject);

    const thisDateString = moment(
      moment.unix(planStateValue?.dayEntries.filter((item: any) => item.id === dayId)[0]?.date.seconds).utc()
    )
      .locale('de')
      .startOf('day')
      .format('DD.MM.YY');

    history.push({
      pathname: `/nutrition/recipes/${itemObject.uid}`,
      state: {
        from: 'planEditChangeRecipe',
        planId: planStateValue.uid,
        planDayId: dayId,
        planDateString: thisDateString,
        mealLabel: mealType,
        recipeChangeId: itemObject.id,
        planKcalTotal: currentDayItem.kcal_total,
        planCarbohydratesTotal: currentDayItem.carbohydrates_total,
        planProteinTotal: currentDayItem.protein_total,
        planFatTotal: currentDayItem.fat_total,
        planCustomKcalTotal: currentDayItem?.custom_kcal_total
          ? currentDayItem?.custom_kcal_total
          : planStateValue?.custom_kcal_total
          ? planStateValue?.custom_kcal_total
          : undefined,
        planCustomCarbohydratesTotal: currentDayItem?.custom_carbohydrates_total
          ? currentDayItem?.custom_carbohydrates_total
          : planStateValue?.custom_carbohydrates_total
          ? planStateValue?.custom_carbohydrates_total
          : undefined,
        planCustomProteinTotal: currentDayItem?.custom_protein_total
          ? currentDayItem?.custom_protein_total
          : planStateValue?.custom_protein_total
          ? planStateValue?.custom_protein_total
          : undefined,
        planCustomFatTotal: currentDayItem?.custom_fat_total
          ? currentDayItem?.custom_fat_total
          : planStateValue?.custom_fat_total
          ? planStateValue?.custom_fat_total
          : undefined,
        planOriginalKcalTotal: itemObject.kcal_total,
        planOriginalCarbohydratesTotal: itemObject.carbohydrates_total,
        planOriginalProteinTotal: itemObject.protein_total,
        planOriginalFatTotal: itemObject.fat_total,
      },
    });
  }

  function changeItem(popupOpener: any) {
    handleOpenClosePopups(setActionClass, popupOpener, 'both');

    itemChangeIdState(itemObject.id);
    setCurrentChangeItemId(itemObject.id);
    itemChangeTypeState(thisType);
    setMealType(mealType);
  }

  useEffect(() => {
    setCurrentTypedAmount(itemObject.amount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemObject.amount]);

  return (
    <>
      <div className="rounded-2xl bg-lightGray bg-opacity-10 mb-20 border-textColor border-2 hover:border-accentColor">
        <div className="flex justify-between">
          {type === 'Rezept' ? (
            <div
              className="font-semibold text-17 pr-20 pb-20 xl:pb-40 pt-20 pl-20 w-auto xl:w-2/6 cursor-pointer  min-w-0"
              onClick={() => jumpToRecipe()}
              onMouseDown={() => jumpToRecipe()}
              aria-hidden="true"
            >
              <div className="flex">
                <div className="rounded-3xl bg-lightRed py-1 mb-1 px-10 font-bold text-10 ">{type}</div>
              </div>
              <div className="pt-1 break-words">{itemObject?.name}</div>
            </div>
          ) : (
            <div className="font-semibold text-17 pr-20 pt-20 w-auto xl:w-2/6 pl-20 pb-20 xl:pb-40">
              <div className="flex">
                <div className="rounded-3xl bg-lightGreen py-1 mb-1 px-10 font-bold text-10 text-textColor">{type}</div>
              </div>
              <div className="pt-1">{itemObject?.name}</div>
            </div>
          )}

          {/* Start Desktop Version */}
          <div className="hidden w-full xl:block my-auto pl-10">
            <div className="flex justify-between">
              <div className="flex w-1/4 space-x-2">
                <div className="my-auto ">
                  <CustomUserInput
                    lightBackground
                    thisValue={
                      currentRecipeAmountTyped === ''
                        ? ''
                        : currentTypedAmount.length > 0
                        ? currentTypedAmount
                        : itemObject?.amount
                    }
                    name="amount"
                    thisRef={editIngridientRefDesktop}
                    onClick={() => clickIngridientAmount('desktop')}
                    width={
                      currentTypedAmount.length > 0 ? currentTypedAmount.length : itemObject?.amount.toString().length
                    }
                    onChange={changeAmountValue}
                  />
                </div>
                <div className="my-auto">
                  {type === 'Rezept' ? (
                    <>
                      <div className="my-auto pl-5 hidden">
                        <div className="flex space-x-5">
                          <div>
                            {itemObject?.piece}{' '}
                            <span className="text-12 font-extralight">({itemObject.portion_g}g)</span>
                          </div>
                        </div>
                      </div>
                      {isLoadingIngridientAmountChange ? (
                        <ReactLoading
                          type="bars"
                          width={20}
                          height={20}
                          color={theme?.textColor ?? defaultTextColor}
                          className="mx-auto"
                        />
                      ) : (
                        <div className="w-180 pr-10">
                          {itemObject?.portion_g !== undefined && (
                            <CustomSelect
                              name="portionValues"
                              dropDownOption={currentRecipePortionValues}
                              value={mapRecipePiece()}
                              onChange={e => changeRecipePiece(e)}
                              isFullSize
                              isBorder
                            />
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="flex-1 relative">
                      {isLoadingIngridientAmountChange ? (
                        <ReactLoading
                          type="bars"
                          width={20}
                          height={20}
                          color={theme?.textColor ?? defaultTextColor}
                          className="mx-auto"
                        />
                      ) : (
                        <div className="border-opacity-30">
                          <span
                            id="piece"
                            className="appearance-none block text-center py-1 px-2 font-extralight rounded-md text-16 placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30 cursor-pointer"
                            role="textbox"
                            onClick={() =>
                              (/hidden/.exec(piecePopupClassDesktop) && setPiecePopupClassDesktop('hidden')) ||
                              setPiecePopupClassDesktop('block')
                            }
                            aria-hidden="true"
                          >
                            {itemObject.piece}
                          </span>
                        </div>
                      )}
                      <div className={piecePopupClassDesktop} ref={wrapperRefDesktop}>
                        <div className="absolute right-0 shadow-main bg-bgColor mt-5 z-20 text-left w-100 rounded-md">
                          {!itemObject.serving_data &&
                            getPrimaryPieceValue(itemObject, getIngridientData, 'static') !== 'g' && (
                              <>
                                <div
                                  className="pl-10 py-10 hover:bg-lightGray cursor-pointer"
                                  onClick={() =>
                                    editIngridientPiece(
                                      getPrimaryPieceValue(itemObject, getIngridientData, 'static'),
                                      itemObject.id,
                                      itemObject,
                                      getIngridientData,
                                      setPiecePopupClassDesktop
                                    )
                                  }
                                  aria-hidden="true"
                                >
                                  {getPrimaryPieceValue(itemObject, getIngridientData, 'static')}
                                </div>
                              </>
                            )}
                          {itemObject.serving_data &&
                            getPrimaryPieceValue(itemObject, getIngridientData, 'API') !== 'g' &&
                            !getPrimaryPieceValue(itemObject, getIngridientData, 'API').includes('undefined') && (
                              <>
                                <div
                                  className="pl-10 py-10 hover:bg-lightGray cursor-pointer"
                                  onClick={() =>
                                    editIngridientPiece(
                                      getPrimaryPieceValue(itemObject, getIngridientData, 'API'),
                                      itemObject.id,
                                      itemObject,
                                      getIngridientData,
                                      setPiecePopupClassDesktop
                                    )
                                  }
                                  aria-hidden="true"
                                >
                                  {getPrimaryPieceValue(itemObject, getIngridientData, 'API')}
                                </div>
                              </>
                            )}
                          {!itemObject.serving_data && (
                            <div
                              className="pl-10 py-10 hover:bg-lightGray cursor-pointer"
                              onClick={() =>
                                editIngridientPiece(
                                  itemObject.default_piece !== undefined
                                    ? itemObject.default_piece
                                    : itemObject.piece === 'ml'
                                    ? 'ml'
                                    : 'g',
                                  itemObject.id,
                                  itemObject,
                                  getIngridientData,
                                  setPiecePopupClassDesktop
                                )
                              }
                              aria-hidden="true"
                            >
                              {itemObject.default_piece !== undefined
                                ? itemObject.default_piece === 'g'
                                  ? 'Gramm'
                                  : itemObject.default_piece
                                : itemObject.piece === 'ml'
                                ? 'ml'
                                : 'Gramm'}
                            </div>
                          )}
                          {itemObject.serving_data && (
                            <div
                              className="pl-10 py-10 hover:bg-lightGray cursor-pointer"
                              onClick={() =>
                                editIngridientPiece(
                                  itemObject.default_piece !== undefined
                                    ? itemObject.default_piece
                                    : itemObject.piece === 'ml'
                                    ? 'ml'
                                    : 'g',
                                  itemObject.id,
                                  itemObject,
                                  getIngridientData,
                                  setPiecePopupClassDesktop
                                )
                              }
                              aria-hidden="true"
                            >
                              Gramm
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className={type === 'Rezept' ? 'mx-20 cursor-pointer' : 'mx-20'}
                onClick={() => type === 'Rezept' && jumpToRecipe()}
                onMouseDown={() => type === 'Rezept' && jumpToRecipe()}
                aria-hidden="true"
              >
                <div className="flex space-x-50 pt-10 justify-end">
                  <div>
                    <div className="pb-5">Kalorien</div>
                    <div className="font-extralight text-12">{Math.round(parseFloat(itemObject?.kcal_total))} kcal</div>
                  </div>
                  <div>
                    <div className="pb-5">Kohlenhydrate</div>
                    <div className="font-extralight text-12">
                      {Math.round(parseFloat(itemObject?.carbohydrates_total))}g
                    </div>
                  </div>
                  <div>
                    <div className="pb-5">Eiweiß</div>
                    <div className="font-extralight text-12">{Math.round(parseFloat(itemObject?.protein_total))}g</div>
                  </div>
                  <div>
                    <div className="pb-5">Fett</div>
                    <div className="font-extralight text-12">{Math.round(parseFloat(itemObject?.fat_total))}g</div>
                  </div>
                </div>
              </div>
              <div className="flex space-x-10 ml-10 mr-10">
                <div className="my-auto ml-1 justify-end z-10">
                  <TrashIcon
                    width={23}
                    height={23}
                    className="text-accentColor cursor-pointer"
                    onClick={() => deleteItem()}
                  />
                </div>
                {type === 'Lebensmittel' && (
                  <>
                    <div className="my-auto relative">
                      <div>
                        <DotsVerticalIcon
                          width={23}
                          height={23}
                          className="text-accentColor cursor-pointer"
                          onClick={() => setActionClass('block')}
                        />
                      </div>
                      <div
                        className={`absolute bg-lightGray shadow-2xl rounded-xl z-100 ${getActionsMenuStyle()} w-300 border border-accentColor transition-opacity duration-1000 ${actionClass} ${
                          styles.actionsMenu
                        }`}
                      >
                        <div className="pb-20 relative font-extralight">
                          <div className="flex justify-between px-20 pt-20 pb-20">
                            <div className="flex">
                              <div className="my-auto pr-10">
                                <CogIcon width={25} height={25} className="text-accentColor mx-auto" />
                              </div>
                              <div className="text-xl my-auto font-light">Actions-Menü</div>
                            </div>

                            <div>
                              <XIcon
                                width={25}
                                height={25}
                                className="text-accentColor cursor-pointer"
                                onClick={() => setActionClass('hidden')}
                              />
                            </div>
                          </div>
                          <div className="border-transparent border-2 hover:border-accentColor rounded-lg mx-20">
                            <div
                              className="flex justify-between border-b py-7 border-blackSemiDark cursor-pointer"
                              onClick={() => changeItem(copyIngredientPopupClass)}
                              onMouseDown={() => changeItem(copyIngredientPopupClass)}
                              aria-hidden="true"
                            >
                              <div className=" cursor-pointer px-7">Lebensmittel kopieren</div>
                              <div>
                                <DocumentDuplicateIcon width={25} height={25} className="text-accentColor mr-10" />
                              </div>
                            </div>
                          </div>
                          <div className="border-transparent border-2 hover:border-accentColor rounded-lg mx-20">
                            <div
                              className="flex justify-between py-7 cursor-pointer"
                              onClick={() => changeItem(moveIngredientPopupClass)}
                              onMouseDown={() => changeItem(moveIngredientPopupClass)}
                              aria-hidden="true"
                            >
                              <div className=" cursor-pointer px-7">Lebensmittel verschieben</div>
                              <div>
                                <SwitchHorizontalIcon width={25} height={25} className="text-accentColor mr-10" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {type === 'Rezept' && (
                  <>
                    <div className="my-auto relative">
                      <div>
                        <DotsVerticalIcon
                          width={23}
                          height={23}
                          className="text-accentColor cursor-pointer"
                          onClick={() => setActionClass('block')}
                        />
                      </div>
                      <div
                        className={`absolute bg-lightGray shadow-2xl rounded-xl z-100 ${getActionsMenuStyle()} w-300 border border-accentColor transition-opacity duration-1000 ${actionClass} ${
                          styles.actionsMenu
                        }`}
                      >
                        <div className="pb-20 relative font-extralight">
                          <div className="flex justify-between px-20 pt-20 pb-20">
                            <div className="flex">
                              <div className="my-auto pr-10">
                                <CogIcon width={25} height={25} className="text-accentColor mx-auto" />
                              </div>
                              <div className="text-xl my-auto font-light">Actions-Menü</div>
                            </div>

                            <div>
                              <XIcon
                                width={25}
                                height={25}
                                className="text-accentColor cursor-pointer"
                                onClick={() => setActionClass('hidden')}
                              />
                            </div>
                          </div>
                          <div className="border-transparent border-2 hover:border-accentColor rounded-lg mx-20">
                            <div
                              className="flex justify-between border-b py-7 border-blackSemiDark cursor-pointer"
                              onClick={() => changeItem(openRecipePopupClass)}
                              onMouseDown={() => changeItem(openRecipePopupClass)}
                              aria-hidden="true"
                            >
                              <div className=" cursor-pointer px-7">Rezept austauschen</div>
                              <div>
                                <RefreshIcon width={25} height={25} className="text-accentColor mr-10" />
                              </div>
                            </div>
                          </div>
                          {planStateValue?.manualPlanDateString === undefined && (
                            <div className="border-transparent border-2 hover:border-accentColor rounded-lg mx-20">
                              <div
                                className="flex justify-between border-b py-7 border-blackSemiDark cursor-pointer"
                                onClick={() => changeItem(copyRecipePopupOpener)}
                                onMouseDown={() => changeItem(copyRecipePopupOpener)}
                                aria-hidden="true"
                              >
                                <div className=" cursor-pointer px-7">Rezept kopieren</div>
                                <div>
                                  <DocumentDuplicateIcon width={25} height={25} className="text-accentColor mr-10" />
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="border-transparent border-2 hover:border-accentColor rounded-lg mx-20">
                            <div
                              className="flex justify-between py-7 cursor-pointer"
                              onClick={() => changeItem(moveRecipePopupOpener)}
                              onMouseDown={() => changeItem(moveRecipePopupOpener)}
                              aria-hidden="true"
                            >
                              <div className=" cursor-pointer px-7">Rezept verschieben</div>
                              <div>
                                <SwitchHorizontalIcon width={25} height={25} className="text-accentColor mr-10" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* End Desktop Version */}

          <div className="py-20 pr-20 xl:hidden">
            {type === 'Lebensmittel' && (
              <>
                <div className="flex justify-end">
                  <div className="relative">
                    <div className="flex space-x-10 justify-between pb-10">
                      <div className="my-auto ml-1 justify-end z-10">
                        <TrashIcon
                          width={23}
                          height={23}
                          className="text-accentColor cursor-pointer"
                          onClick={() => deleteItem()}
                        />
                      </div>
                      <div>
                        <DotsVerticalIcon
                          width={23}
                          height={23}
                          className="text-accentColor cursor-pointer"
                          onClick={() => setActionClass('block')}
                        />
                      </div>
                    </div>
                    <div
                      className={`absolute bg-lightGray shadow-2xl rounded-xl z-100 ${getActionsMenuStyle()} w-300 border border-accentColor transition-opacity duration-1000 ${actionClass} ${
                        styles.actionsMenu
                      }`}
                    >
                      <div className="pb-20 relative font-extralight">
                        <div className="flex justify-between px-20 pt-20 pb-20">
                          <div className="flex">
                            <div className="my-auto pr-10">
                              <CogIcon width={25} height={25} className="text-accentColor mx-auto" />
                            </div>
                            <div className="text-xl my-auto font-light">Actions-Menü</div>
                          </div>

                          <div>
                            <XIcon
                              width={25}
                              height={25}
                              className="text-accentColor cursor-pointer"
                              onClick={() => setActionClass('hidden')}
                            />
                          </div>
                        </div>
                        <div className="border-transparent border-2 hover:border-accentColor rounded-lg mx-20">
                          <div
                            className="flex justify-between border-b py-7 border-blackSemiDark cursor-pointer"
                            onClick={() => changeItem(copyIngredientPopupClass)}
                            onMouseDown={() => changeItem(copyIngredientPopupClass)}
                            aria-hidden="true"
                          >
                            <div className=" cursor-pointer px-7">Lebensmittel kopieren</div>
                            <div>
                              <DocumentDuplicateIcon width={25} height={25} className="text-accentColor mr-10" />
                            </div>
                          </div>
                        </div>
                        <div className="border-transparent border-2 hover:border-accentColor rounded-lg mx-20">
                          <div
                            className="flex justify-between py-7 cursor-pointer"
                            onClick={() => changeItem(moveIngredientPopupClass)}
                            onMouseDown={() => changeItem(moveIngredientPopupClass)}
                            aria-hidden="true"
                          >
                            <div className=" cursor-pointer px-7">Lebensmittel verschieben</div>
                            <div>
                              <SwitchHorizontalIcon width={25} height={25} className="text-accentColor mr-10" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {type === 'Rezept' && (
              <>
                <div className="flex justify-end">
                  <div className="relative">
                    <div className="flex space-x-10 justify-between pb-10">
                      <div className="my-auto ml-1 justify-end z-10">
                        <TrashIcon
                          width={23}
                          height={23}
                          className="text-accentColor cursor-pointer"
                          onClick={() => deleteItem()}
                        />
                      </div>
                      <div>
                        <DotsVerticalIcon
                          width={23}
                          height={23}
                          className="text-accentColor cursor-pointer"
                          onClick={() => setActionClass('block')}
                        />
                      </div>
                    </div>
                    <div
                      className={`absolute bg-lightGray shadow-2xl rounded-xl z-100 ${getActionsMenuStyle()} w-300 border border-accentColor transition-opacity duration-1000 ${actionClass} ${
                        styles.actionsMenu
                      }`}
                    >
                      <div className="pb-20 relative font-extralight">
                        <div className="flex justify-between px-20 pt-20 pb-20">
                          <div className="flex">
                            <div className="my-auto pr-10">
                              <CogIcon width={25} height={25} className="text-accentColor mx-auto" />
                            </div>
                            <div className="text-xl my-auto font-light">Actions-Menü</div>
                          </div>

                          <div>
                            <XIcon
                              width={25}
                              height={25}
                              className="text-accentColor cursor-pointer"
                              onClick={() => setActionClass('hidden')}
                            />
                          </div>
                        </div>
                        <div className="border-transparent border-2 hover:border-accentColor rounded-lg mx-20">
                          <div
                            className="flex justify-between border-b py-7 border-blackSemiDark cursor-pointer"
                            onClick={() => changeItem(openRecipePopupClass)}
                            onMouseDown={() => changeItem(openRecipePopupClass)}
                            aria-hidden="true"
                          >
                            <div className=" cursor-pointer px-7">Rezept austauschen</div>
                            <div>
                              <RefreshIcon width={25} height={25} className="text-accentColor mr-10" />
                            </div>
                          </div>
                        </div>
                        <div className="border-transparent border-2 hover:border-accentColor rounded-lg mx-20">
                          <div
                            className="flex justify-between border-b py-7 border-blackSemiDark cursor-pointer"
                            onClick={() => changeItem(copyRecipePopupOpener)}
                            onMouseDown={() => changeItem(copyRecipePopupOpener)}
                            aria-hidden="true"
                          >
                            <div className=" cursor-pointer px-7">Rezept kopieren</div>
                            <div>
                              <DocumentDuplicateIcon width={25} height={25} className="text-accentColor mr-10" />
                            </div>
                          </div>
                        </div>
                        <div className="border-transparent border-2 hover:border-accentColor rounded-lg mx-20">
                          <div
                            className="flex justify-between py-7 cursor-pointer"
                            onClick={() => changeItem(moveRecipePopupOpener)}
                            onMouseDown={() => changeItem(moveRecipePopupOpener)}
                            aria-hidden="true"
                          >
                            <div className=" cursor-pointer px-7">Rezept verschieben</div>
                            <div>
                              <SwitchHorizontalIcon width={25} height={25} className="text-accentColor mr-10" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="xl:hidden">
          <div className="pb-10 mx-20 w-200">
            <CustomUserInput
              lightBackground
              thisValue={
                currentRecipeAmountTyped === ''
                  ? ''
                  : currentTypedAmount.length > 0
                  ? currentTypedAmount
                  : itemObject?.amount
              }
              name="amount"
              thisRef={editIngridientRef}
              onClick={() => clickIngridientAmount('mobile')}
              onChange={changeAmountValue}
              customPaddingY={type === 'Rezept' ? '2' : '1'}
              textCenter
            />
          </div>
          <div className="my-auto mx-20 w-200 flex-1">
            {type === 'Rezept' ? (
              <>
                <div className="px-1 hidden">
                  <div>{itemObject?.piece}</div>
                  <div className="text-12 font-extralight">({itemObject.portion_g}g)</div>
                </div>
                <div>
                  {isLoadingIngridientAmountChange ? (
                    <ReactLoading
                      type="bars"
                      width={20}
                      height={20}
                      color={theme?.textColor ?? defaultTextColor}
                      className="mx-auto"
                    />
                  ) : (
                    <div className="border-textColor border rounded-md">
                      <CustomSelect
                        name="portionValues"
                        dropDownOption={currentRecipePortionValues}
                        value={mapRecipePiece()}
                        onChange={e => changeRecipePiece(e)}
                        className="text-12"
                        isFullSize
                      />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="flex-1 relative">
                {isLoadingIngridientAmountChange ? (
                  <ReactLoading
                    type="bars"
                    width={20}
                    height={20}
                    color={theme?.textColor ?? defaultTextColor}
                    className="mx-auto"
                  />
                ) : (
                  <div className="border-opacity-30">
                    <span
                      id="piece"
                      className="appearance-none block text-center py-1 px-2 font-extralight rounded-md text-16 placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30 cursor-pointer"
                      role="textbox"
                      onClick={() =>
                        (/hidden/.exec(piecePopupClass) && setPiecePopupClass('hidden')) || setPiecePopupClass('block')
                      }
                      aria-hidden="true"
                    >
                      {itemObject.piece}
                    </span>
                  </div>
                )}
                <div className={piecePopupClass} ref={wrapperRef}>
                  <div className="absolute right-0 shadow-main bg-bgColor mt-5 z-20 text-left w-100 rounded-md">
                    {!itemObject.serving_data && getPrimaryPieceValue(itemObject, getIngridientData, 'static') !== 'g' && (
                      <>
                        <div
                          className="pl-10 py-10 hover:bg-lightGray cursor-pointer"
                          onClick={() =>
                            editIngridientPiece(
                              getPrimaryPieceValue(itemObject, getIngridientData, 'static'),
                              itemObject.id,
                              itemObject,
                              getIngridientData,
                              setPiecePopupClass
                            )
                          }
                          aria-hidden="true"
                        >
                          {getPrimaryPieceValue(itemObject, getIngridientData, 'static')}
                        </div>
                      </>
                    )}
                    {itemObject.serving_data &&
                      getPrimaryPieceValue(itemObject, getIngridientData, 'API') !== 'g' &&
                      !getPrimaryPieceValue(itemObject, getIngridientData, 'API').includes('undefined') && (
                        <>
                          <div
                            className="pl-10 py-10 hover:bg-lightGray cursor-pointer"
                            onClick={() =>
                              editIngridientPiece(
                                getPrimaryPieceValue(itemObject, getIngridientData, 'API'),
                                itemObject.id,
                                itemObject,
                                getIngridientData,
                                setPiecePopupClass
                              )
                            }
                            aria-hidden="true"
                          >
                            {getPrimaryPieceValue(itemObject, getIngridientData, 'API')}
                          </div>
                        </>
                      )}
                    {!itemObject.serving_data && (
                      <div
                        className="pl-10 py-10 hover:bg-lightGray cursor-pointer"
                        onClick={() =>
                          editIngridientPiece(
                            itemObject.default_piece !== undefined
                              ? itemObject.default_piece
                              : itemObject.piece === 'ml'
                              ? 'ml'
                              : 'g',
                            itemObject.id,
                            itemObject,
                            getIngridientData,
                            setPiecePopupClass
                          )
                        }
                        aria-hidden="true"
                      >
                        {itemObject.default_piece !== undefined
                          ? itemObject.default_piece === 'g'
                            ? 'Gramm'
                            : itemObject.default_piece
                          : itemObject.piece === 'ml'
                          ? 'ml'
                          : 'Gramm'}
                      </div>
                    )}
                    {itemObject.serving_data && (
                      <div
                        className="pl-10 py-10 hover:bg-lightGray cursor-pointer"
                        onClick={() =>
                          editIngridientPiece(
                            itemObject.default_piece !== undefined
                              ? itemObject.default_piece
                              : itemObject.piece === 'ml'
                              ? 'ml'
                              : 'g',
                            itemObject.id,
                            itemObject,
                            getIngridientData,
                            setPiecePopupClass
                          )
                        }
                        aria-hidden="true"
                      >
                        Gramm
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="my-auto ml-1 justify-end z-10 hidden">
            <TrashIcon
              width={23}
              height={23}
              className="text-accentColor cursor-pointer"
              onClick={() => deleteItem()}
            />
          </div>
        </div>
        <div className="flex space-x-20 pl-20 pb-20 pt-10 xl:hidden">
          <div>
            <div className="font-extralight text-12">{Math.round(parseFloat(itemObject?.kcal_total))}</div>
            <div className="font-extralight text-8 text-center">KCAL</div>
          </div>
          <div>
            <div className="font-extralight text-12">{Math.round(parseFloat(itemObject?.carbohydrates_total))}g</div>
            <div className="font-extralight text-8 text-center">KH</div>
          </div>
          <div>
            <div className="font-extralight text-12">{Math.round(parseFloat(itemObject?.protein_total))}g</div>
            <div className="font-extralight text-8 text-center">EW</div>
          </div>
          <div>
            <div className="font-extralight text-12">{Math.round(parseFloat(itemObject?.fat_total))}g</div>
            <div className="font-extralight text-8 text-center">FETT</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMealItem;
