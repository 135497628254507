import React, { useContext, useEffect } from 'react';
import { AuthContext } from 'providers/AuthProvider';

type Props = {
  isActive: string;
  setIsActive: Function;
};

const CoachingDashboardTabs: React.FC<Props> = ({ isActive, setIsActive }) => {
  const { tenantData } = useContext(AuthContext);

  useEffect(() => {
    const getArea = localStorage.getItem('coachingDashBoardArea');

    localStorage.removeItem('coachingDashBoardPlanDayId');

    if (getArea !== null) {
      setIsActive(getArea);
      localStorage.removeItem('coachingDashBoardArea');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex justify-between flex-wrap gap-10 gap-y-10 pt-20">
      {isActive === 'anamnese' ? (
        <div className="rounded-3xl bg-accentColor px-40 py-10 cursor-pointer">
          <div className="text-buttonTextColor">Anamnese</div>
        </div>
      ) : (
        <div
          className="rounded-3xl bg-lightGray px-40 py-10 cursor-pointer"
          onClick={() => setIsActive('anamnese')}
          aria-hidden
        >
          <div>Anamnese</div>
        </div>
      )}

      {tenantData?.features?.surveys === undefined || tenantData?.features?.surveys === false ? (
        <></>
      ) : isActive === 'checkIn' ? (
        <div className="rounded-3xl bg-accentColor px-40 py-10 cursor-pointer">
          <div className="text-buttonTextColor">Umfragen</div>
        </div>
      ) : (
        <div
          className="rounded-3xl bg-lightGray px-40 py-10 cursor-pointer"
          onClick={() => setIsActive('checkIn')}
          aria-hidden
        >
          <div>Umfragen</div>
        </div>
      )}

      {isActive === 'plan' ? (
        <div className="rounded-3xl bg-accentColor px-40 py-10 cursor-pointer">
          <div className="text-buttonTextColor">Ernährungsplan</div>
        </div>
      ) : (
        <div
          className="rounded-3xl bg-lightGray px-40 py-10 cursor-pointer"
          onClick={() => setIsActive('plan')}
          aria-hidden
        >
          <div>Ernährungsplan</div>
        </div>
      )}

      {isActive === 'activities' ? (
        <div className="rounded-3xl bg-accentColor px-40 py-10 cursor-pointer">
          <div className="text-buttonTextColor">Analyse</div>
        </div>
      ) : (
        <div
          className="rounded-3xl bg-lightGray px-40 py-10 cursor-pointer"
          onClick={() => setIsActive('activities')}
          aria-hidden
        >
          <div>Analyse</div>
        </div>
      )}

      {isActive === 'bodyData' ? (
        <div className="rounded-3xl bg-accentColor px-40 py-10 cursor-pointer">
          <div className="text-buttonTextColor">Körperdaten</div>
        </div>
      ) : (
        <div
          className="rounded-3xl bg-lightGray px-40 py-10 cursor-pointer"
          onClick={() => setIsActive('bodyData')}
          aria-hidden
        >
          <div>Körperdaten</div>
        </div>
      )}
    </div>
  );
};

export default CoachingDashboardTabs;
