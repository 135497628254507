import React, { useState, useMemo, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MainContext } from 'providers/MainProvider';
import { AuthContext } from 'providers/AuthProvider';
import ButtonGroup from 'components/ButtonGroup';
import SwitchButton from 'components/SwitchButton';
import CustomSelect from 'components/CustomSelect';
import Button from 'components/Button';
import ButtonBack from 'components/ButtonBack';
import TransitionContainer from 'components/TransitionContainer';
import ReactLoading from 'react-loading';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { formOfNutrition, intolerancesOption, eatingHabitsAlghorithmus } from 'shared/constants/profile-wizard';
import {
  ChevronDownIcon,
  XMarkIcon as XIcon,
  ArrowPathIcon as RefreshIcon,
  ExclamationTriangleIcon as ExclamationIcon,
} from '@heroicons/react/24/outline';
import { difficultyGroup } from 'shared/constants/filter';
import { toast } from 'react-toast';
import { handleOpenClosePopups, savePlanChangesToFirebase, scrollToTop } from 'shared/functions/global';
import { OptionType } from 'react-switch-selector/dist/SwitchSelector';
import { defaultTextColor } from 'layouts/Theme';
import styles from './style.module.scss';

type Props = {
  currentDayItem?: any;
  currentPlan?: any;
  currentDayId?: number;
  setCurrentPlan?: any;
  generateDayPopupClass?: string;
  setGenerateDayPopupClass?: any;
  currentMealType?: any;
  planGenerateType?: string;
  isNewPlan?: boolean;
  savePlanState?: any;
  planSettings?: any;
  setVisibleIncompabilityPopup?: Function;
};

const GenerateAlghorithmus: React.FC<Props> = ({
  currentDayItem,
  currentPlan,
  currentDayId,
  setCurrentPlan,
  generateDayPopupClass = 'hidden',
  setGenerateDayPopupClass,
  currentMealType,
  planGenerateType = 'day',
  isNewPlan = false,
  savePlanState,
  planSettings,
  setVisibleIncompabilityPopup,
}) => {
  const { t } = useTranslation();

  const authContext = useContext(AuthContext);
  const { userData, tenant, theme } = useContext(AuthContext);

  const { ingredientList, recipesList, favoritesRecipesList } = useContext(MainContext);

  const selectNotIncludeRef = React.useRef() as any;
  const generatePlanPopupRef = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [checkSomeDayIsNotValid, setCheckSomeDayIsNotValid] = useState(false);

  const [planGenerateSettingsUseFavorites, setPlanGenerateSettingsUseFavorites] = useState(false);
  const [difficultyClass, setDifficultyClass] = useState('hidden');
  const [difficultyChevronClass, setDifficultyChevronClass] = useState('text-accentColor');
  const [formOfNutritionClass, setFormOfNutritionClass] = useState('hidden');
  const [formOfNutritionChevronClass, setFormOfNutritionChevronClass] = useState('text-accentColor');
  const [incompatibilitesClass, setIncompatibilitesClass] = useState('hidden');
  const [incompatibilitesChevronClass, setIncompatibilitesChevronClass] = useState('text-accentColor');
  const [eatingHabitsClass, setEatingHabitsClass] = useState('hidden');
  const [generalContentClass, setGeneralContentClass] = useState('block');
  const [eatingHabitsChevronClass, setEatingHabitsChevronClass] = useState('text-accentColor');
  const [ingredientsNotIncludeClass, setIngredientsNotIncludeClass] = useState('hidden');
  const [ingredientsNotIncludeChevronClass, setIngredientsNotIncludeChevronClass] = useState('text-accentColor');
  const ingredientsItems: OptionType[] = useMemo(
    () => ingredientList?.map(ingredient => ({ value: ingredient.name?.toLowerCase(), label: ingredient.name })) ?? [],
    [ingredientList]
  );
  const [initialNutrition, changeNutrition] = useState<string[]>([]);
  const [initialDifficulty, changeDifficulty] = useState<string[]>([]);
  const [initialIntolerances, changeIncompatibilities] = useState<string[]>([]);
  const [initialEatingHabits, changeEatingHabits] = useState<string[]>([]);
  const [ingredientsNotIncludeFilter, changeIngredientNotInclude] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialIngredientsNotIncludeFilter, setInitialIngredientsNotIncludeFilter] = useState<any[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [notificationPopupClass, setNotificationPopupClass] = useState('hidden');
  const [isOtherMealsComplete, setIsOtherMealsComplete] = useState(false);
  const [currentRecipeList, setCurrentRecipeList] = useState<any[]>([]);

  const [currentKcalSum, setCurrentKcalSum] = useState(0);
  const [currentCarbohydratesSum, setCurrentCarbohydratesSum] = useState(0);
  const [currentProteinSum, setCurrentProteinSum] = useState(0);
  const [currentFatSum, setCurrentFatSum] = useState(0);
  const [currentUpdateMeal, setCurrentUpdateMeal] = useState('');

  // const colourOptions: any = [
  //   { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
  //   { value: 'blue', label: 'Blue', color: '#0052CC', disabled: true },
  //   { value: 'purple', label: 'Purple', color: '#5243AA' },
  //   { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
  //   { value: 'orange', label: 'Orange', color: '#FF8B00' },
  //   { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  //   { value: 'green', label: 'Green', color: '#36B37E' },
  //   { value: 'forest', label: 'Forest', color: '#00875A' },
  //   { value: 'slate', label: 'Slate', color: '#253858' },
  //   { value: 'silver', label: 'Silver', color: '#666666' },
  // ];

  const setMyFavorites = (status: boolean): void => {
    setPlanGenerateSettingsUseFavorites(status);
  };

  // Toggle form of nutrition area
  const toggleDifficultyLevel = (): void => {
    if (difficultyClass === 'hidden') {
      setDifficultyClass('block');
      setDifficultyChevronClass('text-accentColor transition duration-300 transform rotate-180');
    } else {
      setDifficultyClass('hidden');
      setDifficultyChevronClass('text-accentColor transition duration-300 transform rotate-360');
    }
  };

  // Toggle form of nutrition area
  const toggleFormOfNutrition = (): void => {
    if (formOfNutritionClass === 'hidden') {
      setFormOfNutritionClass('block');
      setFormOfNutritionChevronClass('text-accentColor transition duration-300 transform rotate-180');
    } else {
      setFormOfNutritionClass('hidden');
      setFormOfNutritionChevronClass('text-accentColor transition duration-300 transform rotate-360');
    }
  };

  const changeDifficultyValue = (value: string[] | string) => {
    changeDifficulty(value as string[]);
  };

  const changeNutritionValue = (value: string[] | string) => {
    changeNutrition(value as string[]);
  };

  const changeIncompatibilites = (value: string[] | string) => {
    changeIncompatibilities(value as string[]);
  };

  const changeEatingHabitsValue = (value: string[] | string) => {
    changeEatingHabits(value as string[]);
  };

  // Toggle incompatibilites area
  const toggleIncompatibilites = (): void => {
    if (incompatibilitesClass === 'hidden') {
      setIncompatibilitesClass('block');
      setIncompatibilitesChevronClass('text-accentColor transition duration-300 transform rotate-180');
    } else {
      setIncompatibilitesClass('hidden');
      setIncompatibilitesChevronClass('text-accentColor transition duration-300 transform rotate-360');
    }
  };

  // Toggle eating habits area
  const toggleEatingHabits = (): void => {
    if (eatingHabitsClass === 'hidden') {
      setEatingHabitsClass('block');
      setEatingHabitsChevronClass('text-accentColor transition duration-300 transform rotate-180');
    } else {
      setEatingHabitsClass('hidden');
      setEatingHabitsChevronClass('text-accentColor transition duration-300 transform rotate-360');
    }
  };

  // Toggle ingredients not include area
  const toggleIngredientsNotInclude = (): void => {
    if (ingredientsNotIncludeClass === 'hidden') {
      setIngredientsNotIncludeClass('block');
      setIngredientsNotIncludeChevronClass('text-accentColor transition duration-300 transform rotate-180');
    } else {
      setIngredientsNotIncludeClass('hidden');
      setIngredientsNotIncludeChevronClass('text-accentColor transition duration-300 transform rotate-360');
    }
  };

  const changeIngredientNotIncludeGroup = (value: any) => {
    changeIngredientNotInclude(value.map((item: any) => item.value));
    setInitialIngredientsNotIncludeFilter(value);
  };

  const changeInitialGeneratePlanDayValues = () => {
    if (planSettings !== undefined) {
      changeDifficulty(difficultyGroup?.filter(item => planSettings.difficultyLevel?.includes(item)));
      changeIncompatibilites(intolerancesOption?.filter(item => planSettings.incompatibilites?.includes(item)));
      changeEatingHabits(eatingHabitsAlghorithmus?.filter(item => planSettings.eatingHabits?.includes(item)));
      changeNutrition(formOfNutrition?.filter(item => planSettings.formOfNutrition?.includes(item)));
      setPlanGenerateSettingsUseFavorites(planSettings.favorites);
      const ingredientArray = [] as any;

      planSettings.ingredientsNotInclude.map((entries: any) => {
        ingredientArray.push({
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          label: `${entries[0].toUpperCase()}${entries.slice(1).toLowerCase()}`,
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          value: `${entries[0].toUpperCase()}${entries.slice(1).toLowerCase()}`,
        });
        return 0;
      });

      setInitialIngredientsNotIncludeFilter(ingredientArray);
    } else {
      changeIncompatibilites(intolerancesOption?.filter(item => userData?.incompatibilities?.includes(item)));
      changeEatingHabits(eatingHabitsAlghorithmus?.filter(item => userData?.eatingHabits?.includes(item)));
      changeNutrition(formOfNutrition?.filter(item => userData?.formOfNutrition?.includes(item)));
      changeDifficulty([]);
      /* if (selectNotIncludeRef.current) {
        selectNotIncludeRef.current.select.clearValue();
      } */
      setPlanGenerateSettingsUseFavorites(false);
    }

    setDifficultyChevronClass('text-accentColor transition duration-300 transform rotate-360');
    setFormOfNutritionChevronClass('text-accentColor transition duration-300 transform rotate-360');
    setIncompatibilitesChevronClass('text-accentColor transition duration-300 transform rotate-360');
    setEatingHabitsChevronClass('text-accentColor transition duration-300 transform rotate-360');
    setIngredientsNotIncludeChevronClass('text-accentColor transition duration-300 transform rotate-360');
    setDifficultyClass('hidden');
    setFormOfNutritionClass('hidden');
    setIncompatibilitesClass('hidden');
    setEatingHabitsClass('hidden');
    setIngredientsNotIncludeClass('hidden');
    scrollToTop(generatePlanPopupRef);
    setIsLoading(false);
  };

  const resetFilter = () => {
    changeDifficulty([]);
    changeIncompatibilities([]);
    changeNutrition([]);
    changeEatingHabits([]);
    setPlanGenerateSettingsUseFavorites(false);
    if (selectNotIncludeRef.current) {
      selectNotIncludeRef.current.clearValue();
    }
    toast.success('Alle Werte wurden zurückgesetzt! Du suchst jetzt ohne Einschränkungen!');
  };

  const generatePlan = () => {
    setIsLoading(true);
    const thisDayEntriesData = [] as any;
    const thisExistingRecipesArray = [] as any;

    for (let i = 1; i <= currentPlan.dayEntries.length; i += 1) {
      generatePlanDay('plan', i, thisDayEntriesData, thisExistingRecipesArray);
    }

    const checkIncompabilityArray = [] as any;
    const checkFormOfNutritionArray = [] as any;

    intolerancesOption.forEach(key => {
      thisDayEntriesData.forEach((dayKey: any) => {
        // Check recipes
        const checkBreakfastRecipes = dayKey.breakfast.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkLunchRecipes = dayKey.lunch.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkDinnerRecipes = dayKey.dinner.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkSnacksRecipes = dayKey.snacks.recipes?.filter((item: any) => item[key.toLowerCase()] === true);

        if (
          checkBreakfastRecipes.length > 0 ||
          checkLunchRecipes.length > 0 ||
          checkDinnerRecipes.length > 0 ||
          checkSnacksRecipes.length > 0
        ) {
          checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
        } else {
          // Check ingredients
          dayKey.breakfast.ingredients?.forEach((ingredientKey: any) => {
            const getIngridient = ingredientList?.find(
              item => item.name.toLowerCase() === ingredientKey.name.toLowerCase()
            );

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.lunch.ingredients?.forEach((ingredientKey: any) => {
            const getIngridient = ingredientList?.find(
              item => item.name.toLowerCase() === ingredientKey.name.toLowerCase()
            );

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.dinner.ingredients?.forEach((ingredientKey: any) => {
            const getIngridient = ingredientList?.find(
              item => item.name.toLowerCase() === ingredientKey.name.toLowerCase()
            );

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.snacks.ingredients?.forEach((ingredientKey: any) => {
            const getIngridient = ingredientList?.find(
              item => item.name.toLowerCase() === ingredientKey.name.toLowerCase()
            );

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });
        }
      });
    });

    formOfNutrition.forEach(key => {
      thisDayEntriesData.forEach((dayKey: any) => {
        // Check recipes
        const checkBreakfastRecipes = dayKey.breakfast.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkLunchRecipes = dayKey.lunch.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkDinnerRecipes = dayKey.dinner.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkSnacksRecipes = dayKey.snacks.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkBreakfastRecipesFalse = dayKey.breakfast.recipes?.filter(
          (item: any) => item[key.toLowerCase()] === false
        );
        const checkLunchRecipesFalse = dayKey.lunch.recipes?.filter((item: any) => item[key.toLowerCase()] === false);
        const checkDinnerRecipesFalse = dayKey.dinner.recipes?.filter((item: any) => item[key.toLowerCase()] === false);
        const checkSnacksRecipesFalse = dayKey.snacks.recipes?.filter((item: any) => item[key.toLowerCase()] === false);

        if (
          checkBreakfastRecipes.length > 0 ||
          checkLunchRecipes.length > 0 ||
          checkDinnerRecipes.length > 0 ||
          checkSnacksRecipes.length > 0
        ) {
          checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
        }

        if (
          checkBreakfastRecipesFalse.length > 0 ||
          checkLunchRecipesFalse.length > 0 ||
          checkDinnerRecipesFalse.length > 0 ||
          checkSnacksRecipesFalse.length > 0
        ) {
          checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: false });
        }
      });
    });

    const checkFormOfNutritionArrayMerged = [
      ...checkFormOfNutritionArray.filter((item: any) => item.status === true),
      ...checkFormOfNutritionArray.filter((item: any) => item.status === false),
    ];

    // Copy newColumns to variable because this needs to be updated in the following sections
    let thisRecipeData = { ...currentPlan };

    // Set elements true if incompatibility exists
    for (let g = 0; g < checkIncompabilityArray.length; g += 1) {
      thisRecipeData = { ...thisRecipeData, [checkIncompabilityArray[g].name]: true };
    }

    // Set elements true if form of nutrition exists
    for (let g = 0; g < checkFormOfNutritionArrayMerged.length; g += 1) {
      thisRecipeData = {
        ...thisRecipeData,
        [checkFormOfNutritionArrayMerged[g].name]: checkFormOfNutritionArrayMerged[g].status,
      };
    }

    const newColumns = {
      ...thisRecipeData,
      dayEntries: thisDayEntriesData,
      settings: {
        difficultyLevel: initialDifficulty,
        eatingHabits: initialEatingHabits,
        incompatibilites: initialIntolerances,
        favorites: planGenerateSettingsUseFavorites,
        formOfNutrition: initialNutrition,
        ingredientsNotInclude: ingredientsNotIncludeFilter,
      },
    };

    setCurrentPlan(newColumns);
    savePlanState(true);
  };

  const saveGenerateMealRecipe = (recipe: any) => {
    let thisKcalSum = currentKcalSum;
    let thisCarbohydratesSum = currentCarbohydratesSum;
    let thisProteinSum = currentProteinSum;
    let thisFatSum = currentFatSum;
    const thisUpdateMeal = currentUpdateMeal;

    let newObject = {
      ...recipe,
      amount: 1,
      piece: 'Portion',
      id: Math.random(),
    };

    if (
      parseFloat(currentDayItem.kcal_total) - parseFloat(currentDayItem[thisUpdateMeal].kcal_total) !==
      currentKcalSum
    ) {
      thisKcalSum -= parseFloat(currentDayItem[thisUpdateMeal].kcal_total);
      thisCarbohydratesSum -= parseFloat(currentDayItem[thisUpdateMeal].carbohydrates_total);
      thisProteinSum -= parseFloat(currentDayItem[thisUpdateMeal].protein_total);
      thisFatSum -= parseFloat(currentDayItem[thisUpdateMeal].fat_total);
    }

    newObject = {
      ...newObject,
      kcal_total: Math.round(parseFloat(recipe.kcal_total)),
      carbohydrates_total: Math.round(parseFloat(recipe.carbohydrates_total)),
      protein_total: Math.round(parseFloat(recipe.protein_total)),
      fat_total: Math.round(parseFloat(recipe.fat_total)),
    };

    thisKcalSum += parseFloat(newObject.kcal_total);
    thisCarbohydratesSum += parseFloat(newObject.carbohydrates_total);
    thisProteinSum += parseFloat(newObject.protein_total);
    thisFatSum += parseFloat(newObject.fat_total);

    const newColumns = {
      ...currentPlan,
      dayEntries: currentPlan.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== currentDayId) return entries;
        return {
          ...entries,
          kcal_total: thisKcalSum,
          carbohydrates_total: thisCarbohydratesSum,
          protein_total: thisProteinSum,
          fat_total: thisFatSum,
          [thisUpdateMeal]: {
            ...entries[thisUpdateMeal],
            kcal_total: Math.round(newObject.kcal_total),
            carbohydrates_total: Math.round(parseFloat(newObject.carbohydrates_total)),
            protein_total: Math.round(parseFloat(newObject.protein_total)),
            fat_total: Math.round(parseFloat(newObject.fat_total)),
            recipes: [newObject],
            ingredients: [],
            manualItems: [],
          },
        };
      }),
    };

    const checkIncompabilityArray = [] as any;
    const checkFormOfNutritionArray = [] as any;

    intolerancesOption.forEach(key => {
      newColumns.dayEntries.forEach((dayKey: any) => {
        // Check recipes
        const checkBreakfastRecipes = dayKey.breakfast.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkLunchRecipes = dayKey.lunch.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkDinnerRecipes = dayKey.dinner.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkSnacksRecipes = dayKey.snacks.recipes?.filter((item: any) => item[key.toLowerCase()] === true);

        if (
          checkBreakfastRecipes.length > 0 ||
          checkLunchRecipes.length > 0 ||
          checkDinnerRecipes.length > 0 ||
          checkSnacksRecipes.length > 0
        ) {
          checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
        } else {
          // Check ingredients
          dayKey.breakfast.ingredients?.forEach((ingredientKey: any) => {
            const getIngridient = ingredientList?.find(
              item => item.name.toLowerCase() === ingredientKey.name.toLowerCase()
            );

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.lunch.ingredients?.forEach((ingredientKey: any) => {
            const getIngridient = ingredientList?.find(
              item => item.name.toLowerCase() === ingredientKey.name.toLowerCase()
            );

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.dinner.ingredients?.forEach((ingredientKey: any) => {
            const getIngridient = ingredientList?.find(
              item => item.name.toLowerCase() === ingredientKey.name.toLowerCase()
            );

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.snacks.ingredients?.forEach((ingredientKey: any) => {
            const getIngridient = ingredientList?.find(
              item => item.name.toLowerCase() === ingredientKey.name.toLowerCase()
            );

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });
        }
      });
    });

    formOfNutrition.forEach(key => {
      newColumns.dayEntries.forEach((dayKey: any) => {
        // Check recipes
        const checkBreakfastRecipes = dayKey.breakfast.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkLunchRecipes = dayKey.lunch.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkDinnerRecipes = dayKey.dinner.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkSnacksRecipes = dayKey.snacks.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkBreakfastRecipesFalse = dayKey.breakfast.recipes?.filter(
          (item: any) => item[key.toLowerCase()] === false
        );
        const checkLunchRecipesFalse = dayKey.lunch.recipes?.filter((item: any) => item[key.toLowerCase()] === false);
        const checkDinnerRecipesFalse = dayKey.dinner.recipes?.filter((item: any) => item[key.toLowerCase()] === false);
        const checkSnacksRecipesFalse = dayKey.snacks.recipes?.filter((item: any) => item[key.toLowerCase()] === false);

        if (
          checkBreakfastRecipes.length > 0 ||
          checkLunchRecipes.length > 0 ||
          checkDinnerRecipes.length > 0 ||
          checkSnacksRecipes.length > 0
        ) {
          checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
        }

        if (
          checkBreakfastRecipesFalse.length > 0 ||
          checkLunchRecipesFalse.length > 0 ||
          checkDinnerRecipesFalse.length > 0 ||
          checkSnacksRecipesFalse.length > 0
        ) {
          checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: false });
        }
      });
    });

    const checkFormOfNutritionArrayMerged = [
      ...checkFormOfNutritionArray.filter((item: any) => item.status === true),
      ...checkFormOfNutritionArray.filter((item: any) => item.status === false),
    ];

    // Copy newColumns to variable because this needs to be updated in the following sections
    let thisRecipeData = { ...newColumns };

    // Set elements true if incompatibility exists
    for (let g = 0; g < checkIncompabilityArray.length; g += 1) {
      thisRecipeData = { ...thisRecipeData, [checkIncompabilityArray[g].name]: true };
    }

    // Set elements true if form of nutrition exists
    for (let g = 0; g < checkFormOfNutritionArrayMerged.length; g += 1) {
      thisRecipeData = {
        ...thisRecipeData,
        [checkFormOfNutritionArrayMerged[g].name]: checkFormOfNutritionArrayMerged[g].status,
      };
    }

    handleOpenClosePopups(setGenerateDayPopupClass, '', 'close');
    toast.success('Mahlzeit wurde erfolgreich generiert!');
    // setCurrentPlan(thisRecipeData);

    if (setVisibleIncompabilityPopup !== undefined) {
      if (userData?.incompatibilityAccept === undefined && userData?.age !== undefined) {
        setVisibleIncompabilityPopup(true);
      }
    }

    savePlanChangesToFirebase(
      userData,
      thisRecipeData,
      thisRecipeData.uid,
      authContext.user?.uid,
      true,
      setCurrentPlan,
      tenant
    );
    setGeneralContentClass('block');
    setShowResults(false);
    setCurrentRecipeList([]);

    if (isOtherMealsComplete === false) {
      setNotificationPopupClass('block');
    }
  };

  const generatePlanMeal = (type: string, updateMeal: string) => {
    setIsLoading(true);
    // Set min and max values
    let thisKcalTotal = 0;
    let thisCarbohydratesTotal = 0;
    let thisProteinTotal = 0;
    let thisFatTotal = 0;

    let kcalSum =
      parseFloat(currentDayItem?.lunch.kcal_total) +
      parseFloat(currentDayItem?.dinner.kcal_total) +
      parseFloat(currentDayItem?.snacks.kcal_total) +
      parseFloat(currentDayItem?.breakfast.kcal_total);
    let carbohydratesSum =
      parseFloat(currentDayItem?.lunch.carbohydrates_total) +
      parseFloat(currentDayItem?.dinner.carbohydrates_total) +
      parseFloat(currentDayItem?.snacks.carbohydrates_total) +
      parseFloat(currentDayItem?.breakfast.carbohydrates_total);
    let proteinSum =
      parseFloat(currentDayItem?.lunch.protein_total) +
      parseFloat(currentDayItem?.dinner.protein_total) +
      parseFloat(currentDayItem?.snacks.protein_total) +
      parseFloat(currentDayItem?.breakfast.protein_total);
    let fatSum =
      parseFloat(currentDayItem?.lunch.fat_total) +
      parseFloat(currentDayItem?.dinner.fat_total) +
      parseFloat(currentDayItem?.snacks.fat_total) +
      parseFloat(currentDayItem?.breakfast.fat_total);

    if (currentDayItem?.custom_kcal_total) {
      thisKcalTotal = currentDayItem?.custom_kcal_total;
      thisCarbohydratesTotal = currentDayItem?.custom_carbohydrates_total;
      thisProteinTotal = currentDayItem?.custom_protein_total;
      thisFatTotal = currentDayItem?.custom_fat_total;
    } else if (currentPlan?.custom_kcal_total) {
      thisKcalTotal = currentPlan?.custom_kcal_total;
      thisCarbohydratesTotal = currentPlan?.custom_carbohydrates_total;
      thisProteinTotal = currentPlan?.custom_protein_total;
      thisFatTotal = currentPlan?.custom_fat_total;
    } else {
      thisKcalTotal = Math.round(parseFloat(userData!.calories));
      thisCarbohydratesTotal = userData!.carbohydrates_total;
      thisProteinTotal = userData!.protein_total;
      thisFatTotal = userData!.fat_total;
    }

    const minKcalGoal = Math.round(thisKcalTotal - thisKcalTotal * 0.05);
    const maxKcalGoal = Math.round(parseFloat(thisKcalTotal.toString()) + parseFloat(thisKcalTotal.toString()) * 0.05);
    const minCarbohydratesGoal = Math.round(thisCarbohydratesTotal - thisCarbohydratesTotal * 0.15);
    const maxCarbohydratesGoal = Math.round(thisCarbohydratesTotal + thisCarbohydratesTotal * 0.15);
    const minProteinGoal = Math.round(thisProteinTotal - thisProteinTotal * 0.15);
    const maxProteinGoal = Math.round(thisProteinTotal + thisProteinTotal * 0.15);
    const minFatGoal = Math.round(thisFatTotal - thisFatTotal * 0.15);
    const maxFatGoal = Math.round(thisFatTotal + thisFatTotal * 0.15);

    let kcalPercentageMin = 0;
    let kcalPercentageMax = 0;
    let fatPercentageMin = 0;
    let fatPercentageMax = 0;

    if (updateMeal === 'breakfast') {
      kcalPercentageMin = maxKcalGoal * 0.19;
      kcalPercentageMax = maxKcalGoal * 0.3;
      fatPercentageMin = maxFatGoal * 0.19;
      fatPercentageMax = maxFatGoal * 0.3;
    } else if (updateMeal === 'snacks') {
      kcalPercentageMax = maxKcalGoal * 0.15;
      fatPercentageMax = maxFatGoal * 0.15;
    } else {
      kcalPercentageMin = maxKcalGoal * 0.2;
      kcalPercentageMax = maxKcalGoal * 0.35;
      fatPercentageMin = maxFatGoal * 0.1;
      fatPercentageMax = maxFatGoal * 0.25;
    }

    let localIsOtherMealsComplete = false;

    if (
      updateMeal === 'breakfast' &&
      currentDayItem?.lunch.kcal_total > 0 &&
      currentDayItem?.dinner.kcal_total > 0 &&
      currentDayItem?.snacks.kcal_total > 0
    ) {
      localIsOtherMealsComplete = true;
      if (currentDayItem?.breakfast.kcal_total > 0) {
        kcalSum -= currentDayItem?.breakfast.kcal_total;
        carbohydratesSum -= currentDayItem?.breakfast.carbohydrates_total;
        proteinSum -= currentDayItem?.breakfast.protein_total;
        fatSum -= currentDayItem?.breakfast.fat_total;
      }
    } else if (
      updateMeal === 'lunch' &&
      currentDayItem?.breakfast.kcal_total > 0 &&
      currentDayItem?.dinner.kcal_total > 0 &&
      currentDayItem?.snacks.kcal_total > 0
    ) {
      localIsOtherMealsComplete = true;
      if (currentDayItem?.lunch.kcal_total > 0) {
        kcalSum -= currentDayItem?.lunch.kcal_total;
        carbohydratesSum -= currentDayItem?.lunch.carbohydrates_total;
        proteinSum -= currentDayItem?.lunch.protein_total;
        fatSum -= currentDayItem?.lunch.fat_total;
      }
    } else if (
      updateMeal === 'dinner' &&
      currentDayItem?.breakfast.kcal_total > 0 &&
      currentDayItem?.lunch.kcal_total > 0 &&
      currentDayItem?.snacks.kcal_total > 0
    ) {
      localIsOtherMealsComplete = true;
      if (currentDayItem?.dinner.kcal_total > 0) {
        kcalSum -= currentDayItem?.dinner.kcal_total;
        carbohydratesSum -= currentDayItem?.dinner.carbohydrates_total;
        proteinSum -= currentDayItem?.dinner.protein_total;
        fatSum -= currentDayItem?.dinner.fat_total;
      }
    } else if (
      updateMeal === 'snacks' &&
      currentDayItem?.breakfast.kcal_total > 0 &&
      currentDayItem?.lunch.kcal_total > 0 &&
      currentDayItem?.dinner.kcal_total > 0
    ) {
      localIsOtherMealsComplete = true;
      if (currentDayItem?.snacks.kcal_total > 0) {
        kcalSum -= currentDayItem?.snacks.kcal_total;
        carbohydratesSum -= currentDayItem?.snacks.carbohydrates_total;
        proteinSum -= currentDayItem?.snacks.protein_total;
        fatSum -= currentDayItem?.snacks.fat_total;
      }
    }

    let thisRecipeUid = 'nothing';

    if (parseFloat(currentDayItem[updateMeal].kcal_total) > 0 && currentDayItem[updateMeal].recipes.length > 0) {
      thisRecipeUid = currentDayItem[updateMeal].recipes[0].uid;
    }

    const getMealRecipe = recipesList?.filter(
      localIsOtherMealsComplete
        ? element =>
            element[updateMeal] &&
            element.uid !== thisRecipeUid &&
            parseFloat(element.kcal_total) > minKcalGoal - kcalSum &&
            parseFloat(element.kcal_total) < maxKcalGoal - kcalSum &&
            parseFloat(element.carbohydrates_total) > minCarbohydratesGoal - carbohydratesSum &&
            parseFloat(element.carbohydrates_total) < maxCarbohydratesGoal - carbohydratesSum &&
            parseFloat(element.protein_total) > minProteinGoal - proteinSum &&
            parseFloat(element.protein_total) < maxProteinGoal - proteinSum &&
            parseFloat(element.fat_total) > minFatGoal - fatSum &&
            parseFloat(element.fat_total) < maxFatGoal - fatSum &&
            (initialDifficulty.length === 0 ||
              initialDifficulty.findIndex(
                item => element.difficulty_level === difficultyGroup.findIndex(difficulty => difficulty === item) + 1
              ) > -1) &&
            (initialNutrition.length === 0 || initialNutrition.findIndex(item => element[item.toLowerCase()]) > -1) &&
            (element.alghorithmus === undefined || element.alghorithmus === true) &&
            (initialIntolerances.length === 0 ||
              initialIntolerances.findIndex(item => element[item.toLowerCase()]) === -1) &&
            (initialEatingHabits.length === 0 ||
              initialEatingHabits.findIndex(item => element[item.toLowerCase()]) > -1) &&
            (ingredientsNotIncludeFilter.length === 0 ||
              ingredientsNotIncludeFilter.findIndex(
                (name: string) =>
                  element.ingredients.findIndex((item: Ingredients) => item.name.toLowerCase() === name.toLowerCase()) >
                  -1
              ) === -1)
        : type === 'userClick'
        ? element =>
            element[updateMeal] &&
            parseFloat(element.kcal_total) > kcalPercentageMin &&
            parseFloat(element.kcal_total) < kcalPercentageMax &&
            parseFloat(element.fat_total) > fatPercentageMin &&
            parseFloat(element.fat_total) < fatPercentageMax &&
            (initialDifficulty.length === 0 ||
              initialDifficulty.findIndex(
                item => element.difficulty_level === difficultyGroup.findIndex(difficulty => difficulty === item) + 1
              ) > -1) &&
            (initialNutrition.length === 0 || initialNutrition.findIndex(item => element[item.toLowerCase()]) > -1) &&
            (element.alghorithmus === undefined || element.alghorithmus === true) &&
            (initialIntolerances.length === 0 ||
              initialIntolerances.findIndex(item => element[item.toLowerCase()]) === -1) &&
            (initialEatingHabits.length === 0 ||
              initialEatingHabits.findIndex(item => element[item.toLowerCase()]) > -1) &&
            (ingredientsNotIncludeFilter.length === 0 ||
              ingredientsNotIncludeFilter.findIndex(
                (name: string) =>
                  element.ingredients.findIndex((item: Ingredients) => item.name.toLowerCase() === name.toLowerCase()) >
                  -1
              ) === -1)
        : element =>
            element[updateMeal] &&
            (initialDifficulty.length === 0 ||
              initialDifficulty.findIndex(
                item => element.difficulty_level === difficultyGroup.findIndex(difficulty => difficulty === item) + 1
              ) > -1) &&
            (initialNutrition.length === 0 || initialNutrition.findIndex(item => element[item.toLowerCase()]) > -1) &&
            (element.alghorithmus === undefined || element.alghorithmus === true) &&
            (initialIntolerances.length === 0 ||
              initialIntolerances.findIndex(item => element[item.toLowerCase()]) === -1) &&
            (initialEatingHabits.length === 0 ||
              initialEatingHabits.findIndex(item => element[item.toLowerCase()]) > -1) &&
            (ingredientsNotIncludeFilter.length === 0 ||
              ingredientsNotIncludeFilter.findIndex(
                (name: string) =>
                  element.ingredients.findIndex((item: Ingredients) => item.name.toLowerCase() === name.toLowerCase()) >
                  -1
              ) === -1)
    ) as any;

    // const getRandomRecipe =
    //   getMealRecipe !== undefined ? getMealRecipe[Math.floor(Math.random() * getMealRecipe.length)] : ({} as any);

    // const newObject = {
    //   ...getRandomRecipe,
    //   amount: 1,
    //   piece: 'Portion',
    //   id: Math.random(),
    // };

    setIsOtherMealsComplete(localIsOtherMealsComplete);

    if (getMealRecipe.length > 0) {
      setCurrentKcalSum(kcalSum);
      setCurrentCarbohydratesSum(carbohydratesSum);
      setCurrentProteinSum(proteinSum);
      setCurrentFatSum(fatSum);
      setCurrentUpdateMeal(updateMeal);
      setCurrentRecipeList(getMealRecipe);
      setShowResults(true);
      setGeneralContentClass('hidden');
      setIsLoading(false);
      scrollToTop(generatePlanPopupRef);
    } else {
      toast.warn(
        'Es wurden keine passende Kombinationen gefunden. Bitte prüfe deine Einstellungen und ergänze bzw. reduziere die Einschränkungen!'
      );
      setIsLoading(false);
    }

    return 0;
  };

  const generatePlanDay = (type: string, dayId?: number, dayEntriesObject?: any, existingRecipesArray?: any) => {
    if (type !== 'plan') {
      setIsLoading(true);
    }

    // Set min and max values
    let thisKcalTotal = 0;
    let thisCarbohydratesTotal = 0;
    let thisProteinTotal = 0;
    let thisFatTotal = 0;
    let thisDayId = currentDayId;

    if (dayId !== undefined) {
      thisDayId = dayId;
    }

    if (type)
      if (currentDayItem?.custom_kcal_total) {
        thisKcalTotal = currentDayItem?.custom_kcal_total;
        thisCarbohydratesTotal = currentDayItem?.custom_carbohydrates_total;
        thisProteinTotal = currentDayItem?.custom_protein_total;
        thisFatTotal = currentDayItem?.custom_fat_total;
      } else if (currentPlan?.custom_kcal_total) {
        thisKcalTotal = currentPlan?.custom_kcal_total;
        thisCarbohydratesTotal = currentPlan?.custom_carbohydrates_total;
        thisProteinTotal = currentPlan?.custom_protein_total;
        thisFatTotal = currentPlan?.custom_fat_total;
      } else {
        thisKcalTotal = Math.round(parseFloat(userData!.calories));
        thisCarbohydratesTotal = userData!.carbohydrates_total;
        thisProteinTotal = userData!.protein_total;
        thisFatTotal = userData!.fat_total;
      }

    const minKcalGoal = Math.round(thisKcalTotal - thisKcalTotal * 0.05);
    const maxKcalGoal = Math.round(parseFloat(thisKcalTotal.toString()) + parseFloat(thisKcalTotal.toString()) * 0.05);
    const minCarbohydratesGoal = Math.round(thisCarbohydratesTotal - thisCarbohydratesTotal * 0.15);
    const maxCarbohydratesGoal = Math.round(thisCarbohydratesTotal + thisCarbohydratesTotal * 0.15);
    const minProteinGoal = Math.round(thisProteinTotal - thisProteinTotal * 0.15);
    const maxProteinGoal = Math.round(thisProteinTotal + thisProteinTotal * 0.15);
    const minFatGoal = Math.round(thisFatTotal - thisFatTotal * 0.15);
    const maxFatGoal = Math.round(thisFatTotal + thisFatTotal * 0.15);

    const kcalPercentage19 = maxKcalGoal * 0.19;
    const kcalPercentage30 = maxKcalGoal * 0.3;
    const kcalPercentage15 = maxKcalGoal * 0.15;
    const kcalPercentage43 = maxKcalGoal * 0.43;

    const getBreakfastRecipe = recipesList?.filter(
      type === 'userClick' || type === 'plan'
        ? element =>
            element.breakfast &&
            (existingRecipesArray === undefined || existingRecipesArray.indexOf(element.uid) === -1) &&
            parseFloat(element.kcal_total) > kcalPercentage19 &&
            parseFloat(element.kcal_total) < kcalPercentage30 &&
            (initialDifficulty.length === 0 ||
              initialDifficulty.findIndex(
                item => element.difficulty_level === difficultyGroup.findIndex(difficulty => difficulty === item) + 1
              ) > -1) &&
            (initialNutrition.length === 0 || initialNutrition.findIndex(item => element[item.toLowerCase()]) > -1) &&
            (element.alghorithmus === undefined || element.alghorithmus === true) &&
            (initialIntolerances.length === 0 ||
              initialIntolerances.findIndex(item => element[item.toLowerCase()]) === -1) &&
            (initialEatingHabits.length === 0 ||
              initialEatingHabits.findIndex(item => element[item.toLowerCase()]) > -1) &&
            (ingredientsNotIncludeFilter.length === 0 ||
              ingredientsNotIncludeFilter.findIndex(
                (name: string) =>
                  element.ingredients.findIndex((item: Ingredients) => item.name.toLowerCase() === name.toLowerCase()) >
                  -1
              ) === -1)
        : element =>
            element.breakfast &&
            (initialDifficulty.length === 0 ||
              initialDifficulty.findIndex(
                item => element.difficulty_level === difficultyGroup.findIndex(difficulty => difficulty === item) + 1
              ) > -1) &&
            (initialNutrition.length === 0 || initialNutrition.findIndex(item => element[item.toLowerCase()]) > -1) &&
            (element.alghorithmus === undefined || element.alghorithmus === true) &&
            (initialIntolerances.length === 0 ||
              initialIntolerances.findIndex(item => element[item.toLowerCase()]) === -1) &&
            (initialEatingHabits.length === 0 ||
              initialEatingHabits.findIndex(item => element[item.toLowerCase()]) > -1) &&
            (ingredientsNotIncludeFilter.length === 0 ||
              ingredientsNotIncludeFilter.findIndex(
                (name: string) =>
                  element.ingredients.findIndex((item: Ingredients) => item.name.toLowerCase() === name.toLowerCase()) >
                  -1
              ) === -1)
    );
    const getSnackRecipe = recipesList?.filter(
      type === 'userClick' || type === 'plan'
        ? element =>
            element.snacks &&
            (existingRecipesArray === undefined || existingRecipesArray.indexOf(element.uid) === -1) &&
            parseFloat(element.kcal_total) < kcalPercentage15 &&
            (initialDifficulty.length === 0 ||
              initialDifficulty.findIndex(
                item => element.difficulty_level === difficultyGroup.findIndex(difficulty => difficulty === item) + 1
              ) > -1) &&
            (initialNutrition.length === 0 || initialNutrition.findIndex(item => element[item.toLowerCase()]) > -1) &&
            (element.alghorithmus === undefined || element.alghorithmus === true) &&
            (initialIntolerances.length === 0 ||
              initialIntolerances.findIndex(item => element[item.toLowerCase()]) === -1) &&
            (initialEatingHabits.length === 0 ||
              initialEatingHabits.findIndex(item => element[item.toLowerCase()]) > -1) &&
            (ingredientsNotIncludeFilter.length === 0 ||
              ingredientsNotIncludeFilter.findIndex(
                (name: string) =>
                  element.ingredients.findIndex((item: Ingredients) => item.name.toLowerCase() === name.toLowerCase()) >
                  -1
              ) === -1)
        : element =>
            element.snacks &&
            (initialDifficulty.length === 0 ||
              initialDifficulty.findIndex(
                item => element.difficulty_level === difficultyGroup.findIndex(difficulty => difficulty === item) + 1
              ) > -1) &&
            (initialNutrition.length === 0 || initialNutrition.findIndex(item => element[item.toLowerCase()]) > -1) &&
            (element.alghorithmus === undefined || element.alghorithmus === true) &&
            (initialIntolerances.length === 0 ||
              initialIntolerances.findIndex(item => element[item.toLowerCase()]) === -1) &&
            (initialEatingHabits.length === 0 ||
              initialEatingHabits.findIndex(item => element[item.toLowerCase()]) > -1) &&
            (ingredientsNotIncludeFilter.length === 0 ||
              ingredientsNotIncludeFilter.findIndex(
                (name: string) =>
                  element.ingredients.findIndex((item: Ingredients) => item.name.toLowerCase() === name.toLowerCase()) >
                  -1
              ) === -1)
    );
    const getLunchRecipe = recipesList?.filter(
      type === 'userClick' || type === 'plan'
        ? element =>
            element.lunch &&
            (existingRecipesArray === undefined || existingRecipesArray.indexOf(element.uid) === -1) &&
            parseFloat(element.kcal_total) > kcalPercentage30 &&
            parseFloat(element.kcal_total) < kcalPercentage43 &&
            (initialDifficulty.length === 0 ||
              initialDifficulty.findIndex(
                item => element.difficulty_level === difficultyGroup.findIndex(difficulty => difficulty === item) + 1
              ) > -1) &&
            (initialNutrition.length === 0 || initialNutrition.findIndex(item => element[item.toLowerCase()]) > -1) &&
            (element.alghorithmus === undefined || element.alghorithmus === true) &&
            (initialIntolerances.length === 0 ||
              initialIntolerances.findIndex(item => element[item.toLowerCase()]) === -1) &&
            (initialEatingHabits.length === 0 ||
              initialEatingHabits.findIndex(item => element[item.toLowerCase()]) > -1) &&
            (ingredientsNotIncludeFilter.length === 0 ||
              ingredientsNotIncludeFilter.findIndex(
                (name: string) =>
                  element.ingredients.findIndex((item: Ingredients) => item.name.toLowerCase() === name.toLowerCase()) >
                  -1
              ) === -1)
        : element =>
            element.lunch &&
            (initialDifficulty.length === 0 ||
              initialDifficulty.findIndex(
                item => element.difficulty_level === difficultyGroup.findIndex(difficulty => difficulty === item) + 1
              ) > -1) &&
            (initialNutrition.length === 0 || initialNutrition.findIndex(item => element[item.toLowerCase()]) > -1) &&
            (element.alghorithmus === undefined || element.alghorithmus === true) &&
            (initialIntolerances.length === 0 ||
              initialIntolerances.findIndex(item => element[item.toLowerCase()]) === -1) &&
            (initialEatingHabits.length === 0 ||
              initialEatingHabits.findIndex(item => element[item.toLowerCase()]) > -1) &&
            (ingredientsNotIncludeFilter.length === 0 ||
              ingredientsNotIncludeFilter.findIndex(
                (name: string) =>
                  element.ingredients.findIndex((item: Ingredients) => item.name.toLowerCase() === name.toLowerCase()) >
                  -1
              ) === -1)
    );

    const getFavoriteOwnBreakfastRecipes = getBreakfastRecipe?.filter(
      element =>
        element.userRecipe === true || favoritesRecipesList?.find(item => item.origId === element.uid) !== undefined
    );
    const getFavoriteOwnLunchRecipes = getLunchRecipe?.filter(
      element =>
        element.userRecipe === true || favoritesRecipesList?.find(item => item.origId === element.uid) !== undefined
    );
    const getFavoriteOwnSnacksRecipes = getSnackRecipe?.filter(
      element =>
        element.userRecipe === true || favoritesRecipesList?.find(item => item.origId === element.uid) !== undefined
    );

    let getBreakfastRandomRecipe =
      getBreakfastRecipe !== undefined
        ? getBreakfastRecipe[Math.floor(Math.random() * getBreakfastRecipe.length)]
        : ({} as any);
    let getSnackRandomRecipe =
      getSnackRecipe !== undefined ? getSnackRecipe[Math.floor(Math.random() * getSnackRecipe.length)] : ({} as any);
    let getLunchRandomRecipe =
      getLunchRecipe !== undefined ? getLunchRecipe[Math.floor(Math.random() * getLunchRecipe.length)] : ({} as any);

    if (getFavoriteOwnBreakfastRecipes!.length > 0 && planGenerateSettingsUseFavorites === true) {
      getBreakfastRandomRecipe =
        getFavoriteOwnBreakfastRecipes![Math.floor(Math.random() * getFavoriteOwnBreakfastRecipes!.length)];
    }
    if (getFavoriteOwnLunchRecipes!.length > 0 && planGenerateSettingsUseFavorites === true) {
      getLunchRandomRecipe =
        getFavoriteOwnLunchRecipes![Math.floor(Math.random() * getFavoriteOwnLunchRecipes!.length)];
    }
    if (getFavoriteOwnSnacksRecipes!.length > 0 && planGenerateSettingsUseFavorites === true) {
      getSnackRandomRecipe =
        getFavoriteOwnSnacksRecipes![Math.floor(Math.random() * getFavoriteOwnSnacksRecipes!.length)];
    }

    let kcalSum = 0;
    let carbohydratesSum = 0;
    let proteinSum = 0;
    let fatSum = 0;

    if (
      getBreakfastRandomRecipe !== undefined &&
      getSnackRandomRecipe !== undefined &&
      getLunchRandomRecipe !== undefined
    ) {
      kcalSum =
        parseFloat(getBreakfastRandomRecipe.kcal_total) +
        parseFloat(getSnackRandomRecipe.kcal_total) +
        parseFloat(getLunchRandomRecipe.kcal_total);
      carbohydratesSum =
        parseFloat(getBreakfastRandomRecipe.carbohydrates_total) +
        parseFloat(getSnackRandomRecipe.carbohydrates_total) +
        parseFloat(getLunchRandomRecipe.carbohydrates_total);
      proteinSum =
        parseFloat(getBreakfastRandomRecipe.protein_total) +
        parseFloat(getSnackRandomRecipe.protein_total) +
        parseFloat(getLunchRandomRecipe.protein_total);
      fatSum =
        parseFloat(getBreakfastRandomRecipe.fat_total) +
        parseFloat(getSnackRandomRecipe.fat_total) +
        parseFloat(getLunchRandomRecipe.fat_total);
    }

    let getLastRecipe = recipesList?.filter(
      element =>
        element.dinner &&
        (existingRecipesArray === undefined || existingRecipesArray.indexOf(element.uid) === -1) &&
        parseFloat(element.kcal_total) > minKcalGoal - kcalSum &&
        parseFloat(element.kcal_total) < maxKcalGoal - kcalSum &&
        parseFloat(element.carbohydrates_total) > minCarbohydratesGoal - carbohydratesSum &&
        parseFloat(element.carbohydrates_total) < maxCarbohydratesGoal - carbohydratesSum &&
        parseFloat(element.protein_total) > minProteinGoal - proteinSum &&
        parseFloat(element.protein_total) < maxProteinGoal - proteinSum &&
        parseFloat(element.fat_total) > minFatGoal - fatSum &&
        parseFloat(element.fat_total) < maxFatGoal - fatSum &&
        element.category !== getLunchRandomRecipe.category &&
        (initialDifficulty.length === 0 ||
          initialDifficulty.findIndex(
            item => element.difficulty_level === difficultyGroup.findIndex(difficulty => difficulty === item) + 1
          ) > -1) &&
        (initialNutrition.length === 0 || initialNutrition.findIndex(item => element[item.toLowerCase()]) > -1) &&
        (element.alghorithmus === undefined || element.alghorithmus === true) &&
        (initialIntolerances.length === 0 ||
          initialIntolerances.findIndex(item => element[item.toLowerCase()]) === -1) &&
        (initialEatingHabits.length === 0 || initialEatingHabits.findIndex(item => element[item.toLowerCase()]) > -1) &&
        (ingredientsNotIncludeFilter.length === 0 ||
          ingredientsNotIncludeFilter.findIndex(
            (name: string) =>
              element.ingredients.findIndex((item: Ingredients) => item.name.toLowerCase() === name.toLowerCase()) > -1
          ) === -1)
    ) as any;

    let isLastRecipeFound = false;

    // If no last recipe found: generate new lunch and generate new last recipe until combination was found
    if (getLastRecipe.length === 0) {
      let checkCounter = 0;
      while (isLastRecipeFound === false) {
        checkCounter += 1;

        if (checkCounter === 10 && type === 'plan') {
          break;
        }

        if (checkCounter === 25 && type === 'checkAgainWithoutRules') {
          break;
        }

        if (checkCounter === 25 && type === 'userClick') {
          generatePlanDay('checkAgainWithoutRules');
          break;
        }

        let thisKcalSum = kcalSum;
        let thisCarbohydratesSum = carbohydratesSum;
        let thisProteinSum = proteinSum;
        let thisFatSum = fatSum;

        const getThisLunchRandomRecipe =
          getLunchRecipe !== undefined
            ? getLunchRecipe[Math.floor(Math.random() * getLunchRecipe.length)]
            : ({} as any);

        if (
          getBreakfastRandomRecipe !== undefined &&
          getSnackRandomRecipe !== undefined &&
          getThisLunchRandomRecipe !== undefined
        ) {
          thisKcalSum =
            parseFloat(getBreakfastRandomRecipe.kcal_total) +
            parseFloat(getSnackRandomRecipe.kcal_total) +
            parseFloat(getThisLunchRandomRecipe.kcal_total);
          thisCarbohydratesSum =
            parseFloat(getBreakfastRandomRecipe.carbohydrates_total) +
            parseFloat(getSnackRandomRecipe.carbohydrates_total) +
            parseFloat(getThisLunchRandomRecipe.carbohydrates_total);
          thisProteinSum =
            parseFloat(getBreakfastRandomRecipe.protein_total) +
            parseFloat(getSnackRandomRecipe.protein_total) +
            parseFloat(getThisLunchRandomRecipe.protein_total);
          thisFatSum =
            parseFloat(getBreakfastRandomRecipe.fat_total) +
            parseFloat(getSnackRandomRecipe.fat_total) +
            parseFloat(getThisLunchRandomRecipe.fat_total);
        }

        const getThisLastRecipe = recipesList?.filter(
          element =>
            element.dinner &&
            (existingRecipesArray === undefined || existingRecipesArray.indexOf(element.uid) === -1) &&
            parseFloat(element.kcal_total) > minKcalGoal - thisKcalSum &&
            parseFloat(element.kcal_total) < maxKcalGoal - thisKcalSum &&
            parseFloat(element.carbohydrates_total) > minCarbohydratesGoal - thisCarbohydratesSum &&
            parseFloat(element.carbohydrates_total) < maxCarbohydratesGoal - thisCarbohydratesSum &&
            parseFloat(element.protein_total) > minProteinGoal - thisProteinSum &&
            parseFloat(element.protein_total) < maxProteinGoal - thisProteinSum &&
            parseFloat(element.fat_total) > minFatGoal - thisFatSum &&
            parseFloat(element.fat_total) < maxFatGoal - thisFatSum &&
            element.category !== getThisLunchRandomRecipe.category &&
            (initialDifficulty.length === 0 ||
              initialDifficulty.findIndex(
                item => element.difficulty_level === difficultyGroup.findIndex(difficulty => difficulty === item) + 1
              ) > -1) &&
            (initialNutrition.length === 0 || initialNutrition.findIndex(item => element[item.toLowerCase()]) > -1) &&
            (element.alghorithmus === undefined || element.alghorithmus === true) &&
            (initialIntolerances.length === 0 ||
              initialIntolerances.findIndex(item => element[item.toLowerCase()]) === -1) &&
            (initialEatingHabits.length === 0 ||
              initialEatingHabits.findIndex(item => element[item.toLowerCase()]) > -1) &&
            (ingredientsNotIncludeFilter.length === 0 ||
              ingredientsNotIncludeFilter.findIndex(
                (name: string) =>
                  element.ingredients.findIndex((item: Ingredients) => item.name.toLowerCase() === name.toLowerCase()) >
                  -1
              ) === -1)
        ) as any;

        if (getThisLastRecipe.length > 0) {
          isLastRecipeFound = true;
          getLastRecipe = getThisLastRecipe;
          getLunchRandomRecipe = getThisLunchRandomRecipe;
          kcalSum = thisKcalSum;
          carbohydratesSum = thisCarbohydratesSum;
          proteinSum = thisProteinSum;
          fatSum = thisFatSum;
        }
      }
    }

    const getLastRandomRecipe =
      getLastRecipe !== undefined ? getLastRecipe[Math.floor(Math.random() * getLastRecipe.length)] : ({} as any);

    const breakfastObject = {
      ...getBreakfastRandomRecipe,
      amount: 1,
      piece: 'Portion',
      id: Math.random(),
      kcal_total: Math.round(parseFloat(getBreakfastRandomRecipe?.kcal_total)),
      carbohydrates_total: Math.round(parseFloat(getBreakfastRandomRecipe?.carbohydrates_total)),
      protein_total: Math.round(parseFloat(getBreakfastRandomRecipe?.protein_total)),
      fat_total: Math.round(parseFloat(getBreakfastRandomRecipe?.fat_total)),
    };
    const lunchObject = {
      ...getLunchRandomRecipe,
      amount: 1,
      piece: 'Portion',
      id: Math.random(),
      kcal_total: Math.round(parseFloat(getLunchRandomRecipe?.kcal_total)),
      carbohydrates_total: Math.round(parseFloat(getLunchRandomRecipe?.carbohydrates_total)),
      protein_total: Math.round(parseFloat(getLunchRandomRecipe?.protein_total)),
      fat_total: Math.round(parseFloat(getLunchRandomRecipe?.fat_total)),
    };
    const SnackObject = {
      ...getSnackRandomRecipe,
      amount: 1,
      piece: 'Portion',
      id: Math.random(),
      kcal_total: Math.round(parseFloat(getSnackRandomRecipe?.kcal_total)),
      carbohydrates_total: Math.round(parseFloat(getSnackRandomRecipe?.carbohydrates_total)),
      protein_total: Math.round(parseFloat(getSnackRandomRecipe?.protein_total)),
      fat_total: Math.round(parseFloat(getSnackRandomRecipe?.fat_total)),
    };
    const DinnerObject = {
      ...getLastRandomRecipe,
      amount: 1,
      piece: 'Portion',
      id: Math.random(),
      kcal_total: Math.round(parseFloat(getLastRandomRecipe?.kcal_total)),
      carbohydrates_total: Math.round(parseFloat(getLastRandomRecipe?.carbohydrates_total)),
      protein_total: Math.round(parseFloat(getLastRandomRecipe?.protein_total)),
      fat_total: Math.round(parseFloat(getLastRandomRecipe?.fat_total)),
    };

    if (getLastRecipe.length > 0) {
      kcalSum += parseFloat(getLastRandomRecipe?.kcal_total);
      carbohydratesSum += parseFloat(getLastRandomRecipe?.carbohydrates_total);
      proteinSum += parseFloat(getLastRandomRecipe?.protein_total);
      fatSum += parseFloat(getLastRandomRecipe?.fat_total);
    } else {
      kcalSum -= parseFloat(getLunchRandomRecipe?.kcal_total);
      carbohydratesSum -= parseFloat(getLunchRandomRecipe?.carbohydrates_total);
      proteinSum -= parseFloat(getLunchRandomRecipe?.protein_total);
      fatSum -= parseFloat(getLunchRandomRecipe?.fat_total);
    }

    const newColumns = {
      ...currentPlan,
      dayEntries: currentPlan.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== thisDayId) return entries;
        return {
          ...entries,
          kcal_total: !Number.isNaN(kcalSum) ? kcalSum : 0,
          carbohydrates_total: !Number.isNaN(carbohydratesSum) ? carbohydratesSum : 0,
          protein_total: !Number.isNaN(proteinSum) ? proteinSum : 0,
          fat_total: !Number.isNaN(fatSum) ? fatSum : 0,
          breakfast: {
            ...entries.breakfast,
            kcal_total:
              parseFloat(breakfastObject.kcal_total) > 0 ? Math.round(parseFloat(breakfastObject.kcal_total)) : 0,
            carbohydrates_total:
              parseFloat(breakfastObject.kcal_total) > 0
                ? Math.round(parseFloat(breakfastObject.carbohydrates_total))
                : 0,
            protein_total:
              parseFloat(breakfastObject.kcal_total) > 0 ? Math.round(parseFloat(breakfastObject.protein_total)) : 0,
            fat_total:
              parseFloat(breakfastObject.kcal_total) > 0 ? Math.round(parseFloat(breakfastObject.fat_total)) : 0,
            recipes: parseFloat(breakfastObject.kcal_total) > 0 ? [breakfastObject] : [],
            ingredients: [],
            manualItems: [],
          },
          lunch: {
            ...entries.lunch,
            kcal_total: getLastRecipe.length === 0 ? 0 : Math.round(lunchObject.kcal_total),
            carbohydrates_total:
              getLastRecipe.length === 0 ? 0 : Math.round(parseFloat(lunchObject.carbohydrates_total)),
            protein_total: getLastRecipe.length === 0 ? 0 : Math.round(parseFloat(lunchObject.protein_total)),
            fat_total: getLastRecipe.length === 0 ? 0 : Math.round(parseFloat(lunchObject.fat_total)),
            recipes: getLastRecipe.length === 0 ? [] : [lunchObject],
            ingredients: [],
            manualItems: [],
          },
          dinner: {
            ...entries.dinner,
            kcal_total: getLastRecipe.length > 0 ? Math.round(DinnerObject.kcal_total) : 0,
            carbohydrates_total:
              getLastRecipe.length > 0 ? Math.round(parseFloat(DinnerObject.carbohydrates_total)) : 0,
            protein_total: getLastRecipe.length > 0 ? Math.round(parseFloat(DinnerObject.protein_total)) : 0,
            fat_total: getLastRecipe.length > 0 ? Math.round(parseFloat(DinnerObject.fat_total)) : 0,
            recipes: getLastRecipe.length > 0 ? [DinnerObject] : [],
            ingredients: [],
            manualItems: [],
          },
          snacks: {
            ...entries.snacks,
            kcal_total: parseFloat(SnackObject.kcal_total) > 0 ? Math.round(SnackObject.kcal_total) : 0,
            carbohydrates_total:
              parseFloat(SnackObject.kcal_total) > 0 ? Math.round(parseFloat(SnackObject.carbohydrates_total)) : 0,
            protein_total:
              parseFloat(SnackObject.kcal_total) > 0 ? Math.round(parseFloat(SnackObject.protein_total)) : 0,
            fat_total: parseFloat(SnackObject.kcal_total) > 0 ? Math.round(parseFloat(SnackObject.fat_total)) : 0,
            recipes: parseFloat(SnackObject.kcal_total) > 0 ? [SnackObject] : [],
            ingredients: [],
            manualItems: [],
          },
        };
      }),
    };

    const checkIncompabilityArray = [] as any;
    const checkFormOfNutritionArray = [] as any;

    intolerancesOption.forEach(key => {
      newColumns.dayEntries.forEach((dayKey: any) => {
        // Check recipes
        const checkBreakfastRecipes = dayKey.breakfast.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkLunchRecipes = dayKey.lunch.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkDinnerRecipes = dayKey.dinner.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkSnacksRecipes = dayKey.snacks.recipes?.filter((item: any) => item[key.toLowerCase()] === true);

        if (
          checkBreakfastRecipes.length > 0 ||
          checkLunchRecipes.length > 0 ||
          checkDinnerRecipes.length > 0 ||
          checkSnacksRecipes.length > 0
        ) {
          checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
        } else {
          // Check ingredients
          dayKey.breakfast.ingredients?.forEach((ingredientKey: any) => {
            const getIngridient = ingredientList?.find(
              item => item.name.toLowerCase() === ingredientKey.name.toLowerCase()
            );

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.lunch.ingredients?.forEach((ingredientKey: any) => {
            const getIngridient = ingredientList?.find(
              item => item.name.toLowerCase() === ingredientKey.name.toLowerCase()
            );

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.dinner.ingredients?.forEach((ingredientKey: any) => {
            const getIngridient = ingredientList?.find(
              item => item.name.toLowerCase() === ingredientKey.name.toLowerCase()
            );

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });

          dayKey.snacks.ingredients?.forEach((ingredientKey: any) => {
            const getIngridient = ingredientList?.find(
              item => item.name.toLowerCase() === ingredientKey.name.toLowerCase()
            );

            if (
              getIngridient !== undefined &&
              typeof getIngridient[key.toLowerCase()] !== undefined &&
              getIngridient[key.toLowerCase()] !== null
            ) {
              if ((getIngridient[key.toLowerCase()] as any) === true) {
                checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
              }
            }
          });
        }
      });
    });

    formOfNutrition.forEach(key => {
      newColumns.dayEntries.forEach((dayKey: any) => {
        // Check recipes
        const checkBreakfastRecipes = dayKey.breakfast.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkLunchRecipes = dayKey.lunch.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkDinnerRecipes = dayKey.dinner.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkSnacksRecipes = dayKey.snacks.recipes?.filter((item: any) => item[key.toLowerCase()] === true);
        const checkBreakfastRecipesFalse = dayKey.breakfast.recipes?.filter(
          (item: any) => item[key.toLowerCase()] === false
        );
        const checkLunchRecipesFalse = dayKey.lunch.recipes?.filter((item: any) => item[key.toLowerCase()] === false);
        const checkDinnerRecipesFalse = dayKey.dinner.recipes?.filter((item: any) => item[key.toLowerCase()] === false);
        const checkSnacksRecipesFalse = dayKey.snacks.recipes?.filter((item: any) => item[key.toLowerCase()] === false);

        if (
          checkBreakfastRecipes.length > 0 ||
          checkLunchRecipes.length > 0 ||
          checkDinnerRecipes.length > 0 ||
          checkSnacksRecipes.length > 0
        ) {
          checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
        }

        if (
          checkBreakfastRecipesFalse.length > 0 ||
          checkLunchRecipesFalse.length > 0 ||
          checkDinnerRecipesFalse.length > 0 ||
          checkSnacksRecipesFalse.length > 0
        ) {
          checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: false });
        }
      });
    });

    const checkFormOfNutritionArrayMerged = [
      ...checkFormOfNutritionArray.filter((item: any) => item.status === true),
      ...checkFormOfNutritionArray.filter((item: any) => item.status === false),
    ];

    // Copy newColumns to variable because this needs to be updated in the following sections
    let thisRecipeData = { ...newColumns };

    // Set elements true if incompatibility exists
    for (let g = 0; g < checkIncompabilityArray.length; g += 1) {
      thisRecipeData = { ...thisRecipeData, [checkIncompabilityArray[g].name]: true };
    }

    // Set elements true if form of nutrition exists
    for (let g = 0; g < checkFormOfNutritionArrayMerged.length; g += 1) {
      thisRecipeData = {
        ...thisRecipeData,
        [checkFormOfNutritionArrayMerged[g].name]: checkFormOfNutritionArrayMerged[g].status,
      };
    }

    if (existingRecipesArray !== undefined) {
      existingRecipesArray.push(breakfastObject.uid, lunchObject.uid, DinnerObject.uid, SnackObject.uid);
    }

    if (getLastRecipe.length > 0) {
      if (type !== 'plan' || dayEntriesObject === undefined) {
        handleOpenClosePopups(setGenerateDayPopupClass, '', 'close');
        setIsLoading(false);
        toast.success('Tag wurde erfolgreich generiert!');

        if (setVisibleIncompabilityPopup !== undefined) {
          if (userData?.incompatibilityAccept === undefined && userData?.age !== undefined) {
            setVisibleIncompabilityPopup(true);
          }
        }

        savePlanChangesToFirebase(
          userData,
          thisRecipeData,
          thisRecipeData.uid,
          authContext.user?.uid,
          true,
          setCurrentPlan,
          tenant
        );
        // setCurrentPlan(thisRecipeData);
      } else {
        dayEntriesObject.push(thisRecipeData.dayEntries.filter((item: any) => parseFloat(item.id) === thisDayId)[0]);
      }
    } else if (type === 'checkAgainWithoutRules' && getLastRecipe.length === 0) {
      setIsLoading(false);
      toast.warn(
        'Es wurden keine optimalen Kombinationen gefunden. Bitte prüfe deine Einstellungen und ergänze bzw. reduziere die Einschränkungen!'
      );
    } else if (type === 'plan') {
      setCheckSomeDayIsNotValid(true);
      dayEntriesObject.push(thisRecipeData.dayEntries.filter((item: any) => parseFloat(item.id) === thisDayId)[0]);
    }

    return 0;
  };

  const closeNotification = () => {
    setNotificationPopupClass('hidden');
  };

  const backToSettings = () => {
    setCurrentRecipeList([]);
    setShowResults(false);
    setGeneralContentClass('block');
    setCurrentKcalSum(0);
    setCurrentCarbohydratesSum(0);
    setCurrentProteinSum(0);
    setCurrentFatSum(0);
  };

  useEffect(() => {
    changeInitialGeneratePlanDayValues();
    backToSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateDayPopupClass]);

  useEffect(() => {
    if (checkSomeDayIsNotValid === true) {
      toast.warn(
        'Es konnten nicht bei allen Tagen passende Kombinationen gefunden werden. Bitte prüfe die Tage nochmal und versuche ggf. einzelne Tage neu zu generieren!'
      );
    }
  }, [checkSomeDayIsNotValid]);

  return (
    <>
      <div className={notificationPopupClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <ExclamationIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Hinweis</div>
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent} ref={generatePlanPopupRef}>
              <div className="mx-20 mb-40 pt-20">
                <div className="font-bold">Hinweis:</div>
                <div className="font-extralight">
                  Wir haben dir die Mahlzeit wie gewünscht neu generiert. Da du aber in den anderen Mahlzeiten entweder
                  schon etwas geplant hast oder irgendwo noch nichts geplant hast prüfe bitte nochmal die Vorschläge.
                </div>
                <div className="font-extralight pt-10">
                  In diesem Fall können wir nicht garantieren, dass die Vorschläge deinen aktuellen Bedarf ideal decken.
                </div>
                <div className="font-extralight pt-10">
                  Der beste Weg ist, wenn du dir deinen Tag generieren lässt und dann im Anschluss eine neue Mahlzeit
                  wenn die Vorschläge nicht passen.
                </div>
                <div className="pt-30">
                  <Button onClick={() => closeNotification()} className="w-full">
                    Hinweis schließen
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={generateDayPopupClass}>
        <div className={styles.backgroundPopupLayer}>
          <TransitionContainer isShown={generateDayPopupClass === 'block'} isOverlay>
            <div className={styles.editPicturePopup}>
              <div className="flex justify-between pt-20 pl-20">
                <div className="flex">
                  <div className="my-auto pr-10">
                    <RefreshIcon width={25} height={25} className="text-accentColor mx-auto" />
                  </div>
                  {planGenerateType === 'meal' ? (
                    <div className="text-xl my-auto font-light">Mahlzeit neu generieren</div>
                  ) : (
                    <div className="text-xl my-auto font-light">Tag neu generieren</div>
                  )}
                </div>
                <div className="my-auto pr-20">
                  <XIcon
                    width={25}
                    height={25}
                    className="text-accentColor mx-auto cursor-pointer"
                    onClick={() => handleOpenClosePopups(setGenerateDayPopupClass, '', 'close')}
                  />
                </div>
              </div>
              <div className={styles.editPictureIngridientPopupContent} ref={generatePlanPopupRef}>
                {showResults === true && (
                  <div>
                    {currentRecipeList.length > 0 && (
                      <>
                        <div
                          className="mx-20 pt-20"
                          onClick={() => backToSettings()}
                          onKeyDown={() => backToSettings()}
                          aria-hidden="true"
                        >
                          <ButtonBack
                            text="Zurück zu den Einstellungen"
                            className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                          />
                        </div>
                        <div className="mx-20 font-extralight pt-10">
                          Folgende Rezepte würden zu dieser Mahlzeit passen.
                        </div>
                        <div className="mx-20 font-extralight pb-20 pt-5">
                          Bitte wähle ein Rezept aus der Liste aus!
                        </div>
                        {currentRecipeList.map((recipe, index) => {
                          return (
                            <>
                              <div className="mx-20 mb-20 flex" key={index}>
                                <div className="flex-1">
                                  <div
                                    onClick={() => saveGenerateMealRecipe(recipe)}
                                    onKeyDown={() => saveGenerateMealRecipe(recipe)}
                                    aria-hidden="true"
                                  >
                                    <div className={styles.contentItem}>
                                      <div className={styles.imageWrapper}>
                                        <LazyLoadImage
                                          src={
                                            recipe.imageUrl
                                              ? recipe.imageUrl
                                              : theme?.logo !== undefined && userData?.role !== 2
                                              ? theme.logo
                                              : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
                                          }
                                          alt=""
                                          width={45}
                                          height={45}
                                          className={styles.image}
                                        />
                                      </div>
                                      <div className={styles.description}>
                                        <div className="pr-5">
                                          <div className={styles.itemTitle} title={recipe.name}>
                                            {recipe.name}
                                          </div>
                                        </div>
                                        <div className={styles.category}>
                                          <div className={styles.item}>
                                            <div className="text-12">{Math.round(parseFloat(recipe.kcal_total))}</div>
                                            <div>kcal</div>
                                          </div>
                                          <div className={styles.itemSecondary}>
                                            <div className="text-12">
                                              {Math.round(parseFloat(recipe.carbohydrates_total))}
                                            </div>
                                            <div>KH</div>
                                          </div>
                                          <div className={styles.itemSecondary}>
                                            <div className="text-12">
                                              {Math.round(parseFloat(recipe.protein_total))}
                                            </div>
                                            <div>EW</div>
                                          </div>
                                          <div className={styles.itemSecondary}>
                                            <div className="text-12">{Math.round(parseFloat(recipe.fat_total))}</div>
                                            <div>Fett</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="pt-5">
                                    <Button onClick={() => saveGenerateMealRecipe(recipe)} className="text-12 py-1">
                                      Rezept auswählen
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    )}
                  </div>
                )}
                <div className={generalContentClass}>
                  <div className="pt-20 pb-10 mx-20 text-20 font-bold">Einstellungen</div>
                  {planSettings !== undefined ? (
                    <div className="mx-20 text-14 font-extralight">
                      <span className="font-normal">
                        Deine Einstellungen, die du beim Erstellen des Plans eingestellt hast sind bereits
                        voreingestellt!
                      </span>
                    </div>
                  ) : (
                    <div className="mx-20 text-14 font-extralight">
                      In den Bereichen{' '}
                      <span className="font-normal">
                        {' '}
                        Ernährungsform, Unverträglichkeiten, Essverhalten und Lebensmittel
                      </span>{' '}
                      sind deine im Profil gespeicherten Daten bereits voreingestellt!
                    </div>
                  )}
                  <div
                    className="pb-10 mx-20 pt-10 flex gap-5 flex-wrap cursor-pointer text-14"
                    onClick={() => resetFilter()}
                    onKeyDown={() => resetFilter()}
                    aria-hidden="true"
                  >
                    <div className="my-auto">
                      <RefreshIcon width={20} height={20} className="text-accentColor mx-auto cursor-pointer" />
                    </div>
                    <div className="hover:underline">Alle Einstellungen zurücksetzen</div>
                  </div>
                  <div className="mx-20 pt-20 mb-14">
                    <SwitchButton
                      label="Meine Rezepte und Favoriten bevorzugen?"
                      enabled={planGenerateSettingsUseFavorites}
                      onChange={setMyFavorites}
                      notContainer
                      isCapitalize={false}
                    />
                  </div>
                  <hr className={styles.divider} />
                  <div
                    className="flex pt-15 mx-20 mb-15 cursor-pointer"
                    id="descriptionJumpContainerDesktop"
                    onClick={() => toggleDifficultyLevel()}
                    onKeyDown={() => toggleDifficultyLevel()}
                    aria-hidden="true"
                  >
                    <div className="font-light text-20">{t('Level of difficulty')}</div>
                    <div className="pl-5 my-auto">
                      <ChevronDownIcon className={difficultyChevronClass} height={26} width={26} />
                    </div>
                  </div>
                  <div className={difficultyClass}>
                    <div className="pb-15">
                      <div className="font-extralight text-12 mx-20">
                        Klicke hier die Schwierigkeits-Level an, die berücksichtigt werden sollen.
                      </div>
                      <div className="font-extralight text-12 pb-10 mx-20">
                        Wenn du nichts auswählst, werden alle berücksichtigt.
                      </div>
                      <ButtonGroup
                        options={difficultyGroup}
                        initialOption={initialDifficulty}
                        itemsClassName="grid-cols-3"
                        className="mx-20"
                        changeSelectedItem={changeDifficultyValue}
                      />
                    </div>
                  </div>
                  <hr className={styles.divider} />
                  <div
                    className="flex pt-15 mx-20 mb-15 cursor-pointer"
                    id="descriptionJumpContainerDesktop"
                    onClick={() => toggleFormOfNutrition()}
                    onKeyDown={() => toggleFormOfNutrition()}
                    aria-hidden="true"
                  >
                    <div className="font-light text-20">{t('Form of nutrition')}</div>
                    <div className="pl-5 my-auto">
                      <ChevronDownIcon className={formOfNutritionChevronClass} height={26} width={26} />
                    </div>
                  </div>
                  <div className={formOfNutritionClass}>
                    <div className="pb-15">
                      <div className="font-extralight text-12 mx-20">
                        Klicke hier die Ernährungsformen an, die berücksichtigt werden sollen.
                      </div>
                      <div className="font-extralight text-12 pb-10 mx-20">
                        Wenn du nichts auswählst, werden alle berücksichtigt.
                      </div>
                      <ButtonGroup
                        options={formOfNutrition}
                        initialOption={initialNutrition}
                        isRadio
                        itemsClassName="grid-cols-3"
                        className="mx-20"
                        changeSelectedItem={changeNutritionValue}
                      />
                    </div>
                  </div>
                  <hr className={styles.divider} />
                  <div
                    className="flex pt-15 mx-20 mb-15 cursor-pointer"
                    id="descriptionJumpContainerDesktop"
                    onClick={() => toggleIncompatibilites()}
                    onKeyDown={() => toggleIncompatibilites()}
                    aria-hidden="true"
                  >
                    <div className="font-light text-20">Unverträglichkeiten</div>
                    <div className="pl-5 my-auto">
                      <ChevronDownIcon className={incompatibilitesChevronClass} height={26} width={26} />
                    </div>
                  </div>
                  <div className={incompatibilitesClass}>
                    <div className="pb-15">
                      <div className="font-extralight text-12 pb-10 mx-20">
                        Klicke hier die Unverträglichkeiten an, die berücksichtigt werden sollen.
                      </div>
                      <ButtonGroup
                        options={intolerancesOption}
                        itemsClassName="grid-cols-3"
                        initialOption={initialIntolerances}
                        className="mx-20"
                        changeSelectedItem={changeIncompatibilites}
                      />
                    </div>
                  </div>
                  <hr className={styles.divider} />
                  <div
                    className="flex pt-15 mx-20 mb-15 cursor-pointer"
                    id="descriptionJumpContainerDesktop"
                    onClick={() => toggleEatingHabits()}
                    onKeyDown={() => toggleEatingHabits()}
                    aria-hidden="true"
                  >
                    <div className="font-light text-20">Essverhalten</div>
                    <div className="pl-5 my-auto">
                      <ChevronDownIcon className={eatingHabitsChevronClass} height={26} width={26} />
                    </div>
                  </div>
                  <div className={eatingHabitsClass}>
                    <div className="pb-15">
                      <div className="font-extralight text-12  mx-20">
                        Klicke hier die Essensverhalten-Merkmal an, die berücksichtigt werden sollen.
                      </div>
                      <div className="font-extralight text-12 pb-10 mx-20">
                        Wenn du nichts auswählst, werden alle berücksichtigt.
                      </div>
                      <ButtonGroup
                        options={eatingHabitsAlghorithmus}
                        itemsClassName="grid-cols-2"
                        initialOption={initialEatingHabits}
                        className="mx-20"
                        changeSelectedItem={changeEatingHabitsValue}
                      />
                    </div>
                  </div>
                  <hr className={styles.divider} />
                  <div
                    className="flex pt-15 mx-20 mb-15 cursor-pointer"
                    id="descriptionJumpContainerDesktop"
                    onClick={() => toggleIngredientsNotInclude()}
                    onKeyDown={() => toggleIngredientsNotInclude()}
                    aria-hidden="true"
                  >
                    <div className="font-light text-20">Lebensmittel ausschließen?</div>
                    <div className="pl-5 my-auto">
                      <ChevronDownIcon className={ingredientsNotIncludeChevronClass} height={26} width={26} />
                    </div>
                  </div>
                  <div className={ingredientsNotIncludeClass}>
                    <div className="pb-15 mx-20">
                      <div className="font-extralight text-12 pb-10">
                        Trage hier Lebensmittel ein, die wir ausschließen sollen.
                      </div>
                      {ingredientsItems.length > 0 && (
                        <CustomSelect
                          name="notGoal"
                          className={styles.selector}
                          dropDownOption={ingredientsItems}
                          isSearchable
                          isMulti
                          onChange={(value: OptionType[] | OptionType) => changeIngredientNotIncludeGroup(value)}
                          defaultValue={userData?.ingredientsNotLike?.map(item => ({
                            value: item.name.toLowerCase(),
                            label: item.name,
                          }))}
                          thisRef={selectNotIncludeRef}
                          isFullSize
                        />
                      )}
                    </div>
                  </div>
                  <div className="pt-30 pl-20 flex gap-30 pb-40">
                    <div className="w-full">
                      {planGenerateType === 'meal' ? (
                        <Button
                          className="w-full"
                          onClick={() => !isLoading && generatePlanMeal('userClick', currentMealType)}
                        >
                          {isLoading ? (
                            <ReactLoading
                              type="bars"
                              width={30}
                              height={30}
                              color={theme?.textColor ?? defaultTextColor}
                            />
                          ) : (
                            <>Mahlzeit neu generieren</>
                          )}
                        </Button>
                      ) : (
                        <Button className="w-full" onClick={() => !isLoading && generatePlanDay('userClick')}>
                          {isLoading ? (
                            <ReactLoading
                              type="bars"
                              width={30}
                              height={30}
                              color={theme?.textColor ?? defaultTextColor}
                            />
                          ) : (
                            <>Tag neu generieren</>
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TransitionContainer>
        </div>
      </div>

      {isNewPlan && (
        <>
          <div className="mx-20 text-14 font-extralight">
            In den Bereichen{' '}
            <span className="font-normal"> Ernährungsform, Unverträglichkeiten, Essverhalten und Lebensmittel</span>{' '}
            sind deine im Profil gespeicherten Daten bereits voreingestellt!
          </div>
          <div
            className="pb-10 mx-20 pt-10 flex gap-5 flex-wrap cursor-pointer text-14"
            onClick={() => resetFilter()}
            onKeyDown={() => resetFilter()}
            aria-hidden="true"
          >
            <div className="my-auto">
              <RefreshIcon width={20} height={20} className="text-accentColor mx-auto cursor-pointer" />
            </div>
            <div className="hover:underline">Alle Einstellungen zurücksetzen</div>
          </div>
          <div className="mx-20 pt-20 mb-14">
            <SwitchButton
              label="Meine Rezepte und Favoriten bevorzugen?"
              enabled={planGenerateSettingsUseFavorites}
              onChange={setMyFavorites}
              notContainer
              isCapitalize={false}
            />
          </div>
          <hr className={styles.divider} />
          <div
            className="flex pt-15 mx-20 mb-15 cursor-pointer"
            id="descriptionJumpContainerDesktop"
            onClick={() => toggleDifficultyLevel()}
            onKeyDown={() => toggleDifficultyLevel()}
            aria-hidden="true"
          >
            <div className="font-light text-20">{t('Level of difficulty')}</div>
            <div className="pl-5 my-auto">
              <ChevronDownIcon className={difficultyChevronClass} height={26} width={26} />
            </div>
          </div>
          <div className={difficultyClass}>
            <div className="pb-15">
              <div className="font-extralight text-12 mx-20">
                Klicke hier die Schwierigkeits-Level an, die berücksichtigt werden sollen.
              </div>
              <div className="font-extralight text-12 pb-10 mx-20">
                Wenn du nichts auswählst, werden alle berücksichtigt.
              </div>
              <ButtonGroup
                options={difficultyGroup}
                initialOption={initialDifficulty}
                itemsClassName="grid-cols-3"
                className="mx-20"
                changeSelectedItem={changeDifficultyValue}
              />
            </div>
          </div>
          <hr className={styles.divider} />
          <div
            className="flex pt-15 mx-20 mb-15 cursor-pointer"
            id="descriptionJumpContainerDesktop"
            onClick={() => toggleFormOfNutrition()}
            onKeyDown={() => toggleFormOfNutrition()}
            aria-hidden="true"
          >
            <div className="font-light text-20">{t('Form of nutrition')}</div>
            <div className="pl-5 my-auto">
              <ChevronDownIcon className={formOfNutritionChevronClass} height={26} width={26} />
            </div>
          </div>
          <div className={formOfNutritionClass}>
            <div className="pb-15">
              <div className="font-extralight text-12 mx-20">
                Klicke hier die Ernährungsformen an, die berücksichtigt werden sollen.
              </div>
              <div className="font-extralight text-12 pb-10 mx-20">
                Wenn du nichts auswählst, werden alle berücksichtigt.
              </div>
              <ButtonGroup
                options={formOfNutrition}
                initialOption={initialNutrition}
                isRadio
                itemsClassName="grid-cols-3"
                className="mx-20"
                changeSelectedItem={changeNutritionValue}
              />
            </div>
          </div>
          <hr className={styles.divider} />
          <div
            className="flex pt-15 mx-20 mb-15 cursor-pointer"
            id="descriptionJumpContainerDesktop"
            onClick={() => toggleIncompatibilites()}
            onKeyDown={() => toggleIncompatibilites()}
            aria-hidden="true"
          >
            <div className="font-light text-20">Unverträglichkeiten</div>
            <div className="pl-5 my-auto">
              <ChevronDownIcon className={incompatibilitesChevronClass} height={26} width={26} />
            </div>
          </div>
          <div className={incompatibilitesClass}>
            <div className="pb-15">
              <div className="font-extralight text-12 pb-10 mx-20">
                Klicke hier die Unverträglichkeiten an, die berücksichtigt werden sollen.
              </div>
              <ButtonGroup
                options={intolerancesOption}
                itemsClassName="grid-cols-3"
                initialOption={initialIntolerances}
                className="mx-20"
                changeSelectedItem={changeIncompatibilites}
              />
            </div>
          </div>
          <hr className={styles.divider} />
          <div
            className="flex pt-15 mx-20 mb-15 cursor-pointer"
            id="descriptionJumpContainerDesktop"
            onClick={() => toggleEatingHabits()}
            onKeyDown={() => toggleEatingHabits()}
            aria-hidden="true"
          >
            <div className="font-light text-20">Essverhalten</div>
            <div className="pl-5 my-auto">
              <ChevronDownIcon className={eatingHabitsChevronClass} height={26} width={26} />
            </div>
          </div>
          <div className={eatingHabitsClass}>
            <div className="pb-15">
              <div className="font-extralight text-12  mx-20">
                Klicke hier die Essensverhalten-Merkmal an, die berücksichtigt werden sollen.
              </div>
              <div className="font-extralight text-12 pb-10 mx-20">
                Wenn du nichts auswählst, werden alle berücksichtigt.
              </div>
              <ButtonGroup
                options={eatingHabitsAlghorithmus}
                itemsClassName="grid-cols-2"
                initialOption={initialEatingHabits}
                className="mx-20"
                changeSelectedItem={changeEatingHabitsValue}
              />
            </div>
          </div>
          <hr className={styles.divider} />
          <div
            className="flex pt-15 mx-20 mb-15 cursor-pointer"
            id="descriptionJumpContainerDesktop"
            onClick={() => toggleIngredientsNotInclude()}
            onKeyDown={() => toggleIngredientsNotInclude()}
            aria-hidden="true"
          >
            <div className="font-light text-20">Lebensmittel ausschließen?</div>
            <div className="pl-5 my-auto">
              <ChevronDownIcon className={ingredientsNotIncludeChevronClass} height={26} width={26} />
            </div>
          </div>
          <div className={ingredientsNotIncludeClass}>
            <div className="pb-15 mx-20">
              <div className="font-extralight text-12 pb-10">
                Trage hier Lebensmittel ein, die wir ausschließen sollen.
              </div>
              {ingredientsItems.length > 0 && (
                <CustomSelect
                  name="notGoal"
                  className={styles.selector}
                  dropDownOption={ingredientsItems}
                  isSearchable
                  isMulti
                  onChange={(value: OptionType[] | OptionType) => changeIngredientNotIncludeGroup(value)}
                  defaultValue={userData?.ingredientsNotLike?.map(item => ({
                    value: item.name.toLowerCase(),
                    label: item.name,
                  }))}
                  thisRef={selectNotIncludeRef}
                  isFullSize
                />
              )}
            </div>
          </div>
          <div className="pt-10">
            <Button className="w-full" onClick={() => !isLoading && generatePlan()}>
              {isLoading ? (
                <ReactLoading type="bars" width={30} height={30} color={theme?.textColor ?? defaultTextColor} />
              ) : (
                <>Plan erstellen</>
              )}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default GenerateAlghorithmus;
