import React from 'react';
import { Transition } from '@headlessui/react';
import styles from './style.module.scss';

type Props = {
  isShown: boolean;
  isOverlay?: boolean;
  children: React.ReactNode;
  className?: string;
};

const TransitionContainer: React.FC<Props> = ({ isShown, children, isOverlay, className }) => {
  return (
    <>
      {isOverlay !== undefined ? (
        <Transition
          show={isShown}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          {children}
        </Transition>
      ) : (
        <Transition
          show={isShown}
          appear
          enter={styles.enter}
          enterFrom={styles.enterFrom}
          enterTo={styles.enterTo}
          className={className}
        >
          {children}
        </Transition>
      )}
    </>
  );
};

export default TransitionContainer;
