import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import CoachingDashboardFoodItem from '../CoachingDashboardFoodItem';

type Props = {
  mealLabel: string;
  mealObject: any;
  userId: any;
  planUid: string;
  mealIdentifier: string;
  dayId: any;
  setPlanObject: any;
};

const CoachingDashboardPlanNutritionItem: React.FC<Props> = ({
  mealLabel,
  mealObject,
  userId,
  planUid,
  mealIdentifier,
  dayId,
  setPlanObject,
}) => {
  const [currentDayImage, setCurrentDayImage] = useState('');
  const [currentDayMealLabel, setCurrentDayMealLabel] = useState('');
  const [currentDayMealCounter, setCurrentDayMealCounter] = useState(0);

  const { theme } = useContext(AuthContext);

  const [nutritionItemsChevronClass, setNutritionItemsChevronClass] = useState(
    'text-accentColor mx-auto cursor-pointer'
  );
  const [nutritionItemsClass, setNutritionItemsClass] = useState('hidden');

  const getMealImage = () => {
    const thisItem = mealObject;

    let thisCounter = 0;
    if (thisItem) {
      if (thisItem.recipes.length > 0 || thisItem.ingredients.length > 0 || thisItem.manualItems?.length > 0) {
        if (thisItem.recipes.length > 0) {
          setCurrentDayImage(thisItem.recipes[0].imageUrl);
          setCurrentDayMealLabel(thisItem.recipes[0].name);
          thisCounter += thisItem.recipes.length;
        }
        if (thisItem.ingredients.length > 0) {
          thisCounter += thisItem.ingredients.length;
        }
        if (thisItem.ingredients.length > 0 && thisItem.recipes.length === 0) {
          if (thisItem.ingredients[0].imageUrl?.length > 0) {
            setCurrentDayImage(thisItem.ingredients[0].imageUrl);
          } else if (theme?.logo !== undefined) {
            setCurrentDayImage(theme.logo);
          } else {
            setCurrentDayImage(
              'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
            );
          }

          setCurrentDayMealLabel(thisItem.ingredients[0].name);
        }
        if (thisItem.manualItems !== undefined && thisItem.manualItems.length > 0) {
          thisCounter += thisItem.manualItems.length;
        }
      } else {
        if (theme?.logo !== undefined) {
          setCurrentDayImage(theme.logo);
        } else {
          setCurrentDayImage(
            'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
          );
        }

        setCurrentDayMealLabel('');
      }

      setCurrentDayMealCounter(thisCounter - 1);
    }
  };

  const toggleNutritionItem = (): void => {
    if (nutritionItemsClass === 'hidden') {
      setNutritionItemsClass('block');
      setNutritionItemsChevronClass(
        'text-accentColor mx-auto cursor-pointer transition duration-300 transform rotate-180'
      );
    } else {
      setNutritionItemsClass('hidden');
      setNutritionItemsChevronClass(
        'text-accentColor mx-auto cursor-pointer transition duration-300 transform rotate-360'
      );
    }
  };

  useEffect(() => {
    getMealImage();
  }, [mealObject]);

  return (
    <div className="bg-lightGrayDarker rounded-xl mb-10">
      <div className="flex justify-between gap-10 px-10 pt-10">
        <div className="w-1/4 flex-shrink-0">
          {currentDayImage?.length > 0 && (
            <img
              src={currentDayImage ?? ''}
              className={
                currentDayImage === theme?.logo
                  ? 'w-100 h-80 rounded-full object-contain'
                  : 'w-100 h-80 rounded-full object-cover'
              }
              loading="lazy"
              alt="zz"
            />
          )}
        </div>

        <div className="flex-1 flex flex-col justify-between">
          <div>
            <div className="text-8 opacity-50">{mealLabel}</div>
            <div className="font-semibold text-12 pb-2 w-3/4">
              <span className="text-textColor inline-block">{currentDayMealLabel}</span>
              {currentDayMealCounter > 0 && (
                <span className="pl-1 text-textColor inline-block">+{currentDayMealCounter}</span>
              )}
            </div>
          </div>
          <div className="pr-10 pb-5 mt-auto">
            <div className="flex justify-between opacity-50">
              <div>
                <div className="text-12 text-center">{mealObject?.kcal_total}</div>
                <div className="text-8 font-extralight text-center">kcal</div>
              </div>
              <div>
                <div className="text-12 text-center">{mealObject?.carbohydrates_total}</div>
                <div className="text-8 font-extralight text-center">KH</div>
              </div>
              <div>
                <div className="text-12 text-center">{mealObject?.protein_total}</div>
                <div className="text-8 font-extralight text-center">EW</div>
              </div>
              <div>
                <div className="text-12 text-center">{mealObject?.fat_total}</div>
                <div className="text-8 font-extralight text-center">FT</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {mealObject?.kcal_total > 0 ? (
        <div className="pb-1">
          <ChevronDownIcon
            width={25}
            height={25}
            className={nutritionItemsChevronClass}
            onClick={() => toggleNutritionItem()}
          />
        </div>
      ) : (
        <div className="h-30 pb-1" />
      )}

      <div className={nutritionItemsClass}>
        {mealObject?.recipes?.map((recipe: any, index: number) => (
          <div key={index}>
            <CoachingDashboardFoodItem
              foodObject={recipe}
              foodType="recipes"
              userId={userId}
              planUid={planUid}
              mealIdentifier={mealIdentifier}
              dayId={dayId}
              setPlanObject={setPlanObject}
            />
          </div>
        ))}

        {mealObject?.ingredients?.map((ingredient: any, index: number) => (
          <div key={index}>
            <CoachingDashboardFoodItem
              foodObject={ingredient}
              foodType="ingredients"
              userId={userId}
              planUid={planUid}
              mealIdentifier={mealIdentifier}
              dayId={dayId}
              setPlanObject={setPlanObject}
            />
          </div>
        ))}

        {mealObject?.manualItems?.map((manualItem: any, index: number) => (
          <div key={index}>
            <CoachingDashboardFoodItem
              foodObject={manualItem}
              foodType="manualItems"
              userId={userId}
              planUid={planUid}
              mealIdentifier={mealIdentifier}
              dayId={dayId}
              setPlanObject={setPlanObject}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoachingDashboardPlanNutritionItem;
