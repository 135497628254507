import React from 'react';
import { ReactComponent as logo } from 'assets/img/svg/logo.svg';
import { ReactComponent as home } from 'assets/img/svg/home.svg';
import { ReactComponent as plan } from 'assets/img/svg/plan.svg';
import { ReactComponent as profile } from 'assets/img/svg/profile.svg';
import { ReactComponent as recipes } from 'assets/img/svg/recipes.svg';
import { ReactComponent as cooking } from 'assets/img/svg/cooking.svg';
import { ReactComponent as mastercard } from 'assets/img/svg/mastercard.svg';
import { ReactComponent as stripe } from 'assets/img/svg/stripe.svg';
import { ReactComponent as paypal } from 'assets/img/svg/paypal.svg';
import { ReactComponent as visa } from 'assets/img/svg/visa.svg';
import { ReactComponent as amex } from 'assets/img/svg/amex.svg';
import { ReactComponent as rewe } from 'assets/img/svg/rewe-button-xl.svg';
import { ReactComponent as maestro } from 'assets/img/svg/maestrocard.svg';
import { ReactComponent as shoppingList } from 'assets/img/svg/shoppingList.svg';
import { ReactComponent as waterGlass } from 'assets/img/svg/glas_water.svg';
import { ReactComponent as stopWatch } from 'assets/img/svg/stopwatch.svg';
import { ReactComponent as barCode } from 'assets/img/svg/barcode.svg';
import { ReactComponent as sepa } from 'assets/img/svg/sepa.svg';
import { ReactComponent as waterDrop } from 'assets/img/svg/waterDrop.svg';
import { ReactComponent as emojiSad } from 'assets/img/svg/emoji-sad.svg';
import { ReactComponent as emojiNeutral } from 'assets/img/svg/emoji-neutral.svg';
import { ReactComponent as emojiHappy } from 'assets/img/svg/emoji-smile.svg';
import { ReactComponent as emojiHeart } from 'assets/img/svg/emoji-heart.svg';
import { ReactComponent as userGroup } from 'assets/img/svg/user-group.svg';
import { ReactComponent as office } from 'assets/img/svg/office.svg';
import { ReactComponent as settings } from 'assets/img/svg/settings.svg';
import { ReactComponent as zapier } from 'assets/img/svg/zapier.svg';
import { ReactComponent as pdfFile } from 'assets/img/svg/pdfFile.svg';
import { ReactComponent as hantel } from 'assets/img/svg/hantel.svg';
import { ReactComponent as walking } from 'assets/img/svg/walking.svg';
import { ReactComponent as clipboard } from 'assets/img/svg/clipboard.svg';
import { ReactComponent as archive } from 'assets/img/svg/archive.svg';
import { ReactComponent as copy } from 'assets/img/svg/copy-icon.svg';
import { ReactComponent as trash } from 'assets/img/svg/trash.svg';
import { ReactComponent as sortUp } from 'assets/img/svg/arrow-up.svg';
import { ReactComponent as sortDown } from 'assets/img/svg/arrow-down.svg';
import { ReactComponent as sortUpDown } from 'assets/img/svg/arrows-down-up.svg';
import { ReactComponent as stop } from 'assets/img/svg/stop-circle.svg';
import { ReactComponent as arrowDoubleUpLeft } from 'assets/img/svg/arrow-bend-double-up-left.svg';
import { ReactComponent as eye } from 'assets/img/svg/eye.svg';
import { ReactComponent as plus } from 'assets/img/svg/plus.svg';
import { ReactComponent as star } from 'assets/img/svg/star-fill.svg';
import { ReactComponent as flagDe } from 'assets/img/svg/flag_de.svg';
import { ReactComponent as flagAt } from 'assets/img/svg/flag_at.svg';
import { ReactComponent as flagCh } from 'assets/img/svg/flag_ch.svg';
import { ReactComponent as chat } from 'assets/img/svg/chat.svg';

export type IconType =
  | 'logo'
  | 'shoppingList'
  | 'recipes'
  | 'profile'
  | 'plan'
  | 'home'
  | 'cooking'
  | 'mastercard'
  | 'stripe'
  | 'amex'
  | 'rewe'
  | 'zapier'
  | 'paypal'
  | 'visa'
  | 'hantel'
  | 'walking'
  | 'maestro'
  | 'waterGlass'
  | 'stopWatch'
  | 'sepa'
  | 'waterDrop'
  | 'emojiSad'
  | 'emojiNeutral'
  | 'emojiHappy'
  | 'emojiHeart'
  | 'userGroup'
  | 'office'
  | 'settings'
  | 'barCode'
  | 'pdfFile'
  | 'clipboard'
  | 'archive'
  | 'copy'
  | 'trash'
  | 'sortUp'
  | 'sortDown'
  | 'sortUpDown'
  | 'stop'
  | 'arrowDoubleUpLeft'
  | 'eye'
  | 'plus'
  | 'star'
  | 'flagDe'
  | 'flagAt'
  | 'flagCh'
  | 'pdfFile'
  | 'chat';

type IconProps = {
  name: IconType;
  width?: number;
  height?: number;
  className?: string;
};

const Icon: React.FC<IconProps> = ({ name, width, height, className }) => {
  if (!name) {
    return null;
  }

  const icons = {
    logo,
    home,
    plan,
    profile,
    recipes,
    shoppingList,
    cooking,
    mastercard,
    stripe,
    paypal,
    amex,
    zapier,
    rewe,
    maestro,
    hantel,
    walking,
    visa,
    waterGlass,
    stopWatch,
    waterDrop,
    emojiSad,
    emojiNeutral,
    emojiHappy,
    emojiHeart,
    sepa,
    userGroup,
    office,
    settings,
    barCode,
    pdfFile,
    clipboard,
    archive,
    copy,
    trash,
    sortUp,
    sortDown,
    sortUpDown,
    stop,
    arrowDoubleUpLeft,
    eye,
    plus,
    star,
    flagDe,
    flagAt,
    flagCh,
    chat,
  };

  const CurrentIcon = icons[name];

  return <CurrentIcon width={width} height={height} className={className} />;
};

export default Icon;
