import React from 'react';
import CoachingDashBoardUserSurvey from '../CoachingDashBoardUserSurvey';

type Props = {
  userData?: any;
};

const CoachingDashboardCheckIn: React.FC<Props> = ({ userData }) => {
  return (
    <div>
      <CoachingDashBoardUserSurvey />
    </div>
  );
};

export default CoachingDashboardCheckIn;
