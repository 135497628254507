import { XMarkIcon as XIcon } from '@heroicons/react/24/solid';
import { defaultTextColor } from 'layouts/Theme';
import { AuthContext } from 'providers/AuthProvider';
import { useHistory } from 'react-router-dom';
import ReactLoading from 'react-loading';
import axios from 'axios';
import React, { useState, useContext } from 'react';

import firebase from 'services/firebase';

type Props = {};

const ImpersonateHeader: React.FC<Props> = () => {
  const currentUserToken = localStorage.getItem('currentUserToken');

  const { theme } = useContext(AuthContext);

  const history = useHistory();

  const [exitLoading, setExitLoading] = useState(false);

  if (!currentUserToken) {
    return null;
  }

  /* const handleExit = async () => {
    setExitLoading(true);
    const userUid = firebase.auth().currentUser?.uid;

    await firebase
      .auth()
      .signInWithCustomToken(currentUserToken)
      .catch(async error => {
        const getTenantUserUid = localStorage.getItem('currentUserTokenID');

        const regenrateToken = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/getUserToken`, {
          userId: getTenantUserUid,
        });

        const impersonateToken = regenrateToken.data?.token;

        await firebase
          .auth()
          .signInWithCustomToken(impersonateToken)
          .catch(errorPart2 => {
            setExitLoading(false);
            console.log(errorPart2);
          });

        setExitLoading(false);
        console.log(error);
      });
    localStorage.removeItem('currentUserToken');
    localStorage.removeItem('currentUserTokenID');
    setExitLoading(false);
    if (userUid) {
      window.location.href = `/member/detail/${userUid}`;
    } else {
      window.location.href = '/member?reload=true';
    }
  }; */

  const handleExit = async () => {
    const userUid = firebase.auth().currentUser?.uid;

    await firebase
      .auth()
      .signInWithCustomToken(currentUserToken)
      .catch(async error => {
        console.log(error);
        await firebase.auth().signOut();
        localStorage.removeItem('currentUserToken');
        window.location.reload();
      });
    localStorage.removeItem('currentUserToken');
    if (userUid) {
      history.replace(`/member/detail/${userUid}`);
      window.location.reload();
    } else {
      history.replace('/member');
      window.location.reload();
    }
  };

  return (
    <div className="bg-lightGray p-15 w-full desktop:w-500 bottom-80 desktop:bottom-auto text-12 right-0 desktop:text-14 desktop:top-0 absolute z-30 flex flex-wrap justify-between">
      <div>
        <span className="font-bold">Ansicht:</span> {firebase.auth().currentUser?.email}
      </div>
      {exitLoading ? (
        <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
      ) : (
        <button type="button" onClick={handleExit} className="flex items-center">
          <XIcon className="mr-5" height={20} />
          verlassen
        </button>
      )}
    </div>
  );
};

export default ImpersonateHeader;
