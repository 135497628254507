import React, { useEffect, useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import firebase from 'services/firebase';
import { toast } from 'react-toast';
import Button from 'components/Button';
import CoachingDashboardPlanNutritionItem from '../CoachingDashboardPlanNutritionItem';

type Props = {
  planObject?: any;
  userId?: any;
  planUid: string;
  startIndex: number;
  selectedWeek: number;
};

const CoachingDashboardPlanItem: React.FC<Props> = ({ planObject, userId, planUid, startIndex, selectedWeek }) => {
  const [impersonateLoading, setImpersonateLoading] = useState(false);

  const [thisPlanObject, setThisPlanObject] = useState<any>(planObject);

  const editDay = async () => {
    setImpersonateLoading(true);
    const token = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/getUserToken`, {
      userId,
    });

    const impersonateToken = token.data?.token;

    if (impersonateToken) {
      const currentUserId = firebase.auth().currentUser?.uid;
      const currentUserToken = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/getUserToken`, {
        userId: currentUserId,
      });

      if (currentUserToken) {
        localStorage.setItem('currentUserToken', currentUserToken.data?.token);
        if (currentUserId) {
          localStorage.setItem('currentUserTokenID', currentUserId);
        }
        if (planUid.length > 0) {
          localStorage.setItem('coachingDashBoardArea', 'plan');
          localStorage.setItem('coachingDashBoardPlanStartIndex', startIndex.toString());
          localStorage.setItem('coachingDashBoardPlanSelectedWeek', selectedWeek.toString());
          localStorage.setItem(
            'coachingDashBoardDashboardDate',
            moment.unix(thisPlanObject?.date.seconds).subtract(1, 'days').format('DD.MM.YYYY')
          );
        }
        firebase
          .auth()
          .signInWithCustomToken(impersonateToken)
          .catch(error => {
            console.log(error);
          });
      }
    } else {
      toast.error('Es ist leider etwas schief gelaufen. Bitte versuchen Sie es später erneut!');
    }
  };

  return (
    <>
      <div className="bg-lightGray rounded-xl px-20 pb-20 h-full">
        <div className="pt-20">
          <div className="flex justify-between flex-wrap">
            <div>
              <div className="font-bold my-auto">
                {moment.unix(thisPlanObject?.date.seconds).subtract(1, 'days').format('dddd')}
              </div>
              <div className="font-extralight text-12">
                {moment.unix(thisPlanObject?.date.seconds).subtract(1, 'days').format('DD.MM.YYYY')}
              </div>
            </div>
            <div className="flex gap-20 flex-wrap my-auto">
              <div className="opacity-50">
                <div className="text-12 text-center">{thisPlanObject?.kcal_total ?? 0}</div>
                <div className="text-8 font-extralight text-center">kcal</div>
              </div>
              <div className="opacity-50">
                <div className="text-12 text-center">{thisPlanObject?.carbohydrates_total ?? 0}</div>
                <div className="text-8 font-extralight text-center">KH</div>
              </div>
              <div className="opacity-50">
                <div className="text-12 text-center">{thisPlanObject?.protein_total ?? 0}</div>
                <div className="text-8 font-extralight text-center">EW</div>
              </div>
              <div className="opacity-50">
                <div className="text-12 text-center">{thisPlanObject?.fat_total ?? 0}</div>
                <div className="text-8 font-extralight text-center">FT</div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end pt-10">
          <Button className="text-12 py-5" onClick={() => editDay()} isPending={impersonateLoading}>
            Tag bearbeiten
          </Button>
        </div>
        <div className="pt-15">
          <CoachingDashboardPlanNutritionItem
            mealLabel="Frühstück"
            mealIdentifier="breakfast"
            dayId={thisPlanObject?.id}
            mealObject={thisPlanObject?.breakfast}
            userId={userId}
            planUid={planUid}
            setPlanObject={setThisPlanObject}
          />
          <CoachingDashboardPlanNutritionItem
            mealLabel="Mittagessen"
            mealIdentifier="lunch"
            dayId={thisPlanObject?.id}
            mealObject={thisPlanObject?.lunch}
            userId={userId}
            planUid={planUid}
            setPlanObject={setThisPlanObject}
          />
          <CoachingDashboardPlanNutritionItem
            mealLabel="Abendessen"
            mealIdentifier="dinner"
            dayId={thisPlanObject?.id}
            mealObject={thisPlanObject?.dinner}
            userId={userId}
            planUid={planUid}
            setPlanObject={setThisPlanObject}
          />
          <CoachingDashboardPlanNutritionItem
            mealLabel="Snacks"
            mealIdentifier="snacks"
            dayId={thisPlanObject?.id}
            mealObject={thisPlanObject?.snacks}
            userId={userId}
            planUid={planUid}
            setPlanObject={setThisPlanObject}
          />
        </div>
      </div>
    </>
  );
};

export default CoachingDashboardPlanItem;
