import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import ButtonBack from 'components/ButtonBack';
import Headline from 'components/Headline';
import { getFrequencyNaming, getStatusNaming, getAnswersFromUser } from 'shared/functions/surveyFunctions';
import { Answer, Survey } from 'shared/types/survey';
import QuestionItem from 'pages/Surveys/userSurveys/components/QuestionItem';
import CustomSelect from 'components/CustomSelect';
import { OptionType } from 'react-switch-selector/dist/SwitchSelector';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

import styles from './styles.module.scss';

interface Props {
  survey: Survey;
  allUsers: { id: string; fullName: string }[];
}

const ViewAnswers: React.FC<Props> = ({ survey, allUsers }) => {
  const [selectedAssignee, setSelectedAssignee] = useState<{ id: string; fullName: string } | null>(null);
  const [selectedIteration, setSelectedIteration] = useState<number>(
    survey.iterations[survey.iterations.length - 1].iteration
  );

  const assignees = survey.assignees.includes('all')
    ? allUsers
    : survey.assignees.map(assignee => ({ id: assignee.id, fullName: assignee.fullName }));

  const assigneesUnanswered = assignees.filter(assignee => {
    const iterationAnswers = survey.questions
      .flatMap(question => question.answers)
      .filter(answer => answer?.iteration === selectedIteration);

    return !iterationAnswers.some(answer => answer?.creatorId === assignee.id);
  });

  const assigneesAnswered = assignees.filter(assignee => {
    const iterationAnswers = survey.questions
      .flatMap(question => question.answers)
      .filter(answer => answer?.iteration === selectedIteration);

    return iterationAnswers.some(answer => answer?.creatorId === assignee.id);
  });

  const selectedUserAnswers = getAnswersFromUser(survey.questions, selectedAssignee?.id, selectedIteration);

  const handleSelectAssignee = (value: OptionType | OptionType[]) => {
    if (Array.isArray(value)) {
      return;
    }
    setSelectedAssignee({ id: value.value as string, fullName: value.label as string });
  };

  const handleSelectIterationDown = () => {
    if (selectedIteration === 0) {
      return;
    }
    setSelectedIteration(selectedIteration - 1);
  };

  const handleSelectIterationUp = () => {
    if (selectedIteration === survey.iterations[survey.iterations.length - 1].iteration) {
      return;
    }
    setSelectedIteration(selectedIteration + 1);
  };

  useEffect(() => {
    setSelectedAssignee(assigneesAnswered[0]);
  }, [selectedIteration]);

  return (
    <section className={styles.surveyDetailContainer}>
      <Link to="/surveys" className={styles.backBtnWrapper}>
        <ButtonBack className={styles.backBtn} text="Zurück" />
      </Link>

      <div className={styles.nameWrapper}>
        <Headline level={1} className={styles.surveyTitle}>
          {survey.surveyTitle}
        </Headline>
        <p className={styles.status}>{getStatusNaming(survey.status)}</p>
      </div>

      <div className={styles.smallInfoWrapper}>
        <p className={styles.smallInfoText}>Frequenz</p>
        <p>{getFrequencyNaming(survey.frequency)}</p>
      </div>

      <div className={styles.assigneeWrapper}>
        {assigneesAnswered.length > 0 ? (
          <CustomSelect
            name="User selection answered"
            dropDownOption={assigneesAnswered.map(assignee => ({ label: assignee.fullName, value: assignee.id }))}
            value={selectedAssignee ? { label: selectedAssignee.fullName, value: selectedAssignee.id } : null}
            onChange={handleSelectAssignee}
            isSearchable={!!selectedAssignee}
            label={`Antworten einsehen (${assigneesAnswered.length})`}
            className={styles.selectWrapper}
            placeholder={selectedAssignee ? '' : 'Keine Antworten'}
          />
        ) : (
          <div className={styles.noResultsContainer}>
            <label className={styles.noResultsLabel}>Antworten einsehen</label>
            <p className={classNames(styles.noResults, 'react-select-container')}>Keine Antworten</p>
          </div>
        )}

        {assigneesUnanswered.length > 0 ? (
          <CustomSelect
            name="User selection unanswered"
            dropDownOption={assigneesUnanswered.map(assignee => ({ label: assignee.fullName, value: assignee.id }))}
            label={`Ausstehende Antworten (${assigneesUnanswered.length})`}
            className={styles.selectWrapper}
            isSearchable={assigneesUnanswered.length > 0}
            placeholder={assigneesUnanswered.length === 0 ? 'Alle Kunden haben geantwortet' : 'Kunden einsehen'}
            controlShouldRenderValue={false}
          />
        ) : (
          <div className={styles.noResultsContainer}>
            <label className={styles.noResultsLabel}>Ausstehende Antworten</label>
            <p className={classNames(styles.noResults, 'react-select-container')}>Alle Kunden haben geantwortet</p>
          </div>
        )}
      </div>

      {survey.iterations.length > 1 && (
        <div className={styles.iterationWrapper}>
          <button
            className={styles.iterationBtn}
            disabled={selectedIteration === 0}
            type="button"
            onClick={handleSelectIterationDown}
          >
            <ChevronLeftIcon />
          </button>

          <p>Umfrage vom</p>
          <p>{survey.iterations[selectedIteration].startDate.toLocaleDateString()}</p>

          <button
            className={styles.iterationBtn}
            disabled={selectedIteration === survey.iterations[survey.iterations.length - 1].iteration}
            type="button"
            onClick={handleSelectIterationUp}
          >
            <ChevronRightIcon />
          </button>
        </div>
      )}

      <div className={styles.questionsWrapper}>
        {survey.questions
          .sort((a, b) => (a.position || 0) - (b.position || 0))
          .map(question => (
            <QuestionItem
              key={question.id}
              question={question}
              isViewOnly
              viewAnswer={selectedUserAnswers.find(answer => answer?.questionId === question.id) as Answer}
              answerForEdit={selectedUserAnswers.find(answer => answer?.questionId === question.id) as Answer}
              onChangeAnswer={() => {}}
              placeholder="Nicht beantwortet"
            />
          ))}
      </div>
    </section>
  );
};

export default ViewAnswers;
