import React, { useEffect, useContext, useState } from 'react';
import firebase from 'services/firebase';
import moment from 'moment';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { AuthContext } from 'providers/AuthProvider';
import ReactLoading from 'react-loading';
import CoachingDashboardPlanItem from './components/CoachingDashboardPlanItem';
import CoachingDashboardPlanItemEmpty from './components/CoachingDashboardPlanItemEmpty';
import styles from './styles.module.scss';

type Props = {
  userData?: any;
  userId?: string;
};

const CoachingDashboardPlan: React.FC<Props> = ({ userData, userId }) => {
  const { tenant } = useContext(AuthContext);

  const [userPlanList, setUserPlanList] = useState<any>([]);
  const [planListLoaded, setPlanListLoaded] = useState(false);

  const [fillDaysIndex, setFillDaysIndex] = useState(0);

  const [planIterationsChecked, setPlanIterationsChecked] = useState(false);

  const [displayedPlanFirstItem, setDisplayedPlanFirstItem] = useState<any>({});
  const [displayedPlanSecondItem, setDisplayedPlanSecondItem] = useState<any>({});
  const [displayedPlanThirdItem, setDisplayedPlanThirdItem] = useState<any>({});

  const [firstPlanItemUid, setFirstPlanItemUid] = useState<any>('');
  const [secondPlanItemUid, setSecondPlanItemUid] = useState<any>('');
  const [thirdPlanItemUid, setThirdPlanItemUid] = useState<any>('');

  const [startIndex, setStartIndex] = useState(0);

  // Zustand für die mittlere Woche
  const [currentWeek, setCurrentWeek] = useState(moment().week());
  // Zustand für die ausgewählte Woche (default: aktuelle Woche)
  const [selectedWeek, setSelectedWeek] = useState(moment().week());

  const handleNext = () => {
    if (startIndex === 6) {
      return;
    }

    setPlanIterationsChecked(false);
    setDisplayedPlanFirstItem({});
    setDisplayedPlanSecondItem({});
    setDisplayedPlanThirdItem({});

    setStartIndex(prev => prev + 3);
  };

  const handlePrev = () => {
    if (startIndex === 0) {
      return;
    }
    setPlanIterationsChecked(false);
    setDisplayedPlanFirstItem({});
    setDisplayedPlanSecondItem({});
    setDisplayedPlanThirdItem({});
    if (startIndex > 0) {
      setStartIndex(prev => prev - 3);
    }
  };

  // Funktion zum Berechnen der 5 anzuzeigenden Wochen
  const getWeeks = () => {
    return Array.from({ length: 5 }, (_, i) => {
      const weekNumber = currentWeek - 2 + i; // Die aktuelle Woche ist in der Mitte
      const startOfWeek = moment().week(weekNumber).startOf('isoWeek');
      const endOfWeek = moment().week(weekNumber).endOf('isoWeek');

      return {
        weekNumber,
        dateRange: `${startOfWeek.format('DD.MM.YY')} - ${endOfWeek.format('DD.MM.YY')}`,
        isSelected: weekNumber === selectedWeek, // Markierung der geklickten Woche
      };
    });
  };

  const checkCurrentPlanExists = (date: any, iteration: number) => {
    const checkPlan = [] as any;
    if (userPlanList !== undefined) {
      for (let i = 0; i <= userPlanList.length; i += 1) {
        if (checkPlan.length > 0) {
          break;
        } else {
          getCurrentPlanData(userPlanList[i], date, iteration);
        }
      }

      if (iteration === 3) {
        setPlanIterationsChecked(true);
      }
    }
  };

  const getCurrentPlanData = (plan: PlanType, date: any, planIteration: number) => {
    const thisStartDate = moment.unix(plan?.startDate.seconds).utc().format('YYYY-MM-DD');
    const thisEndDate = moment.unix(plan?.endDate.seconds).utc().format('YYYY-MM-DD');

    if (
      moment(date.format('YYYY-MM-DD')).isBetween(thisStartDate, thisEndDate, undefined, '(]') ||
      moment(date.format('YYYY-MM-DD')).isBetween(thisStartDate, thisEndDate, undefined, '[)') ||
      moment(date.format('YYYY-MM-DD')).isBetween(thisStartDate, thisEndDate, undefined, '[]')
    ) {
      const newDayItem = plan?.dayEntries?.filter(
        (item: any) => moment.unix(item.date.seconds).utc().format('YYYYMMDD') === date.format('YYYYMMDD')
      )[0];

      if (newDayItem !== undefined) {
        if (planIteration === 1) {
          setDisplayedPlanFirstItem(newDayItem);
          if (plan?.uid) {
            setFirstPlanItemUid(plan.uid);
          }
        } else if (planIteration === 2) {
          setDisplayedPlanSecondItem(newDayItem);
          if (plan?.uid) {
            setSecondPlanItemUid(plan.uid);
          }
        } else if (planIteration === 3) {
          setDisplayedPlanThirdItem(newDayItem);
          if (plan?.uid) {
            setThirdPlanItemUid(plan.uid);
          }
        }
      } else if (planIteration === 1) {
        setDisplayedPlanFirstItem({ type: 'empty' });
        setFirstPlanItemUid('');
      } else if (planIteration === 2) {
        setDisplayedPlanSecondItem({ type: 'empty' });
        setSecondPlanItemUid('');
      } else if (planIteration === 3) {
        setDisplayedPlanThirdItem({ type: 'empty' });
        setThirdPlanItemUid('');
      }
    }
  };

  const fillDays = () => {
    const firstDate = moment().week(selectedWeek).startOf('isoWeek').add(startIndex, 'days');
    const secondDate = moment()
      .week(selectedWeek)
      .startOf('isoWeek')
      .add(startIndex + 1, 'days');
    const thirdDate = moment()
      .week(selectedWeek)
      .startOf('isoWeek')
      .add(startIndex + 2, 'days');

    checkCurrentPlanExists(firstDate, 1);
    checkCurrentPlanExists(secondDate, 2);
    checkCurrentPlanExists(thirdDate, 3);
  };

  useEffect(() => {
    getPlanData();

    const getStartIndex = localStorage.getItem('coachingDashBoardPlanStartIndex');
    const getSelectedWeek = localStorage.getItem('coachingDashBoardPlanSelectedWeek');

    if (getStartIndex !== null) {
      setStartIndex(parseFloat(getStartIndex));
      localStorage.removeItem('coachingDashBoardPlanStartIndex');
    }

    if (getSelectedWeek !== null) {
      setSelectedWeek(parseFloat(getSelectedWeek));
      localStorage.removeItem('coachingDashBoardPlanSelectedWeek');
    }

    setFillDaysIndex(fillDaysIndex + 1);
  }, []);

  useEffect(() => {
    if (planListLoaded) {
      fillDays();
    }
  }, [planListLoaded, startIndex, fillDaysIndex]);

  const getPlanData = async () => {
    const userPlansCollection = await firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .doc(userId)
      .collection('plans')
      .orderBy('startDate.seconds', 'desc')
      .get();

    const getUserPlanList: PlanType[] = userPlansCollection.docs.map(
      item => ({ uid: item.id, ...item.data() } as PlanType)
    );

    setUserPlanList(getUserPlanList);
    setPlanListLoaded(true);
  };

  const changeWeek = (weekNumber: number) => {
    setPlanIterationsChecked(false);
    setDisplayedPlanFirstItem({});
    setDisplayedPlanSecondItem({});
    setDisplayedPlanThirdItem({});

    setStartIndex(0);
    setFillDaysIndex(fillDaysIndex + 1);
    setSelectedWeek(weekNumber);
  };

  return (
    <div className="pt-30">
      <div className="bg-lightGray rounded-xl p-20">
        <div className="flex flex-wrap justify-between items-center w-full gap-20">
          {/* Chevron Left */}
          <div className="my-auto">
            <ChevronLeftIcon
              width={25}
              height={25}
              className="text-accentColor mx-auto cursor-pointer"
              onClick={() => setCurrentWeek(prev => prev - 1)}
            />
          </div>

          {/* Dynamische Wochenanzeige */}
          <div className="flex flex-wrap flex-1 justify-between gap-30">
            {getWeeks().map((week, index) => (
              <div
                key={index}
                className={`flex-1 rounded-xl py-5 px-4 transition-all cursor-pointer text-center ${
                  week.isSelected ? 'bg-accentColor text-buttonTextColor' : 'bg-lightGrayDarker text-textColor'
                }`}
                onClick={() => changeWeek(week.weekNumber)}
                aria-hidden
              >
                <div className="font-bold">KW {week.weekNumber}</div>
                <div>{week.dateRange}</div>
              </div>
            ))}
          </div>

          {/* Chevron Right */}
          <div className="my-auto">
            <ChevronRightIcon
              width={25}
              height={25}
              className="text-accentColor mx-auto cursor-pointer"
              onClick={() => setCurrentWeek(prev => prev + 1)}
            />
          </div>
        </div>
      </div>

      {planListLoaded && planIterationsChecked ? (
        <div className="flex gap-5 mt-20">
          {/* Pfeil links */}
          <ChevronLeftIcon
            width={30}
            height={30}
            className={`text-accentColor cursor-pointer mt-300 sticky top-1/2 ${
              startIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handlePrev}
          />

          <div className="grid grid-cols-1 large:grid-cols-3 gap-20 flex-1">
            {displayedPlanFirstItem?.kcal_total !== undefined ? (
              <div>
                <CoachingDashboardPlanItem
                  planObject={displayedPlanFirstItem}
                  userId={userId}
                  planUid={firstPlanItemUid}
                  startIndex={startIndex}
                  selectedWeek={selectedWeek}
                />
              </div>
            ) : (
              <div>
                <CoachingDashboardPlanItemEmpty
                  planObject={displayedPlanFirstItem}
                  userId={userId}
                  planUid=""
                  startIndex={startIndex}
                  selectedWeek={selectedWeek}
                  noPlan
                  noPlanDate={moment()
                    .week(selectedWeek)
                    .startOf('isoWeek')
                    .add(startIndex + 1, 'days')
                    .format('DD.MM.YY')}
                />
              </div>
            )}

            {startIndex === 6 ? (
              <></>
            ) : displayedPlanSecondItem?.kcal_total !== undefined ? (
              <div>
                <CoachingDashboardPlanItem
                  planObject={displayedPlanSecondItem}
                  userId={userId}
                  planUid={secondPlanItemUid}
                  startIndex={startIndex}
                  selectedWeek={selectedWeek}
                />
              </div>
            ) : (
              <div>
                <CoachingDashboardPlanItemEmpty
                  planObject={displayedPlanSecondItem}
                  userId={userId}
                  planUid=""
                  startIndex={startIndex}
                  selectedWeek={selectedWeek}
                  noPlan
                  noPlanDate={moment()
                    .week(selectedWeek)
                    .startOf('isoWeek')
                    .add(startIndex + 2, 'days')
                    .format('DD.MM.YY')}
                />
              </div>
            )}

            {startIndex === 6 ? (
              <></>
            ) : displayedPlanThirdItem?.kcal_total !== undefined ? (
              <div>
                <CoachingDashboardPlanItem
                  planObject={displayedPlanThirdItem}
                  userId={userId}
                  planUid={thirdPlanItemUid}
                  startIndex={startIndex}
                  selectedWeek={selectedWeek}
                />
              </div>
            ) : (
              <div>
                <CoachingDashboardPlanItemEmpty
                  planObject={displayedPlanThirdItem}
                  userId={userId}
                  planUid=""
                  startIndex={startIndex}
                  selectedWeek={selectedWeek}
                  noPlan
                  noPlanDate={moment()
                    .week(selectedWeek)
                    .startOf('isoWeek')
                    .add(startIndex + 3, 'days')
                    .format('DD.MM.YY')}
                />
              </div>
            )}
          </div>

          {/* Pfeil rechts */}
          <ChevronRightIcon
            width={30}
            height={30}
            className={`text-accentColor cursor-pointer mt-300 sticky top-1/2 ${
              startIndex === 6 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleNext}
          />
        </div>
      ) : (
        <div className="relative">
          <div className="h-full w-full md:flex block">
            <div className={styles.loading}>
              <div className="font-extralight">Ernährungspläne werden geladen...</div>
              <ReactLoading type="bars" width={20} height={20} color="#ffffff" className="mx-auto" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoachingDashboardPlan;
