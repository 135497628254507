import React, { useContext, useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useParams, useLocation, Link, useHistory } from 'react-router-dom';
import Button from 'components/Button';
import {
  generateRandomUid,
  savePlanChangesToFirebase,
  shareToFirebase,
  scrollToTop,
  updateTourToFirebase,
} from 'shared/functions/global';
import { useTranslation } from 'react-i18next';
import firebase from 'services/firebase';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { toast } from 'react-toast';
import HashTagBadge from 'pages/Plans/components/HashTagBadge';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import Select from 'react-select';
import IncompatibilityOverlay from 'components/IncompabilityOverlay';
import ReactLoading from 'react-loading';
import TransitionContainer from 'components/TransitionContainer';
import axios from 'axios';
import {
  PlusIcon,
  HeartIcon,
  ShareIcon,
  ArrowDownOnSquareIcon as SaveIcon,
  ChevronDownIcon,
  ArrowTopRightOnSquareIcon as ExternalLinkIcon,
  PencilIcon,
  XMarkIcon as XIcon,
  MagnifyingGlassIcon as SearchIcon,
  TrashIcon,
  CurrencyDollarIcon as CurrencyEuroIcon,
  CheckBadgeIcon as BadgeCheckIcon,
  CalendarIcon,
} from '@heroicons/react/24/outline';
import ReactCrop, { Crop } from 'react-image-crop';
import { MainContext } from 'providers/MainProvider';
import { AuthContext } from 'providers/AuthProvider';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import SearchBox from 'components/SearchBox';
import ButtonBack from 'components/ButtonBack';
import CustomSelect from 'components/CustomSelect';
import CustomUserInput from 'components/CustomUserInput';
import SwitchButton from 'components/SwitchButton';
import MakroCircles from 'components/MakroCircles';
import { difficultyValues, recipeCategoryValues } from 'shared/constants/global';
import { intolerancesOption, eatingHabitsOption, formOfNutrition } from 'shared/constants/profile-wizard';
import { defaultTextColor } from 'layouts/Theme';
import Icon from 'components/Icon';
import RecipeStepSwitch from '../components/RecipeStepSwitch';
import CookingMode from '../components/CookingMode';
import IngridientItem from '../components/IngridientItem';
import DescriptionItem from '../components/DescriptionItem';
import 'react-image-crop/dist/ReactCrop.css';
import styles from './styles.module.scss';

registerLocale('de', de);

type ParamsType = {
  id: string;
};

// Add new description step
function addDescriptionStep(
  state: any,
  updateFunction: any,
  setRecipeChanged: any,
  viewPort: string,
  recipeDescriptionArray: any,
  setRecipeDescriptionArray: any,
  setDescriptionStepAdded: any
) {
  let thisDescriptionArray;
  if (recipeDescriptionArray.length > 0) {
    thisDescriptionArray = recipeDescriptionArray;
  } else {
    thisDescriptionArray = state.description;
  }

  const newColumns = {
    ...state,
    description: [
      ...thisDescriptionArray,
      {
        id: thisDescriptionArray.length,
        text: '',
      },
    ],
  };

  const userAgent = navigator.userAgent || navigator.vendor;

  if (!/iPad|iPhone|iPod/.test(userAgent)) {
    document.getElementById(`descriptionJumpContainerDesktop1`)!.scrollIntoView();
  }

  setRecipeDescriptionArray(...thisDescriptionArray, {
    id: thisDescriptionArray.length,
    text: '',
  });

  updateFunction(newColumns);
  setRecipeChanged(true);
  setDescriptionStepAdded(true);
}

interface CompletedCrop {
  x: number;
  y: number;
  width: number;
  height: number;
  unit: string;
  aspect: number;
}

type TonSelectFile = (evt: React.ChangeEvent<HTMLInputElement>) => void;

const RecipeDetail: React.FC = () => {
  const { id } = useParams<ParamsType>();
  const moment = extendMoment(Moment);
  const location = useLocation<LocationState>();
  const { recipesList, userPlanList } = useContext(MainContext);
  const recipe = recipesList?.find(item => item.uid === id);
  const favoritesRecipesList = useContext(MainContext).favoritesRecipesList?.find(item => item.origId === id);
  const authContext = useContext(AuthContext);
  const { userData, tenant, theme, tenantData } = useContext(AuthContext);
  const [currentAddRecipe, setCurrentAddRecipe] = useState(recipe);
  const [currentOriginalRecipe, setCurrentOriginalRecipe] = useState(recipe);
  const [currentRecipeName, setCurrentRecipeName] = useState(currentAddRecipe?.name);

  const [currentRecipePrice, setCurrentRecipePrice] = useState(0);

  const [currentEditIngridientPopupType, setCurrentEditIngridientPopupType] = useState('internal');

  const [currentApiFoodObject, setCurrentApiFoodObject] = useState<any>(null);

  const [visibleIncompatibilityPopup, setVisibleIncompatibilityPopup] = useState(false);
  const [incompatibilityPopupVisited, setIncompatibilityPopupVisited] = useState(false);

  const [sharePlanOverlayClass, setSharePlanOverlayClass] = useState('hidden');
  const [currentShareMail, setCurrentShareMail] = useState('');
  const [shareMailArray, setShareMailArray] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(false);

  const [currentRecipeLoaded, setCurrentRecipeLoaded] = useState(false);

  const [transitionLoaded, setTransitionLoaded] = useState(false);

  const [updatedRecipePortionSize, setUpdatedRecipePortionSize] = useState('');

  const [currentAddMealTypeValue, setCurrentAddMealTypeValue] = useState('breakfast');

  const [recipeDescriptionArray, setRecipeDescriptionArray] = useState([]);
  const [currentEditIngridientAmount, setCurrentEditIngridientAmount] = useState('1');
  const [currentEditInitialIngridientAmount, setCurrentEditInitialIngridientAmount] = useState('1');
  const [currentEditIngridientID, setCurrentEditIngridientID] = useState('1');
  const [currentEditIngridientName, setCurrentEditIngridientName] = useState('');
  const [currentEditIngridientType, setCurrentEditIngridientType] = useState('');
  const [currentEditIngridientPiece, setCurrentEditIngridientPiece] = useState('g');

  const [shareLoading, setShareLoading] = useState(false);

  const [currentAddRecipeList, setCurrentAddRecipeList] = useState("'40 Gramm Tofu', '50 Gramm Kirschtomate'");

  const [currentEditIngridientPreselectG, setCurrentEditIngridientPreselectG] = useState('');
  const [currentEditIngridientPreselectType, setCurrentEditIngridientPreselectType] = useState('');
  const [currentEditIngridientKcal100g, setCurrentEditIngridientKcal100g] = useState('');
  const [currentEditIngridientCarbohydrates100g, setCurrentEditIngridientCarbohydrates100g] = useState('');
  const [currentEditIngridientProtein100g, setCurrentEditIngridientProtein100g] = useState('');
  const [currentEditIngridientFat100g, setCurrentEditIngridientFat100g] = useState('');
  const [currentEditIngridientCategory, setCurrentEditIngridientCategory] = useState('');
  const [currentEditIngridientAPIFoodId, setCurrentEditIngridientAPIFoodId] = useState('');

  const [currentEditIngridientImageUrl, setCurrentEditIngridientImageUrl] = useState('');
  const [currentEditIngridientKcalTotal, setCurrentEditIngridientKcalTotal] = useState(0);
  const [currentEditIngridientCarbohydratesTotal, setCurrentEditIngridientCarbohydratesTotal] = useState(0);
  const [currentEditIngridientProteinTotal, setCurrentEditIngridientProteinTotal] = useState(0);
  const [currentEditIngridientFatTotal, setCurrentEditIngridientFatTotal] = useState(0);
  const [currentEditIngridientKcalValue, setCurrentEditIngridientKcalValue] = useState(0);
  const [currentEditIngridientMetricServingAmountValue, setCurrentEditIngridientMetricServingAmountValue] =
    useState('');
  const [currentEditIngridientCarbohydratesValue, setCurrentEditIngridientCarbohydratesValue] = useState(0);
  const [currentEditIngridientProteinValue, setCcurrentEditIngridientProteinValue] = useState(0);
  const [currentEditIngridientFatValue, setCurrentEditIngridientFatValue] = useState(0);
  const [currentEditIngridientPortionValues, setCurrentEditIngridientPortionValues] = useState<any>([]);
  const [currentEditIngridientDefaultPiece, setCurrentEditIngridientDefaultPiece] = useState('g');

  const [currentScrollPosition, setCurrentScrollPosition] = useState<Number | undefined>(0);

  const [startDate, setStartDate] = useState(null);

  const onChange = (dates: any) => {
    setStartDate(dates);
  };

  const imgRef = useRef<HTMLImageElement>();
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const searchIngridientRef = useRef<HTMLInputElement>(null);
  const editIngridientRef = useRef<HTMLInputElement>(null);
  const newDescriptionStepRef = useRef<HTMLDivElement>(null);
  const editRecipePortionSizeRef = useRef<HTMLInputElement>(null);
  const editRecipePortionSizeMobileRef = useRef<HTMLInputElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const leftColumnRef = useRef<HTMLDivElement>(null);

  const history = useHistory();

  const [upImg, setUpImg] = useState<FileReader['result']>();
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    width: 425,
    height: 180,
    x: 25,
    y: 25,
  });
  const [completedCrop, setCompletedCrop] = useState<CompletedCrop>();

  const tourRef = useRef<HTMLDivElement>(null);
  const tourRefMobile = useRef<HTMLDivElement>(null);

  const sharePopupRecipeInputRef = useRef<HTMLSpanElement>(null);

  const triggerShare = async () => {
    setShareLoading(true);

    await shareToFirebase(
      'recipe',
      currentAddRecipe,
      currentShareMail,
      setSharePlanOverlayClass,
      userData,
      tenant,
      authContext.user?.uid
    );

    setShareLoading(false);
  };

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
    },
    scrollTo: true,
    classes: 'shepherd-theme-dark',
    useModalOverlay: true,
  };

  function TourButton() {
    const tour = useContext(ShepherdTourContext);

    return (
      <div
        className="hidden"
        ref={tourRef}
        onClick={() => userData?.role === 2 && tour?.start()}
        onKeyDown={() => userData?.role === 2 && tour?.start()}
        aria-hidden="true"
      >
        <div>Test</div>
      </div>
    );
  }

  function TourButtonMobile() {
    const tourMobile = useContext(ShepherdTourContext);

    return (
      <div
        className="hidden"
        ref={tourRefMobile}
        onClick={() => userData?.role === 2 && tourMobile?.start()}
        onKeyDown={() => userData?.role === 2 && tourMobile?.start()}
        aria-hidden="true"
      >
        <div>Test</div>
      </div>
    );
  }

  const onSelectFile: TonSelectFile = evt => {
    if (evt.target.files && evt.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(evt.target.files[0]);
    }
  };

  const openSharePopup = async () => {
    const getShareMails = await firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .doc(authContext.user?.uid)
      .collection('messages-emails')
      .orderBy('dateSeconds', 'desc')
      .limit(5)
      .get();

    if (getShareMails.size > 0) {
      const sharedMailArray = [] as any;

      const getSharedMailsDocuments = getShareMails.docs.map(doc => {
        sharedMailArray.push(doc.data());

        return 0;
      });

      setShareMailArray(sharedMailArray);
    }

    setCurrentShareMail('');
    setSharePlanOverlayClass('block');
  };

  const changePlanShareEmail = (event: any) => {
    const thisCurrentEmail = event.currentTarget.textContent;

    setCurrentShareMail(thisCurrentEmail.toLowerCase());
  };

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  const mealTypeValues: any = [
    { value: 'breakfast', label: 'Frühstück' },
    { value: 'lunch', label: 'Mittagessen' },
    { value: 'dinner', label: 'Abendessen' },
    { value: 'snacks', label: 'Snacks' },
  ];

  const [recipeChanged, setRecipeChanged] = useState(false);
  const [descriptionStepAdded, setDescriptionStepAdded] = useState(false);
  const [recipeFavorite, setRecipeFavorite] = useState(false);
  const [recipePiece, setRecipePiece] = useState('Portion');
  const [piecePopupClass, setPiecePopupClass] = useState('hidden');
  const [deleteRecipeOverlayClass, setDeleteRecipeOverlayClass] = useState('hidden');
  const [addToPlanOverlayClass, setAddToPlanOverlayClass] = useState('hidden');
  const [incompatibilitiesClass, setIncompatibilitiesClass] = useState('hidden');
  const [eatingHabitsClass, setEatingHabitsClass] = useState('hidden');
  const [popupOverlayClass, setPopupOverlayClass] = useState('hidden');
  const [cookingModeOverlayClass, setCookingModeOverlayClass] = useState('hidden');
  const [currentCookingStep, setCurrentCookingStep] = useState(1);
  const [editPictureOverlayClass, setEditPictureOverlayClass] = useState('hidden');
  const [editPopupOverlayClass, setEditPopupOverlayClass] = useState('hidden');
  const [editFormOfNutritionOverlayClass, setEditFormOfNutritionOverlayClass] = useState('hidden');
  const [currentSearchQuery, setCurrentSearchQuery] = useState('');
  const [directAddedIngridients, setDirectAddedIngridients] = useState<any[]>([]);
  const [currentIngridientSearchApi, setCurrentIngridientSearchApi] = useState({ foods: { food: [{}] } as any });
  const [currentRecipePortionValues, setCurrentRecipePortionValues] = useState<any>([
    { value: 'Portion', label: `Portion (${currentAddRecipe?.portion_g}g)` },
    { value: 'g', label: 'Gramm' },
  ]);
  const { accessTokenString } = useContext(MainContext);
  const [currentIngridientSearchAutocomplete, setCurrentIngridientSearchAutocomplete] = useState({
    suggestions: { suggestion: [''] },
  });
  const [currentRecipeAmount, setCurrentRecipeAmount] = useState('1');
  const [currentRecipeAmountTyped, setCurrentRecipeAmountTyped] = useState('1');
  const [useAlghorithmus, setUseAlghorithmus] = useState(true);
  const [currentIngridientSearch, setCurrentIngridientSearch] = useState(false);
  const [incompatibilitiesChevronClass, setIncompatibilitiesChevronClass] = useState('text-accentColor');
  const [eatingHabitsChevronClass, setEatingHabitsChevronClass] = useState('text-accentColor');
  const { ingredientList } = useContext(MainContext);
  const { setTempRecipeObject, tempRecipeObject } = useContext(MainContext);

  const ingredientFilteredList = useMemo(
    () => ingredientList?.filter(element => element.name.toLowerCase().includes(currentSearchQuery.toLowerCase())),
    [ingredientList, currentSearchQuery]
  );
  const { t } = useTranslation();

  const CustomInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
    return (
      <label>
        <div className="flex w-full">
          <div className="flex-1">
            <input {...props} />
          </div>
          <div className="ml-10 my-auto">
            <CalendarIcon width={25} height={25} className="text-accentColor mx-auto cursor-pointer" />
          </div>
        </div>
      </label>
    );
  };

  const recipePortionValues: any = [
    { value: 'Portion', label: `Portion (${currentAddRecipe?.portion_g}g)` },
    { value: 'g', label: 'Gramm' },
  ];

  function clickRecipePortionSize(editType: string) {
    if (editType === 'desktop' && editRecipePortionSizeRef.current) {
      editRecipePortionSizeRef.current.select();
    } else if (editType === 'mobile' && editRecipePortionSizeMobileRef.current) {
      editRecipePortionSizeMobileRef.current.select();
    }
  }

  function clickIngridientChange() {
    if (editIngridientRef.current) {
      editIngridientRef.current.select();
    }
  }

  const mapRecipePiece = () => {
    if (currentAddRecipe?.recipe_piece !== undefined) {
      const dropDownFilter = currentRecipePortionValues.filter(
        (st: any) => st.value === currentAddRecipe?.recipe_piece.toLowerCase()
      );

      if (dropDownFilter.length > 0) {
        return currentRecipePortionValues.filter(
          (st: any) => st.value === currentAddRecipe?.recipe_piece.toLowerCase()
        );
      }
      return currentRecipePortionValues[0];
    }
    return currentRecipePortionValues[0];
  };

  useEffect(() => {
    if (favoritesRecipesList !== undefined) {
      setRecipeFavorite(true);
    }
    if (popupOverlayClass === 'block' && searchIngridientRef.current) {
      searchIngridientRef.current.focus();
    }
    if (editPopupOverlayClass === 'block' && editIngridientRef.current) {
      /* const sel = window.getSelection();
      const textLength = editIngridientRef.current.textContent!.length;
      sel?.setPosition(editIngridientRef.current.childNodes[0], textLength); */

      editIngridientRef.current.select();
    }
    window.history.replaceState({}, document.title);
  }, [favoritesRecipesList, popupOverlayClass, editPopupOverlayClass]);

  // Image Crop
  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image: any = imgRef.current;
    const canvas: any = previewCanvasRef.current;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    const pixelRatio = window.devicePixelRatio;

    const width = 425;
    const height = 180;
    canvas.width = width * pixelRatio;
    canvas.height = height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    const cropC = crop as CompletedCrop;

    ctx.drawImage(
      image,
      cropC.x * scaleX,
      cropC.y * scaleY,
      cropC.width * scaleX,
      cropC.height * scaleY,
      0,
      0,
      cropC.width,
      cropC.height
    );
  }, [crop, completedCrop]);

  useEffect(() => {
    const thisCurrentRecipe = recipesList?.find(item => item.uid === id);

    if (
      typeof location.state !== undefined &&
      location.state !== null &&
      location.state?.isCurrentRecipeDashboard === undefined &&
      (location.state?.from === 'planEditChangeRecipe' ||
        location.state?.from === 'plans' ||
        id === 'shared' ||
        location.state?.from === 'dashboard')
    ) {
      setCurrentAddRecipe(tempRecipeObject);
      setCurrentOriginalRecipe(tempRecipeObject);
      setCurrentRecipeName(tempRecipeObject.name);
      setCurrentRecipeLoaded(true);
      if (transitionLoaded === false) {
        setTransitionLoaded(true);
      }

      /* const reweButtonScript = document.createElement('script');
      reweButtonScript.setAttribute('src', 'https://shop.rewe.de/ingredients/button.js');
      document.body.appendChild(reweButtonScript); */
    } else if (thisCurrentRecipe?.kcal_total && currentRecipeLoaded === false) {
      setCurrentAddRecipe(thisCurrentRecipe);
      setCurrentOriginalRecipe(thisCurrentRecipe);
      setCurrentRecipeLoaded(true);
      if (transitionLoaded === false) {
        setTransitionLoaded(true);
      }
      /* const reweButtonScript = document.createElement('script');
      reweButtonScript.setAttribute('src', 'https://shop.rewe.de/ingredients/button.js');
      document.body.appendChild(reweButtonScript); */
    }

    if (thisCurrentRecipe?.alghorithmus !== undefined) {
      setUseAlghorithmus(thisCurrentRecipe.alghorithmus);
    }
  }, [id, recipesList]);

  useEffect(() => {
    setTimeout(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollIntoView();
      }
    }, 1);
  }, []);

  useEffect(() => {
    if (location.state?.from === 'planEditChangeRecipe' || location.state?.from === 'planEdit') {
      scrollToTop(leftColumnRef);
    }
  }, [location.state?.from]);

  useEffect(() => {
    if (currentAddRecipe?.recipe_portion !== undefined) {
      setCurrentRecipeAmount(currentAddRecipe?.recipe_portion);
    }

    if (currentAddRecipe?.ingredients !== undefined) {
      let thisPortionG = 0;

      currentAddRecipe?.ingredients.map((ingridient: any) => {
        if (ingridient.piece !== 'g' && ingridient.piece !== 'ml') {
          thisPortionG = Math.round(thisPortionG + parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g));
        } else {
          thisPortionG = Math.round(thisPortionG + parseFloat(ingridient.amount));
        }
        return null;
      });

      setCurrentRecipePortionValues([
        { value: 'Portion', label: `Portion (${thisPortionG}g)` },
        { value: 'g', label: 'Gramm' },
      ]);

      const thisRecipeListString = [] as any;

      let thisPrice = 0;
      let onlyIngredientsWithPrice = true;

      for (let i = 0; i <= currentAddRecipe.ingredients.length - 1; i += 1) {
        let thisAmountPiece = '';
        thisAmountPiece = currentAddRecipe.ingredients[i].amount.toString();

        if (currentAddRecipe.ingredients[i].piece === 'g') {
          thisAmountPiece += ' g';
        } else if (currentAddRecipe.ingredients[i].piece !== 'Stk.') {
          thisAmountPiece += ` ${currentAddRecipe.ingredients[i].piece.replace('Portion', 'TL')}`;
        }

        let thisRecipeListName = currentAddRecipe.ingredients[i].name;

        // Replace specific strings to get the best results
        thisRecipeListName = thisRecipeListName.replace('%', '');
        thisRecipeListName = thisRecipeListName.replace('(Rohmenge)', '');
        thisRecipeListName = thisRecipeListName.replace('Fetakäse light', 'Feta Light (Salakis)');

        thisRecipeListString.push(`${thisAmountPiece} ${thisRecipeListName}`);

        let thisIngredientGramm = 0;
        const thisItem = currentAddRecipe.ingredients[i] as any;
        const getIngridient = ingredientList?.find(
          item => item.name.toLowerCase() === thisItem.name.toLowerCase()
        ) as any;

        if (thisItem.piece !== 'g' && thisItem.piece !== 'ml') {
          thisIngredientGramm = thisItem.amount * thisItem.preselect_g;
        } else if (thisItem.serving_unit && thisItem.piece !== 'g' && thisItem.piece !== 'ml') {
          thisIngredientGramm = thisItem.serving_unit;
        } else {
          thisIngredientGramm = thisItem.amount;
        }

        if (getIngridient === undefined || getIngridient?.price_100g === undefined) {
          onlyIngredientsWithPrice = false;
        } else {
          const thisPriceCalculate = Math.round(((getIngridient.price_100g * thisIngredientGramm) / 100) * 100) / 100;
          thisPrice += parseFloat(thisPriceCalculate.toFixed(2));
        }
      }

      if (onlyIngredientsWithPrice === false) {
        thisPrice = 0;
      }

      setCurrentRecipePrice(parseFloat(thisPrice.toFixed(2)));

      setCurrentAddRecipeList(thisRecipeListString.map((element: any) => `${element}`).join(', '));

      setUpdatedRecipePortionSize(thisPortionG.toString());

      if (currentRecipeAmount === '1') {
        setCurrentOriginalRecipe(currentAddRecipe);
      }

      if (currentAddRecipe.recipe_piece !== undefined) {
        if (currentAddRecipe.recipe_piece === 'g') {
          setRecipePiece('g');
        }
      }
    }
  }, [currentAddRecipe]);

  useEffect(() => {
    setCurrentScrollPosition(location.state?.scrollPos);
  }, [location.state?.scrollPos]);

  useEffect(() => {
    if (
      userData?.incompatibilityAccept === undefined &&
      userData?.age !== undefined &&
      incompatibilityPopupVisited === false
    ) {
      setVisibleIncompatibilityPopup(true);
      setIncompatibilityPopupVisited(true);
    }
  }, [userData]);

  useEffect(() => {
    if (currentRecipeLoaded && visibleIncompatibilityPopup === false) {
      setTimeout(() => {
        if (document.getElementById('recipeName')?.offsetParent !== null) {
          if (tourRef.current && (userData?.cookieBannerType !== 'initial' || userData?.role === 5)) {
            if (userData?.tour?.nutritionDetail === undefined) {
              tourRef.current.click();
            }
          }
        }

        if (document.getElementById('recipeNameMobile')?.offsetParent !== null) {
          if (tourRefMobile.current && (userData?.cookieBannerType !== 'initial' || userData?.role === 5)) {
            if (userData?.tour?.nutritionDetail === undefined) {
              tourRefMobile.current.click();
            }
          }
        }
      }, 1);
    }
  }, [visibleIncompatibilityPopup]);

  // Update general recipe state
  function updateRecipeState(stateObject: any): void {
    setCurrentAddRecipe(stateObject);
  }

  // Change incompatibility items
  const setIncompatibilities = (status: boolean, item: string): void => {
    const newColumns = {
      ...currentAddRecipe,
      [item.toLowerCase()]: status,
    };

    setRecipeChanged(true);
    updateRecipeState(newColumns);
  };

  // Change incompatibility items
  const changeAlghorithmus = (status: boolean, item: string): void => {
    const newColumns = {
      ...currentAddRecipe,
      [item.toLowerCase()]: status,
    };

    setUseAlghorithmus(status);
    setRecipeChanged(true);
    updateRecipeState(newColumns);
  };

  // Change eating habits items
  const setEatingHabits = (status: boolean, item: string): void => {
    const newColumns = {
      ...currentAddRecipe,
      [item.toLowerCase()]: status,
    };

    setRecipeChanged(true);
    updateRecipeState(newColumns);
  };

  // Change form of nutrition items
  const setFormOfNutritions = (status: boolean, item: string): void => {
    const newColumns = {
      ...currentAddRecipe,
      [item.toLowerCase()]: status,
    };

    setRecipeChanged(true);
    updateRecipeState(newColumns);
  };

  type TsavePicture = (canvas: HTMLCanvasElement) => void;

  const savePicture: TsavePicture = canvas => {
    saveNewRecipePicture();
  };

  function jumpToNextStep() {
    if (currentCookingStep === currentAddRecipe?.description.length) {
      setCookingModeOverlayClass('hidden');
    } else {
      setCurrentCookingStep(currentCookingStep + 1);
    }
  }

  function returnToPreviousStep() {
    setCurrentCookingStep(currentCookingStep - 1);
  }

  // Toggle incompatibility area
  const toggleIncompatibilities = (): void => {
    if (incompatibilitiesClass === 'hidden') {
      setIncompatibilitiesClass('block');
      setIncompatibilitiesChevronClass('text-accentColor transition duration-300 transform rotate-180');
    } else {
      setIncompatibilitiesClass('hidden');
      setIncompatibilitiesChevronClass('text-accentColor transition duration-300 transform rotate-360');
    }
  };

  // Toggle eating habits area
  const toggleEatingHabits = (): void => {
    if (eatingHabitsClass === 'hidden') {
      setEatingHabitsClass('block');
      setEatingHabitsChevronClass('text-accentColor transition duration-300 transform rotate-180');
    } else {
      setEatingHabitsClass('hidden');
      setEatingHabitsChevronClass('text-accentColor transition duration-300 transform rotate-360');
    }
  };

  // Change general recipe piece
  const changeRecipePiece = (value: any) => {
    let thisPortionValue = '';
    if (currentAddRecipe?.recipe_portion) {
      thisPortionValue = currentAddRecipe.recipe_portion.toString();
    } else {
      thisPortionValue = '1';
    }

    setRecipePiece(value.value as string);
    changeRecipeAmount(thisPortionValue, currentOriginalRecipe, value.value as string);
  };

  // Push Function
  const saveNewRecipeToFirebase = async () => {
    let thisDescriptionArr;
    if (recipeDescriptionArray.length > 0) {
      thisDescriptionArr = [...recipeDescriptionArray];
    } else {
      thisDescriptionArr = currentAddRecipe?.description;
    }

    const thisRecipeUid = generateRandomUid();

    const newColumns = {
      ...currentAddRecipe,
      name: currentRecipeName,
      description: thisDescriptionArr,
      userRecipe: true,
      portion_g: updatedRecipePortionSize,
      uid: thisRecipeUid,
      created: moment().unix(),
    };

    const newColumnsAdmin = {
      ...currentAddRecipe,
      name: currentRecipeName,
      description: thisDescriptionArr,
      portion_g: updatedRecipePortionSize,
      uid: thisRecipeUid,
      created: moment().unix(),
    };

    const newColumnsFavorite = {
      uid: thisRecipeUid,
      name: currentRecipeName,
      origId: thisRecipeUid,
    };

    if (userData?.role === 1) {
      try {
        await firebase
          .firestore()
          .collection('recipes')
          .doc(thisRecipeUid)
          .set(newColumnsAdmin as object);
        toast.success(t('Dein Rezept wurde erfolgreich erstellt!'));
        history.push(`/nutrition/recipes/${thisRecipeUid}`);
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    } else if (currentRecipeName === currentOriginalRecipe?.name) {
      toast.error('Bitte ändere noch den Namen des Rezepts');
    } else {
      try {
        await firebase
          .firestore()
          .collection(`tenants/${tenant}/users`)
          .doc(authContext.user?.uid)
          .collection('recipes')
          .doc(thisRecipeUid)
          .set(newColumns as object);
        try {
          await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(authContext.user?.uid)
            .collection('favorites_recipes')
            .doc()
            .set(newColumnsFavorite as object);
        } catch (error: any) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          toast.error('Es ist leider etwas schief gegangen!');
        }
        setRecipeFavorite(true);
        toast.success(t('Dein Rezept wurde erfolgreich erstellt!'));
        history.push(`/nutrition/recipes/${thisRecipeUid}`);
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    }
  };

  // Push Function
  const saveExistingRecipeToFirebase = async () => {
    let thisDescriptionArr;
    if (recipeDescriptionArray.length > 0) {
      thisDescriptionArr = [...recipeDescriptionArray];
    } else {
      thisDescriptionArr = currentAddRecipe?.description;
    }

    let thisRecipeName = currentRecipeName;
    if (thisRecipeName === undefined) {
      thisRecipeName = currentAddRecipe?.name;
    }

    const newColumnsAdmin = {
      ...currentAddRecipe,
      name: thisRecipeName,
      description: thisDescriptionArr,
      portion_g: updatedRecipePortionSize,
      uid: id,
      ingredients: currentAddRecipe?.ingredients.map((ingridients: any) => {
        const getIngridient = ingredientList?.filter((item: any) => item.name === ingridients.name)[0];

        return {
          ...ingridients,
          category: getIngridient !== undefined ? getIngridient.category : 'Sonstiges',
        };
      }),
    };

    const newColumns = {
      ...currentAddRecipe,
      name: thisRecipeName,
      userRecipe: true,
      description: thisDescriptionArr,
      uid: id,
      portion_g: updatedRecipePortionSize,
    };

    const newColumnsB2BAdmin = {
      ...currentAddRecipe,
      name: thisRecipeName,
      description: thisDescriptionArr,
      communityRecipe: true,
      portion_g: updatedRecipePortionSize,
      uid: id,
    };

    if (userData?.role === 1) {
      try {
        await firebase
          .firestore()
          .collection('recipes')
          .doc(id)
          .update(newColumnsAdmin as object);
        toast.success(t('Dein Rezept wurde erfolgreich gespeichert!'));
        setRecipeChanged(false);
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    } else if (
      (userData?.isAdminUser || userData?.isTrainerUser || userData?.isLocationAdminUser) &&
      newColumnsB2BAdmin?.userRecipe !== true
    ) {
      try {
        await firebase
          .firestore()
          .collection(`tenants/${tenant}/recipes`)
          .doc(id)
          .update(newColumnsB2BAdmin as object);
        toast.success(t('Dein Rezept wurde erfolgreich gespeichert!'));
        setRecipeChanged(false);
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    } else {
      try {
        await firebase
          .firestore()
          .collection(`tenants/${tenant}/users`)
          .doc(authContext.user?.uid)
          .collection('recipes')
          .doc(id)
          .update(newColumns as object);
        toast.success(t('Dein Rezept wurde erfolgreich gespeichert!'));
        setRecipeChanged(false);
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    }
  };

  // Delete Function
  const deleteRecipeFromFirebase = async () => {
    if (userData?.role === 1) {
      try {
        await firebase.firestore().collection('recipes').doc(id).delete();
        toast.success(t('Dein Rezept wurde erfolgreich gelöscht!'));
        history.push('/nutrition');
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    } else if (
      (userData?.isAdminUser || userData?.isTrainerUser || userData?.isLocationAdminUser) &&
      currentAddRecipe?.userRecipe !== true
    ) {
      try {
        await firebase.firestore().collection(`tenants/${tenant}/recipes`).doc(id).delete();
        toast.success(t('Dein Rezept wurde erfolgreich gelöscht!'));
        history.push('/nutrition');
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    } else {
      try {
        await firebase
          .firestore()
          .collection(`tenants/${tenant}/users`)
          .doc(authContext.user?.uid)
          .collection('recipes')
          .doc(id)
          .delete();
        toast.success(t('Dein Rezept wurde erfolgreich gelöscht!'));
        history.push('/nutrition');
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    }
  };

  const saveRecipeToFavorites = async () => {
    const newColumns = {
      uid: currentAddRecipe?.uid,
      name: currentAddRecipe?.name,
      origId: currentAddRecipe?.uid,
    };

    if (recipeFavorite) {
      try {
        await firebase
          .firestore()
          .collection(`tenants/${tenant}/users`)
          .doc(authContext.user?.uid)
          .collection('favorites_recipes')
          .doc(favoritesRecipesList?.uid)
          .delete();

        setRecipeFavorite(false);
        toast.success('Das Rezept wurde erfolgreich von deinen Favoriten entfernt!');
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    } else {
      try {
        await firebase
          .firestore()
          .collection(`tenants/${tenant}/users`)
          .doc(authContext.user?.uid)
          .collection('favorites_recipes')
          .doc()
          .set(newColumns as object);

        setRecipeFavorite(true);
        toast.success('Das Rezept wurde erfolgreich zu deinen Favoriten gespeichert!');
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    }
  };

  // Push Function
  const saveNewRecipePicture = async () => {
    const thisImage = dataURItoBlob(previewCanvasRef.current?.toDataURL());
    let thisUserUid = authContext.user!.uid;
    if (userData?.role === 1) {
      thisUserUid = 'tortija';
    }
    const res = await firebase.storage().ref(`Recipe_Pictures/${thisUserUid}/${Math.random()}`).put(thisImage);
    const thisImageUrl = await res.ref.getDownloadURL();

    try {
      const newColumns = {
        ...currentAddRecipe,
        imageUrl: thisImageUrl,
      };

      setRecipeChanged(true);
      updateRecipeState(newColumns);
      handleOpenClosePopups(setEditPictureOverlayClass, '', 'close');
      toast.success('Dein Foto wurde erfolgreich gespeichert!');
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.error('Es ist leider etwas schief gegangen!');
    }
  };

  const addRecipeToPlan = (addType?: string) => {
    let thisDescriptionArr;
    if (recipeDescriptionArray.length > 0) {
      thisDescriptionArr = [...recipeDescriptionArray];
    } else {
      thisDescriptionArr = currentAddRecipe?.description;
    }

    const thisItemObject = {
      ...currentAddRecipe,
      amount: currentRecipeAmount,
      name: currentRecipeName,
      portion_g: updatedRecipePortionSize,
      description: thisDescriptionArr,
      piece: recipePiece,
      id: Math.random(),
      uid: id,
    } as any;

    if (addType !== undefined) {
      if (startDate !== null) {
        let updateObjectId = '' as any;

        let planObject = {
          kcal_total: 0,
          carbohydrates_total: 0,
          protein_total: 0,
          fat_total: 0,
          startDate: {
            seconds: moment(startDate, 'DD.MM.YYYY').unix(),
          },
          endDate: {
            seconds: moment(startDate, 'DD.MM.YYYY').unix(),
          },
          dayEntries: [
            {
              breakfast: {
                ingredients: [],
                recipes: [],
                kcal_total: 0,
                carbohydrates_total: 0,
                protein_total: 0,
                fat_total: 0,
                label: 'Frühstück',
              },
              lunch: {
                ingredients: [],
                recipes: [],
                kcal_total: 0,
                carbohydrates_total: 0,
                protein_total: 0,
                fat_total: 0,
                label: 'Mittagessen',
              },
              dinner: {
                ingredients: [],
                recipes: [],
                kcal_total: 0,
                carbohydrates_total: 0,
                protein_total: 0,
                fat_total: 0,
                label: 'Abendessen',
              },
              snacks: {
                ingredients: [],
                recipes: [],
                kcal_total: 0,
                carbohydrates_total: 0,
                protein_total: 0,
                fat_total: 0,
                label: 'Snacks',
              },
              id: 1,
              name: 'Tag 1',
              kcal_total: 0,
              carbohydrates_total: 0,
              protein_total: 0,
              fat_total: 0,
              date: {
                seconds: moment(startDate, 'DD.MM.YYYY').unix(),
              },
            },
          ],
        } as any;

        userPlanList?.forEach(function (key) {
          const thisCheckPlanStartDate = moment.unix(key.startDate.seconds).utc().format('DD-MM-YY');
          const thisCheckPlanEndDate = moment.unix(key.endDate.seconds).utc().format('DD-MM-YY');

          const thisConvertedPlanStartDate = moment(thisCheckPlanStartDate, 'DD-MM-YY');
          const thisConvertedPlanEndDate = moment(thisCheckPlanEndDate, 'DD-MM-YY');

          const thisUserStartDate = moment(startDate).format('YYYY-MM-DD');
          const thisUserEndDate = moment(startDate);

          const thisPlanRange = moment.range(thisConvertedPlanStartDate, thisConvertedPlanEndDate);
          const thisUserRange = moment.range(moment(thisUserStartDate), moment(thisUserEndDate));

          if (thisUserRange.overlaps(thisPlanRange, { adjacent: true }) === true) {
            updateObjectId = key.uid;
            planObject = key;
          }
        });

        if (planObject.startDate !== undefined) {
          const newColumns = {
            ...planObject,
            dayEntries: planObject.dayEntries.map((entries: any) => {
              let thisCheckDate = moment.unix(entries.date.seconds).utc().format('YYYYMMDD');
              if (updateObjectId.length === 0) {
                thisCheckDate = moment.unix(entries.date.seconds).subtract(-1, 'day').utc().format('YYYYMMDD');
              }

              if (moment(startDate, 'DD.MM.YYYY').format('YYYYMMDD') !== thisCheckDate) return entries;
              return {
                ...entries,
                kcal_total: parseFloat(entries.kcal_total) + parseFloat(thisItemObject.kcal_total),
                carbohydrates_total:
                  parseFloat(entries.carbohydrates_total) + parseFloat(thisItemObject.carbohydrates_total),
                protein_total: parseFloat(entries.protein_total) + parseFloat(thisItemObject.protein_total),
                fat_total: parseFloat(entries.fat_total) + parseFloat(thisItemObject.fat_total),
                [currentAddMealTypeValue]: {
                  ...entries[currentAddMealTypeValue],
                  kcal_total:
                    parseFloat(entries[currentAddMealTypeValue].kcal_total) + parseFloat(thisItemObject.kcal_total),
                  carbohydrates_total:
                    parseFloat(entries[currentAddMealTypeValue].carbohydrates_total) +
                    parseFloat(thisItemObject.carbohydrates_total),
                  protein_total:
                    parseFloat(entries[currentAddMealTypeValue].protein_total) +
                    parseFloat(thisItemObject.protein_total),
                  fat_total:
                    parseFloat(entries[currentAddMealTypeValue].fat_total) + parseFloat(thisItemObject.fat_total),
                  recipes: [thisItemObject, ...entries[currentAddMealTypeValue].recipes],
                },
              };
            }),
          };

          savePlanChangesToFirebase(
            userData,
            newColumns,
            updateObjectId,
            authContext.user?.uid,
            true,
            undefined,
            tenant
          );
          toast.success('Das Rezept wurde erfolgreich hinzugefügt');
          history.push({
            pathname: '/',
            state: {
              from: 'recipe-detail-add',
              planId: 'noPlan',
              planDateString: moment(startDate).format('DD.MM.YY'),
            },
          });
        }
      } else {
        toast.error('Bitte wähle ein Datum aus, wann du das Rezept hinzufügen möchtest!');
      }
    } else {
      setTempRecipeObject(thisItemObject);

      if (location.state.isDashBoard !== undefined && location.state.isDashBoard === true) {
        history.push({
          pathname: '/',
          state: {
            from: 'recipe-detail',
            planId: location.state.planId !== undefined ? location.state.planId : 'noPlan',
            planDayId: location.state.planDayId,
            mealLabel:
              location.state.mealLabel !== undefined
                ? location.state.mealLabel
                : location.state.label !== undefined
                ? location.state.label
                : undefined,
            planDateString: location.state.planDateString,
            uid: location.state?.uid !== undefined ? location.state?.uid : '',
            id: location.state?.id !== undefined ? location.state?.id : undefined,
          },
        });
      } else {
        history.push({
          pathname: `/plans/edit/${location.state.planId}`,
          state: {
            from: 'recipe-detail',
            planId: location.state.planId,
            planDayId: location.state.planDayId,
            mealLabel: location.state.mealLabel,
            planDateString: location.state.planDateString,
            scrollPos: location.state.scrollPos,
            recipeUid: location?.state?.recipeUid !== undefined ? location?.state?.recipeUid : undefined,
            searchQuery: location?.state?.searchQuery !== undefined ? location?.state?.searchQuery : undefined,
          },
        });
      }
    }
  };

  const changeRecipeInPlan = () => {
    let thisDescriptionArr;
    if (recipeDescriptionArray.length > 0) {
      thisDescriptionArr = [...recipeDescriptionArray];
    } else {
      thisDescriptionArr = currentAddRecipe?.description;
    }

    const thisItemObject = {
      ...currentAddRecipe,
      amount: currentRecipeAmount,
      name: currentRecipeName,
      description: thisDescriptionArr,
      piece: recipePiece,
      uid: id,
    };

    setTempRecipeObject(thisItemObject);

    const redirectPath =
      location?.state?.from === undefined || location?.state?.from !== 'dashboard'
        ? `/plans/edit/${location.state.planId}`
        : '/';

    history.push({
      pathname: redirectPath,
      state: {
        from: 'recipe-detail-change',
        planId: location.state.planId,
        planDayId: location.state.planDayId,
        mealLabel: location.state.mealLabel,
        label: location.state?.label !== undefined ? location.state?.label : '',
        planDateString: location.state.planDateString,
        recipeChangeId: location.state.recipeChangeId,
        uid: location.state?.uid !== undefined ? location.state?.uid : '',
        id: location.state?.id !== undefined ? location.state?.id : undefined,
        type: location.state?.type !== undefined ? location.state?.type : undefined,
      },
    });
  };

  // Change general recipe amount
  const changeRecipeAmount = (value: string, originalRecipe: any, thisRecipePiece: string) => {
    let thisKcalGesamt = 0;
    let thisKHGesamt = 0;
    let thisEWGesamt = 0;
    let thisFTGesamt = 0;
    let thisValue = value;

    setCurrentRecipeAmountTyped(thisValue);

    // Replace Comma with Point for calculating results
    thisValue = thisValue.replace(',', '.');

    // Replace all text characters
    thisValue = thisValue.replace(/[^.0-9]/g, '');

    if (Number.isNaN(parseFloat(thisValue))) {
      thisValue = '';
    }

    if (thisValue === '') {
      thisValue = '1';
    }

    let isChangeValid = true;

    let newColumns = {
      ...currentAddRecipe,
    } as any;

    if (parseFloat(currentAddRecipe!.recipe_portion) === 1) {
      newColumns = {
        ...currentAddRecipe,
        ingredients: currentAddRecipe?.ingredients.map(ingridients => {
          return {
            ...ingridients,
            initial_amount: ingridients.amount,
          };
        }),
      };
    }

    if (thisRecipePiece === 'Portion' && newColumns.initial_kcal_total === undefined) {
      thisKcalGesamt = parseFloat(originalRecipe?.kcal_total) * parseFloat(thisValue);
      thisKHGesamt = parseFloat(originalRecipe?.carbohydrates_total) * parseFloat(thisValue);
      thisEWGesamt = parseFloat(originalRecipe?.protein_total) * parseFloat(thisValue);
      thisFTGesamt = parseFloat(originalRecipe?.fat_total) * parseFloat(thisValue);
    } else if (thisRecipePiece === 'Portion' && newColumns.initial_kcal_total !== undefined) {
      thisKcalGesamt = parseFloat(newColumns.initial_kcal_total) * parseFloat(thisValue);
      thisKHGesamt = parseFloat(newColumns.initial_carbohydrates_total) * parseFloat(thisValue);
      thisEWGesamt = parseFloat(newColumns.initial_protein_total) * parseFloat(thisValue);
      thisFTGesamt = parseFloat(newColumns.initial_fat_total) * parseFloat(thisValue);
    } else if (thisRecipePiece === 'g' && newColumns.initial_kcal_total === undefined) {
      thisKcalGesamt =
        (parseFloat(originalRecipe?.kcal_total) * parseFloat(thisValue)) / parseFloat(updatedRecipePortionSize);
      thisKHGesamt =
        (parseFloat(originalRecipe?.carbohydrates_total) * parseFloat(thisValue)) /
        parseFloat(updatedRecipePortionSize);
      thisEWGesamt =
        (parseFloat(originalRecipe?.protein_total) * parseFloat(thisValue)) / parseFloat(updatedRecipePortionSize);
      thisFTGesamt =
        (parseFloat(originalRecipe?.fat_total) * parseFloat(thisValue)) / parseFloat(updatedRecipePortionSize);
    } else if (thisRecipePiece === 'g' && newColumns.initial_kcal_total !== undefined) {
      thisKcalGesamt =
        (parseFloat(newColumns.initial_kcal_total) * parseFloat(thisValue)) / parseFloat(updatedRecipePortionSize);
      thisKHGesamt =
        (parseFloat(newColumns.initial_carbohydrates_total) * parseFloat(thisValue)) /
        parseFloat(updatedRecipePortionSize);
      thisEWGesamt =
        (parseFloat(newColumns.initial_protein_total) * parseFloat(thisValue)) / parseFloat(updatedRecipePortionSize);
      thisFTGesamt =
        (parseFloat(newColumns.initial_fat_total) * parseFloat(thisValue)) / parseFloat(updatedRecipePortionSize);
    } else {
      isChangeValid = false;
    }

    newColumns = {
      ...newColumns,
      initial_kcal_total:
        newColumns.initial_kcal_total !== undefined ? newColumns.initial_kcal_total : newColumns.kcal_total,
      initial_carbohydrates_total:
        newColumns.initial_carbohydrates_total !== undefined
          ? newColumns.initial_carbohydrates_total
          : newColumns.carbohydrates_total,
      initial_protein_total:
        newColumns.initial_protein_total !== undefined ? newColumns.initial_protein_total : newColumns.protein_total,
      initial_fat_total:
        newColumns.initial_fat_total !== undefined ? newColumns.initial_fat_total : newColumns.fat_total,
      recipe_portion: thisValue,
      recipe_piece: thisRecipePiece.toLowerCase(),
      piece: thisRecipePiece.toLowerCase(),
      kcal_total: Math.round(thisKcalGesamt),
      carbohydrates_total: Math.round(thisKHGesamt),
      protein_total: Math.round(thisEWGesamt),
      fat_total: Math.round(thisFTGesamt),
    };

    if (isChangeValid) {
      setCurrentRecipeAmount(thisValue);
      updateRecipeState(newColumns);
      // setRecipeChanged(true);
    } else {
      toast.error('Es ist etwas mit einem Lebensmittel nicht in Ordnung! Wendet euch an uns!');
    }
  };

  const changeIngridientAmount = (event: any) => {
    const thisCurrentInitialAmount = event.target.value;
    let thisCurrentAmount = event.target.value;
    let ingridientKcalNew;
    let ingridientKhNew;
    let ingridientEwNew;
    let ingridientFtNew;
    let getIngridientRecipeData = [] as any;
    let getIngridient = [] as any;

    // Replace Comma with Point for calculating results
    thisCurrentAmount = thisCurrentAmount.replace(',', '.');

    // Replace all text characters
    thisCurrentAmount = thisCurrentAmount.replace(/[^.0-9]/g, '');

    if (Number.isNaN(parseFloat(thisCurrentAmount))) {
      thisCurrentAmount = 0;
    }

    if (thisCurrentAmount === '') {
      thisCurrentAmount = 0;
    }

    if (currentEditIngridientType === 'notAdded') {
      getIngridientRecipeData = ingredientList?.filter(
        (item: any) => item.name.toLowerCase() === currentEditIngridientName.toLowerCase()
      );
    } else {
      getIngridientRecipeData = currentAddRecipe?.ingredients.filter(
        (item: any) => item.id === currentEditIngridientID
      );
    }

    getIngridient = ingredientList?.filter(
      (item: any) => item.name.toLowerCase() === currentEditIngridientName.toLowerCase()
    );

    if (currentEditIngridientPiece !== 'g' && currentEditIngridientPiece !== 'ml') {
      ingridientKcalNew = Math.round(
        ((parseFloat(thisCurrentAmount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].kcal_100g)
      );

      ingridientKhNew = Math.round(
        ((parseFloat(thisCurrentAmount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].carbohydrates_100g)
      );

      ingridientEwNew = Math.round(
        ((parseFloat(thisCurrentAmount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].protein_100g)
      );

      ingridientFtNew = Math.round(
        ((parseFloat(thisCurrentAmount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].fat_100g)
      );
    } else {
      ingridientKcalNew = Math.round((parseFloat(getIngridient[0].kcal_100g) / 100) * parseFloat(thisCurrentAmount));

      ingridientKhNew = Math.round(
        (parseFloat(getIngridient[0].carbohydrates_100g) / 100) * parseFloat(thisCurrentAmount)
      );

      ingridientEwNew = Math.round((parseFloat(getIngridient[0].protein_100g) / 100) * parseFloat(thisCurrentAmount));

      ingridientFtNew = Math.round((parseFloat(getIngridient[0].fat_100g) / 100) * parseFloat(thisCurrentAmount));
    }

    if (thisCurrentAmount !== 0) {
      setCurrentEditIngridientAmount(thisCurrentAmount);
    } else {
      setCurrentEditIngridientAmount('');
    }
    setCurrentEditIngridientKcalTotal(ingridientKcalNew);
    setCurrentEditIngridientCarbohydratesTotal(ingridientKhNew);
    setCurrentEditIngridientProteinTotal(ingridientEwNew);
    setCurrentEditIngridientFatTotal(ingridientFtNew);
  };

  const changeIngridientAmountAPI = (event: any) => {
    const thisCurrentInitialAmount = event.target.value;
    let thisCurrentAmount = event.target.value;
    let ingridientKcalNew: number;
    let ingridientKhNew: number;
    let ingridientEwNew: number;
    let ingridientFtNew: number;

    // Replace Comma with Point for calculating results
    thisCurrentAmount = thisCurrentAmount.replace(',', '.');

    // Replace all text characters
    thisCurrentAmount = thisCurrentAmount.replace(/[^.0-9]/g, '');

    if (Number.isNaN(parseFloat(thisCurrentAmount))) {
      thisCurrentAmount = 0;
    }

    if (thisCurrentAmount === '') {
      thisCurrentAmount = 0;
    }

    if (currentEditIngridientPiece !== 'g' && currentEditIngridientPiece !== 'ml') {
      ingridientKcalNew = Math.round(parseFloat(thisCurrentAmount) * currentEditIngridientKcalValue);

      ingridientKhNew = Math.round(parseFloat(thisCurrentAmount) * currentEditIngridientCarbohydratesValue);

      ingridientEwNew = Math.round(parseFloat(thisCurrentAmount) * currentEditIngridientProteinValue);

      ingridientFtNew = Math.round(parseFloat(thisCurrentAmount) * currentEditIngridientFatValue);
    } else if (currentEditIngridientMetricServingAmountValue !== '') {
      let thisMetricAmountValue = 1;

      if (
        parseFloat(
          currentEditIngridientMetricServingAmountValue.substr(
            0,
            currentEditIngridientMetricServingAmountValue.indexOf('.')
          )
        ) !== 0
      ) {
        thisMetricAmountValue = parseFloat(
          currentEditIngridientMetricServingAmountValue.substr(
            0,
            currentEditIngridientMetricServingAmountValue.indexOf('.')
          )
        );
      }

      ingridientKcalNew = Math.round(
        (parseFloat(thisCurrentAmount) / thisMetricAmountValue) * currentEditIngridientKcalValue
      );

      ingridientKhNew = Math.round(
        (parseFloat(thisCurrentAmount) / thisMetricAmountValue) * currentEditIngridientCarbohydratesValue
      );

      ingridientEwNew = Math.round(
        (parseFloat(thisCurrentAmount) / thisMetricAmountValue) * currentEditIngridientProteinValue
      );

      ingridientFtNew = Math.round(
        (parseFloat(thisCurrentAmount) / thisMetricAmountValue) * currentEditIngridientFatValue
      );
    } else {
      ingridientKcalNew = (parseFloat(thisCurrentAmount) / 100) * currentEditIngridientKcalValue;
      ingridientKhNew = (parseFloat(thisCurrentAmount) / 100) * currentEditIngridientCarbohydratesValue;
      ingridientEwNew = (parseFloat(thisCurrentAmount) / 100) * currentEditIngridientProteinValue;
      ingridientFtNew = (parseFloat(thisCurrentAmount) / 100) * currentEditIngridientFatValue;
    }

    if (thisCurrentAmount !== 0) {
      setCurrentEditIngridientAmount(thisCurrentAmount);
    } else {
      setCurrentEditIngridientAmount('');
    }
    setCurrentEditIngridientKcalTotal(Math.round(ingridientKcalNew));
    setCurrentEditIngridientCarbohydratesTotal(Math.round(ingridientKhNew));
    setCurrentEditIngridientProteinTotal(Math.round(ingridientEwNew));
    setCurrentEditIngridientFatTotal(Math.round(ingridientFtNew));
    // setCurrentEditIngridientMetricServingAmountValue('');
  };

  const changeIngridientPiece = (event: any) => {
    const thisCurrentPiece = event.value;
    let thisCurrentPieceLabel = event.label;
    let ingridientKcalNew;
    let ingridientKhNew;
    let ingridientEwNew;
    let ingridientFtNew;
    let getIngridientRecipeData = [] as any;
    let getIngridient = [] as any;

    let thisEditIngredientAmount = currentEditIngridientAmount;

    if (thisEditIngredientAmount.toString().length === 0) {
      thisEditIngredientAmount = '0';
    }

    if (thisCurrentPieceLabel.includes('(')) {
      thisCurrentPieceLabel = thisCurrentPieceLabel.substr(0, thisCurrentPieceLabel.indexOf('(') - 1);
    }

    getIngridientRecipeData = currentAddRecipe?.ingredients.filter((item: any) => item.id === currentEditIngridientID);
    getIngridient = ingredientList?.filter((item: any) => item.name === currentEditIngridientName);

    if (thisCurrentPiece !== 'g' && thisCurrentPiece !== 'ml') {
      ingridientKcalNew = Math.round(
        ((parseFloat(thisEditIngredientAmount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].kcal_100g)
      );

      ingridientKhNew = Math.round(
        ((parseFloat(thisEditIngredientAmount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].carbohydrates_100g)
      );

      ingridientEwNew = Math.round(
        ((parseFloat(thisEditIngredientAmount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].protein_100g)
      );

      ingridientFtNew = Math.round(
        ((parseFloat(thisEditIngredientAmount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].fat_100g)
      );
    } else {
      ingridientKcalNew = Math.round(
        (parseFloat(getIngridient[0].kcal_100g) / 100) * parseFloat(thisEditIngredientAmount)
      );

      ingridientKhNew = Math.round(
        (parseFloat(getIngridient[0].carbohydrates_100g) / 100) * parseFloat(thisEditIngredientAmount)
      );

      ingridientEwNew = Math.round(
        (parseFloat(getIngridient[0].protein_100g) / 100) * parseFloat(thisEditIngredientAmount)
      );

      ingridientFtNew = Math.round(
        (parseFloat(getIngridient[0].fat_100g) / 100) * parseFloat(thisEditIngredientAmount)
      );
    }

    if (thisCurrentPieceLabel === 'Gramm') {
      setCurrentEditIngridientPiece('g');
    } else if (thisCurrentPieceLabel === 'Milliliter') {
      setCurrentEditIngridientPiece('ml');
    } else {
      setCurrentEditIngridientPiece(thisCurrentPieceLabel);
    }

    setCurrentEditIngridientKcalTotal(ingridientKcalNew);
    setCurrentEditIngridientCarbohydratesTotal(ingridientKhNew);
    setCurrentEditIngridientProteinTotal(ingridientEwNew);
    setCurrentEditIngridientFatTotal(ingridientFtNew);
  };

  const changeIngridientPieceAPI = (event: any) => {
    let thisCurrentPieceLabel = event.value;
    let ingridientKcalNew: number;
    let ingridientKhNew: number;
    let ingridientEwNew: number;
    let ingridientFtNew: number;

    let thisEditIngredientAmount = currentEditIngridientAmount;

    if (thisEditIngredientAmount.toString().length === 0) {
      thisEditIngredientAmount = '0';
    }

    if (thisCurrentPieceLabel !== 'g' && thisCurrentPieceLabel !== 'ml') {
      ingridientKcalNew = Math.round(parseFloat(thisEditIngredientAmount) * currentEditIngridientKcalValue);

      ingridientKhNew = Math.round(parseFloat(thisEditIngredientAmount) * currentEditIngridientCarbohydratesValue);

      ingridientEwNew = Math.round(parseFloat(thisEditIngredientAmount) * currentEditIngridientProteinValue);

      ingridientFtNew = Math.round(parseFloat(thisEditIngredientAmount) * currentEditIngridientFatValue);
    } else if (currentEditIngridientMetricServingAmountValue !== '') {
      let thisMetricAmountValue = 1;

      if (
        parseFloat(
          currentEditIngridientMetricServingAmountValue.substr(
            0,
            currentEditIngridientMetricServingAmountValue.indexOf('.')
          )
        ) !== 0
      ) {
        thisMetricAmountValue = parseFloat(
          currentEditIngridientMetricServingAmountValue.substr(
            0,
            currentEditIngridientMetricServingAmountValue.indexOf('.')
          )
        );
      }

      ingridientKcalNew = Math.round(
        (parseFloat(thisEditIngredientAmount) / thisMetricAmountValue) * currentEditIngridientKcalValue
      );

      ingridientKhNew = Math.round(
        (parseFloat(thisEditIngredientAmount) / thisMetricAmountValue) * currentEditIngridientCarbohydratesValue
      );

      ingridientEwNew = Math.round(
        (parseFloat(thisEditIngredientAmount) / thisMetricAmountValue) * currentEditIngridientProteinValue
      );

      ingridientFtNew = Math.round(
        (parseFloat(thisEditIngredientAmount) / thisMetricAmountValue) * currentEditIngridientFatValue
      );
    } else {
      ingridientKcalNew = (parseFloat(thisEditIngredientAmount) / 100) * currentEditIngridientKcalValue;
      ingridientKhNew = (parseFloat(thisEditIngredientAmount) / 100) * currentEditIngridientCarbohydratesValue;
      ingridientEwNew = (parseFloat(thisEditIngredientAmount) / 100) * currentEditIngridientProteinValue;
      ingridientFtNew = (parseFloat(thisEditIngredientAmount) / 100) * currentEditIngridientFatValue;
    }

    setCurrentEditIngridientKcalTotal(Math.round(ingridientKcalNew));
    setCurrentEditIngridientCarbohydratesTotal(Math.round(ingridientKhNew));
    setCurrentEditIngridientProteinTotal(Math.round(ingridientEwNew));
    setCurrentEditIngridientFatTotal(Math.round(ingridientFtNew));
    // setCurrentEditIngridientMetricServingAmountValue('');

    if (thisCurrentPieceLabel.includes('(')) {
      thisCurrentPieceLabel = thisCurrentPieceLabel.substr(0, thisCurrentPieceLabel.indexOf('(') - 1);
    }

    if (thisCurrentPieceLabel === 'Gramm') {
      setCurrentEditIngridientPiece('g');
    } else {
      setCurrentEditIngridientPiece(event.value);
    }
  };

  const changeIngridientExecute = () => {
    let currentCalculatedKcal;
    let currentCalculatedKH;
    let currentCalculatedEW;
    let currentCalculatedFT;
    let currentKcal: any = currentAddRecipe?.kcal_total;
    let currentKH: any = currentAddRecipe?.carbohydrates_total;
    let currentEW: any = currentAddRecipe?.protein_total;
    let currentFT: any = currentAddRecipe?.fat_total;
    let getIngridientRecipeData = [] as any;
    let getIngridient = [] as any;

    let thisEditIngredientAmount = currentEditIngridientAmount;

    if (thisEditIngredientAmount.toString().length === 0) {
      thisEditIngredientAmount = '0';
    }

    getIngridientRecipeData = currentAddRecipe!.ingredients.filter((item: any) => item.id === currentEditIngridientID);
    getIngridient = ingredientList?.filter((item: any) => item.name === currentEditIngridientName);

    if (getIngridientRecipeData[0].piece !== 'g' && getIngridientRecipeData[0].piece !== 'ml') {
      currentCalculatedKcal =
        ((parseFloat(getIngridientRecipeData[0].amount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
        parseFloat(getIngridient[0].kcal_100g);

      currentCalculatedKH =
        ((parseFloat(getIngridientRecipeData[0].amount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
        parseFloat(getIngridient[0].carbohydrates_100g);

      currentCalculatedEW =
        ((parseFloat(getIngridientRecipeData[0].amount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
        parseFloat(getIngridient[0].protein_100g);

      currentCalculatedFT =
        ((parseFloat(getIngridientRecipeData[0].amount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
        parseFloat(getIngridient[0].fat_100g);
    } else {
      currentCalculatedKcal =
        (parseFloat(getIngridientRecipeData[0].amount) / 100) * parseFloat(getIngridient[0].kcal_100g);

      currentCalculatedKH =
        (parseFloat(getIngridientRecipeData[0].amount) / 100) * parseFloat(getIngridient[0].carbohydrates_100g);

      currentCalculatedEW =
        (parseFloat(getIngridientRecipeData[0].amount) / 100) * parseFloat(getIngridient[0].protein_100g);

      currentCalculatedFT =
        (parseFloat(getIngridientRecipeData[0].amount) / 100) * parseFloat(getIngridient[0].fat_100g);
    }

    currentKcal = Math.round(parseFloat(currentKcal) - currentCalculatedKcal + currentEditIngridientKcalTotal);
    currentKH = Math.round(parseFloat(currentKH) - currentCalculatedKH + currentEditIngridientCarbohydratesTotal);
    currentEW = Math.round(parseFloat(currentEW) - currentCalculatedEW + currentEditIngridientProteinTotal);
    currentFT = Math.round(parseFloat(currentFT) - currentCalculatedFT + currentEditIngridientFatTotal);

    const newColumns = {
      ...currentAddRecipe,
      kcal_total: currentKcal,
      carbohydrates_total: currentKH,
      protein_total: currentEW,
      fat_total: currentFT,
      ingredients: currentAddRecipe?.ingredients.map((ingridients: any) => {
        if (parseFloat(ingridients.id) !== parseFloat(currentEditIngridientID)) return ingridients;
        return {
          ...ingridients,
          amount: thisEditIngredientAmount,
          piece: currentEditIngridientPiece,
        };
      }),
    };

    const newColumnsDirectAdd = directAddedIngridients.map((ingridients: any) => {
      if (parseFloat(ingridients.id) !== parseFloat(currentEditIngridientID)) return ingridients;
      return {
        ...ingridients,
        amount: thisEditIngredientAmount,
        amountBefore: ingridients.amount,
        piece: currentEditIngridientPiece,
      };
    });

    if (!Number.isNaN(parseFloat(thisEditIngredientAmount))) {
      updateRecipeState(newColumns);
      setRecipeChanged(true);
      setDirectAddedIngridients(newColumnsDirectAdd);
    } else {
      toast.error('Die eigegebenen Daten enthielten Fehler!');
    }
    handleOpenClosePopups(setEditPopupOverlayClass, setPopupOverlayClass, 'both');
  };

  const changeIngridientExecuteAPI = () => {
    setIsLoading(true);
    let currentKcal: any = currentAddRecipe?.kcal_total;
    let currentKH: any = currentAddRecipe?.carbohydrates_total;
    let currentEW: any = currentAddRecipe?.protein_total;
    let currentFT: any = currentAddRecipe?.fat_total;
    let getIngridientRecipeData = [] as any;
    let thisPieceValue = currentEditIngridientPiece;

    let thisEditIngredientAmount = currentEditIngridientAmount;

    if (thisEditIngredientAmount.toString().length === 0) {
      thisEditIngredientAmount = '0';
    }

    getIngridientRecipeData = currentAddRecipe!.ingredients.filter((item: any) => item.id === currentEditIngridientID);

    currentKcal = Math.round(
      parseFloat(currentKcal) - parseFloat(getIngridientRecipeData[0].kcal_total) + currentEditIngridientKcalTotal
    );
    currentKH = Math.round(
      parseFloat(currentKH) -
        parseFloat(getIngridientRecipeData[0].carbohydrates_total) +
        currentEditIngridientCarbohydratesTotal
    );
    currentEW = Math.round(
      parseFloat(currentEW) - parseFloat(getIngridientRecipeData[0].protein_total) + currentEditIngridientProteinTotal
    );
    currentFT = Math.round(
      parseFloat(currentFT) - parseFloat(getIngridientRecipeData[0].fat_total) + currentEditIngridientFatTotal
    );

    if (currentEditIngridientPiece.includes('(')) {
      thisPieceValue = currentEditIngridientPiece.substr(0, currentEditIngridientPiece.indexOf('(') - 1);
    }

    const newColumns = {
      ...currentAddRecipe,
      kcal_total: currentKcal,
      carbohydrates_total: currentKH,
      protein_total: currentEW,
      fat_total: currentFT,
      ingredients: currentAddRecipe?.ingredients.map((ingridients: any) => {
        if (parseFloat(ingridients.id) !== parseFloat(currentEditIngridientID)) return ingridients;
        return {
          ...ingridients,
          amount: thisEditIngredientAmount,
          piece: thisPieceValue,
          kcal_total: currentEditIngridientKcalTotal,
          carbohydrates_total: currentEditIngridientCarbohydratesTotal,
          protein_total: currentEditIngridientProteinTotal,
          fat_total: currentEditIngridientFatTotal,
        };
      }),
    };

    const newColumnsDirectAdd = directAddedIngridients.map((ingridients: any) => {
      if (parseFloat(ingridients.id) !== parseFloat(currentEditIngridientID)) return ingridients;
      return {
        ...ingridients,
        amount: thisEditIngredientAmount,
        piece: thisPieceValue,
        kcal_total: currentEditIngridientKcalTotal,
        carbohydrates_total: currentEditIngridientCarbohydratesTotal,
        protein_total: currentEditIngridientProteinTotal,
        fat_total: currentEditIngridientFatTotal,
      };
    });

    setCurrentEditIngridientKcalValue(0);
    setCurrentEditIngridientCarbohydratesValue(0);
    setCcurrentEditIngridientProteinValue(0);
    setCurrentEditIngridientFatValue(0);
    if (!Number.isNaN(parseFloat(thisEditIngredientAmount))) {
      updateRecipeState(newColumns);
      setRecipeChanged(true);
      setDirectAddedIngridients(newColumnsDirectAdd);
    } else {
      toast.error('Die eigegebenen Daten enthielten Fehler!');
    }
    setIsLoading(false);
    handleOpenClosePopups(setEditPopupOverlayClass, setPopupOverlayClass, 'both');
  };

  // Change recipe name
  const updateRecipeName = (event: any) => {
    setCurrentRecipeName(event.currentTarget.textContent.trim());
    setRecipeChanged(true);
  };

  // Change recipe category
  const updateCategory = (value: any) => {
    const newColumns = {
      ...currentAddRecipe,
      category: value.label,
    };

    updateRecipeState(newColumns);
    setRecipeChanged(true);
  };

  // change recipe difficulty level
  const updateDifficultyLevel = (value: any) => {
    const newColumns = {
      ...currentAddRecipe,
      difficulty_level: parseFloat(value.value as string),
    };

    updateRecipeState(newColumns);
    setRecipeChanged(true);
  };

  // Get FatSecret Autocomplete results
  const ingridientAutoCompleteSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSearchQuery(event.target.value);
    handleSearchChange();
  };

  const cancelToken = useRef(null) as any;

  const handleSearchChange = async () => {
    // setCount(count + 1);
    if (cancelToken.current !== null) {
      cancelToken.current.cancel('Operation canceled due to new request.');
    }

    // Save the cancel token for the current request
    cancelToken.current = axios.CancelToken.source();

    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/ingridientAutoCompleteSearch`,
          {
            token: accessTokenString,
            query: currentSearchQuery,
            language: userData?.country ?? 'DE',
            headers: {
              'content-type': 'application/json',
              Accept: 'application/json',
            },
          },
          {
            cancelToken: cancelToken?.current?.token,
          }
        )
        .then(response => {
          if (response.data.suggestions !== undefined) {
            setCurrentIngridientSearchAutocomplete(response.data);
            setCurrentIngridientSearch(false);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // Execute FatSecret ingridient autocomplete search
  // OLD DEBOUNCE VERSION
  /* const ingridientAutoCompleteSearchExecute = _.debounce(() => {
    if (currentSearchQuery.length > 0) {
      try {
        axios({
          url: `${process.env.REACT_APP_API_URL}/ingridientAutoCompleteSearch`,
          method: 'post',
          data: {
            token: accessTokenString,
            query: currentSearchQuery,
          },
          headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
          },
        }).then(response => {
          if (response.data.suggestions !== undefined) {
            setCurrentIngridientSearchAutocomplete(response.data);
            setCurrentIngridientSearch(false);
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  }, 10); */

  // Execute FatSecret ingridient search
  function ingridientApiSearch(query: string) {
    let thisQuery = query;

    // Hide ios keyboard
    (document.activeElement as HTMLElement).blur();

    if (query.length === 0) {
      if (searchIngridientRef.current) {
        setCurrentSearchQuery(searchIngridientRef.current.value);
        thisQuery = searchIngridientRef.current.value;
      }
    } else {
      setCurrentSearchQuery(query);
    }

    try {
      axios({
        url: `${process.env.REACT_APP_API_URL}/ingridientApiSearch`,
        method: 'post',
        data: {
          token: accessTokenString,
          query: thisQuery,
          language: userData?.country ?? 'DE',
        },
        headers: {
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      }).then(response => {
        setCurrentIngridientSearchApi(response.data);
        setCurrentIngridientSearch(true);
        setCurrentIngridientSearchAutocomplete({ suggestions: { suggestion: [''] } });
      });
    } catch (err) {
      console.log('error');
    }
  }

  function regenerateRecipe() {
    setCurrentAddRecipe(currentOriginalRecipe);
    setRecipeDescriptionArray([]);
    setDirectAddedIngridients([]);
    setCurrentRecipeAmount('1');
    setRecipeChanged(false);
  }

  function startCookingMode() {
    setCookingModeOverlayClass('block');
    setCurrentCookingStep(1);
  }

  // DIRECT ADD: Add a non API ingridient without setting amount and piece (Plus icon)
  function addIngridientDirectInternal(
    piece: string,
    amount: number,
    preselect_g: string,
    kcal_100g: string,
    carbohydrates_100g: string,
    protein_100g: string,
    fat_100g: string,
    name: string,
    category: string,
    imageUrl: string,
    preselect_type: string,
    default_piece: string
  ) {
    let thisCurrentKcalComplete = 0;
    let thisCurrentKHComplete = 0;
    let thisCurrentEWComplete = 0;
    let thisCurrentFTComplete = 0;

    if (piece !== 'g' && piece !== 'ml') {
      thisCurrentKcalComplete = Math.round(
        parseFloat(currentAddRecipe?.kcal_total!) + ((amount * parseFloat(preselect_g)) / 100) * parseFloat(kcal_100g)
      );

      thisCurrentKHComplete = Math.round(
        parseFloat(currentAddRecipe?.carbohydrates_total!) +
          ((amount * parseFloat(preselect_g)) / 100) * parseFloat(carbohydrates_100g)
      );

      thisCurrentEWComplete = Math.round(
        parseFloat(currentAddRecipe?.protein_total!) +
          ((amount * parseFloat(preselect_g)) / 100) * parseFloat(protein_100g)
      );

      thisCurrentFTComplete = Math.round(
        parseFloat(currentAddRecipe?.fat_total!) + ((amount * parseFloat(preselect_g)) / 100) * parseFloat(fat_100g)
      );
    } else {
      thisCurrentKcalComplete = Math.round(
        parseFloat(currentAddRecipe?.kcal_total!) + (amount / 100) * parseFloat(kcal_100g)
      );

      thisCurrentKHComplete = Math.round(
        parseFloat(currentAddRecipe?.carbohydrates_total!) + (amount / 100) * parseFloat(carbohydrates_100g)
      );

      thisCurrentEWComplete = Math.round(
        parseFloat(currentAddRecipe?.protein_total!) + (amount / 100) * parseFloat(protein_100g)
      );

      thisCurrentFTComplete = Math.round(
        parseFloat(currentAddRecipe?.fat_total!) + (amount / 100) * parseFloat(fat_100g)
      );
    }

    const thisPiece = piece;

    const thisIngridientObject = {
      id: Math.random(),
      name,
      amount,
      initial_amount: amount,
      piece: thisPiece,
      kcal_100g,
      carbohydrates_100g,
      protein_100g,
      fat_100g,
      default_piece,
      preselect_g,
      category,
      imageUrl,
      preselect_type,
    };

    let thisRecipeData = currentAddRecipe as any;

    const newColumns = {
      ...currentAddRecipe,
      kcal_total: thisCurrentKcalComplete,
      carbohydrates_total: thisCurrentKHComplete,
      protein_total: thisCurrentEWComplete,
      fat_total: thisCurrentFTComplete,
      ingredients: [...currentAddRecipe!.ingredients, thisIngridientObject],
    };

    const checkIncompabilityArray = [] as any;
    const checkFormOfNutritionArray = [] as any;

    // check incombality options of ingredients
    for (let i = 0; i < newColumns.ingredients.length; i += 1) {
      const thisIngridientList = ingredientList as any;
      const getThisIngridient = thisIngridientList?.find((item: any) => item.name === newColumns.ingredients[i].name);

      intolerancesOption.forEach(function (key) {
        if (
          getThisIngridient !== undefined &&
          typeof getThisIngridient[key.toLowerCase()] !== undefined &&
          getThisIngridient[key.toLowerCase()] !== null
        ) {
          if (getThisIngridient[key.toLowerCase()] === true) {
            checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
          }
        }
      });

      formOfNutrition.forEach(function (key) {
        if (
          getThisIngridient !== undefined &&
          typeof getThisIngridient[key.toLowerCase()] !== undefined &&
          getThisIngridient[key.toLowerCase()] !== null
        ) {
          if (getThisIngridient[key.toLowerCase()] === true) {
            checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
          } else {
            checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: false });
          }
        }
      });
    }

    const checkFormOfNutritionArrayMerged = [
      ...checkFormOfNutritionArray.filter((item: any) => item.status === true),
      ...checkFormOfNutritionArray.filter((item: any) => item.status === false),
    ];

    // Copy newColumns to variable because this needs to be updated in the following sections
    thisRecipeData = { ...newColumns };

    // First set all to false
    for (let f = 0; f < intolerancesOption.length; f += 1) {
      thisRecipeData = { ...thisRecipeData, [intolerancesOption[f].toLowerCase()]: false };
    }
    for (let h = 0; h < formOfNutrition.length; h += 1) {
      thisRecipeData = { ...thisRecipeData, [formOfNutrition[h].toLowerCase()]: false };
    }

    // Set elements true if incompatibility exists
    for (let g = 0; g < checkIncompabilityArray.length; g += 1) {
      thisRecipeData = { ...thisRecipeData, [checkIncompabilityArray[g].name]: true };
    }

    // Set elements true if form of nutrition exists
    for (let g = 0; g < checkFormOfNutritionArrayMerged.length; g += 1) {
      thisRecipeData = {
        ...thisRecipeData,
        [checkFormOfNutritionArrayMerged[g].name]: checkFormOfNutritionArrayMerged[g].status,
      };
    }

    if (currentEditIngridientType === 'add') {
      handleOpenClosePopups(setEditPopupOverlayClass, '', 'close');
      handleOpenClosePopups(setPopupOverlayClass, '', 'close');
    }
    setCurrentIngridientSearch(false);
    setCurrentSearchQuery('');
    // this.setState({ currentIngridientPieceIdentifier: '' });
    setCurrentIngridientSearchAutocomplete({ suggestions: { suggestion: [''] } });
    if (!Number.isNaN(amount)) {
      updateRecipeState(thisRecipeData);
      setDirectAddedIngridients([thisIngridientObject, ...directAddedIngridients]);
      setRecipeChanged(true);
    } else {
      toast.error('Die eingegebenen Daten enthielten Fehler!');
    }

    if (currentEditIngridientType === 'notAdded') {
      handleOpenClosePopups(setEditPopupOverlayClass, setPopupOverlayClass, 'both');
    }
  }

  function addChangedIngridientAPI() {
    setIsLoading(true);
    let thisCurrentKcalComplete = 0;
    let thisCurrentKHComplete = 0;
    let thisCurrentEWComplete = 0;
    let thisCurrentFTComplete = 0;

    let getIngridientServingData;
    let currentServingDescription;
    let ingridientKcal100g;
    let ingridientKH100g;
    let ingridientEW100g;
    let ingridientFT100g;
    let thisPieceValue = currentEditIngridientPiece;

    let thisEditIngredientAmount = currentEditIngridientAmount;

    if (thisEditIngredientAmount.toString().length === 0) {
      thisEditIngredientAmount = '0';
    }

    try {
      const { data } = currentApiFoodObject;

      if (Array.isArray(data.food.servings.serving)) {
        getIngridientServingData = data.food.servings.serving.filter((item: any) => item.is_default === '1');
        currentServingDescription = getIngridientServingData[0].serving_description.replace(/\s+/g, '');
      } else {
        getIngridientServingData = [data.food.servings.serving];
        currentServingDescription = data.food.servings.serving.serving_description.replace(/\s+/g, '');
      }

      if (currentEditIngridientPiece.includes('(')) {
        thisPieceValue = currentEditIngridientPiece.substr(0, currentEditIngridientPiece.indexOf('(') - 1);
      }

      if (getIngridientServingData[0].metric_serving_amount) {
        ingridientKcal100g = Math.round(
          (parseFloat(getIngridientServingData[0].calories) * 100) /
            parseFloat(getIngridientServingData[0].metric_serving_amount)
        );
        ingridientKH100g = Math.round(
          (parseFloat(getIngridientServingData[0].carbohydrate) * 100) /
            parseFloat(getIngridientServingData[0].metric_serving_amount)
        );
        ingridientEW100g = Math.round(
          (parseFloat(getIngridientServingData[0].protein) * 100) /
            parseFloat(getIngridientServingData[0].metric_serving_amount)
        );
        ingridientFT100g = Math.round(
          (parseFloat(getIngridientServingData[0].fat) * 100) /
            parseFloat(getIngridientServingData[0].metric_serving_amount)
        );
      } else {
        ingridientKcal100g = Math.round(parseFloat(getIngridientServingData[0].calories));
        ingridientKH100g = Math.round(parseFloat(getIngridientServingData[0].carbohydrate));
        ingridientEW100g = Math.round(parseFloat(getIngridientServingData[0].protein));
        ingridientFT100g = Math.round(parseFloat(getIngridientServingData[0].fat));
      }

      thisCurrentKcalComplete = Math.round(parseFloat(currentAddRecipe?.kcal_total!) + currentEditIngridientKcalTotal);

      thisCurrentKHComplete = Math.round(
        parseFloat(currentAddRecipe?.carbohydrates_total!) + currentEditIngridientCarbohydratesTotal
      );

      thisCurrentEWComplete = Math.round(
        parseFloat(currentAddRecipe?.protein_total!) + currentEditIngridientProteinTotal
      );

      thisCurrentFTComplete = Math.round(parseFloat(currentAddRecipe?.fat_total!) + currentEditIngridientFatTotal);

      let thisFoodCategory = 'Sonstiges';
      if (data.food.food_sub_categories) {
        if (Array.isArray(data.food.food_sub_categories.food_sub_category)) {
          thisFoodCategory = data.food.food_sub_categories.food_sub_category[1].toString();
        } else {
          thisFoodCategory = data.food.food_sub_categories.food_sub_category;
        }
      }

      const thisIngridientObject = {
        id: currentEditIngridientAPIFoodId,
        name: currentEditIngridientName,
        amount: thisEditIngredientAmount,
        initial_amount: thisEditIngredientAmount,
        piece: thisPieceValue,
        kcal_100g: ingridientKcal100g,
        carbohydrates_100g: ingridientKH100g,
        protein_100g: ingridientEW100g,
        fat_100g: ingridientFT100g,
        kcal_total: currentEditIngridientKcalTotal,
        carbohydrates_total: currentEditIngridientCarbohydratesTotal,
        protein_total: currentEditIngridientProteinTotal,
        fat_total: currentEditIngridientFatTotal,
        preselect_g:
          (getIngridientServingData[0].metric_serving_amount &&
            getIngridientServingData[0].metric_serving_amount.substr(
              0,
              getIngridientServingData[0].metric_serving_amount.indexOf('.')
            )) ||
          100,
        category: thisFoodCategory,
        serving_id: getIngridientServingData[0].serving_id,
        serving_unit:
          getIngridientServingData[0].metric_serving_unit !== undefined
            ? getIngridientServingData[0].metric_serving_unit
            : 'g',
        serving_data: data.food.servings,
      };

      const newColumns = {
        ...currentAddRecipe,
        kcal_total: thisCurrentKcalComplete,
        carbohydrates_total: thisCurrentKHComplete,
        protein_total: thisCurrentEWComplete,
        fat_total: thisCurrentFTComplete,
        ingredients: [...currentAddRecipe!.ingredients, thisIngridientObject],
      };

      setCurrentIngridientSearch(false);
      setCurrentSearchQuery('');
      setCurrentIngridientSearchAutocomplete({ suggestions: { suggestion: [''] } });
      if (!Number.isNaN(parseFloat(thisEditIngredientAmount))) {
        updateRecipeState(newColumns);
        setRecipeChanged(true);
        setDirectAddedIngridients([thisIngridientObject, ...directAddedIngridients]);
      } else {
        toast.error('Die eigegebenen Daten enthielten Fehler!');
      }
      setIsLoading(false);
      handleOpenClosePopups(setEditPopupOverlayClass, setPopupOverlayClass, 'both');
    } catch (err) {
      toast.error('Es ist leider ein Fehler aufgetreten. Bitte probiere es erneut!');
    }
  }

  function addIngridientDirectAPI(food_id: string) {
    setIsLoading(true);
    let thisCurrentKcalComplete = 0;
    let thisCurrentKHComplete = 0;
    let thisCurrentEWComplete = 0;
    let thisCurrentFTComplete = 0;

    let getIngridientServingData;
    let currentServingDescription;
    let thisAmount;
    let ingridientKcal100g;
    let ingridientKH100g;
    let ingridientEW100g;
    let ingridientFT100g;

    try {
      axios({
        url: `${process.env.REACT_APP_API_URL}/getIngridientApi`,
        method: 'post',
        data: {
          token: accessTokenString,
          food_id,
          language: userData?.country ?? 'DE',
        },
        headers: {
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      }).then(response => {
        const { data } = response;

        if (Array.isArray(data.food.servings.serving)) {
          getIngridientServingData = data.food.servings.serving.filter((item: any) => item.is_default === '1');
          currentServingDescription = getIngridientServingData[0].serving_description.replace(/\s+/g, '');
        } else {
          getIngridientServingData = [data.food.servings.serving];
          currentServingDescription = data.food.servings.serving.serving_description.replace(/\s+/g, '');
        }

        let thisPiece;
        if (getIngridientServingData[0].serving_description.includes('1 ')) {
          if (
            getIngridientServingData[0].serving_description.includes('g)') ||
            getIngridientServingData[0].serving_description.includes('ml)')
          ) {
            thisPiece = getIngridientServingData[0].serving_description.substr(
              2,
              getIngridientServingData[0].serving_description.indexOf(' (') - 2
            );
          } else {
            thisPiece = getIngridientServingData[0].serving_description.substr(
              2,
              getIngridientServingData[0].serving_description.length
            );
          }
        } else {
          thisPiece = getIngridientServingData[0].serving_description;
        }
        if (thisPiece.includes('(')) {
          thisPiece = thisPiece.substr(0, thisPiece.indexOf('(') - 1);
        }

        if (
          currentServingDescription === '100ml' ||
          currentServingDescription === '100g' ||
          getIngridientServingData[0].measurement_description === 'g' ||
          getIngridientServingData[0].measurement_description === 'ml'
        ) {
          if (
            getIngridientServingData[0].metric_serving_unit === 'ml' ||
            getIngridientServingData[0].measurement_description === 'ml'
          ) {
            thisPiece = 'ml';
          } else {
            thisPiece = 'g';
          }
        }

        if (
          currentServingDescription !== '100ml' &&
          currentServingDescription !== '100g' &&
          getIngridientServingData[0].measurement_description !== 'g' &&
          getIngridientServingData[0].measurement_description !== 'ml'
        ) {
          thisAmount = getIngridientServingData[0].number_of_units.substr(
            0,
            getIngridientServingData[0].number_of_units.indexOf('.')
          );
        } else {
          thisAmount = 100;
        }

        if (getIngridientServingData[0].metric_serving_amount) {
          ingridientKcal100g = Math.round(
            (parseFloat(getIngridientServingData[0].calories) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
          ingridientKH100g = Math.round(
            (parseFloat(getIngridientServingData[0].carbohydrate) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
          ingridientEW100g = Math.round(
            (parseFloat(getIngridientServingData[0].protein) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
          ingridientFT100g = Math.round(
            (parseFloat(getIngridientServingData[0].fat) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
        } else {
          ingridientKcal100g = Math.round(parseFloat(getIngridientServingData[0].calories));
          ingridientKH100g = Math.round(parseFloat(getIngridientServingData[0].carbohydrate));
          ingridientEW100g = Math.round(parseFloat(getIngridientServingData[0].protein));
          ingridientFT100g = Math.round(parseFloat(getIngridientServingData[0].fat));
        }

        thisCurrentKcalComplete = Math.round(
          parseFloat(currentAddRecipe?.kcal_total!) + Math.round(parseFloat(getIngridientServingData[0].calories))
        );

        thisCurrentKHComplete = Math.round(
          parseFloat(currentAddRecipe?.carbohydrates_total!) +
            Math.round(parseFloat(getIngridientServingData[0].carbohydrate))
        );

        thisCurrentEWComplete = Math.round(
          parseFloat(currentAddRecipe?.protein_total!) + Math.round(parseFloat(getIngridientServingData[0].protein))
        );

        thisCurrentFTComplete = Math.round(
          parseFloat(currentAddRecipe?.fat_total!) + Math.round(parseFloat(getIngridientServingData[0].fat))
        );

        let thisFoodName = data.food.food_name;
        if (data.food.brand_name) {
          thisFoodName += ` (${data.food.brand_name})`;
        }

        let thisFoodCategory = 'Sonstiges';
        if (data.food.food_sub_categories) {
          if (Array.isArray(data.food.food_sub_categories.food_sub_category)) {
            thisFoodCategory = data.food.food_sub_categories.food_sub_category[1].toString();
          } else {
            thisFoodCategory = data.food.food_sub_categories.food_sub_category;
          }
        }

        const thisIngridientObject = {
          id: food_id,
          name: thisFoodName,
          amount: thisAmount,
          initial_amount: thisAmount,
          piece: thisPiece,
          kcal_100g: ingridientKcal100g,
          carbohydrates_100g: ingridientKH100g,
          protein_100g: ingridientEW100g,
          fat_100g: ingridientFT100g,
          kcal_total: Math.round(parseFloat(getIngridientServingData[0].calories)),
          carbohydrates_total: Math.round(parseFloat(getIngridientServingData[0].carbohydrate)),
          protein_total: Math.round(parseFloat(getIngridientServingData[0].protein)),
          fat_total: Math.round(parseFloat(getIngridientServingData[0].fat)),
          preselect_g:
            (getIngridientServingData[0].metric_serving_amount &&
              getIngridientServingData[0].metric_serving_amount.substr(
                0,
                getIngridientServingData[0].metric_serving_amount.indexOf('.')
              )) ||
            100,
          category: thisFoodCategory,
          serving_id: getIngridientServingData[0].serving_id,
          serving_unit:
            getIngridientServingData[0].metric_serving_unit !== undefined
              ? getIngridientServingData[0].metric_serving_unit
              : 'g',
          serving_data: data.food.servings,
        };

        const newColumns = {
          ...currentAddRecipe,
          kcal_total: thisCurrentKcalComplete,
          carbohydrates_total: thisCurrentKHComplete,
          protein_total: thisCurrentEWComplete,
          fat_total: thisCurrentFTComplete,
          ingredients: [...currentAddRecipe!.ingredients, thisIngridientObject],
        };

        setCurrentIngridientSearch(false);
        setCurrentSearchQuery('');
        setCurrentIngridientSearchAutocomplete({ suggestions: { suggestion: [''] } });
        if (!Number.isNaN(thisAmount)) {
          updateRecipeState(newColumns);
          setRecipeChanged(true);
          setDirectAddedIngridients([thisIngridientObject, ...directAddedIngridients]);
        } else {
          toast.error('Die eigegebenen Daten enthielten Fehler!');
        }
        setIsLoading(false);
      });
    } catch (err) {
      toast.error('Es ist leider ein Fehler aufgetreten. Bitte probiere es erneut!');
    }
  }

  function getAPIIngridientData(food_id: string) {
    setIsLoading(true);
    let getIngridientServingData;
    let currentServingDescription;
    let thisAmount;
    let ingridientKcal100g;
    let ingridientKH100g;
    let ingridientEW100g;
    let ingridientFT100g;

    try {
      axios({
        url: `${process.env.REACT_APP_API_URL}/getIngridientApi`,
        method: 'post',
        data: {
          token: accessTokenString,
          food_id,
          language: userData?.country ?? 'DE',
        },
        headers: {
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      }).then(response => {
        const { data } = response;

        setCurrentApiFoodObject(response);

        if (Array.isArray(data.food.servings.serving)) {
          getIngridientServingData = data.food.servings.serving.filter((item: any) => item.is_default === '1');
          currentServingDescription = getIngridientServingData[0].serving_description.replace(/\s+/g, '');
        } else {
          getIngridientServingData = [data.food.servings.serving];
          currentServingDescription = data.food.servings.serving.serving_description.replace(/\s+/g, '');
        }

        let thisPiece;
        if (getIngridientServingData[0].serving_description.includes('1 ')) {
          if (
            getIngridientServingData[0].serving_description.includes('g)') ||
            getIngridientServingData[0].serving_description.includes('ml)')
          ) {
            thisPiece = getIngridientServingData[0].serving_description.substr(
              2,
              getIngridientServingData[0].serving_description.indexOf(' (') - 2
            );
          } else {
            thisPiece = getIngridientServingData[0].serving_description.substr(
              2,
              getIngridientServingData[0].serving_description.length
            );
          }
        } else {
          thisPiece = getIngridientServingData[0].serving_description;
        }
        if (thisPiece.includes('(')) {
          thisPiece = thisPiece.substr(0, thisPiece.indexOf('(') - 1);
        }

        if (
          currentServingDescription === '100ml' ||
          currentServingDescription === '100g' ||
          getIngridientServingData[0].measurement_description === 'g' ||
          getIngridientServingData[0].measurement_description === 'ml'
        ) {
          if (
            getIngridientServingData[0].metric_serving_unit === 'ml' ||
            getIngridientServingData[0].measurement_description === 'ml'
          ) {
            thisPiece = 'ml';
          } else {
            thisPiece = 'g';
          }
        }

        if (
          currentServingDescription !== '100ml' &&
          currentServingDescription !== '100g' &&
          getIngridientServingData[0].measurement_description !== 'g' &&
          getIngridientServingData[0].measurement_description !== 'ml'
        ) {
          thisAmount = getIngridientServingData[0].number_of_units.substr(
            0,
            getIngridientServingData[0].number_of_units.indexOf('.')
          );
        } else {
          thisAmount = 100;
        }

        if (getIngridientServingData[0].metric_serving_amount) {
          ingridientKcal100g = Math.round(
            (parseFloat(getIngridientServingData[0].calories) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
          ingridientKH100g = Math.round(
            (parseFloat(getIngridientServingData[0].carbohydrate) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
          ingridientEW100g = Math.round(
            (parseFloat(getIngridientServingData[0].protein) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
          ingridientFT100g = Math.round(
            (parseFloat(getIngridientServingData[0].fat) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
        } else {
          ingridientKcal100g = Math.round(parseFloat(getIngridientServingData[0].calories));
          ingridientKH100g = Math.round(parseFloat(getIngridientServingData[0].carbohydrate));
          ingridientEW100g = Math.round(parseFloat(getIngridientServingData[0].protein));
          ingridientFT100g = Math.round(parseFloat(getIngridientServingData[0].fat));
        }

        let thisFoodName = data.food.food_name;
        if (data.food.brand_name) {
          thisFoodName += ` (${data.food.brand_name})`;
        }

        let thisFoodCategory = 'Sonstiges';
        if (data.food.food_sub_categories) {
          if (Array.isArray(data.food.food_sub_categories.food_sub_category)) {
            thisFoodCategory = data.food.food_sub_categories.food_sub_category[1].toString();
          } else {
            thisFoodCategory = data.food.food_sub_categories.food_sub_category;
          }
        }

        const thisIngridientObject = {
          id: food_id,
          name: thisFoodName,
          amount: thisAmount,
          piece: thisPiece,
          newAdded: true,
          kcal_100g: ingridientKcal100g,
          carbohydrates_100g: ingridientKH100g,
          protein_100g: ingridientEW100g,
          fat_100g: ingridientFT100g,
          kcal_total: Math.round(parseFloat(getIngridientServingData[0].calories)),
          carbohydrates_total: Math.round(parseFloat(getIngridientServingData[0].carbohydrate)),
          protein_total: Math.round(parseFloat(getIngridientServingData[0].protein)),
          fat_total: Math.round(parseFloat(getIngridientServingData[0].fat)),
          preselect_g:
            (getIngridientServingData[0].metric_serving_amount &&
              getIngridientServingData[0].metric_serving_amount.substr(
                0,
                getIngridientServingData[0].metric_serving_amount.indexOf('.')
              )) ||
            100,
          category: thisFoodCategory,
          serving_id: getIngridientServingData[0].serving_id,
          serving_unit:
            getIngridientServingData[0].metric_serving_unit !== undefined
              ? getIngridientServingData[0].metric_serving_unit
              : 'g',
          serving_data: data.food.servings,
        };

        setCurrentEditIngridientAPIFoodId(food_id);
        setCurrentEditIngridientType('notAdded');
        handleEditIngridientPopupAPI(thisIngridientObject);
      });
    } catch (err) {
      console.log('error');
    }
  }

  // Delete item from direct added list and ingridient list
  function deleteDirectAddedIngridient(
    thisId: string,
    amount: number,
    piece: string,
    preselect_g: string,
    kcal_100g: string,
    carbohydrates_100g: string,
    protein_100g: string,
    fat_100g: string
  ) {
    let ingridientKcal;
    let ingridientKh;
    let ingridientEw;
    let ingridientFt;

    if (piece !== 'g' && piece !== 'ml') {
      ingridientKcal = Math.round(((amount * parseFloat(preselect_g)) / 100) * parseFloat(kcal_100g));
      ingridientKh = Math.round(((amount * parseFloat(preselect_g)) / 100) * parseFloat(carbohydrates_100g));
      ingridientEw = Math.round(((amount * parseFloat(preselect_g)) / 100) * parseFloat(protein_100g));
      ingridientFt = Math.round(((amount * parseFloat(preselect_g)) / 100) * parseFloat(fat_100g));
    } else {
      ingridientKcal = Math.round((parseFloat(kcal_100g) / 100) * amount);
      ingridientKh = Math.round((parseFloat(carbohydrates_100g) / 100) * amount);
      ingridientEw = Math.round((parseFloat(protein_100g) / 100) * amount);
      ingridientFt = Math.round((parseFloat(fat_100g) / 100) * amount);
    }

    const checkIncompabilityArray = [] as any;
    const checkFormOfNutritionArray = [] as any;
    let thisRecipeData = currentAddRecipe as any;

    const newColumns = {
      ...currentAddRecipe,
      kcal_total: Math.round(parseFloat(currentAddRecipe!.kcal_total) - ingridientKcal),
      carbohydrates_total: `${Math.round(parseFloat(currentAddRecipe!.carbohydrates_total) - ingridientKh)}`,
      protein_total: `${Math.round(parseFloat(currentAddRecipe!.protein_total) - ingridientEw)}`,
      fat_total: `${Math.round(parseFloat(currentAddRecipe!.fat_total) - ingridientFt)}`,
      ingredients: [...currentAddRecipe!.ingredients.filter((item: any) => item.id !== thisId)],
    };

    // check incombality options of ingredients
    for (let i = 0; i < newColumns.ingredients.length; i += 1) {
      const thisIngridientList = ingredientList as any;
      const getThisIngridient = thisIngridientList?.find((item: any) => item.name === newColumns.ingredients[i].name);

      intolerancesOption.forEach(function (key) {
        if (
          getThisIngridient !== undefined &&
          typeof getThisIngridient[key.toLowerCase()] !== undefined &&
          getThisIngridient[key.toLowerCase()] !== null
        ) {
          if (getThisIngridient[key.toLowerCase()] === true) {
            checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
          }
        }
      });

      formOfNutrition.forEach(function (key) {
        if (
          getThisIngridient !== undefined &&
          typeof getThisIngridient[key.toLowerCase()] !== undefined &&
          getThisIngridient[key.toLowerCase()] !== null
        ) {
          if (getThisIngridient[key.toLowerCase()] === true) {
            checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
          } else {
            checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: false });
          }
        }
      });
    }

    const checkFormOfNutritionArrayMerged = [
      ...checkFormOfNutritionArray.filter((item: any) => item.status === true),
      ...checkFormOfNutritionArray.filter((item: any) => item.status === false),
    ];

    // Copy newColumns to variable because this needs to be updated in the following sections
    thisRecipeData = { ...newColumns };

    // First set all to false
    for (let f = 0; f < intolerancesOption.length; f += 1) {
      thisRecipeData = { ...thisRecipeData, [intolerancesOption[f].toLowerCase()]: false };
    }
    for (let h = 0; h < formOfNutrition.length; h += 1) {
      thisRecipeData = { ...thisRecipeData, [formOfNutrition[h].toLowerCase()]: false };
    }

    // Set elements true if incompatibility exists
    for (let g = 0; g < checkIncompabilityArray.length; g += 1) {
      thisRecipeData = { ...thisRecipeData, [checkIncompabilityArray[g].name]: true };
    }

    // Set elements true if incompatibility exists
    for (let g = 0; g < checkFormOfNutritionArrayMerged.length; g += 1) {
      thisRecipeData = {
        ...thisRecipeData,
        [checkFormOfNutritionArrayMerged[g].name]: checkFormOfNutritionArrayMerged[g].status,
      };
    }

    setDirectAddedIngridients(directAddedIngridients.filter(item => item.id !== thisId));
    updateRecipeState(thisRecipeData);
    setRecipeChanged(true);
  }

  const orderRewe = () => {
    let thisPartnerId = '5e114485-e0e0-4999-a248-e3b799fa0329';

    if (tenantData?.rewePartnerId !== undefined) {
      thisPartnerId = tenantData.rewePartnerId;
    }

    window.open(
      `https://static.tortija.de/interfaces/apis/getReweAsRedirect.php?data=${currentAddRecipeList}&partnerId=${thisPartnerId}`,
      '_blank'
    );
  };

  // Delete api item from direct added list and ingridient list
  function deleteDirectAddedIngridientAPI(
    thisId: string,
    kcal_total: string,
    carbohydrates_total: string,
    protein_total: string,
    fat_total: string
  ) {
    let thisCurrentKcalComplete = 0;
    let thisCurrentKHComplete = 0;
    let thisCurrentEWComplete = 0;
    let thisCurrentFTComplete = 0;

    thisCurrentKcalComplete = Math.round(parseFloat(currentAddRecipe!.kcal_total) - parseFloat(kcal_total));
    thisCurrentKHComplete = Math.round(
      parseFloat(currentAddRecipe!.carbohydrates_total) - parseFloat(carbohydrates_total)
    );
    thisCurrentEWComplete = Math.round(parseFloat(currentAddRecipe!.protein_total) - parseFloat(protein_total));
    thisCurrentFTComplete = Math.round(parseFloat(currentAddRecipe!.fat_total) - parseFloat(fat_total));

    const newColumns = {
      ...currentAddRecipe,
      kcal_total: thisCurrentKcalComplete,
      carbohydrates_total: thisCurrentKHComplete,
      protein_total: thisCurrentEWComplete,
      fat_total: thisCurrentFTComplete,
      ingredients: [...currentAddRecipe!.ingredients.filter((item: any) => item.id !== thisId)],
    };

    setDirectAddedIngridients(directAddedIngridients.filter(item => item.id !== thisId));
    updateRecipeState(newColumns);
    setRecipeChanged(true);
  }

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  function handleEditIngridientPopup(
    ingridientId: string,
    name: string,
    amount: string,
    piece: string,
    imageUrl: string,
    preselect_g: string,
    preselect_type: string,
    kcal_100g: string,
    carbohydrates_100g: string,
    protein_100g: string,
    fat_100g: string,
    default_piece: string,
    type: string,
    category: string
  ) {
    setCurrentEditIngridientKcalValue(0);
    setCurrentEditIngridientCarbohydratesValue(0);
    setCcurrentEditIngridientProteinValue(0);
    setCurrentEditIngridientFatValue(0);
    setPopupOverlayClass('hidden');

    setCurrentEditIngridientPopupType('internal');

    if (piece !== 'g' && piece !== 'ml') {
      setCurrentEditIngridientKcalTotal(
        Math.round(((parseFloat(amount) * parseFloat(preselect_g)) / 100) * parseFloat(kcal_100g))
      );
      setCurrentEditIngridientCarbohydratesTotal(
        Math.round(((parseFloat(amount) * parseFloat(preselect_g)) / 100) * parseFloat(carbohydrates_100g))
      );
      setCurrentEditIngridientProteinTotal(
        Math.round(((parseFloat(amount) * parseFloat(preselect_g)) / 100) * parseFloat(protein_100g))
      );
      setCurrentEditIngridientFatTotal(
        Math.round(((parseFloat(amount) * parseFloat(preselect_g)) / 100) * parseFloat(fat_100g))
      );
    } else {
      setCurrentEditIngridientKcalTotal(Math.round((parseFloat(amount) / 100) * parseFloat(kcal_100g)));
      setCurrentEditIngridientCarbohydratesTotal(
        Math.round((parseFloat(amount) / 100) * parseFloat(carbohydrates_100g))
      );
      setCurrentEditIngridientProteinTotal(Math.round((parseFloat(amount) / 100) * parseFloat(protein_100g)));
      setCurrentEditIngridientFatTotal(Math.round((parseFloat(amount) / 100) * parseFloat(fat_100g)));
    }

    const thisPortionValues: any = [
      { value: preselect_type.toLowerCase(), label: `${preselect_type} (${preselect_g}g)` },
      { value: 'g', label: 'Gramm' },
    ];

    const thisPortionValuesWithDefaultPiece: any = [
      { value: preselect_type.toLowerCase(), label: `${preselect_type} (${preselect_g}${default_piece})` },
      { value: default_piece === 'ml' ? 'ml' : 'g', label: default_piece === 'ml' ? 'Milliliter' : 'Gramm' },
    ];

    if (default_piece !== undefined) {
      setCurrentEditIngridientPortionValues(thisPortionValuesWithDefaultPiece);
      setCurrentEditIngridientDefaultPiece(default_piece);
    } else {
      setCurrentEditIngridientPortionValues(thisPortionValues);
    }

    setCurrentEditIngridientPreselectG(preselect_g);
    setCurrentEditIngridientPreselectType(preselect_type);
    setCurrentEditIngridientKcal100g(kcal_100g);
    setCurrentEditIngridientCarbohydrates100g(carbohydrates_100g);
    setCurrentEditIngridientProtein100g(protein_100g);
    setCurrentEditIngridientFat100g(fat_100g);
    setCurrentEditIngridientCategory(category);

    setCurrentEditIngridientName(name);
    setCurrentEditIngridientType(type);
    setCurrentEditIngridientID(ingridientId);
    setCurrentEditInitialIngridientAmount(amount);
    setCurrentEditIngridientAmount(amount);
    setCurrentEditIngridientPiece(piece);
    if (imageUrl !== undefined) {
      setCurrentEditIngridientImageUrl(imageUrl);
    }
    setEditPopupOverlayClass('block');
  }

  const getItemLabel = () => {
    if (currentAddRecipe?.recipe_piece !== undefined) {
      if (currentAddRecipe?.recipe_piece === 'g') {
        return `${currentAddRecipe?.amount} g`;
      }
    } else if (currentAddRecipe?.recipe_portion !== undefined) {
      return currentAddRecipe?.recipe_portion;
    }

    if (currentOriginalRecipe?.portion_g !== undefined) {
      return `${currentRecipeAmount} Portion (${parseFloat(currentOriginalRecipe?.portion_g)}g)`;
    }

    return '';
  };

  function handleEditIngridientPopupAPI(ingridientData: any) {
    let thisServingDataArray = [] as any;
    let getIngridientServingData: any;
    const thisIngridientData = ingridientData;

    thisServingDataArray = thisIngridientData.serving_data.serving;

    if (thisIngridientData.serving_data.serving instanceof Array) {
      if (thisIngridientData.piece === 'g' || thisIngridientData.piece === 'ml') {
        getIngridientServingData = thisServingDataArray.filter(
          (item: any) => item.serving_id === thisIngridientData.serving_id
        );
      } else {
        getIngridientServingData = thisServingDataArray.filter(
          (item: any) => item.serving_id === thisIngridientData.serving_id
        );
      }
    } else {
      getIngridientServingData = [thisIngridientData.serving_data.serving];
    }

    setCurrentEditIngridientPopupType('api');

    setCurrentEditIngridientKcalValue(parseFloat(getIngridientServingData[0].calories));
    setCurrentEditIngridientCarbohydratesValue(parseFloat(getIngridientServingData[0].carbohydrate));
    setCcurrentEditIngridientProteinValue(parseFloat(getIngridientServingData[0].protein));
    setCurrentEditIngridientFatValue(parseFloat(getIngridientServingData[0].fat));
    setPopupOverlayClass('hidden');

    if (getIngridientServingData[0].metric_serving_amount) {
      setCurrentEditIngridientMetricServingAmountValue(getIngridientServingData[0].metric_serving_amount);
    }

    let thisPortionValues: any;
    let thisPieceValue = '';

    if (getPrimaryPieceValue(thisIngridientData).length > 0) {
      thisPortionValues = [
        {
          value: getPrimaryPieceValue(thisIngridientData)?.toLowerCase(),
          label: getPrimaryPieceValue(thisIngridientData),
        },
        { value: 'g', label: 'Gramm' },
      ];
      thisPieceValue = getPrimaryPieceValue(thisIngridientData);
    } else {
      thisPortionValues = [{ value: 'g', label: 'Gramm' }];
      thisPieceValue = 'g';
    }

    if (thisIngridientData.piece !== 'g' && thisIngridientData.piece !== 'ml') {
      setCurrentEditIngridientKcalTotal(
        Math.round(
          ((parseFloat(thisIngridientData.amount) * parseFloat(thisIngridientData.preselect_g)) / 100) *
            parseFloat(thisIngridientData.kcal_100g)
        )
      );
      setCurrentEditIngridientCarbohydratesTotal(
        Math.round(
          ((parseFloat(thisIngridientData.amount) * parseFloat(thisIngridientData.preselect_g)) / 100) *
            parseFloat(thisIngridientData.carbohydrates_100g)
        )
      );
      setCurrentEditIngridientProteinTotal(
        Math.round(
          ((parseFloat(thisIngridientData.amount) * parseFloat(thisIngridientData.preselect_g)) / 100) *
            parseFloat(thisIngridientData.protein_100g)
        )
      );
      setCurrentEditIngridientFatTotal(
        Math.round(
          ((parseFloat(thisIngridientData.amount) * parseFloat(thisIngridientData.preselect_g)) / 100) *
            parseFloat(thisIngridientData.fat_100g)
        )
      );
    } else {
      setCurrentEditIngridientKcalTotal(
        Math.round((parseFloat(thisIngridientData.amount) / 100) * parseFloat(thisIngridientData.kcal_100g))
      );
      setCurrentEditIngridientCarbohydratesTotal(
        Math.round((parseFloat(thisIngridientData.amount) / 100) * parseFloat(thisIngridientData.carbohydrates_100g))
      );
      setCurrentEditIngridientProteinTotal(
        Math.round((parseFloat(thisIngridientData.amount) / 100) * parseFloat(thisIngridientData.protein_100g))
      );
      setCurrentEditIngridientFatTotal(
        Math.round((parseFloat(thisIngridientData.amount) / 100) * parseFloat(thisIngridientData.fat_100g))
      );
    }

    setCurrentEditIngridientPortionValues(thisPortionValues);
    if (thisIngridientData.piece === 'g' || thisIngridientData.piece === 'ml') {
      setCurrentEditIngridientPiece(thisIngridientData.piece);
    } else {
      setCurrentEditIngridientPiece(thisPieceValue);
    }
    setCurrentEditIngridientAmount(thisIngridientData.amount);
    setCurrentEditInitialIngridientAmount(thisIngridientData.amount);
    setCurrentEditIngridientName(thisIngridientData.name);
    setCurrentEditIngridientID(thisIngridientData.id);
    setCurrentEditIngridientImageUrl('');
    if (!ingridientData.newAdded) {
      setCurrentEditIngridientType('edit');
    }

    setEditPopupOverlayClass('block');
    setIsLoading(false);
  }

  function handleOpenSearchPopup() {
    if (searchIngridientRef.current) {
      searchIngridientRef.current.value = '';
    }
    setPopupOverlayClass('block');
  }

  function handleOpenClosePopups(closeStateObject: any, openStateObject: any, type: string) {
    if (type === 'both') {
      closeStateObject('hidden');
      openStateObject('block');
    } else if (type === 'open') {
      openStateObject('block');
    } else if (type === 'close') {
      closeStateObject('hidden');
    }
  }

  function getPrimaryPieceValue(ingridientData: any) {
    let thisPieceValue = '';

    if (ingridientData.serving_data.serving && ingridientData.serving_data.serving instanceof Array) {
      ingridientData.serving_data.serving.map((serving: any, index: any) => {
        if (
          serving.measurement_description !== 'g' &&
          serving.measurement_description !== 'ml' &&
          serving.serving_description !== '100ml' &&
          serving.serving_description !== '100g' &&
          serving.is_default === '1' &&
          !serving.serving_description.includes('(')
        ) {
          thisPieceValue =
            (serving.serving_description.includes('1 ') &&
              serving.serving_description.substr(2, serving.serving_description.length)) ||
            `${serving.serving_description} `;

          if (
            serving.measurement_description !== 'g' &&
            serving.measurement_description !== 'ml' &&
            !serving.measurement_description.includes('g)') &&
            !serving.measurement_description.includes('ml)')
          ) {
            thisPieceValue = `${thisPieceValue} (${serving.metric_serving_amount.substr(
              0,
              serving.metric_serving_amount.indexOf('.')
            )} ${serving.metric_serving_unit})`;
          }
        }

        if (
          serving.measurement_description !== 'g' &&
          serving.measurement_description !== 'ml' &&
          serving.serving_description !== '100ml' &&
          serving.serving_description !== '100g' &&
          serving.is_default === '1' &&
          serving.serving_description.includes('(')
        ) {
          thisPieceValue =
            (serving.serving_description.includes('1 ') &&
              serving.serving_description.substr(2, serving.serving_description.indexOf('(') - 3)) ||
            `${serving.serving_description.substr(0, serving.serving_description.indexOf('(') - 3)} `;

          if (
            serving.measurement_description !== 'g' &&
            serving.measurement_description !== 'ml' &&
            !serving.measurement_description.includes('g)') &&
            !serving.measurement_description.includes('ml)')
          ) {
            thisPieceValue = `${thisPieceValue} (${serving.metric_serving_amount.substr(
              0,
              serving.metric_serving_amount.indexOf('.')
            )} ${serving.metric_serving_unit})`;
          }
        }

        return '';
      });

      return thisPieceValue;
    }

    if (
      ingridientData.serving_data.serving &&
      !(ingridientData.serving_data.serving instanceof Array) &&
      ingridientData.serving_data.serving.serving_description !== '100ml' &&
      ingridientData.serving_data.serving.serving_description !== '100g' &&
      !ingridientData.serving_data.serving.serving_description.includes('(')
    ) {
      thisPieceValue =
        (ingridientData.serving_data.serving.serving_description.includes('1 ') &&
          ingridientData.serving_data.serving.serving_description.substr(
            2,
            ingridientData.serving_data.serving.serving_description.length
          )) ||
        ingridientData.serving_data.serving.serving_description;

      if (
        ingridientData.serving_data.serving.measurement_description !== 'g' &&
        ingridientData.serving_data.serving.measurement_description !== 'ml' &&
        !ingridientData.serving_data.serving.measurement_description.includes('g)') &&
        !ingridientData.serving_data.serving.measurement_description.includes('ml)') &&
        ingridientData.serving_data.serving.metric_serving_amount
      ) {
        thisPieceValue = `${thisPieceValue} (${ingridientData.serving_data.serving.metric_serving_amount.substr(
          0,
          ingridientData.serving_data.serving.metric_serving_amount.indexOf('.')
        )} ${ingridientData.serving_data.serving.metric_serving_unit})`;
      }

      return thisPieceValue;
    }

    if (
      ingridientData.serving_data.serving &&
      !(ingridientData.serving_data.serving instanceof Array) &&
      ingridientData.serving_data.serving.serving_description !== '100ml' &&
      ingridientData.serving_data.serving.serving_description !== '100g' &&
      ingridientData.serving_data.serving.serving_description.includes('(')
    ) {
      thisPieceValue =
        (ingridientData.serving_data.serving.serving_description.includes('1 ') &&
          ingridientData.serving_data.serving.serving_description.substr(
            2,
            ingridientData.serving_data.serving.serving_description.indexOf('(') - 3
          )) ||
        ingridientData.serving_data.serving.serving_description.substr(
          0,
          ingridientData.serving_data.serving.serving_description.indexOf('(') - 3
        );

      if (
        ingridientData.serving_data.serving.measurement_description !== 'g' &&
        ingridientData.serving_data.serving.measurement_description !== 'ml' &&
        !ingridientData.serving_data.serving.measurement_description.includes('g)') &&
        !ingridientData.serving_data.serving.measurement_description.includes('ml)') &&
        ingridientData.serving_data.serving.metric_serving_amount
      ) {
        thisPieceValue = `${thisPieceValue} (${ingridientData.serving_data.serving.metric_serving_amount.substr(
          0,
          ingridientData.serving_data.serving.metric_serving_amount.indexOf('.')
        )} ${ingridientData.serving_data.serving.metric_serving_unit})`;
      }

      return thisPieceValue;
    }
    return thisPieceValue;
  }

  function dataURItoBlob(dataURI: any) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  // Image crop

  const handleSetShareMail = (mailString: string) => {
    if (sharePopupRecipeInputRef.current) {
      sharePopupRecipeInputRef.current.innerHTML = mailString;
      setCurrentShareMail(mailString);
    }
  };

  return (
    <>
      <IncompatibilityOverlay visible={visibleIncompatibilityPopup} setVisible={setVisibleIncompatibilityPopup} />

      <div className={editPictureOverlayClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopupWithScroll}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <PlusIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Neues Bild hinzufügen</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setEditPictureOverlayClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className="pt-15  pb-30">
                <div className="font-light text-base pl-20 pr-15">
                  Hier kannst du ein neues Foto hochladen und einen geeigneten Ausschnitt wählen!
                </div>
                <div className="pt-40 pl-20 pr-15">
                  <input type="file" accept="image/*" onChange={onSelectFile} />
                </div>
                <div className="pt-20 large:pl-20 large:pr-15">
                  <ReactCrop
                    src={upImg as string}
                    onImageLoaded={onLoad}
                    crop={crop}
                    locked
                    onChange={c => setCrop(c)}
                    onComplete={c => setCompletedCrop(c as CompletedCrop)}
                  />
                </div>
                <div className="hidden">
                  <canvas
                    ref={previewCanvasRef}
                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                    style={{
                      width: Math.round(completedCrop?.width ?? 0),
                      height: Math.round(completedCrop?.height ?? 0),
                    }}
                  />
                </div>
              </div>
              <div className="pl-20 pr-10 pb-40">
                <Button className="w-full" onClick={() => saveNewRecipePicture()}>
                  {t('Save')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={sharePlanOverlayClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <ShareIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>

                <div className="text-xl my-auto font-light">Rezept teilen</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setSharePlanOverlayClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className={styles.editPictureIngridientPopupContent}>
                <div className="mx-20 pt-20 pb-40 font-extralight">
                  <div>
                    <div className="pt-20 font-extralight pb-1">
                      Bitte gebe die Email Adresse der Person ein mit der du dieses Rezept teilen möchtest:
                    </div>
                    <div className="flex">
                      <div className="w-3/4">
                        <div className="font-light left text-xs border-opacity-30 w-full">
                          <span
                            className="appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                            role="textbox"
                            contentEditable
                            onFocus={setBodyClassForMobileSafari}
                            onBlur={removeBodyClassForMobileSafari}
                            ref={sharePopupRecipeInputRef}
                            suppressContentEditableWarning
                            onInput={e => changePlanShareEmail(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pt-20 font-extralight pb-1">
                      Das Rezept erscheint dann bei der Person im Profil unter "Nachrichten".
                    </div>
                    {shareMailArray.length > 0 && <div className="font-bold pt-10">Zuletzt:</div>}
                    {shareMailArray.map((item: any, index: number) => (
                      <div
                        key={index}
                        className="flex gap-10 pt-5 cursor-pointer"
                        onClick={() => handleSetShareMail(item.mail)}
                        aria-hidden="true"
                      >
                        <div>
                          <PlusIcon
                            width={25}
                            height={25}
                            className="text-accentColor mx-auto cursor-pointer rotate-180"
                          />
                        </div>
                        <div>{item.mail}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="pl-20 pr-10 pb-40">
                  <Button isPending={shareLoading} className="w-full" onClick={() => triggerShare()}>
                    Rezept teilen
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={deleteRecipeOverlayClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <TrashIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Rezept löschen</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setDeleteRecipeOverlayClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className="pt-15 pl-20 pb-30">
                <div className="pt-20 font-light text-base">Wollen Sie dieses Rezept wirklich löschen?</div>
                <div className="pt-30 flex gap-30">
                  <div>
                    <Button className="w-full" onClick={() => deleteRecipeFromFirebase()}>
                      Ja
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="w-full"
                      onClick={() => handleOpenClosePopups(setDeleteRecipeOverlayClass, '', 'close')}
                    >
                      Nein
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={addToPlanOverlayClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <PlusIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Rezept zum Plan hinzufügen</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setAddToPlanOverlayClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className="pt-15 pl-20 pb-30">
                <div className="pt-30 font-extralight pr-30 pb-10">
                  Bitte wähle ein Datum aus, wann du dieses Rezept hinzufügen möchtest:
                </div>
                <div className="flex">
                  <div className="w-auto font-light left text-xs border-opacity-30">
                    <DatePicker
                      className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                      selected={startDate}
                      onChange={onChange}
                      locale="de"
                      startDate={startDate}
                      dateFormat="dd.MM.yyyy"
                      withPortal
                      customInput={React.createElement(React.forwardRef(CustomInput))}
                      calendarStartDay={1}
                      onFocus={e => e.target.blur()}
                    />
                  </div>
                </div>
                <div>
                  <div className="pt-20">
                    <div className="font-extralight my-auto pb-10">Mahlzeit auswählen:</div>
                    <div className="w-1/2">
                      <div>
                        <Select
                          options={mealTypeValues}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          unstyled
                          value={mealTypeValues.filter((item: any) => item.value === currentAddMealTypeValue)}
                          onChange={e => setCurrentAddMealTypeValue(e?.value as string)}
                          isSearchable={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-40 pb-40">
                  <Button className="w-full" onClick={() => addRecipeToPlan('add')}>
                    Rezept hinzufügen
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={editFormOfNutritionOverlayClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editPicturePopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <PencilIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Ernährungsform bearbeiten</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setEditFormOfNutritionOverlayClass, '', 'close')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className="pt-30 font-extralight text-base pl-20 pr-15 pb-30">
                <div>Folgende Ernährungsformen passen zu diesem Rezept.</div>
                <div className="pt-1">Du kannst diese bei Bedarf anpassen (Mehrfachauswahl möglich).</div>
                <div className="pt-40">
                  {formOfNutrition.map((item: string, index: number) => (
                    <>
                      <SwitchButton
                        key={index}
                        label={item}
                        enabled={
                          currentAddRecipe?.name !== undefined &&
                          typeof currentAddRecipe[item.toLowerCase()] !== undefined
                            ? Boolean(currentAddRecipe[item.toLowerCase()])
                            : false
                        }
                        isBackground={index % 2 === 0}
                        onChange={setFormOfNutritions}
                      />
                    </>
                  ))}
                </div>
              </div>
              <div className="pl-20 pr-10 pb-40">
                <Button
                  className="w-full"
                  onClick={() => handleOpenClosePopups(setEditFormOfNutritionOverlayClass, '', 'close')}
                >
                  {t('Finish')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cookingModeOverlayClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.editCookingModePopup}>
            <div className="flex justify-between xl:pt-20 pt-60 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <Icon name="cooking" width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Kochmodus</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => setCookingModeOverlayClass('hidden')}
                />
              </div>
            </div>
            <div className={styles.editCookingModePopupContent}>
              <CookingMode
                step={currentCookingStep}
                stepFunction={setCurrentCookingStep}
                descriptionArray={currentAddRecipe?.description}
              />
              <div className="pl-20 pr-10 pb-40 pt-40 z-150">
                <RecipeStepSwitch
                  returnFunction={returnToPreviousStep}
                  nextFunction={jumpToNextStep}
                  currentStepValue={currentCookingStep.toString()}
                  totalSteps={currentAddRecipe?.description.length}
                  isFinished={currentCookingStep === currentAddRecipe?.description.length}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {typeof location.state !== undefined && location.state !== null && location.state?.from !== 'plans' && (
        <div
          className={cookingModeOverlayClass === 'hidden' && addToPlanOverlayClass === 'hidden' ? 'block' : 'hidden'}
        >
          <div className={styles.mobileButtonGroup} id="addButtonMobile">
            {typeof location.state !== undefined && location.state !== null && location.state?.from === 'planEdit' ? (
              <></>
            ) : currentRecipeAmount !== '1' ? (
              <></>
            ) : typeof location.state !== undefined &&
              location.state !== null &&
              (location.state?.from === 'planEditChangeRecipe' || location.state?.from === 'dashboard') ? (
              <></>
            ) : recipeChanged && userData?.role === 1 ? (
              <>
                <div className="pl-30">
                  <Button className="w-full" onClick={() => saveNewRecipeToFirebase()}>
                    {t('SaveAsNewRecipe')}
                  </Button>
                </div>
              </>
            ) : recipeChanged && currentAddRecipe?.userRecipe && id !== 'shared' ? (
              <div className="ml-30">
                <Button onClick={() => saveExistingRecipeToFirebase()}>Änderungen speichern</Button>
              </div>
            ) : recipeChanged &&
              currentAddRecipe?.communityRecipe &&
              (userData?.isAdminUser || userData?.isTrainerUser || userData?.isLocationAdminUser) &&
              id !== 'shared' ? (
              <div className="ml-30">
                <Button onClick={() => saveExistingRecipeToFirebase()}>Änderungen speichern</Button>
              </div>
            ) : recipeChanged ? (
              <div>
                <Button className="ml-30" onClick={() => saveNewRecipeToFirebase()}>
                  {t('SaveAsNewRecipe')}
                </Button>
              </div>
            ) : (
              <></>
            )}
            <div className="flex-1 px-30">
              {typeof location.state !== undefined &&
              location.state !== null &&
              location.state?.isCurrentRecipeDashboard === undefined &&
              (location.state?.from === 'planEditChangeRecipe' || location.state?.from === 'dashboard') ? (
                <Button onClick={() => changeRecipeInPlan()} className="w-full h-full">
                  Speichern
                </Button>
              ) : typeof location.state !== undefined &&
                location.state !== null &&
                location.state?.from === 'planEdit' ? (
                <Button onClick={() => addRecipeToPlan()} className="w-full h-full">
                  {t('Add')}
                </Button>
              ) : (
                <Button
                  onClick={() => handleOpenClosePopups('', setAddToPlanOverlayClass, 'open')}
                  className="w-full h-full"
                >
                  {t('Add')}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}

      <div className={editPopupOverlayClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.addIngridientPopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <PencilIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Bearbeiten</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setEditPopupOverlayClass, setPopupOverlayClass, 'both')}
                />
              </div>
            </div>
            <div className={styles.ingridientPopupContentEdit}>
              <div className="pt-15 pl-20 pb-30 pr-15">
                <div>
                  <div className="flex gap-20 pt-4">
                    <div className="font-light my-auto w-130">Menge auswählen:</div>
                    <div>
                      {currentEditIngridientType === 'notAdded' && (
                        <div onClick={() => clickIngridientChange()} aria-hidden="true">
                          <CustomUserInput
                            thisRef={editIngridientRef}
                            name="amount"
                            customPaddingY="10"
                            customPaddingX="10"
                            thisValue={currentEditIngridientAmount}
                            submitFunction={() =>
                              currentEditIngridientPopupType === 'api'
                                ? addChangedIngridientAPI()
                                : addIngridientDirectInternal(
                                    currentEditIngridientPiece,
                                    currentEditIngridientAmount.toString().length === 0
                                      ? 0
                                      : parseFloat(currentEditIngridientAmount),
                                    currentEditIngridientPreselectG,
                                    currentEditIngridientKcal100g,
                                    currentEditIngridientCarbohydrates100g,
                                    currentEditIngridientProtein100g,
                                    currentEditIngridientFat100g,
                                    currentEditIngridientName,
                                    currentEditIngridientCategory,
                                    currentEditIngridientImageUrl,
                                    currentEditIngridientPreselectType,
                                    currentEditIngridientDefaultPiece
                                  )
                            }
                            onChange={e =>
                              currentEditIngridientPopupType === 'api'
                                ? changeIngridientAmountAPI(e)
                                : changeIngridientAmount(e)
                            }
                            width={3}
                          />
                        </div>
                      )}
                      {currentEditIngridientType !== 'notAdded' && (
                        <div onClick={() => clickIngridientChange()} aria-hidden="true">
                          <CustomUserInput
                            thisValue={currentEditIngridientAmount}
                            thisRef={editIngridientRef}
                            name="amount"
                            submitFunction={() =>
                              currentEditIngridientPopupType === 'api'
                                ? changeIngridientExecuteAPI()
                                : changeIngridientExecute()
                            }
                            onChange={e =>
                              currentEditIngridientPopupType === 'api'
                                ? changeIngridientAmountAPI(e)
                                : changeIngridientAmount(e)
                            }
                            width={
                              currentEditIngridientAmount.toString().length === 0
                                ? 1
                                : currentEditIngridientAmount.toString().length
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex gap-20 pt-4">
                    <div className="font-light my-auto w-130">Einheit auswählen:</div>
                    <div className="flex-1">
                      <div>
                        <Select
                          options={currentEditIngridientPortionValues}
                          className="react-select-container-full"
                          classNamePrefix="react-select"
                          unstyled
                          onChange={e =>
                            currentEditIngridientPopupType === 'api'
                              ? changeIngridientPieceAPI(e)
                              : changeIngridientPiece(e)
                          }
                          value={currentEditIngridientPortionValues.filter(
                            (item: any) => item.value === currentEditIngridientPiece.toLowerCase()
                          )}
                          isSearchable={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pb-20 large:pb-40">
                <div className="text-xl pl-20">Nährwertangabe</div>
                <div className="pt-10 flex pl-20">
                  <div className={styles.nutritionBorderItemFirst}>
                    <div>
                      <div className="font-semibold text-base">{currentEditIngridientKcalTotal}</div>
                      <div className={styles.recipeLabel}>kcal</div>
                    </div>
                    <span className="divider" />
                  </div>
                  <div className={styles.nutritionBorderItem}>
                    <div className="font-semibold text-base">{currentEditIngridientCarbohydratesTotal}g</div>
                    <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
                  </div>
                  <div className={styles.nutritionBorderItem}>
                    <div className="font-semibold text-base">{currentEditIngridientProteinTotal}g</div>
                    <div className={styles.recipeLabel}>{t('Protein')}</div>
                  </div>
                  <div className="text-center px-20">
                    <div className="font-semibold text-base">{currentEditIngridientFatTotal}g</div>
                    <div className={styles.recipeLabel}>{t('Fat')}</div>
                  </div>
                </div>
                {currentEditIngridientImageUrl.length > 0 && (
                  <>
                    <div className="pt-30 large:pt-40 pl-25">
                      <img
                        src={currentEditIngridientImageUrl}
                        alt="Ingridient"
                        className="rounded-2xl h-90 large:h-auto w-full object-cover"
                        loading="lazy"
                      />
                    </div>
                  </>
                )}
                <div className="pl-20 pt-30 large:pt-40 bottom-20">
                  {isLoading ? (
                    <div className="flex justify-center items-center pt-20">
                      <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
                    </div>
                  ) : (
                    <>
                      {currentEditIngridientType === 'add' && (
                        <Button
                          className="w-full"
                          onClick={() =>
                            currentEditIngridientPopupType === 'api'
                              ? changeIngridientExecuteAPI()
                              : addIngridientDirectInternal(
                                  currentEditIngridientPiece,
                                  currentEditIngridientAmount.toString().length === 0
                                    ? 0
                                    : parseFloat(currentEditIngridientAmount),
                                  '100',
                                  '100',
                                  '100',
                                  '100',
                                  '100',
                                  currentEditIngridientName,
                                  '100',
                                  currentEditIngridientImageUrl,
                                  currentEditIngridientPiece,
                                  currentEditIngridientDefaultPiece
                                )
                          }
                        >
                          {t('Save')}
                        </Button>
                      )}
                      {currentEditIngridientType === 'edit' && (
                        <Button
                          className="w-full"
                          onClick={() =>
                            currentEditIngridientPopupType === 'api'
                              ? changeIngridientExecuteAPI()
                              : changeIngridientExecute()
                          }
                        >
                          {t('Save')}
                        </Button>
                      )}
                      {currentEditIngridientType === 'notAdded' && (
                        <Button
                          className="w-full"
                          onClick={() =>
                            currentEditIngridientPopupType === 'api'
                              ? addChangedIngridientAPI()
                              : addIngridientDirectInternal(
                                  currentEditIngridientPiece,
                                  currentEditIngridientAmount.toString().length === 0
                                    ? 0
                                    : parseFloat(currentEditIngridientAmount),
                                  currentEditIngridientPreselectG,
                                  currentEditIngridientKcal100g,
                                  currentEditIngridientCarbohydrates100g,
                                  currentEditIngridientProtein100g,
                                  currentEditIngridientFat100g,
                                  currentEditIngridientName,
                                  currentEditIngridientCategory,
                                  currentEditIngridientImageUrl,
                                  currentEditIngridientPreselectType,
                                  currentEditIngridientDefaultPiece
                                )
                          }
                        >
                          {t('Add')}
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={popupOverlayClass}>
        <div className={styles.backgroundPopupLayer}>
          <div className={styles.addIngridientPopup}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <PlusIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Hinzufügen</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setPopupOverlayClass, '', 'close')}
                />
              </div>
            </div>
            <div className="pt-20 pl-20 pr-20">
              <div>
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    ingridientApiSearch(currentSearchQuery);
                  }}
                >
                  <SearchBox
                    thisRef={searchIngridientRef}
                    searchValue={currentSearchQuery}
                    onChange={ingridientAutoCompleteSearch}
                  />
                </form>
              </div>
            </div>
            <div className="pt-15 pl-20 pb-40">
              {currentIngridientSearchAutocomplete.suggestions !== null &&
                Array.isArray(currentIngridientSearchAutocomplete.suggestions.suggestion) &&
                currentSearchQuery.length > 0 &&
                currentIngridientSearchAutocomplete.suggestions.suggestion.map((plans, index) => (
                  <div
                    key={index}
                    onClick={() => ingridientApiSearch(plans)}
                    onKeyDown={() => ingridientApiSearch(plans)}
                    aria-hidden="true"
                  >
                    {plans.length > 1 && (
                      <div className="flex border-b py-7 border-blackSemiDark hover:bg-lightGray hover:bg-opacity-20 cursor-pointer mr-30">
                        <div className="pl-10 pr-15 my-auto">
                          <SearchIcon width={15} height={15} className="text-accentColor" />
                        </div>
                        <div key={index}>
                          <button type="button">{plans}</button>
                        </div>
                      </div>
                    )}
                  </div>
                ))}

              {currentIngridientSearchAutocomplete.suggestions !== null &&
                !Array.isArray(currentIngridientSearchAutocomplete.suggestions.suggestion) &&
                currentSearchQuery.length > 0 && (
                  <div
                    className="flex border-b py-7 border-blackSemiDark hover:bg-lightGray hover:bg-opacity-20 cursor-pointer mr-30"
                    onClick={() =>
                      ingridientApiSearch(currentIngridientSearchAutocomplete.suggestions.suggestion.toString())
                    }
                    onKeyDown={() =>
                      ingridientApiSearch(currentIngridientSearchAutocomplete.suggestions.suggestion.toString())
                    }
                    aria-hidden="true"
                  >
                    <div className="pl-10 pr-15 my-auto">
                      <SearchIcon width={15} height={15} className="text-accentColor" />
                    </div>
                    <div>
                      <button type="button">{currentIngridientSearchAutocomplete.suggestions.suggestion}</button>
                    </div>
                  </div>
                )}
              <div className={currentSearchQuery.length > 0 ? 'hidden' : 'visible'}>
                {directAddedIngridients.length > 0 && (
                  <div className="pb-10 font-light text-base">Aktuell hinzugefügt:</div>
                )}
                {directAddedIngridients.length > 0 &&
                  directAddedIngridients.map((ingridient, index) => {
                    return (
                      <>
                        <div className="pr-20 flex" key={index}>
                          <div
                            className={styles.contentItem}
                            onClick={() =>
                              ingridient.serving_data
                                ? handleEditIngridientPopupAPI(ingridient)
                                : handleEditIngridientPopup(
                                    ingridient.id,
                                    ingridient.name,
                                    ingridient.amount,
                                    ingridient.piece,
                                    ingridient.imageUrl,
                                    ingridient.preselect_g,
                                    ingridient.preselect_type,
                                    ingridient.kcal_100g,
                                    ingridient.carbohydrates_100g,
                                    ingridient.protein_100g,
                                    ingridient.fat_100g,
                                    ingridient.default_piece,
                                    'edit',
                                    ingridient.category
                                  )
                            }
                            onKeyDown={() =>
                              ingridient.serving_data
                                ? handleEditIngridientPopupAPI(ingridient)
                                : handleEditIngridientPopup(
                                    ingridient.id,
                                    ingridient.name,
                                    ingridient.amount,
                                    ingridient.piece,
                                    ingridient.imageUrl,
                                    ingridient.preselect_g,
                                    ingridient.preselect_type,
                                    ingridient.kcal_100g,
                                    ingridient.carbohydrates_100g,
                                    ingridient.protein_100g,
                                    ingridient.fat_100g,
                                    ingridient.default_piece,
                                    'edit',
                                    ingridient.category
                                  )
                            }
                            aria-hidden="true"
                          >
                            {(ingridient.userIngridient === undefined || ingridient.userIngridient === false) &&
                            ingridient.communityIngridient === undefined ? (
                              <div className="absolute right-15 top-5 flex gap-5">
                                <div>
                                  <BadgeCheckIcon width={15} height={15} className="text-calories" />
                                </div>
                                {userData?.role === 2 ? (
                                  <div className="font-light text-10">Verified by Tortija</div>
                                ) : (
                                  <div className="font-light text-10">Verified</div>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                            {(ingridient.userIngridient !== undefined || ingridient.userIngridient === true) &&
                            ingridient.communityIngridient === undefined ? (
                              <div className="absolute right-15 top-5 flex gap-5">
                                <div>
                                  <BadgeCheckIcon width={15} height={15} className="text-calories" />
                                </div>
                                <div className="font-light text-10">Eigenes Lebensmittel</div>
                              </div>
                            ) : (
                              <></>
                            )}
                            {ingridient.communityIngridient !== undefined && ingridient.communityIngridient === true ? (
                              <div className="absolute right-15 top-5 flex gap-5">
                                <div>
                                  <BadgeCheckIcon width={15} height={15} className="text-calories" />
                                </div>
                                <div className="font-light text-10">Community Lebensmittel</div>
                              </div>
                            ) : (
                              <></>
                            )}

                            <div className={styles.imageWrapper}>
                              <img
                                src={
                                  ingridient.imageUrl
                                    ? ingridient.imageUrl
                                    : theme?.logo !== undefined && userData?.role !== 2
                                    ? theme.logo
                                    : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fdefault_ingridient.png?alt=media&token=decc7814-19a3-41c9-9949-a965bbfa0ee2'
                                }
                                alt=""
                                width={45}
                                height={45}
                                loading="lazy"
                                className={styles.image}
                              />
                            </div>
                            <div className={styles.description}>
                              <div className="pr-5">
                                <div className="text-14 w-100" title={ingridient.name}>
                                  {ingridient.name}{' '}
                                </div>
                                <div className="text-10 font-light">
                                  {ingridient.amount}
                                  {ingridient.piece.match(/^\d/) && 'x'} {ingridient.piece}{' '}
                                  {ingridient.piece !== 'g' &&
                                    ingridient.piece !== 'ml' &&
                                    `(${ingridient.amount * ingridient.preselect_g}`}
                                  {ingridient.serving_unit &&
                                    ingridient.piece !== 'g' &&
                                    ingridient.piece !== 'ml' &&
                                    `${ingridient.serving_unit})`}
                                  {!ingridient.serving_data &&
                                    ingridient.piece !== 'g' &&
                                    ingridient.piece !== 'ml' &&
                                    ingridient.default_piece === undefined &&
                                    'g)'}
                                  {!ingridient.serving_data &&
                                    ingridient.piece !== 'g' &&
                                    ingridient.piece !== 'ml' &&
                                    ingridient.default_piece !== undefined &&
                                    `${ingridient.default_piece})`}
                                  {ingridient.serving_data &&
                                    !ingridient.serving_unit &&
                                    ingridient.piece !== 'g' &&
                                    ingridient.piece !== 'ml' &&
                                    'g)'}
                                </div>
                              </div>
                              <div className={styles.category}>
                                <div className={styles.item}>
                                  <div className="text-12">
                                    {(ingridient.piece !== 'g' &&
                                      ingridient.piece !== 'ml' &&
                                      Math.round(
                                        ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) / 100) *
                                          parseFloat(ingridient.kcal_100g)
                                      )) ||
                                      Math.round(
                                        (parseFloat(ingridient.amount) / 100) * parseFloat(ingridient.kcal_100g)
                                      )}
                                  </div>
                                  <div>kcal</div>
                                </div>
                                <div className={styles.itemSecondary}>
                                  <div className="text-12">
                                    {(ingridient.piece !== 'g' &&
                                      ingridient.piece !== 'ml' &&
                                      Math.round(
                                        ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) / 100) *
                                          parseFloat(ingridient.carbohydrates_100g)
                                      )) ||
                                      Math.round(
                                        (parseFloat(ingridient.amount) / 100) *
                                          parseFloat(ingridient.carbohydrates_100g)
                                      )}
                                  </div>
                                  <div>KH</div>
                                </div>
                                <div className={styles.itemSecondary}>
                                  <div className="text-12">
                                    {(ingridient.piece !== 'g' &&
                                      ingridient.piece !== 'ml' &&
                                      Math.round(
                                        ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) / 100) *
                                          parseFloat(ingridient.protein_100g)
                                      )) ||
                                      Math.round(
                                        (parseFloat(ingridient.amount) / 100) * parseFloat(ingridient.protein_100g)
                                      )}
                                  </div>
                                  <div>EW</div>
                                </div>
                                <div className={styles.itemSecondary}>
                                  <div className="text-12">
                                    {(ingridient.piece !== 'g' &&
                                      ingridient.piece !== 'ml' &&
                                      Math.round(
                                        ((parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g)) / 100) *
                                          parseFloat(ingridient.fat_100g)
                                      )) ||
                                      Math.round(
                                        (parseFloat(ingridient.amount) / 100) * parseFloat(ingridient.fat_100g)
                                      )}
                                  </div>
                                  <div>Fett</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="pl-5 my-auto">
                            {ingridient.serving_data && (
                              <button
                                type="button"
                                onClick={() =>
                                  deleteDirectAddedIngridientAPI(
                                    ingridient.id,
                                    ingridient.kcal_total,
                                    ingridient.carbohydrates_total,
                                    ingridient.protein_total,
                                    ingridient.fat_total
                                  )
                                }
                              >
                                <TrashIcon width={25} height={25} className="text-accentColor mx-auto" />
                              </button>
                            )}
                            {!ingridient.serving_data && (
                              <button
                                type="button"
                                onClick={() =>
                                  deleteDirectAddedIngridient(
                                    ingridient.id,
                                    ingridient.amount,
                                    ingridient.piece,
                                    ingridient.preselect_g,
                                    ingridient.kcal_100g,
                                    ingridient.carbohydrates_100g,
                                    ingridient.protein_100g,
                                    ingridient.fat_100g
                                  )
                                }
                              >
                                <TrashIcon width={25} height={25} className="text-accentColor mx-auto" />
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
                {directAddedIngridients.length > 0 && (
                  <div className="pt-20 pr-20">
                    <Button className="w-full" onClick={() => setPopupOverlayClass('hidden')}>
                      {t('Finish')}
                    </Button>
                  </div>
                )}
              </div>
              <div className={styles.ingridientPopupContent}>
                {currentIngridientSearch &&
                  ingredientFilteredList?.length === 0 &&
                  currentIngridientSearchApi.foods.food === undefined && (
                    <div className="font-extralight px-20">
                      <p>Es wurden leider keine Lebensmittel zu deinem Suchbegriff gefunden.</p>
                      <p className="pt-10">Bitte versuche es mit einem anderen Suchbegriff.</p>
                    </div>
                  )}

                {isLoading ? (
                  <div className="flex justify-center items-center pt-20">
                    <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
                  </div>
                ) : (
                  <>
                    {currentIngridientSearch &&
                      ingredientFilteredList &&
                      ingredientFilteredList?.length > 0 &&
                      ingredientFilteredList?.map((item, index) => (
                        <div className="flex" key={index}>
                          <div
                            className={styles.contentItem}
                            onClick={() =>
                              handleEditIngridientPopup(
                                Math.random().toString(),
                                item.name,
                                item.preselect_amount,
                                item.preselect_type,
                                item.imageUrl,
                                item.preselect_g,
                                item.preselect_type,
                                item.kcal_100g,
                                item.carbohydrates_100g,
                                item.protein_100g,
                                item.fat_100g,
                                item.default_piece,
                                'notAdded',
                                item.category
                              )
                            }
                            onKeyDown={() =>
                              handleEditIngridientPopup(
                                Math.random().toString(),
                                item.name,
                                item.preselect_amount,
                                item.preselect_type,
                                item.imageUrl,
                                item.preselect_g,
                                item.preselect_type,
                                item.kcal_100g,
                                item.carbohydrates_100g,
                                item.protein_100g,
                                item.fat_100g,
                                item.default_piece,
                                'notAdded',
                                item.category
                              )
                            }
                            aria-hidden="true"
                          >
                            {(item.userIngridient === undefined || item.userIngridient === false) &&
                            item.communityIngridient === undefined ? (
                              <div className="absolute right-15 top-5 flex gap-5">
                                <div>
                                  <BadgeCheckIcon width={15} height={15} className="text-calories" />
                                </div>
                                {userData?.role === 2 ? (
                                  <div className="font-light text-10">Verified by Tortija</div>
                                ) : (
                                  <div className="font-light text-10">Verified</div>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                            {(item.userIngridient !== undefined || item.userIngridient === true) &&
                            item.communityIngridient === undefined ? (
                              <div className="absolute right-15 top-5 flex gap-5">
                                <div>
                                  <BadgeCheckIcon width={15} height={15} className="text-calories" />
                                </div>
                                <div className="font-light text-10">Eigenes Lebensmittel</div>
                              </div>
                            ) : (
                              <></>
                            )}
                            {item.communityIngridient !== undefined && item.communityIngridient === true ? (
                              <div className="absolute right-15 top-5 flex gap-5">
                                <div>
                                  <BadgeCheckIcon width={15} height={15} className="text-calories" />
                                </div>
                                <div className="font-light text-10">Community Lebensmittel</div>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className={styles.imageWrapper}>
                              <img
                                src={
                                  item.imageUrl
                                    ? item.imageUrl
                                    : theme?.logo !== undefined && userData?.role !== 2
                                    ? theme.logo
                                    : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fdefault_ingridient.png?alt=media&token=decc7814-19a3-41c9-9949-a965bbfa0ee2'
                                }
                                alt=""
                                width={45}
                                height={65}
                                loading="lazy"
                                className={styles.image}
                              />
                            </div>
                            <div className={styles.description}>
                              <div className="pr-5">
                                <div className={styles.itemTitle} title={item.name}>
                                  {item.name}
                                </div>

                                <div className="text-10 pb-10 font-light">
                                  {item.preselect_amount} {item.preselect_type}{' '}
                                  {item.preselect_type !== 'g' &&
                                    item.preselect_type !== 'ml' &&
                                    item.default_piece === undefined &&
                                    `(${parseFloat(item.preselect_amount) * parseFloat(item.preselect_g)}g)`}
                                  {item.preselect_type !== 'g' &&
                                    item.preselect_type !== 'ml' &&
                                    item.default_piece !== undefined &&
                                    `(${parseFloat(item.preselect_amount) * parseFloat(item.preselect_g)}${
                                      item.default_piece
                                    })`}
                                </div>
                              </div>
                              <div className={styles.category}>
                                <div className={styles.item}>
                                  <div className="text-12">
                                    {Math.round(
                                      ((parseFloat(item.preselect_amount) * parseFloat(item.preselect_g)) / 100) *
                                        parseFloat(item.kcal_100g)
                                    )}
                                  </div>
                                  <div>kcal</div>
                                </div>
                                <div className={styles.itemSecondary}>
                                  <div className="text-12">
                                    {Math.round(
                                      ((parseFloat(item.preselect_amount) * parseFloat(item.preselect_g)) / 100) *
                                        parseFloat(item.carbohydrates_100g)
                                    )}
                                  </div>
                                  <div>KH</div>
                                </div>
                                <div className={styles.itemSecondary}>
                                  <div className="text-12">
                                    {Math.round(
                                      ((parseFloat(item.preselect_amount) * parseFloat(item.preselect_g)) / 100) *
                                        parseFloat(item.protein_100g)
                                    )}
                                  </div>
                                  <div>EW</div>
                                </div>
                                <div className={styles.itemSecondary}>
                                  <div className="text-12">
                                    {Math.round(
                                      ((parseFloat(item.preselect_amount) * parseFloat(item.preselect_g)) / 100) *
                                        parseFloat(item.fat_100g)
                                    )}
                                  </div>
                                  <div>Fett</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="pl-5 my-auto">
                            <PlusIcon
                              width={25}
                              height={25}
                              className="text-accentColor cursor-pointer"
                              onClick={() =>
                                addIngridientDirectInternal(
                                  item.preselect_type,
                                  parseFloat(item.preselect_amount),
                                  item.preselect_g,
                                  item.kcal_100g,
                                  item.carbohydrates_100g,
                                  item.protein_100g,
                                  item.fat_100g,
                                  item.name,
                                  item.category,
                                  item.imageUrl,
                                  item.preselect_type,
                                  item.default_piece !== undefined ? item.default_piece : 'g'
                                )
                              }
                            />
                          </div>
                        </div>
                      ))}
                    {currentIngridientSearch &&
                      currentIngridientSearchApi.foods?.food?.length > 0 &&
                      currentIngridientSearchApi.foods.food.map((plans: any) => {
                        return (
                          <>
                            {isLoading ? (
                              <div className="flex justify-center items-center pt-20">
                                <ReactLoading
                                  type="bars"
                                  width={20}
                                  height={20}
                                  color={theme?.textColor ?? defaultTextColor}
                                />
                              </div>
                            ) : (
                              <div className="flex">
                                <div
                                  className={styles.contentItem}
                                  onClick={() => getAPIIngridientData(plans.food_id)}
                                  onKeyDown={() => getAPIIngridientData(plans.food_id)}
                                  aria-hidden="true"
                                >
                                  <div className={styles.imageWrapper}>
                                    <img
                                      src="https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fdefault_ingridient.png?alt=media&token=decc7814-19a3-41c9-9949-a965bbfa0ee2"
                                      alt=""
                                      width={45}
                                      height={45}
                                      loading="lazy"
                                      className={styles.image}
                                    />
                                  </div>
                                  <div className={styles.description}>
                                    <div className="pr-5">
                                      <div className={styles.itemTitle} title={plans.food_name}>
                                        {plans.food_name} {plans.brand_name !== undefined && `(${plans.brand_name})`}
                                      </div>
                                      <div className="text-10 font-light">
                                        {plans.food_description.substr(4, plans.food_description.indexOf('-') - 4)}
                                      </div>
                                    </div>
                                    <div className={styles.category}>
                                      <div className={styles.item}>
                                        <div className="text-12">
                                          {plans.food_description.match('Kalorien: (.*)kcal')[1]}
                                        </div>
                                        <div>kcal</div>
                                      </div>
                                      <div className={styles.itemSecondary}>
                                        <div className="text-12">
                                          {Math.round(parseFloat(plans.food_description.match('Kohlh: (.*)g')[1]))}
                                        </div>
                                        <div>KH</div>
                                      </div>
                                      <div className={styles.itemSecondary}>
                                        <div className="text-12">
                                          {Math.round(parseFloat(plans.food_description.match('Eiw: (.*)g')[1]))}
                                        </div>
                                        <div>EW</div>
                                      </div>
                                      <div className={styles.itemSecondary}>
                                        <div className="text-12">
                                          {Math.round(parseFloat(plans.food_description.match('Fett: (.*)g')[1]))}
                                        </div>
                                        <div>Fett</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="pl-5 my-auto">
                                  <PlusIcon
                                    width={25}
                                    height={25}
                                    className="text-accentColor cursor-pointer"
                                    onClick={() => addIngridientDirectAPI(plans.food_id)}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <TransitionContainer isShown={transitionLoaded}>
        {currentRecipeLoaded ? (
          <>
            <ShepherdTour
              steps={[
                {
                  id: 'intro',
                  attachTo: { element: '#recipeName', on: 'top' },
                  buttons: [
                    {
                      classes: 'shepherd-button-secondary',
                      text: 'Beenden',
                      type: 'cancel',
                    },
                    {
                      classes: 'shepherd-button-primary',
                      text: 'Weiter',
                      type: 'next',
                    },
                  ],
                  classes: 'custom-class-name-1 custom-class-name-2',
                  highlightClass: 'highlight',
                  scrollTo: true,
                  cancelIcon: {
                    enabled: true,
                  },
                  title: 'Rezeptnamen anpassen!',
                  text: ['<p>Hier kannst du den Namen des Rezepts anpassen.</p>'],
                  when: {
                    destroy() {
                      updateTourToFirebase(userData, 'nutritionDetail', authContext.user?.uid!, tenant);
                      setTimeout(() => {
                        document.getElementById('mainContainer')?.scrollIntoView();
                      }, 1);
                    },
                  },
                },
                {
                  id: 'intro',
                  attachTo: { element: '#recipeIngredientList', on: 'top' },
                  buttons: [
                    {
                      classes: 'shepherd-button-secondary',
                      text: 'Beenden',
                      type: 'cancel',
                    },
                    {
                      classes: 'shepherd-button-primary',
                      text: 'Weiter',
                      type: 'next',
                    },
                  ],
                  classes: 'custom-class-name-1 custom-class-name-2',
                  highlightClass: 'highlight',
                  scrollTo: true,
                  cancelIcon: {
                    enabled: true,
                  },
                  title: 'Zutaten anpassen!',
                  text: [
                    '<p>Du hast bei uns die Möglichkeit jedes auch von uns erstellte Rezept beliebig zu verändern.</p><p>Durch Klick auf das + Icon kannst du neue Lebensmittel hinzufügen.</p><p>Bei jedem Einzel-Lebensmittel hast du die Möglichkeit die Menge anzupassen oder das Lebensmittel zu löschen.</p>',
                  ],
                },
                {
                  id: 'intro',
                  attachTo: { element: '#recipeDescriptionList', on: 'top' },
                  buttons: [
                    {
                      classes: 'shepherd-button-secondary',
                      text: 'Beenden',
                      type: 'cancel',
                    },
                    {
                      classes: 'shepherd-button-primary',
                      text: 'Weiter',
                      type: 'next',
                    },
                  ],
                  classes: 'custom-class-name-1 custom-class-name-2',
                  highlightClass: 'highlight',
                  scrollTo: true,
                  cancelIcon: {
                    enabled: true,
                  },
                  title: 'Zubereitungsschritte anpassen!',
                  text: [
                    '<p>Hier kannst du die Zubereitungsschritte anpassen und durch Klick auf das + Icon auch neue Zubereitungsschritte hinzufügen.</p>',
                  ],
                },
                {
                  id: 'intro',
                  attachTo: { element: '#recipeIncompatibilities', on: 'top' },
                  buttons: [
                    {
                      classes: 'shepherd-button-secondary',
                      text: 'Beenden',
                      type: 'cancel',
                    },
                    {
                      classes: 'shepherd-button-primary',
                      text: 'Weiter',
                      type: 'next',
                    },
                  ],
                  classes: 'custom-class-name-1 custom-class-name-2',
                  highlightClass: 'highlight',
                  scrollTo: true,
                  cancelIcon: {
                    enabled: true,
                  },
                  title: 'Unverträglichkeiten und Essverhalten!',
                  text: [
                    '<p>Hier kannst du die erkannten Unverträglichkeiten prüfen und ggf. anpassen. Zusätzlich kannst du die Merkmale des Essverhaltens anpassen die zu dem Rezept passen.</p>',
                  ],
                },
                {
                  id: 'intro',
                  attachTo: { element: '#addButton', on: 'top' },
                  buttons: [
                    {
                      classes: 'shepherd-button-primary',
                      text: 'Zurück',
                      type: 'back',
                    },
                    {
                      classes: 'shepherd-button-primary',
                      text: 'Beenden',
                      type: 'next',
                    },
                  ],
                  classes: 'custom-class-name-1 custom-class-name-2',
                  highlightClass: 'highlight',
                  scrollTo: true,
                  cancelIcon: {
                    enabled: true,
                  },
                  title: 'Füge das Rezept zu einer Mahlzeit hinzu!',
                  text: [
                    '<p>Wenn du alle Anpassungen im Rezept durchgeführt hast, kannst du es durch Klick auf diesen Button zu einem bestimmten Tag und zu einer bestimmten Mahlzeit hinzufügen.</p>',
                  ],
                },
              ]}
              tourOptions={tourOptions}
            >
              <TourButton />
            </ShepherdTour>

            <ShepherdTour
              steps={[
                {
                  id: 'intro',
                  attachTo: { element: '#recipeNameMobile', on: 'top' },
                  buttons: [
                    {
                      classes: 'shepherd-button-secondary',
                      text: 'Beenden',
                      type: 'cancel',
                    },
                    {
                      classes: 'shepherd-button-primary',
                      text: 'Weiter',
                      type: 'next',
                    },
                  ],
                  classes: 'custom-class-name-1 custom-class-name-2',
                  highlightClass: 'highlight',
                  scrollTo: true,
                  cancelIcon: {
                    enabled: true,
                  },
                  title: 'Rezeptnamen anpassen!',
                  text: ['<p>Hier kannst du den Namen des Rezepts anpassen.</p>'],
                  when: {
                    destroy() {
                      updateTourToFirebase(userData, 'nutritionDetail', authContext.user?.uid!, tenant);
                      setTimeout(() => {
                        document.getElementById('mainContainer')?.scrollIntoView();
                      }, 1);
                    },
                  },
                },
                {
                  id: 'intro',
                  attachTo: { element: '#recipeIngredientListMobile', on: 'top' },
                  buttons: [
                    {
                      classes: 'shepherd-button-secondary',
                      text: 'Beenden',
                      type: 'cancel',
                    },
                    {
                      classes: 'shepherd-button-primary',
                      text: 'Weiter',
                      type: 'next',
                    },
                  ],
                  classes: 'custom-class-name-1 custom-class-name-2',
                  highlightClass: 'highlight',
                  scrollTo: true,
                  cancelIcon: {
                    enabled: true,
                  },
                  title: 'Zutaten anpassen!',
                  text: [
                    '<p>Du hast bei uns die Möglichkeit jedes auch von uns erstellte Rezept beliebig zu verändern.</p><p>Durch Klick auf das + Icon kannst du neue Lebensmittel hinzufügen.</p><p>Bei jedem Einzel-Lebensmittel hast du die Möglichkeit die Menge anzupassen oder das Lebensmittel zu löschen.</p>',
                  ],
                },
                {
                  id: 'intro',
                  attachTo: { element: '#recipeDescriptionListMobile', on: 'top' },
                  buttons: [
                    {
                      classes: 'shepherd-button-secondary',
                      text: 'Beenden',
                      type: 'cancel',
                    },
                    {
                      classes: 'shepherd-button-primary',
                      text: 'Weiter',
                      type: 'next',
                    },
                  ],
                  classes: 'custom-class-name-1 custom-class-name-2',
                  highlightClass: 'highlight',
                  scrollTo: true,
                  cancelIcon: {
                    enabled: true,
                  },
                  title: 'Zubereitungsschritte anpassen!',
                  text: [
                    '<p>Hier kannst du die Zubereitungsschritte anpassen und durch Klick auf das + Icon auch neue Zubereitungsschritte hinzufügen.</p>',
                  ],
                },
                {
                  id: 'intro',
                  attachTo: { element: '#recipeIncompatibilitiesMobile', on: 'top' },
                  buttons: [
                    {
                      classes: 'shepherd-button-secondary',
                      text: 'Beenden',
                      type: 'cancel',
                    },
                    {
                      classes: 'shepherd-button-primary',
                      text: 'Weiter',
                      type: 'next',
                    },
                  ],
                  classes: 'custom-class-name-1 custom-class-name-2',
                  highlightClass: 'highlight',
                  scrollTo: true,
                  cancelIcon: {
                    enabled: true,
                  },
                  title: 'Unverträglichkeiten und Essverhalten!',
                  text: [
                    '<p>Hier kannst du die erkannten Unverträglichkeiten prüfen und ggf. anpassen. Zusätzlich kannst du die Merkmale des Essverhaltens anpassen die zu dem Rezept passen.</p>',
                  ],
                },
                {
                  id: 'intro',
                  attachTo: { element: '#addButtonMobile', on: 'top' },
                  buttons: [
                    {
                      classes: 'shepherd-button-primary',
                      text: 'Zurück',
                      type: 'back',
                    },
                    {
                      classes: 'shepherd-button-primary',
                      text: 'Beenden',
                      type: 'next',
                    },
                  ],
                  classes: 'custom-class-name-1 custom-class-name-2',
                  highlightClass: 'highlight',
                  scrollTo: true,
                  cancelIcon: {
                    enabled: true,
                  },
                  title: 'Füge das Rezept zu einer Mahlzeit hinzu!',
                  text: [
                    '<p>Wenn du alle Anpassungen im Rezept durchgeführt hast, kannst du es durch Klick auf diesen Button zu einem bestimmten Tag und zu einer bestimmten Mahlzeit hinzufügen.</p>',
                  ],
                },
              ]}
              tourOptions={tourOptions}
            >
              <TourButtonMobile />
            </ShepherdTour>

            <div className={styles.pageWrapper} ref={scrollContainerRef}>
              {typeof location.state !== undefined &&
              location.state !== null &&
              (location.state?.from === 'planEdit' ||
                location.state?.from === 'planEditChangeRecipe' ||
                location.state?.isDashBoard) ? (
                <>
                  <div className="bg-lightGray pb-40">
                    <div className="z-10 w-32 pt-50 large:pt-4 pl-4">
                      <div className={styles.backBtnBg}>
                        {location.state.from === 'planEdit' && !location.state?.isDashBoard ? (
                          <Link
                            to={{
                              pathname: `/plans/edit/${location.state?.planId}`,
                              state: {
                                from: 'nutrition-detail',
                                type: location.state.type,
                                id: location.state.id,
                                mealLabel: location.state.mealLabel,
                                scrollPos: location.state.scrollPos,
                                recipeUid:
                                  location?.state?.recipeUid !== undefined ? location?.state?.recipeUid : undefined,
                                searchQuery:
                                  location?.state?.searchQuery !== undefined ? location?.state?.searchQuery : undefined,
                              },
                            }}
                          >
                            {' '}
                            <ButtonBack
                              text={t('Return')}
                              className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                            />
                          </Link>
                        ) : location.state?.from === 'dashboard' || location.state?.isDashBoard ? (
                          <Link
                            to={{
                              pathname: '/',
                              state: {
                                from: 'nutrition-detail',
                                type: location.state.type,
                                id: location.state.id,
                                mealLabel:
                                  location.state.label !== undefined
                                    ? location.state.label
                                    : location.state.mealLabel !== undefined
                                    ? location.state.mealLabel
                                    : undefined,

                                planDayId: location.state.planDayId,
                                uid: location.state.uid,
                                isBackLink: true,
                                scrollPos: location.state.scrollPos,
                                recipeUid:
                                  location?.state?.recipeUid !== undefined ? location?.state?.recipeUid : undefined,
                                isCurrentRecipeDashboard: location.state?.isCurrentRecipeDashboard,
                                searchQuery:
                                  location?.state?.searchQuery !== undefined ? location?.state?.searchQuery : undefined,
                              },
                            }}
                          >
                            {' '}
                            <ButtonBack
                              text={t('Return')}
                              className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                            />
                          </Link>
                        ) : (
                          <Link
                            to={{
                              pathname: `/plans/edit/${location.state?.planId}`,
                              state: {
                                from: 'nutrition-detail',
                                type: location.state.type,
                                id: location.state.id,
                                mealLabel: location.state.mealLabel,
                                planDayId: location.state.planDayId,
                                scrollPos: location.state.scrollPos,
                                recipeUid:
                                  location?.state?.recipeUid !== undefined ? location?.state?.recipeUid : undefined,
                                searchQuery:
                                  location?.state?.searchQuery !== undefined ? location?.state?.searchQuery : undefined,
                              },
                            }}
                          >
                            {' '}
                            <ButtonBack
                              text={t('Return')}
                              className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                    {location.state?.from !== 'dashboard' && (
                      <div className="font-extralight pt-20 pb-30 px-20 text-14">
                        {location.state?.from === 'planEdit' ? (
                          <div>
                            Wenn du dieses Rezept hinzufügst würden sich die Werte in deinem aktuellen Plan
                            folgendermaßen verändern:
                          </div>
                        ) : (
                          <div>
                            Wenn du dieses Rezept änderst würden sich die Werte in deinem aktuellen Plan folgendermaßen
                            verändern:
                          </div>
                        )}
                      </div>
                    )}
                    {currentAddRecipe?.kcal_total !== undefined &&
                      location.state.planKcalTotal !== undefined &&
                      (location.state?.from === 'planEdit' ? (
                        <>
                          <MakroCircles
                            kcal_value={
                              parseInt(location.state.planKcalTotal.toString(), 10) +
                              parseInt(currentAddRecipe?.kcal_total.toString(), 10)
                            }
                            carbohydrates_value={
                              parseInt(location.state.planCarbohydratesTotal.toString(), 10) +
                              parseInt(currentAddRecipe?.carbohydrates_total.toString(), 10)
                            }
                            protein_value={
                              parseInt(location.state.planProteinTotal.toString(), 10) +
                              parseInt(currentAddRecipe?.protein_total.toString(), 10)
                            }
                            fat_value={
                              parseInt(location.state.planFatTotal.toString(), 10) +
                              parseInt(currentAddRecipe?.fat_total.toString(), 10)
                            }
                            custom_kcal_value={location.state.planCustomKcalTotal}
                            custom_carbohydrates_value={location.state.planCustomCarbohydratesTotal}
                            custom_protein_value={location.state.planCustomProteinTotal}
                            custom_fat_value={location.state.planCustomFatTotal}
                          />
                        </>
                      ) : (
                        <>
                          <MakroCircles
                            kcal_value={
                              parseInt(location.state.planKcalTotal.toString(), 10) -
                              parseInt(location.state.planOriginalKcalTotal.toString(), 10) +
                              parseInt(currentAddRecipe?.kcal_total.toString(), 10)
                            }
                            carbohydrates_value={
                              parseInt(location.state.planCarbohydratesTotal.toString(), 10) -
                              parseInt(location.state.planOriginalCarbohydratesTotal.toString(), 10) +
                              parseInt(currentAddRecipe?.carbohydrates_total.toString(), 10)
                            }
                            protein_value={
                              parseInt(location.state.planProteinTotal.toString(), 10) -
                              parseInt(location.state.planOriginalProteinTotal.toString(), 10) +
                              parseInt(currentAddRecipe?.protein_total.toString(), 10)
                            }
                            fat_value={
                              parseInt(location.state.planFatTotal.toString(), 10) -
                              parseInt(location.state.planOriginalFatTotal.toString(), 10) +
                              parseInt(currentAddRecipe?.fat_total.toString(), 10)
                            }
                            custom_kcal_value={location.state.planCustomKcalTotal}
                            custom_carbohydrates_value={location.state.planCustomCarbohydratesTotal}
                            custom_protein_value={location.state.planCustomProteinTotal}
                            custom_fat_value={location.state.planCustomFatTotal}
                          />
                        </>
                      ))}
                  </div>
                </>
              ) : (
                <div
                  className={styles.recipeImageContainer}
                  style={{
                    backgroundImage: `url(${
                      currentAddRecipe?.imageUrl !== ''
                        ? currentAddRecipe?.imageUrl
                        : theme?.logo !== undefined && userData?.role !== 2
                        ? theme.logo
                        : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
                    })`,
                  }}
                >
                  <div className="flex justify-between">
                    <div className="z-10 w-32 pt-50 large:pt-4 pl-4">
                      <div className={styles.backBtnBg}>
                        {typeof location.state !== undefined &&
                        location.state !== null &&
                        location.state?.from === 'plans' ? (
                          <Link
                            to={{
                              pathname: `/plans/${location.state?.uid}`,
                              state: {
                                from: 'nutrition-detail',
                                type: location.state.type,
                                id: location.state.id,
                                mealLabel: location.state.label,
                              },
                            }}
                          >
                            <ButtonBack
                              text={t('Return')}
                              className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                            />
                          </Link>
                        ) : location.state?.from === 'dashboard' || location.state?.isDashBoard ? (
                          <Link
                            to={{
                              pathname: '/',
                              state: {
                                from: 'nutrition-detail',
                                type: location.state.type,
                                id: location.state.id,
                                mealLabel:
                                  location.state.label !== undefined
                                    ? location.state.label
                                    : location.state.mealLabel !== undefined
                                    ? location.state.mealLabel
                                    : undefined,
                                planDayId: location.state.planDayId,
                                uid: location.state.uid,
                                isBackLink: true,
                                scrollPos: location.state.scrollPos,
                                recipeUid:
                                  location?.state?.recipeUid !== undefined ? location?.state?.recipeUid : undefined,
                                isCurrentRecipeDashboard: location.state?.isCurrentRecipeDashboard,
                                searchQuery:
                                  location?.state?.searchQuery !== undefined ? location?.state?.searchQuery : undefined,
                              },
                            }}
                          >
                            {' '}
                            <ButtonBack
                              text={t('Return')}
                              className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                            />
                          </Link>
                        ) : typeof location.state !== undefined &&
                          location.state !== null &&
                          (location.state?.from === 'dashboard' || location.state?.isDashBoard) ? (
                          <Link
                            to={{
                              pathname: '/',
                              state: {
                                from: 'nutrition-detail',
                                type: location.state.type,
                                id: location.state.id,
                                mealLabel:
                                  location.state.label !== undefined
                                    ? location.state.label
                                    : location.state.mealLabel !== undefined
                                    ? location.state.mealLabel
                                    : undefined,
                                planDayId: location.state.planDayId,
                                uid: location.state.uid,
                                isBackLink: true,
                                scrollPos: location.state.scrollPos,
                                recipeUid:
                                  location?.state?.recipeUid !== undefined ? location?.state?.recipeUid : undefined,
                                isCurrentRecipeDashboard: location.state?.isCurrentRecipeDashboard,
                                searchQuery:
                                  location?.state?.searchQuery !== undefined ? location?.state?.searchQuery : undefined,
                              },
                            }}
                          >
                            <ButtonBack
                              text={t('Return')}
                              className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                            />
                          </Link>
                        ) : (
                          <Link
                            to={{
                              pathname: '/nutrition',
                              state: {
                                scrollPos: currentScrollPosition,
                                recipeUid:
                                  location?.state?.recipeUid !== undefined ? location?.state?.recipeUid : undefined,
                              },
                            }}
                          >
                            <ButtonBack
                              text={t('Return')}
                              className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                    {typeof location.state !== undefined &&
                      location.state !== null &&
                      location.state?.from !== 'plans' && (
                        <div className="bg-lightGray rounded-full p-3 my-auto mt-50 large:pt-15 mr-15 z-10 cursor-pointer">
                          <PencilIcon
                            width={17}
                            height={17}
                            className="text-textColor"
                            onClick={() => handleOpenClosePopups('', setEditPictureOverlayClass, 'open')}
                          />
                        </div>
                      )}
                  </div>
                </div>
              )}

              <div className={styles.recipeWrapper}>
                <div className="hidden large:block">
                  <img
                    className="h-full object-cover block"
                    src={
                      currentAddRecipe?.imageUrl !== ''
                        ? currentAddRecipe?.imageUrl
                        : theme?.logo !== undefined && userData?.role !== 2
                        ? theme.logo
                        : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
                    }
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className={styles.recipeContent}>
                  {id !== 'shared' && (
                    <div className="pt-4 flex flex-wrap justify-center gap-8 gap-y-2">
                      <div
                        title="Kochmodus"
                        onClick={() => startCookingMode()}
                        onKeyDown={() => startCookingMode()}
                        aria-hidden="true"
                      >
                        <Icon
                          name="cooking"
                          width={28}
                          height={28}
                          className="text-accentColor cursor-pointer mx-auto"
                        />
                        <div className="font-extralight text-14 pt-1">Kochmodus</div>
                      </div>
                      {/* typeof location.state !== undefined &&
                      location.state !== null &&
                      location.state?.from !== 'plans' && (
                        <div title="Originalrezept wiederherstellen">
                          <RefreshIcon
                            width={28}
                            height={28}
                            className="text-accentColor cursor-pointer"
                            onClick={() => regenerateRecipe()}
                          />
                        </div>
                      ) */}
                      <div
                        title="Favoriten"
                        onClick={() => saveRecipeToFavorites()}
                        onKeyDown={() => saveRecipeToFavorites()}
                        aria-hidden="true"
                      >
                        <HeartIcon
                          width={28}
                          height={28}
                          className="text-accentColor mx-auto"
                          fill={(recipeFavorite && '#C97132') || 'none'}
                        />
                        <div className="font-extralight text-14 pt-1">Zu Favoriten</div>
                      </div>
                      <div
                        title="Teilen"
                        onClick={() => openSharePopup()}
                        onKeyDown={() => openSharePopup()}
                        aria-hidden="true"
                      >
                        <ShareIcon width={28} height={28} className="text-accentColor mx-auto" />
                        <div className="font-extralight text-14 pt-1">Teilen</div>
                      </div>
                      {userData?.role === 1 ||
                      currentAddRecipe?.userRecipe ||
                      ((userData?.isAdminUser || userData?.isTrainerUser || userData?.isLocationAdminUser) &&
                        currentAddRecipe?.communityRecipe) ? (
                        <>
                          <div
                            title="Löschen"
                            onClick={() => setDeleteRecipeOverlayClass('block')}
                            onKeyDown={() => setDeleteRecipeOverlayClass('block')}
                            aria-hidden="true"
                          >
                            <TrashIcon width={28} height={28} className="text-accentColor cursor-pointer mx-auto" />
                            <div className="font-extralight text-14 pt-1">Löschen</div>
                          </div>
                        </>
                      ) : (
                        <> </>
                      )}
                      {currentRecipePrice > 0 && (
                        <div title="Preis">
                          <CurrencyEuroIcon width={28} height={28} className="text-accentColor mx-auto" />
                          <div className="font-extralight text-14 pt-1 text-center">
                            {currentRecipePrice.toString().replaceAll('.', ',')} €
                          </div>
                          {currentOriginalRecipe !== undefined && (
                            <div className="font-extralight text-10">
                              Portion ({parseFloat(currentOriginalRecipe?.portion_g)}g)
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="mx-20 mt-20 z-10 text-left text-xl font-semibold" id="recipeNameMobile">
                    {typeof location.state !== undefined &&
                    location.state !== null &&
                    location.state?.from === 'plans' ? (
                      <div className="text-textColor leading-8 px-5 rounded-xl">{currentAddRecipe?.name}</div>
                    ) : (
                      <div
                        onInput={updateRecipeName}
                        className="text-textColor leading-8 border border-opacity-30 border-textColor p-5 rounded-xl"
                        contentEditable
                        onFocus={setBodyClassForMobileSafari}
                        onBlur={removeBodyClassForMobileSafari}
                        suppressContentEditableWarning
                      >
                        {currentAddRecipe?.name}
                      </div>
                    )}
                  </div>

                  {currentAddRecipe?.userRecipe && (
                    <div className="flex ml-20 pt-15">
                      <div className="rounded-3xl bg-accentColor py-5 px-15 font-bold text-14 text-buttonTextColor">
                        Eigenes Rezept
                      </div>
                    </div>
                  )}
                  {currentAddRecipe?.communityRecipe && (
                    <div className="flex ml-20 pt-15">
                      <div className="rounded-3xl bg-accentColor py-5 px-15 font-bold text-14 text-buttonTextColor">
                        Community Rezept
                      </div>
                    </div>
                  )}

                  <div className="pt-4 px-6">
                    <div className="flex w-full gap-4 sticky py-4 top-0 pt-50 large:pt-0 z-10 bg-bgColor">
                      <div className="flex-1 text-center">
                        <div className="font-semibold text-base">
                          {Math.round(parseFloat(currentAddRecipe?.kcal_total!))}
                        </div>
                        <div className={styles.recipeLabel}>kcal</div>
                      </div>
                      <div className="flex-1 text-center">
                        <div className="font-semibold text-base">
                          {Math.round(parseFloat(currentAddRecipe?.carbohydrates_total!))}g
                        </div>
                        <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
                      </div>
                      <div className="flex-1 text-center">
                        <div className="font-semibold text-base">
                          {Math.round(parseFloat(currentAddRecipe?.protein_total!))}g
                        </div>
                        <div className={styles.recipeLabel}>{t('Protein')}</div>
                      </div>
                      <div className="flex-1 text-center">
                        <div className="font-semibold text-base">
                          {Math.round(parseFloat(currentAddRecipe?.fat_total!))}g
                        </div>
                        <div className={styles.recipeLabel}>{t('Fat')}</div>
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-10 pt-20">
                      {typeof location.state !== undefined &&
                      location.state !== null &&
                      location.state?.from === 'plans' ? (
                        <HashTagBadge
                          planObject={currentAddRecipe}
                          setPlanObject={setCurrentAddRecipe}
                          setPlanChanged={setRecipeChanged}
                        />
                      ) : (
                        <>
                          <HashTagBadge
                            planObject={currentAddRecipe}
                            onlyNutritionForm
                            edit={userData?.role === 1}
                            setPlanObject={setCurrentAddRecipe}
                            setPlanChanged={setRecipeChanged}
                          />
                        </>
                      )}
                    </div>
                    <div className="hidden">
                      <div className={styles.actionBtn}>
                        <div>
                          <PlusIcon width={36} height={36} className="text-accentColor mx-auto cursor-pointer" />
                        </div>
                        <div className="text-center pt-4">{t('Add')}</div>
                      </div>
                      <div className={styles.actionBtn}>
                        <div>
                          <SaveIcon width={36} height={36} className="text-accentColor mx-auto" />
                        </div>
                        <div className="text-center pt-4">{t('SaveAsNewRecipe')}</div>
                      </div>
                    </div>
                    {typeof location.state !== undefined &&
                    location.state !== null &&
                    location.state?.from === 'plans' ? (
                      <div className="flex gap-20 justify-between">
                        <div>
                          <div className="flex justify-between pt-40">
                            <div className="font-extralight text-15">{t('PortionSize')}</div>
                          </div>

                          <div className="pt-2">
                            <div className="font-semibold text-15">{getItemLabel()}</div>
                          </div>
                        </div>
                        <div>
                          <div className="flex justify-between pt-40">
                            <div className="font-extralight text-15">{t('Level of difficulty')}</div>
                          </div>
                          <div className="pt-2">
                            <div className="font-semibold text-15">
                              {currentAddRecipe?.name !== undefined &&
                                difficultyValues.filter(
                                  (st: any) => parseFloat(st.value as string) === currentAddRecipe?.difficulty_level
                                )[0].label}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="flex justify-between pt-40">
                            <div className="font-extralight text-15">{t('Category')}</div>
                          </div>
                          <div className="pt-2">
                            <div className="font-semibold text-15">
                              {currentAddRecipe?.name !== undefined &&
                                recipeCategoryValues.filter((st: any) => st.label === currentAddRecipe?.category)[0]
                                  .label}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="pb-20">
                          <div className="flex justify-between pt-30">
                            <div className="font-light text-2xl">{t('PortionSize')}</div>
                          </div>
                          <div className="flex gap-4 pt-4">
                            <div>
                              <CustomUserInput
                                submitFunction={() =>
                                  typeof location.state !== undefined &&
                                  location.state !== null &&
                                  (location.state?.from === 'planEditChangeRecipe' ||
                                    location.state?.from === 'dashboard')
                                    ? changeRecipeInPlan()
                                    : typeof location.state !== undefined &&
                                      location.state !== null &&
                                      location.state?.from === 'planEdit'
                                    ? addRecipeToPlan()
                                    : handleOpenClosePopups('', setAddToPlanOverlayClass, 'open')
                                }
                                thisValue={
                                  currentRecipeAmountTyped === ''
                                    ? ''
                                    : currentAddRecipe?.recipe_portion !== undefined
                                    ? currentAddRecipe?.recipe_portion
                                    : currentRecipeAmount
                                }
                                thisRef={editRecipePortionSizeMobileRef}
                                name="changeRecipeAmount"
                                onChange={e =>
                                  changeRecipeAmount(e.target.value.trim(), currentOriginalRecipe, recipePiece)
                                }
                                onClick={() => clickRecipePortionSize('mobile')}
                                width="4"
                                customPaddingY="2"
                              />
                            </div>
                            <div className="w-full">
                              {currentAddRecipe?.portion_g !== undefined && (
                                <CustomSelect
                                  name="portionValues"
                                  dropDownOption={currentRecipePortionValues}
                                  value={mapRecipePiece()}
                                  onChange={e => changeRecipePiece(e)}
                                  isFullSize
                                />
                              )}
                            </div>

                            {/* currentOriginalRecipe?.portion_g !== undefined && (
                              <div className="my-auto">
                                <div className="font-semibold text-15">{`(${
                                  parseFloat(currentRecipeAmount) * parseFloat(currentOriginalRecipe?.portion_g)
                                }g)`}</div>
                              </div>
                              ) */}
                          </div>
                        </div>
                        {userData?.role === 1 && (
                          <div>
                            <div className="font-light text-base pt-40">
                              <SwitchButton
                                notContainer
                                label="alghorithmus"
                                enabled={useAlghorithmus}
                                onChange={changeAlghorithmus}
                              />
                            </div>
                          </div>
                        )}
                        <div className="pt-4 flex justify-between">
                          <div className="pt-2 pr-15 font-light text-base">{t('Level of difficulty')}</div>
                          <div className="flex-1">
                            {currentAddRecipe?.difficulty_level !== undefined && (
                              <CustomSelect
                                name="difficultyLevel"
                                dropDownOption={difficultyValues}
                                defaultValue={difficultyValues.filter(
                                  (st: any) => parseFloat(st.value) === currentAddRecipe?.difficulty_level
                                )}
                                onChange={updateDifficultyLevel}
                                isFullSize
                              />
                            )}
                          </div>
                        </div>
                        <div className="pt-4 flex justify-between">
                          <div className="pt-2 pr-15 font-light text-base">{t('Category')}</div>
                          <div className="flex-1">
                            {currentAddRecipe?.category !== undefined && (
                              <CustomSelect
                                name="category"
                                dropDownOption={recipeCategoryValues}
                                defaultValue={recipeCategoryValues.filter(
                                  (st: any) => st.label === currentAddRecipe?.category
                                )}
                                onChange={updateCategory}
                                isFullSize
                              />
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    <div id="recipeIngredientListMobile">
                      <div className="flex justify-between pt-40">
                        <div className="font-light text-2xl">{t('IngredientsList')}</div>
                        {typeof location.state !== undefined &&
                          location.state !== null &&
                          location.state?.from !== 'plans' &&
                          currentRecipeAmount === '1' &&
                          recipePiece === 'Portion' && (
                            <div className="my-auto">
                              <div
                                className="flex"
                                onClick={() => handleOpenSearchPopup()}
                                onKeyDown={() => handleOpenSearchPopup()}
                                aria-hidden="true"
                              >
                                <div className="text-sm my-auto font-light">Hinzufügen</div>
                                <div className="my-auto pl-5">
                                  <PlusIcon width={25} height={25} className="text-accentColor cursor-pointer" />
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                      {(currentRecipeAmount !== '1' || recipePiece === 'g') && (
                        <div className="font-extralight text-12">
                          Hinweis: Du hast die Portionsgröße geändert und kannst daher die Zutatenliste nicht mehr
                          anpassen!
                        </div>
                      )}
                      <div className={styles.ingridientContainer}>
                        {currentAddRecipe?.ingredients.map(ingridient => (
                          <>
                            <IngridientItem
                              ingridientData={ingridient}
                              recipeData={currentAddRecipe}
                              updateFunction={updateRecipeState}
                              setRecipeChanged={setRecipeChanged}
                              setDirectAddedIngridients={setDirectAddedIngridients}
                              directAddedIngridients={directAddedIngridients}
                              defaultValue={ingridient.amount}
                            />
                          </>
                        ))}

                        <div className="pt-5 pb-20">
                          {currentRecipeLoaded && (
                            <>
                              <div
                                className="cursor-pointer"
                                onClick={() => orderRewe()}
                                onKeyDown={() => orderRewe()}
                                aria-hidden="true"
                              >
                                <Icon name="rewe" width={196} />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="hidden gap-10 font-extralight">
                      <div className="text-12">Quelle Lebensmittel-Datenbank:</div>
                      <a
                        target="_blank"
                        href="https://www.fatsecret.com/calories-nutrition/"
                        rel="noreferrer"
                        className="my-auto underline"
                      >
                        <div className="text-12 flex gap-5">
                          <div>fatsecret.com</div>
                          <div className="cursor-pointer">
                            <div className="my-auto">
                              <ExternalLinkIcon className="text-accentColor" height={15} width={15} />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>

                    <div id="recipeDescriptionListMobile">
                      <div className="flex justify-between pt-40 mb-14">
                        <div className="font-light text-2xl">{t('Preparation')}</div>
                        {typeof location.state !== undefined &&
                          location.state !== null &&
                          location.state?.from !== 'plans' && (
                            <div className="my-auto">
                              <div
                                className="flex"
                                onClick={() =>
                                  addDescriptionStep(
                                    currentAddRecipe,
                                    updateRecipeState,
                                    setRecipeChanged,
                                    'mobile',
                                    recipeDescriptionArray,
                                    setRecipeDescriptionArray,
                                    setDescriptionStepAdded
                                  )
                                }
                                onKeyDown={() =>
                                  addDescriptionStep(
                                    currentAddRecipe,
                                    updateRecipeState,
                                    setRecipeChanged,
                                    'mobile',
                                    recipeDescriptionArray,
                                    setRecipeDescriptionArray,
                                    setDescriptionStepAdded
                                  )
                                }
                                aria-hidden="true"
                              >
                                <div className="text-sm my-auto font-light">Neuer Schritt</div>
                                <div className="my-auto pl-5">
                                  <PlusIcon width={25} height={25} className="text-accentColor cursor-pointer" />
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                      {currentAddRecipe?.description.map(desc => (
                        <>
                          <DescriptionItem
                            descriptionData={desc}
                            recipeData={currentAddRecipe}
                            updateFunction={updateRecipeState}
                            setRecipeChanged={setRecipeChanged}
                            recipeDescriptionArray={setRecipeDescriptionArray}
                            recipeDescriptionArrayValue={recipeDescriptionArray}
                            newDescriptionStepRef={newDescriptionStepRef}
                            recipeChanged={recipeChanged}
                            setDescriptionStepAdded={setDescriptionStepAdded}
                            descriptionStepAdded={descriptionStepAdded}
                          />
                        </>
                      ))}
                    </div>
                    {typeof location.state !== undefined &&
                      location.state !== null &&
                      location.state?.from !== 'plans' && (
                        <div id="recipeIncompatibilitiesMobile">
                          <div>
                            <div
                              className="flex pt-20 mb-14 cursor-pointer"
                              id="descriptionJumpContainerDesktop1"
                              onClick={() => toggleIncompatibilities()}
                              onKeyDown={() => toggleIncompatibilities()}
                              aria-hidden="true"
                            >
                              <div className="font-light text-2xl">{t('Intolerances')}</div>
                              <div className="pl-5 my-auto">
                                <ChevronDownIcon className={incompatibilitiesChevronClass} height={30} width={30} />
                              </div>
                            </div>
                            <div className={incompatibilitiesClass}>
                              <div className="font-extralight text-15 pb-20 pr-20">
                                <p>Folgende Unverträglichkeiten haben wir bei diesem Rezept erkannt (ohne Gewähr). </p>
                                <p>Bitte prüfe das nochmal und passe es bei Bedarf an.</p>
                              </div>
                              {intolerancesOption.map((item: string, index: number) => (
                                <>
                                  <SwitchButton
                                    key={index}
                                    label={item}
                                    enabled={
                                      currentAddRecipe?.name !== undefined &&
                                      typeof currentAddRecipe[item.toLowerCase()] !== undefined
                                        ? Boolean(currentAddRecipe[item.toLowerCase()])
                                        : false
                                    }
                                    isBackground={index % 2 === 0}
                                    onChange={setIncompatibilities}
                                  />
                                </>
                              ))}
                            </div>
                          </div>
                          <div className="mb-40">
                            <div
                              className="flex pt-20 mb-14 cursor-pointer"
                              onClick={() => toggleEatingHabits()}
                              onKeyDown={() => toggleEatingHabits()}
                              aria-hidden="true"
                            >
                              <div className="font-light text-2xl">{t('Eating habits')}</div>
                              <div className="pl-5 my-auto">
                                <ChevronDownIcon className={eatingHabitsChevronClass} height={30} width={30} />
                              </div>
                            </div>
                            <div className={eatingHabitsClass}>
                              <div className="font-extralight text-15 pb-20 pr-20">
                                <p>Folgendes Essverhalten passt zu diesem Rezept. </p>
                                <p>Du kannst die Werte bei Bedarf anpassen.</p>
                              </div>
                              {eatingHabitsOption.map((item: string, index: number) => (
                                <>
                                  <SwitchButton
                                    key={index}
                                    label={item}
                                    enabled={
                                      currentAddRecipe?.name !== undefined &&
                                      typeof currentAddRecipe[item.toLowerCase()] !== undefined
                                        ? Boolean(currentAddRecipe[item.toLowerCase()])
                                        : false
                                    }
                                    isBackground={index % 2 === 0}
                                    onChange={setEatingHabits}
                                  />
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.pageWrapperDesktop}>
              <div className="w-1/2 relative overflow-auto" ref={leftColumnRef}>
                <div className="w-32 pt-2">
                  <div className={styles.backBtnBg}>
                    {typeof location.state !== undefined &&
                    location.state !== null &&
                    location.state?.from === 'plans' ? (
                      <Link
                        to={{
                          pathname: `/plans/${location.state?.uid}`,
                          state: {
                            from: 'nutrition-detail',
                            type: location.state.type,
                            id: location.state.id,
                            mealLabel: location.state.label,
                          },
                        }}
                      >
                        <ButtonBack
                          text={t('Return')}
                          className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                        />
                      </Link>
                    ) : typeof location.state !== undefined &&
                      location.state !== null &&
                      location.state?.from === 'planEdit' &&
                      !location.state.isDashBoard ? (
                      <Link
                        to={{
                          pathname: `/plans/edit/${location.state?.planId}`,
                          state: {
                            from: 'nutrition-detail',
                            type: location.state.type,
                            id: location.state.id,
                            mealLabel: location.state.mealLabel,
                            planDayId: location.state.planDayId,
                            scrollPos: location.state.scrollPos,
                            recipeUid:
                              location?.state?.recipeUid !== undefined ? location?.state?.recipeUid : undefined,

                            searchQuery:
                              location?.state?.searchQuery !== undefined ? location?.state?.searchQuery : undefined,
                          },
                        }}
                      >
                        <ButtonBack
                          text={t('Return')}
                          className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                        />
                      </Link>
                    ) : typeof location.state !== undefined &&
                      location.state !== null &&
                      location.state?.from === 'planEditChangeRecipe' ? (
                      <Link
                        to={{
                          pathname: `/plans/edit/${location.state?.planId}`,
                          state: {
                            from: 'nutrition-detail',
                            type: location.state.type,
                            id: location.state.id,
                            mealLabel: location.state.mealLabel,
                            planDayId: location.state.planDayId,
                            scrollPos: location.state.scrollPos,
                            recipeUid:
                              location?.state?.recipeUid !== undefined ? location?.state?.recipeUid : undefined,

                            searchQuery:
                              location?.state?.searchQuery !== undefined ? location?.state?.searchQuery : undefined,
                          },
                        }}
                      >
                        <ButtonBack
                          text={t('Return')}
                          className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                        />
                      </Link>
                    ) : typeof location.state !== undefined &&
                      location.state !== null &&
                      (location.state?.from === 'dashboard' || location.state?.isDashBoard) ? (
                      <Link
                        to={{
                          pathname: '/',
                          state: {
                            from: 'nutrition-detail',
                            type: location.state.type,
                            id: location.state.id,
                            mealLabel:
                              location.state.label !== undefined
                                ? location.state.label
                                : location.state.mealLabel !== undefined
                                ? location.state.mealLabel
                                : undefined,
                            planDayId: location.state.planDayId,
                            uid: location.state.uid,
                            isBackLink: true,
                            scrollPos: location.state.scrollPos,
                            recipeUid:
                              location?.state?.recipeUid !== undefined ? location?.state?.recipeUid : undefined,
                            searchQuery:
                              location?.state?.searchQuery !== undefined ? location?.state?.searchQuery : undefined,
                            isCurrentRecipeDashboard: location.state?.isCurrentRecipeDashboard,
                          },
                        }}
                      >
                        <ButtonBack
                          text={t('Return')}
                          className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                        />
                      </Link>
                    ) : (
                      <Link
                        to={{
                          pathname: '/nutrition',
                          state: {
                            scrollPos: currentScrollPosition,
                            recipeUid:
                              location?.state?.recipeUid !== undefined ? location?.state?.recipeUid : undefined,
                          },
                        }}
                      >
                        <ButtonBack
                          text={t('Return')}
                          className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                        />
                      </Link>
                    )}
                  </div>
                </div>
                <div className="flex text-2xl font-semibold pt-20">
                  <div className="z-10">
                    {typeof location.state !== undefined &&
                    location.state !== null &&
                    location.state?.from === 'plans' ? (
                      <div className="text-textColor leading-8">{currentAddRecipe?.name}</div>
                    ) : (
                      <>
                        <div
                          onInput={updateRecipeName}
                          className="text-textColor leading-8 border border-opacity-30 border-textColor p-10 rounded-xl"
                          contentEditable
                          onFocus={setBodyClassForMobileSafari}
                          onBlur={removeBodyClassForMobileSafari}
                          suppressContentEditableWarning
                          id="recipeName"
                        >
                          {currentAddRecipe?.name}
                        </div>
                      </>
                    )}
                  </div>
                  {typeof location.state !== undefined && location.state !== null && location.state?.from !== 'plans' && (
                    <div className="my-auto pl-10">
                      <PencilIcon width={30} height={20} className="text-accentColor" />
                    </div>
                  )}
                </div>
                {currentAddRecipe?.userRecipe && (
                  <div className="flex pt-15">
                    <div className="rounded-3xl bg-accentColor py-5 px-15 font-bold text-14 text-buttonTextColor">
                      Eigenes Rezept
                    </div>
                  </div>
                )}
                {currentAddRecipe?.communityRecipe && (
                  <div className="flex pt-15">
                    <div className="rounded-3xl bg-accentColor py-5 px-15 font-bold text-14 text-buttonTextColor">
                      Community Rezept
                    </div>
                  </div>
                )}
                <div className="relative">
                  {typeof location.state !== undefined &&
                  location.state !== null &&
                  (location.state?.from === 'planEdit' || location.state?.from === 'planEditChangeRecipe') ? (
                    <div className="rounded-3xl bg-lightGray mt-20 pb-40">
                      <div className="font-extralight pt-20 pb-30 px-20 text-14">
                        {location.state?.from === 'planEdit' ? (
                          <div>
                            Wenn du dieses Rezept hinzufügst würden sich die Werte in deinem aktuellen Plan
                            folgendermaßen verändern:
                          </div>
                        ) : (
                          <div>
                            Wenn du dieses Rezept änderst würden sich die Werte in deinem aktuellen Plan folgendermaßen
                            verändern:
                          </div>
                        )}
                      </div>
                      {currentAddRecipe?.kcal_total !== undefined &&
                        location.state.planKcalTotal !== undefined &&
                        (location.state?.from === 'planEdit' ? (
                          <>
                            <MakroCircles
                              kcal_value={
                                parseInt(location.state.planKcalTotal.toString(), 10) +
                                parseInt(currentAddRecipe?.kcal_total.toString(), 10)
                              }
                              carbohydrates_value={
                                parseInt(location.state.planCarbohydratesTotal.toString(), 10) +
                                parseInt(currentAddRecipe?.carbohydrates_total.toString(), 10)
                              }
                              protein_value={
                                parseInt(location.state.planProteinTotal.toString(), 10) +
                                parseInt(currentAddRecipe?.protein_total.toString(), 10)
                              }
                              fat_value={
                                parseInt(location.state.planFatTotal.toString(), 10) +
                                parseInt(currentAddRecipe?.fat_total.toString(), 10)
                              }
                              custom_kcal_value={location.state.planCustomKcalTotal}
                              custom_carbohydrates_value={location.state.planCustomCarbohydratesTotal}
                              custom_protein_value={location.state.planCustomProteinTotal}
                              custom_fat_value={location.state.planCustomFatTotal}
                            />
                          </>
                        ) : (
                          <>
                            <MakroCircles
                              kcal_value={
                                parseInt(location.state.planKcalTotal.toString(), 10) -
                                parseInt(location.state.planOriginalKcalTotal.toString(), 10) +
                                parseInt(currentAddRecipe?.kcal_total.toString(), 10)
                              }
                              carbohydrates_value={
                                parseInt(location.state.planCarbohydratesTotal.toString(), 10) -
                                parseInt(location.state.planOriginalCarbohydratesTotal.toString(), 10) +
                                parseInt(currentAddRecipe?.carbohydrates_total.toString(), 10)
                              }
                              protein_value={
                                parseInt(location.state.planProteinTotal.toString(), 10) -
                                parseInt(location.state.planOriginalProteinTotal.toString(), 10) +
                                parseInt(currentAddRecipe?.protein_total.toString(), 10)
                              }
                              fat_value={
                                parseInt(location.state.planFatTotal.toString(), 10) -
                                parseInt(location.state.planOriginalFatTotal.toString(), 10) +
                                parseInt(currentAddRecipe?.fat_total.toString(), 10)
                              }
                              custom_kcal_value={location.state.planCustomKcalTotal}
                              custom_carbohydrates_value={location.state.planCustomCarbohydratesTotal}
                              custom_protein_value={location.state.planCustomProteinTotal}
                              custom_fat_value={location.state.planCustomFatTotal}
                            />
                          </>
                        ))}
                    </div>
                  ) : (
                    <img
                      className="object-cover rounded-3xl w-full mt-30"
                      src={
                        currentAddRecipe?.imageUrl !== ''
                          ? currentAddRecipe?.imageUrl
                          : theme?.logo !== undefined && userData?.role !== 2
                          ? theme.logo
                          : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default_wide.png?alt=media&token=88b5a5f3-b6d3-471f-9341-5bdec5307694'
                      }
                      alt=""
                      loading="lazy"
                    />
                  )}
                  {typeof location.state !== undefined &&
                    location.state !== null &&
                    location.state?.from !== 'plans' &&
                    location.state?.from !== 'planEdit' &&
                    location.state?.from !== 'planEditChangeRecipe' && (
                      <div className="absolute right-20 top-10 bg-lightGray rounded-full p-2 cursor-pointer">
                        <PencilIcon
                          width={15}
                          height={15}
                          className="text-textColor"
                          onClick={() => handleOpenClosePopups('', setEditPictureOverlayClass, 'open')}
                        />
                      </div>
                    )}
                </div>
                {id !== 'shared' && (
                  <div className="pt-4 flex justify-center gap-8 gap-y-10 flex-wrap">
                    <div
                      title="Kochmodus"
                      onClick={() => startCookingMode()}
                      onKeyDown={() => startCookingMode()}
                      aria-hidden="true"
                      className="rounded-2xl border-transparent border-2 hover:border-accentColor p-5 cursor-pointer"
                    >
                      <Icon name="cooking" width={28} height={28} className="text-accentColor cursor-pointer mx-auto" />
                      <div className="font-extralight text-14 pt-1">Kochmodus</div>
                    </div>

                    {/* typeof location.state !== undefined &&
                    location.state !== null &&
                    location.state?.from !== 'plans' &&
                    location.state?.from !== 'planEdit' &&
                    location.state?.from !== 'planEditChangeRecipe' && (
                      <div
                        title="Originalrezept wiederherstellen"
                        className="rounded-full border-transparent border-2 hover:border-accentColor p-5 cursor-pointer"
                      >
                        <RefreshIcon
                          width={28}
                          height={28}
                          className="text-accentColor cursor-pointer"
                          onClick={() => regenerateRecipe()}
                        />
                      </div>
                    ) */}
                    <div
                      title="Favoriten"
                      className="rounded-2xl border-transparent border-2 hover:border-accentColor p-5 cursor-pointer"
                      onClick={() => saveRecipeToFavorites()}
                      onKeyDown={() => saveRecipeToFavorites()}
                      aria-hidden="true"
                    >
                      <HeartIcon
                        width={28}
                        height={28}
                        className="text-accentColor mx-auto"
                        fill={(recipeFavorite && '#C97132') || 'none'}
                      />
                      <div className="font-extralight text-14 pt-1">Zu Favoriten</div>
                    </div>
                    <div
                      title="Teilen"
                      className="rounded-2xl border-transparent border-2 hover:border-accentColor pt-5 pl-5 pb-5 pr-10 cursor-pointer"
                      onClick={() => openSharePopup()}
                      onKeyDown={() => openSharePopup()}
                      aria-hidden="true"
                    >
                      <ShareIcon width={28} height={28} className="text-accentColor cursor-pointer mx-auto" />
                      <div className="font-extralight text-14 pt-1">Teilen</div>
                    </div>
                    {(userData?.role === 1 ||
                      currentAddRecipe?.userRecipe ||
                      ((userData?.isAdminUser || userData?.isTrainerUser || userData?.isLocationAdminUser) &&
                        currentAddRecipe?.communityRecipe)) &&
                    typeof location.state !== undefined &&
                    location.state !== null &&
                    location.state?.from !== 'plans' &&
                    location.state?.from !== 'planEdit' &&
                    location.state?.from !== 'planEditChangeRecipe' ? (
                      <>
                        <div
                          title="Löschen"
                          className="rounded-2xl border-transparent border-2 hover:border-accentColor p-5 cursor-pointer"
                          onClick={() => setDeleteRecipeOverlayClass('block')}
                          onKeyDown={() => setDeleteRecipeOverlayClass('block')}
                          aria-hidden="true"
                        >
                          <TrashIcon width={28} height={28} className="text-accentColor cursor-pointer mx-auto" />
                          <div className="font-extralight text-14 pt-1">Löschen</div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {currentRecipePrice > 0 && (
                      <div title="Preis" className="rounded-2xl border-transparent border-2 p-5">
                        <CurrencyEuroIcon width={28} height={28} className="text-accentColor mx-auto" />
                        <div className="font-extralight text-14 pt-1 text-center">
                          {currentRecipePrice.toString().replaceAll('.', ',')} €
                        </div>
                        {currentOriginalRecipe !== undefined && (
                          <div className="font-extralight text-10">
                            Portion ({parseFloat(currentOriginalRecipe?.portion_g)}g)
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

                <div className="pt-8 px-6">
                  <div className="flex w-full gap-4">
                    <div className="flex-1 text-center">
                      <div className="font-semibold text-base">
                        {Math.round(parseFloat(currentAddRecipe?.kcal_total!))}
                      </div>
                      <div className={styles.recipeLabel}>kcal</div>
                    </div>
                    <div className="flex-1 text-center">
                      <div className="font-semibold text-base">
                        {Math.round(parseFloat(currentAddRecipe?.carbohydrates_total!))}g
                      </div>
                      <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
                    </div>
                    <div className="flex-1 text-center">
                      <div className="font-semibold text-base">
                        {Math.round(parseFloat(currentAddRecipe?.protein_total!))}g
                      </div>
                      <div className={styles.recipeLabel}>{t('Protein')}</div>
                    </div>
                    <div className="flex-1 text-center">
                      <div className="font-semibold text-base">
                        {Math.round(parseFloat(currentAddRecipe?.fat_total!))}g
                      </div>
                      <div className={styles.recipeLabel}>{t('Fat')}</div>
                    </div>
                  </div>
                  {typeof location.state !== undefined && location.state !== null && location.state?.from !== 'plans' && (
                    <div>
                      <div className="pt-30">
                        <div>
                          {typeof location.state !== undefined &&
                            location.state !== null &&
                            location.state?.from === 'planEdit' && (
                              <div className="font-extralight pb-10 pr-15 text-16">
                                Mit dem Klick auf diesen Button fügst du dieses Rezept zum Plan am{' '}
                                <strong>{location.state.planDateString}</strong> hinzu!
                              </div>
                            )}
                          {typeof location.state !== undefined &&
                            location.state !== null &&
                            location.state?.from === 'planEditChangeRecipe' && (
                              <div className="font-extralight pb-10 pr-15 text-16">
                                Mit dem Klick auf diesen Button änderst du das Rezept im Plan am{' '}
                                <strong>{location.state.planDateString}</strong>!
                              </div>
                            )}
                        </div>
                        <div className="justify-between flex z-150">
                          <div id="addButton">
                            <div>
                              {typeof location.state !== undefined &&
                              location.state !== null &&
                              location.state?.isCurrentRecipeDashboard === undefined &&
                              (location.state?.from === 'planEditChangeRecipe' ||
                                location.state?.from === 'dashboard') ? (
                                <Button onClick={() => changeRecipeInPlan()}>Speichern</Button>
                              ) : typeof location.state !== undefined &&
                                location.state !== null &&
                                location.state?.from === 'planEdit' ? (
                                <Button onClick={() => addRecipeToPlan()}>{t('Add')}</Button>
                              ) : (
                                <Button onClick={() => handleOpenClosePopups('', setAddToPlanOverlayClass, 'open')}>
                                  {t('Add')}
                                </Button>
                              )}
                            </div>
                          </div>
                          {typeof location.state !== undefined &&
                          location.state !== null &&
                          location.state?.from === 'planEdit' ? (
                            <></>
                          ) : currentRecipeAmount !== '1' ? (
                            <></>
                          ) : typeof location.state !== undefined &&
                            location.state !== null &&
                            (location.state?.from === 'planEditChangeRecipe' ||
                              location.state?.from === 'dashboard') ? (
                            <></>
                          ) : recipeChanged && userData?.role === 1 ? (
                            <>
                              <div className="pl-40">
                                <Button className="w-full" onClick={() => saveNewRecipeToFirebase()}>
                                  {t('SaveAsNewRecipe')}
                                </Button>
                              </div>
                            </>
                          ) : recipeChanged && currentAddRecipe?.userRecipe && id !== 'shared' ? (
                            <div>
                              <Button onClick={() => saveExistingRecipeToFirebase()}>Änderungen speichern</Button>
                            </div>
                          ) : recipeChanged &&
                            currentAddRecipe?.communityRecipe &&
                            (userData?.isAdminUser || userData?.isTrainerUser || userData?.isLocationAdminUser) &&
                            id !== 'shared' ? (
                            <div>
                              <Button onClick={() => saveExistingRecipeToFirebase()}>Änderungen speichern</Button>
                            </div>
                          ) : recipeChanged ? (
                            <div>
                              <Button className="w-full" onClick={() => saveNewRecipeToFirebase()}>
                                {t('SaveAsNewRecipe')}
                              </Button>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {userData?.role === 1 && (
                        <div>
                          {typeof location.state !== undefined &&
                            location.state !== null &&
                            location.state?.from !== 'planEdit' &&
                            location.state?.from !== 'planEditChangeRecipe' &&
                            id !== 'shared' &&
                            currentRecipeAmount === '1' &&
                            location.state?.from !== 'dashboard' && (
                              <Button className="mt-20" onClick={() => saveExistingRecipeToFirebase()}>
                                Änderungen speichern
                              </Button>
                            )}
                        </div>
                      )}
                    </div>
                  )}
                  {typeof location.state !== undefined &&
                  location.state !== null &&
                  location.state?.from === 'plans' ? (
                    <>
                      <div className="pt-40">
                        <HashTagBadge planObject={currentAddRecipe} />
                      </div>
                      <div className="flex flex-wrap gap-20 justify-between">
                        <div>
                          <div className="flex justify-between pt-40">
                            <div className="font-extralight text-15">{t('PortionSize')}</div>
                          </div>
                          <div className="pt-2">
                            <div className="font-semibold text-15">{getItemLabel()}</div>
                          </div>
                        </div>
                        <div>
                          <div className="flex justify-between pt-40">
                            <div className="font-extralight text-15">{t('Category')}</div>
                          </div>
                          <div className="pt-2">
                            <div className="font-semibold text-15">
                              {currentAddRecipe?.name !== undefined &&
                                recipeCategoryValues.filter((st: any) => st.label === currentAddRecipe?.category)[0]
                                  .label}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="flex justify-between pt-40">
                            <div className="font-extralight text-15">{t('Level of difficulty')}</div>
                          </div>
                          <div className="pt-2">
                            <div className="font-semibold text-15">
                              {currentAddRecipe?.name !== undefined &&
                                difficultyValues.filter(
                                  (st: any) => parseFloat(st.value) === currentAddRecipe?.difficulty_level
                                )[0].label}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex flex-wrap gap-10 pt-40">
                        <HashTagBadge
                          planObject={currentAddRecipe}
                          onlyNutritionForm
                          edit={userData?.role === 1}
                          setPlanObject={setCurrentAddRecipe}
                          setPlanChanged={setRecipeChanged}
                        />
                      </div>
                      <div>
                        <div className="flex flex-wrap justify-between">
                          <div>
                            <div className="flex justify-between pt-30">
                              <div className="font-light text-2xl">{t('PortionSize')}</div>
                            </div>
                            <div className="flex gap-4 pt-4">
                              <div>
                                <CustomUserInput
                                  submitFunction={() =>
                                    typeof location.state !== undefined &&
                                    location.state !== null &&
                                    (location.state?.from === 'planEditChangeRecipe' ||
                                      location.state?.from === 'dashboard')
                                      ? changeRecipeInPlan()
                                      : typeof location.state !== undefined &&
                                        location.state !== null &&
                                        location.state?.from === 'planEdit'
                                      ? addRecipeToPlan()
                                      : handleOpenClosePopups('', setAddToPlanOverlayClass, 'open')
                                  }
                                  thisValue={
                                    currentRecipeAmountTyped === ''
                                      ? ''
                                      : currentAddRecipe?.recipe_portion !== undefined
                                      ? currentAddRecipe?.recipe_portion
                                      : currentRecipeAmount
                                  }
                                  thisRef={editRecipePortionSizeRef}
                                  name="changeRecipeAmount"
                                  onChange={e =>
                                    changeRecipeAmount(e.target.value.trim(), currentOriginalRecipe, recipePiece)
                                  }
                                  onClick={() => clickRecipePortionSize('desktop')}
                                  width="4"
                                  customPaddingY="2"
                                />
                              </div>
                              <div className="w-200">
                                {currentAddRecipe?.portion_g !== undefined && (
                                  <CustomSelect
                                    name="portionValues"
                                    dropDownOption={currentRecipePortionValues}
                                    value={mapRecipePiece()}
                                    onChange={e => changeRecipePiece(e)}
                                    isFullSize
                                  />
                                )}
                              </div>

                              {/* currentOriginalRecipe?.portion_g !== undefined && (
                              <div className="my-auto">
                                <div className="font-semibold text-15">{`(${
                                  parseFloat(currentRecipeAmount) * parseFloat(currentOriginalRecipe?.portion_g)
                                }g)`}</div>
                              </div>
                              ) */}
                            </div>
                          </div>
                          {userData?.role === 1 && (
                            <div>
                              <div className="font-light text-2xl pt-30">&#160;</div>
                              <div>
                                <SwitchButton
                                  label="alghorithmus"
                                  enabled={useAlghorithmus}
                                  onChange={changeAlghorithmus}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="flex justify-between">
                          <div>
                            <div className="flex justify-between pt-40">
                              <div className="font-light text-2xl">{t('Level of difficulty')}</div>
                            </div>
                            <div className="pt-4">
                              <div className="w-28">
                                {currentAddRecipe?.difficulty_level !== undefined && (
                                  <CustomSelect
                                    name="difficultyLevel"
                                    dropDownOption={difficultyValues}
                                    defaultValue={difficultyValues.filter(
                                      (st: any) => parseFloat(st.value) === currentAddRecipe?.difficulty_level
                                    )}
                                    onChange={updateDifficultyLevel}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="flex justify-between pt-40">
                              <div className="font-light text-2xl">{t('Category')}</div>
                            </div>
                            <div className="pt-4">
                              <div className="w-300">
                                {currentAddRecipe?.category !== undefined && (
                                  <CustomSelect
                                    name="category"
                                    isFullSize
                                    dropDownOption={recipeCategoryValues}
                                    defaultValue={recipeCategoryValues.filter(
                                      (st: any) => st.label === currentAddRecipe?.category
                                    )}
                                    onChange={updateCategory}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className={styles.recipeRightWrapper}>
                <div className={styles.recipeWrapper}>
                  <div className={styles.recipeContent}>
                    <div id="recipeIngredientList">
                      <div className="flex justify-between pt-40">
                        <div className="font-light text-2xl my-auto">{t('IngredientsList')}</div>
                        {typeof location.state !== undefined &&
                          location.state !== null &&
                          location.state?.from !== 'plans' &&
                          currentRecipeAmount === '1' &&
                          recipePiece === 'Portion' && (
                            <div className="my-auto">
                              <div
                                className="flex p-7 rounded-lg cursor-pointer border-transparent border-2 hover:border-accentColor"
                                onClick={() => handleOpenSearchPopup()}
                                onKeyDown={() => handleOpenSearchPopup()}
                                aria-hidden="true"
                              >
                                <div className="text-sm my-auto font-light">Hinzufügen</div>
                                <div className="my-auto pl-10">
                                  <PlusIcon width={28} height={28} className="text-accentColor cursor-pointer" />
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                      {(currentRecipeAmount !== '1' || recipePiece === 'g') && (
                        <div className="font-extralight text-12">
                          Hinweis: Du hast die Portionsgröße geändert und kannst daher die Zutatenliste nicht mehr
                          anpassen!
                        </div>
                      )}

                      <div className={styles.ingridientContainer}>
                        {currentAddRecipe?.ingredients.map(ingridient => (
                          <>
                            <IngridientItem
                              ingridientData={ingridient}
                              recipeData={currentAddRecipe}
                              updateFunction={updateRecipeState}
                              setRecipeChanged={setRecipeChanged}
                              setDirectAddedIngridients={setDirectAddedIngridients}
                              directAddedIngridients={directAddedIngridients}
                              defaultValue={ingridient.amount}
                            />
                          </>
                        ))}

                        <div className="pt-10 pb-20">
                          {currentRecipeLoaded && (
                            <>
                              <div
                                className="cursor-pointer"
                                onClick={() => orderRewe()}
                                onKeyDown={() => orderRewe()}
                                aria-hidden="true"
                              >
                                <Icon name="rewe" width={196} />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <div id="recipeDescriptionList">
                      <div className="flex justify-between pt-40 mb-14">
                        <div className="font-light text-2xl my-auto">{t('Preparation')}</div>
                        {typeof location.state !== undefined &&
                          location.state !== null &&
                          location.state?.from !== 'plans' && (
                            <div className="my-auto">
                              <div
                                className="flex p-7 rounded-lg cursor-pointer border-transparent border-2 hover:border-accentColor"
                                onClick={() =>
                                  addDescriptionStep(
                                    currentAddRecipe,
                                    updateRecipeState,
                                    setRecipeChanged,
                                    'desktop',
                                    recipeDescriptionArray,
                                    setRecipeDescriptionArray,
                                    setDescriptionStepAdded
                                  )
                                }
                                onKeyDown={() =>
                                  addDescriptionStep(
                                    currentAddRecipe,
                                    updateRecipeState,
                                    setRecipeChanged,
                                    'desktop',
                                    recipeDescriptionArray,
                                    setRecipeDescriptionArray,
                                    setDescriptionStepAdded
                                  )
                                }
                                aria-hidden="true"
                              >
                                <div className="text-sm my-auto font-light">Neuer Schritt</div>
                                <div className="my-auto pl-10">
                                  <PlusIcon width={28} height={28} className="text-accentColor cursor-pointer" />
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                      {currentAddRecipe?.description.map(desc => (
                        <>
                          <DescriptionItem
                            descriptionData={desc}
                            recipeData={currentAddRecipe}
                            updateFunction={updateRecipeState}
                            setRecipeChanged={setRecipeChanged}
                            recipeDescriptionArray={setRecipeDescriptionArray}
                            recipeDescriptionArrayValue={recipeDescriptionArray}
                            newDescriptionStepRef={newDescriptionStepRef}
                            recipeChanged={recipeChanged}
                            setDescriptionStepAdded={setDescriptionStepAdded}
                            descriptionStepAdded={descriptionStepAdded}
                          />
                        </>
                      ))}
                    </div>
                    {typeof location.state !== undefined &&
                      location.state !== null &&
                      location.state?.from !== 'plans' && (
                        <div id="recipeIncompatibilities">
                          <div>
                            <div
                              className="flex pt-40 mb-14 cursor-pointer"
                              onClick={() => toggleIncompatibilities()}
                              onKeyDown={() => toggleIncompatibilities()}
                              aria-hidden="true"
                            >
                              <div className="font-light text-2xl">{t('Intolerances')}</div>
                              <div className="pl-5 my-auto">
                                <ChevronDownIcon className={incompatibilitiesChevronClass} height={30} width={30} />
                              </div>
                            </div>
                            <div className={incompatibilitiesClass}>
                              <div className="font-extralight text-15 pb-20 pr-20">
                                <p>Folgende Unverträglichkeiten haben wir bei diesem Rezept erkannt (ohne Gewähr). </p>
                                <p>Bitte prüfe das nochmal und passe es bei Bedarf an.</p>
                              </div>
                              {intolerancesOption.map((item: string, index: number) => (
                                <>
                                  <SwitchButton
                                    key={index}
                                    label={item}
                                    enabled={
                                      currentAddRecipe?.name !== undefined &&
                                      typeof currentAddRecipe[item.toLowerCase()] !== undefined
                                        ? Boolean(currentAddRecipe[item.toLowerCase()])
                                        : false
                                    }
                                    isBackground={index % 2 === 0}
                                    onChange={setIncompatibilities}
                                  />
                                </>
                              ))}
                            </div>
                          </div>
                          <div>
                            <div
                              className="flex pt-40 mb-14 cursor-pointer"
                              onClick={() => toggleEatingHabits()}
                              onKeyDown={() => toggleEatingHabits()}
                              aria-hidden="true"
                            >
                              <div className="font-light text-2xl">{t('Eating habits')}</div>
                              <div className="pl-5 my-auto">
                                <ChevronDownIcon className={eatingHabitsChevronClass} height={30} width={30} />
                              </div>
                            </div>
                            <div className={eatingHabitsClass}>
                              <div className="font-extralight text-15 pb-20 pr-20">
                                <p>Folgendes Essverhalten passt zu diesem Rezept. </p>
                                <p>Du kannst die Werte bei Bedarf anpassen.</p>
                              </div>
                              {eatingHabitsOption.map((item: string, index: number) => (
                                <>
                                  <SwitchButton
                                    key={index}
                                    label={item}
                                    enabled={
                                      currentAddRecipe?.name !== undefined &&
                                      typeof currentAddRecipe[item.toLowerCase()] !== undefined
                                        ? Boolean(currentAddRecipe[item.toLowerCase()])
                                        : false
                                    }
                                    isBackground={index % 2 === 0}
                                    onChange={setEatingHabits}
                                  />
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="grid h-screen place-items-center">
            <div className={styles.loading}>
              <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
            </div>
          </div>
        )}
      </TransitionContainer>
    </>
  );
};

export default RecipeDetail;
