import React from 'react';
import classNames from 'classnames';
import TransparentInput from 'pages/Surveys/components/TransparentInput';
import { Question, Survey } from 'shared/types/survey';
import { XMarkIcon as XIcon } from '@heroicons/react/24/outline';

import styles from './styles.module.scss';

interface AnswerOptionProps {
  option: string;
  optionIndex: number;
  question: Question;
  onSetSurvey: (survey: Survey) => void;
  survey: Survey;
}

export const AnswerOption: React.FC<AnswerOptionProps> = ({ option, optionIndex, question, onSetSurvey, survey }) => (
  <div
    className={classNames(styles.answerOption, {
      [styles.radio]: question.type === 1,
      [styles.checkbox]: question.type === 2,
    })}
  >
    <TransparentInput
      value={option}
      useIcon={false}
      onInput={event => {
        const target = event.target as HTMLInputElement;
        target.size = target.value.length || 1;
        onSetSurvey({
          ...survey,
          questions: survey?.questions?.map(q => {
            if (q.id === question.id) {
              return {
                ...q,
                answerOptions: q.answerOptions?.map((o, index) => {
                  if (index === optionIndex) {
                    return target.value;
                  }
                  return o;
                }),
              };
            }
            return q;
          }),
        });
      }}
      className={styles.answerOptionInput}
    />
    <button
      type="button"
      onClick={() => {
        const newAnswerOptions = question.answerOptions?.filter((_, index) => index !== optionIndex);

        onSetSurvey({
          ...survey,
          questions: survey.questions?.map(q => (q.id === question.id ? { ...q, answerOptions: newAnswerOptions } : q)),
        });
      }}
      className={styles.deleteAnswerOptionBtn}
    >
      <XIcon width={16} height={16} />
    </button>
  </div>
);

export default AnswerOption;
