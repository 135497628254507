import React, { useState, useContext } from 'react';
import { toast } from 'react-toast';
import { TrashIcon } from '@heroicons/react/24/solid';

import Button from 'components/Button';
import Overlay from 'components/Overlay';
import { AuthContext } from 'providers/AuthProvider';
import firebase from 'services/firebase';

type DeleteDocumentOverlayProps = {
  fileName: string;
  isOpen: boolean;
  onClose: () => void;
  chatId: string;
};

export default function DeleteDocumentOverlay({ fileName, isOpen, onClose, chatId }: DeleteDocumentOverlayProps) {
  const { user, tenant } = useContext(AuthContext);
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteDocument = async () => {
    setIsDeleting(true);
    const storageRef = firebase.storage().ref(`tenants/${tenant}/chats/${chatId}/documents`);

    await storageRef
      .child(fileName)
      .delete()
      .then(async () => {
        try {
          const deleteObjectRef = await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(user?.uid)
            .collection('documents')
            .where('fileName', '==', fileName)
            .get();

          if (deleteObjectRef.docs.length === 0) {
            const deleteMessageObjectRef = await firebase
              .firestore()
              .collection(`tenants/${tenant}/chats/${chatId}/messages`)
              .where('fileName', '==', fileName)
              .get();

            const documentId = deleteMessageObjectRef.docs[0].id;
            await firebase
              .firestore()
              .collection(`tenants/${tenant}/chats/${chatId}/messages`)
              .doc(documentId)
              .delete();
          } else {
            const documentId = deleteObjectRef.docs[0].id;
            await firebase
              .firestore()
              .collection(`tenants/${tenant}/users`)
              .doc(user?.uid)
              .collection('documents')
              .doc(documentId)
              .delete();
          }

          toast.success('Dokument erfolgreich gelöscht!');
          setIsDeleting(false);
        } catch (error) {
          setIsDeleting(false);
          toast.error('Leider ist etwas schief gelaufen. Bitte versuchen Sie es erneut!');
        }

        onClose();
      })
      .catch(() => {
        setIsDeleting(false);
        toast.error('Leider ist etwas schief gelaufen. Bitte versuchen Sie es erneut!');
      });
  };

  if (!fileName) {
    return null;
  }

  return (
    <Overlay
      overlayClass={isOpen ? 'block' : 'hidden'}
      setOverlayClass={onClose}
      headline="Dokument löschen"
      icon={<TrashIcon width={25} height={25} className="text-accentColor mx-auto" />}
    >
      <div>
        <div className="pt-30 font-extralight text-base">
          <div>
            Wollen Sie das Dokument <span className="font-bold">"{fileName}"</span> wirklich löschen?
          </div>
        </div>
        <div className="flex justify-between flex-wrap py-30 gap-20">
          <div className="flex-1">
            <Button className="w-full" isPending={isDeleting} disabled={isDeleting} onClick={() => deleteDocument()}>
              Löschen
            </Button>
          </div>
          <div className="flex-1">
            <Button onClick={onClose} className="w-full" buttonStyle="white">
              Abbrechen
            </Button>
          </div>
        </div>
      </div>
    </Overlay>
  );
}
