import React, { useContext } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import Button from 'components/Button';

type Props = {
  locationsContentClass: string;
  locationsChevronClass: string;
  setFunctionsChevronClass: any;
  setFunctionsContentClass: any;
  setMacroChevronClass: any;
  setMacroContentClass: any;
  setLinkListChevronClass: any;
  setLinkListContentClass: any;
  setLocationsContentClass: any;
  setLocationsChevronClass: any;
  openNewLocationsPopup: any;
  startDeleteLocationItem: any;
  openEditLocationPopup: any;
  setInvitationContentClass: any;
  setInvitationChevronClass: any;
};

const SettingsLocations: React.FC<Props> = ({
  locationsContentClass,
  locationsChevronClass,
  setMacroChevronClass,
  setMacroContentClass,
  setLinkListChevronClass,
  setLinkListContentClass,
  setLocationsContentClass,
  setLocationsChevronClass,
  openNewLocationsPopup,
  startDeleteLocationItem,
  openEditLocationPopup,
  setInvitationChevronClass,
  setInvitationContentClass,
  setFunctionsChevronClass,
  setFunctionsContentClass,
}) => {
  const { tenantData, setTenantData } = useContext(AuthContext);

  // Toggle Functions area
  const toggleLocations = (): void => {
    if (locationsContentClass === 'hidden') {
      setLocationsContentClass('block');
      setLocationsChevronClass('text-accentColor transition duration-300 transform rotate-180');
    } else {
      setLocationsContentClass('hidden');
      setLocationsChevronClass('text-accentColor transition duration-300 transform rotate-360');
    }

    setMacroChevronClass('text-accentColor');
    setMacroContentClass('hidden');

    setLinkListChevronClass('text-accentColor');
    setLinkListContentClass('hidden');

    setFunctionsChevronClass('text-accentColor');
    setFunctionsContentClass('hidden');

    setInvitationChevronClass('text-accentColor');
    setInvitationContentClass('hidden');
  };

  return (
    <>
      <div className="mt-30 rounded-3xl bg-lightGray p-20">
        <div className="flex space-x-10 cursor-pointer" onClick={() => toggleLocations()} aria-hidden="true">
          <div className="font-bold text-20">Standorte</div>
          <div>
            <ChevronDownIcon className={locationsChevronClass} height={30} width={30} />
          </div>
        </div>
        <div className={locationsContentClass}>
          <div className="font-extralight pt-5">
            Wenn Sie Ihren Mitgliedern auch einen Standort zuordnen wollen können Sie an dieser Stelle neue Standorte
            anlegen und bestehende bearbeiten.
          </div>
          <div className="font-extralight pt-5 pb-20">
            Beim anlegen oder importieren eines Mitglieds können Sie diese Standorte dann auswählen.
          </div>
          <div className="pt-20 pb-20">
            <Button className="py-10" onClick={() => openNewLocationsPopup()}>
              Neuen Standort hinzufügen
            </Button>
          </div>
          <div>
            {tenantData?.settings?.locations?.map((item: SettingsLocations, index: number) => (
              <div key={index} className="rounded-2xl border border-accentColor mb-15 p-20 w-full large:w-1/2">
                <div>
                  <div>{item.name}</div>
                </div>
                <div className="flex gap-20 pt-20 flex-wrap">
                  <Button className="py-5 text-12" onClick={() => openEditLocationPopup(item.id, item.name)}>
                    Bearbeiten
                  </Button>
                  <Button className="py-5 text-12" onClick={() => startDeleteLocationItem(item.id)}>
                    Löschen
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsLocations;
