import React, { ReactNode, useState } from 'react';
import { XMarkIcon as XIcon } from '@heroicons/react/24/solid';
import { handleOpenClosePopups } from 'shared/functions/global';
import TransitionContainer from 'components/TransitionContainer';
import HashTagBadge from 'pages/Plans/components/HashTagBadge';
import { difficultyValues, recipeCategoryValues } from 'shared/constants/global';
import { Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';

type Props = {
  overlayClass?: string;
  setOverlayClass: Function;
  title: String;
  foodObject: any;
};

const NutritionItemOverlayDetails: React.FC<Props> = ({ overlayClass, setOverlayClass, title, foodObject }) => {
  const [showGeneral, setShowGeneral] = useState(true);
  const [showItems, setShowItems] = useState(false);
  const [showDescription, setShowDescription] = useState(false);

  const { t } = useTranslation();

  const changeTab = (tab: string) => {
    if (tab === 'general') {
      setShowItems(false);
      setShowDescription(false);
      setShowGeneral(true);
    } else if (tab === 'description') {
      setShowItems(false);
      setShowDescription(true);
      setShowGeneral(false);
    } else {
      setShowItems(true);
      setShowDescription(false);
      setShowGeneral(false);
    }
  };

  const getItemLabel = () => {
    if (foodObject?.recipe_piece !== undefined) {
      if (foodObject?.recipe_piece === 'g') {
        return `${foodObject?.amount} g`;
      }
    } else if (foodObject?.recipe_portion !== undefined) {
      return foodObject?.recipe_portion;
    }

    if (foodObject?.portion_g !== undefined) {
      if (foodObject?.recipe_portion !== undefined) {
        return `${foodObject?.recipe_portion} Portion (${parseFloat(foodObject?.portion_g)}g)`;
      }
      return `${foodObject?.amount} Portion (${parseFloat(foodObject?.portion_g)}g)`;
    }

    return '';
  };

  const closePopup = () => {
    setShowItems(false);
    setShowDescription(false);
    setShowGeneral(true);
    setOverlayClass('hidden');
  };

  return (
    <div className={overlayClass}>
      <div className={styles.backgroundPopupLayerTop0}>
        <TransitionContainer isShown={overlayClass === 'block'} isOverlay>
          <div className={styles.overlayPopup}>
            <div className={styles.overlayContent}>
              <div className={styles.mainOverlayWrapper}>
                <div className="flex">
                  <div className="w-1/2 my-auto">
                    <div className="text-30 px-20 font-bold my-auto mx-auto">{title}</div>
                    <div className="mx-20 pt-10">
                      <HashTagBadge planObject={foodObject} setPlanObject={null} setPlanChanged={null} isPlanOverview />
                    </div>
                  </div>
                  <div className="flex w-1/2 justify-end">
                    <div className="relative">
                      <img
                        src={foodObject.imageUrl}
                        alt=""
                        className="object-cover rounded-tr-3xl rounded-bl-3xl w-full max-x-200 opacity-80"
                      />
                      <div className="absolute top-10 right-10 text-textColor p-1 bg-bgColor rounded-full">
                        <XIcon
                          width={25}
                          height={25}
                          className="text-accentColor mx-auto cursor-pointer"
                          onClick={() => closePopup()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="font-bold text-20 pt-30 mx-20">Rezept Details</div>
                <div className="flex gap-60 flex-wrap pt-10 mx-20">
                  <div>
                    <div
                      className={
                        showGeneral === true
                          ? 'border-b-4 border-accentColor cursor-pointer'
                          : 'border-transparent cursor-pointer'
                      }
                      onClick={() => changeTab('general')}
                      aria-hidden
                    >
                      Allgemein
                    </div>
                  </div>
                  <div>
                    <div
                      className={
                        showItems === true
                          ? 'border-b-4 border-accentColor cursor-pointer'
                          : 'border-transparent cursor-pointer'
                      }
                      onClick={() => changeTab('items')}
                      aria-hidden
                    >
                      Zutaten
                    </div>
                  </div>
                  <div>
                    <div
                      className={
                        showDescription === true
                          ? 'border-b-4 border-accentColor cursor-pointer'
                          : 'border-transparent cursor-pointer'
                      }
                      onClick={() => changeTab('description')}
                      aria-hidden
                    >
                      Zubereitung
                    </div>
                  </div>
                </div>
                <div>
                  <Transition
                    show={showGeneral}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <div className="mx-20">
                      <div className="bg-lightGrayDarker rounded-xl p-20 mt-20">
                        <div className="font-bold">Nährtwertangabe:</div>
                        <div className="flex w-full gap-60 pt-10">
                          <div className="">
                            <div className="font-semibold text-base">
                              {Math.round(parseFloat(foodObject?.kcal_total!))}
                            </div>
                            <div className={styles.recipeLabel}>kcal</div>
                          </div>
                          <div className="">
                            <div className="font-semibold text-base">
                              {Math.round(parseFloat(foodObject?.carbohydrates_total!))}g
                            </div>
                            <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
                          </div>
                          <div className="">
                            <div className="font-semibold text-base">
                              {Math.round(parseFloat(foodObject?.protein_total!))}g
                            </div>
                            <div className={styles.recipeLabel}>{t('Protein')}</div>
                          </div>
                          <div className="">
                            <div className="font-semibold text-base">
                              {Math.round(parseFloat(foodObject?.fat_total!))}g
                            </div>
                            <div className={styles.recipeLabel}>{t('Fat')}</div>
                          </div>
                        </div>
                      </div>

                      <div className="bg-lightGrayDarker rounded-xl p-20 mt-40">
                        <div className="font-bold">Eigenschaften:</div>
                        <div className="flex gap-60">
                          <div>
                            <div className="flex justify-between pt-10">
                              <div className="font-extralight text-15 opacity-50">{t('PortionSize')}</div>
                            </div>

                            <div className="pt-2">
                              <div className="font-semibold text-15">{getItemLabel()}</div>
                            </div>
                          </div>
                          <div>
                            <div className="flex justify-between pt-10">
                              <div className="font-extralight text-15 opacity-50">{t('Level of difficulty')}</div>
                            </div>
                            <div className="pt-2">
                              <div className="font-semibold text-15">
                                {foodObject?.name !== undefined &&
                                  foodObject?.difficulty_level !== undefined &&
                                  difficultyValues.filter(
                                    (st: any) => parseFloat(st.value as string) === foodObject?.difficulty_level
                                  )[0].label}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="flex justify-between pt-10">
                              <div className="font-extralight text-15 opacity-50">{t('Category')}</div>
                            </div>
                            <div className="pt-2">
                              <div className="font-semibold text-15">
                                {foodObject?.name !== undefined &&
                                  foodObject?.difficulty_level !== undefined &&
                                  recipeCategoryValues.filter((st: any) => st.label === foodObject?.category)[0].label}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Transition>
                </div>
                <div>
                  <Transition
                    show={showItems}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <div className="mx-20 bg-lightGrayDarker rounded-xl p-15 mt-20 mb-40">
                      {foodObject?.ingredients?.map((ingridient: any, index: number) => (
                        <div key={index}>
                          <div className="py-10 hover:bg-lightGray">
                            <div className="flex">
                              <div className="font-extralight text-16 flex gap-1 relative cursor-pointer pl-20">
                                <span>{ingridient.name}</span>
                              </div>
                              <div className="ml-auto my-auto pr-30">
                                <div className="font-extralight text-16">
                                  {ingridient.amount} {ingridient.piece}
                                </div>
                              </div>
                            </div>
                          </div>
                          {index + 1 !== foodObject?.ingredients?.length && <hr className="opacity-50" />}
                        </div>
                      ))}
                    </div>
                  </Transition>
                </div>
                <div>
                  <Transition
                    show={showDescription}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <div className="mx-20 mt-20 mb-40">
                      {foodObject?.description?.map((desc: any, index: number) => (
                        <div key={index}>
                          <div className="flex bg-lightGrayDarker rounded-xl p-15 mb-20">
                            <div className="text-3xl text-accentColor font-bold pr-2 my-auto">{index + 1}.</div>
                            <div className="font-extralight text-16">
                              <div className="p-5">
                                <div className="text-textColor leading-6 p-10">{desc.text}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Transition>
                </div>
              </div>
            </div>
          </div>
        </TransitionContainer>
      </div>
    </div>
  );
};

export default NutritionItemOverlayDetails;
