import React from 'react';

type Props = {
  userData?: any;
};

const CoachingDashboardWater: React.FC<Props> = ({ userData }) => {
  return (
    <div className="pt-30">
      <div>Wasserbedarf</div>
    </div>
  );
};

export default CoachingDashboardWater;
