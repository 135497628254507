import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TrashIcon, ShoppingCartIcon, ChevronLeftIcon, ShareIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import Button from 'components/Button';
import _ from 'lodash';
import Header from 'components/Header';
import Headline from 'components/Headline';
import { MainContext } from 'providers/MainProvider';
import { AuthContext } from 'providers/AuthProvider';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import Icon from 'components/Icon';
import { savePurchaseListToFirebase } from 'shared/functions/global';
import FileSaver from 'file-saver';
import ShoppingListItem from '../ShoppingListItem';
import RecipeListItem from '../RecipeListItem';

type Props = {
  thisId?: number;
  openPurchaseListOnLoad?: boolean;
  openSharePopup: any;
  resetFunction: Function;
};

const RecipeList: React.FC<Props> = ({ thisId, openPurchaseListOnLoad = false, openSharePopup, resetFunction }) => {
  const { t } = useTranslation();
  const { userPlanList, purchaseList } = useContext(MainContext);
  const moment = extendMoment(Moment);

  const authContext = useContext(AuthContext);
  const { userData, tenant, tenantData } = useContext(AuthContext);

  const recipeFilterList = [] as any;

  const [recipeFilteredListArray, setRecipeFilteredListArray] = useState([]);
  const [purchaseListItemArray, setPurchaseListItemArray] = useState<any[]>([]);
  const [currentType, setCurrentType] = useState('add');

  const [isDownloadPending, setIsDownloadPending] = useState(false);

  const [currentAddRecipeList, setCurrentAddRecipeList] = useState("'40 Gramm Tofu', '50 Gramm Kirschtomate'");

  const planFilteredList = [] as any;

  const deleteIngredient = (deleteName: any) => {
    if (purchaseList !== undefined) {
      const newObject = {
        ...purchaseList[0],
        ingredients: purchaseList[0].ingredients.map(ingridients => {
          if (ingridients.name !== deleteName) return ingridients;
          return {
            ...ingridients,
            deleted: !ingridients.deleted,
          };
        }),
      };

      savePurchaseListToFirebase(userData, newObject, authContext.user?.uid, tenant);
    }
  };

  const jumpToShoppingList = () => {
    setCurrentType('show');
  };

  const getGrouArray = () => {
    if (purchaseList !== undefined) {
      const GroupByName = _(purchaseList[0].ingredients)
        .groupBy('name')
        .map((ingridients, id) => ({
          name: id,
          category: ingridients[0].category,
          deleted: ingridients[0].deleted,
          amount: _.sumBy(ingridients, 'amount'),
          id: ingridients[0].id,
        }))
        .filter((item: any) => item.deleted === false)
        .value();

      const sortedGroupArray = GroupByName.sort((a, b) => a.category.localeCompare(b.category));

      const groups = _.groupBy(sortedGroupArray, 'category');

      const groupedArray = Object.keys(groups).map(key => {
        return groups[key].map(ingridients => {
          return ingridients;
        });
      });

      setPurchaseListItemArray(groupedArray);
    }
  };

  const orderRewe = () => {
    let thisPartnerId = '5e114485-e0e0-4999-a248-e3b799fa0329';

    if (tenantData?.rewePartnerId !== undefined) {
      thisPartnerId = tenantData.rewePartnerId;
    }

    window.open(
      `https://static.tortija.de/interfaces/apis/getReweAsRedirect.php?data=${currentAddRecipeList}&partnerId=${thisPartnerId}`,
      '_blank'
    );
  };

  // Download purchase list as pdf
  const downloadPurchaseList = () => {
    let thisStartDate = '';
    let thisEndDate = '';

    if (purchaseList !== undefined) {
      if (purchaseList[0].startDate !== undefined) {
        thisStartDate = purchaseList[0].startDate;
      }
      if (purchaseList[0].endDate !== undefined) {
        thisEndDate = purchaseList[0].endDate;
      }
    }

    setIsDownloadPending(true);

    fetch(
      `https://static.tortija.de/interfaces/apis/generate_pdf_purchaselist_by_tenant.php?type=recipe&tenant=${tenant}&tenantName=${
        tenantData?.company?.companyName ?? ''
      }`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(purchaseListItemArray),
      }
    )
      .then(function (response) {
        return response.blob();
      })
      .then(blob => {
        FileSaver.saveAs(blob, 'einkaufsliste.pdf');
        setIsDownloadPending(false);
      });
  };

  useEffect(() => {
    if (userPlanList) {
      userPlanList?.forEach(function (key) {
        const thisCheckPlanStartDate = moment.unix(key.startDate.seconds).utc().format('DD-MM-YY');
        const thisCheckPlanEndDate = moment.unix(key.endDate.seconds).utc().format('DD-MM-YY');

        const thisConvertedPlanStartDate = moment(thisCheckPlanStartDate, 'DD-MM-YY');
        const thisConvertedPlanEndDate = moment(thisCheckPlanEndDate, 'DD-MM-YY');

        const thisUserStartDate = moment().utc().format('YYYY-MM-DD');
        const thisUserEndDate = moment().utc().subtract(-7, 'day').format('YYYY-MM-DD');

        const thisPlanRange = moment.range(thisConvertedPlanStartDate, thisConvertedPlanEndDate);
        const thisUserRange = moment.range(moment(thisUserStartDate), moment(thisUserEndDate));

        if (thisUserRange.overlaps(thisPlanRange, { adjacent: true }) === true) {
          planFilteredList.push(key);
        }
      });

      const generatePlanFilteredList = planFilteredList?.map((item: any) => {
        item.dayEntries?.map((entries: any) => {
          recipeFilterList.push(entries);

          return 0;
        });
        return 0;
      });

      setRecipeFilteredListArray(recipeFilterList);

      if (openPurchaseListOnLoad) {
        setCurrentType('show');
      }
    }
  }, [userPlanList]);

  useEffect(() => {
    if (purchaseList !== undefined && purchaseList.length > 0) {
      const thisRecipeListString = [] as any;

      for (let i = 0; i <= purchaseList[0].ingredients.length - 1; i += 1) {
        const isDeleted = purchaseList[0].ingredients[i].deleted as any;

        if (isDeleted !== true) {
          let thisAmountPiece = '';
          thisAmountPiece = purchaseList[0].ingredients[i].amount.toString();

          if ((parseFloat(thisAmountPiece).toFixed(2) as any) % 1 === 0) {
            thisAmountPiece = parseFloat(thisAmountPiece).toFixed(0);
          } else {
            thisAmountPiece = parseFloat(thisAmountPiece).toFixed(2);
          }

          thisAmountPiece += ' g';

          /* if (purchaseList[0].ingredients[i].piece === 'g') {
          thisAmountPiece += ' g';
        } else if (purchaseList[0].ingredients[i].piece !== 'Stk.') {
          thisAmountPiece += ` ${purchaseList[0].ingredients[i].piece.replace('Portion', 'TL')}`;
        } */

          let thisRecipeListName = purchaseList[0].ingredients[i].name;

          // Replace specific strings to get the best results
          thisRecipeListName = thisRecipeListName.replace('%', '');
          thisRecipeListName = thisRecipeListName.replace('(Rohmenge)', '');
          thisRecipeListName = thisRecipeListName.replace('Fetakäse light', 'Feta Light (Salakis)');

          thisRecipeListString.push(`${thisAmountPiece} ${thisRecipeListName}`);
        }
      }

      setCurrentAddRecipeList(thisRecipeListString.map((element: any) => `${element}`).join(', '));

      /* const reweButtonScript = document.createElement('script');
      reweButtonScript.setAttribute('src', 'https://shop.rewe.de/ingredients/button.js');
      document.body.appendChild(reweButtonScript); */

      getGrouArray();
    }
  }, [purchaseList]);

  return (
    <>
      <div>
        <Header>
          <Headline level={1}>Einkaufsliste nach Rezept</Headline>
        </Header>
      </div>
      {currentType === 'add' ? (
        <>
          <div className="flex gap-20 flex-wrap pb-20">
            <div className="relative" id="toCartRecipe">
              <div>
                <Button className="text-12 py-2 pr-40" onClick={() => jumpToShoppingList()}>
                  Zur Einkaufsliste
                </Button>
              </div>
              <div className="absolute right-15 top-10">
                <ShoppingCartIcon width={17} height={17} className="text-buttonTextColor" />
              </div>
            </div>
          </div>
          <div className="font-extralight">Hier werden dir die Rezept der nächsten 7 Tage angezeigt.</div>
          <div className="font-extralight">Bitte klicke die Rezepte an, für die du einkaufen möchtest!</div>
        </>
      ) : (
        <div className="flex gap-20 flex-wrap pb-20">
          <div className="relative">
            <div>
              <Button className="text-12 py-2 pl-40" onClick={() => setCurrentType('add')}>
                Zurück zur Rezept Auswahl
              </Button>
            </div>
            <div className="absolute left-15 top-10">
              <ChevronLeftIcon width={17} height={17} className="text-buttonTextColor" />
            </div>
          </div>
        </div>
      )}

      {currentType === 'show' && (
        <>
          <div className="gap-20 flex-wrap pb-20 flex">
            <div className="hidden desktop:block">
              <div className="relative">
                <div>
                  <Button
                    isPending={isDownloadPending}
                    className="text-12 py-2 pr-40"
                    disabled={isDownloadPending}
                    onClick={() => downloadPurchaseList()}
                  >
                    Download PDF
                  </Button>
                </div>
                <div className="absolute right-15 top-10 text-buttonTextColor">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    width={17}
                    height={17}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="relative"
              onClick={() => openSharePopup()}
              onKeyDown={() => openSharePopup()}
              aria-hidden="true"
            >
              <div>
                <Button className="text-12 py-2 pr-40">Teilen</Button>
              </div>
              <div className="absolute right-15 top-10 text-buttonTextColor">
                <ShareIcon width={18} height={18} />
              </div>
            </div>
            <div
              className="relative"
              onClick={() => resetFunction()}
              onKeyDown={() => resetFunction()}
              aria-hidden="true"
            >
              <div>
                <Button className="text-12 py-2 pr-40">Leeren</Button>
              </div>
              <div className="absolute right-15 top-10">
                <TrashIcon width={17} height={17} className="text-buttonTextColor" />
              </div>
            </div>
          </div>

          <div className="pt-20 pb-15">
            {purchaseList !== undefined && purchaseList.length > 0 && (
              <>
                <div
                  className="cursor-pointer"
                  onClick={() => orderRewe()}
                  onKeyDown={() => orderRewe()}
                  aria-hidden="true"
                >
                  <Icon name="rewe" width={196} />
                </div>
              </>
            )}
          </div>
        </>
      )}
      <div className="grid grid-cols-1 desktop:grid-cols-2 xxl:grid-cols-3 gap-x-60 desktop:gap-x-30 max-w-7xl pt-20">
        {currentType === 'show' && (
          <>
            {purchaseListItemArray?.map((ingridients: any, index: number) => (
              <div className="mb-30" key={index}>
                <ShoppingListItem itemObject={ingridients} />
              </div>
            ))}
          </>
        )}

        {currentType === 'add' &&
          recipeFilteredListArray?.map(
            (recipe: any, index: number) =>
              (recipe?.breakfast?.recipes.length > 0 ||
                recipe?.lunch?.recipes.length > 0 ||
                recipe?.dinner?.recipes.length > 0 ||
                recipe?.snacks?.recipes.length > 0) &&
              moment.unix(recipe.date.seconds).utc().format('YYYYMMDD') >= moment().utc().format('YYYYMMDD') && (
                <div className="mb-30" key={index}>
                  <RecipeListItem recipeItem={recipe} />
                </div>
              )
          )}
      </div>
      {purchaseListItemArray.length === 0 && currentType === 'show' && (
        <>
          <div className="w-full">
            <div className="font-semibold text-20 pb-15">
              Aktuell hast du noch keine Lebensmittel in deiner Einkaufsliste.
            </div>
            <div className="font-extralight">
              Gehe zurück zur Rezept Auswahl und klicke dort die Rezepte an, für die du einkaufen möchtest!
            </div>
          </div>
        </>
      )}
      {currentType === 'show' && purchaseList !== undefined && (
        <>
          {purchaseList[0]?.ingredients?.filter((item: any) => item.deleted === true).length > 0 && (
            <div className="w-full desktop:w-1/2">
              <div className="font-bold pb-10 pt-20 ">Gekaufte Produkte</div>
              <div className="rounded-3xl h-full mb-30 p-20 shadow-2xl bg-lightGray relative">
                <>
                  {purchaseList[0]?.ingredients
                    ?.filter((item: any) => item.deleted === true)
                    .map((ingridients: any, index: number) => (
                      <div
                        key={index}
                        className="flex justify-between mb-20 cursor-pointer"
                        onClick={() => deleteIngredient(ingridients.name)}
                        aria-hidden="true"
                      >
                        <div className="flex">
                          {ingridients.deleted === true ? (
                            <CheckCircleIcon width={25} height={25} className="mr-10 my-auto text-accentColor" />
                          ) : (
                            <div className="rounded-full w-20 h-20 border-2 border-accentColor ml-1 mr-10 my-auto">
                              <></>
                            </div>
                          )}

                          <div className={ingridients.deleted === true ? 'my-auto line-through' : 'my-auto'}>
                            {ingridients.name}
                          </div>
                        </div>
                        <div
                          className={
                            ingridients.deleted === true
                              ? 'my-auto font-extralight line-through'
                              : 'my-auto font-extralight'
                          }
                        >
                          {(parseFloat(ingridients.amount).toFixed(2) as any) % 1 === 0
                            ? parseFloat(ingridients.amount).toFixed(0)
                            : parseFloat(ingridients.amount).toFixed(2)}{' '}
                          g
                        </div>
                      </div>
                    ))}
                </>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default RecipeList;
