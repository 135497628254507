import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StarIcon as StarIconOutline } from '@heroicons/react/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';

type Props = {
  updateFunction: any;
  isMobile: boolean;
  ratingDayType: number;
};

const DayRating: React.FC<Props> = ({ updateFunction, isMobile, ratingDayType }) => {
  const { t } = useTranslation();
  const [hoverStars, setHoverStars] = useState(0);

  return (
    <>
      <div>
        <div className="px-15 md:px-0 mt-15 font-extralight">Wie würdest du diesen Tag bewerten?</div>
        <div className="flex justify-between mx-20 my-20">
          <div
            onClick={() => updateFunction(1)}
            onMouseOver={() => setHoverStars(1)}
            onFocus={() => setHoverStars(1)}
            onMouseLeave={() => setHoverStars(0)}
            aria-hidden="true"
            className="cursor-pointer"
          >
            {(ratingDayType >= 1 && hoverStars === 0) || hoverStars >= 1 ? (
              <StarIconSolid width={35} height={35} className="text-accentColor mx-auto" />
            ) : (
              <StarIconOutline width={35} height={35} className="text-accentColor mx-auto" />
            )}
          </div>
          <div
            onClick={() => updateFunction(2)}
            onMouseOver={() => setHoverStars(2)}
            onFocus={() => setHoverStars(2)}
            onMouseLeave={() => setHoverStars(0)}
            aria-hidden="true"
            className="cursor-pointer"
          >
            {(ratingDayType >= 2 && hoverStars === 0) || hoverStars >= 2 ? (
              <StarIconSolid width={35} height={35} className="text-accentColor mx-auto" />
            ) : (
              <StarIconOutline width={35} height={35} className="text-accentColor mx-auto" />
            )}
          </div>
          <div
            onClick={() => updateFunction(3)}
            onMouseOver={() => setHoverStars(3)}
            onFocus={() => setHoverStars(3)}
            onMouseLeave={() => setHoverStars(0)}
            aria-hidden="true"
            className="cursor-pointer"
          >
            {(ratingDayType >= 3 && hoverStars === 0) || hoverStars >= 3 ? (
              <StarIconSolid width={35} height={35} className="text-accentColor mx-auto" />
            ) : (
              <StarIconOutline width={35} height={35} className="text-accentColor mx-auto" />
            )}
          </div>
          <div
            onClick={() => updateFunction(4)}
            onMouseOver={() => setHoverStars(4)}
            onFocus={() => setHoverStars(4)}
            onMouseLeave={() => setHoverStars(0)}
            aria-hidden="true"
            className="cursor-pointer"
          >
            {(ratingDayType >= 4 && hoverStars === 0) || hoverStars >= 4 ? (
              <StarIconSolid width={35} height={35} className="text-accentColor mx-auto" />
            ) : (
              <StarIconOutline width={35} height={35} className="text-accentColor mx-auto" />
            )}
          </div>
          <div
            onClick={() => updateFunction(5)}
            onMouseOver={() => setHoverStars(5)}
            onFocus={() => setHoverStars(5)}
            onMouseLeave={() => setHoverStars(0)}
            aria-hidden="true"
            className="cursor-pointer"
          >
            {(ratingDayType >= 5 && hoverStars === 0) || hoverStars >= 5 ? (
              <StarIconSolid width={35} height={35} className="text-accentColor mx-auto" />
            ) : (
              <StarIconOutline width={35} height={35} className="text-accentColor mx-auto" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DayRating;
