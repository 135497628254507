import React, { useContext } from 'react';
import TransitionContainer from 'components/TransitionContainer';
import Headline from 'components/Headline';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';
import firebase from 'services/firebase';
import { AuthContext } from 'providers/AuthProvider';
import { toast } from 'react-toast';
import moment from 'moment';
import Icon from 'components/Icon';

import { MainContext } from 'providers/MainProvider';
import { XMarkIcon as XIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { savePurchaseListToFirebase } from 'shared/functions/global';
import styles from './styles.module.scss';

type Props = {
  isShown: boolean;
  title: string;
  goBack?: React.MouseEventHandler<HTMLButtonElement>;
};

const Messages: React.FC<Props> = ({ isShown, title, goBack = () => {} }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const { userMessagesList, setTempRecipeObject } = React.useContext(MainContext);
  const { userData, tenant } = useContext(AuthContext);

  const getDateDifference = () => {
    if (userMessagesList !== undefined) {
      if (userMessagesList.length > 0 && userMessagesList[0].sendDate !== undefined) {
        const sendDate = moment.unix(userMessagesList[0].sendDate).utc();
        const currentDate = moment().utc();

        const dateDifference = sendDate.diff(currentDate, 'days') as any;

        if (dateDifference === 0) {
          return 'Heute';
        }

        if (dateDifference < 0) {
          return `vor ${dateDifference.toString().substr(1, dateDifference.length)} Tage(n)`;
        }

        return `${dateDifference}Tag(e)`;
      }
    }
    return '';
  };

  const jumpToPurchaseList = async (purchaseListUid: string, messageUid: string) => {
    try {
      const gePurchaseListObject = await firebase
        .firestore()
        .collection('sharedPurchaselist')
        .doc(purchaseListUid)
        .get();
      const getMessageObject = await firebase.firestore().collection('messages').doc(messageUid).get();

      const getPurchaselistObjectDocumentValue = { ...gePurchaseListObject.data() };
      const getMessageObjectDocumentValue = {
        ...getMessageObject.data(),
        messageRead: true,
      };

      try {
        await firebase
          .firestore()
          .collection('messages')
          .doc(messageUid)
          .update(getMessageObjectDocumentValue as object);

        savePurchaseListToFirebase(userData, { ...getPurchaselistObjectDocumentValue }, authContext.user?.uid, tenant);
        history.push({
          pathname: '/shopping-list',
          state: { from: 'shared' },
        });
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.error('Es ist leider etwas schief gegangen!');
    }
  };

  const jumpToSharedPlan = async (planUid: string, messageUid: string) => {
    try {
      const getPlanObject = await firebase.firestore().collection('sharedPlans').doc(planUid).get();
      const getMessageObject = await firebase.firestore().collection('messages').doc(messageUid).get();

      const getPlanObjectDocumentValue = { ...getPlanObject.data() };
      const getMessageObjectDocumentValue = {
        ...getMessageObject.data(),
        messageRead: true,
      };

      try {
        await firebase
          .firestore()
          .collection('messages')
          .doc(messageUid)
          .update(getMessageObjectDocumentValue as object);

        setTempRecipeObject({ ...getPlanObjectDocumentValue });
        history.push({
          pathname: '/plans/shared',
        });
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.error('Es ist leider etwas schief gegangen!');
    }
  };

  const jumpToSharedRecipe = async (recipeUid: string, messageUid: string) => {
    try {
      const getRecipeObject = await firebase.firestore().collection('sharedRecipes').doc(recipeUid).get();
      const getMessageObject = await firebase.firestore().collection('messages').doc(messageUid).get();

      const getRecipeObjectDocumentValue = { ...getRecipeObject.data() };
      const getMessageObjectDocumentValue = {
        ...getMessageObject.data(),
        messageRead: true,
      };

      try {
        await firebase
          .firestore()
          .collection('messages')
          .doc(messageUid)
          .update(getMessageObjectDocumentValue as object);

        setTempRecipeObject({ ...getRecipeObjectDocumentValue });

        history.push({
          pathname: '/nutrition/recipes/shared',
        });
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.error('Es ist leider etwas schief gegangen!');
    }
  };

  // Delete Function
  const deleteMessage = async (deleteId: any, deleteItemId: any, messageType: string) => {
    if (messageType === 'plan') {
      try {
        await firebase.firestore().collection('messages').doc(deleteId).delete();
        try {
          await firebase.firestore().collection('sharedPlans').doc(deleteItemId).delete();
        } catch (error: any) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          toast.error('Es ist leider etwas schief gegangen!');
        }
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    } else if (messageType === 'purchaseList') {
      try {
        await firebase.firestore().collection('messages').doc(deleteId).delete();
        try {
          await firebase.firestore().collection('sharedPurchaselist').doc(deleteItemId).delete();
        } catch (error: any) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          toast.error('Es ist leider etwas schief gegangen!');
        }
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    } else if (messageType === 'recipe') {
      try {
        await firebase.firestore().collection('messages').doc(deleteId).delete();
        try {
          await firebase.firestore().collection('sharedRecipes').doc(deleteItemId).delete();
        } catch (error: any) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          toast.error('Es ist leider etwas schief gegangen!');
        }
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist leider etwas schief gegangen!');
      }
    }
  };

  return (
    <TransitionContainer isShown={isShown}>
      <div className={styles.header}>
        <Headline level={1} className="mb-20">
          {t(title)}
        </Headline>
        <Headline level={4} displayBackBtn goBack={goBack}>
          {t('Return')}
        </Headline>
        {userMessagesList !== undefined && (
          <>
            {userMessagesList.length > 0 ? (
              <div className="pb-80">
                {userMessagesList.filter(item => item.messageRead === false).length > 0 && (
                  <div className="font-bold pt-30 pb-10">Neu</div>
                )}
                {userMessagesList
                  .filter(item => item.messageRead === false)
                  .map((entries: any, index: number) => {
                    return (
                      <div key={index}>
                        <div className="rounded-3xl bg-lightGray flex justify-between flex-wrap gap-20 md:mb-40 mb-20 py-40 px-40 relative mr-20">
                          <div className="my-auto pt-10">
                            <div className="absolute left-40 top-15">
                              {entries.messageType === 'plan' ? (
                                <div className="flex gap-10">
                                  <div>
                                    <Icon name="plan" height={22} width={22} className={styles.icon} />
                                  </div>
                                  <div className="font-extralight text-12 my-auto">Plan</div>
                                </div>
                              ) : entries.messageType === 'purchaseList' ? (
                                <div className="flex gap-10">
                                  <div>
                                    <Icon name="shoppingList" height={22} width={22} className={styles.icon} />
                                  </div>
                                  <div className="font-extralight text-12 my-auto">Einkaufsliste</div>
                                </div>
                              ) : entries.messageType === 'recipe' ? (
                                <div className="flex gap-10">
                                  <div>
                                    <Icon name="recipes" height={22} width={22} className={styles.icon} />
                                  </div>
                                  <div className="font-extralight text-12 my-auto">Rezept</div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="text-10 text-grey">{getDateDifference()}</div>
                            {entries.messageType === 'plan' ? (
                              <>
                                <span className="font-bold">{entries.userSenderName}</span> hat dir den Plan{' '}
                                <span className="font-bold">"{entries.messageItemName}"</span> geschickt!
                              </>
                            ) : entries.messageType === 'purchaseList' ? (
                              <>
                                <span className="font-bold">{entries.userSenderName}</span> hat dir eine Einkaufsliste
                                geschickt!
                              </>
                            ) : entries.messageType === 'recipe' ? (
                              <>
                                <span className="font-bold">{entries.userSenderName}</span> hat dir das Rezept{' '}
                                <span className="font-bold">"{entries.messageItemName}"</span> geschickt!
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="pt-10">
                            {entries.messageType === 'plan' ? (
                              <>
                                <Button
                                  className="py-10 text-14"
                                  onClick={() => jumpToSharedPlan(entries.messageItemUid, entries.messageUid)}
                                >
                                  Zum Plan
                                </Button>
                              </>
                            ) : entries.messageType === 'purchaseList' ? (
                              <>
                                <Button
                                  className="py-10 text-14"
                                  onClick={() => jumpToPurchaseList(entries.messageItemUid, entries.messageUid)}
                                >
                                  Zur Liste
                                </Button>
                              </>
                            ) : entries.messageType === 'recipe' ? (
                              <>
                                <Button
                                  className="py-10 text-14"
                                  onClick={() => jumpToSharedRecipe(entries.messageItemUid, entries.messageUid)}
                                >
                                  Zum Rezept
                                </Button>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="absolute right-20 top-15 cursor-pointer">
                            <XIcon
                              width={25}
                              height={25}
                              className="text-accentColor mx-auto"
                              onClick={() =>
                                deleteMessage(entries.messageUid, entries.messageItemUid, entries.messageType)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {userMessagesList.filter(item => item.messageRead === true).length > 0 && (
                  <div className="font-bold pt-20 pb-10">Frühere</div>
                )}
                {userMessagesList
                  .filter(item => item.messageRead === true)
                  .map((entries: any, index: number) => {
                    return (
                      <div key={index}>
                        <div className="rounded-3xl bg-lightGray flex justify-between flex-wrap gap-20 md:mb-40 mb-20 py-40 px-40 relative mr-20">
                          <div className="my-auto pt-10">
                            <div className="absolute left-40 top-15">
                              {entries.messageType === 'plan' ? (
                                <div className="flex gap-10">
                                  <div>
                                    <Icon name="plan" height={22} width={22} className={styles.icon} />
                                  </div>
                                  <div className="font-extralight text-12 my-auto">Plan</div>
                                </div>
                              ) : entries.messageType === 'purchaseList' ? (
                                <div className="flex gap-10">
                                  <div>
                                    <Icon name="shoppingList" height={22} width={22} className={styles.icon} />
                                  </div>
                                  <div className="font-extralight text-12 my-auto">Einkaufsliste</div>
                                </div>
                              ) : entries.messageType === 'recipe' ? (
                                <div className="flex gap-10">
                                  <div>
                                    <Icon name="recipes" height={22} width={22} className={styles.icon} />
                                  </div>
                                  <div className="font-extralight text-12 my-auto">Rezept</div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="text-10 text-grey">{getDateDifference()}</div>
                            {entries.messageType === 'plan' ? (
                              <>
                                <span className="font-bold">{entries.userSenderName}</span> hat dir den Plan{' '}
                                <span className="font-bold">"{entries.messageItemName}"</span> geschickt!
                              </>
                            ) : entries.messageType === 'purchaseList' ? (
                              <>
                                <span className="font-bold">{entries.userSenderName}</span> hat dir eine Einkaufsliste
                                geschickt!
                              </>
                            ) : entries.messageType === 'recipe' ? (
                              <>
                                <span className="font-bold">{entries.userSenderName}</span> hat dir das Rezept{' '}
                                <span className="font-bold">"{entries.messageItemName}"</span> geschickt!
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="pt-10">
                            {entries.messageType === 'plan' ? (
                              <>
                                <Button
                                  className="py-10 text-14"
                                  onClick={() => jumpToSharedPlan(entries.messageItemUid, entries.messageUid)}
                                >
                                  Zum Plan
                                </Button>
                              </>
                            ) : entries.messageType === 'purchaseList' ? (
                              <>
                                <Button
                                  className="py-10 text-14"
                                  onClick={() => jumpToPurchaseList(entries.messageItemUid, entries.messageUid)}
                                >
                                  Zur Liste
                                </Button>
                              </>
                            ) : entries.messageType === 'recipe' ? (
                              <>
                                <Button
                                  className="py-10 text-14"
                                  onClick={() => jumpToSharedRecipe(entries.messageItemUid, entries.messageUid)}
                                >
                                  Zum Rezept
                                </Button>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="absolute right-20 top-15 cursor-pointer">
                            <XIcon
                              width={25}
                              height={25}
                              className="text-accentColor mx-auto"
                              onClick={() =>
                                deleteMessage(entries.messageUid, entries.messageItemUid, entries.messageType)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div className="pt-40 pb-80">Du hast aktuell keine Nachrichten!</div>
            )}
          </>
        )}
      </div>
    </TransitionContainer>
  );
};

export default Messages;
