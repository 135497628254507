import React, { useContext, useState } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import { toast } from 'react-toast';
import CustomUserInput from 'components/CustomUserInput';
import Button from 'components/Button';

type Props = {
  invitationContentClass: string;
  invitationChevronClass: string;
  setFunctionsChevronClass: any;
  setFunctionsContentClass: any;
  setMacroChevronClass: any;
  setMacroContentClass: any;
  setLinkListChevronClass: any;
  setLinkListContentClass: any;
  setLocationsContentClass: any;
  setLocationsChevronClass: any;
  setInvitationChevronClass: any;
  setInvitationContentClass: any;
  setSubject: any;
  setMailText: any;
  setMailFrom: any;
  setMailName: any;
  subject: any;
  mailText: any;
  mailFrom: any;
  mailName: any;
};

const SettingsInvitation: React.FC<Props> = ({
  invitationContentClass,
  setFunctionsChevronClass,
  setMacroChevronClass,
  setMacroContentClass,
  setLinkListChevronClass,
  setFunctionsContentClass,
  invitationChevronClass,
  setLinkListContentClass,
  setLocationsContentClass,
  setLocationsChevronClass,
  setInvitationContentClass,
  setInvitationChevronClass,
  setSubject,
  setMailFrom,
  setMailText,
  setMailName,
  subject,
  mailFrom,
  mailText,
  mailName,
}) => {
  const { tenantData, userData, companyData } = useContext(AuthContext);
  const { t } = useTranslation();

  const [sendTextMailAdress, setSendTextMailAdress] = useState('');

  const [isPending, setIsPending] = useState(false);

  // Toggle Invitation area
  const toggleInvitation = (): void => {
    if (invitationContentClass === 'hidden') {
      setInvitationContentClass('block');
      setInvitationChevronClass('text-accentColor transition duration-300 transform rotate-180');
    } else {
      setInvitationContentClass('hidden');
      setInvitationChevronClass('text-accentColor transition duration-300 transform rotate-360');
    }

    setMacroChevronClass('text-accentColor');
    setMacroContentClass('hidden');

    setFunctionsChevronClass('text-accentColor');
    setFunctionsContentClass('hidden');

    setLocationsChevronClass('text-accentColor');
    setLocationsContentClass('hidden');

    setLinkListChevronClass('text-accentColor');
    setLinkListContentClass('hidden');
  };

  const sendTestMail = async () => {
    setIsPending(true);
    let invitationMailText = `Hallo ${userData?.fullName},\n\ndu wurdest zu der Ernährungssoftware von ${
      companyData?.companyName
    } eingeladen.\n\nBitte klicke auf den folgenden Link, um weitere Informationen zu erhalten:\n${
      window.location.hostname === 'demo.tortija.de'
        ? 'https://whitelabel.tortija.de/demo/'
        : tenantData?.invitationLink !== undefined
        ? tenantData?.invitationLink
        : 'https://whitelabel.tortija.de/einladung/'
    }\n\nViel Spaß mit unserer Ernährungssoftware!`;

    if (mailText.length > 0) {
      invitationMailText = `Hallo ${userData?.fullName},\n\n${mailText}`;
    }

    console.log(mailName);

    await axios({
      url: `${process.env.REACT_APP_API_URL}/sendTextMail`,
      method: 'post',
      data: {
        mailSubject: subject.length > 0 ? subject : `${companyData?.companyName} Einladung`,
        mailTo: sendTextMailAdress.length > 0 ? sendTextMailAdress : userData?.email,
        mailText: invitationMailText,
        mailFrom: mailFrom.length > 0 ? mailFrom : 'info@tortija.de',
        mailName: mailName.length > 0 ? mailName : '',
      },
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    }).then(
      response => {
        console.log(response);
        toast.success('Die Testmail wurde erfolgreich verschickt!');
        setIsPending(false);
      },
      error => {
        toast.error('Es ist leider etwas schief gelaufen!');
        console.log(error);
        setIsPending(false);
      }
    );
  };

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  const changeTestMail = (event: any) => {
    const thisCurrentValue = event.target.value;

    setSendTextMailAdress(thisCurrentValue);
  };

  const changeMailFrom = (event: any) => {
    const thisCurrentValue = event.target.value;

    setMailFrom(thisCurrentValue);
  };

  const changeMailSubject = (event: any) => {
    const thisCurrentValue = event.target.value;

    setSubject(thisCurrentValue);
  };

  const changeMailName = (event: any) => {
    const thisCurrentValue = event.target.value;

    setMailName(thisCurrentValue);
  };

  return (
    <>
      <div className="mt-30 rounded-3xl bg-lightGray p-20">
        <div className="flex space-x-10 cursor-pointer" onClick={() => toggleInvitation()} aria-hidden="true">
          <div className="font-bold text-20">Einladungsmail für Mitglieder</div>
          <div>
            <ChevronDownIcon className={invitationChevronClass} height={30} width={30} />
          </div>
        </div>
        <div className={invitationContentClass}>
          <div className="font-extralight pt-5 pb-20">
            An dieser Stelle können Sie Einstellungen für die Einladungsmail vornehmen, welche beim hinzufügen eines
            neuen Mitglieds automatisch verschickt wird.
          </div>
          <div className="pt-10">
            <div className="my-auto w-full font-bold text-16">Absender Email-Adresse:</div>
            <div className="w-full pt-10">
              <div className="font-light left text-xs border-opacity-30 w-full desktop:w-1/2">
                <CustomUserInput
                  name="email"
                  textCenter={false}
                  thisValue={mailFrom}
                  type="text"
                  placeHolder="E-Mail Adresse"
                  onChange={e => changeMailFrom(e)}
                />
              </div>
              <div className="w-full desktop:w-3/4 pt-5 font-extralight text-13">
                <div>
                  <span className="font-bold">Hinweis:</span> Die E-Mail Adresse muss bei unserem Mailing-Anbieter
                  verifiziert werden.
                </div>
                <div>
                  Bitte kontaktieren Sie uns wenn Sie an dieser Stelle eine eigene E-Mail Adresse verwenden wollen!
                </div>
              </div>
            </div>
          </div>
          <div className="pt-30">
            <div className="my-auto w-full font-bold text-16">Absender Name:</div>
            <div className="w-full pt-10">
              <div className="font-light left text-xs border-opacity-30 w-full desktop:w-1/2">
                <CustomUserInput
                  name="senderName"
                  textCenter={false}
                  thisValue={mailName}
                  type="text"
                  placeHolder="E-Mail Adresse"
                  onChange={e => changeMailName(e)}
                />
              </div>
              <div className="w-full desktop:w-3/4 pt-5 font-extralight text-13">
                <div>
                  <span className="font-bold">Hinweis:</span> Sie können den Namen des Email Absenders an dieser Stelle
                  überschreiben.
                </div>
              </div>
            </div>
          </div>
          <div className="pt-30">
            <div className="my-auto w-full font-bold text-16">Betreff:</div>
            <div className="w-full pt-10">
              <div className="font-light left text-xs border-opacity-30 w-full desktop:w-1/2">
                <CustomUserInput
                  name="subject"
                  textCenter={false}
                  type="text"
                  placeHolder="Betreff"
                  thisValue={subject}
                  onChange={e => changeMailSubject(e)}
                />
              </div>
              <div className="w-full desktop:w-3/4 pt-5 font-extralight text-13">
                <div>
                  <span className="font-bold">Hinweis:</span> Standardmäßig wird folgender Betreff gesetzt:
                </div>
                <div>[Name des Unternehmens] Einladung</div>
                <div className="pt-10">Sie können den Betreff an dieser Stelle überschreiben.</div>
              </div>
            </div>
          </div>
          <div className="pt-30">
            <div className="my-auto w-full font-bold text-16">Text nach persönlicher Ansprache:</div>
            <div className="w-full pt-10">
              <div className="font-light left text-xs border-opacity-30 w-full desktop:w-1/2">
                <textarea
                  defaultValue={mailText ?? ''}
                  className="w-full h-300 text-textColor bg-transparent border-textColor border rounded-20 p-10"
                  onChange={e => setMailText(e.target.value)}
                  onFocus={setBodyClassForMobileSafari}
                  onBlur={removeBodyClassForMobileSafari}
                />
              </div>
              <div className="w-full desktop:w-3/4 pt-5 font-extralight text-13">
                <div>
                  <span className="font-bold">Hinweis:</span> Der Nutzer erhält immer eine persönliche Ansprache mit
                  Angabe seines hinterlegten Namens.
                </div>
                <div>Sie können den Text nach der Ansprache an dieser Stelle überschreiben.</div>
              </div>
            </div>
          </div>
          <div className="pt-30">
            <div className="my-auto w-full font-bold text-16">Testmail verschicken:</div>
            <div className="font-extralight text-13">Schicken Sie eine Testmail mit den Änderungen!</div>
            <div className="w-full pt-10">
              <div className="font-light left text-xs border-opacity-30 w-full desktop:w-1/2">
                <CustomUserInput
                  name="companyName"
                  textCenter={false}
                  type="text"
                  thisValue={sendTextMailAdress.length > 0 ? sendTextMailAdress : userData?.email}
                  placeHolder="E-Mail Adresse Testmail"
                  onChange={e => changeTestMail(e)}
                />
              </div>
              <div className="pt-20">
                <Button onClick={() => sendTestMail()} isPending={isPending} disabled={isPending} className="py-10">
                  Testmail verschicken
                </Button>
              </div>
            </div>
          </div>
          <div className="pt-30">Sie wollen die Einladungsmail komplett individuell in Ihrem Design gestalten?</div>
          <div className="pb-20">Kontaktieren Sie uns in diesem Fall unter info@tortija.de.</div>
        </div>
      </div>
    </>
  );
};

export default SettingsInvitation;
