import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface ModalProps {
  children: React.ReactNode;
  className?: string;
}

const Modal: React.FC<ModalProps> = ({ children, className }) => {
  return (
    <>
      <div className={classNames(styles.modal, className)}>{children}</div>
      <div className={styles.overlay} />
    </>
  );
};

export default Modal;
