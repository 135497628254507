import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Preloader from 'components/Preloader';
import { AuthContext } from 'providers/AuthProvider';
import firebase from 'services/firebase';

export default function PrivateRoute({ component: RouteComponent, path, ...rest }: any) {
  const { authenticated, loadingAuthState, user, tenant, setUserData } = useContext(AuthContext);

  const [unsubscribeFn, setUnsubscribeFn] = useState<(() => void) | null>(null);

  useEffect(() => {
    if (!tenant || !user?.uid || loadingAuthState) {
      return;
    }

    // 🔥 Falls ein alter Listener existiert, wird er zuerst entfernt
    if (unsubscribeFn) {
      unsubscribeFn();
      setUnsubscribeFn(null); // Damit der alte Listener nicht erneut entfernt wird
    }

    const newUnsubscribeFn = firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .doc(user.uid)
      .onSnapshot(doc => {
        if (!doc.exists) {
          firebase.auth().signOut();
        } else {
          setUserData(doc.data() as UserInfo);
        }
      });

    setUnsubscribeFn(() => newUnsubscribeFn);
  }, [user, tenant, loadingAuthState]);

  useEffect(() => {
    return () => {
      if (unsubscribeFn) {
        unsubscribeFn();
      }
    };
  }, []);

  if (loadingAuthState) {
    return <Preloader />;
  }

  return (
    <Route
      {...rest}
      render={routeProps =>
        !authenticated ? (
          <Redirect to={{ pathname: '/auth', state: { prevPath: path } }} />
        ) : (
          <RouteComponent {...routeProps} />
        )
      }
    />
  );
}
