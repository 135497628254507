import React, { useContext } from 'react';
import { Circle } from 'rc-progress';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import { PencilIcon } from '@heroicons/react/24/outline';
import { AuthContext } from 'providers/AuthProvider';
import ReactLoading from 'react-loading';
import { darkenColor, defaultAccentColor, isLight, lightenColor, defaultTextColor } from 'layouts/Theme';
import styles from './style.module.scss';

type Props = {
  customDayWaterGoal: number | undefined;
  waterCounterValue: number;
  updateFunction: any;
  openWaterPopup: any;
  closeEditPopup?: Function;
  isMobile?: boolean;
  waterCountLoading: boolean;
};

const WaterSection: React.FC<Props> = ({
  customDayWaterGoal,
  waterCounterValue = 0,
  updateFunction,
  openWaterPopup,
  closeEditPopup,
  isMobile = false,
  waterCountLoading = false,
}) => {
  const { t } = useTranslation();
  const { userData, theme } = useContext(AuthContext);

  const getWaterGoal = () => {
    if (customDayWaterGoal !== undefined) {
      return Math.round(customDayWaterGoal);
    }
    if (userData !== undefined && userData?.settings?.waterGoal === undefined) {
      return Math.round(35 * parseFloat(userData.bodyWeight));
    }
    if (userData !== undefined && userData?.settings?.waterGoal !== undefined) {
      return Math.round(userData.settings.waterGoal);
    }
    return 0;
  };

  const openEditPopup = () => {
    if (closeEditPopup !== undefined) {
      closeEditPopup('hidden');
    }
    openWaterPopup('block');
  };

  return (
    <>
      <div>
        {isMobile ? (
          <>
            <div className="flex justify-between">
              <div className="my-auto pl-15">
                <div className="font-extralight text-14 my-auto">Dein Ziel:</div>
                <div className="font-semibold text-14 flex gap-10 cursor-pointer">
                  <div className="py-5">{getWaterGoal()} ml</div>
                  <div
                    className="my-auto border-transparent border-2 hover:border-accentColor rounded-xl py-5"
                    onClick={() => openEditPopup()}
                    onKeyDown={() => openEditPopup()}
                    aria-hidden="true"
                  >
                    <PencilIcon width={15} height={15} className="text-accentColor cursor-pointer block" />
                  </div>
                </div>
              </div>
              <div className="relative w-150 mr-15">
                <div className={styles.circleImageMobile}>
                  <Circle
                    className="mx-auto pt-0"
                    percent={(100 * waterCounterValue) / getWaterGoal()}
                    strokeWidth={10}
                    trailWidth={10}
                    trailColor={theme?.bgColor ?? '#3c4044'}
                    strokeLinecap="round"
                    strokeColor={{
                      '100%': theme?.accentColor
                        ? !isLight(theme?.accentColor)
                          ? lightenColor(theme?.bgColor, 40) ?? defaultAccentColor
                          : darkenColor(theme?.bgColor, 40) ?? defaultAccentColor
                        : defaultAccentColor,
                      '0%': theme?.accentColor ?? defaultAccentColor,
                    }}
                  />
                </div>
                <div className="absolute top-0 text-center h-full w-full pt-50">
                  <div>
                    <div className="font-bold text-20">
                      {waterCounterValue !== undefined ? waterCounterValue : '0'} ml
                    </div>
                    <div className="font-extralight text-13 pt-5">getrunken</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-between mx-30">
              <div className="font-extralight text-18 my-auto">Dein Ziel:</div>
              <div className="font-semibold text-18 flex gap-10 cursor-pointer">
                <div className="py-5">{getWaterGoal()} ml</div>
                <div
                  className="my-auto border-transparent border-2 hover:border-accentColor rounded-xl px-5 py-5"
                  onClick={() => openEditPopup()}
                  onKeyDown={() => openEditPopup()}
                  aria-hidden="true"
                >
                  <PencilIcon width={18} height={18} className="text-accentColor cursor-pointer block" />
                </div>
              </div>
            </div>
            <div className="relative">
              <div className={styles.circleImage}>
                <Circle
                  className="mx-auto pt-20"
                  percent={(100 * waterCounterValue) / getWaterGoal()}
                  strokeWidth={10}
                  trailWidth={10}
                  trailColor={theme?.bgColor ?? '#3c4044'}
                  strokeLinecap="round"
                  strokeColor={{
                    '100%': theme?.accentColor
                      ? !isLight(theme?.accentColor)
                        ? lightenColor(theme?.bgColor, 40) ?? defaultAccentColor
                        : darkenColor(theme?.bgColor, 40) ?? defaultAccentColor
                      : defaultAccentColor,
                    '0%': theme?.accentColor ?? defaultAccentColor,
                  }}
                />
              </div>
              <div className="absolute top-0 text-center h-full w-full pt-100">
                <div>
                  <div className="text-13 font-extralightlight pb-5">Bisher</div>
                  <div className="font-bold text-30">
                    {waterCounterValue !== undefined ? waterCounterValue : '0'} ml
                  </div>
                  <div className="font-extralight text-13 pt-5">getrunken</div>
                </div>
              </div>
            </div>
          </>
        )}

        {waterCountLoading ? (
          <div className="flex justify-center items-center mt-20 desktop:mt-60 mb-20 desktop:mb-0">
            <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
          </div>
        ) : (
          <div className="pt-10 md:pt-40 w-full mx-auto">
            <div className="flex justify-center gap-20 md:gap-50">
              <div
                onClick={() => updateFunction(100)}
                onKeyDown={() => updateFunction(100)}
                aria-hidden="true"
                className="text-center border-transparent border-2 hover:border-accentColor rounded-xl px-5 py-5 cursor-pointer"
              >
                <div className="h-35 md:h-40 relative">
                  <div className={styles.waterIcon}>
                    {isMobile ? (
                      <Icon name="waterGlass" height={23} className="text-accentColor cursor-pointer block mx-auto" />
                    ) : (
                      <Icon name="waterGlass" height={26} className="text-accentColor cursor-pointer block mx-auto" />
                    )}
                  </div>
                </div>
                <div className="font-bold text-14 md:text-16 pt-5">100 ml</div>
                <div className="font-extralight text-12 md:text-13">hinzufügen</div>
              </div>
              <div
                onClick={() => updateFunction(250)}
                onKeyDown={() => updateFunction(250)}
                aria-hidden="true"
                className="text-center border-transparent border-2 hover:border-accentColor rounded-xl px-5 py-5 cursor-pointer"
              >
                <div className="h-35 md:h-40 relative">
                  <div className={styles.waterIcon}>
                    {isMobile ? (
                      <Icon name="waterGlass" height={28} className="text-accentColor cursor-pointer block mx-auto" />
                    ) : (
                      <Icon name="waterGlass" height={31} className="text-accentColor cursor-pointer block mx-auto" />
                    )}
                  </div>
                </div>
                <div className="font-bold text-14 md:text-16 pt-5">250 ml</div>
                <div className="font-extralight text-12 md:text-13">hinzufügen</div>
              </div>
              <div
                onClick={() => updateFunction(500)}
                onKeyDown={() => updateFunction(500)}
                aria-hidden="true"
                className="text-center border-transparent border-2 hover:border-accentColor rounded-xl px-5 py-5 cursor-pointer"
              >
                <div className="h-35 md:h-40 relative">
                  <div className={styles.waterIcon}>
                    {isMobile ? (
                      <Icon name="waterGlass" height={35} className="text-accentColor cursor-pointer block mx-auto" />
                    ) : (
                      <Icon name="waterGlass" height={38} className="text-accentColor cursor-pointer block mx-auto" />
                    )}
                  </div>
                </div>
                <div className="font-bold text-14 md:text-16 pt-5">500 ml</div>
                <div className="font-extralight text-12 md:text-13">hinzufügen</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WaterSection;
