import React, { useContext } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

type Props = {
  macroContentClass: string;
  macroChevronClass: string;
  setFunctionsChevronClass: any;
  setFunctionsContentClass: any;
  setMacroChevronClass: any;
  setMacroContentClass: any;
  setLinkListChevronClass: any;
  setLinkListContentClass: any;
  setCurrentMakroChangeCarbohydratesPercent: any;
  setCurrentMakroChangeProteinPercent: any;
  setCurrentMakroChangeFatPercent: any;
  setLocationsContentClass: any;
  setLocationsChevronClass: any;
  setInvitationContentClass: any;
  setInvitationChevronClass: any;
};

const SettingsMacros: React.FC<Props> = ({
  macroContentClass,
  setFunctionsChevronClass,
  setMacroChevronClass,
  setMacroContentClass,
  setLinkListChevronClass,
  setFunctionsContentClass,
  macroChevronClass,
  setLinkListContentClass,
  setCurrentMakroChangeCarbohydratesPercent,
  setCurrentMakroChangeProteinPercent,
  setCurrentMakroChangeFatPercent,
  setLocationsContentClass,
  setLocationsChevronClass,
  setInvitationChevronClass,
  setInvitationContentClass,
}) => {
  const { tenantData, setTenantData } = useContext(AuthContext);

  // Toggle macro area
  const toggleMacroList = (): void => {
    if (macroContentClass === 'hidden') {
      setMacroContentClass('block');
      setMacroChevronClass('text-accentColor transition duration-300 transform rotate-180');
    } else {
      setMacroContentClass('hidden');
      setMacroChevronClass('text-accentColor transition duration-300 transform rotate-360');
    }

    setLinkListChevronClass('text-accentColor');
    setLinkListContentClass('hidden');

    setFunctionsChevronClass('text-accentColor');
    setFunctionsContentClass('hidden');

    setLocationsChevronClass('text-accentColor');
    setLocationsContentClass('hidden');

    setInvitationChevronClass('text-accentColor');
    setInvitationContentClass('hidden');
  };

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  const changeUserCarbohydrates = (event: any) => {
    let thisValue = event.target.value;

    if (thisValue === '') {
      thisValue = 0;
    }

    const newObjectTenantData = {
      ...tenantData,
      settings: {
        ...(tenantData?.settings ?? ''),
        makros: {
          ...(tenantData?.settings?.makros ?? ''),
          carbohydrates_percent: thisValue,
        },
      },
    } as any;

    setTenantData(newObjectTenantData);

    setCurrentMakroChangeCarbohydratesPercent(thisValue);
  };

  const changeUserProtein = (event: any) => {
    let thisValue = event.target.value;

    if (thisValue === '') {
      thisValue = 0;
    }

    const newObjectTenantData = {
      ...tenantData,
      settings: {
        ...(tenantData?.settings ?? ''),
        makros: {
          ...(tenantData?.settings?.makros ?? ''),
          protein_percent: thisValue,
        },
      },
    } as any;

    setTenantData(newObjectTenantData);

    setCurrentMakroChangeProteinPercent(thisValue);
  };

  const changeUserFat = (event: any) => {
    let thisValue = event.target.value;

    if (thisValue === '') {
      thisValue = 0;
    }

    const newObjectTenantData = {
      ...tenantData,
      settings: {
        ...(tenantData?.settings ?? ''),
        makros: {
          ...(tenantData?.settings?.makros ?? ''),
          fat_percent: thisValue,
        },
      },
    } as any;

    setTenantData(newObjectTenantData);

    setCurrentMakroChangeFatPercent(thisValue);
  };

  return (
    <>
      <div className="mt-30 rounded-3xl bg-lightGray p-20">
        <div className="flex space-x-10 cursor-pointer" onClick={() => toggleMacroList()} aria-hidden="true">
          <div className="font-bold text-20">Makronährstoff Verteilung</div>
          <div>
            <ChevronDownIcon className={macroChevronClass} height={30} width={30} />
          </div>
        </div>
        <div className={macroContentClass}>
          <div className="font-extralight pt-5 pb-20">
            Sie können hier die Makronährstoff Verteilung einstellen. Diese Verteilung wird dann bei Ihren Kunden
            berücksichtigt.
          </div>
          <div className="font-bold text-18">Makronährstoff Verteilung</div>
          <div className="font-extralight">Sie können die Verteilung durch Eingabe in den Feldern anpassen.</div>
          <div className="font-extralight pt-5">
            <span className="font-bold">Hinweis:</span> Bei Änderung der Verteilung wird das bei zukünftigen Kunden
            angewendet. Die Verteilung bei bestehenden Kunden wird nicht angepasst und müsste bei Bedarf manuell in den
            Einstellungen des Kunden eingestellt werden.
          </div>
          <div className="font-extralight pt-20">
            <div className="flex space-x-20 desktop:space-x-30 pt-10 pb-20">
              <div>
                <div className="block text-sm font-regular mb-1">Kohlenhydrate</div>
                <div className="w-80 rounded-md border-solid border border-textColor border-opacity-30 py-2 px-2 relative flex  flex-wrap items-stretch">
                  <input
                    id="carbohydrates"
                    name="carbohydrates"
                    type="number"
                    onFocus={setBodyClassForMobileSafari}
                    onBlur={removeBodyClassForMobileSafari}
                    onChange={changeUserCarbohydrates}
                    defaultValue={
                      tenantData?.settings?.makros?.carbohydrates_percent === undefined
                        ? '50'
                        : tenantData?.settings?.makros?.carbohydrates_percent
                    }
                    className="w-40 my-auto appearance-none block text-18 pl-5 font-semibold bg-transparent focus:outline-none bg-opacity-20 text-textColor "
                  />
                  <span className="leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3">
                    %
                  </span>
                </div>
              </div>
              <div>
                <div className="block text-sm font-regular mb-1">Eiweiß</div>
                <div className="w-80 rounded-md border-solid border border-textColor border-opacity-30 py-2 px-2 relative flex  flex-wrap items-stretch">
                  <input
                    id="protein"
                    name="protein"
                    type="number"
                    onFocus={setBodyClassForMobileSafari}
                    onBlur={removeBodyClassForMobileSafari}
                    onChange={changeUserProtein}
                    defaultValue={
                      tenantData?.settings?.makros?.protein_percent === undefined
                        ? '25'
                        : tenantData?.settings?.makros?.protein_percent
                    }
                    className="w-40 my-auto appearance-none block text-18 pl-5 font-semibold bg-transparent focus:outline-none bg-opacity-20 text-textColor "
                  />
                  <span className="leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3">
                    %
                  </span>
                </div>
              </div>
              <div>
                <div className="block text-sm font-regular mb-1">Fett</div>
                <div className="w-80 rounded-md border-solid border border-textColor border-opacity-30 py-2 px-2 relative flex  flex-wrap items-stretch">
                  <input
                    id="fat"
                    name="fat"
                    type="number"
                    onFocus={setBodyClassForMobileSafari}
                    onBlur={removeBodyClassForMobileSafari}
                    onChange={changeUserFat}
                    defaultValue={
                      tenantData?.settings?.makros?.fat_percent === undefined
                        ? '25'
                        : tenantData?.settings?.makros?.fat_percent
                    }
                    className="w-40 my-auto appearance-none block text-18 pl-5 font-semibold bg-transparent focus:outline-none bg-opacity-20 text-textColor "
                  />
                  <span className="leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3">
                    %
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsMacros;
