import React from 'react';
import Button from 'components/Button';

// Typ für den State der ErrorBoundary
interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false }; // Initialisiere den State
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    // Aktualisiere den State bei einem Fehler
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // Optional: Fehler protokollieren
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    const { hasError } = this.state; // Destructuring von state
    const { children } = this.props; // Destructuring von props

    if (hasError) {
      return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <h1 className="font-bold text-30 pt-50">Ups, etwas ist schiefgelaufen!</h1>
          <p className="mt-20">Die angeforderte Seite existiert nicht oder ein Fehler ist aufgetreten.</p>
          <a href="/">
            <Button className="mx-auto mt-40">Zurück zur Startseite</Button>
          </a>
        </div>
      );
    }

    return children; // Rückgabe der Kinder
  }
}

export default ErrorBoundary;
