import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from '@heroicons/react/24/outline';
import Button from 'components/Button';
import { MainContext } from 'providers/MainProvider';
import { AuthContext } from 'providers/AuthProvider';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toast';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { intolerancesOption, formOfNutrition } from 'shared/constants/profile-wizard';
import { savePlanChangesToFirebase } from 'shared/functions/global';
import styles from './style.module.scss';

type Props = {
  itemObject?: any;
  planState?: any;
  planStateValue?: any;
  updateDayId: any;
  updateDayString?: any;
  mealType?: any;
  popupType?: any;
  recipeChangeFunction?: any;
  popupCloseFunction?: any;
  isDashBoard?: boolean;
  setVisibleIncompabilityPopup?: Function;
  recipeId?: number;
  searchQuery?: string;
};

const IngredientSearchPopupItem = ({
  itemObject,
  planState,
  planStateValue,
  updateDayId,
  updateDayString,
  mealType,
  popupType,
  recipeChangeFunction,
  popupCloseFunction,
  isDashBoard,
  setVisibleIncompabilityPopup,
  recipeId,
  searchQuery,
}: Props) => {
  const { t } = useTranslation();
  const { userData, tenant, theme } = useContext(AuthContext);
  const authContext = useContext(AuthContext);

  const { ingredientList } = useContext(MainContext);

  const currentDayItem = planStateValue?.dayEntries.filter((item: any) => item.id === updateDayId)[0];

  const history = useHistory();

  function addRecipeDirect() {
    const thisRecipeObject = {
      ...itemObject,
      id: Math.random().toString(),
      amount: 1,
      piece: 'Portion',
      kcal_total: Math.round(parseFloat(itemObject.kcal_total)),
      carbohydrates_total: Math.round(parseFloat(itemObject.carbohydrates_total)),
      protein_total: Math.round(parseFloat(itemObject.protein_total)),
      fat_total: Math.round(parseFloat(itemObject.fat_total)),
    };

    const newColumns = {
      ...planStateValue,
      dayEntries: planStateValue.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== updateDayId) return entries;
        return {
          ...entries,
          kcal_total: parseFloat(entries.kcal_total) + parseFloat(itemObject.kcal_total),
          carbohydrates_total: parseFloat(entries.carbohydrates_total) + parseFloat(itemObject.carbohydrates_total),
          protein_total: parseFloat(entries.protein_total) + parseFloat(itemObject.protein_total),
          fat_total: parseFloat(entries.fat_total) + parseFloat(itemObject.fat_total),
          [mealType]: {
            ...entries[mealType],
            kcal_total: parseFloat(entries[mealType].kcal_total) + parseFloat(itemObject.kcal_total),
            carbohydrates_total:
              parseFloat(entries[mealType].carbohydrates_total) + parseFloat(itemObject.carbohydrates_total),
            protein_total: parseFloat(entries[mealType].protein_total) + parseFloat(itemObject.protein_total),
            fat_total: parseFloat(entries[mealType].fat_total) + parseFloat(itemObject.fat_total),
            recipes: [thisRecipeObject, ...entries[mealType].recipes],
          },
        };
      }),
    };

    const checkIncompabilityArray = [] as any;
    const checkFormOfNutritionArray = [] as any;
    const thisPlanKcalWithValuesLength = planStateValue.dayEntries.filter((item: any) => item.kcal_total > 0).length;

    intolerancesOption.forEach(function (key) {
      if (typeof thisRecipeObject[key.toLowerCase()] !== undefined && thisRecipeObject[key.toLowerCase()] !== null) {
        if (thisRecipeObject[key.toLowerCase()] === true) {
          checkIncompabilityArray.push({ name: [key.toLowerCase()], status: true });
        }
      }
    });

    formOfNutrition.forEach(function (key) {
      if (typeof thisRecipeObject[key.toLowerCase()] !== undefined && thisRecipeObject[key.toLowerCase()] !== null) {
        if (thisPlanKcalWithValuesLength === 0 && thisRecipeObject[key.toLowerCase()] === true) {
          checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: true });
        } else if (thisRecipeObject[key.toLowerCase()] === false) {
          checkFormOfNutritionArray.push({ name: [key.toLowerCase()], status: false });
        }
      }
    });

    const checkFormOfNutritionArrayMerged = [
      ...checkFormOfNutritionArray.filter((item: any) => item.status === true),
      ...checkFormOfNutritionArray.filter((item: any) => item.status === false),
    ];

    // Copy newColumns to variable because this needs to be updated in the following sections
    let thisRecipeData = { ...newColumns };

    // Set elements true if incompatibility exists
    for (let g = 0; g < checkIncompabilityArray.length; g += 1) {
      thisRecipeData = { ...thisRecipeData, [checkIncompabilityArray[g].name]: true };
    }

    // Set elements true if form of nutrition exists
    for (let g = 0; g < checkFormOfNutritionArrayMerged.length; g += 1) {
      thisRecipeData = {
        ...thisRecipeData,
        [checkFormOfNutritionArrayMerged[g].name]: checkFormOfNutritionArrayMerged[g].status,
      };
    }

    popupCloseFunction('hidden');
    toast.success('Das Rezept wurde erfolgreich hinzugefügt!');

    if (setVisibleIncompabilityPopup !== undefined) {
      if (userData?.incompatibilityAccept === undefined && userData?.age !== undefined) {
        setVisibleIncompabilityPopup(true);
      }
    }

    savePlanChangesToFirebase(
      userData,
      thisRecipeData,
      thisRecipeData.uid,
      authContext.user?.uid,
      isDashBoard,
      planState,
      tenant
    );
    // planState(thisRecipeData);
  }

  return (
    <>
      <div className="flex mb-20">
        <div className="flex-1">
          <div
            onClick={() =>
              history.push({
                pathname: `/nutrition/recipes/${itemObject.uid}`,
                state: {
                  from: 'planEdit',
                  planId: planStateValue.uid,
                  planDayId: updateDayId,
                  mealLabel: mealType,
                  planDateString: updateDayString,
                  planKcalTotal: currentDayItem.kcal_total,
                  planCarbohydratesTotal: currentDayItem.carbohydrates_total,
                  planProteinTotal: currentDayItem.protein_total,
                  planFatTotal: currentDayItem.fat_total,
                  planCustomKcalTotal: currentDayItem?.custom_kcal_total
                    ? currentDayItem?.custom_kcal_total
                    : planStateValue?.custom_kcal_total
                    ? planStateValue?.custom_kcal_total
                    : undefined,
                  planCustomCarbohydratesTotal: currentDayItem?.custom_carbohydrates_total
                    ? currentDayItem?.custom_carbohydrates_total
                    : planStateValue?.custom_carbohydrates_total
                    ? planStateValue?.custom_carbohydrates_total
                    : undefined,
                  planCustomProteinTotal: currentDayItem?.custom_protein_total
                    ? currentDayItem?.custom_protein_total
                    : planStateValue?.custom_protein_total
                    ? planStateValue?.custom_protein_total
                    : undefined,
                  planCustomFatTotal: currentDayItem?.custom_fat_total
                    ? currentDayItem?.custom_fat_total
                    : planStateValue?.custom_fat_total
                    ? planStateValue?.custom_fat_total
                    : undefined,
                  isDashBoard,
                  scrollPos: recipeId,
                  uid: planStateValue.uid,
                  id: updateDayId,
                  recipeUid: itemObject.uid,
                  searchQuery,
                },
              })
            }
            onMouseDown={() =>
              history.push({
                pathname: `/nutrition/recipes/${itemObject.uid}`,
                state: {
                  from: 'planEdit',
                  planId: planStateValue.uid,
                  planDayId: updateDayId,
                  mealLabel: mealType,
                  planDateString: updateDayString,
                  planKcalTotal: currentDayItem.kcal_total,
                  planCarbohydratesTotal: currentDayItem.carbohydrates_total,
                  planProteinTotal: currentDayItem.protein_total,
                  planFatTotal: currentDayItem.fat_total,
                  planCustomKcalTotal: currentDayItem?.custom_kcal_total
                    ? currentDayItem?.custom_kcal_total
                    : planStateValue?.custom_kcal_total
                    ? planStateValue?.custom_kcal_total
                    : undefined,
                  planCustomCarbohydratesTotal: currentDayItem?.custom_carbohydrates_total
                    ? currentDayItem?.custom_carbohydrates_total
                    : planStateValue?.custom_carbohydrates_total
                    ? planStateValue?.custom_carbohydrates_total
                    : undefined,
                  planCustomProteinTotal: currentDayItem?.custom_protein_total
                    ? currentDayItem?.custom_protein_total
                    : planStateValue?.custom_protein_total
                    ? planStateValue?.custom_protein_total
                    : undefined,
                  planCustomFatTotal: currentDayItem?.custom_fat_total
                    ? currentDayItem?.custom_fat_total
                    : planStateValue?.custom_fat_total
                    ? planStateValue?.custom_fat_total
                    : undefined,
                  isDashBoard,
                  scrollPos: recipeId,
                  uid: planStateValue.uid,
                  id: updateDayId,
                  recipeUid: itemObject.uid,
                  searchQuery,
                },
              })
            }
            aria-hidden="true"
          >
            <div className={styles.contentItem}>
              <div className={styles.imageWrapper}>
                <LazyLoadImage
                  src={
                    itemObject.imageUrl
                      ? itemObject.imageUrl
                      : theme?.logo !== undefined && userData?.role !== 2
                      ? theme.logo
                      : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
                  }
                  alt=""
                  width={45}
                  height={45}
                  className={styles.image}
                />
              </div>
              <div className={styles.description}>
                <div className="pr-5">
                  <div className={styles.itemTitle} title={itemObject.name}>
                    {itemObject.name}
                  </div>
                </div>
                <div className={styles.category}>
                  <div className={styles.item}>
                    <div className="text-12">{Math.round(parseFloat(itemObject.kcal_total))}</div>
                    <div>kcal</div>
                  </div>
                  <div className={styles.itemSecondary}>
                    <div className="text-12">{Math.round(parseFloat(itemObject.carbohydrates_total))}</div>
                    <div>KH</div>
                  </div>
                  <div className={styles.itemSecondary}>
                    <div className="text-12">{Math.round(parseFloat(itemObject.protein_total))}</div>
                    <div>EW</div>
                  </div>
                  <div className={styles.itemSecondary}>
                    <div className="text-12">{Math.round(parseFloat(itemObject.fat_total))}</div>
                    <div>Fett</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {popupType === 'recipeChange' && (
            <div className="pt-5">
              <Button onClick={() => recipeChangeFunction(itemObject)} className="text-12 py-1">
                Mit diesem Rezept austauschen
              </Button>
            </div>
          )}
        </div>

        {popupType === 'plan' && (
          <div className="pl-5 pt-15 my-auto">
            <PlusIcon
              width={25}
              height={25}
              className="text-accentColor cursor-pointer"
              onClick={() => addRecipeDirect()}
              onKeyDown={() => addRecipeDirect()}
              aria-hidden="true"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default IngredientSearchPopupItem;
