import React, { useContext } from 'react';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';

import Headline from 'components/Headline';
import TransitionContainer from 'components/TransitionContainer';
import { AuthContext } from 'providers/AuthProvider';
import firebase from 'services/firebase';
import Button from 'components/Button';

export default function UserConsentForChat() {
  const { user, tenant } = useContext(AuthContext);
  const db = firebase.firestore();

  return (
    <TransitionContainer isShown className="h-full">
      <div className="flex items-center pt-40 font-light">
        <Headline level={1}>Chat</Headline>
      </div>
      <div className="flex flex-col gap-20">
        <p className="text-textColor">
          Ich bin damit einverstanden, dass mich neben meinem Trainer auch andere Trainer kontaktieren können.
        </p>
        <div className="flex gap-20">
          <Button
            type="button"
            buttonStyle="primary"
            onClick={() => {
              db.collection(`tenants/${tenant}/users`).doc(user?.uid).update({
                shareChats: true,
              });
            }}
          >
            <CheckCircleIcon className="w-20 h-20" /> Ja
          </Button>
          <Button
            type="button"
            buttonStyle="dark"
            onClick={() => {
              db.collection(`tenants/${tenant}/users`).doc(user?.uid).update({
                shareChats: false,
              });
            }}
          >
            <XMarkIcon className="w-20 h-20" /> Nein
          </Button>
        </div>
      </div>
    </TransitionContainer>
  );
}
