import React, { useMemo, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CustomInput from 'components/Input/custom-input';
import CustomSelect from 'components/CustomSelect';
import { AuthContext } from 'providers/AuthProvider';
import { toast } from 'react-toast';
import firebase from 'services/firebase';
import ReactLoading from 'react-loading';
import { defaultTextColor } from 'layouts/Theme';
import { XMarkIcon as XIcon, CheckIcon } from '@heroicons/react/24/outline';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid';
import { countryOption } from 'shared/constants/profile-wizard';

type Props = {
  userData: UserInfo;
  changeUserDataFunction: Function;
};

const AnamneseCustomInformation: React.FC<Props> = ({ userData, changeUserDataFunction }) => {
  const { t } = useTranslation();

  const { tenantData, tenant, setTenantData, theme } = useContext(AuthContext);

  const [newCustomFieldLoading, setNewCustomFieldLoading] = useState(false);

  const [deleteCustomFieldLoading, setDeleteCustomFieldLoading] = useState(false);

  const [newCustomFieldVisible, setNewCustomFieldVisible] = useState(false);
  const [newCustomFieldName, setNewCustomFieldName] = useState('');

  const countryChange = (value: any) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({
      ...prevProfile,
      country: value.value,
    }));
  };

  const customerIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({
      ...prevProfile,
      customProperties: {
        ...prevProfile.customProperties,
        customerId: event.target.value,
      },
    }));
  };

  const telephoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({
      ...prevProfile,
      customProperties: {
        ...prevProfile.customProperties,
        telephoneNumber: event.target.value,
      },
    }));
  };

  const mobileNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({
      ...prevProfile,
      customProperties: {
        ...prevProfile.customProperties,
        mobileNumber: event.target.value,
      },
    }));
  };

  const streetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({
      ...prevProfile,
      customProperties: {
        ...prevProfile.customProperties,
        street: event.target.value,
      },
    }));
  };

  const postCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({
      ...prevProfile,
      customProperties: {
        ...prevProfile.customProperties,
        postCode: event.target.value,
      },
    }));
  };

  const cityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({
      ...prevProfile,
      customProperties: {
        ...prevProfile.customProperties,
        city: event.target.value,
      },
    }));
  };

  const changeNewConfigField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewCustomFieldName(event.target.value);
  };

  const customFieldChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string, fieldId: number) => {
    const newValue = event.target.value;

    changeUserDataFunction((prevProfile: UserInfo) => {
      const existingFields = prevProfile.customConfigurationFields || []; // Falls undefined, leeres Array setzen

      // Prüfen, ob das Feld bereits existiert
      const fieldExists = existingFields.some(field => field.name === fieldName);

      return {
        ...prevProfile,
        customConfigurationFields: fieldExists
          ? existingFields.map(field => (field.name === fieldName ? { ...field, value: newValue } : field))
          : [...existingFields, { name: fieldName, value: newValue, id: fieldId }], // Neues Feld hinzufügen
      };
    });
  };

  const birthDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({
      ...prevProfile,
      customProperties: {
        ...prevProfile.customProperties,
        birthDay: event.target.value,
      },
    }));
  };

  const birthDayDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({
      ...prevProfile,
      customProperties: {
        ...prevProfile.customProperties,
        birthDay: {
          ...prevProfile.customProperties?.birthDay,
          day: event.target.value,
        },
      },
    }));
  };

  const birthDayMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({
      ...prevProfile,
      customProperties: {
        ...prevProfile.customProperties,
        birthDay: {
          ...prevProfile.customProperties?.birthDay,
          month: event.target.value,
        },
      },
    }));
  };

  const birthDayYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({
      ...prevProfile,
      customProperties: {
        ...prevProfile.customProperties,
        birthDay: {
          ...prevProfile.customProperties?.birthDay,
          year: event.target.value,
        },
      },
    }));
  };

  const startNewConfigValue = () => {
    setNewCustomFieldName('');
    setNewCustomFieldVisible(true);
  };

  const deleteConfigField = async (fieldId: number) => {
    setDeleteCustomFieldLoading(true);

    try {
      const tenantRef = firebase.firestore().collection('tenants').doc(tenant);

      // Sicherstellen, dass tenantData.customConfigurationFields existiert
      const updatedFields = tenantData?.customConfigurationFields?.filter(item => item.id !== fieldId) || [];

      await tenantRef.update({
        customConfigurationFields: updatedFields,
      });

      // State aktualisieren
      setTenantData((prev: any) => ({
        ...prev,
        customConfigurationFields: updatedFields,
      }));

      setDeleteCustomFieldLoading(false);
    } catch (error: any) {
      toast.error(
        'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
      );
      setDeleteCustomFieldLoading(false);
    }
  };

  const saveNewConfigField = async () => {
    setNewCustomFieldLoading(true);

    if (tenantData?.customConfigurationFields && tenantData?.customConfigurationFields.length > 0) {
      const checkNameExists = tenantData?.customConfigurationFields?.filter(item => item.name === newCustomFieldName);

      if (checkNameExists.length > 0) {
        toast.error('Dieses Feld existiert schon!');
        setNewCustomFieldLoading(false);
        return;
      }
    }

    if (newCustomFieldName.length === 0) {
      toast.error('Bitte geben Sie einen Namen für das neue Feld ein!');
      setNewCustomFieldLoading(false);
      return;
    }

    const newColumn = {
      name: newCustomFieldName,
      id: Math.random(),
    };

    try {
      // Sicherstellen, dass customConfigurationFields existiert
      const updatedCustomConfigurationFields: { name: string; id: number }[] = [
        ...(tenantData?.customConfigurationFields ?? []),
        newColumn,
      ];

      // Firestore aktualisieren
      const tenantRef = firebase.firestore().collection('tenants').doc(tenant);
      await tenantRef.update({
        customConfigurationFields: updatedCustomConfigurationFields,
      });

      // State aktualisieren
      setTenantData((prev: any) => ({
        ...prev,
        customConfigurationFields: updatedCustomConfigurationFields,
      }));

      setNewCustomFieldLoading(false);
      setNewCustomFieldVisible(false);
    } catch (error: any) {
      toast.error(
        'Es ist leider etwas schief gelaufen. Bitte probieren Sie es später nochmal oder wenden Sie sich an uns!'
      );
      setNewCustomFieldLoading(false);
    }
  };

  const resetCustomConfigFields = () => {
    setNewCustomFieldVisible(false);
  };

  const defaultCountryValue = useMemo(() => {
    if (!userData?.country) {
      return countryOption[0]; // Gibt das erste Element zurück, wenn `userData?.country` nicht existiert
    }
    return countryOption.find((item: any) => item.value === userData.country);
  }, [userData?.country, countryOption]);

  return (
    <div className="flex-grow">
      <div>
        <CustomSelect
          name="gender"
          dropDownOption={countryOption}
          defaultValue={defaultCountryValue}
          onChange={countryChange}
          label="Land / Lebensmitteldatenbank"
          isSearchable
          className="w-full border border-textColor"
        />
        <CustomInput
          name="customerid"
          type="text"
          label="Kundennummer"
          onChange={customerIdChange}
          className="mt-15"
          defaultValue={userData.customProperties?.customerId ?? ''}
          inputClassName="border border-textColor"
        />
        <div className="mt-15 block text-sm font-regular text-textColor opacity-50 mb-1">Geburtsdatum</div>
        <div className="flex gap-10">
          <CustomInput
            name="birthDayMonth"
            type="number"
            placeholder="DD"
            label="Tag"
            onChange={birthDayDayChange}
            defaultValue={userData.customProperties?.birthDay?.day?.toString() ?? ''}
            inputClassName="border border-textColor"
          />
          <CustomInput
            name="birthDayYear"
            type="number"
            placeholder="MM"
            label="Monat"
            onChange={birthDayMonthChange}
            defaultValue={userData.customProperties?.birthDay?.month?.toString() ?? ''}
            inputClassName="border border-textColor"
          />
          <CustomInput
            name="birthDayDay"
            type="number"
            placeholder="YYYY"
            label="Jahr"
            onChange={birthDayYearChange}
            defaultValue={userData.customProperties?.birthDay?.year?.toString() ?? ''}
            inputClassName="border border-textColor"
          />
        </div>

        <CustomInput
          name="telephone"
          type="text"
          label="Telefonnummer"
          onChange={telephoneChange}
          defaultValue={userData.customProperties?.telephoneNumber ?? ''}
          className="mt-15"
          inputClassName="border border-textColor"
        />
        <CustomInput
          name="mobileNumber"
          type="text"
          label="Mobilnummer"
          onChange={mobileNumberChange}
          defaultValue={userData.customProperties?.mobileNumber ?? ''}
          className="mt-15"
          inputClassName="border border-textColor"
        />
        <CustomInput
          name="street"
          type="text"
          label="Straße"
          onChange={streetChange}
          defaultValue={userData.customProperties?.street ?? ''}
          className="mt-15"
          inputClassName="border border-textColor"
        />
        <CustomInput
          name="plz"
          type="text"
          label="Postleitzahl"
          onChange={postCodeChange}
          defaultValue={userData.customProperties?.postCode ?? ''}
          className="mt-15"
          inputClassName="border border-textColor"
        />
        <CustomInput
          name="city"
          type="text"
          label="Stadt"
          onChange={cityChange}
          defaultValue={userData.customProperties?.city ?? ''}
          className="mt-15"
          inputClassName="border border-textColor"
        />
        <div className="py-20">
          <div className="font-bold">Eigene Eigenschaftsfelder</div>
          {newCustomFieldVisible ? (
            <div className="relative">
              <div>
                <CustomInput
                  name="city"
                  type="text"
                  label="Name des Feldes"
                  onChange={changeNewConfigField}
                  className="mt-15"
                  inputClassName="border border-textColor"
                />
              </div>
              <div className="absolute right-0 top-75">
                {newCustomFieldLoading ? (
                  <div>
                    <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
                  </div>
                ) : (
                  <div className="bg-bgColor p-1 flex gap-1">
                    <div className="bg-lightGrayDarker">
                      <CheckIcon
                        width={22}
                        height={22}
                        className="text-buttonTextColor cursor-pointer"
                        onClick={() => saveNewConfigField()}
                      />
                    </div>
                    <div className="bg-lightGrayDarker">
                      <XIcon
                        width={22}
                        height={22}
                        className="text-buttonTextColor cursor-pointer"
                        onClick={() => resetCustomConfigFields()}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div className="flex flex-wrap gap-10">
                <div className="my-auto">
                  <div>Sie können an dieser Stelle eigene Eigenschaftsfelder anlegen.</div>
                  <div>Neu angelegte Felder sind direkt bei allen Kunden sichtbar.</div>
                </div>

                <div className="my-auto">
                  <PlusCircleIcon
                    width={30}
                    height={30}
                    className="text-accentColor cursor-pointer"
                    onClick={() => startNewConfigValue()}
                  />
                </div>
              </div>
              <div>
                {tenantData?.customConfigurationFields?.map((item, index) => (
                  <div className="flex gap-10 mt-15" key={index}>
                    <CustomInput
                      name={item.name.toLowerCase()}
                      type="text"
                      label={item.name}
                      onChange={event => customFieldChange(event, item.name, item.id)}
                      defaultValue={
                        userData?.customConfigurationFields?.filter(userItem => item.name === userItem.name)[0]
                          ?.value ?? ''
                      }
                      className="flex-1"
                      inputClassName="border border-textColor"
                    />
                    {deleteCustomFieldLoading ? (
                      <div className="mt-40">
                        <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
                      </div>
                    ) : (
                      <TrashIcon
                        width={30}
                        height={30}
                        className="text-accentColor cursor-pointer mt-30"
                        onClick={() => deleteConfigField(item.id)}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnamneseCustomInformation;
