import React, { useContext, useState, useEffect, Fragment } from 'react';
import firebase from 'services/firebase';
import classNames from 'classnames';
import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toast';
import ReactLoading from 'react-loading';
import { BellIcon, UserCircleIcon, CheckCircleIcon, XMarkIcon as XIcon } from '@heroicons/react/24/outline';

import LogoImg from 'assets/img/svg/logo.svg';
import { AuthContext } from 'providers/AuthProvider';
import { MainContext } from 'providers/MainProvider';
import { defaultTextColor } from 'layouts/Theme';
import {
  companyAdminNavLinkList,
  navLinkList,
  companyTrainerNavLinkList,
  companyLocationAdminNavLinkList,
  ROLE_ADMIN,
  ROLE_COMPANY_ADMIN,
  ROLE_TRAINER,
  ROLE_USER,
  ROLE_MEMBER,
  ROLE_LOCATION_ADMIN,
} from 'shared/constants/global';
import { getUserSurveys, getUserSurveysWithIterations } from 'shared/functions/surveyFunctions';
import { changeUserView } from 'shared/functions/global';
import Icon from '../Icon';

import styles from './style.module.scss';

const Sidebar: React.FC = () => {
  const { t } = useTranslation();
  const { userData, theme, tenant, user, tenantData, showMobileNav } = useContext(AuthContext);
  const [isAdminNavi, setIsAdminNavi] = useState(false);
  const [isUserSwitch, setIsUserSwitch] = useState(false);
  const [changeSwitchLoading, setChangeSwitchLoading] = useState(false);
  const { userMessagesList } = useContext(MainContext);
  const [unansweredCount, setUnansweredCount] = useState(0);
  const [chatUnreadCount, setChatUnreadCount] = useState(0);
  const logo = theme?.logo ?? LogoImg;
  const authContext = useContext(AuthContext);

  const history = useHistory();

  const changeUserViewExecute = async () => {
    setChangeSwitchLoading(true);
    if (userData?.role === 5 && (userData?.isAdminUser || userData?.isTrainerUser || userData?.isLocationAdminUser)) {
      const updateUser = await changeUserView(
        userData,
        authContext.user?.uid,
        tenant,
        userData?.isAdminUser ? 3 : userData?.isLocationAdminUser ? 6 : 4
      );

      if (updateUser.status === 'success') {
        authContext.setUserData((prevProfile: UserInfo) => ({
          ...prevProfile,
          role: userData?.isAdminUser ? 3 : userData?.isLocationAdminUser ? 6 : 4,
        }));

        authContext.setOriginalUserData((prevProfile: UserInfo) => ({
          ...prevProfile,
          role: userData?.isAdminUser ? 3 : userData?.isLocationAdminUser ? 6 : 4,
        }));

        setIsUserSwitch(false);
        setChangeSwitchLoading(false);
        history.push('/');
      } else {
        setChangeSwitchLoading(false);
        toast.error('Es ist leider etwas schiefgelaufen! Bitte versuche es nochmal oder kontaktiere uns!');
      }
    } else if (userData?.isAdminUser || userData?.role === 4 || userData?.role === 6) {
      const updateUser = await changeUserView(userData, authContext.user?.uid, tenant, 5);

      if (updateUser.status === 'success') {
        authContext.setUserData((prevProfile: UserInfo) => ({
          ...prevProfile,
          role: 5,
        }));

        authContext.setOriginalUserData((prevProfile: UserInfo) => ({
          ...prevProfile,
          role: 5,
        }));

        setIsUserSwitch(false);
        setChangeSwitchLoading(false);
        history.push('/');
      } else {
        setChangeSwitchLoading(false);
        toast.error('Es ist leider etwas schiefgelaufen! Bitte versuche es nochmal oder kontaktiere uns!');
      }
    }
  };

  useEffect(() => {
    if (!user?.uid || !tenant) return;

    const fetchUnansweredCount = async () => {
      const userRef = firebase.firestore().collection(`tenants/${tenant}/users`).doc(user.uid);
      const surveys = await getUserSurveys(userRef, tenant);
      const surveysWithIterations = getUserSurveysWithIterations(surveys, user?.uid);
      setUnansweredCount(surveysWithIterations.filter(survey => !survey.isViewOnly).length);
    };

    const fetchChatUnreadCount = async () => {
      const chatRef = await firebase
        .firestore()
        .collection(`tenants/${tenant}/chats`)
        .where('users', 'array-contains', user?.uid)
        .get();
      const unreadCount = await Promise.all(
        chatRef.docs.map(async doc => {
          const messages = await firebase
            .firestore()
            .collection(`tenants/${tenant}/chats/${doc.id}/messages`)
            .where('read', '==', false)
            .get();
          return messages.docs.map(docMessage => docMessage.data()).filter(message => message.userId !== user?.uid)
            .length;
        })
      );
      setChatUnreadCount(unreadCount.reduce((acc, curr) => acc + curr, 0));
    };

    fetchUnansweredCount();
    fetchChatUnreadCount();
  }, [user, tenant]);

  return (
    <div className={styles.sidebar}>
      <div className={classNames(styles.navContainer, 'custom-scrollbar')}>
        <div className="w-full desktop:space-y-70 ">
          <img className="hidden mx-auto w-120 desktop:block" width={160} height={140} src={logo} alt="Logo" />
          {userData?.role === 1 && (
            <button type="button" className={styles.adminBtn} onClick={() => setIsAdminNavi(!isAdminNavi)}>
              {t('Admin')}
            </button>
          )}

          {(userData?.isAdminUser || userData?.isTrainerUser || userData?.isLocationAdminUser) && (
            <>
              {changeSwitchLoading ? (
                <div className="w-full flex justify-center">
                  <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
                </div>
              ) : (
                <div className={styles.changeBtn} onClick={() => setIsUserSwitch(true)} aria-hidden="true">
                  <div className="hidden desktop:flex space-x-5">
                    <UserCircleIcon width={20} height={20} className="text-textColor cursor-pointer" />
                    <button className="outline-none" type="button">
                      {userData.role === 3
                        ? 'Admin'
                        : userData?.role === 6
                        ? 'Leiter'
                        : userData?.role === 4
                        ? 'Trainer'
                        : 'Nutzer'}
                    </button>
                  </div>
                </div>
              )}
            </>
          )}

          {isUserSwitch && (
            <div className={styles.userSwitch}>
              <div className="relative p-25">
                <div className="absolute right-10 top-15">
                  <XIcon
                    width={20}
                    height={20}
                    className="mr-5 my-auto text-accentColor cursor-pointer"
                    onClick={() => setIsUserSwitch(false)}
                  />
                </div>
                {userData?.role === 3 || userData?.role === 4 || userData?.role === 6 ? (
                  <div
                    className="flex space-x-5 pb-15 pt-15 cursor-pointer"
                    onClick={() => changeUserViewExecute()}
                    aria-hidden
                  >
                    <CheckCircleIcon width={25} height={25} className="mr-5 my-auto text-accentColor" />
                    <div className="my-auto">
                      {userData?.role === 4 || userData?.isTrainerUser
                        ? 'Trainer'
                        : userData?.role === 6 || userData?.isLocationAdminUser
                        ? 'Leiter'
                        : 'Admin'}
                    </div>
                  </div>
                ) : (
                  <div
                    className="flex space-x-5 pb-15 pt-15 cursor-pointer"
                    onClick={() => changeUserViewExecute()}
                    aria-hidden
                  >
                    <div className="rounded-full w-20 h-20 border-2 border-accentColor ml-1 mr-5 my-auto">
                      <></>
                    </div>
                    <div className="my-auto">
                      {userData?.role === 4 || userData?.isTrainerUser
                        ? 'Trainer'
                        : userData?.role === 6 || userData?.isLocationAdminUser
                        ? 'Leiter'
                        : 'Admin'}
                    </div>
                  </div>
                )}

                {userData?.role !== 3 && userData?.role !== 4 && userData?.role !== 6 ? (
                  <div className="flex space-x-5 cursor-pointer" onClick={() => changeUserViewExecute()} aria-hidden>
                    <CheckCircleIcon width={25} height={25} className="mr-5 my-auto text-accentColor" />
                    <div className="my-auto">Nutzer</div>
                  </div>
                ) : (
                  <div className="flex space-x-5 cursor-pointer" onClick={() => changeUserViewExecute()} aria-hidden>
                    <div className="rounded-full w-20 h-20 border-2 border-accentColor ml-1 mr-5 my-auto">
                      <></>
                    </div>
                    <div className="my-auto">Nutzer</div>
                  </div>
                )}
              </div>
            </div>
          )}

          <nav className={classNames(styles.nav, { 'hidden desktop:block': !showMobileNav })} data-pdf-navi>
            {/* User */}
            {(userData?.role === ROLE_ADMIN || userData?.role === ROLE_USER || userData?.role === ROLE_MEMBER) && (
              <>
                {!isAdminNavi ? (
                  <>
                    {navLinkList.map((item, index) => (
                      <Fragment key={index}>
                        {item.label === 'Umfragen' &&
                        (tenantData?.features?.surveys === undefined || tenantData?.features?.surveys === false) ? (
                          <></>
                        ) : item.label === 'Chat' &&
                          (tenantData?.features?.chat === undefined || tenantData?.features?.chat === false) ? (
                          <></>
                        ) : (
                          <NavLink
                            to={item.link}
                            exact={index === 0}
                            className={styles.link}
                            activeClassName={styles.selected}
                            key={index}
                          >
                            <Icon name={item.icon} height={22} width={22} className={styles.icon} />
                            {t(item.label) === 'Profil' && (
                              <>
                                {userMessagesList !== undefined && (
                                  <>
                                    {userMessagesList.filter(messageItem => messageItem.messageRead === false).length >
                                      0 && (
                                      <span className="desktop:hidden absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-10 font-normal leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                                        {
                                          userMessagesList.filter(messageItem => messageItem.messageRead === false)
                                            .length
                                        }
                                      </span>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {item.label === 'Umfragen' && (
                              <>
                                {unansweredCount > 0 && (
                                  <span className="desktop:hidden absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-10 font-normal leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                                    {unansweredCount}
                                  </span>
                                )}
                              </>
                            )}
                            <span className={styles.label}>{t(item.label)}</span>

                            {t(item.label) === 'Profil' && (
                              <div className="absolute top-20 right-30 hidden desktop:block">
                                <span className="relative inline-block">
                                  {userMessagesList !== undefined && (
                                    <>
                                      {userMessagesList.filter(messageItem => messageItem.messageRead === false)
                                        .length > 0 ? (
                                        <>
                                          <BellIcon
                                            width={20}
                                            height={20}
                                            className="text-textColor cursor-pointer animate-swing"
                                          />
                                          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-10 font-normal leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                                            {
                                              userMessagesList.filter(messageItem => messageItem.messageRead === false)
                                                .length
                                            }
                                          </span>
                                        </>
                                      ) : (
                                        <BellIcon width={20} height={20} className="text-textColor cursor-pointer" />
                                      )}
                                    </>
                                  )}
                                </span>
                              </div>
                            )}
                            {t(item.label) === 'Umfragen' && (
                              <div className="absolute top-20 right-30 hidden desktop:block">
                                {unansweredCount > 0 && (
                                  <span className="relative inline-block">
                                    <BellIcon
                                      width={20}
                                      height={20}
                                      className="text-textColor cursor-pointer animate-swing"
                                    />
                                    <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-10 font-normal leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                                      {unansweredCount}
                                    </span>
                                  </span>
                                )}
                              </div>
                            )}
                            {t(item.label) === 'Chat' && (
                              <>
                                {chatUnreadCount > 0 && (
                                  <>
                                    <div className="absolute top-20 right-30 hidden desktop:block">
                                      <span className="relative inline-block">
                                        <BellIcon
                                          width={20}
                                          height={20}
                                          className="text-textColor cursor-pointer animate-swing"
                                        />
                                        <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-10 font-normal leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                                          {chatUnreadCount}
                                        </span>
                                      </span>
                                    </div>
                                    <span className="desktop:hidden absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-10 font-normal leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                                      {chatUnreadCount}
                                    </span>
                                  </>
                                )}
                              </>
                            )}
                          </NavLink>
                        )}
                      </Fragment>
                    ))}
                  </>
                ) : (
                  <>
                    {userData?.role === ROLE_ADMIN && (
                      <>
                        <div className={styles.adminLinkWrapper}>
                          <NavLink to="/admin/gym" className={styles.link} activeClassName={styles.selected}>
                            <span className={styles.label}>{t('Gym')}</span>
                          </NavLink>
                        </div>
                        <div className={styles.adminLinkWrapper}>
                          <NavLink to="/admin/coupons" className={styles.link} activeClassName={styles.selected}>
                            <span className={styles.label}>Rabattcodes</span>
                          </NavLink>
                        </div>
                        <div className={styles.adminLinkWrapper}>
                          <NavLink to="/admin/entrycodes" className={styles.link} activeClassName={styles.selected}>
                            <span className={styles.label}>Zugangscodes</span>
                          </NavLink>
                        </div>
                        <div className={styles.adminLinkWrapper}>
                          <NavLink to="/admin/b2boverview" className={styles.link} activeClassName={styles.selected}>
                            <span className={styles.label}>B2B Übersicht</span>
                          </NavLink>
                        </div>
                        <div className={styles.adminLinkWrapper}>
                          <NavLink to="/admin/b2coverview" className={styles.link} activeClassName={styles.selected}>
                            <span className={styles.label}>B2C Übersicht</span>
                          </NavLink>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {/* Company Admin */}
            {userData?.role === ROLE_COMPANY_ADMIN && (
              <>
                {companyAdminNavLinkList.map((item, index) => (
                  <NavLink
                    to={item.link}
                    exact={index === 0}
                    className={styles.link}
                    activeClassName={styles.selected}
                    key={index}
                  >
                    <Icon name={item.icon} height={22} width={22} className={styles.icon} />
                    <span className={styles.label}>{t(item.label)}</span>
                  </NavLink>
                ))}
              </>
            )}
            {/* Trainer */}
            {userData?.role === ROLE_TRAINER && (
              <>
                {companyTrainerNavLinkList.map((item, index) => (
                  <Fragment key={index}>
                    {item.label === 'Umfragen' &&
                    (tenantData?.features?.surveys === undefined || tenantData?.features?.surveys === false) ? (
                      <></>
                    ) : item.label === 'Chat' &&
                      (tenantData?.features?.chat === undefined || tenantData?.features?.chat === false) ? (
                      <></>
                    ) : (
                      <NavLink
                        to={item.link}
                        exact={index === 0}
                        className={styles.link}
                        activeClassName={styles.selected}
                        key={index}
                      >
                        <Icon name={item.icon} height={22} width={22} className={styles.icon} />
                        <span className={styles.label}>{t(item.label)}</span>
                        {t(item.label) === 'Chat' && (
                          <>
                            {chatUnreadCount > 0 && (
                              <>
                                <div className="absolute top-20 right-30 hidden desktop:block">
                                  <span className="relative inline-block">
                                    <BellIcon
                                      width={20}
                                      height={20}
                                      className="text-textColor cursor-pointer animate-swing"
                                    />
                                    <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-10 font-normal leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                                      {chatUnreadCount}
                                    </span>
                                  </span>
                                </div>
                                <span className="desktop:hidden absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-10 font-normal leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                                  {chatUnreadCount}
                                </span>
                              </>
                            )}
                          </>
                        )}
                      </NavLink>
                    )}
                  </Fragment>
                ))}
              </>
            )}
            {/* Location Admin */}
            {userData?.role === ROLE_LOCATION_ADMIN && (
              <>
                {companyLocationAdminNavLinkList.map((item, index) => (
                  <NavLink
                    to={item.link}
                    exact={index === 0}
                    className={styles.link}
                    activeClassName={styles.selected}
                    key={index}
                  >
                    <Icon name={item.icon} height={22} width={22} className={styles.icon} />
                    <span className={styles.label}>{t(item.label)}</span>
                  </NavLink>
                ))}
              </>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
