import React, { useContext, useState, useRef } from 'react';
import SwitchSelector from 'react-switch-selector';
import { AuthContext } from 'providers/AuthProvider';
import { defaultTextColor, defaultButtonTextColor } from 'layouts/Theme';
import Button from 'components/Button';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import { toast } from 'react-toast';
import AnalyzeOverview from './components/AnalyzeGraph';

type Props = {
  userData?: any;
  userId: string;
};

const CoachingDashboardAnalyze: React.FC<Props> = ({ userData, userId }) => {
  const [startDate, setStartDate] = useState<Date | null>(moment().subtract(29, 'days').startOf('day').toDate());
  const [endDate, setEndDate] = useState<Date | null>(moment().endOf('day').toDate());

  const [lastStartDate, setLastStartDate] = useState<Date | null>(
    moment().subtract(29, 'days').startOf('day').toDate()
  );
  const [lastEndDate, setLastEndDate] = useState<Date | null>(moment().endOf('day').toDate());

  const [selectedQuickIndex, setSelectedQuickIndex] = useState(0);
  const [originalSelectedQuickIndex, setOriginalSelectedQuickIndex] = useState(0);
  const pickerRef = useRef<any>(null);

  // Custom Input Component
  const CustomInputSelect = React.forwardRef(({ value, onClick }: any, ref: React.Ref<HTMLButtonElement>) => (
    <div className="flex gap-20 pb-10">
      <div>
        <div className="my-auto font-bold text-18">Zeitraum:</div>
        <div className="opacity-50 text-13">{quickSelections[selectedQuickIndex].label}</div>
      </div>
      <div>
        <Button
          onClick={onClick}
          className=" appearance-none block py-10 px-3 rounded-md text-base placeholder-buttonTextColor focus:outline-none bg-lightGray bg-opacity-20 text-buttonTextColor border-solid border border-textColor border-opacity-30"
        >
          {value || 'Datum wählen'}
        </Button>
      </div>
    </div>
  ));

  // Schnellauswahl Optionen mit moment.js
  const quickSelections = [
    {
      label: 'Letzte 30 Tage',
      range: [
        moment().subtract(29, 'days').startOf('day').toDate(), // Vor 29 Tagen ab Mitternacht
        moment().endOf('day').toDate(), // Heute bis Mitternacht
      ],
    },
    {
      label: 'Aktuelle Woche',
      range: [
        moment().startOf('isoWeek').toDate(), // Beginn der aktuellen Woche (Montag)
        moment().endOf('isoWeek').toDate(), // Ende der aktuellen Woche (Sonntag)
      ],
    },
    {
      label: 'Letzte 7 Tage',
      range: [
        moment().subtract(6, 'days').startOf('day').toDate(), // Vor 6 Tagen ab Mitternacht
        moment().endOf('day').toDate(), // Heute bis Mitternacht
      ],
    },
    {
      label: 'Letzte Woche',
      range: [
        moment().subtract(1, 'week').startOf('isoWeek').toDate(), // Beginn der letzten Woche (Montag)
        moment().subtract(1, 'week').endOf('isoWeek').toDate(), // Ende der letzten Woche (Sonntag)
      ],
    },
    {
      label: 'Aktueller Monat',
      range: [
        moment().startOf('month').toDate(), // Erster Tag des aktuellen Monats
        moment().endOf('month').toDate(), // Letzter Tag des aktuellen Monats
      ],
    },
    {
      label: 'Letzter Monat',
      range: [
        moment().subtract(1, 'month').startOf('month').toDate(), // Erster Tag des letzten Monats
        moment().subtract(1, 'month').endOf('month').toDate(), // Letzter Tag des letzten Monats
      ],
    },
    {
      label: 'Aktuelles Quartal',
      range: [
        moment().startOf('quarter').toDate(), // Erster Tag des aktuellen Quartals
        moment().endOf('quarter').toDate(), // Letzter Tag des aktuellen Quartals
      ],
    },
    {
      label: 'Letztes Quartal',
      range: [
        moment().subtract(1, 'quarter').startOf('quarter').toDate(), // Erster Tag des letzten Quartals
        moment().subtract(1, 'quarter').endOf('quarter').toDate(), // Letzter Tag des letzten Quartals
      ],
    },
    {
      label: 'Letzte 90 Tage',
      range: [
        moment().subtract(89, 'days').startOf('day').toDate(), // Vor 89 Tagen ab Mitternacht
        moment().endOf('day').toDate(), // Heute bis Mitternacht
      ],
    },
    {
      label: 'Aktuelles Jahr',
      range: [
        moment().startOf('year').toDate(), // Erster Tag des aktuellen Jahres
        moment().endOf('year').toDate(), // Letzter Tag des aktuellen Jahres
      ],
    },
    {
      label: 'Letzte 12 Monate',
      range: [
        moment().subtract(1, 'year').add(1, 'day').startOf('day').toDate(), // Heute vor einem Jahr +1 Tag
        moment().endOf('day').toDate(), // Heute bis Mitternacht
      ],
    },
  ];

  const handleQuickSelection = (range: any, index: number) => {
    setStartDate(range[0]);
    setEndDate(range[1]);
    setSelectedQuickIndex(index);
  };

  const openCalendar = () => {
    setSelectedQuickIndex(originalSelectedQuickIndex);
  };

  const applyCalendar = () => {
    if (startDate === null || endDate === null) {
      toast.error('Bitte wählen Sie ein Start- und Enddatum aus');
      return;
    }

    setLastStartDate(startDate);
    setLastEndDate(endDate);
    setOriginalSelectedQuickIndex(selectedQuickIndex);
    pickerRef.current?.setOpen(false);
  };

  const cancelCalendar = () => {
    setStartDate(lastStartDate);
    setEndDate(lastEndDate);
    setSelectedQuickIndex(originalSelectedQuickIndex);
    pickerRef.current?.setOpen(false);
  };

  const onChange = (dates: any) => {
    const [start, end] = dates;

    setStartDate(start);
    if (end === null) {
      setEndDate(end);
    } else {
      setEndDate(moment(end).endOf('day').toDate());
    }
  };

  return (
    <div className="pt-30">
      <div>
        <DatePicker
          className="w-150 appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
          locale="de"
          ref={pickerRef}
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd.MM.yyyy" // Korrektes Format
          withPortal
          calendarClassName="date_picker px-20 pt-10"
          selectsRange
          disabledKeyboardNavigation
          calendarStartDay={1}
          onFocus={e => e.target.blur()}
          onCalendarOpen={() => openCalendar()}
          openToDate={startDate as any}
          monthsShown={2}
          customInput={<CustomInputSelect />}
          renderCustomHeader={({
            monthDate,
            customHeaderCount,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '10px',
                  borderBottom: '1px solid #ccc',
                }}
              >
                {/* Linker Chevron-Pfeil */}
                {customHeaderCount === 0 ? (
                  <button
                    onClick={decreaseMonth}
                    type="button"
                    disabled={prevMonthButtonDisabled}
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: prevMonthButtonDisabled ? 'not-allowed' : 'pointer',
                      fontSize: '16px',
                      outline: 'none',
                      padding: '0px 0px 0px 10px',
                    }}
                  >
                    &lt;
                  </button>
                ) : (
                  <div className="w-30" />
                )}

                {/* Monats- und Jahresanzeige */}
                <div>
                  <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                    {monthDate.toLocaleString('de-DE', { month: 'long', year: 'numeric' })}
                  </span>
                  <div>
                    {moment(startDate).format('DD.MM.YY')} - {moment(endDate).format('DD.MM.YY')}
                  </div>
                </div>

                {customHeaderCount === 1 ? (
                  <button
                    onClick={increaseMonth}
                    type="button"
                    disabled={nextMonthButtonDisabled}
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: nextMonthButtonDisabled ? 'not-allowed' : 'pointer',
                      fontSize: '16px',
                      outline: 'none',
                      padding: '0px 10px 0px 0px',
                    }}
                  >
                    &gt;
                  </button>
                ) : (
                  <div className="w-30" />
                )}
              </div>
            );
          }}
        >
          <div className="flex">
            <div className="px-10">
              <div style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                <div
                  style={{ fontWeight: 'bold', padding: '0px 0px 10px 0px', fontSize: '15px' }}
                  className="text-black pt-10"
                >
                  Schnellauswahl
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {quickSelections.map((option, index) => (
                    <div
                      key={index}
                      onClick={() => handleQuickSelection(option.range, index)}
                      aria-hidden
                      className="cursor-pointer"
                    >
                      {selectedQuickIndex === index ? (
                        <div className="bg-accentColor rounded-xl pl-3 pr-20 py-1 text-buttonTextColor shadow-lg mb-10">
                          {option.label}
                        </div>
                      ) : (
                        <div className="mb-10 pl-3 pr-20 py-1">{option.label}</div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex pt-20 gap-20 px-20 pb-20">
                <div>
                  <Button className="py-10" onClick={() => applyCalendar()}>
                    Anwenden
                  </Button>
                </div>
                <div
                  className="my-auto text-black font-bold text-16 cursor-pointer"
                  onClick={() => cancelCalendar()}
                  aria-hidden
                >
                  Abbrechen
                </div>
              </div>
            </div>
          </div>
        </DatePicker>
      </div>
      <div className="flex gap-40 flex-wrap">
        <div className="flex-1 flex flex-col">
          <div className="">
            <AnalyzeOverview userData={userData} userId={userId} startDate={startDate} endDate={endDate} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachingDashboardAnalyze;
