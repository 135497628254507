import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AuthContext } from 'providers/AuthProvider';
import firebase from 'services/firebase';
import Button from 'components/Button';
import { PlusIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import Overlay from 'components/Overlay';
import moment from 'moment';
import { toast } from 'react-toast';
import { Disclosure } from '@headlessui/react';
import ButtonBack from 'components/ButtonBack';
import CustomInput from 'components/Input/custom-input';
import styles from './styles.module.scss';

type ParamsType = {
  userId: string;
};

type Props = {
  userData: UserInfo;
};

const CoachingDashBoardNotices: React.FC<Props> = ({ userData }) => {
  const { userId } = useParams<ParamsType>();
  const { tenant, tenantData } = useContext(AuthContext);
  const [currentUserData, setCurrentUserData] = useState<UserInfo>({} as UserInfo);
  const [userDataValid, setUserDataValid] = useState(false);
  const [noticeSavePending, setNoticeSavePending] = useState(false);
  const [noteSearch, setNoteSearch] = useState<string>('');
  const [selectedUserCommentList, setSelectedUserCommentList] = useState<any>([]);
  const [selectedUserInitialCommentList, setSelectedUserInitialCommentList] = useState<any>([]);
  const [newNoticeOverlayClass, setNewNoticeOverlayClass] = useState('hidden');
  const [comment, setComment] = useState<string>('');

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  const saveNote = async () => {
    setNoticeSavePending(true);
    if (comment.length === 0) {
      toast.error('Diese Notiz hat keinen Inhalt. Bitte nutzen Sie das Textfeld!');
      setNoticeSavePending(false);
      return;
    }

    let noteForCurrentDayFound = false;

    if (currentUserData.commentList !== undefined) {
      for (let i = 0; i <= currentUserData?.commentList?.length - 1; i += 1) {
        if (currentUserData.commentList[i].date === moment().utc().format('DD.MM.YYYY')) {
          noteForCurrentDayFound = true;
        }
      }
    }

    if (noteForCurrentDayFound) {
      setNoticeSavePending(false);
      toast.error('Sie haben an diesem Tag schon eine Notiz eingetragen. Bitte editieren Sie die entsprechende Notiz!');
    } else {
      const thisNewObject = {
        comment,
        date: moment().format('DD.MM.YYYY'),
        dateSeconds: moment().unix(),
      };

      let newColumns = {};

      if (currentUserData.commentList !== undefined) {
        newColumns = {
          ...currentUserData,
          commentList: [thisNewObject, ...(currentUserData.commentList as any[])],
        };
      } else {
        newColumns = {
          ...currentUserData,
          commentList: [thisNewObject],
        };
      }

      if (currentUserData.type === 'invite') {
        await firebase.firestore().collection('user-invitations').doc(userId).update(newColumns);
      } else {
        await firebase.firestore().collection(`tenants/${tenant}/users`).doc(userId).update(newColumns);
      }
      setComment('');
      setNewNoticeOverlayClass('hidden');
      setNoticeSavePending(false);
      toast.success('Die Notiz wurde erfolgreich gespeichert!');
    }
  };

  const updateNote = async (dateId: number) => {
    setNoticeSavePending(true);
    if (currentUserData?.commentList !== undefined) {
      const newColumns = {
        ...currentUserData,
        commentList: currentUserData.commentList.map((entries: any) => {
          if (dateId !== entries.dateSeconds) return entries;
          return {
            ...entries,
            comment,
          };
        }),
      };

      if (currentUserData.type === 'invite') {
        await firebase.firestore().collection('user-invitations').doc(userId).update(newColumns);
      } else {
        await firebase.firestore().collection(`tenants/${tenant}/users`).doc(userId).update(newColumns);
      }
      setComment('');
      setNoticeSavePending(false);
      toast.success('Deine Notiz wurde erfolgreich gespeichert!');
    } else {
      setNoticeSavePending(false);
      toast.error('Es ist leider etwas schief gelaufen. Bitte probiere es erneut!');
    }
  };

  const deleteNote = async (dateId: number) => {
    setNoticeSavePending(true);
    if (currentUserData?.commentList !== undefined) {
      const newColumns = {
        ...userData,
        commentList: [...currentUserData.commentList.filter((c: any) => c.dateSeconds !== dateId)],
      };

      if (currentUserData.type === 'invite') {
        await firebase.firestore().collection('user-invitations').doc(userId).update(newColumns);
      } else {
        await firebase.firestore().collection(`tenants/${tenant}/users`).doc(userId).update(newColumns);
      }
      setComment('');

      setNoticeSavePending(false);
      toast.success('Deine Notiz wurde erfolgreich gelöscht!');
    } else {
      setNoticeSavePending(false);
      toast.error('Es ist leider etwas schief gelaufen. Bitte probiere es erneut!');
    }
  };

  const handleNoteSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNoteSearch(value);
  };

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .doc(userId)
      .onSnapshot(
        snapshot => {
          const localUserData = snapshot.data() as UserInfo;

          setCurrentUserData(localUserData);

          if (localUserData && localUserData.fullName !== undefined) {
            setUserDataValid(true);
          }

          if (localUserData?.commentList) {
            setSelectedUserCommentList(localUserData?.commentList);
            setSelectedUserInitialCommentList(localUserData?.commentList);
          }
        },
        error => {
          console.error('Error getting user data: ', error);
        }
      );
    return unsubscribe;
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line

  useEffect(() => {
    const filteredNoteList = currentUserData?.commentList?.filter(
      (element: any) =>
        element.comment.toLowerCase().includes(noteSearch.toLowerCase()) ||
        element.date.toLowerCase().includes(noteSearch.toLowerCase())
    );

    if (noteSearch.length > 0) {
      setSelectedUserCommentList(filteredNoteList);
    } else {
      setSelectedUserCommentList(selectedUserInitialCommentList);
    }
  }, [noteSearch]);

  return (
    <>
      <Overlay
        overlayClass={newNoticeOverlayClass}
        setOverlayClass={setNewNoticeOverlayClass}
        headline="Neue Notiz hinzufügen"
        icon={<PlusIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div>An dieser Stelle können Sie eine neue Notiz von Ihrem Kunden hinzufügen.</div>
            <div className="pt-10">Bitte nutzen Sie das folgende Textfeld für Ihre Notiz.</div>
          </div>
          <div className="pt-30 pb-80">
            <textarea
              className="w-full h-300 text-textColor bg-transparent border-textColor border rounded-20 p-10"
              onChange={e => setComment(e.target.value)}
              onFocus={setBodyClassForMobileSafari}
              onBlur={removeBodyClassForMobileSafari}
            />
            <div className="pt-20">
              <Button
                className="w-full"
                onClick={() => saveNote()}
                isPending={noticeSavePending}
                disabled={noticeSavePending}
              >
                Hinzufügen
              </Button>
            </div>
          </div>
        </div>
      </Overlay>

      <div className={styles.wrapper}>
        <div>
          <Link
            to={{
              pathname: `/member/detail/${userId}`,
            }}
          >
            {' '}
            <ButtonBack
              text="Zurück"
              className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
            />
          </Link>
          {currentUserData?.fullName !== undefined && userDataValid ? (
            <div>
              <div className="flex justify-between space-x-20 w-full pt-20 xxl:w-3/4">
                <div>
                  <div className="font-bold text-25 my-auto">Notizen von</div>
                  <div className="text-25 font-extralight">{currentUserData?.fullName}</div>
                </div>
                <div className="my-auto">
                  <Button onClick={() => setNewNoticeOverlayClass('block')}>Neue Notiz</Button>
                </div>
              </div>
              {currentUserData?.commentList !== undefined ? (
                <div className="pt-60 w-full xxl:w-3/4">
                  <div className="font-bold hidden text-20 my-auto">Liste der Notizen</div>
                  <div className="flex justify-between flex-wrap gap-20">
                    <div className="flex-1">
                      <CustomInput
                        type="text"
                        placeholder="Notiz suchen"
                        name="search"
                        onChange={handleNoteSearch}
                        className="w-full"
                      />
                    </div>
                  </div>
                  {currentUserData && currentUserData?.commentList && currentUserData?.commentList?.length > 0 && (
                    <div>
                      <div className="pt-30">
                        {selectedUserCommentList?.map((item: any, index: number) => (
                          <div key={index} className="pb-10">
                            <Disclosure>
                              {({ open }) => (
                                <>
                                  <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-20 font-medium text-left rounded-20 bg-lightGray focus:outline-none mb-3">
                                    <span>{item.date}</span>
                                    <ChevronDownIcon
                                      className={`${open ? 'transform rotate-180' : ''} w-35 h-35 text-accentColor`}
                                    />
                                  </Disclosure.Button>
                                  <Disclosure.Panel className="px-4">
                                    <textarea
                                      defaultValue={item.comment ?? ''}
                                      className="w-full h-300 text-textColor bg-transparent border-textColor border rounded-20 p-10"
                                      onChange={e => setComment(e.target.value)}
                                      onFocus={setBodyClassForMobileSafari}
                                      onBlur={removeBodyClassForMobileSafari}
                                    />
                                    <div className="flex justify-between pt-10 pb-20">
                                      <Button
                                        onClick={() => updateNote(item.dateSeconds)}
                                        className="py-10"
                                        isPending={noticeSavePending}
                                        disabled={noticeSavePending}
                                      >
                                        Speichern
                                      </Button>
                                      <Button
                                        onClick={() => deleteNote(item.dateSeconds)}
                                        className="py-10"
                                        isPending={noticeSavePending}
                                        disabled={noticeSavePending}
                                      >
                                        Notiz löschen
                                      </Button>
                                    </div>
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {userDataValid && currentUserData?.commentList && selectedUserCommentList?.length === 0 && (
                    <div className="font-bold text-20 pt-20">Es wurden leider keine Notizen gefunden!</div>
                  )}
                </div>
              ) : (
                <div className="font-extralight pt-20">Zu diesem Nutzer wurden noch keine Notizen hinzugefügt!</div>
              )}
            </div>
          ) : (
            <div>Leider konnten wir die Nutzerdaten nicht laden!</div>
          )}
        </div>
      </div>
    </>
  );
};

export default CoachingDashBoardNotices;
