import React, { useState, useEffect, useRef, useContext } from 'react';
import { toast } from 'react-toast';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import firebase from 'services/firebase';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { scrollToTop, generateRandomUid } from 'shared/functions/global';
import CustomSelect from 'components/CustomSelect';

import { CalendarIcon } from '@heroicons/react/24/outline';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import { AuthContext } from 'providers/AuthProvider';
import { MainContext } from 'providers/MainProvider';

import RecipeStepSwitch from '../../../Nutrition/components/RecipeStepSwitch';

registerLocale('de', de);

type Props = {
  className?: string;
  currentStep?: any;
  currentStepValue?: string;
  planState?: any;
  planStateValue?: any;
  popupContainerRef?: any;
  mainPopup?: any;
};

const CopyPlanStep2: React.FC<Props> = ({
  currentStep,
  currentStepValue,
  planState,
  planStateValue,
  popupContainerRef,
  mainPopup,
}) => {
  const [visible, setVisible] = useState('block');
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [currentSearchQuery, setCurrentSearchQuery] = useState('');
  const [currentName, setCurrentName] = useState(planStateValue?.name);
  const [startDate, setStartDate] = useState(null);
  const [initialIntolerances, changeIncompatibilities] = useState<string[]>([]);
  const [caloriesRange, changeCaloriesRange] = useState({ min: 1000, max: 4500 });
  const [initialNutrition, changeNutrition] = useState<string[]>([]);
  const { userPlanList, planList } = useContext(MainContext);
  const [excludeDateArray, setExcludeDateArray] = useState<any>([]);
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates: any) => {
    setStartDate(dates);
  };
  const { t } = useTranslation();
  const moment = extendMoment(Moment);

  const history = useHistory();

  const authContext = useContext(AuthContext);
  const { userData, tenant } = useContext(AuthContext);

  const caloriesValues: any = [
    { value: '1250_1750', label: '1250 - 1750' },
    { value: '2000_2500', label: '2000 - 2500' },
    { value: '2750_3250', label: '2750 - 3250' },
    { value: '3500_4000', label: '3500 - 4000' },
  ];

  const nutritionValues: any = [
    { value: 'flexitarian', label: 'Flexibel' },
    { value: 'vegetarian', label: 'Vegetarisch' },
    { value: 'vegan', label: 'Vegan' },
    { value: 'ketogen', label: 'Ketogen' },
  ];

  const [currentCalories, setCurrentCalories] = useState('1250_1750');
  const [currentNutrition, setCurrentNutrition] = useState('flexitarian');

  const updateCalories = (value: any) => {
    setCurrentCalories(value.value);
  };

  const updateNutrition = (value: any) => {
    setCurrentNutrition(value.value);
  };

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  const editPlanNameRef = useRef<HTMLDivElement>(null);

  function jumpToNextStep() {
    let isValid = true;

    if (currentName === '' || currentName === undefined) {
      isValid = false;
      toast.warn(t('Select Name'));
    }

    if (isValid) {
      const newColumns = {
        ...planStateValue,
        name: currentName,
        startDate: {
          seconds: moment(startDate).subtract(-1, 'days').unix(),
        },
        endDate: {
          seconds: moment(endDate).subtract(-1, 'days').unix(),
        },
      };

      planState(newColumns);
      setVisible('hidden');
      currentStep('3');
    }
  }

  function returnToPreviousStep() {
    setVisible('hidden');
    currentStep('1');
  }

  const changePlanName = (event: any) => {
    const thisCurrentName = event.currentTarget.textContent;

    setCurrentName(thisCurrentName);
  };

  const savePlanToFirebase = async () => {
    const thisPlanUid = generateRandomUid();
    let isPlanValid = true;
    let isPlanNoDate = false;

    if (planStateValue?.name !== undefined) {
      userPlanList?.forEach(function (key) {
        const thisCheckPlanStartDate = moment.unix(key.startDate.seconds).utc().format('DD-MM-YY');
        const thisCheckPlanEndDate = moment.unix(key.endDate.seconds).utc().format('DD-MM-YY');

        const thisConvertedPlanStartDate = moment(thisCheckPlanStartDate, 'DD-MM-YY');
        const thisConvertedPlanEndDate = moment(thisCheckPlanEndDate, 'DD-MM-YY');

        const thisUserStartDate = moment(startDate).format('YYYY-MM-DD');
        const thisUserEndDate = moment(startDate).subtract(-planStateValue?.dayEntries.length, 'days');

        const thisPlanRange = moment.range(thisConvertedPlanStartDate, thisConvertedPlanEndDate);
        const thisUserRange = moment.range(moment(thisUserStartDate), moment(thisUserEndDate));

        if (thisUserRange.overlaps(thisPlanRange, { adjacent: true }) === true) {
          isPlanValid = false;
        }
      });
    }

    if (startDate === null) {
      isPlanValid = false;
      isPlanNoDate = true;
      toast.error('Bitte gebe ein Datum ein!');
    }

    if (isPlanValid) {
      const newColumns = {
        ...planStateValue,
        examplePlan: false,
        startDate: {
          ...planStateValue?.startDate,
          seconds: moment(startDate).subtract(-1, 'days').unix(),
        },
        endDate: {
          ...planStateValue?.endDate,
          seconds: moment(startDate).subtract(-planStateValue?.dayEntries.length, 'days').unix(),
        },
        name: currentName,
        uid: thisPlanUid,
        origId: thisPlanUid,
        created: moment().unix(),
        dayEntries: planStateValue?.dayEntries.map((entries: any, index: number) => {
          return {
            ...entries,
            date: {
              ...entries.date,
              seconds: moment(startDate)
                .subtract(-(index + 1), 'days')
                .unix(),
            },
          };
        }),
      };

      if (newColumns.custom_kcal_total !== undefined) {
        delete newColumns.custom_kcal_total;
      }
      if (newColumns.custom_carbohydrates_total !== undefined) {
        delete newColumns.custom_carbohydrates_total;
      }
      if (newColumns.custom_protein_total !== undefined) {
        delete newColumns.custom_protein_total;
      }
      if (newColumns.custom_fat_total !== undefined) {
        delete newColumns.custom_fat_total;
      }
      if (newColumns.custom_makro_type !== undefined) {
        delete newColumns.custom_makro_type;
      }
      if (newColumns.examplePlanCalories !== undefined) {
        delete newColumns.examplePlanCalories;
      }
      if (newColumns.examplePlanNutrition !== undefined) {
        delete newColumns.examplePlanNutrition;
      }

      const newColumnsAdmin = {
        ...planStateValue,
        startDate: {
          ...planStateValue?.startDate,
          seconds: moment(startDate).subtract(-1, 'days').unix(),
        },
        endDate: {
          ...planStateValue?.endDate,
          seconds: moment(startDate).subtract(-planStateValue?.dayEntries.length, 'days').unix(),
        },
        name: currentName,
        uid: thisPlanUid,
        origId: thisPlanUid,
        created: moment().unix(),
        examplePlan: true,
        examplePlanNutrition: currentNutrition,
        examplePlanCalories: currentCalories,
        dayEntries: planStateValue?.dayEntries.map((entries: any, index: number) => {
          return {
            ...entries,
            date: {
              ...entries.date,
              seconds: moment(startDate)
                .subtract(-(index + 1), 'days')
                .unix(),
            },
          };
        }),
      };

      const newColumnsFavorite = {
        uid: thisPlanUid,
        name: currentName,
        origId: thisPlanUid,
      };

      if (userData?.role === 1) {
        try {
          await firebase
            .firestore()
            .collection('plans')
            .doc(thisPlanUid)
            .set(newColumnsAdmin as object);
          toast.success(t('Dein Plan wurde erfolgreich erstellt!'));
          history.push({
            pathname: `/plans/edit/${thisPlanUid}`,
            state: {
              from: 'plan_add',
            },
          });
        } catch (error) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          toast.error('Es ist leider etwas schief gegangen!');
        }
      } else {
        try {
          await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(authContext.user?.uid)
            .collection('plans')
            .doc(thisPlanUid)
            .set(newColumns as object);
          try {
            await firebase
              .firestore()
              .collection(`tenants/${tenant}/users`)
              .doc(authContext.user?.uid)
              .collection('favorites_plans')
              .doc()
              .set(newColumnsFavorite as object);
          } catch (error) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            toast.error('Es ist leider etwas schief gegangen!');
          }
          toast.success(t('Dein Plan wurde erfolgreich erstellt!'));
          history.push({
            pathname: `/plans/edit/${thisPlanUid}`,
            state: {
              from: 'plan_add',
            },
          });
        } catch (error) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          toast.error('Es ist leider etwas schief gegangen!');
        }
      }

      currentStep('1');
      mainPopup('hidden');
    } else if (isPlanNoDate === false) {
      toast.error('In diesem Zeitraum existiert schon ein Plan. Bitte wähle ein anderes Datum!');
    }
  };

  useEffect(() => {
    if (currentStepValue !== 'copy2') {
      setVisible('hidden');
    }

    if (editPlanNameRef.current && visible === 'block') {
      editPlanNameRef.current.focus();
      scrollToTop(popupContainerRef);
    }

    const excludeDatesStaticArray = [] as any;

    if (userData?.role === 1) {
      planList?.forEach(key => {
        key.dayEntries?.forEach(day => {
          excludeDatesStaticArray.push(
            moment
              .unix(day.date.seconds)
              .utc()
              .subtract(+1, 'days')
              .toDate()
          );
        });
      });
    } else {
      userPlanList?.forEach(key => {
        key.dayEntries?.forEach(day => {
          excludeDatesStaticArray.push(
            moment
              .unix(day.date.seconds)
              .utc()
              .subtract(+1, 'days')
              .toDate()
          );
        });
      });
    }

    setExcludeDateArray(excludeDatesStaticArray);
  }, [currentStepValue, planStateValue, visible, popupContainerRef, userPlanList, planList]);

  const CustomInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
    return (
      <label>
        <div className="flex w-full">
          <div className="flex-1">
            <input {...props} />
          </div>
          <div className="ml-10 my-auto">
            <CalendarIcon width={25} height={25} className="text-accentColor mx-auto cursor-pointer" />
          </div>
        </div>
      </label>
    );
  };

  return (
    <>
      <div className={visible}>
        <div className="mx-20 pt-20 pb-80 font-extralight">
          <div className="text-20 font-bold">Du möchtest den Plan "{planStateValue?.name}" kopieren</div>
          <div className="pt-10">
            Dieser Plan hat eine Laufzeit von <span className="font-bold">{planStateValue?.dayEntries.length}</span>{' '}
            Tagen.
          </div>
          <div className="pt-30">Bitte wähle ein Startdatum aus, wann du diesen Plan starten möchtest:</div>
          <div className="flex">
            <div className="w-3/4 font-light left text-xs border-opacity-30">
              <DatePicker
                className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                selected={startDate}
                onChange={onChange}
                excludeDates={excludeDateArray}
                startDate={startDate}
                locale="de"
                dateFormat="dd.MM.yyyy"
                minDate={moment().toDate()}
                withPortal
                customInput={React.createElement(React.forwardRef(CustomInput))}
                calendarStartDay={1}
                onFocus={e => e.target.blur()}
              />
            </div>
          </div>
          <div>
            <div className="pt-20 font-extralight pb-1">Vergebe einen Namen für deinen Plan:</div>
            <div className="flex">
              <div className="w-3/4">
                <div className="font-light left text-xs border-opacity-30 w-full">
                  <span
                    className="appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                    role="textbox"
                    contentEditable
                    onFocus={setBodyClassForMobileSafari}
                    onBlur={removeBodyClassForMobileSafari}
                    suppressContentEditableWarning
                    onInput={e => changePlanName(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          {userData?.role === 1 && (
            <div className="pt-20">
              <div className="font-bold">Ordner für Beispielpläne</div>
              <div className="pt-10">Ernährungsform:</div>
              <div className="pb-20">
                <CustomSelect
                  name="calories"
                  dropDownOption={nutritionValues}
                  defaultValue={nutritionValues[0]}
                  onChange={updateNutrition}
                />
              </div>
              <div className="pt-10">Kalorien (kcal):</div>
              <div className="pb-80">
                <CustomSelect
                  name="calories"
                  dropDownOption={caloriesValues}
                  defaultValue={caloriesValues[0]}
                  onChange={updateCalories}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <RecipeStepSwitch
        returnFunction={returnToPreviousStep}
        nextFunction={savePlanToFirebase}
        currentStepValue="2"
        totalSteps={2}
        isFinished
      />
    </>
  );
};

export default CopyPlanStep2;
