import React, { useMemo, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomInput from 'components/Input/custom-input';
import CustomSelect from 'components/CustomSelect';
import { genderOption, physicalStrainOption, yourGoal } from 'shared/constants/profile-wizard';
import { XMarkIcon as XIcon, CheckIcon } from '@heroicons/react/24/outline';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { OptionType } from 'react-switch-selector/dist/SwitchSelector';
import { MainContext } from 'providers/MainProvider';
import { AuthContext } from 'providers/AuthProvider';
import styles from './styles.module.scss';

type Props = {
  userData: UserInfo;
  changeUserDataFunction: Function;
  saveUserDataFunction: Function;
  originalUserData: UserInfo;
};

const AnamnesePersonalInformation: React.FC<Props> = ({
  userData,
  changeUserDataFunction,
  saveUserDataFunction,
  originalUserData,
}) => {
  const { t } = useTranslation();
  const [ingridientSearchVisible, setIngridientSearchVisible] = useState(false);
  const [profileChanged, setProfileChanged] = useState(false);
  const [currentIngriedientAddList, setCurrentIngriedientAddList] = useState<any>([]);
  const { ingredientList } = useContext(MainContext);
  const { tenantData } = useContext(AuthContext);

  const selectNotIncludeRef = React.useRef() as any;

  const defaultGenderValue = useMemo(
    () => genderOption.filter((item: any) => item.value === userData?.gender),
    [userData?.gender]
  );

  const defaultPhysicalStrainValue = useMemo(
    () => physicalStrainOption.filter((item: any) => item.value === userData?.physicalStrainChange),
    [userData?.physicalStrainChange]
  );

  const ingredientsItems: OptionType[] = useMemo(
    () => ingredientList?.map(ingredient => ({ value: ingredient.name?.toLowerCase(), label: ingredient.name })) ?? [],
    [ingredientList]
  );

  const dCaloriesList = [-500, 250, 0];

  const genderChange = (value: any) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({ ...prevProfile, gender: value.value }));
  };
  const ageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({ ...prevProfile, age: event.target.value }));
  };
  const sizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const testValue = event.target.value.replace(/[^0-9]/g, '');
    changeUserDataFunction((prevProfile: UserInfo) => ({ ...prevProfile, bodySize: testValue }));
  };
  const weightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({ ...prevProfile, bodyWeight: event.target.value }));
  };
  const waterGoalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({
      ...prevProfile,
      settings: { waterGoal: parseFloat(event.target.value) },
    }));
  };

  const physicalStrainChange = (value: any) => {
    changeUserDataFunction((prevProfile: UserInfo) => ({ ...prevProfile, physicalStrainChange: value.value }));
  };

  const getWaterGoal = (getType: string) => {
    if (userData !== undefined && userData?.settings?.waterGoal === undefined) {
      if (getType === 'number') {
        return 35 * parseFloat(userData.bodyWeight);
      }
      return 0;
    }
    if (userData !== undefined && userData?.settings?.waterGoal !== undefined) {
      if (getType === 'number') {
        return userData.settings.waterGoal;
      }
      return 0;
    }
    return 0;
  };

  const deleteIgredientsNotLikeList = async (ingredientName: string) => {
    let thisIngredientObject = [] as any;

    if (userData.ingredientsNotLike !== undefined) {
      thisIngredientObject = [...userData.ingredientsNotLike.filter((st: any) => st.name !== ingredientName)];
    }

    const newColumns = {
      ...userData,
      ingredientsNotLike: [...thisIngredientObject],
    };

    await saveUserDataFunction(newColumns);

    changeUserDataFunction(newColumns);
  };

  const changeIngredientNotIncludeGroup = (value: any) => {
    const transformedValues = value.map((item: any) => ({ name: item.label }));

    setCurrentIngriedientAddList(transformedValues);
  };

  const resetIngredientNotLike = () => {
    const newColumns = {
      ...userData,
      ingredientsNotLike: [...(userData.ingredientsNotLike as any)],
    };

    changeUserDataFunction(newColumns);

    setCurrentIngriedientAddList([]);

    setIngridientSearchVisible(false);
  };

  const saveIngredientNotLike = async () => {
    const newColumns = {
      ...userData,
      ingredientsNotLike: [
        ...(userData.ingredientsNotLike || []), // Falls undefined oder null, wird ein leeres Array genutzt
        ...currentIngriedientAddList,
      ],
    };

    if (currentIngriedientAddList.length > 0) {
      changeUserDataFunction(newColumns);
      await saveUserDataFunction(newColumns);
    }

    setCurrentIngriedientAddList([]);

    setIngridientSearchVisible(false);
  };

  useEffect(() => {
    if (
      userData.age !== originalUserData.age ||
      userData.bodyWeight !== originalUserData.bodyWeight ||
      userData.bodySize !== originalUserData.bodySize ||
      userData.gender !== originalUserData.gender ||
      userData.goal !== originalUserData.goal ||
      userData.physicalStrainChange !== originalUserData.physicalStrainChange ||
      profileChanged === true
    ) {
      setProfileChanged(true);
      const age = parseFloat(userData?.age ?? '') ?? 0;
      const height = parseFloat(userData?.bodySize ?? '') ?? 0;
      const weight = parseFloat(userData?.bodyWeight ?? '') ?? 0;
      let calories = 0;
      if (userData?.gender === 'man') {
        calories = 10 * weight + 6.25 * height - 5 * age + 5;
      } else if (userData?.gender === 'woman') {
        calories = 10 * weight + 6.25 * height - 5 * age - 161;
      }
      const pal = physicalStrainOption.find((item: any) => item.value === userData?.physicalStrainChange)?.pal ?? 1;
      calories *= pal;

      const dCalories =
        userData?.goal !== undefined ? dCaloriesList[yourGoal.findIndex(goal => goal === userData.goal)] ?? 0 : 0;
      calories += dCalories;

      let carbohydratesPercent = 50;
      let proteinPercent = 25;
      let fatPercent = 25;

      if (tenantData?.settings?.makros?.carbohydrates_percent !== undefined) {
        carbohydratesPercent = tenantData?.settings?.makros?.carbohydrates_percent;
      }

      if (tenantData?.settings?.makros?.protein_percent !== undefined) {
        proteinPercent = tenantData?.settings?.makros?.protein_percent;
      }

      if (tenantData?.settings?.makros?.fat_percent !== undefined) {
        fatPercent = tenantData?.settings?.makros?.fat_percent;
      }

      let carbohydrates = Math.round((calories * carbohydratesPercent) / 100 / 4.1);
      let protein = Math.round((calories * proteinPercent) / 100 / 4.1);
      let fat = Math.round((calories * fatPercent) / 100 / 9.3);

      if (
        tenantData?.settings?.functions?.caloriesGoal !== undefined &&
        tenantData?.settings?.functions?.caloriesGoal === false &&
        userData?.profileComplete !== undefined &&
        userData?.profileComplete === true
      ) {
        calories = parseFloat(userData?.calories);
        carbohydrates = userData?.carbohydrates_total;
        protein = userData?.protein_total;
        fat = userData?.fat_total;
      }

      if (
        tenantData?.settings?.makros?.carbohydrates_percent !== undefined ||
        tenantData?.settings?.makros?.protein_percent !== undefined ||
        tenantData?.settings?.makros?.fat_percent !== undefined
      ) {
        changeUserDataFunction((prevProfile: UserInfo) => ({
          ...prevProfile,
          calories: calories.toFixed(2),
          carbohydrates_total: carbohydrates,
          protein_total: protein,
          fat_total: fat,
          custom_nutrition_type: 'user',
          custom_carbohydrates_percent:
            tenantData?.settings?.makros?.carbohydrates_percent !== undefined
              ? tenantData?.settings?.makros?.carbohydrates_percent.toString()
              : '50',
          custom_protein_percent:
            tenantData?.settings?.makros?.protein_percent !== undefined
              ? tenantData?.settings?.makros?.protein_percent.toString()
              : '25',
          custom_fat_percent:
            tenantData?.settings?.makros?.fat_percent !== undefined
              ? tenantData?.settings?.makros?.fat_percent.toString()
              : '25',
        }));
      } else {
        changeUserDataFunction((prevProfile: UserInfo) => ({
          ...prevProfile,
          calories: calories.toFixed(2),
          carbohydrates_total: carbohydrates,
          protein_total: protein,
          fat_total: fat,
        }));
      }
    }
  }, [
    userData?.age,
    userData?.bodySize,
    userData?.bodyWeight,
    userData?.gender,
    userData?.goal,
    userData?.physicalStrainChange,
  ]);

  return (
    <div>
      <div className="relative">
        <div className="flex-grow">
          <CustomSelect
            name="gender"
            dropDownOption={genderOption}
            label={t('Gender')}
            defaultValue={defaultGenderValue}
            onChange={genderChange}
            isSearchable
            className="w-full border border-textColor"
          />
          <CustomInput
            name="age"
            type="number"
            label={t('Age')}
            onChange={ageChange}
            defaultValue={userData.age ?? ''}
            className="mt-15"
            inputClassName="border border-textColor"
          />
          <CustomInput
            name="bodySize"
            type="number"
            label={t('Body Size')}
            onChange={sizeChange}
            defaultValue={userData.bodySize ?? ''}
            suffix="cm"
            className="mt-15"
            inputClassName="border border-textColor"
          />
          <CustomInput
            name="bodyWeight"
            type="number"
            label={t('Body Weight')}
            onChange={weightChange}
            defaultValue={userData.bodyWeight ?? ''}
            suffix="kg"
            className="my-15"
            inputClassName="border border-textColor"
          />
          <CustomSelect
            name="physicalStrain"
            dropDownOption={physicalStrainOption}
            defaultValue={defaultPhysicalStrainValue}
            label={t('Physical strain')}
            onChange={physicalStrainChange}
            className="w-full border border-textColor"
          />
          <CustomInput
            name="waterGoal"
            type="number"
            label="Aktuelles Wasser-Ziel"
            onChange={waterGoalChange}
            defaultValue={getWaterGoal('number').toString() ?? ''}
            suffix="ml"
            className="my-15"
            inputClassName="border border-textColor"
          />
          <div className="mt-15">
            <label className="block text-sm font-regular text-textColor opacity-50 mb-1">
              Aktuelle Makronährstoff Ziele
            </label>
            <div className="pt-10 flex">
              <div className={styles.nutritionBorderItemFirst}>
                <div>
                  <div className="font-semibold text-base">{Math.round(parseFloat(userData.calories))}</div>
                  <div className={styles.recipeLabel}>Kalorien</div>
                </div>
                <span className="divider" />
              </div>
              <div className={styles.nutritionBorderItem}>
                <div className="font-semibold text-base">{userData?.carbohydrates_total}g</div>
                <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
              </div>
              <div className={styles.nutritionBorderItem}>
                <div className="font-semibold text-base">{userData?.protein_total}g</div>
                <div className={styles.recipeLabel}>{t('Protein')}</div>
              </div>
              <div className="text-center px-20">
                <div className="font-semibold text-base">{userData?.fat_total}g</div>
                <div className={styles.recipeLabel}>{t('Fat')}</div>
              </div>
            </div>
            <hr className="border-lightGrayDarker mt-15 pb-5" />
          </div>
          <div className="mt-15">
            <div className="flex flex-wrap gap-5 mb-1">
              <label className="block text-sm font-regular text-textColor opacity-50 my-auto">
                Lebensmittel, die der Nutzer nicht mag
              </label>
            </div>
            {userData?.ingredientsNotLike && userData?.ingredientsNotLike.length > 0 ? (
              <div>
                {ingridientSearchVisible ? (
                  <div className="relative">
                    <CustomSelect
                      name="notGoal"
                      dropDownOption={ingredientsItems}
                      isSearchable
                      isMulti
                      onChange={(value: any) => changeIngredientNotIncludeGroup(value)}
                      thisRef={selectNotIncludeRef}
                      className="w-full border border-textColor"
                      isFullSize
                    />
                    <div className="absolute right-0 top-50">
                      <div className="bg-bgColor p-1 flex gap-1">
                        <div className="bg-lightGrayDarker">
                          <CheckIcon
                            width={22}
                            height={22}
                            className="text-buttonTextColor cursor-pointer"
                            onClick={() => saveIngredientNotLike()}
                          />
                        </div>
                        <div className="bg-lightGrayDarker">
                          <XIcon
                            width={22}
                            height={22}
                            className="text-buttonTextColor cursor-pointer"
                            onClick={() => resetIngredientNotLike()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-wrap gap-10">
                    {userData?.ingredientsNotLike?.map((item, index) => (
                      <div
                        key={index}
                        className="rounded-3xl bg-accentColor py-5 pl-15 pr-30 relative font-bold text-14"
                      >
                        <div>
                          <div className="text-buttonTextColor">{item.name}</div>
                          <div className={styles.editIcon}>
                            <XIcon
                              width={15}
                              height={15}
                              className="text-buttonTextColor cursor-pointer"
                              onClick={() => deleteIgredientsNotLikeList(item.name)}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    <div>
                      <PlusCircleIcon
                        width={30}
                        height={30}
                        className="text-accentColor cursor-pointer"
                        onClick={() => setIngridientSearchVisible(true)}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {ingridientSearchVisible ? (
                  <div className="relative">
                    <CustomSelect
                      name="notGoal"
                      dropDownOption={ingredientsItems}
                      isSearchable
                      isMulti
                      onChange={(value: any) => changeIngredientNotIncludeGroup(value)}
                      thisRef={selectNotIncludeRef}
                      className="w-full border border-textColor"
                      isFullSize
                    />
                    <div className="absolute right-0 top-50">
                      <div className="bg-bgColor p-1 flex gap-1">
                        <div className="bg-lightGrayDarker">
                          <CheckIcon
                            width={22}
                            height={22}
                            className="text-buttonTextColor cursor-pointer"
                            onClick={() => saveIngredientNotLike()}
                          />
                        </div>
                        <div className="bg-lightGrayDarker">
                          <XIcon
                            width={22}
                            height={22}
                            className="text-buttonTextColor cursor-pointer"
                            onClick={() => resetIngredientNotLike()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-wrap gap-10">
                    <div className="my-auto">Aktuell sind keine Lebensmittel hinterlegt!</div>
                    <div>
                      <PlusCircleIcon
                        width={30}
                        height={30}
                        className="text-accentColor cursor-pointer"
                        onClick={() => setIngridientSearchVisible(true)}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            <hr className="border-lightGrayDarker mt-20 pb-5" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnamnesePersonalInformation;
