import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import Headline from 'components/Headline';
import firebase from 'firebase';
import Select from 'react-select';
import moment from 'moment';
import { ChartPieIcon, XMarkIcon as XIcon, BookOpenIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toast';
import { useTranslation } from 'react-i18next';

import TransitionContainer from 'components/TransitionContainer';
import Icon from 'components/Icon';
import CookieBanner from 'components/CookieBanner';
import { AuthContext } from 'providers/AuthProvider';
import { MainContext } from 'providers/MainProvider';
import SearchBox from 'components/SearchBox';
import Button from 'components/Button';
import SwitchButton from 'components/SwitchButton';
import { ROLE_MEMBER, ROLE_USER } from 'shared/constants/global';

import styles from './styles.module.scss';

type Props = {
  isShown: boolean;
  title: string;
  goBack?: React.MouseEventHandler<HTMLButtonElement>;
};

const Settings: React.FC<Props> = ({ isShown, title, goBack = () => {} }) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { userData, tenant, tenantData, user } = useContext(AuthContext);
  const { ingredientList } = useContext(MainContext);

  const db = firebase.firestore();

  const [currentSection, setCurrentSection] = useState('start');
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentWaterChangeValue, setCurrentWaterChangeValue] = useState(0);
  const [currentMakroChangeCalories, setCurrentMakroChangeCalories] = useState<any>(0);
  const [currentMakroChangeCarbohydrates, setCurrentMakroChangeCarbohydrates] = useState<any>(0);
  const [currentMakroChangeProtein, setCurrentMakroChangeProtein] = useState<any>(0);
  const [currentMakroChangeFat, setCurrentMakroChangeFat] = useState<any>(0);
  const [currentMakroChangeType, setCurrentMakroChangeType] = useState('standard');
  const [currentSearchValue, setCurrentSearchValue] = useState('');

  const [changeCaloriesNeed, setChangeCaloriesNeed] = useState(false);

  const [currentCarbohydratesPercent, setCurrentCarbohydratesPercent] = useState('');
  const [currentProteinPercent, setCurrentProteinPercent] = useState('');
  const [currentFatPercent, setCurrentFatPercent] = useState('');

  const [currentMakroChangeCarbohydratesPercent, setCurrentMakroChangeCarbohydratesPercent] = useState('');
  const [currentMakroChangeProteinPercent, setCurrentMakroChangeProteinPercent] = useState('');
  const [currentMakroChangeFatPercent, setCurrentMakroChangeFatPercent] = useState('');

  const changeMakroCarbohydrateRef = useRef<HTMLInputElement>(null);
  const changeMakroProteinRef = useRef<HTMLInputElement>(null);
  const changeMakroFatRef = useRef<HTMLInputElement>(null);
  const changeMakroCaloriesRef = useRef<HTMLInputElement>(null);
  const searchIngridientRef = useRef<HTMLInputElement>(null);

  const makroTypes: any = [
    { value: 'standard', label: 'Standard', carbohydrates: '50', protein: '25', fat: '25' },
    { value: 'ketogen', label: 'Ketogen', carbohydrates: '5', protein: '30', fat: '65' },
    { value: 'user', label: 'Benutzerdefiniert' },
  ];

  const ingredientFilteredList = useMemo(
    () => ingredientList?.filter(element => element.name.toLowerCase().includes(currentSearchValue.toLowerCase())),
    [ingredientList, currentSearchValue]
  );

  useEffect(() => {
    if (isShown) {
      setCurrentSection('start');
      if (userData?.custom_nutrition_type !== undefined) {
        if (userData?.custom_nutrition_type === 'user') {
          setCurrentCarbohydratesPercent(userData?.custom_carbohydrates_percent!);
          setCurrentProteinPercent(userData?.custom_protein_percent!);
          setCurrentFatPercent(userData?.custom_fat_percent!);
        } else {
          setCurrentCarbohydratesPercent(
            makroTypes.filter((st: any) => st.value === userData?.custom_nutrition_type)[0].carbohydrates
          );
          setCurrentProteinPercent(
            makroTypes.filter((st: any) => st.value === userData?.custom_nutrition_type)[0].protein
          );
          setCurrentFatPercent(makroTypes.filter((st: any) => st.value === userData?.custom_nutrition_type)[0].fat);
        }
      } else {
        setCurrentCarbohydratesPercent('50');
        setCurrentProteinPercent('25');
        setCurrentFatPercent('25');
      }
    }
  }, [isShown]);

  useEffect(() => {
    if (currentSection === 'calories') {
      setChangeCaloriesNeed(false);
      setCurrentMakroChangeType('');
      setCurrentMakroChangeType('standard');

      if (userData?.custom_nutrition_type !== undefined) {
        if (userData?.custom_nutrition_type === 'user') {
          setCurrentCarbohydratesPercent(userData?.custom_carbohydrates_percent!);
          setCurrentProteinPercent(userData?.custom_protein_percent!);
          setCurrentFatPercent(userData?.custom_fat_percent!);
        } else {
          setCurrentCarbohydratesPercent(
            makroTypes.filter((st: any) => st.value === userData?.custom_nutrition_type)[0].carbohydrates
          );
          setCurrentProteinPercent(
            makroTypes.filter((st: any) => st.value === userData?.custom_nutrition_type)[0].protein
          );
          setCurrentFatPercent(makroTypes.filter((st: any) => st.value === userData?.custom_nutrition_type)[0].fat);
        }
      } else {
        setCurrentCarbohydratesPercent('50');
        setCurrentProteinPercent('25');
        setCurrentFatPercent('25');
      }
    }
  }, [currentSection]);

  useEffect(() => {
    if (currentSection === 'calories') {
      setCurrentMakroChangeType('');
      setCurrentMakroChangeType('standard');
      setCurrentMakroChangeCarbohydrates(0);
      setCurrentMakroChangeProtein(0);
      setCurrentMakroChangeFat(0);
      setCurrentMakroChangeCalories(0);
    }
  }, [changeCaloriesNeed]);

  const updateWaterGoal = (event: any) => {
    setCurrentWaterChangeValue(event.target.value);
  };

  const getWaterGoal = (getType: string) => {
    if (userData !== undefined && userData?.settings?.waterGoal === undefined) {
      if (getType === 'number') {
        return 35 * parseFloat(userData.bodyWeight);
      }
      return 0;
    }
    if (userData !== undefined && userData?.settings?.waterGoal !== undefined) {
      if (getType === 'number') {
        return userData.settings.waterGoal;
      }
      return 0;
    }
    return 0;
  };

  const changeUserFat = (event: any) => {
    let thisValue = event.target.value;
    let thisCalculatedFat = 0;

    if (thisValue === '') {
      thisValue = 0;
    }

    thisCalculatedFat = Math.round((currentMakroChangeCalories * parseFloat(thisValue)) / 100 / 9.3);

    setCurrentMakroChangeFatPercent(thisValue);
    setCurrentMakroChangeFat(thisCalculatedFat);
  };

  const changeUserProtein = (event: any) => {
    let thisValue = event.target.value;
    let thisCalculatedProtein = 0;

    if (thisValue === '') {
      thisValue = 0;
    }

    thisCalculatedProtein = Math.round((currentMakroChangeCalories * parseFloat(thisValue)) / 100 / 4.1);

    setCurrentMakroChangeProteinPercent(thisValue);
    setCurrentMakroChangeProtein(thisCalculatedProtein);
  };

  const changeUserCarbohydrates = (event: any) => {
    let thisValue = event.target.value;
    let thisCalculatedCarbohydrates = 0;

    if (thisValue === '') {
      thisValue = 0;
    }

    thisCalculatedCarbohydrates = Math.round((currentMakroChangeCalories * parseFloat(thisValue)) / 100 / 4.1);

    setCurrentMakroChangeCarbohydratesPercent(thisValue);
    setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
  };

  const changeSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSearchValue(event.target.value);
  };

  const updateMakroCalories = (event: any) => {
    let thisCalculatedCarbohydrates = 0;
    let thisCalculatedProtein = 0;
    let thisCalculatedFat = 0;
    const thisCarbohydratesPercent = makroTypes.filter((st: any) => st.value === currentMakroChangeType)[0]
      .carbohydrates;
    const thisProteinPercent = makroTypes.filter((st: any) => st.value === currentMakroChangeType)[0].protein;
    const thisFatPercent = makroTypes.filter((st: any) => st.value === currentMakroChangeType)[0].fat;

    if (currentMakroChangeType === 'standard') {
      thisCalculatedCarbohydrates = Math.round((event.target.value * 50) / 100 / 4.1);
      thisCalculatedProtein = Math.round((event.target.value * 25) / 100 / 4.1);
      thisCalculatedFat = Math.round((event.target.value * 25) / 100 / 9.3);

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    } else if (currentMakroChangeType === 'user') {
      thisCalculatedCarbohydrates = Math.round(
        (event.target.value * parseFloat(currentMakroChangeCarbohydratesPercent)) / 100 / 4.1
      );
      thisCalculatedProtein = Math.round(
        (event.target.value * parseFloat(currentMakroChangeProteinPercent)) / 100 / 4.1
      );
      thisCalculatedFat = Math.round((event.target.value * parseFloat(currentMakroChangeFatPercent)) / 100 / 9.3);

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    } else {
      thisCalculatedCarbohydrates = Math.round((event.target.value * parseFloat(thisCarbohydratesPercent)) / 100 / 4.1);
      thisCalculatedProtein = Math.round((event.target.value * parseFloat(thisProteinPercent)) / 100 / 4.1);
      thisCalculatedFat = Math.round((event.target.value * parseFloat(thisFatPercent)) / 100 / 9.3);

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    }

    setCurrentMakroChangeCalories(event.target.value);
  };

  const updateMakroType = (value: any) => {
    let thisCalculatedCarbohydrates = 0;
    let thisCalculatedProtein = 0;
    let thisCalculatedFat = 0;
    const thisCarbohydratesPercent = makroTypes.filter((st: any) => st.value === value.value)[0].carbohydrates;
    const thisProteinPercent = makroTypes.filter((st: any) => st.value === value.value)[0].protein;
    const thisFatPercent = makroTypes.filter((st: any) => st.value === value.value)[0].fat;

    if (value.value === 'user') {
      if (changeMakroCarbohydrateRef.current) {
        changeMakroCarbohydrateRef.current.value = '50';
      }
      if (changeMakroProteinRef.current) {
        changeMakroProteinRef.current.value = '25';
      }
      if (changeMakroFatRef.current) {
        changeMakroFatRef.current.value = '25';
      }

      thisCalculatedCarbohydrates = Math.round((currentMakroChangeCalories * 50) / 100 / 4.1);
      thisCalculatedProtein = Math.round((currentMakroChangeCalories * 25) / 100 / 4.1);
      thisCalculatedFat = Math.round((currentMakroChangeCalories * 25) / 100 / 9.3);

      setCurrentMakroChangeCarbohydratesPercent('50');
      setCurrentMakroChangeProteinPercent('25');
      setCurrentMakroChangeFatPercent('25');

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    } else {
      if (changeMakroCarbohydrateRef.current) {
        changeMakroCarbohydrateRef.current.value = thisCarbohydratesPercent;
      }
      if (changeMakroProteinRef.current) {
        changeMakroProteinRef.current.value = thisProteinPercent;
      }
      if (changeMakroFatRef.current) {
        changeMakroFatRef.current.value = thisFatPercent;
      }

      thisCalculatedCarbohydrates = Math.round(
        (currentMakroChangeCalories * parseFloat(thisCarbohydratesPercent)) / 100 / 4.1
      );
      thisCalculatedProtein = Math.round((currentMakroChangeCalories * parseFloat(thisProteinPercent)) / 100 / 4.1);
      thisCalculatedFat = Math.round((currentMakroChangeCalories * parseFloat(thisFatPercent)) / 100 / 9.3);

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    }

    setCurrentMakroChangeType(value.value);
  };

  const updateIgredientsNotLikeList = async (ingredientName: string) => {
    try {
      const profile: UserInfo = authContext.userData as UserInfo;

      let thisIngredientObject = [] as any;

      if (profile.ingredientsNotLike !== undefined) {
        thisIngredientObject = [...profile.ingredientsNotLike];
      }

      const isIngredientExists = thisIngredientObject.filter((st: any) => st.name === ingredientName);

      if (isIngredientExists.length > 0) {
        toast.warn('Dieses Lebensmittel hast du schon hinzugefügt!');
      } else {
        await db
          .collection(`tenants/${tenant}/users`)
          .doc(authContext.user?.uid)
          .set({
            ...profile,
            ingredientsNotLike: [...thisIngredientObject, { name: ingredientName }],
          });
        authContext.setUserData((prevProfile: UserInfo) => ({
          ...prevProfile,
        }));
        setCurrentSearchValue('');
        if (searchIngridientRef.current) {
          searchIngridientRef.current.value = '';
          searchIngridientRef.current.focus();
        }
        toast.success('Dein Profil wurde erfolgreich gespeichert!');
      }
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.warn(error.message);
    }
  };

  const deleteIgredientsNotLikeList = async (ingredientName: string) => {
    try {
      const profile: UserInfo = authContext.userData as UserInfo;

      let thisIngredientObject = [] as any;

      if (profile.ingredientsNotLike !== undefined) {
        thisIngredientObject = [...profile.ingredientsNotLike.filter((st: any) => st.name !== ingredientName)];
      }

      await db
        .collection(`tenants/${tenant}/users`)
        .doc(authContext.user?.uid)
        .set({
          ...profile,
          ingredientsNotLike: [...thisIngredientObject],
        });
      authContext.setUserData((prevProfile: UserInfo) => ({
        ...prevProfile,
      }));
      setCurrentSearchValue('');
      toast.success('Dein Profil wurde erfolgreich gespeichert!');
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.warn(error.message);
    }
  };

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  // Funktion zum Hinzufügen oder Aktualisieren
  const addOrUpdateMakroHistory = (obj: any, newEntry: any) => {
    // Kopie von obj erstellen, um Seiteneffekte zu vermeiden
    const updatedObj = { ...obj };

    // Sicherstellen, dass `makroHistory` existiert
    if (!Array.isArray(updatedObj.makroHistory)) {
      updatedObj.makroHistory = [];
    }

    // Prüfen, ob ein Eintrag mit demselben `date` existiert
    const index = updatedObj.makroHistory.findIndex((entry: any) => entry.date === newEntry.date);

    if (index !== -1) {
      // Überschreiben, wenn `date` existiert
      updatedObj.makroHistory[index] = newEntry;
    } else {
      // Andernfalls neuen Eintrag hinzufügen
      updatedObj.makroHistory.push(newEntry);
    }

    return updatedObj;
  };

  const onSubmit = async () => {
    setIsUpdating(true);
    if (currentSection === 'water') {
      if (currentWaterChangeValue > 1000) {
        try {
          const profile: UserInfo = authContext.userData as UserInfo;
          await db
            .collection(`tenants/${tenant}/users`)
            .doc(authContext.user?.uid)
            .set({
              ...profile,
              settings: {
                waterGoal: currentWaterChangeValue,
              },
            });
          authContext.setUserData((prevProfile: UserInfo) => ({
            ...prevProfile,
            settings: {
              waterGoal: currentWaterChangeValue,
            },
          }));

          authContext.setOriginalUserData((prevProfile: UserInfo) => ({
            ...prevProfile,
            settings: {
              waterGoal: currentWaterChangeValue,
            },
          }));
          setCurrentWaterChangeValue(0);
          toast.success('Dein Profil wurde erfolgreich gespeichert!');
          setIsUpdating(false);
        } catch (error: any) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          toast.warn(error.message);
          setIsUpdating(false);
        }
      } else {
        setIsUpdating(false);
        toast.error('Dein Ziel ist zu gering. Bitte gebe mindestens 1000 ml ein!');
      }
    } else if (currentSection === 'calories') {
      if (currentMakroChangeCalories > 1000) {
        let thisCarbohydratesPercent = '';
        let thisProteinPercent = '';
        let thisFatPercent = '';

        if (currentMakroChangeCarbohydratesPercent !== '') {
          thisCarbohydratesPercent = currentMakroChangeCarbohydratesPercent;
        } else {
          thisCarbohydratesPercent = '50';
        }

        if (currentMakroChangeProteinPercent !== '') {
          thisProteinPercent = currentMakroChangeProteinPercent;
        } else {
          thisProteinPercent = '25';
        }

        if (currentMakroChangeFatPercent !== '') {
          thisFatPercent = currentMakroChangeFatPercent;
        } else {
          thisFatPercent = '25';
        }

        const profile: UserInfo = authContext.userData as UserInfo;

        const userDoc = await firebase
          .firestore()
          .collection(`tenants/${tenant}/users`)
          .doc(authContext?.user?.uid)
          .get();

        let newObject = {
          ...profile,
          calories: currentMakroChangeCalories,
          carbohydrates_total: currentMakroChangeCarbohydrates,
          protein_total: currentMakroChangeProtein,
          fat_total: currentMakroChangeFat,
          custom_nutrition_type: currentMakroChangeType,
          custom_carbohydrates_percent:
            currentMakroChangeCarbohydratesPercent !== '' ? currentMakroChangeCarbohydratesPercent : '50',
          custom_protein_percent: currentMakroChangeProteinPercent !== '' ? currentMakroChangeProteinPercent : '25',
          custom_fat_percent: currentMakroChangeFatPercent !== '' ? currentMakroChangeFatPercent : '25',
        } as any;

        if (profile.makroHistory === undefined) {
          newObject = {
            ...newObject,
            makroHistory: [
              {
                calories: profile.calories,
                carbohydrates_total: profile.carbohydrates_total,
                protein_total: profile.protein_total,
                fat_total: profile.fat_total,
                ...(profile.custom_carbohydrates_percent && {
                  carbohydratesPercent: profile.custom_carbohydrates_percent,
                }),
                ...(profile.custom_protein_percent && {
                  proteinPercent: profile.custom_protein_percent,
                }),
                ...(profile.custom_fat_percent && { fatPercent: profile.custom_fat_percent }),
                ...(profile.custom_nutrition_type && { makroType: profile.custom_nutrition_type }),
                date: moment.unix(profile.created.seconds).utc().format('YYYY-MM-DD'),
                dateTimeStamp: moment.unix(profile.created.seconds).utc().unix(),
              },
            ],
          };
        }

        if (currentMakroChangeCalories !== userDoc?.data()?.calories) {
          // Beispiel für einen neuen Eintrag
          const newMakroEntry = {
            calories: currentMakroChangeCalories,
            carbohydrates_total: currentMakroChangeCarbohydrates,
            protein_total: currentMakroChangeProtein,
            fat_total: currentMakroChangeFat,
            custom_nutrition_type: currentMakroChangeType,
            custom_carbohydrates_percent:
              currentMakroChangeCarbohydratesPercent !== '' ? currentMakroChangeCarbohydratesPercent : '50',
            custom_protein_percent: currentMakroChangeProteinPercent !== '' ? currentMakroChangeProteinPercent : '25',
            custom_fat_percent: currentMakroChangeFatPercent !== '' ? currentMakroChangeFatPercent : '25',
            date: moment().utc().format('YYYY-MM-DD'),
            dateTimeStamp: moment().endOf('day').utc().unix(),
          };

          // Makro-History hinzufügen oder aktualisieren
          newObject = addOrUpdateMakroHistory(newObject, newMakroEntry);
        }

        if (
          currentMakroChangeType === 'user' &&
          parseFloat(thisCarbohydratesPercent) + parseFloat(thisProteinPercent) + parseFloat(thisFatPercent) > 100
        ) {
          setIsUpdating(false);
          toast.error('Du hast bei der Verteilung mehr als 100% angegeben. Bitte prüfe deine Eingaben!');
        } else if (
          currentMakroChangeType === 'user' &&
          parseFloat(thisCarbohydratesPercent) + parseFloat(thisProteinPercent) + parseFloat(thisFatPercent) < 100
        ) {
          setIsUpdating(false);
          toast.error('Du hast bei der Verteilung weniger als 100% angegeben. Bitte prüfe deine Eingaben!');
        } else if (changeCaloriesNeed) {
          setChangeCaloriesNeed(false);
          if (parseFloat(currentMakroChangeFat) > 0) {
            try {
              await db.collection(`tenants/${tenant}/users`).doc(authContext.user?.uid).set(newObject);
              authContext.setUserData(() => newObject);

              authContext.setOriginalUserData(() => newObject);
              toast.success('Dein Profil wurde erfolgreich gespeichert!');
              setIsUpdating(false);

              if (currentMakroChangeType === 'user') {
                setCurrentCarbohydratesPercent(
                  currentMakroChangeCarbohydratesPercent !== '' ? currentMakroChangeCarbohydratesPercent : '50'
                );
                setCurrentProteinPercent(
                  currentMakroChangeProteinPercent !== '' ? currentMakroChangeProteinPercent : '25'
                );
                setCurrentFatPercent(currentMakroChangeFatPercent !== '' ? currentMakroChangeFatPercent : '25');
              } else {
                setCurrentCarbohydratesPercent(
                  makroTypes.filter((st: any) => st.value === currentMakroChangeType)[0].carbohydrates !== undefined
                    ? makroTypes.filter((st: any) => st.value === currentMakroChangeType)[0].carbohydrates
                    : '50'
                );
                setCurrentProteinPercent(
                  makroTypes.filter((st: any) => st.value === currentMakroChangeType)[0].protein !== undefined
                    ? makroTypes.filter((st: any) => st.value === currentMakroChangeType)[0].protein
                    : '25'
                );
                setCurrentFatPercent(
                  makroTypes.filter((st: any) => st.value === currentMakroChangeType)[0].fat !== undefined
                    ? makroTypes.filter((st: any) => st.value === currentMakroChangeType)[0].fat
                    : '25'
                );
              }
            } catch (error: any) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              toast.warn(error.message);
              setIsUpdating(false);
            }
          } else {
            setIsUpdating(false);
            toast.warn('Dein Bedarf wurde nicht verändert!');
          }
        } else {
          setIsUpdating(false);
          toast.warn('Dein Bedarf wurde nicht verändert!');
        }
      } else {
        setIsUpdating(false);
        toast.error('Dein Ziel ist zu gering. Bitte gebe mindestens 1000 Kalorien ein!');
      }
    }
  };

  const handleShareChats = () => {
    db.collection(`tenants/${tenant}/users`).doc(user?.uid).update({
      shareChats: !authContext.userData?.shareChats,
    });
  };

  const handlePushNotifications = () => {
    db.collection(`tenants/${tenant}/users`)
      .doc(user?.uid)
      .update({
        notifications: {
          chat: !authContext.userData?.notifications?.chat,
        },
      });
  };

  const renderFoodSettings = () => {
    return (
      <div className="mb-30 border-b border-secondaryBgColor">
        {currentSection === 'start' ? (
          <div className={styles.content}>
            <div
              className={styles.button}
              onClick={() => setCurrentSection('water')}
              onKeyDown={() => setCurrentSection('water')}
              aria-hidden="true"
            >
              <div>
                <Icon name="waterGlass" height={55} width={55} className={styles.icon} />
                <Headline level={4} className={styles.label}>
                  Wasserbedarf
                </Headline>
              </div>
            </div>
            {(tenantData?.settings?.functions?.caloriesGoal === undefined ||
              tenantData?.settings?.functions?.caloriesGoal === true ||
              userData?.isAdminUser ||
              userData?.isTrainerUser ||
              userData?.isLocationAdminUser ||
              localStorage.getItem('currentUserToken')) && (
              <div
                className={styles.button}
                onClick={() => setCurrentSection('calories')}
                onKeyDown={() => setCurrentSection('calories')}
                aria-hidden="true"
              >
                <div>
                  <ChartPieIcon width={55} height={55} className={styles.icon} />
                  <Headline level={4} className={styles.label}>
                    Kalorienbedarf
                  </Headline>
                </div>
              </div>
            )}

            <div
              className={styles.button}
              onClick={() => setCurrentSection('food')}
              onKeyDown={() => setCurrentSection('food')}
              aria-hidden="true"
            >
              <div>
                <Icon name="cooking" height={55} width={55} className={styles.icon} />
                <Headline level={4} className={styles.label}>
                  Lebensmittel
                </Headline>
              </div>
            </div>
            {userData?.role === 2 && (
              <div
                className={styles.button}
                onClick={() => setCurrentSection('cookie')}
                onKeyDown={() => setCurrentSection('cookie')}
                aria-hidden="true"
              >
                <div>
                  <BookOpenIcon width={55} height={55} className={styles.icon} />
                  <Headline level={4} className={styles.label}>
                    Cookies & Tracking
                  </Headline>
                </div>
              </div>
            )}
          </div>
        ) : currentSection === 'water' ? (
          <div>
            <div className="font-bold text-20 pt-20">Wasserbedarf</div>
            <div className="pt-30 font-extralight text-base pr-15 w-full">
              <div className="text-16 flex gap-30">
                <div>
                  <span className="font-semibold">Aktuelles Tagesziel:</span>
                </div>
                <div> {getWaterGoal('number')} ml</div>
              </div>
              <hr className="mt-10 mb-20" />
              <div className="pt-4">
                <div className="block font-extralight pb-5">Bitte trage hier dein gewünschtes Ziel ein:</div>
                <div className="w-100 flex rounded-md border-solid border border-textColor border-opacity-30 py-2 px-2">
                  <div>
                    <input
                      id="calories"
                      name="calories"
                      type="number"
                      onFocus={setBodyClassForMobileSafari}
                      onBlur={removeBodyClassForMobileSafari}
                      className="w-100 bg-transparent appearance-none block text-18 font-semibold placeholder-gray-400 focus:outline-none bg-opacity-20 text-textColor text-center"
                      onChange={updateWaterGoal}
                    />
                  </div>
                  <div className="pl-10 font-semibold">ml</div>
                </div>
              </div>
            </div>
          </div>
        ) : currentSection === 'calories' ? (
          <div>
            <div className="font-bold text-20 pt-20 ">Kalorienbedarf</div>
            <div className="font-bold pt-20">Dein aktueller Bedarf:</div>
            <div className="pt-10 flex md:pl-20">
              <div className={styles.nutritionBorderItemFirst}>
                <div>
                  <div className="font-semibold text-base">{Math.round(parseFloat(userData!.calories))}</div>
                  <div className={styles.recipeLabel}>Kalorien</div>
                </div>
                <span className="divider" />
              </div>
              <div className={styles.nutritionBorderItem}>
                <div className="font-semibold text-base">{userData?.carbohydrates_total}g</div>
                <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
              </div>
              <div className={styles.nutritionBorderItem}>
                <div className="font-semibold text-base">{userData?.protein_total}g</div>
                <div className={styles.recipeLabel}>{t('Protein')}</div>
              </div>
              <div className="text-center px-20">
                <div className="font-semibold text-base">{userData?.fat_total}g</div>
                <div className={styles.recipeLabel}>{t('Fat')}</div>
              </div>
            </div>
            <div className="font-bold pt-30">Deine aktuelle Makronährstoff Verteilung:</div>
            <div>
              Variante:{' '}
              {userData?.custom_nutrition_type !== undefined
                ? makroTypes.filter((st: any) => st.value === userData?.custom_nutrition_type)[0].label
                : 'Standard'}
            </div>
            <div className="text-12">{currentCarbohydratesPercent}% Kohlenhydrate</div>
            <div className="text-12">{currentProteinPercent}% Eiweiß</div>
            <div className="text-12">{currentFatPercent}% Fett</div>
            {changeCaloriesNeed ? (
              <>
                <div className="pt-20">
                  <hr />
                </div>
                <div className="pt-10 flex justify-between">
                  <div className="pt-10 text-20 font-semibold">Neuer Bedarf</div>
                  <div className="pt-10 my-auto">
                    <Button onClick={() => setChangeCaloriesNeed(false)} buttonStyle="white" className="py-5">
                      Abbrechen
                    </Button>
                  </div>
                </div>

                <div className="pt-4">
                  <div className="block font-extralight pb-5">Trage hier deinen neuen Bedarf ein: </div>
                  <div className="w-120 rounded-md border-solid border border-textColor border-opacity-30 py-2 px-2">
                    <input
                      id="calories"
                      name="calories"
                      type="number"
                      onFocus={setBodyClassForMobileSafari}
                      onBlur={removeBodyClassForMobileSafari}
                      className="w-100 my-auto appearance-none block text-18 font-semibold bg-transparent focus:outline-none bg-opacity-20 text-textColor text-center"
                      onChange={updateMakroCalories}
                      ref={changeMakroCaloriesRef}
                    />
                  </div>
                </div>
                <div className="font-semibold pt-30">Makronährstoff Verteilung</div>
                <div className="flex w-300 gap-40 pt-2">
                  <div className="flex-1">
                    <Select
                      name="makroType"
                      className="react-select-container-full"
                      classNamePrefix="react-select"
                      unstyled
                      options={makroTypes}
                      value={
                        currentMakroChangeType !== ''
                          ? makroTypes.filter((st: any) => st.value === currentMakroChangeType)
                          : makroTypes[0]
                      }
                      onChange={updateMakroType}
                    />
                  </div>
                </div>
                <div className="flex gap-20 md:gap-40 pt-20 ">
                  <div>
                    <div className="block text-sm font-regular mb-1">Kohlenhydrate</div>
                    <div className="w-80 rounded-md border-solid border border-textColor border-opacity-30 py-2 px-2 relative flex  flex-wrap items-stretch">
                      <input
                        id="carbohydrates"
                        name="carbohydrates"
                        type="number"
                        onFocus={setBodyClassForMobileSafari}
                        onBlur={removeBodyClassForMobileSafari}
                        onChange={changeUserCarbohydrates}
                        disabled={currentMakroChangeType !== 'user'}
                        defaultValue="50"
                        className="w-40 my-auto appearance-none block text-18 pl-5 font-semibold bg-transparent focus:outline-none bg-opacity-20 text-textColor "
                        ref={changeMakroCarbohydrateRef}
                      />
                      <span className="leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3">
                        %
                      </span>
                    </div>
                    <div className="w-80 block text-sm font-extralight text-center pt-5">
                      {currentMakroChangeCarbohydrates}g
                    </div>
                  </div>

                  <div>
                    <div className="block text-sm font-regular mb-1">Eiweiß</div>
                    <div className="w-80 rounded-md border-solid border border-textColor border-opacity-30 py-2 px-2 relative flex  flex-wrap items-stretch">
                      <input
                        id="protein"
                        name="protein"
                        type="number"
                        onChange={changeUserProtein}
                        onFocus={setBodyClassForMobileSafari}
                        onBlur={removeBodyClassForMobileSafari}
                        disabled={currentMakroChangeType !== 'user'}
                        defaultValue="25"
                        className="w-40 my-auto appearance-none block text-18 pl-5 font-semibold bg-transparent focus:outline-none bg-opacity-20 text-textColor "
                        ref={changeMakroProteinRef}
                      />
                      <span className="leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3">
                        %
                      </span>
                    </div>
                    <div className="w-80 block text-sm font-extralight text-center pt-5">
                      {currentMakroChangeProtein}g
                    </div>
                  </div>

                  <div>
                    <div className="block text-sm font-regular mb-1">Fett</div>
                    <div className="w-80 rounded-md border-solid border border-textColor border-opacity-30 py-2 px-2 relative flex  flex-wrap items-stretch">
                      <input
                        id="fat"
                        name="fat"
                        type="number"
                        onChange={changeUserFat}
                        onFocus={setBodyClassForMobileSafari}
                        onBlur={removeBodyClassForMobileSafari}
                        disabled={currentMakroChangeType !== 'user'}
                        defaultValue="25"
                        className="w-40 my-auto appearance-none block text-18 pl-5 font-semibold bg-transparent focus:outline-none bg-opacity-20 text-textColor "
                        ref={changeMakroFatRef}
                      />
                      <span className="leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3">
                        %
                      </span>
                    </div>
                    <div className="w-80 block text-sm font-extralight text-center pt-5">{currentMakroChangeFat}g</div>
                  </div>
                </div>
                <div className="h-150">
                  <></>
                </div>
              </>
            ) : (
              <div className="pt-20">
                <Button onClick={() => setChangeCaloriesNeed(true)}>Bedarf anpassen</Button>
              </div>
            )}
          </div>
        ) : currentSection === 'cookie' ? (
          <>
            <div className="font-bold text-20 pt-20">Cookies & Tracking</div>
            <div className="pt-30 font-extralight text-base pr-15 w-full">
              <div className="text-16 flex gap-30">
                <div>
                  <span className="font-semibold">Aktuelle Einstellung:</span>
                </div>
                <div>
                  {userData?.cookieBannerType === 'all'
                    ? 'Alle Cookies akzeptiert'
                    : userData?.cookieBannerType === 'technical'
                    ? 'Nur technisch notwendige Cookies akzeptiert'
                    : 'Noch keine Einstellung getroffen'}
                </div>
              </div>
              <hr className="mt-10 mb-20" />
              <div className="pt-4">
                <CookieBanner show />
              </div>
            </div>
          </>
        ) : currentSection === 'food' ? (
          <div>
            <div className="font-bold text-20 pt-20">Lebensmittel, die du nicht magst</div>
            <div className="font-extralight pt-30">
              Suche in dem Suchfeld nach Lebensmittel, die du nicht magst und speichere diese in deinem Profil!
            </div>
            <div className="font-extralight pt-5">
              Die Lebensmittel werden dann im Alghorithmus automatisch vorbelegt und du kannst aber bei Bedarf anpassen!
            </div>
            <div className="pt-20">
              <SearchBox searchValue={currentSearchValue} onChange={changeSearchValue} thisRef={searchIngridientRef} />
            </div>
            {currentSearchValue.length < 3 && (
              <div className="flex flex-wrap gap-10 pt-10">
                {userData?.ingredientsNotLike?.map((item, index) => (
                  <div key={index} className="rounded-3xl bg-accentColor py-5 pl-15 pr-30 relative font-bold text-14">
                    <div>
                      <div>{item.name}</div>
                      <div className={styles.editIcon}>
                        <XIcon
                          width={15}
                          height={15}
                          className="text-textColor"
                          onClick={() => deleteIgredientsNotLikeList(item.name)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {currentSearchValue.length >= 3 && (
              <div className="pt-10">
                {ingredientFilteredList?.map((item, index) => (
                  <div
                    key={index}
                    className="flex"
                    onClick={() => updateIgredientsNotLikeList(item.name)}
                    onKeyDown={() => updateIgredientsNotLikeList(item.name)}
                    aria-hidden="true"
                  >
                    <div className="cursor-pointer border-transparent border-2 hover:border-accentColor rounded-3xl bg-blackSemiDark py-10 px-15 relative font-bold text-14 mt-10">
                      <div>
                        <div>{item.name}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };
  return (
    <TransitionContainer isShown={isShown}>
      <div className={styles.header}>
        <Headline level={1} className="mb-20">
          {t(title)}
        </Headline>
        {currentSection === 'start' ? (
          <Headline level={4} displayBackBtn goBack={goBack}>
            {t('Return')}
          </Headline>
        ) : (
          <div className="flex gap-10 flex-wrap justify-between items-center">
            <div
              onClick={() => setCurrentSection('start')}
              onKeyDown={() => setCurrentSection('start')}
              aria-hidden="true"
            >
              <Headline level={4} displayBackBtn>
                {t('Return')}
              </Headline>
            </div>
            <Button
              isPending={isUpdating}
              disabled={isUpdating}
              buttonStyle={isUpdating ? 'dark' : 'primary'}
              onClick={onSubmit}
              className="w-150 tablet:w-200"
            >
              {t('Change')}
            </Button>
          </div>
        )}
      </div>

      {(authContext.userData?.role === ROLE_USER || authContext.userData?.role === ROLE_MEMBER) && renderFoodSettings()}

      {authContext.userData?.role === ROLE_MEMBER && tenantData?.features?.chat && tenantData?.features?.chat === true && (
        <div className="pb-20 mt-20 mb-10 border-b border-secondaryBgColor">
          <Headline level={3}>{t('Chat')}</Headline>
          <SwitchButton
            label="Ich bin damit einverstanden, dass mich neben meinem Trainer auch andere Trainer kontaktieren können."
            enabled={authContext.userData?.shareChats}
            isDisabled={
              !(
                authContext.userData?.isAdminUser ||
                authContext.userData?.isTrainerUser ||
                authContext.userData?.isLocationAdminUser ||
                localStorage.getItem('currentUserToken') ||
                tenantData?.settings?.functions?.profileEatForm === undefined ||
                tenantData?.settings?.functions?.profileEatForm === true
              )
            }
            onChange={handleShareChats}
          />
        </div>
      )}

      {tenantData?.features?.chat && tenantData?.features?.chat === true && (
        <div className="pt-20">
          <Headline level={3}>{t('Push Notifications')}</Headline>
          <SwitchButton
            label="Chat-Benachrichtigungen aktivieren"
            enabled={
              authContext.userData?.notifications?.chat || authContext.userData?.notifications?.chat === undefined
            }
            isDisabled={
              !(
                authContext.userData?.role === 2 ||
                authContext.userData?.isAdminUser ||
                authContext.userData?.isTrainerUser ||
                authContext.userData?.isLocationAdminUser ||
                localStorage.getItem('currentUserToken') ||
                tenantData?.settings?.functions?.profileEatForm === undefined ||
                tenantData?.settings?.functions?.profileEatForm === true
              )
            }
            onChange={handlePushNotifications}
          />
        </div>
      )}
    </TransitionContainer>
  );
};

export default Settings;
