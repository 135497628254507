import React, { useContext, useState, useEffect } from 'react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CalendarIcon,
  ArrowsRightLeftIcon as SwitchHorizontalIcon,
} from '@heroicons/react/24/outline';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  LineChart,
  ReferenceLine,
  Label,
} from 'recharts';
import { AuthContext } from 'providers/AuthProvider';
import { useHistory } from 'react-router-dom';
import firebase from 'services/firebase';
import { defaultTextColor } from 'layouts/Theme';
import ReactLoading from 'react-loading';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import RatingGraph from '../RatingGraph';
import WaterGraph from '../WaterGraph';
import StepGraph from '../StepGraph';
import WorkoutGraph from '../WorkoutGraph';

type Props = {
  planStateValue?: any;
  thisWeekIndex?: number;
  withClick?: boolean;
  updateDayId?: string;
  userData: UserInfo;
  userId: string;
  startDate: any;
  endDate: any;
};

const AnalyzeOverview: React.FC<Props> = ({
  planStateValue,
  withClick = false,
  updateDayId,
  userData,
  userId,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();

  const { theme, tenant } = useContext(AuthContext);
  const history = useHistory();

  const [currentWeekDate, setCurrentWeekDate] = useState(moment());
  const [loadingData, setLoadingData] = useState(false);

  const [chartType, setChartType] = useState('bar');

  const [jumpDate, setJumpDate] = useState<any>(null);

  const CustomDot = (props: any) => {
    const { cx, cy, value, stroke } = props;

    if (value === 0) return null; // Kein Punkt zeichnen, wenn der Wert 0 ist

    return null;

    return <circle cx={cx} cy={cy} r={4} fill={stroke} stroke="white" strokeWidth={1} />;
  };

  const [currentWeekDataObject, setCurrentWeekDataObject] = useState([
    {
      name: 'Mo.',
      longLabel: 'Montag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'Di.',
      longLabel: 'Dienstag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'Mi.',
      longLabel: 'Mittwoch',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'Do.',
      longLabel: 'Donnerstag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'Fr.',
      longLabel: 'Freitag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'Sa.',
      longLabel: 'Samstag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
    {
      name: 'So.',
      longLabel: 'Sonntag',
      Kalorien: 0,
      Kohlenhydrate: 0,
      Eiweiß: 0,
      Fett: 0,
      date: '',
      dayId: 0,
    },
  ]);

  const CustomTooltip = ({ active, payload, label }: { active: any; label: any; payload: any }) => {
    if (active) {
      return (
        <div className="bg-lightGray text-textColor px-30 py-30">
          {currentWeekDataObject?.filter((item: any) => item.date === label)[0].Kalorien > 0 ? (
            <>
              {planStateValue?.examplePlan === false && (
                <div className="font-bold">
                  {currentWeekDataObject?.filter((item: any) => item.date === label)[0].longLabel}
                </div>
              )}
              <div className="font-bold pb-10">
                {moment(
                  currentWeekDataObject?.filter((item: any) => item.date === label)[0]?.date,
                  'DD.MM.YYYY'
                ).format('dddd')}
                , {currentWeekDataObject?.filter((item: any) => item.date === label)[0]?.date}
              </div>
              <div>
                <p className="label text-calories"> {`${payload[0].name} : ${payload[0].value} %`} </p>
              </div>
              <div>
                <p className="label text-carbohydrates"> {`${payload[1].name} : ${payload[1].value} %`} </p>
              </div>
              <div>
                <p className="label text-protein"> {`${payload[2].name} : ${payload[2].value} %`} </p>
              </div>
              <div>
                <p className="label text-fat"> {`${payload[3].name} : ${payload[3].value} %`} </p>
              </div>
            </>
          ) : (
            <div>
              <div className="font-bold pb-10">
                {moment(
                  currentWeekDataObject?.filter((item: any) => item.date === label)[0]?.date,
                  'DD.MM.YYYY'
                ).format('dddd')}
                , {currentWeekDataObject?.filter((item: any) => item.date === label)[0]?.date}
              </div>
              <div>Für diesen Tag sind noch keine Daten vorhanden!</div>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  // Get Makro Percentage Values
  const getMakroPercentages = (type: string, custom_value: any, value: any) => {
    let thisCalculatedValue = 0;

    if (custom_value) {
      thisCalculatedValue = Math.round((100 * value) / custom_value);
    } else if (type === 'kcal') {
      thisCalculatedValue = Math.round((100 * value) / parseFloat(userData.calories));
    } else if (type === 'carbohydrates') {
      thisCalculatedValue = Math.round((100 * value) / userData.carbohydrates_total);
    } else if (type === 'protein') {
      thisCalculatedValue = Math.round((100 * value) / userData.protein_total);
    } else if (type === 'fat') {
      thisCalculatedValue = Math.round((100 * value) / userData.fat_total);
    }

    return thisCalculatedValue;
  };

  const weekBackward = () => {
    const thisNewDate = moment(currentWeekDate).subtract(7, 'days'); // Eine ganze Woche zurück

    setCurrentWeekDate(thisNewDate);
    updateWeekValues(thisNewDate);
  };

  const weekForward = () => {
    const thisNewDate = moment(currentWeekDate).add(7, 'days'); // Eine ganze Woche nach vorne

    setCurrentWeekDate(thisNewDate);
    updateWeekValues(thisNewDate);
  };

  const checkCurrentPlanExists = (
    date: any,
    index: number,
    filteredList: any,
    newWeekObject: any,
    dateFormat: string
  ) => {
    filteredList?.map(
      (plan: PlanType) => plan.startDate && getCurrentPlanData(plan, date, index, newWeekObject, dateFormat)
    );
  };

  const getCurrentPlanData = (plan: PlanType, date: any, index: number, newWeekObject: any, dateFormat: string) => {
    const thisStartDate = moment.unix(plan?.startDate.seconds).utc().format('YYYY-MM-DD');
    const thisEndDate = moment.unix(plan.endDate.seconds).utc().format('YYYY-MM-DD');

    const newDayItem = plan?.dayEntries.filter(
      (item: any) => moment.unix(item.date.seconds).utc().format('YYYYMMDD') === date.format('YYYYMMDD')
    )[0];

    if (
      moment(date.format('YYYY-MM-DD')).isBetween(thisStartDate, thisEndDate, undefined, '(]') ||
      moment(date.format('YYYY-MM-DD')).isBetween(thisStartDate, thisEndDate, undefined, '[)') ||
      moment(date.format('YYYY-MM-DD')).isBetween(thisStartDate, thisEndDate, undefined, '[]')
    ) {
      const getThisWeekName = moment.unix(newDayItem.date.seconds).utc().format('dddd');
      const getThisShortWeekName = moment.unix(newDayItem.date.seconds).utc().format('dd');

      newWeekObject.push({
        name: `${getThisShortWeekName}.`,
        longLabel: getThisWeekName,
        date:
          dateFormat === 'short'
            ? moment(moment.unix(newDayItem?.date.seconds).utc()).locale('de').format('DD[.]MM[.]')
            : moment(moment.unix(newDayItem?.date.seconds).utc()).locale('de').format('DD[.]MM[.]YYYY'),
        dayId: newDayItem?.id,
        Kalorien: getMakroPercentages(
          'kcal',
          newDayItem?.custom_kcal_total
            ? newDayItem?.custom_kcal_total
            : plan?.custom_kcal_total
            ? plan?.custom_kcal_total
            : undefined,
          newDayItem?.kcal_total
        ),
        Kohlenhydrate: getMakroPercentages(
          'carbohydrates',
          newDayItem?.custom_carbohydrates_total
            ? newDayItem?.custom_carbohydrates_total
            : plan?.custom_carbohydrates_total
            ? plan?.custom_carbohydrates_total
            : undefined,
          newDayItem?.carbohydrates_total
        ),
        Eiweiß: getMakroPercentages(
          'protein',
          newDayItem?.custom_protein_total
            ? newDayItem?.custom_protein_total
            : plan?.custom_protein_total
            ? plan?.custom_protein_total
            : undefined,
          newDayItem?.protein_total
        ),
        Fett: getMakroPercentages(
          'fat',
          newDayItem?.custom_fat_total
            ? newDayItem?.custom_fat_total
            : plan?.custom_fat_total
            ? plan?.custom_fat_total
            : undefined,
          newDayItem?.fat_total
        ),
      });
    }
  };

  const updateWeekValues = async (date: any) => {
    setLoadingData(true);

    if (chartType === 'line') {
      await updateValuesForGivenStartEndDate();
      return;
    }

    const userPlansCollection = await firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .doc(userId)
      .collection('plans')
      .orderBy('startDate.seconds', 'desc')
      .get();

    const userPlanList: PlanType[] = userPlansCollection.docs.map(
      item => ({ uid: item.id, ...item.data() } as PlanType)
    );

    // Neue Start- und Enddaten basierend auf dem übergebenen Datum
    const thisEndDate = moment(date).utc().format('YYYY-MM-DD'); // Das übergebene Datum als Ende
    const thisStartDate = moment(date).subtract(6, 'days').utc().format('YYYY-MM-DD'); // Sechs Tage vor dem Datum

    const filteredPlanList = userPlanList.filter(
      element =>
        moment(moment.unix(element.startDate.seconds).utc().format('YYYY-MM-DD')).isBetween(
          thisStartDate,
          thisEndDate,
          undefined,
          '[]'
        ) ||
        moment(moment.unix(element.endDate.seconds).utc().format('YYYY-MM-DD')).isBetween(
          thisStartDate,
          thisEndDate,
          undefined,
          '[]'
        )
    );

    const newWeekObject = [] as any;

    // Jetzt von sechs Tagen vor `date` bis zu `date` iterieren
    for (let i = 0; i < 7; i += 1) {
      const thisDate = moment(thisStartDate).add(i, 'days'); // Starte vom neuen Startdatum und addiere i Tage
      const getThisWeekName = moment(thisDate).format('dddd');
      const getThisShortWeekName = moment(thisDate).format('dd');
      const arrayLengthBefore = newWeekObject.length;

      checkCurrentPlanExists(thisDate, i, filteredPlanList, newWeekObject, 'long');
      if (newWeekObject.length === arrayLengthBefore) {
        newWeekObject.push({
          date: thisDate.format('DD.MM.YYYY'),
          dayId: i,
          Kalorien: 0,
          Kohlenhydrate: 0,
          Eiweiß: 0,
          Fett: 0,
          name: `${getThisShortWeekName}.`,
          longLabel: getThisWeekName,
        });
      }
    }

    setCurrentWeekDataObject(newWeekObject);
    setLoadingData(false);
  };

  const jumpToDate = () => {
    const thisNewDate = moment(jumpDate).endOf('day');

    setCurrentWeekDate(thisNewDate);
    updateWeekValues(thisNewDate);
  };

  const changeChartView = async () => {
    if (chartType === 'bar') {
      await updateValuesForGivenStartEndDate();
      setChartType('line');
    } else {
      setChartType('bar');
    }
  };

  const updateValuesForGivenStartEndDate = async () => {
    setLoadingData(true);

    const userPlansCollection = await firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .doc(userId)
      .collection('plans')
      .orderBy('startDate.seconds', 'desc')
      .get();

    const userPlanList: PlanType[] = userPlansCollection.docs.map(
      item => ({ uid: item.id, ...item.data() } as PlanType)
    );

    // Neue Start- und Enddaten basierend auf dem übergebenen Datum
    const thisEndDate = moment(endDate).utc().format('YYYY-MM-DD'); // Das übergebene Datum als Ende
    const thisStartDate = moment(startDate).add(1, 'days').utc().format('YYYY-MM-DD'); // Sechs Tage vor dem Datum

    const getDifference = moment(endDate).diff(moment(startDate), 'days') + 1;

    const filteredPlanList = userPlanList.filter(
      element =>
        moment(moment.unix(element.startDate.seconds).utc().format('YYYY-MM-DD')).isBetween(
          thisStartDate,
          thisEndDate,
          undefined,
          '[]'
        ) ||
        moment(moment.unix(element.endDate.seconds).utc().format('YYYY-MM-DD')).isBetween(
          thisStartDate,
          thisEndDate,
          undefined,
          '[]'
        )
    );

    const newWeekObject = [] as any;

    // Jetzt von sechs Tagen vor `date` bis zu `date` iterieren
    for (let i = 0; i < getDifference; i += 1) {
      const thisDate = moment(thisStartDate).add(i, 'days'); // Starte vom neuen Startdatum und addiere i Tage
      const getThisWeekName = moment(thisDate).format('dddd');
      const getThisShortWeekName = moment(thisDate).format('dd');
      const arrayLengthBefore = newWeekObject.length;

      checkCurrentPlanExists(thisDate, i, filteredPlanList, newWeekObject, 'short');
      if (newWeekObject.length === arrayLengthBefore) {
        newWeekObject.push({
          date: thisDate.format('DD.MM.'),
          dayId: i,
          Kalorien: 0,
          Kohlenhydrate: 0,
          Eiweiß: 0,
          Fett: 0,
          name: `${getThisShortWeekName}.`,
          longLabel: getThisWeekName,
        });
      }
    }

    setCurrentWeekDataObject(newWeekObject);
    setLoadingData(false);
  };

  useEffect(() => {
    const thisNewDate = moment(endDate);

    setCurrentWeekDate(thisNewDate);
    updateWeekValues(thisNewDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, chartType]);

  useEffect(() => {
    if (jumpDate !== null) {
      jumpToDate();
    }
  }, [jumpDate]);

  const CustomInputSelect = React.forwardRef(({ value, onClick }: any, ref: React.Ref<HTMLButtonElement>) => (
    <div>
      {chartType === 'bar' && (
        <div className="my-auto flex gap-10 cursor-pointer" onClick={() => onClick()} aria-hidden>
          <CalendarIcon
            width={30}
            height={30}
            className="text-accentColor cursor-pointer"
            onClick={() => weekForward()}
          />
          <div className="font-extralight my-auto text-14">Springe zu Datum</div>
        </div>
      )}
    </div>
  ));

  const CustomXAxisTick = (props: any) => {
    const { x, y, payload } = props;
    const date = moment(payload.value, 'DD.MM.YYYY');

    return (
      <g transform={`translate(${Number(x)},${Number(y) + 15})`}>
        <text textAnchor="middle" fill={theme?.textColor ?? '#ffffff'} fontSize="14">
          <tspan x="0" dy="0" className="font-extralight opacity-50">
            {moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY')}
          </tspan>
          <tspan x="0" dy="18" fontWeight="bold">
            {moment(date, 'DD.MM.YYYY').format('dd')}
          </tspan>
        </text>
      </g>
    );
  };

  return (
    <div className="pt-10">
      <div className="bg-lightGray rounded-3xl py-30 px-20">
        <div className="pl-20 pr-30 pb-10 flex flex-wrap gap-y-10 justify-between items-center">
          <div className="flex gap-20 flex-wrap">
            <div className="font-bold text-20">
              <div>Nährwert Analyse</div>
              {chartType === 'bar' ? (
                <div className="text-14 opacity-50 font-extralight">
                  {moment(currentWeekDate).subtract(6, 'days').format('DD[.]MM[.]YY')} -{' '}
                  {moment(currentWeekDate).format('DD[.]MM[.]YY')}
                </div>
              ) : (
                <div className="text-14 opacity-50 font-extralight">
                  {moment(startDate).format('DD[.]MM[.]YY')} - {moment(endDate).format('DD[.]MM[.]YY')}
                </div>
              )}

              <div className="flex gap-5 pt-10 cursor-pointer" onClick={() => changeChartView()} aria-hidden>
                <div className="my-auto font-extralight text-14">
                  {chartType === 'bar' ? 'Balkendiagramm' : 'Kurvendiagramm'}
                </div>
                <div className="my-auto">
                  <SwitchHorizontalIcon width={20} height={20} className="text-accentColor cursor-pointer" />
                </div>
              </div>
            </div>
          </div>

          <DatePicker
            locale="de"
            onChange={date => setJumpDate(date)}
            dateFormat="dd.MM.yyyy" // Korrektes Format
            withPortal
            disabledKeyboardNavigation
            calendarStartDay={1}
            onFocus={e => e.target.blur()}
            customInput={<CustomInputSelect />}
            renderCustomHeader={({
              monthDate,
              customHeaderCount,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px',
                    borderBottom: '1px solid #ccc',
                  }}
                >
                  {/* Linker Chevron-Pfeil */}

                  <button
                    onClick={decreaseMonth}
                    type="button"
                    disabled={prevMonthButtonDisabled}
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: prevMonthButtonDisabled ? 'not-allowed' : 'pointer',
                      fontSize: '16px',
                      outline: 'none',
                      padding: '0px 0px 0px 10px',
                    }}
                  >
                    &lt;
                  </button>

                  {/* Monats- und Jahresanzeige */}
                  <div>
                    <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                      {monthDate.toLocaleString('de-DE', { month: 'long', year: 'numeric' })}
                    </span>
                  </div>

                  <button
                    onClick={increaseMonth}
                    type="button"
                    disabled={nextMonthButtonDisabled}
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: nextMonthButtonDisabled ? 'not-allowed' : 'pointer',
                      fontSize: '16px',
                      outline: 'none',
                      padding: '0px 10px 0px 0px',
                    }}
                  >
                    &gt;
                  </button>
                </div>
              );
            }}
          />

          <div className="my-auto flex gap-20 flex-wrap">
            <div className="flex gap-1 flex-wrap">
              <div className="rounded-full w-20 h-20 bg-calories ml-1 mr-5 my-auto">
                <></>
              </div>
              <div>Kalorien</div>
            </div>
            <div className="flex gap-1 flex-wrap">
              <div className="rounded-full w-20 h-20 bg-carbohydrates ml-1 mr-5 my-auto">
                <></>
              </div>
              <div>Kohlenhydrate</div>
            </div>
            <div className="flex gap-1 flex-wrap">
              <div className="rounded-full w-20 h-20 bg-protein ml-1 mr-5 my-auto">
                <></>
              </div>
              <div>Eiweiß</div>
            </div>
            <div className="flex gap-1 flex-wrap">
              <div className="rounded-full w-20 h-20 bg-fat ml-1 mr-5 my-auto">
                <></>
              </div>
              <div>Fett</div>
            </div>
          </div>
        </div>
        {loadingData ? (
          <div className="w-full flex justify-center h-300">
            <ReactLoading
              type="bars"
              width={20}
              height={20}
              color={theme?.textColor ?? defaultTextColor}
              className="my-auto"
            />
          </div>
        ) : (
          <div>
            <div className="flex">
              {chartType === 'bar' && (
                <div className="my-auto">
                  <ChevronLeftIcon
                    width={40}
                    height={40}
                    className="text-accentColor cursor-pointer"
                    onClick={() => weekBackward()}
                  />
                </div>
              )}

              <ResponsiveContainer width="100%" height={300} className={chartType === 'bar' ? 'hidden' : 'block'}>
                <LineChart
                  data={currentWeekDataObject} // Originaldaten ohne Änderung nutzen
                  margin={{ top: 20, right: 20, left: -20, bottom: 20 }}
                >
                  <XAxis dataKey="date" tickLine={false} padding={{ left: 10, right: 10 }} />
                  <YAxis
                    tickCount={4}
                    tick={{ fill: theme?.textColor ?? '#ffffff', fontSize: 14, fontWeight: 'normal' }}
                  />
                  <Tooltip content={<CustomTooltip active={undefined} payload={undefined} label={undefined} />} />
                  <CartesianGrid strokeDasharray="1" opacity={0.15} vertical={false} />

                  <Line dataKey="Kalorien" stroke="#01A2E0" strokeWidth={1} dot={<CustomDot />} />
                  <Line dataKey="Kohlenhydrate" stroke="#DE4C39" strokeWidth={1} dot={<CustomDot />} />
                  <Line dataKey="Eiweiß" stroke="#8DC703" strokeWidth={1} dot={<CustomDot />} />
                  <Line dataKey="Fett" stroke="#FBA500" strokeWidth={1} dot={<CustomDot />} />
                </LineChart>
              </ResponsiveContainer>
              <ResponsiveContainer width="100%" height={300} className={chartType === 'bar' ? 'block' : 'hidden'}>
                <BarChart
                  data={currentWeekDataObject}
                  margin={{ top: 20, right: 20, left: -20, bottom: 20 }} // linke Margin auf 0 reduziert
                  barCategoryGap="15%"
                  barGap={2}
                >
                  <XAxis dataKey="date" tick={<CustomXAxisTick />} tickLine={false} padding={{ left: 10, right: 10 }} />
                  <YAxis
                    tickCount={4}
                    tick={{ fill: theme?.textColor ?? '#ffffff', fontSize: 14, fontWeight: 'normal' }}
                  />
                  <Tooltip content={<CustomTooltip active={undefined} payload={undefined} label={undefined} />} />
                  <CartesianGrid strokeDasharray="1" opacity={0.15} vertical={false} />
                  <Bar dataKey="Kalorien" fill="#01A2E0" radius={[5, 5, 0, 0]} />
                  <Bar dataKey="Kohlenhydrate" fill="#DE4C39" radius={[5, 5, 0, 0]} />
                  <Bar dataKey="Eiweiß" fill="#8DC703" radius={[5, 5, 0, 0]} />
                  <Bar dataKey="Fett" fill="#FBA500" radius={[5, 5, 0, 0]} />
                  <ReferenceLine
                    y={100}
                    stroke={theme?.textColor ?? '#ffffff'}
                    strokeWidth={2}
                    alwaysShow
                    strokeDasharray="3 3"
                  >
                    <Label
                      value="100%"
                      offset={6}
                      position="insideBottomRight"
                      fill={theme?.textColor ?? '#ffffff'}
                      fontWeight="bold"
                      fontSize="16"
                    />
                  </ReferenceLine>
                </BarChart>
              </ResponsiveContainer>

              {chartType === 'bar' && (
                <div className="my-auto">
                  <ChevronRightIcon
                    width={40}
                    height={40}
                    className="text-accentColor cursor-pointer"
                    onClick={() => weekForward()}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="pt-30 grid grid-cols-1 xl:grid-cols-2 gap-x-30 gap-y-30">
        <WaterGraph analyzeLabel="Wasserbedarf" startDate={startDate} endDate={endDate} userId={userId} />
        <RatingGraph analyzeLabel="Tagesbewertung" startDate={startDate} endDate={endDate} userId={userId} />
        <StepGraph analyzeLabel="Aktivitäten (Schritte)" startDate={startDate} endDate={endDate} userId={userId} />
        <WorkoutGraph analyzeLabel="Aktivitäten (Workouts)" startDate={startDate} endDate={endDate} userId={userId} />
      </div>
    </div>
  );
};

export default AnalyzeOverview;
