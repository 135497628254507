import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import Button from 'components/Button';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import {
  XMarkIcon as XIcon,
  PlusIcon,
  MagnifyingGlassIcon as SearchIcon,
  PencilIcon,
  CheckBadgeIcon as BadgeCheckIcon,
} from '@heroicons/react/24/outline';
import { toast } from 'react-toast';
import axios from 'axios';
import ReactLoading from 'react-loading';
import SearchBox from 'components/SearchBox';
import { AuthContext } from 'providers/AuthProvider';
import CustomUserInput from 'components/CustomUserInput';
import { defaultTextColor } from 'layouts/Theme';
import { scrollToTop } from 'shared/functions/global';
import { MainContext } from 'providers/MainProvider';
import IngridientItem from '../IngridientItem';
import RecipeStepSwitch from '../RecipeStepSwitch';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  currentStep?: any;
  currentStepValue?: string;
  recipeState?: any;
  recipeStateValue?: any;
  mainPopup?: any;
  popupContainerRef?: any;
};

const NewRecipeStep3: React.FC<Props> = ({
  currentStep,
  currentStepValue,
  recipeState,
  recipeStateValue,
  mainPopup,
  popupContainerRef,
}) => {
  const [visible, setVisible] = useState('block');
  const { t } = useTranslation();
  const { theme, userData } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const searchIngridientRef = useRef<HTMLInputElement>(null);
  const editIngridientRef = useRef<HTMLInputElement>(null);

  const { ingredientList } = useContext(MainContext);

  const [recipeChanged, setRecipeChanged] = useState(false);
  const [directAddedIngridients, setDirectAddedIngridients] = useState<any[]>([]);
  const [currentEditIngridientPreselectType, setCurrentEditIngridientPreselectType] = useState('');

  const [currentApiFoodObject, setCurrentApiFoodObject] = useState<any>(null);

  const [popupOverlayClass, setPopupOverlayClass] = useState('hidden');
  const [editPopupOverlayClass, setEditPopupOverlayClass] = useState('hidden');
  const [currentSearchQuery, setCurrentSearchQuery] = useState('');
  const [currentIngridientSearchApi, setCurrentIngridientSearchApi] = useState({ foods: { food: [{}] } as any });
  const [currentIngridientSearchAutocomplete, setCurrentIngridientSearchAutocomplete] = useState({
    suggestions: { suggestion: [''] },
  });
  const [currentIngridientSearch, setCurrentIngridientSearch] = useState(false);
  const [currentEditIngridientAmount, setCurrentEditIngridientAmount] = useState('1');
  const [currentEditInitialIngridientAmount, setCurrentEditInitialIngridientAmount] = useState('1');
  const [currentEditIngridientID, setCurrentEditIngridientID] = useState('1');
  const [currentEditIngridientName, setCurrentEditIngridientName] = useState('');
  const [currentEditIngridientType, setCurrentEditIngridientType] = useState('');
  const [currentEditIngridientPiece, setCurrentEditIngridientPiece] = useState('g');
  const [currentEditIngridientDefaultPiece, setCurrentEditIngridientDefaultPiece] = useState('g');
  const [currentEditIngridientAPIFoodId, setCurrentEditIngridientAPIFoodId] = useState('');
  const [currentEditIngridientMetricServingAmountValue, setCurrentEditIngridientMetricServingAmountValue] =
    useState('');

  const [currentEditIngridientPreselectG, setCurrentEditIngridientPreselectG] = useState('');
  const [currentEditIngridientKcal100g, setCurrentEditIngridientKcal100g] = useState('');
  const [currentEditIngridientCarbohydrates100g, setCurrentEditIngridientCarbohydrates100g] = useState('');
  const [currentEditIngridientProtein100g, setCurrentEditIngridientProtein100g] = useState('');
  const [currentEditIngridientFat100g, setCurrentEditIngridientFat100g] = useState('');
  const [currentEditIngridientCategory, setCurrentEditIngridientCategory] = useState('');

  const [currentEditIngridientImageUrl, setCurrentEditIngridientImageUrl] = useState('');
  const [currentEditIngridientKcalTotal, setCurrentEditIngridientKcalTotal] = useState(0);
  const [currentEditIngridientCarbohydratesTotal, setCurrentEditIngridientCarbohydratesTotal] = useState(0);
  const [currentEditIngridientProteinTotal, setCurrentEditIngridientProteinTotal] = useState(0);
  const [currentEditIngridientFatTotal, setCurrentEditIngridientFatTotal] = useState(0);
  const [currentEditIngridientKcalValue, setCurrentEditIngridientKcalValue] = useState(0);

  const [currentEditIngridientCarbohydratesValue, setCurrentEditIngridientCarbohydratesValue] = useState(0);
  const [currentEditIngridientProteinValue, setCcurrentEditIngridientProteinValue] = useState(0);
  const [currentEditIngridientFatValue, setCurrentEditIngridientFatValue] = useState(0);
  const [currentEditIngridientPortionValues, setCurrentEditIngridientPortionValues] = useState<any>([]);

  const [currentEditIngridientPopupType, setCurrentEditIngridientPopupType] = useState('internal');

  const { accessTokenString } = useContext(MainContext);

  const [initialIncompatibilitiesOptions, changeIncompatibilitiesOptions] = useState<any>({
    celery: false,
    egg: false,
    fructose: false,
    histamine: false,
    lactose: false,
    nuts: false,
    peanuts: false,
    sorbitol: false,
    soy: false,
    thermomix: false,
    wheat: false,
    gluten: false,
  });

  const ingredientFilteredList = useMemo(
    () => ingredientList?.filter(element => element.name.toLowerCase().includes(currentSearchQuery.toLowerCase())),
    [ingredientList, currentSearchQuery]
  );

  const editIngridientNameRef = useRef<HTMLDivElement>(null);

  function jumpToNextStep(currentObject: object) {
    if (recipeStateValue?.ingredients.length === 0) {
      toast.warn(t('Select Ingredients'));
    } else {
      let IncompatibilitiesObject = initialIncompatibilitiesOptions;
      const checkIncompabilityArray = [] as any;

      // check incombality options of ingredients
      for (let i = 0; i < recipeStateValue.ingredients.length; i += 1) {
        if (!recipeStateValue.ingredients[i].serving_data) {
          const getIngridient = ingredientList?.find(item => item.name === recipeStateValue.ingredients[i].name);

          Object.keys(initialIncompatibilitiesOptions).forEach(function (key) {
            if ((getIngridient![key] as any) === true) {
              checkIncompabilityArray.push({ name: [key], status: true });
            }
          });
        }
      }

      for (let f = 0; f < checkIncompabilityArray.length; f += 1) {
        IncompatibilitiesObject = { ...IncompatibilitiesObject, [checkIncompabilityArray[f].name]: true };
      }

      const newColumns = {
        ...currentObject,
        ...IncompatibilitiesObject,
      };

      recipeState(newColumns);
      setVisible('hidden');
      currentStep('4');
    }
  }

  // Get FatSecret Autocomplete results
  const ingridientAutoCompleteSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSearchQuery(event.target.value);
    handleSearchChange();
  };

  const cancelToken = useRef(null) as any;

  const handleSearchChange = async () => {
    if (cancelToken.current !== null) {
      cancelToken.current.cancel('Operation canceled due to new request.');
    }

    // Save the cancel token for the current request
    cancelToken.current = axios.CancelToken.source();

    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/ingridientAutoCompleteSearch`,
          {
            token: accessTokenString,
            query: currentSearchQuery,
            language: userData?.country ?? 'DE',
            headers: {
              'content-type': 'application/json',
              Accept: 'application/json',
            },
          },
          {
            cancelToken: cancelToken?.current?.token,
          }
        )
        .then(response => {
          if (response.data.suggestions !== undefined) {
            setCurrentIngridientSearchAutocomplete(response.data);
            setCurrentIngridientSearch(false);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // OLD DEBOUNCE VERSION
  /*
  const ingridientAutoCompleteSearchExecute = _.debounce(() => {
    if (currentSearchQuery.length > 0) {
      try {
        axios({
          url: `${process.env.REACT_APP_API_URL}/ingridientAutoCompleteSearch`,
          method: 'post',
          data: {
            token: accessTokenString,
            query: currentSearchQuery,
          },
          headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
          },
        }).then(response => {
          if (response.data.suggestions !== undefined) {
            setCurrentIngridientSearchAutocomplete(response.data);
            setCurrentIngridientSearch(false);
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  }, 10); */

  // Execute FatSecret ingridient search
  function ingridientApiSearch(query: string) {
    // Hide ios keyboard
    (document.activeElement as HTMLElement).blur();

    setCurrentSearchQuery(query);

    try {
      axios({
        url: `${process.env.REACT_APP_API_URL}/ingridientApiSearch`,
        method: 'post',
        data: {
          token: accessTokenString,
          query,
          language: userData?.country ?? 'DE',
        },
        headers: {
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      }).then(response => {
        setCurrentIngridientSearchApi(response.data);
        setCurrentIngridientSearch(true);
        setCurrentIngridientSearchAutocomplete({ suggestions: { suggestion: [''] } });
      });
    } catch (err) {
      console.log('error');
    }
  }

  // DIRECT ADD: Add a non API ingridient without setting amount and piece (Plus icon)
  function addIngridientDirectInternal(
    piece: string,
    amount: number,
    preselect_g: string,
    kcal_100g: string,
    carbohydrates_100g: string,
    protein_100g: string,
    fat_100g: string,
    name: string,
    category: string,
    imageUrl: string,
    preselect_type: string,
    default_piece: string
  ) {
    let thisCurrentKcalComplete = 0;
    let thisCurrentKHComplete = 0;
    let thisCurrentEWComplete = 0;
    let thisCurrentFTComplete = 0;

    if (piece !== 'g' && piece !== 'ml') {
      thisCurrentKcalComplete = Math.round(
        parseFloat(recipeStateValue?.kcal_total!) + ((amount * parseFloat(preselect_g)) / 100) * parseFloat(kcal_100g)
      );

      thisCurrentKHComplete = Math.round(
        parseFloat(recipeStateValue?.carbohydrates_total!) +
          ((amount * parseFloat(preselect_g)) / 100) * parseFloat(carbohydrates_100g)
      );

      thisCurrentEWComplete = Math.round(
        parseFloat(recipeStateValue?.protein_total!) +
          ((amount * parseFloat(preselect_g)) / 100) * parseFloat(protein_100g)
      );

      thisCurrentFTComplete = Math.round(
        parseFloat(recipeStateValue?.fat_total!) + ((amount * parseFloat(preselect_g)) / 100) * parseFloat(fat_100g)
      );
    } else {
      thisCurrentKcalComplete = Math.round(
        parseFloat(recipeStateValue?.kcal_total!) + (amount / 100) * parseFloat(kcal_100g)
      );

      thisCurrentKHComplete = Math.round(
        parseFloat(recipeStateValue?.carbohydrates_total!) + (amount / 100) * parseFloat(carbohydrates_100g)
      );

      thisCurrentEWComplete = Math.round(
        parseFloat(recipeStateValue?.protein_total!) + (amount / 100) * parseFloat(protein_100g)
      );

      thisCurrentFTComplete = Math.round(
        parseFloat(recipeStateValue?.fat_total!) + (amount / 100) * parseFloat(fat_100g)
      );
    }

    const thisPiece = piece;

    const thisIngridientObject = {
      id: Math.random(),
      name,
      amount,
      initial_amount: amount,
      piece: thisPiece,
      kcal_100g,
      carbohydrates_100g,
      protein_100g,
      fat_100g,
      default_piece,
      preselect_g,
      category,
      imageUrl,
      preselect_type,
    };

    const newColumns = {
      ...recipeStateValue,
      kcal_total: thisCurrentKcalComplete,
      carbohydrates_total: thisCurrentKHComplete,
      protein_total: thisCurrentEWComplete,
      fat_total: thisCurrentFTComplete,
      ingredients: [...recipeStateValue.ingredients, thisIngridientObject],
    };

    handleOpenClosePopups(setEditPopupOverlayClass, '', 'close');
    handleOpenClosePopups(setPopupOverlayClass, '', 'close');

    setCurrentIngridientSearch(false);
    setCurrentSearchQuery('');
    // this.setState({ currentIngridientPieceIdentifier: '' });
    setCurrentIngridientSearchAutocomplete({ suggestions: { suggestion: [''] } });
    if (!Number.isNaN(amount)) {
      updateRecipeState(newColumns);
    } else {
      toast.error('Die eigegebenen Daten enthielten Fehler!');
    }
    setVisible('block');
  }

  const changeIngridientAmountAPI = (event: any) => {
    const thisCurrentInitialAmount = event.target.value;
    let thisCurrentAmount = event.target.value;
    let ingridientKcalNew: number;
    let ingridientKhNew: number;
    let ingridientEwNew: number;
    let ingridientFtNew: number;

    // Replace Comma with Point for calculating results
    thisCurrentAmount = thisCurrentAmount.replace(',', '.');

    // Replace all text characters
    thisCurrentAmount = thisCurrentAmount.replace(/[^.0-9]/g, '');

    if (Number.isNaN(parseFloat(thisCurrentAmount))) {
      thisCurrentAmount = 0;
    }

    if (thisCurrentAmount === '') {
      thisCurrentAmount = 0;
    }

    if (currentEditIngridientPiece !== 'g' && currentEditIngridientPiece !== 'ml') {
      ingridientKcalNew = Math.round(parseFloat(thisCurrentAmount) * currentEditIngridientKcalValue);

      ingridientKhNew = Math.round(parseFloat(thisCurrentAmount) * currentEditIngridientCarbohydratesValue);

      ingridientEwNew = Math.round(parseFloat(thisCurrentAmount) * currentEditIngridientProteinValue);

      ingridientFtNew = Math.round(parseFloat(thisCurrentAmount) * currentEditIngridientFatValue);
    } else if (currentEditIngridientMetricServingAmountValue !== '') {
      let thisMetricAmountValue = 1;

      if (
        parseFloat(
          currentEditIngridientMetricServingAmountValue.substr(
            0,
            currentEditIngridientMetricServingAmountValue.indexOf('.')
          )
        ) !== 0
      ) {
        thisMetricAmountValue = parseFloat(
          currentEditIngridientMetricServingAmountValue.substr(
            0,
            currentEditIngridientMetricServingAmountValue.indexOf('.')
          )
        );
      }

      ingridientKcalNew = Math.round(
        (parseFloat(thisCurrentAmount) / thisMetricAmountValue) * currentEditIngridientKcalValue
      );

      ingridientKhNew = Math.round(
        (parseFloat(thisCurrentAmount) / thisMetricAmountValue) * currentEditIngridientCarbohydratesValue
      );

      ingridientEwNew = Math.round(
        (parseFloat(thisCurrentAmount) / thisMetricAmountValue) * currentEditIngridientProteinValue
      );

      ingridientFtNew = Math.round(
        (parseFloat(thisCurrentAmount) / thisMetricAmountValue) * currentEditIngridientFatValue
      );
    } else {
      ingridientKcalNew = (parseFloat(thisCurrentAmount) / 100) * currentEditIngridientKcalValue;
      ingridientKhNew = (parseFloat(thisCurrentAmount) / 100) * currentEditIngridientCarbohydratesValue;
      ingridientEwNew = (parseFloat(thisCurrentAmount) / 100) * currentEditIngridientProteinValue;
      ingridientFtNew = (parseFloat(thisCurrentAmount) / 100) * currentEditIngridientFatValue;
    }

    if (thisCurrentAmount !== 0) {
      setCurrentEditIngridientAmount(thisCurrentAmount);
    } else {
      setCurrentEditIngridientAmount('');
    }
    setCurrentEditIngridientKcalTotal(Math.round(ingridientKcalNew));
    setCurrentEditIngridientCarbohydratesTotal(Math.round(ingridientKhNew));
    setCurrentEditIngridientProteinTotal(Math.round(ingridientEwNew));
    setCurrentEditIngridientFatTotal(Math.round(ingridientFtNew));
    // setCurrentEditIngridientMetricServingAmountValue('');
  };

  const changeIngridientPieceAPI = (event: any) => {
    let thisCurrentPieceLabel = event.value;
    let ingridientKcalNew: number;
    let ingridientKhNew: number;
    let ingridientEwNew: number;
    let ingridientFtNew: number;

    let thisEditIngredientAmount = currentEditIngridientAmount;

    if (thisEditIngredientAmount.toString().length === 0) {
      thisEditIngredientAmount = '0';
    }

    if (thisCurrentPieceLabel !== 'g' && thisCurrentPieceLabel !== 'ml') {
      ingridientKcalNew = Math.round(parseFloat(thisEditIngredientAmount) * currentEditIngridientKcalValue);

      ingridientKhNew = Math.round(parseFloat(thisEditIngredientAmount) * currentEditIngridientCarbohydratesValue);

      ingridientEwNew = Math.round(parseFloat(thisEditIngredientAmount) * currentEditIngridientProteinValue);

      ingridientFtNew = Math.round(parseFloat(thisEditIngredientAmount) * currentEditIngridientFatValue);
    } else if (currentEditIngridientMetricServingAmountValue !== '') {
      let thisMetricAmountValue = 1;

      if (
        parseFloat(
          currentEditIngridientMetricServingAmountValue.substr(
            0,
            currentEditIngridientMetricServingAmountValue.indexOf('.')
          )
        ) !== 0
      ) {
        thisMetricAmountValue = parseFloat(
          currentEditIngridientMetricServingAmountValue.substr(
            0,
            currentEditIngridientMetricServingAmountValue.indexOf('.')
          )
        );
      }

      ingridientKcalNew = Math.round(
        (parseFloat(thisEditIngredientAmount) / thisMetricAmountValue) * currentEditIngridientKcalValue
      );

      ingridientKhNew = Math.round(
        (parseFloat(thisEditIngredientAmount) / thisMetricAmountValue) * currentEditIngridientCarbohydratesValue
      );

      ingridientEwNew = Math.round(
        (parseFloat(thisEditIngredientAmount) / thisMetricAmountValue) * currentEditIngridientProteinValue
      );

      ingridientFtNew = Math.round(
        (parseFloat(thisEditIngredientAmount) / thisMetricAmountValue) * currentEditIngridientFatValue
      );
    } else {
      ingridientKcalNew = (parseFloat(thisEditIngredientAmount) / 100) * currentEditIngridientKcalValue;
      ingridientKhNew = (parseFloat(thisEditIngredientAmount) / 100) * currentEditIngridientCarbohydratesValue;
      ingridientEwNew = (parseFloat(thisEditIngredientAmount) / 100) * currentEditIngridientProteinValue;
      ingridientFtNew = (parseFloat(thisEditIngredientAmount) / 100) * currentEditIngridientFatValue;
    }

    setCurrentEditIngridientKcalTotal(Math.round(ingridientKcalNew));
    setCurrentEditIngridientCarbohydratesTotal(Math.round(ingridientKhNew));
    setCurrentEditIngridientProteinTotal(Math.round(ingridientEwNew));
    setCurrentEditIngridientFatTotal(Math.round(ingridientFtNew));
    // setCurrentEditIngridientMetricServingAmountValue('');

    if (thisCurrentPieceLabel.includes('(')) {
      thisCurrentPieceLabel = thisCurrentPieceLabel.substr(0, thisCurrentPieceLabel.indexOf('(') - 1);
    }

    if (thisCurrentPieceLabel === 'Gramm') {
      setCurrentEditIngridientPiece('g');
    } else {
      setCurrentEditIngridientPiece(event.value);
    }
  };

  const changeIngridientExecuteAPI = () => {
    setIsLoading(true);
    let currentKcal: any = recipeStateValue?.kcal_total;
    let currentKH: any = recipeStateValue?.carbohydrates_total;
    let currentEW: any = recipeStateValue?.protein_total;
    let currentFT: any = recipeStateValue?.fat_total;
    let getIngridientRecipeData = [] as any;
    let thisPieceValue = currentEditIngridientPiece;

    let thisEditIngredientAmount = currentEditIngridientAmount;

    if (thisEditIngredientAmount.toString().length === 0) {
      thisEditIngredientAmount = '0';
    }

    getIngridientRecipeData = recipeStateValue.ingredients.filter((item: any) => item.id === currentEditIngridientID);

    currentKcal = Math.round(
      parseFloat(currentKcal) - parseFloat(getIngridientRecipeData[0].kcal_total) + currentEditIngridientKcalTotal
    );
    currentKH = Math.round(
      parseFloat(currentKH) -
        parseFloat(getIngridientRecipeData[0].carbohydrates_total) +
        currentEditIngridientCarbohydratesTotal
    );
    currentEW = Math.round(
      parseFloat(currentEW) - parseFloat(getIngridientRecipeData[0].protein_total) + currentEditIngridientProteinTotal
    );
    currentFT = Math.round(
      parseFloat(currentFT) - parseFloat(getIngridientRecipeData[0].fat_total) + currentEditIngridientFatTotal
    );

    if (currentEditIngridientPiece.includes('(')) {
      thisPieceValue = currentEditIngridientPiece.substr(0, currentEditIngridientPiece.indexOf('(') - 1);
    }

    const newColumns = {
      ...recipeStateValue,
      kcal_total: currentKcal,
      carbohydrates_total: currentKH,
      protein_total: currentEW,
      fat_total: currentFT,
      ingredients: recipeStateValue?.ingredients.map((ingridients: any) => {
        if (parseFloat(ingridients.id) !== parseFloat(currentEditIngridientID)) return ingridients;
        return {
          ...ingridients,
          amount: thisEditIngredientAmount,
          piece: thisPieceValue,
          kcal_total: currentEditIngridientKcalTotal,
          carbohydrates_total: currentEditIngridientCarbohydratesTotal,
          protein_total: currentEditIngridientProteinTotal,
          fat_total: currentEditIngridientFatTotal,
        };
      }),
    };

    const newColumnsDirectAdd = directAddedIngridients.map((ingridients: any) => {
      if (parseFloat(ingridients.id) !== parseFloat(currentEditIngridientID)) return ingridients;
      return {
        ...ingridients,
        amount: thisEditIngredientAmount,
        piece: thisPieceValue,
        kcal_total: currentEditIngridientKcalTotal,
        carbohydrates_total: currentEditIngridientCarbohydratesTotal,
        protein_total: currentEditIngridientProteinTotal,
        fat_total: currentEditIngridientFatTotal,
      };
    });

    setCurrentEditIngridientKcalValue(0);
    setCurrentEditIngridientCarbohydratesValue(0);
    setCcurrentEditIngridientProteinValue(0);
    setCurrentEditIngridientFatValue(0);
    if (!Number.isNaN(parseFloat(thisEditIngredientAmount))) {
      updateRecipeState(newColumns);
      setRecipeChanged(true);
      setDirectAddedIngridients(newColumnsDirectAdd);
    } else {
      toast.error('Die eigegebenen Daten enthielten Fehler!');
    }
    setCurrentIngridientSearch(false);
    setCurrentSearchQuery('');
    setCurrentIngridientSearchAutocomplete({ suggestions: { suggestion: [''] } });

    setIsLoading(false);
    handleOpenClosePopups(setEditPopupOverlayClass, '', 'close');
    handleOpenClosePopups(setPopupOverlayClass, '', 'close');
    setVisible('block');
  };

  function addIngridientDirectAPI(food_id: string) {
    setIsLoading(true);
    let thisCurrentKcalComplete = 0;
    let thisCurrentKHComplete = 0;
    let thisCurrentEWComplete = 0;
    let thisCurrentFTComplete = 0;

    let getIngridientServingData;
    let currentServingDescription;
    let thisAmount;
    let ingridientKcal100g;
    let ingridientKH100g;
    let ingridientEW100g;
    let ingridientFT100g;

    try {
      axios({
        url: `${process.env.REACT_APP_API_URL}/getIngridientApi`,
        method: 'post',
        data: {
          token: accessTokenString,
          food_id,
          language: userData?.country ?? 'DE',
        },
        headers: {
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      }).then(response => {
        const { data } = response;

        if (Array.isArray(data.food.servings.serving)) {
          getIngridientServingData = data.food.servings.serving.filter((item: any) => item.is_default === '1');
          currentServingDescription = getIngridientServingData[0].serving_description.replace(/\s+/g, '');
        } else {
          getIngridientServingData = [data.food.servings.serving];
          currentServingDescription = data.food.servings.serving.serving_description.replace(/\s+/g, '');
        }

        let thisPiece;
        if (getIngridientServingData[0].serving_description.includes('1 ')) {
          if (
            getIngridientServingData[0].serving_description.includes('g)') ||
            getIngridientServingData[0].serving_description.includes('ml)')
          ) {
            thisPiece = getIngridientServingData[0].serving_description.substr(
              2,
              getIngridientServingData[0].serving_description.indexOf(' (') - 2
            );
          } else {
            thisPiece = getIngridientServingData[0].serving_description.substr(
              2,
              getIngridientServingData[0].serving_description.length
            );
          }
        } else {
          thisPiece = getIngridientServingData[0].serving_description;
        }
        if (thisPiece.includes('(')) {
          thisPiece = thisPiece.substr(0, thisPiece.indexOf('(') - 1);
        }

        if (
          currentServingDescription === '100ml' ||
          currentServingDescription === '100g' ||
          getIngridientServingData[0].measurement_description === 'g' ||
          getIngridientServingData[0].measurement_description === 'ml'
        ) {
          if (
            getIngridientServingData[0].metric_serving_unit === 'ml' ||
            getIngridientServingData[0].measurement_description === 'ml'
          ) {
            thisPiece = 'ml';
          } else {
            thisPiece = 'g';
          }
        }

        if (
          currentServingDescription !== '100ml' &&
          currentServingDescription !== '100g' &&
          getIngridientServingData[0].measurement_description !== 'g' &&
          getIngridientServingData[0].measurement_description !== 'ml'
        ) {
          thisAmount = getIngridientServingData[0].number_of_units.substr(
            0,
            getIngridientServingData[0].number_of_units.indexOf('.')
          );
        } else {
          thisAmount = 100;
        }

        if (getIngridientServingData[0].metric_serving_amount) {
          ingridientKcal100g = Math.round(
            (parseFloat(getIngridientServingData[0].calories) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
          ingridientKH100g = Math.round(
            (parseFloat(getIngridientServingData[0].carbohydrate) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
          ingridientEW100g = Math.round(
            (parseFloat(getIngridientServingData[0].protein) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
          ingridientFT100g = Math.round(
            (parseFloat(getIngridientServingData[0].fat) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
        } else {
          ingridientKcal100g = Math.round(parseFloat(getIngridientServingData[0].calories));
          ingridientKH100g = Math.round(parseFloat(getIngridientServingData[0].carbohydrate));
          ingridientEW100g = Math.round(parseFloat(getIngridientServingData[0].protein));
          ingridientFT100g = Math.round(parseFloat(getIngridientServingData[0].fat));
        }

        thisCurrentKcalComplete = Math.round(
          parseFloat(recipeStateValue?.kcal_total!) + Math.round(parseFloat(getIngridientServingData[0].calories))
        );

        thisCurrentKHComplete = Math.round(
          parseFloat(recipeStateValue?.carbohydrates_total!) +
            Math.round(parseFloat(getIngridientServingData[0].carbohydrate))
        );

        thisCurrentEWComplete = Math.round(
          parseFloat(recipeStateValue?.protein_total!) + Math.round(parseFloat(getIngridientServingData[0].protein))
        );

        thisCurrentFTComplete = Math.round(
          parseFloat(recipeStateValue?.fat_total!) + Math.round(parseFloat(getIngridientServingData[0].fat))
        );

        let thisFoodName = data.food.food_name;
        if (data.food.brand_name) {
          thisFoodName += ` (${data.food.brand_name})`;
        }

        let thisFoodCategory = 'Sonstiges';
        if (data.food.food_sub_categories) {
          if (Array.isArray(data.food.food_sub_categories.food_sub_category)) {
            thisFoodCategory = data.food.food_sub_categories.food_sub_category[1].toString();
          } else {
            thisFoodCategory = data.food.food_sub_categories.food_sub_category;
          }
        }

        const thisIngridientObject = {
          id: food_id,
          name: thisFoodName,
          amount: thisAmount,
          initial_amount: thisAmount,
          piece: thisPiece,
          kcal_100g: ingridientKcal100g,
          carbohydrates_100g: ingridientKH100g,
          protein_100g: ingridientEW100g,
          fat_100g: ingridientFT100g,
          kcal_total: Math.round(parseFloat(getIngridientServingData[0].calories)),
          carbohydrates_total: Math.round(parseFloat(getIngridientServingData[0].carbohydrate)),
          protein_total: Math.round(parseFloat(getIngridientServingData[0].protein)),
          fat_total: Math.round(parseFloat(getIngridientServingData[0].fat)),
          preselect_g:
            (getIngridientServingData[0].metric_serving_amount &&
              getIngridientServingData[0].metric_serving_amount.substr(
                0,
                getIngridientServingData[0].metric_serving_amount.indexOf('.')
              )) ||
            100,
          category: thisFoodCategory,
          serving_id: getIngridientServingData[0].serving_id,
          serving_unit:
            getIngridientServingData[0].metric_serving_unit !== undefined
              ? getIngridientServingData[0].metric_serving_unit
              : 'g',
          serving_data: data.food.servings,
        };

        const newColumns = {
          ...recipeStateValue,
          kcal_total: thisCurrentKcalComplete,
          carbohydrates_total: thisCurrentKHComplete,
          protein_total: thisCurrentEWComplete,
          fat_total: thisCurrentFTComplete,
          ingredients: [...recipeStateValue.ingredients, thisIngridientObject],
        };

        handleOpenClosePopups(setEditPopupOverlayClass, '', 'close');
        handleOpenClosePopups(setPopupOverlayClass, '', 'close');

        setCurrentIngridientSearch(false);
        setCurrentSearchQuery('');
        setCurrentIngridientSearchAutocomplete({ suggestions: { suggestion: [''] } });
        if (!Number.isNaN(thisAmount)) {
          updateRecipeState(newColumns);
          setRecipeChanged(true);
          setDirectAddedIngridients([thisIngridientObject, ...directAddedIngridients]);
        } else {
          toast.error('Die eigegebenen Daten enthielten Fehler!');
        }
        setIsLoading(false);
        setVisible('block');
      });
    } catch (err) {
      toast.error('Es ist leider ein Fehler aufgetreten. Bitte probiere es erneut!');
    }
  }

  function handleEditIngridientPopup(
    ingridientId: string,
    name: string,
    amount: string,
    piece: string,
    imageUrl: string,
    preselect_g: string,
    preselect_type: string,
    kcal_100g: string,
    carbohydrates_100g: string,
    protein_100g: string,
    fat_100g: string,
    default_piece: string,
    type: string,
    category: string
  ) {
    setCurrentEditIngridientKcalValue(0);
    setCurrentEditIngridientCarbohydratesValue(0);
    setCcurrentEditIngridientProteinValue(0);
    setCurrentEditIngridientFatValue(0);
    setPopupOverlayClass('hidden');

    setCurrentEditIngridientPopupType('internal');

    if (piece !== 'g' && piece !== 'ml') {
      setCurrentEditIngridientKcalTotal(
        Math.round(((parseFloat(amount) * parseFloat(preselect_g)) / 100) * parseFloat(kcal_100g))
      );
      setCurrentEditIngridientCarbohydratesTotal(
        Math.round(((parseFloat(amount) * parseFloat(preselect_g)) / 100) * parseFloat(carbohydrates_100g))
      );
      setCurrentEditIngridientProteinTotal(
        Math.round(((parseFloat(amount) * parseFloat(preselect_g)) / 100) * parseFloat(protein_100g))
      );
      setCurrentEditIngridientFatTotal(
        Math.round(((parseFloat(amount) * parseFloat(preselect_g)) / 100) * parseFloat(fat_100g))
      );
    } else {
      setCurrentEditIngridientKcalTotal(Math.round((parseFloat(amount) / 100) * parseFloat(kcal_100g)));
      setCurrentEditIngridientCarbohydratesTotal(
        Math.round((parseFloat(amount) / 100) * parseFloat(carbohydrates_100g))
      );
      setCurrentEditIngridientProteinTotal(Math.round((parseFloat(amount) / 100) * parseFloat(protein_100g)));
      setCurrentEditIngridientFatTotal(Math.round((parseFloat(amount) / 100) * parseFloat(fat_100g)));
    }

    const thisPortionValues: any = [
      { value: preselect_type.toLowerCase(), label: `${preselect_type} (${preselect_g}g)` },
      { value: 'g', label: 'Gramm' },
    ];

    const thisPortionValuesWithDefaultPiece: any = [
      { value: preselect_type.toLowerCase(), label: `${preselect_type} (${preselect_g}${default_piece})` },
      { value: default_piece === 'ml' ? 'ml' : 'g', label: default_piece === 'ml' ? 'Milliliter' : 'Gramm' },
    ];

    if (default_piece !== undefined) {
      setCurrentEditIngridientPortionValues(thisPortionValuesWithDefaultPiece);
      setCurrentEditIngridientDefaultPiece(default_piece);
    } else {
      setCurrentEditIngridientPortionValues(thisPortionValues);
    }

    setCurrentEditIngridientPreselectG(preselect_g);
    setCurrentEditIngridientKcal100g(kcal_100g);
    setCurrentEditIngridientCarbohydrates100g(carbohydrates_100g);
    setCurrentEditIngridientProtein100g(protein_100g);
    setCurrentEditIngridientFat100g(fat_100g);
    setCurrentEditIngridientCategory(category);

    setCurrentEditIngridientName(name);
    setCurrentEditIngridientType(type);
    setCurrentEditIngridientID(ingridientId);
    setCurrentEditInitialIngridientAmount(amount);
    setCurrentEditIngridientAmount(amount);
    setCurrentEditIngridientPiece(piece);
    if (imageUrl !== undefined) {
      setCurrentEditIngridientImageUrl(imageUrl);
    }
    setEditPopupOverlayClass('block');
  }

  function addChangedIngridientAPI() {
    setIsLoading(true);
    let thisCurrentKcalComplete = 0;
    let thisCurrentKHComplete = 0;
    let thisCurrentEWComplete = 0;
    let thisCurrentFTComplete = 0;

    let getIngridientServingData;
    let currentServingDescription;
    let ingridientKcal100g;
    let ingridientKH100g;
    let ingridientEW100g;
    let ingridientFT100g;
    let thisPieceValue = currentEditIngridientPiece;

    let thisEditIngredientAmount = currentEditIngridientAmount;

    if (thisEditIngredientAmount.toString().length === 0) {
      thisEditIngredientAmount = '0';
    }

    try {
      const { data } = currentApiFoodObject;

      if (Array.isArray(data.food.servings.serving)) {
        getIngridientServingData = data.food.servings.serving.filter((item: any) => item.is_default === '1');
        currentServingDescription = getIngridientServingData[0].serving_description.replace(/\s+/g, '');
      } else {
        getIngridientServingData = [data.food.servings.serving];
        currentServingDescription = data.food.servings.serving.serving_description.replace(/\s+/g, '');
      }

      if (currentEditIngridientPiece.includes('(')) {
        thisPieceValue = currentEditIngridientPiece.substr(0, currentEditIngridientPiece.indexOf('(') - 1);
      }

      if (getIngridientServingData[0].metric_serving_amount) {
        ingridientKcal100g = Math.round(
          (parseFloat(getIngridientServingData[0].calories) * 100) /
            parseFloat(getIngridientServingData[0].metric_serving_amount)
        );
        ingridientKH100g = Math.round(
          (parseFloat(getIngridientServingData[0].carbohydrate) * 100) /
            parseFloat(getIngridientServingData[0].metric_serving_amount)
        );
        ingridientEW100g = Math.round(
          (parseFloat(getIngridientServingData[0].protein) * 100) /
            parseFloat(getIngridientServingData[0].metric_serving_amount)
        );
        ingridientFT100g = Math.round(
          (parseFloat(getIngridientServingData[0].fat) * 100) /
            parseFloat(getIngridientServingData[0].metric_serving_amount)
        );
      } else {
        ingridientKcal100g = Math.round(parseFloat(getIngridientServingData[0].calories));
        ingridientKH100g = Math.round(parseFloat(getIngridientServingData[0].carbohydrate));
        ingridientEW100g = Math.round(parseFloat(getIngridientServingData[0].protein));
        ingridientFT100g = Math.round(parseFloat(getIngridientServingData[0].fat));
      }

      thisCurrentKcalComplete = Math.round(parseFloat(recipeStateValue?.kcal_total!) + currentEditIngridientKcalTotal);

      thisCurrentKHComplete = Math.round(
        parseFloat(recipeStateValue?.carbohydrates_total!) + currentEditIngridientCarbohydratesTotal
      );

      thisCurrentEWComplete = Math.round(
        parseFloat(recipeStateValue?.protein_total!) + currentEditIngridientProteinTotal
      );

      thisCurrentFTComplete = Math.round(parseFloat(recipeStateValue?.fat_total!) + currentEditIngridientFatTotal);

      let thisFoodCategory = 'Sonstiges';
      if (data.food.food_sub_categories) {
        if (Array.isArray(data.food.food_sub_categories.food_sub_category)) {
          thisFoodCategory = data.food.food_sub_categories.food_sub_category[1].toString();
        } else {
          thisFoodCategory = data.food.food_sub_categories.food_sub_category;
        }
      }

      const thisIngridientObject = {
        id: currentEditIngridientAPIFoodId,
        name: currentEditIngridientName,
        amount: thisEditIngredientAmount,
        initial_amount: thisEditIngredientAmount,
        piece: thisPieceValue,
        kcal_100g: ingridientKcal100g,
        carbohydrates_100g: ingridientKH100g,
        protein_100g: ingridientEW100g,
        fat_100g: ingridientFT100g,
        kcal_total: currentEditIngridientKcalTotal,
        carbohydrates_total: currentEditIngridientCarbohydratesTotal,
        protein_total: currentEditIngridientProteinTotal,
        fat_total: currentEditIngridientFatTotal,
        preselect_g:
          (getIngridientServingData[0].metric_serving_amount &&
            getIngridientServingData[0].metric_serving_amount.substr(
              0,
              getIngridientServingData[0].metric_serving_amount.indexOf('.')
            )) ||
          100,
        category: thisFoodCategory,
        serving_id: getIngridientServingData[0].serving_id,
        serving_unit:
          getIngridientServingData[0].metric_serving_unit !== undefined
            ? getIngridientServingData[0].metric_serving_unit
            : 'g',
        serving_data: data.food.servings,
      };

      const newColumns = {
        ...recipeStateValue,
        kcal_total: thisCurrentKcalComplete,
        carbohydrates_total: thisCurrentKHComplete,
        protein_total: thisCurrentEWComplete,
        fat_total: thisCurrentFTComplete,
        ingredients: [...recipeStateValue.ingredients, thisIngridientObject],
      };

      setCurrentIngridientSearch(false);
      setCurrentSearchQuery('');
      setCurrentIngridientSearchAutocomplete({ suggestions: { suggestion: [''] } });
      if (!Number.isNaN(parseFloat(thisEditIngredientAmount))) {
        updateRecipeState(newColumns);
        setRecipeChanged(true);
        setDirectAddedIngridients([thisIngridientObject, ...directAddedIngridients]);
      } else {
        toast.error('Die eigegebenen Daten enthielten Fehler!');
      }
      setIsLoading(false);
      handleOpenClosePopups(setEditPopupOverlayClass, '', 'close');
      handleOpenClosePopups(setPopupOverlayClass, '', 'close');
      setVisible('block');
    } catch (err) {
      toast.error('Es ist leider ein Fehler aufgetreten. Bitte probiere es erneut!');
    }
  }

  function handleOpenClosePopups(closeStateObject: any, openStateObject: any, type: string) {
    if (type === 'both') {
      closeStateObject('hidden');
      openStateObject('block');
    } else if (type === 'open') {
      openStateObject('block');
    } else if (type === 'close') {
      closeStateObject('hidden');
    }
  }

  function getAPIIngridientData(food_id: string) {
    setIsLoading(true);
    let getIngridientServingData;
    let currentServingDescription;
    let thisAmount;
    let ingridientKcal100g;
    let ingridientKH100g;
    let ingridientEW100g;
    let ingridientFT100g;

    try {
      axios({
        url: `${process.env.REACT_APP_API_URL}/getIngridientApi`,
        method: 'post',
        data: {
          token: accessTokenString,
          food_id,
          language: userData?.country ?? 'DE',
        },
        headers: {
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      }).then(response => {
        const { data } = response;

        setCurrentApiFoodObject(response);

        if (Array.isArray(data.food.servings.serving)) {
          getIngridientServingData = data.food.servings.serving.filter((item: any) => item.is_default === '1');
          currentServingDescription = getIngridientServingData[0].serving_description.replace(/\s+/g, '');
        } else {
          getIngridientServingData = [data.food.servings.serving];
          currentServingDescription = data.food.servings.serving.serving_description.replace(/\s+/g, '');
        }

        let thisPiece;
        if (getIngridientServingData[0].serving_description.includes('1 ')) {
          if (
            getIngridientServingData[0].serving_description.includes('g)') ||
            getIngridientServingData[0].serving_description.includes('ml)')
          ) {
            thisPiece = getIngridientServingData[0].serving_description.substr(
              2,
              getIngridientServingData[0].serving_description.indexOf(' (') - 2
            );
          } else {
            thisPiece = getIngridientServingData[0].serving_description.substr(
              2,
              getIngridientServingData[0].serving_description.length
            );
          }
        } else {
          thisPiece = getIngridientServingData[0].serving_description;
        }
        if (thisPiece.includes('(')) {
          thisPiece = thisPiece.substr(0, thisPiece.indexOf('(') - 1);
        }

        if (
          currentServingDescription === '100ml' ||
          currentServingDescription === '100g' ||
          getIngridientServingData[0].measurement_description === 'g' ||
          getIngridientServingData[0].measurement_description === 'ml'
        ) {
          if (
            getIngridientServingData[0].metric_serving_unit === 'ml' ||
            getIngridientServingData[0].measurement_description === 'ml'
          ) {
            thisPiece = 'ml';
          } else {
            thisPiece = 'g';
          }
        }

        if (
          currentServingDescription !== '100ml' &&
          currentServingDescription !== '100g' &&
          getIngridientServingData[0].measurement_description !== 'g' &&
          getIngridientServingData[0].measurement_description !== 'ml'
        ) {
          thisAmount = getIngridientServingData[0].number_of_units.substr(
            0,
            getIngridientServingData[0].number_of_units.indexOf('.')
          );
        } else {
          thisAmount = 100;
        }

        if (getIngridientServingData[0].metric_serving_amount) {
          ingridientKcal100g = Math.round(
            (parseFloat(getIngridientServingData[0].calories) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
          ingridientKH100g = Math.round(
            (parseFloat(getIngridientServingData[0].carbohydrate) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
          ingridientEW100g = Math.round(
            (parseFloat(getIngridientServingData[0].protein) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
          ingridientFT100g = Math.round(
            (parseFloat(getIngridientServingData[0].fat) * 100) /
              parseFloat(getIngridientServingData[0].metric_serving_amount)
          );
        } else {
          ingridientKcal100g = Math.round(parseFloat(getIngridientServingData[0].calories));
          ingridientKH100g = Math.round(parseFloat(getIngridientServingData[0].carbohydrate));
          ingridientEW100g = Math.round(parseFloat(getIngridientServingData[0].protein));
          ingridientFT100g = Math.round(parseFloat(getIngridientServingData[0].fat));
        }

        let thisFoodName = data.food.food_name;
        if (data.food.brand_name) {
          thisFoodName += ` (${data.food.brand_name})`;
        }

        let thisFoodCategory = 'Sonstiges';
        if (data.food.food_sub_categories) {
          if (Array.isArray(data.food.food_sub_categories.food_sub_category)) {
            thisFoodCategory = data.food.food_sub_categories.food_sub_category[1].toString();
          } else {
            thisFoodCategory = data.food.food_sub_categories.food_sub_category;
          }
        }

        const thisIngridientObject = {
          id: food_id,
          name: thisFoodName,
          amount: thisAmount,
          piece: thisPiece,
          newAdded: true,
          kcal_100g: ingridientKcal100g,
          carbohydrates_100g: ingridientKH100g,
          protein_100g: ingridientEW100g,
          fat_100g: ingridientFT100g,
          kcal_total: Math.round(parseFloat(getIngridientServingData[0].calories)),
          carbohydrates_total: Math.round(parseFloat(getIngridientServingData[0].carbohydrate)),
          protein_total: Math.round(parseFloat(getIngridientServingData[0].protein)),
          fat_total: Math.round(parseFloat(getIngridientServingData[0].fat)),
          preselect_g:
            (getIngridientServingData[0].metric_serving_amount &&
              getIngridientServingData[0].metric_serving_amount.substr(
                0,
                getIngridientServingData[0].metric_serving_amount.indexOf('.')
              )) ||
            100,
          category: thisFoodCategory,
          serving_id: getIngridientServingData[0].serving_id,
          serving_unit:
            getIngridientServingData[0].metric_serving_unit !== undefined
              ? getIngridientServingData[0].metric_serving_unit
              : 'g',
          serving_data: data.food.servings,
        };

        setCurrentEditIngridientAPIFoodId(food_id);
        setCurrentEditIngridientType('notAdded');
        handleEditIngridientPopupAPI(thisIngridientObject);
      });
    } catch (err) {
      toast.error('Es ist leider ein Fehler aufgetreten. Bitte probiere es erneut!');
    }
  }

  function getPrimaryPieceValue(ingridientData: any) {
    let thisPieceValue = '';

    if (ingridientData.serving_data.serving && ingridientData.serving_data.serving instanceof Array) {
      ingridientData.serving_data.serving.map((serving: any, index: any) => {
        if (
          serving.measurement_description !== 'g' &&
          serving.measurement_description !== 'ml' &&
          serving.serving_description !== '100ml' &&
          serving.serving_description !== '100g' &&
          serving.is_default === '1' &&
          !serving.serving_description.includes('(')
        ) {
          thisPieceValue =
            (serving.serving_description.includes('1 ') &&
              serving.serving_description.substr(2, serving.serving_description.length)) ||
            `${serving.serving_description} `;

          if (
            serving.measurement_description !== 'g' &&
            serving.measurement_description !== 'ml' &&
            !serving.measurement_description.includes('g)') &&
            !serving.measurement_description.includes('ml)')
          ) {
            thisPieceValue = `${thisPieceValue} (${serving.metric_serving_amount.substr(
              0,
              serving.metric_serving_amount.indexOf('.')
            )} ${serving.metric_serving_unit})`;
          }
        }

        if (
          serving.measurement_description !== 'g' &&
          serving.measurement_description !== 'ml' &&
          serving.serving_description !== '100ml' &&
          serving.serving_description !== '100g' &&
          serving.is_default === '1' &&
          serving.serving_description.includes('(')
        ) {
          thisPieceValue =
            (serving.serving_description.includes('1 ') &&
              serving.serving_description.substr(2, serving.serving_description.indexOf('(') - 3)) ||
            `${serving.serving_description.substr(0, serving.serving_description.indexOf('(') - 3)} `;

          if (
            serving.measurement_description !== 'g' &&
            serving.measurement_description !== 'ml' &&
            !serving.measurement_description.includes('g)') &&
            !serving.measurement_description.includes('ml)')
          ) {
            thisPieceValue = `${thisPieceValue} (${serving.metric_serving_amount.substr(
              0,
              serving.metric_serving_amount.indexOf('.')
            )} ${serving.metric_serving_unit})`;
          }
        }

        return '';
      });

      return thisPieceValue;
    }

    if (
      ingridientData.serving_data.serving &&
      !(ingridientData.serving_data.serving instanceof Array) &&
      ingridientData.serving_data.serving.serving_description !== '100ml' &&
      ingridientData.serving_data.serving.serving_description !== '100g' &&
      !ingridientData.serving_data.serving.serving_description.includes('(')
    ) {
      thisPieceValue =
        (ingridientData.serving_data.serving.serving_description.includes('1 ') &&
          ingridientData.serving_data.serving.serving_description.substr(
            2,
            ingridientData.serving_data.serving.serving_description.length
          )) ||
        ingridientData.serving_data.serving.serving_description;

      if (
        ingridientData.serving_data.serving.measurement_description !== 'g' &&
        ingridientData.serving_data.serving.measurement_description !== 'ml' &&
        !ingridientData.serving_data.serving.measurement_description.includes('g)') &&
        !ingridientData.serving_data.serving.measurement_description.includes('ml)') &&
        ingridientData.serving_data.serving.metric_serving_amount
      ) {
        thisPieceValue = `${thisPieceValue} (${ingridientData.serving_data.serving.metric_serving_amount.substr(
          0,
          ingridientData.serving_data.serving.metric_serving_amount.indexOf('.')
        )} ${ingridientData.serving_data.serving.metric_serving_unit})`;
      }

      return thisPieceValue;
    }

    if (
      ingridientData.serving_data.serving &&
      !(ingridientData.serving_data.serving instanceof Array) &&
      ingridientData.serving_data.serving.serving_description !== '100ml' &&
      ingridientData.serving_data.serving.serving_description !== '100g' &&
      ingridientData.serving_data.serving.serving_description.includes('(')
    ) {
      thisPieceValue =
        (ingridientData.serving_data.serving.serving_description.includes('1 ') &&
          ingridientData.serving_data.serving.serving_description.substr(
            2,
            ingridientData.serving_data.serving.serving_description.indexOf('(') - 3
          )) ||
        ingridientData.serving_data.serving.serving_description.substr(
          0,
          ingridientData.serving_data.serving.serving_description.indexOf('(') - 3
        );

      if (
        ingridientData.serving_data.serving.measurement_description !== 'g' &&
        ingridientData.serving_data.serving.measurement_description !== 'ml' &&
        !ingridientData.serving_data.serving.measurement_description.includes('g)') &&
        !ingridientData.serving_data.serving.measurement_description.includes('ml)') &&
        ingridientData.serving_data.serving.metric_serving_amount
      ) {
        thisPieceValue = `${thisPieceValue} (${ingridientData.serving_data.serving.metric_serving_amount.substr(
          0,
          ingridientData.serving_data.serving.metric_serving_amount.indexOf('.')
        )} ${ingridientData.serving_data.serving.metric_serving_unit})`;
      }

      return thisPieceValue;
    }
    return thisPieceValue;
  }

  function handleEditIngridientPopupAPI(ingridientData: any) {
    let thisServingDataArray = [] as any;
    let getIngridientServingData: any;
    const thisIngridientData = ingridientData;

    thisServingDataArray = thisIngridientData.serving_data.serving;

    if (thisIngridientData.serving_data.serving instanceof Array) {
      if (thisIngridientData.piece === 'g' || thisIngridientData.piece === 'ml') {
        getIngridientServingData = thisServingDataArray.filter(
          (item: any) => item.serving_id === thisIngridientData.serving_id
        );
      } else {
        getIngridientServingData = thisServingDataArray.filter(
          (item: any) => item.serving_id === thisIngridientData.serving_id
        );
      }
    } else {
      getIngridientServingData = [thisIngridientData.serving_data.serving];
    }

    setCurrentEditIngridientPopupType('api');

    setCurrentEditIngridientKcalValue(parseFloat(getIngridientServingData[0].calories));
    setCurrentEditIngridientCarbohydratesValue(parseFloat(getIngridientServingData[0].carbohydrate));
    setCcurrentEditIngridientProteinValue(parseFloat(getIngridientServingData[0].protein));
    setCurrentEditIngridientFatValue(parseFloat(getIngridientServingData[0].fat));
    setPopupOverlayClass('hidden');

    if (getIngridientServingData[0].metric_serving_amount) {
      setCurrentEditIngridientMetricServingAmountValue(getIngridientServingData[0].metric_serving_amount);
    }

    let thisPortionValues: any;
    let thisPieceValue = '';

    if (getPrimaryPieceValue(thisIngridientData).length > 0) {
      thisPortionValues = [
        {
          value: getPrimaryPieceValue(thisIngridientData)?.toLowerCase(),
          label: getPrimaryPieceValue(thisIngridientData),
        },
        { value: 'g', label: 'Gramm' },
      ];
      thisPieceValue = getPrimaryPieceValue(thisIngridientData);
    } else {
      thisPortionValues = [{ value: 'g', label: 'Gramm' }];
      thisPieceValue = 'g';
    }

    if (thisIngridientData.piece !== 'g' && thisIngridientData.piece !== 'ml') {
      setCurrentEditIngridientKcalTotal(
        Math.round(
          ((parseFloat(thisIngridientData.amount) * parseFloat(thisIngridientData.preselect_g)) / 100) *
            parseFloat(thisIngridientData.kcal_100g)
        )
      );
      setCurrentEditIngridientCarbohydratesTotal(
        Math.round(
          ((parseFloat(thisIngridientData.amount) * parseFloat(thisIngridientData.preselect_g)) / 100) *
            parseFloat(thisIngridientData.carbohydrates_100g)
        )
      );
      setCurrentEditIngridientProteinTotal(
        Math.round(
          ((parseFloat(thisIngridientData.amount) * parseFloat(thisIngridientData.preselect_g)) / 100) *
            parseFloat(thisIngridientData.protein_100g)
        )
      );
      setCurrentEditIngridientFatTotal(
        Math.round(
          ((parseFloat(thisIngridientData.amount) * parseFloat(thisIngridientData.preselect_g)) / 100) *
            parseFloat(thisIngridientData.fat_100g)
        )
      );
    } else {
      setCurrentEditIngridientKcalTotal(
        Math.round((parseFloat(thisIngridientData.amount) / 100) * parseFloat(thisIngridientData.kcal_100g))
      );
      setCurrentEditIngridientCarbohydratesTotal(
        Math.round((parseFloat(thisIngridientData.amount) / 100) * parseFloat(thisIngridientData.carbohydrates_100g))
      );
      setCurrentEditIngridientProteinTotal(
        Math.round((parseFloat(thisIngridientData.amount) / 100) * parseFloat(thisIngridientData.protein_100g))
      );
      setCurrentEditIngridientFatTotal(
        Math.round((parseFloat(thisIngridientData.amount) / 100) * parseFloat(thisIngridientData.fat_100g))
      );
    }

    setCurrentEditIngridientPortionValues(thisPortionValues);
    if (thisIngridientData.piece === 'g' || thisIngridientData.piece === 'ml') {
      setCurrentEditIngridientPiece(thisIngridientData.piece);
    } else {
      setCurrentEditIngridientPiece(thisPieceValue);
    }
    setCurrentEditIngridientAmount(thisIngridientData.amount);
    setCurrentEditInitialIngridientAmount(thisIngridientData.amount);
    setCurrentEditIngridientName(thisIngridientData.name);
    setCurrentEditIngridientID(thisIngridientData.id);
    setCurrentEditIngridientImageUrl('');
    if (!ingridientData.newAdded) {
      setCurrentEditIngridientType('edit');
    }

    setEditPopupOverlayClass('block');
    setIsLoading(false);
  }

  const changeIngridientAmount = (event: any) => {
    const thisCurrentInitialAmount = event.target.value;
    let thisCurrentAmount = event.target.value;
    let ingridientKcalNew;
    let ingridientKhNew;
    let ingridientEwNew;
    let ingridientFtNew;
    let getIngridientRecipeData = [] as any;
    let getIngridient = [] as any;

    // Replace Comma with Point for calculating results
    thisCurrentAmount = thisCurrentAmount.replace(',', '.');

    // Replace all text characters
    thisCurrentAmount = thisCurrentAmount.replace(/[^.0-9]/g, '');

    if (Number.isNaN(parseFloat(thisCurrentAmount))) {
      thisCurrentAmount = 0;
    }

    if (thisCurrentAmount === '') {
      thisCurrentAmount = 0;
    }

    if (currentEditIngridientType === 'notAdded') {
      getIngridientRecipeData = ingredientList?.filter((item: any) => item.name === currentEditIngridientName);
    } else {
      getIngridientRecipeData = recipeStateValue?.ingredients.filter(
        (item: any) => item.id === currentEditIngridientID
      );
    }

    getIngridient = ingredientList?.filter((item: any) => item.name === currentEditIngridientName);

    if (currentEditIngridientPiece !== 'g' && currentEditIngridientPiece !== 'ml') {
      ingridientKcalNew = Math.round(
        ((parseFloat(thisCurrentAmount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].kcal_100g)
      );

      ingridientKhNew = Math.round(
        ((parseFloat(thisCurrentAmount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].carbohydrates_100g)
      );

      ingridientEwNew = Math.round(
        ((parseFloat(thisCurrentAmount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].protein_100g)
      );

      ingridientFtNew = Math.round(
        ((parseFloat(thisCurrentAmount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].fat_100g)
      );
    } else {
      ingridientKcalNew = Math.round((parseFloat(getIngridient[0].kcal_100g) / 100) * parseFloat(thisCurrentAmount));

      ingridientKhNew = Math.round(
        (parseFloat(getIngridient[0].carbohydrates_100g) / 100) * parseFloat(thisCurrentAmount)
      );

      ingridientEwNew = Math.round((parseFloat(getIngridient[0].protein_100g) / 100) * parseFloat(thisCurrentAmount));

      ingridientFtNew = Math.round((parseFloat(getIngridient[0].fat_100g) / 100) * parseFloat(thisCurrentAmount));
    }

    if (thisCurrentAmount !== 0) {
      setCurrentEditIngridientAmount(thisCurrentAmount);
    } else {
      setCurrentEditIngridientAmount('');
    }
    setCurrentEditIngridientKcalTotal(ingridientKcalNew);
    setCurrentEditIngridientCarbohydratesTotal(ingridientKhNew);
    setCurrentEditIngridientProteinTotal(ingridientEwNew);
    setCurrentEditIngridientFatTotal(ingridientFtNew);
  };

  const changeIngridientExecute = () => {
    let currentCalculatedKcal;
    let currentCalculatedKH;
    let currentCalculatedEW;
    let currentCalculatedFT;
    let currentKcal: any = recipeStateValue?.kcal_total;
    let currentKH: any = recipeStateValue?.carbohydrates_total;
    let currentEW: any = recipeStateValue?.protein_total;
    let currentFT: any = recipeStateValue?.fat_total;
    let getIngridientRecipeData = [] as any;
    let getIngridient = [] as any;

    let thisEditIngredientAmount = currentEditIngridientAmount;

    if (thisEditIngredientAmount.toString().length === 0) {
      thisEditIngredientAmount = '0';
    }

    getIngridientRecipeData = recipeStateValue.ingredients.filter((item: any) => item.id === currentEditIngridientID);
    getIngridient = ingredientList?.filter((item: any) => item.name === currentEditIngridientName);

    if (getIngridientRecipeData[0].piece !== 'g' && getIngridientRecipeData[0].piece !== 'ml') {
      currentCalculatedKcal =
        ((parseFloat(getIngridientRecipeData[0].amount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
        parseFloat(getIngridient[0].kcal_100g);

      currentCalculatedKH =
        ((parseFloat(getIngridientRecipeData[0].amount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
        parseFloat(getIngridient[0].carbohydrates_100g);

      currentCalculatedEW =
        ((parseFloat(getIngridientRecipeData[0].amount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
        parseFloat(getIngridient[0].protein_100g);

      currentCalculatedFT =
        ((parseFloat(getIngridientRecipeData[0].amount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
        parseFloat(getIngridient[0].fat_100g);
    } else {
      currentCalculatedKcal =
        (parseFloat(getIngridientRecipeData[0].amount) / 100) * parseFloat(getIngridient[0].kcal_100g);

      currentCalculatedKH =
        (parseFloat(getIngridientRecipeData[0].amount) / 100) * parseFloat(getIngridient[0].carbohydrates_100g);

      currentCalculatedEW =
        (parseFloat(getIngridientRecipeData[0].amount) / 100) * parseFloat(getIngridient[0].protein_100g);

      currentCalculatedFT =
        (parseFloat(getIngridientRecipeData[0].amount) / 100) * parseFloat(getIngridient[0].fat_100g);
    }

    currentKcal = Math.round(parseFloat(currentKcal) - currentCalculatedKcal + currentEditIngridientKcalTotal);
    currentKH = Math.round(parseFloat(currentKH) - currentCalculatedKH + currentEditIngridientCarbohydratesTotal);
    currentEW = Math.round(parseFloat(currentEW) - currentCalculatedEW + currentEditIngridientProteinTotal);
    currentFT = Math.round(parseFloat(currentFT) - currentCalculatedFT + currentEditIngridientFatTotal);

    const newColumns = {
      ...recipeStateValue,
      kcal_total: currentKcal,
      carbohydrates_total: currentKH,
      protein_total: currentEW,
      fat_total: currentFT,
      ingredients: recipeStateValue?.ingredients.map((ingridients: any) => {
        if (parseFloat(ingridients.id) !== parseFloat(currentEditIngridientID)) return ingridients;
        return {
          ...ingridients,
          amount: thisEditIngredientAmount,
          piece: currentEditIngridientPiece,
        };
      }),
    };

    recipeState(newColumns);
    setEditPopupOverlayClass('hidden');
    setPopupOverlayClass('hidden');
    setVisible('block');
  };

  const changeIngridientPiece = (event: any) => {
    const thisCurrentPiece = event.value;
    let thisCurrentPieceLabel = event.label;
    let ingridientKcalNew;
    let ingridientKhNew;
    let ingridientEwNew;
    let ingridientFtNew;
    let getIngridientRecipeData = [] as any;
    let getIngridient = [] as any;

    let thisEditIngredientAmount = currentEditIngridientAmount;

    if (thisEditIngredientAmount.toString().length === 0) {
      thisEditIngredientAmount = '0';
    }

    if (thisCurrentPieceLabel.includes('(')) {
      thisCurrentPieceLabel = thisCurrentPieceLabel.substr(0, thisCurrentPieceLabel.indexOf('(') - 1);
    }

    if (currentEditIngridientType === 'notAdded') {
      getIngridientRecipeData = ingredientList?.filter((item: any) => item.name === currentEditIngridientName);
    } else {
      getIngridientRecipeData = recipeStateValue?.ingredients.filter(
        (item: any) => item.id === currentEditIngridientID
      );
    }

    getIngridient = ingredientList?.filter((item: any) => item.name === currentEditIngridientName);

    if (thisCurrentPiece !== 'g' && thisCurrentPiece !== 'ml') {
      ingridientKcalNew = Math.round(
        ((parseFloat(thisEditIngredientAmount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].kcal_100g)
      );

      ingridientKhNew = Math.round(
        ((parseFloat(thisEditIngredientAmount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].carbohydrates_100g)
      );

      ingridientEwNew = Math.round(
        ((parseFloat(thisEditIngredientAmount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].protein_100g)
      );

      ingridientFtNew = Math.round(
        ((parseFloat(thisEditIngredientAmount) * parseFloat(getIngridientRecipeData[0].preselect_g)) / 100) *
          parseFloat(getIngridient[0].fat_100g)
      );
    } else {
      ingridientKcalNew = Math.round(
        (parseFloat(getIngridient[0].kcal_100g) / 100) * parseFloat(thisEditIngredientAmount)
      );

      ingridientKhNew = Math.round(
        (parseFloat(getIngridient[0].carbohydrates_100g) / 100) * parseFloat(thisEditIngredientAmount)
      );

      ingridientEwNew = Math.round(
        (parseFloat(getIngridient[0].protein_100g) / 100) * parseFloat(thisEditIngredientAmount)
      );

      ingridientFtNew = Math.round(
        (parseFloat(getIngridient[0].fat_100g) / 100) * parseFloat(thisEditIngredientAmount)
      );
    }

    if (thisCurrentPieceLabel === 'Gramm') {
      setCurrentEditIngridientPiece('g');
    } else if (thisCurrentPieceLabel === 'Milliliter') {
      setCurrentEditIngridientPiece('ml');
    } else {
      setCurrentEditIngridientPiece(thisCurrentPieceLabel);
    }

    setCurrentEditIngridientKcalTotal(ingridientKcalNew);
    setCurrentEditIngridientCarbohydratesTotal(ingridientKhNew);
    setCurrentEditIngridientProteinTotal(ingridientEwNew);
    setCurrentEditIngridientFatTotal(ingridientFtNew);
  };

  // Update general recipe state
  function updateRecipeState(stateObject: any): void {
    recipeState(stateObject);
  }

  function calculatePortionGramm() {
    let thisPortionG = 0;

    recipeStateValue?.ingredients.map((ingridient: any) => {
      if (ingridient.piece !== 'g' && ingridient.piece !== 'ml') {
        thisPortionG = Math.round(thisPortionG + parseFloat(ingridient.amount) * parseFloat(ingridient.preselect_g));
      } else {
        thisPortionG = Math.round(thisPortionG + parseFloat(ingridient.amount));
      }
      return null;
    });

    const newColumns = {
      ...recipeStateValue,
      portion_g: thisPortionG.toString(),
    };

    jumpToNextStep(newColumns);
  }

  function returnToPreviousStep() {
    setVisible('hidden');
    currentStep('2');
  }

  function clickIngridientChange() {
    if (editIngridientRef.current) {
      editIngridientRef.current.select();
    }
  }

  useEffect(() => {
    if (currentStepValue !== '3') {
      setVisible('hidden');
    }

    if (searchIngridientRef.current && popupOverlayClass === 'block') {
      searchIngridientRef.current.select();
      scrollToTop(popupContainerRef);
    }

    if (editIngridientRef.current && editPopupOverlayClass === 'block') {
      editIngridientRef.current.select();
    }
  }, [currentStepValue, popupOverlayClass, recipeStateValue, editPopupOverlayClass, popupContainerRef]);

  return (
    <>
      <div className={popupOverlayClass}>
        <div className={styles.backgroundPopupLayer2}>
          <div className={styles.addIngridientPopup2}>
            <div className="flex justify-between pt-20 pl-20">
              <div>
                <div className="text-xl my-auto font-light">Suche hier nach deinen Zutaten</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setPopupOverlayClass, setVisible, 'both')}
                />
              </div>
            </div>
            <div className="pt-20 pl-20 pr-20">
              <div>
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    ingridientApiSearch(currentSearchQuery);
                  }}
                >
                  <SearchBox
                    thisRef={searchIngridientRef}
                    searchValue={currentSearchQuery}
                    onChange={ingridientAutoCompleteSearch}
                  />
                </form>
              </div>
            </div>
            <div className="pt-15 pl-20 pb-40">
              {currentIngridientSearchAutocomplete.suggestions !== null &&
                Array.isArray(currentIngridientSearchAutocomplete.suggestions.suggestion) &&
                currentIngridientSearchAutocomplete.suggestions.suggestion.map((plans, index) => (
                  <div
                    key={index}
                    onClick={() => ingridientApiSearch(plans)}
                    onKeyDown={() => ingridientApiSearch(plans)}
                    aria-hidden="true"
                  >
                    {plans.length > 1 && (
                      <div className="flex border-b py-7 border-blackSemiDark hover:bg-lightGray hover:bg-opacity-20 cursor-pointer mr-30">
                        <div className="pl-10 pr-15 my-auto">
                          <SearchIcon width={15} height={15} className="text-accentColor" />
                        </div>
                        <div key={index}>
                          <button type="button">{plans}</button>
                        </div>
                      </div>
                    )}
                  </div>
                ))}

              {currentIngridientSearchAutocomplete.suggestions !== null &&
                !Array.isArray(currentIngridientSearchAutocomplete.suggestions.suggestion) && (
                  <div className="flex border-b py-7 border-blackSemiDark hover:bg-lightGray hover:bg-opacity-20 cursor-pointer mr-30">
                    <div className="pl-10 pr-15 my-auto">
                      <SearchIcon width={15} height={15} className="text-accentColor" />
                    </div>
                    <div>
                      <button
                        type="button"
                        onClick={() =>
                          ingridientApiSearch(currentIngridientSearchAutocomplete.suggestions.suggestion.toString())
                        }
                      >
                        {currentIngridientSearchAutocomplete.suggestions.suggestion}
                      </button>
                    </div>
                  </div>
                )}
              <div className={styles.ingridientPopupContent}>
                {isLoading ? (
                  <div className="flex justify-center items-center pt-20">
                    <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
                  </div>
                ) : (
                  <>
                    {currentIngridientSearch &&
                      ingredientFilteredList?.map((item, index) => (
                        <div className="flex" key={index}>
                          <div
                            className={styles.contentItem}
                            onClick={() =>
                              handleEditIngridientPopup(
                                Math.random().toString(),
                                item.name,
                                item.preselect_amount,
                                item.preselect_type,
                                item.imageUrl,
                                item.preselect_g,
                                item.preselect_type,
                                item.kcal_100g,
                                item.carbohydrates_100g,
                                item.protein_100g,
                                item.fat_100g,
                                item.default_piece,
                                'notAdded',
                                item.category
                              )
                            }
                            onKeyDown={() =>
                              handleEditIngridientPopup(
                                Math.random().toString(),
                                item.name,
                                item.preselect_amount,
                                item.preselect_type,
                                item.imageUrl,
                                item.preselect_g,
                                item.preselect_type,
                                item.kcal_100g,
                                item.carbohydrates_100g,
                                item.protein_100g,
                                item.fat_100g,
                                item.default_piece,
                                'notAdded',
                                item.category
                              )
                            }
                            aria-hidden="true"
                          >
                            {(item.userIngridient === undefined || item.userIngridient === false) &&
                            item.communityIngridient === undefined ? (
                              <div className="absolute right-15 top-5 flex gap-5">
                                <div>
                                  <BadgeCheckIcon width={15} height={15} className="text-calories" />
                                </div>
                                {userData?.role === 2 ? (
                                  <div className="font-light text-10">Verified by Tortija</div>
                                ) : (
                                  <div className="font-light text-10">Verified</div>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                            {(item.userIngridient !== undefined || item.userIngridient === true) &&
                            item.communityIngridient === undefined ? (
                              <div className="absolute right-15 top-5 flex gap-5">
                                <div>
                                  <BadgeCheckIcon width={15} height={15} className="text-calories" />
                                </div>
                                <div className="font-light text-10">Eigenes Lebensmittel</div>
                              </div>
                            ) : (
                              <></>
                            )}
                            {item.communityIngridient !== undefined && item.communityIngridient === true ? (
                              <div className="absolute right-15 top-5 flex gap-5">
                                <div>
                                  <BadgeCheckIcon width={15} height={15} className="text-calories" />
                                </div>
                                <div className="font-light text-10">Community Lebensmittel</div>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className={styles.imageWrapper}>
                              <img
                                src={
                                  item.imageUrl
                                    ? item.imageUrl
                                    : theme?.logo !== undefined && userData?.role !== 2
                                    ? theme.logo
                                    : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fdefault_ingridient.png?alt=media&token=decc7814-19a3-41c9-9949-a965bbfa0ee2'
                                }
                                alt=""
                                width={45}
                                height={65}
                                loading="lazy"
                                className={styles.image}
                              />
                            </div>
                            <div className={styles.description}>
                              <div className="pr-5">
                                <div className={styles.itemTitle} title={item.name}>
                                  {item.name}
                                </div>

                                <div className="text-10 pb-10 font-light">
                                  {item.preselect_amount} {item.preselect_type}{' '}
                                  {item.preselect_type !== 'g' &&
                                    item.preselect_type !== 'ml' &&
                                    item.default_piece === undefined &&
                                    `(${parseFloat(item.preselect_amount) * parseFloat(item.preselect_g)}g)`}
                                  {item.preselect_type !== 'g' &&
                                    item.preselect_type !== 'ml' &&
                                    item.default_piece !== undefined &&
                                    `(${parseFloat(item.preselect_amount) * parseFloat(item.preselect_g)}${
                                      item.default_piece
                                    })`}
                                </div>
                              </div>
                              <div className={styles.category}>
                                <div className={styles.item}>
                                  <div className="text-12">
                                    {Math.round(
                                      ((parseFloat(item.preselect_amount) * parseFloat(item.preselect_g)) / 100) *
                                        parseFloat(item.kcal_100g)
                                    )}
                                  </div>
                                  <div>kcal</div>
                                </div>
                                <div className={styles.itemSecondary}>
                                  <div className="text-12">
                                    {Math.round(
                                      ((parseFloat(item.preselect_amount) * parseFloat(item.preselect_g)) / 100) *
                                        parseFloat(item.carbohydrates_100g)
                                    )}
                                  </div>
                                  <div>KH</div>
                                </div>
                                <div className={styles.itemSecondary}>
                                  <div className="text-12">
                                    {Math.round(
                                      ((parseFloat(item.preselect_amount) * parseFloat(item.preselect_g)) / 100) *
                                        parseFloat(item.protein_100g)
                                    )}
                                  </div>
                                  <div>EW</div>
                                </div>
                                <div className={styles.itemSecondary}>
                                  <div className="text-12">
                                    {Math.round(
                                      ((parseFloat(item.preselect_amount) * parseFloat(item.preselect_g)) / 100) *
                                        parseFloat(item.fat_100g)
                                    )}
                                  </div>
                                  <div>Fett</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="pl-5 my-auto">
                            <PlusIcon
                              width={25}
                              height={25}
                              className="text-accentColor cursor-pointer"
                              onClick={() =>
                                addIngridientDirectInternal(
                                  item.preselect_type,
                                  parseFloat(item.preselect_amount),
                                  item.preselect_g,
                                  item.kcal_100g,
                                  item.carbohydrates_100g,
                                  item.protein_100g,
                                  item.fat_100g,
                                  item.name,
                                  item.category,
                                  item.imageUrl,
                                  item.preselect_type,
                                  item.default_piece !== undefined ? item.default_piece : 'g'
                                )
                              }
                            />
                          </div>
                        </div>
                      ))}
                    {currentIngridientSearch &&
                      currentIngridientSearchApi.foods?.food?.length > 0 &&
                      currentIngridientSearchApi.foods.food.map((plans: any) => {
                        return (
                          <>
                            <div className="flex">
                              <div
                                className={styles.contentItem}
                                onClick={() => getAPIIngridientData(plans.food_id)}
                                onKeyDown={() => getAPIIngridientData(plans.food_id)}
                                aria-hidden="true"
                              >
                                <div className={styles.imageWrapper}>
                                  <img
                                    src="https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Fdefault_ingridient.png?alt=media&token=decc7814-19a3-41c9-9949-a965bbfa0ee2"
                                    alt=""
                                    width={45}
                                    height={45}
                                    loading="lazy"
                                    className={styles.image}
                                  />
                                </div>
                                <div className={styles.description}>
                                  <div className="pr-5">
                                    <div className={styles.itemTitle} title={plans.food_name}>
                                      {plans.food_name} {plans.brand_name !== undefined && `(${plans.brand_name})`}
                                    </div>
                                    <div className="text-10 font-light">
                                      {plans.food_description.substr(4, plans.food_description.indexOf('-') - 4)}
                                    </div>
                                  </div>
                                  <div className={styles.category}>
                                    <div className={styles.item}>
                                      <div className="text-12">
                                        {plans.food_description.match('Kalorien: (.*)kcal')[1]}
                                      </div>
                                      <div>kcal</div>
                                    </div>
                                    <div className={styles.itemSecondary}>
                                      <div className="text-12">
                                        {Math.round(parseFloat(plans.food_description.match('Kohlh: (.*)g')[1]))}
                                      </div>
                                      <div>KH</div>
                                    </div>
                                    <div className={styles.itemSecondary}>
                                      <div className="text-12">
                                        {Math.round(parseFloat(plans.food_description.match('Eiw: (.*)g')[1]))}
                                      </div>
                                      <div>EW</div>
                                    </div>
                                    <div className={styles.itemSecondary}>
                                      <div className="text-12">
                                        {Math.round(parseFloat(plans.food_description.match('Fett: (.*)g')[1]))}
                                      </div>
                                      <div>Fett</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="pl-5 my-auto">
                                <PlusIcon
                                  width={25}
                                  height={25}
                                  className="text-accentColor cursor-pointer"
                                  onClick={() => addIngridientDirectAPI(plans.food_id)}
                                />
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={editPopupOverlayClass}>
        <div className={styles.backgroundPopupLayer2}>
          <div className={styles.addIngridientPopup2}>
            <div className="flex justify-between pt-20 pl-20">
              <div className="flex">
                <div className="my-auto pr-10">
                  <PencilIcon width={25} height={25} className="text-accentColor mx-auto" />
                </div>
                <div className="text-xl my-auto font-light">Bearbeiten</div>
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => handleOpenClosePopups(setEditPopupOverlayClass, setPopupOverlayClass, 'both')}
                />
              </div>
            </div>
            <div className={styles.ingridientPopupContentEdit}>
              <div className="pt-15 pl-20 pb-30 pr-15">
                <div>
                  <div className="flex gap-20 pt-4">
                    <div className="font-light my-auto w-130">Menge auswählen:</div>
                    <div>
                      {currentEditIngridientType === 'notAdded' && (
                        <div onClick={() => clickIngridientChange()} aria-hidden="true">
                          <CustomUserInput
                            thisRef={editIngridientRef}
                            thisValue={currentEditIngridientAmount}
                            name="amount"
                            customPaddingY="10"
                            customPaddingX="10"
                            submitFunction={() =>
                              currentEditIngridientPopupType === 'api'
                                ? addChangedIngridientAPI()
                                : addIngridientDirectInternal(
                                    currentEditIngridientPiece,
                                    currentEditIngridientAmount.toString().length === 0
                                      ? 0
                                      : parseFloat(currentEditIngridientAmount),
                                    currentEditIngridientPreselectG,
                                    currentEditIngridientKcal100g,
                                    currentEditIngridientCarbohydrates100g,
                                    currentEditIngridientProtein100g,
                                    currentEditIngridientFat100g,
                                    currentEditIngridientName,
                                    currentEditIngridientCategory,
                                    currentEditIngridientImageUrl,
                                    currentEditIngridientPreselectType,
                                    currentEditIngridientDefaultPiece
                                  )
                            }
                            onChange={e =>
                              currentEditIngridientPopupType === 'api'
                                ? changeIngridientAmountAPI(e)
                                : changeIngridientAmount(e)
                            }
                            width={
                              currentEditIngridientAmount.toString().length === 0
                                ? 1
                                : currentEditIngridientAmount.toString().length
                            }
                          />
                        </div>
                      )}
                      {currentEditIngridientType !== 'notAdded' && (
                        <div onClick={() => clickIngridientChange()} aria-hidden="true">
                          <CustomUserInput
                            thisRef={editIngridientRef}
                            name="amount"
                            thisValue={currentEditIngridientAmount}
                            submitFunction={() =>
                              currentEditIngridientPopupType === 'api'
                                ? changeIngridientExecuteAPI()
                                : changeIngridientExecute()
                            }
                            onChange={e =>
                              currentEditIngridientPopupType === 'api'
                                ? changeIngridientAmountAPI(e)
                                : changeIngridientAmount(e)
                            }
                            width={
                              currentEditIngridientAmount.toString().length === 0
                                ? 1
                                : currentEditIngridientAmount.toString().length
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex gap-20 pt-4">
                    <div className="font-light my-auto w-130">Einheit auswählen:</div>
                    <div className="flex-1">
                      <div>
                        <Select
                          options={currentEditIngridientPortionValues}
                          className="react-select-container-full"
                          classNamePrefix="react-select"
                          unstyled
                          onChange={e =>
                            currentEditIngridientPopupType === 'api'
                              ? changeIngridientPieceAPI(e)
                              : changeIngridientPiece(e)
                          }
                          value={currentEditIngridientPortionValues.filter(
                            (item: any) => item.value === currentEditIngridientPiece.toLowerCase()
                          )}
                          isSearchable={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pb-20 md:pb-40">
                <div className="text-xl pl-20">Nährwertangabe</div>
                <div className="pt-10 flex pl-20">
                  <div className={styles.nutritionBorderItemFirst}>
                    <div>
                      <div className="font-semibold text-base">{currentEditIngridientKcalTotal}</div>
                      <div className={styles.recipeLabel}>kcal</div>
                    </div>
                    <span className="divider" />
                  </div>
                  <div className={styles.nutritionBorderItem}>
                    <div className="font-semibold text-base">{currentEditIngridientCarbohydratesTotal}g</div>
                    <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
                  </div>
                  <div className={styles.nutritionBorderItem}>
                    <div className="font-semibold text-base">{currentEditIngridientProteinTotal}g</div>
                    <div className={styles.recipeLabel}>{t('Protein')}</div>
                  </div>
                  <div className="text-center px-20">
                    <div className="font-semibold text-base">{currentEditIngridientFatTotal}g</div>
                    <div className={styles.recipeLabel}>{t('Fat')}</div>
                  </div>
                </div>
                {currentEditIngridientImageUrl.length > 0 && (
                  <>
                    <div className="pt-30 md:pt-40 pl-25">
                      <img
                        src={currentEditIngridientImageUrl}
                        alt="Ingridient"
                        className="rounded-2xl h-90 md:h-auto w-full object-cover"
                      />
                    </div>
                  </>
                )}
                <div className="pl-20 pt-30 md:pt-40 bottom-20">
                  {isLoading ? (
                    <div className="flex justify-center items-center pt-20">
                      <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
                    </div>
                  ) : (
                    <>
                      {currentEditIngridientType === 'add' && (
                        <Button
                          className="w-full"
                          onClick={() =>
                            currentEditIngridientPopupType === 'api'
                              ? changeIngridientExecuteAPI()
                              : addIngridientDirectInternal(
                                  currentEditIngridientPiece,
                                  currentEditIngridientAmount.toString().length === 0
                                    ? 0
                                    : parseFloat(currentEditIngridientAmount),
                                  '100',
                                  '100',
                                  '100',
                                  '100',
                                  '100',
                                  currentEditIngridientName,
                                  '100',
                                  currentEditIngridientImageUrl,
                                  currentEditIngridientPiece,
                                  currentEditIngridientDefaultPiece
                                )
                          }
                        >
                          {t('Save')}
                        </Button>
                      )}
                      {currentEditIngridientType === 'edit' && (
                        <Button
                          className="w-full"
                          onClick={() =>
                            currentEditIngridientPopupType === 'api'
                              ? changeIngridientExecuteAPI()
                              : changeIngridientExecute()
                          }
                        >
                          {t('Save')}
                        </Button>
                      )}
                      {currentEditIngridientType === 'notAdded' && (
                        <Button
                          className="w-full"
                          onClick={() =>
                            currentEditIngridientPopupType === 'api'
                              ? addChangedIngridientAPI()
                              : addIngridientDirectInternal(
                                  currentEditIngridientPiece,
                                  currentEditIngridientAmount.toString().length === 0
                                    ? 0
                                    : parseFloat(currentEditIngridientAmount),
                                  currentEditIngridientPreselectG,
                                  currentEditIngridientKcal100g,
                                  currentEditIngridientCarbohydrates100g,
                                  currentEditIngridientProtein100g,
                                  currentEditIngridientFat100g,
                                  currentEditIngridientName,
                                  currentEditIngridientCategory,
                                  currentEditIngridientImageUrl,
                                  currentEditIngridientPiece,
                                  currentEditIngridientDefaultPiece
                                )
                          }
                        >
                          {t('Add')}
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={visible}>
        <div className="pt-20 font-light text-base">Bitte trage hier die Zutaten für das neue Rezept ein!</div>
        <div className="flex gap-40 pt-4">
          <div className="font-light text-2xl">Nährwertangabe</div>
        </div>
        <div className="flex w-full gap-4 sticky py-4 top-0 z-10">
          <div className="flex-1 text-center">
            <div className="font-semibold text-base">{Math.round(parseFloat(recipeStateValue?.kcal_total!))}</div>
            <div className={styles.recipeLabel}>kcal</div>
          </div>
          <div className="flex-1 text-center">
            <div className="font-semibold text-base">
              {Math.round(parseFloat(recipeStateValue?.carbohydrates_total!))}g
            </div>
            <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
          </div>
          <div className="flex-1 text-center">
            <div className="font-semibold text-base">{Math.round(parseFloat(recipeStateValue?.protein_total!))}g</div>
            <div className={styles.recipeLabel}>{t('Protein')}</div>
          </div>
          <div className="flex-1 text-center">
            <div className="font-semibold text-base">{Math.round(parseFloat(recipeStateValue?.fat_total!))}g</div>
            <div className={styles.recipeLabel}>{t('Fat')}</div>
          </div>
        </div>
        <div className="flex gap-40 pt-4">
          <div className="font-light text-2xl">{t('IngredientsList')}</div>
        </div>
        <div
          className="flex pt-10 gap-5 cursor-pointer"
          onClick={() => handleOpenClosePopups(setVisible, setPopupOverlayClass, 'both')}
          onKeyDown={() => handleOpenClosePopups(setVisible, setPopupOverlayClass, 'both')}
          aria-hidden="true"
        >
          <div>
            <PlusIcon width={25} height={25} className="text-accentColor mx-auto" />
          </div>
          <div>Neue Zutat hinzufügen</div>
        </div>
        <div className="pt-20 pb-80">
          {recipeStateValue?.ingredients.map((ingridient: any) => (
            <>
              <IngridientItem
                ingridientData={ingridient}
                recipeData={recipeStateValue}
                updateFunction={recipeState}
                defaultValue={ingridient.amount}
                setRecipeChanged={setRecipeChanged}
                setDirectAddedIngridients={setDirectAddedIngridients}
                directAddedIngridients={directAddedIngridients}
              />
            </>
          ))}
        </div>
        <RecipeStepSwitch
          returnFunction={returnToPreviousStep}
          nextFunction={calculatePortionGramm}
          currentStepValue="3"
          totalSteps={6}
        />
      </div>
    </>
  );
};

export default NewRecipeStep3;
