const documentTypeOptions: any = [
  { value: 'anamnese', label: 'Anamnese' },
  { value: 'doc', label: 'Arztberichte' },
  { value: 'picture', label: 'Bilder' },
  { value: 'dataprivacy', label: 'Datenschutz' },
  { value: 'measurement', label: 'Körper-Analyse' },
  { value: 'other', label: 'Sonstiges' },
  { value: 'contract', label: 'Vertragliches' },
  { value: 'message', label: 'Nachrichten' },
];

export default documentTypeOptions;
