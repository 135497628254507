import React, { useEffect, useContext, useState, ChangeEvent } from 'react';
import { TrashIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import CustomInput from 'components/Input/custom-input';

import TransitionContainer from 'components/TransitionContainer';
import Headline from 'components/Headline';
import firebase from 'services/firebase';
import { AuthContext } from 'providers/AuthProvider';
import documentTypeOptions from 'shared/constants/documents';
import Button from 'components/Button';
import DeleteDocumentOverlay from './DeleteDocumentOverlay';

type Document = {
  fileName: string;
  date: number;
  category: string;
  downloadUrl: string;
  chatId: string;
};

export default function Documents() {
  const { user, tenant } = useContext(AuthContext);
  const [documents, setDocuments] = useState<Document[] | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredDocuments, setFilteredDocuments] = useState<Document[] | null>(null);
  const [deleteFileName, setDeleteFileName] = useState<{ fileName: string; chatId: string } | null>(null);
  const history = useHistory();

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  useEffect(() => {
    const filteredResults = documents?.filter((document: Document) => {
      if (searchTerm.length === 0) {
        return true;
      }
      return document.fileName.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setFilteredDocuments(filteredResults || []);
  }, [searchTerm, documents]);

  useEffect(() => {
    const fetchDocuments = async () => {
      const chatsSnapshot = await firebase
        .firestore()
        .collection(`tenants/${tenant}/chats`)
        .where('users', 'array-contains', user?.uid)
        .get();

      const chatIds = chatsSnapshot.docs.map(doc => doc.id);

      const messagePromises = chatIds.map(chatId =>
        firebase
          .firestore()
          .collection(`tenants/${tenant}/chats/${chatId}/messages`)
          .where('fileUrl', '!=', null)
          .get()
          .then(snapshot => ({
            chatId,
            messages: snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
          }))
      );

      const messagesWithChatIds = await Promise.all(messagePromises);

      const allMessages = messagesWithChatIds.flatMap(({ chatId, messages }) =>
        messages
          .filter((message: any) => !message.fileName.includes('.webm'))
          .map((message: any) => ({
            id: message.id,
            fileName: message.fileName,
            date: moment(message.createdAt.toDate()).unix(),
            category: 'message',
            downloadUrl: message.fileUrl,
            chatId,
          }))
      );

      setDocuments(allMessages);
    };
    fetchDocuments();
  }, [deleteFileName]);

  return (
    <>
      <DeleteDocumentOverlay
        fileName={deleteFileName?.fileName || ''}
        isOpen={deleteFileName !== null}
        onClose={() => setDeleteFileName(null)}
        chatId={deleteFileName?.chatId || ''}
      />

      <TransitionContainer isShown className="">
        <div className="flex justify-between items-center pt-40 font-light">
          <Headline level={1} displayBackBtn goBack={() => history.goBack()}>
            Dokumente
          </Headline>
        </div>

        <div className="pt-60 w-full xxl:w-3/4">
          <div className="font-bold hidden text-20 my-auto">Liste der Dokumente</div>
          <div className="flex justify-between flex-wrap gap-20">
            <div className="flex-1">
              <CustomInput
                type="text"
                placeholder="Dokument suchen"
                name="search"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
                className="w-full"
              />
            </div>
          </div>
          {filteredDocuments && filteredDocuments.length > 0 ? (
            <div className="pt-20">
              {filteredDocuments.map((item: Document, index: number) => (
                <div key={index} className="hover:bg-lightGray">
                  {index === 0 && <hr />}
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-5 px-5 md:py-10 md:px-10">
                    {/* Datei-Info */}
                    <div className="flex flex-wrap items-center gap-4">
                      {(item.fileName.toLowerCase().includes('jpg') ||
                        item.fileName.toLowerCase().includes('png') ||
                        item.fileName.toLowerCase().includes('webp') ||
                        item.fileName.toLowerCase().includes('gif')) && (
                        <div className="w-50 h-50">
                          <img alt="" className="w-full h-full object-cover rounded" src={item.downloadUrl} />
                        </div>
                      )}
                      <div className="truncate">
                        <div className="font-bold">{item.fileName}</div>
                        <div className="text-14 font-extralight">{moment.unix(item.date).format('DD.MM.YYYY')}</div>
                      </div>
                    </div>

                    {/* Dokumententyp (mittlere Spalte) */}
                    <div className="flex justify-center items-center text-center break-words">
                      {documentTypeOptions.find((categoryItem: any) => categoryItem.value === item.category)?.label}
                    </div>

                    {/* Aktionen (rechte Spalte) */}
                    <div className="flex justify-end items-center gap-4">
                      <a href={item.downloadUrl} target="_blank" rel="noreferrer">
                        <Button className="py-2 px-4">Download</Button>
                      </a>
                      <TrashIcon
                        width={25}
                        height={25}
                        className="text-accentColor cursor-pointer"
                        onClick={() => setDeleteFileName({ fileName: item.fileName, chatId: item.chatId })}
                      />
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          ) : (
            <div className="font-bold text-20 pt-40">Es wurden leider keine Dokumente gefunden!</div>
          )}
        </div>
      </TransitionContainer>
    </>
  );
}
