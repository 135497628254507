import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'components/Card';
import Button from 'components/Button';
import { PlusIcon } from '@heroicons/react/24/outline';
import styles from './style.module.scss';

type Props = {
  updateFunction: any;
  defaultValue: string;
  changedDate: any;
  mobilePopupClass?: string;
};

const RateSection: React.FC<Props> = ({ updateFunction, defaultValue, changedDate, mobilePopupClass }) => {
  const { t } = useTranslation();
  const [isTyping, setIsTyping] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState(defaultValue);

  const descriptionRef = useRef<HTMLTextAreaElement>(null);

  const handleChange = (event: any) => {
    setDescriptionValue(event.target.value);
  };

  const clickNewDescription = () => {
    setIsTyping(true);

    setTimeout(() => {
      if (descriptionRef.current) {
        descriptionRef.current.focus();
      }
    }, 1);
  };

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  useEffect(() => {
    setDescriptionValue(defaultValue);
    if (defaultValue.length === 0) {
      setIsTyping(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedDate]);

  useEffect(() => {
    setDescriptionValue(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobilePopupClass]);

  return (
    <>
      <div className="mt-15">
        {isTyping || defaultValue.length > 0 ? (
          <>
            <Card
              isWithoutImage
              className="border-transparent border-2 hover:border-accentColor cursor-pointer md:h-220 h-200"
            >
              <div className="mt-20 mx-10 px-10 h-150 md:h-180">
                <textarea
                  className="break-all border border-opacity-30 border-accentColor outline-none text-textColor p-15  h-full w-full bg-lightGray rounded-xl resize-none"
                  onInput={handleChange}
                  onFocus={setBodyClassForMobileSafari}
                  onBlur={removeBodyClassForMobileSafari}
                  value={descriptionValue}
                  ref={descriptionRef}
                />
              </div>
            </Card>
            <div className="md:pt-20 pt-0 pl-20 md:pl-0 pb-20 md:pb-0">
              <Button className="text-14 md:py-10 py-2" onClick={() => updateFunction(descriptionValue)}>
                Notiz speichern
              </Button>
            </div>
          </>
        ) : (
          <div onClick={() => clickNewDescription()} onKeyDown={() => clickNewDescription()} aria-hidden="true">
            <Card
              isWithoutImage
              className="border-transparent border-2 hover:border-accentColor cursor-pointer md:h-220 h-200"
            >
              <div className={styles.addIcon}>
                <div className="pt-20">
                  <PlusIcon width={42} height={42} className="text-accentColor cursor-pointer mx-auto" />
                </div>
                <div className="text-center font-semibold text-18 px-20 pt-15">Neue Notiz hinzufügen</div>
              </div>
            </Card>
          </div>
        )}
      </div>
    </>
  );
};

export default RateSection;
