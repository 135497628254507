import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LinkIcon } from '@heroicons/react/24/outline';

import TransitionContainer from 'components/TransitionContainer';
import { AuthContext } from 'providers/AuthProvider';
import Headline from 'components/Headline';

import styles from './styles.module.scss';

type Props = {
  isShown: boolean;
  title: string;
  goBack?: React.MouseEventHandler<HTMLButtonElement>;
  setLegalPopupClass?: Function;
  setLegalType?: Function;
};

const Legal: React.FC<Props> = ({ isShown, setLegalPopupClass, setLegalType, title, goBack = () => {} }) => {
  const { tenantData, userData } = useContext(AuthContext);
  const { t } = useTranslation();

  const openLegalPopup = (type: string) => {
    if (setLegalType !== undefined && setLegalPopupClass !== undefined) {
      setLegalType(type);
      setLegalPopupClass('block');
    }
  };

  return (
    <TransitionContainer isShown={isShown}>
      <div className={styles.header}>
        <Headline level={1} className="mb-20">
          {t(title)}
        </Headline>
        <Headline level={4} displayBackBtn goBack={goBack}>
          {t('Return')}
        </Headline>
      </div>
      <div className={styles.content}>
        {tenantData?.legal?.imprint !== undefined && tenantData?.legal?.noWebsite === true ? (
          <div
            onClick={() => openLegalPopup('imprint')}
            onKeyDown={() => openLegalPopup('imprint')}
            aria-hidden="true"
            className={styles.button}
          >
            <div>
              <LinkIcon width={55} height={55} className={styles.icon} />
              <Headline level={4} className={styles.label}>
                Impressum
              </Headline>
            </div>
          </div>
        ) : (
          <Link
            to={{ pathname: tenantData?.legal?.imprint ?? 'https://www.tortija.de/impressum/' }}
            target="_blank"
            className={styles.button}
          >
            <div>
              <LinkIcon width={55} height={55} className={styles.icon} />
              <Headline level={4} className={styles.label}>
                Impressum
              </Headline>
            </div>
          </Link>
        )}

        {tenantData?.legal?.privacyPolicy !== undefined && tenantData?.legal?.noWebsite === true ? (
          <div
            onClick={() => openLegalPopup('dataprivacy')}
            onKeyDown={() => openLegalPopup('dataprivacy')}
            aria-hidden="true"
            className={styles.button}
          >
            <div>
              <LinkIcon width={55} height={55} className={styles.icon} />
              <Headline level={4} className={styles.label}>
                Datenschutz
              </Headline>
            </div>
          </div>
        ) : (
          <Link
            to={{ pathname: tenantData?.legal?.privacyPolicy ?? 'https://www.tortija.de/datenschutz/' }}
            target="_blank"
            className={styles.button}
          >
            <div>
              <LinkIcon width={55} height={55} className={styles.icon} />
              <Headline level={4} className={styles.label}>
                Datenschutz
              </Headline>
            </div>
          </Link>
        )}

        {tenantData?.legal?.termsOfService !== undefined && tenantData?.legal?.noWebsite === true ? (
          <div
            onClick={() => openLegalPopup('agb')}
            onKeyDown={() => openLegalPopup('agb')}
            aria-hidden="true"
            className={styles.button}
          >
            <div>
              <LinkIcon width={55} height={55} className={styles.icon} />
              <Headline level={4} className={styles.label}>
                Nutzungsbedingungen
              </Headline>
            </div>
          </div>
        ) : (
          <Link
            to={{ pathname: tenantData?.legal?.termsOfService ?? 'https://www.tortija.de/agb/' }}
            target="_blank"
            className={styles.button}
          >
            <div>
              <LinkIcon width={55} height={55} className={styles.icon} />
              <Headline level={4} className={styles.label}>
                Nutzungsbedingungen
              </Headline>
            </div>
          </Link>
        )}
      </div>
    </TransitionContainer>
  );
};

export default Legal;
