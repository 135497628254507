import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toast';
import axios from 'axios';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import ReactLoading from 'react-loading';
import firebase from 'services/firebase';
import Headline from 'components/Headline';
import TransitionContainer from 'components/TransitionContainer';
import Input from 'components/Input';
import Button from 'components/Button';
import { AuthContext } from 'providers/AuthProvider';
import CustomSelect from 'components/CustomSelect';
import { defaultTextColor } from 'layouts/Theme';
import {
  ROLE_COMPANY_ADMIN,
  ROLE_MEMBER,
  ROLE_TRAINER,
  ROLE_LOCATION_ADMIN,
  companyRoleOptions,
} from 'shared/constants/global';

import styles from './styles.module.scss';

export default function AddCompanyMember() {
  const { t } = useTranslation();
  const history = useHistory();
  const { tenant, theme, companyData, tenantData, userData } = useContext(AuthContext);
  const { register, handleSubmit } = useForm();
  const [isPending, setIsPending] = useState(false);
  const [memberLoaded, setMemberLoaded] = useState(false);
  const [memberWithUserViewLoaded, setMemberWithUserViewLoaded] = useState(false);
  const [showRoleInfos, setShowRoleInfos] = useState(false);
  const [role, setRole] = useState(5);
  const [trainer, setTrainer] = useState();
  const [currentMemberData, setCurrentMemberData] = useState<any>([]);
  const [currentMemberDataWithUserView, setCurrentMemberDataWithUserView] = useState<any>([]);
  const [trainerOptions, setTrainerOptions] = useState<any>([]);

  const trainerSelectRef = React.useRef() as any;

  const [location, setLocation] = useState();
  const [invitedMembers, setInvitedMembers] = useState<UserInfo[] | undefined>(undefined);

  const roleDefinitions = [
    {
      label: 'Admin',
      description: 'Hat Zugriff auf alle Funktionen und Bereiche.',
      onlyAdmin: true,
    },
    {
      label: 'Mitglied',
      description: 'Hat Zugriff auf die Kunden App.',
      onlyAdmin: false,
    },
    {
      label: 'Trainer',
      description:
        'Hat lesenden Zugriff auf die Mitgliederliste, kann Mitglieder hinzufügen und sich als Mitglied einloggen.',
      onlyAdmin: false,
    },
    {
      label: 'Gruppenleiter',
      description: 'Hat schreibenden Zugriff auf die Mitgliederliste und kann dort alle Einstellungen vornehmen.',
      onlyAdmin: true,
    },
  ];

  const onSubmit = async (data: any) => {
    setIsPending(true);

    const thisEmail = data.email.trim();

    // check if user is already invited
    const collection = firebase.firestore().collection('user-invitations');
    const inviteData = await collection.where('email', '==', thisEmail).get();
    if (!inviteData.empty) {
      toast.warn(t('Member has been already invited.'));
      setIsPending(false);
      return;
    }

    // check if user is already member
    const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(thisEmail);
    if (signInMethods.length > 0) {
      toast.warn(t('Member already exists.'));
      setIsPending(false);
      return;
    }

    const memberData = await firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .where('email', '==', thisEmail)
      .get();
    if (!memberData.empty) {
      toast.warn(t('Member already exists.'));
      setIsPending(false);
      return;
    }

    const documentRef = firebase.firestore().collection(`tenants/${tenant}/users`).doc(trainer);

    collection.add({
      ...data,
      email: thisEmail,
      role,
      trainer: trainer ? { newTrainer: true } : null,
      trainerRef: trainer ? documentRef : null,
      location: location || null,
      tenant,
      created: firebase.firestore.FieldValue.serverTimestamp(),
    });

    let invitationMailText = `Hallo ${data.fullName},\n\ndu wurdest zu der Ernährungssoftware von ${
      companyData?.companyName
    } eingeladen.\n\nBitte klicke auf den folgenden Link, um weitere Informationen zu erhalten:\n${
      window.location.hostname === 'demo.tortija.de'
        ? 'https://whitelabel.tortija.de/demo/'
        : tenantData?.invitationLink !== undefined
        ? tenantData?.invitationLink
        : 'https://whitelabel.tortija.de/einladung/'
    }\n\nViel Spaß mit unserer Ernährungssoftware!`;

    if (
      tenantData?.settings?.invitationMail?.mailText !== undefined &&
      tenantData?.settings?.invitationMail?.mailText?.length > 0
    ) {
      invitationMailText = `Hallo ${data.fullName},\n\n${tenantData?.settings?.invitationMail?.mailText}`;
    }

    await axios({
      url: `${process.env.REACT_APP_API_URL}/sendTextMail`,
      method: 'post',
      data: {
        mailSubject:
          tenantData?.settings?.invitationMail?.mailSubject !== undefined &&
          tenantData?.settings?.invitationMail?.mailSubject?.length > 0
            ? tenantData?.settings?.invitationMail?.mailSubject
            : `${companyData?.companyName} Einladung`,
        mailTo: thisEmail,
        mailText: invitationMailText,
        mailFrom:
          tenantData?.settings?.invitationMail?.mailFrom !== undefined &&
          tenantData?.settings?.invitationMail?.mailFrom?.length > 0
            ? tenantData?.settings?.invitationMail?.mailFrom
            : 'info@tortija.de',
        mailName:
          tenantData?.settings?.invitationMail?.mailName !== undefined &&
          tenantData?.settings?.invitationMail?.mailName?.length > 0
            ? tenantData?.settings?.invitationMail?.mailName
            : '',
      },
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    }).then(
      response => {
        console.log(response);
      },
      error => {
        console.log(error);
      }
    );

    setIsPending(false);
    history.push('/member');
  };

  const roleChange = (value: any) => {
    setRole(value.value);
  };

  const trainerChange = (value: any) => {
    if (value !== null) {
      setTrainer(value.value);
    }
  };

  const locationChange = (value: any) => {
    setLocation(value.value);

    if (trainerSelectRef.current) {
      trainerSelectRef.current.clearValue();
      setTrainer(undefined);
    }

    const newTrainerOptions =
      [...(invitedMembers ?? []), ...(currentMemberData ?? []), ...(currentMemberDataWithUserView ?? [])]
        ?.filter(
          item => (item.role === ROLE_TRAINER || item.isTrainerUser === true) && item.tenantLocation === value.value
        )
        .map(item => {
          return { value: item.uid, label: item.fullName };
        }) ?? [];

    setTrainerOptions(newTrainerOptions);
  };

  const locationOptions: any =
    [...(tenantData?.settings?.locations ?? [])]?.map(item => {
      return { value: item.id, label: item.name };
    }) ?? [];

  useEffect(() => {
    const unsubscribeInvitations = firebase
      .firestore()
      .collection('user-invitations')
      .where('tenant', '==', tenant)
      .onSnapshot(
        snapshot => {
          const inviteData: any = [];

          snapshot.forEach(doc => {
            inviteData.push({ ...doc.data(), uid: doc.id, type: 'invite' });
          });

          setInvitedMembers(inviteData);
        },
        error => {
          console.error('Error getting user-invitations for tenant: ', error);
        }
      );

    return unsubscribeInvitations;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .orderBy('created', 'desc')
      .where('role', '==', ROLE_TRAINER)
      .onSnapshot(
        snapshot => {
          const users: any = [];

          snapshot.forEach(doc => {
            users.push({ ...doc.data(), uid: doc.id });
          });

          setCurrentMemberData(users);
          setMemberLoaded(true);
        },
        error => {
          console.error('Error getting all user from tenant: ', error);
          setMemberLoaded(true);
        }
      );
    return unsubscribe;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const unsubscribeTrainerWithUserView = firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .orderBy('created', 'desc')
      .where('isTrainerUser', '==', true)
      .where('role', '==', 5)
      .onSnapshot(
        snapshot => {
          const users: any = [];

          snapshot.forEach(doc => {
            users.push({ ...doc.data(), uid: doc.id });
          });

          setCurrentMemberDataWithUserView(users);
          setMemberWithUserViewLoaded(true);
        },
        error => {
          console.error('Error getting all user from tenant: ', error);
          setMemberWithUserViewLoaded(true);
        }
      );
    return unsubscribeTrainerWithUserView;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTrainerOptions(
      [...(invitedMembers ?? []), ...(currentMemberData ?? []), ...(currentMemberDataWithUserView ?? [])]
        ?.filter(item => item.role === ROLE_TRAINER || item.isTrainerUser === true)
        .map(item => {
          return { value: item.uid, label: item.fullName };
        }) ?? []
    );

    if (
      tenantData?.settings?.locations !== undefined &&
      tenantData?.settings?.locations.length > 0 &&
      userData?.role === ROLE_TRAINER &&
      userData?.tenantLocation !== undefined
    ) {
      const findLocation = locationOptions.filter((item: any) => item.value === userData?.tenantLocation)[0];

      if (findLocation !== undefined && findLocation.value !== undefined) {
        const newTrainerOptions =
          [...(invitedMembers ?? []), ...(currentMemberData ?? []), ...(currentMemberDataWithUserView ?? [])]
            ?.filter(
              item =>
                (item.role === ROLE_TRAINER || item.isTrainerUser === true) &&
                item.tenantLocation === findLocation.value
            )
            .map(item => {
              return { value: item.uid, label: item.fullName };
            }) ?? [];

        setTrainerOptions(newTrainerOptions);

        setLocation(findLocation.value);
      }
    } else if (
      tenantData?.settings?.locations !== undefined &&
      tenantData?.settings?.locations.length > 0 &&
      userData?.role === ROLE_LOCATION_ADMIN &&
      userData?.tenantLocation !== undefined
    ) {
      const findLocation = locationOptions.filter((item: any) => item.value === userData?.tenantLocation)[0];

      if (findLocation !== undefined && findLocation.value !== undefined) {
        const newTrainerOptions =
          [...(invitedMembers ?? []), ...(currentMemberData ?? []), ...(currentMemberDataWithUserView ?? [])]
            ?.filter(
              item =>
                (item.role === ROLE_TRAINER || item.isTrainerUser === true) &&
                item.tenantLocation === findLocation.value
            )
            .map(item => {
              return { value: item.uid, label: item.fullName };
            }) ?? [];

        setTrainerOptions(newTrainerOptions);

        setLocation(findLocation.value);
      }
    }
    // eslint-disable-next-line
  }, [invitedMembers, currentMemberData, currentMemberDataWithUserView]);

  return (
    <>
      {memberLoaded && memberWithUserViewLoaded ? (
        <div>
          <div className={styles.header}>
            <Headline level={1} displayBackBtn goBack={history.goBack}>
              {t('Add Member')}
            </Headline>
          </div>
          <p className="mb-10">
            Hier können Sie neue Accounts anlegen. Bitte geben Sie den Namen und die E-Mail Adresse der Person ein, die
            Sie hinzufügen möchten und wählen Sie bei normalen Mitgliedern auch gerne direkt den Trainer ggf. einen
            Standort aus.
          </p>
          <p className="mb-10">Die eingeladene Person erhält eine E-Mail mit einem Einladungslink.</p>
          {(userData?.role === ROLE_COMPANY_ADMIN || userData?.role === ROLE_LOCATION_ADMIN) && (
            <div>
              <div className="flex cursor-pointer" onClick={() => setShowRoleInfos(!showRoleInfos)} aria-hidden="true">
                <div
                  className={
                    showRoleInfos
                      ? 'rounded-3xl bg-accentColor text-buttonTextColor py-10 px-20 flex gap-10'
                      : 'rounded-3xl bg-lightGray py-10 px-20 flex gap-10'
                  }
                >
                  <div>Informationen zu den Berechtigungs-Rollen</div>
                  <div className="my-auto">
                    <ChevronDownIcon
                      width={17}
                      height={17}
                      className={showRoleInfos ? 'text-buttonTextColor' : 'text-accentColor'}
                    />
                  </div>
                </div>
              </div>
              <TransitionContainer isShown={showRoleInfos} isOverlay>
                <div className="relative">
                  <div className="absolute top-0 z-10">
                    <div className="flex">
                      <div className="mt-5 rounded-md bg-lightGray py-5 px-20 border border-accentColor">
                        {userData?.role === ROLE_COMPANY_ADMIN ? (
                          <div className="pt-15">
                            {roleDefinitions.map((item: any, index: number) => (
                              <div className="pb-15" key={index}>
                                <div className="font-bold text-18">{item.label}</div>
                                <div className="font-extralight">{item.description}</div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="pt-15">
                            {roleDefinitions
                              .filter((subItem: any) => subItem.onlyAdmin === false)
                              .map((item: any, index: number) => (
                                <div className="pb-15" key={index}>
                                  <div className="font-bold text-18">{item.label}</div>
                                  <div className="font-extralight">{item.description}</div>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </TransitionContainer>
            </div>
          )}
          <div className="mt-50">
            <div className={styles.content}>
              <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <label className="block text-sm font-regular text-textColor opacity-50 mb-1">{t('Name')}</label>
                  <Input name="fullName" type="text" label="Name" required register={register('fullName')} />
                </div>
                <div>
                  <label className="block text-sm font-regular text-textColor opacity-50 mb-1">{t('Email')}</label>
                  <Input name="email" type="text" label="Email" required register={register('email')} />
                </div>
                {(userData?.role === ROLE_COMPANY_ADMIN || userData?.role === ROLE_LOCATION_ADMIN) && (
                  <div className="w-full md:w-1/2">
                    <CustomSelect
                      name="role"
                      dropDownOption={
                        userData?.role !== ROLE_COMPANY_ADMIN
                          ? companyRoleOptions.filter(
                              (item: any) => item.value !== ROLE_COMPANY_ADMIN && item.value !== ROLE_LOCATION_ADMIN
                            )
                          : companyRoleOptions
                      }
                      label={t('Role')}
                      isSearchable
                      onChange={roleChange}
                      defaultValue={companyRoleOptions.filter((item: any) => item.value === ROLE_MEMBER)}
                      isFullSize
                    />
                  </div>
                )}
                {tenantData?.settings?.locations !== undefined &&
                  tenantData?.settings?.locations.length > 0 &&
                  (userData?.role === ROLE_COMPANY_ADMIN ||
                    userData?.role === ROLE_LOCATION_ADMIN ||
                    ((userData?.role === ROLE_TRAINER || userData?.role === ROLE_LOCATION_ADMIN) &&
                      (tenantData?.settings?.functions?.trainerLocation === undefined ||
                        tenantData?.settings?.functions?.trainerLocation === true))) &&
                  role !== 3 && (
                    <div className="w-full md:w-1/2">
                      <CustomSelect
                        name="location"
                        dropDownOption={locationOptions}
                        label="Standort"
                        isSearchable
                        onChange={locationChange}
                        isFullSize
                        defaultValue={
                          (userData?.role === ROLE_TRAINER || userData?.role === ROLE_LOCATION_ADMIN) &&
                          userData?.tenantLocation !== undefined
                            ? locationOptions.filter((item: any) => item.value === userData?.tenantLocation)
                            : locationOptions.filter((item: any) => item.value === 0)
                        }
                      />
                    </div>
                  )}
                {role === 5 && (
                  <div className="w-full md:w-1/2">
                    <CustomSelect
                      name="trainer"
                      dropDownOption={trainerOptions}
                      label={t('Trainer')}
                      isSearchable
                      onChange={trainerChange}
                      isFullSize
                      thisRef={trainerSelectRef}
                    />
                  </div>
                )}

                <Button disabled={isPending} isPending={isPending} className="w-full">
                  {t('Jetzt einladen')}
                </Button>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <ReactLoading type="bars" width={30} height={30} color={theme?.textColor ?? defaultTextColor} />
      )}
    </>
  );
}
