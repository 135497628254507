import React, { useContext, useEffect, useState } from 'react';
import firebase from 'firebase/app';

import Modal from 'pages/Surveys/components/Modal';
import Button from 'components/Button';
import { ROLE_MEMBER, ROLE_TRAINER } from 'shared/constants/global';
import { AuthContext } from 'providers/AuthProvider';
import ChatAssigneeSelect from '../ChatAssigneeSelect';

type Props = {
  onClose: () => void;
  onChatCreate: (assignee: any) => void;
};

export default function CreateChat({ onClose, onChatCreate }: Props) {
  const db = firebase.firestore();
  const { tenant, user, userData } = useContext(AuthContext);

  const [allMemberOfTeneant, setAllMemberOfTeneant] = useState<any[]>([]);
  const [assignee, setAssignee] = useState<any>({
    value: '',
    label: userData?.role === ROLE_TRAINER ? 'Mitglied auswählen' : 'Trainer auswählen',
  });

  useEffect(() => {
    const fetchAllMemberOfTeneant = async () => {
      if (userData?.role === ROLE_MEMBER) {
        const allMemberOfTeneantRequest = await db
          .collection('tenants')
          .doc(tenant)
          .collection('users')
          .where('role', '==', ROLE_TRAINER)
          .get();

        const allMemberOfTeneantData = allMemberOfTeneantRequest.docs.map(doc => ({
          uid: doc.id,
          ...doc.data(),
        }));
        setAllMemberOfTeneant(allMemberOfTeneantData);
      }

      if (userData?.role === ROLE_TRAINER) {
        const allMemberOfTeneantRequest = await db
          .collection('tenants')
          .doc(tenant)
          .collection('users')
          .where('role', '==', ROLE_MEMBER)
          .get();

        const allMemberOfTeneantData = allMemberOfTeneantRequest.docs.map(doc => ({
          uid: doc.id,
          ...doc.data(),
        }));
        const filteredAllMemberOfTeneantData = allMemberOfTeneantData.filter(
          (member: any) => member.trainer?.uid === user?.uid || member.shareChats === true
        );
        setAllMemberOfTeneant(filteredAllMemberOfTeneantData);
      }
    };

    fetchAllMemberOfTeneant();
  }, [tenant, user?.uid, userData?.role]);

  const handleCreateChat = async () => {
    const chatSnapshot = await db
      .collection('tenants')
      .doc(tenant)
      .collection('chats')
      .where('users', 'array-contains', assignee.value)
      .get();

    const existingChat = chatSnapshot.docs.find(doc => {
      const { users } = doc.data();
      return users.includes(assignee.value) && users.includes(user?.uid);
    });

    if (existingChat) {
      onChatCreate(existingChat.id);
      return;
    }

    const newChat = await db
      .collection('tenants')
      .doc(tenant)
      .collection('chats')
      .add({
        users: [assignee.value, user?.uid],
        createdAt: new Date(),
      });

    onChatCreate(newChat.id);
  };

  return (
    <Modal>
      <div className="mb-20">
        <h1 className="text-2xl">Neuer Chat</h1>
      </div>

      <ChatAssigneeSelect
        selectOptions={allMemberOfTeneant.map(member => ({
          value: member.uid,
          label: member.fullName,
        }))}
        assignee={assignee?.label}
        onSetAssignee={setAssignee}
      />

      <div className="flex justify-between mt-50">
        <Button buttonStyle="dark" onClick={onClose}>
          Abbrechen
        </Button>
        <Button buttonStyle="primary" onClick={handleCreateChat}>
          Starten
        </Button>
      </div>
    </Modal>
  );
}
