import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MainContext } from 'providers/MainProvider';
import { AuthContext } from 'providers/AuthProvider';
import { savePlanChangesToFirebase } from 'shared/functions/global';
import { TrashIcon, XMarkIcon as XIcon } from '@heroicons/react/24/outline';
import Select from 'react-select';
import Button from 'components/Button';
import styles from './styles.module.scss';

type Props = {
  ingridientItem?: any;
  type?: string;
  ingredientType?: string;
  openPopup?: any;
  mealType?: string;
  dayId?: number;
  planUid?: string;
  mealLabel?: string;
  isDashBoard?: boolean;
  isDelete?: boolean;
  isManual?: boolean;
  planState?: any;
  planStateValue?: any;
};

type ParamsType = {
  id: string;
};

const IngridientPopupItem: React.FC<Props> = ({
  ingridientItem,
  type,
  openPopup,
  ingredientType,
  mealType,
  dayId,
  planUid,
  mealLabel,
  planState,
  planStateValue,
  isDashBoard = false,
  isManual = false,
  isDelete = false,
}) => {
  const { setTempRecipeObject } = useContext(MainContext);
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const { userData, tenant, theme } = useContext(AuthContext);
  const { id } = useParams<ParamsType>();

  const mealTypeValues: any = [
    { value: 'breakfast', label: 'Frühstück' },
    { value: 'lunch', label: 'Mittagessen' },
    { value: 'dinner', label: 'Abendessen' },
    { value: 'snacks', label: 'Snacks' },
  ];

  const [changedMealType, setChangedMealType] = useState('');
  const [moveType, setMoveType] = useState('');
  const [moveRecipePopupClass, setMoveRecipePopupClass] = useState('hidden');
  const [visibleAll, setVisibleAll] = useState('block');

  let thisType = '';

  if (isManual) {
    thisType = 'manualItems';
  } else if (openPopup) {
    thisType = 'ingredients';
  } else {
    thisType = 'recipes';
  }

  function jumpToRecipe() {
    setTempRecipeObject(ingridientItem);
    const fromPath = isDashBoard ? 'dashboard' : 'plans';

    history.push({
      pathname: `/nutrition/recipes/${ingridientItem.uid}`,
      state: { from: fromPath, uid: planUid, type: mealType, id: dayId, label: mealLabel },
    });
  }

  function deleteItem() {
    const newColumns = {
      ...planStateValue,
      dayEntries: planStateValue?.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== dayId) return entries;
        return {
          ...entries,
          kcal_total:
            entries.kcal_total - ingridientItem.kcal_total < 0 ? 0 : entries.kcal_total - ingridientItem.kcal_total,
          carbohydrates_total:
            entries.carbohydrates_total - ingridientItem.carbohydrates_total < 0
              ? 0
              : entries.carbohydrates_total - ingridientItem.carbohydrates_total,
          protein_total:
            entries.protein_total - ingridientItem.protein_total < 0
              ? 0
              : entries.protein_total - ingridientItem.protein_total,
          fat_total:
            entries.fat_total - ingridientItem.fat_total < 0 ? 0 : entries.fat_total - ingridientItem.fat_total,
          [mealType as string]: {
            ...entries[mealType as string],
            kcal_total:
              entries[mealType as string].kcal_total - ingridientItem.kcal_total < 0
                ? 0
                : entries[mealType as string].kcal_total - ingridientItem.kcal_total,
            carbohydrates_total:
              entries[mealType as string].carbohydrates_total - ingridientItem.carbohydrates_total < 0
                ? 0
                : entries[mealType as string].carbohydrates_total - ingridientItem.carbohydrates_total,
            protein_total:
              entries[mealType as string].protein_total - ingridientItem.protein_total < 0
                ? 0
                : entries[mealType as string].protein_total - ingridientItem.protein_total,
            fat_total:
              entries[mealType as string].fat_total - ingridientItem.fat_total < 0
                ? 0
                : entries[mealType as string].fat_total - ingridientItem.fat_total,
            [thisType]: [
              ...entries[mealType as string][thisType].filter(
                (item: any) => parseFloat(item.id) !== parseFloat(ingridientItem.id)
              ),
            ],
          },
        };
      }),
    };

    savePlanChangesToFirebase(userData, newColumns, newColumns.uid, authContext.user?.uid, false, planState, tenant);
    // planState(newColumns);
  }

  const moveManualItem = () => {
    // Delete from previous meal
    let newColumns = {
      ...planStateValue,
      dayEntries: planStateValue.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== dayId) return entries;
        return {
          ...entries,
          [mealType as string]: {
            ...entries[mealType as string],
            kcal_total:
              parseFloat(entries[mealType as string].kcal_total) - Math.round(ingridientItem.kcal_total) < 0
                ? 0
                : parseFloat(entries[mealType as string].kcal_total) - Math.round(ingridientItem.kcal_total),
            carbohydrates_total:
              parseFloat(entries[mealType as string].carbohydrates_total) -
                Math.round(ingridientItem.carbohydrates_total) <
              0
                ? 0
                : parseFloat(entries[mealType as string].carbohydrates_total) -
                  Math.round(ingridientItem.carbohydrates_total),
            protein_total:
              parseFloat(entries[mealType as string].protein_total) - Math.round(ingridientItem.protein_total) < 0
                ? 0
                : parseFloat(entries[mealType as string].protein_total) - Math.round(ingridientItem.protein_total),
            fat_total:
              parseFloat(entries[mealType as string].fat_total) - Math.round(ingridientItem.fat_total) < 0
                ? 0
                : parseFloat(entries[mealType as string].fat_total) - Math.round(ingridientItem.fat_total),
            manualItems: [
              ...entries[mealType as string].manualItems.filter(
                (item: any) => parseFloat(item.id) !== parseFloat(ingridientItem.id)
              ),
            ],
          },
        };
      }),
    };

    // Add to new meal
    newColumns = {
      ...newColumns,
      dayEntries: newColumns.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== dayId) return entries;
        return {
          ...entries,
          [changedMealType]: {
            ...entries[changedMealType],
            kcal_total: parseFloat(entries[changedMealType].kcal_total) + Math.round(ingridientItem.kcal_total),
            carbohydrates_total:
              parseFloat(entries[changedMealType].carbohydrates_total) + Math.round(ingridientItem.carbohydrates_total),
            protein_total:
              parseFloat(entries[changedMealType].protein_total) + Math.round(ingridientItem.protein_total),
            fat_total: parseFloat(entries[changedMealType].fat_total) + Math.round(ingridientItem.fat_total),
            manualItems:
              entries[changedMealType].manualItems !== undefined
                ? [...entries[changedMealType].manualItems, ingridientItem]
                : [ingridientItem],
          },
        };
      }),
    };

    savePlanChangesToFirebase(userData, newColumns, newColumns.uid, authContext.user?.uid, false, planState, tenant);
    // planState(newColumns);
    setMoveRecipePopupClass('hidden');
  };

  const moveRecipe = () => {
    // Delete from previous meal
    let newColumns = {
      ...planStateValue,
      dayEntries: planStateValue.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== dayId) return entries;
        return {
          ...entries,
          [mealType as string]: {
            ...entries[mealType as string],
            kcal_total:
              parseFloat(entries[mealType as string].kcal_total) - Math.round(ingridientItem.kcal_total) < 0
                ? 0
                : parseFloat(entries[mealType as string].kcal_total) - Math.round(ingridientItem.kcal_total),
            carbohydrates_total:
              parseFloat(entries[mealType as string].carbohydrates_total) -
                Math.round(ingridientItem.carbohydrates_total) <
              0
                ? 0
                : parseFloat(entries[mealType as string].carbohydrates_total) -
                  Math.round(ingridientItem.carbohydrates_total),
            protein_total:
              parseFloat(entries[mealType as string].protein_total) - Math.round(ingridientItem.protein_total) < 0
                ? 0
                : parseFloat(entries[mealType as string].protein_total) - Math.round(ingridientItem.protein_total),
            fat_total:
              parseFloat(entries[mealType as string].fat_total) - Math.round(ingridientItem.fat_total) < 0
                ? 0
                : parseFloat(entries[mealType as string].fat_total) - Math.round(ingridientItem.fat_total),
            recipes: [
              ...entries[mealType as string].recipes.filter(
                (item: any) => parseFloat(item.id) !== parseFloat(ingridientItem.id)
              ),
            ],
          },
        };
      }),
    };

    // Add to new meal
    newColumns = {
      ...newColumns,
      dayEntries: newColumns.dayEntries.map((entries: any) => {
        if (parseFloat(entries.id) !== dayId) return entries;
        return {
          ...entries,
          [changedMealType]: {
            ...entries[changedMealType],
            kcal_total: parseFloat(entries[changedMealType].kcal_total) + Math.round(ingridientItem.kcal_total),
            carbohydrates_total:
              parseFloat(entries[changedMealType].carbohydrates_total) + Math.round(ingridientItem.carbohydrates_total),
            protein_total:
              parseFloat(entries[changedMealType].protein_total) + Math.round(ingridientItem.protein_total),
            fat_total: parseFloat(entries[changedMealType].fat_total) + Math.round(ingridientItem.fat_total),
            recipes: [...entries[changedMealType].recipes, ingridientItem],
          },
        };
      }),
    };

    savePlanChangesToFirebase(userData, newColumns, newColumns.uid, authContext.user?.uid, false, planState, tenant);
    // planState(newColumns);
    setMoveRecipePopupClass('hidden');
  };

  const getRecipePortionSize = () => {
    /* if (ingridientItem.recipe_portion !== undefined) {
      return parseFloat(ingridientItem.recipe_portion) * parseFloat(ingridientItem.portion_g);
    } */
    return ingridientItem.portion_g;
  };

  const openMovePopup = (thisMoveType: string) => {
    setChangedMealType('');
    setMoveType(thisMoveType);
    setMoveRecipePopupClass('block');
  };

  const getItemLabel = () => {
    if (ingridientItem.recipe_piece !== undefined) {
      if (ingridientItem.recipe_piece === 'g') {
        return `${ingridientItem.amount} g`;
      }
    }

    return `${ingridientItem.amount} ${ingridientItem.piece} (${getRecipePortionSize()}g)`;
  };

  return (
    <>
      <div>
        {moveRecipePopupClass === 'block' ? (
          <div>
            <div className="flex justify-between pt-20 pl-20">
              <div className="font-bold">
                {moveType === 'recipe' ? ingridientItem.name : ingridientItem.description}
              </div>
              <div className="my-auto pr-20">
                <XIcon
                  width={25}
                  height={25}
                  className="text-accentColor mx-auto cursor-pointer"
                  onClick={() => setMoveRecipePopupClass('hidden')}
                />
              </div>
            </div>
            <div className={styles.editPictureIngridientPopupContent}>
              <div className="pt-20 font-extralight text-base pl-20 pr-15 pb-20">
                {moveType === 'recipe' ? (
                  <div>Wohin soll das Rezept verschoben werden?</div>
                ) : (
                  <div>Wohin soll der manuelle Eintrag verschoben werden?</div>
                )}
                <div>
                  <div className="flex gap-20 pt-20">
                    <div className="font-light my-auto w-130">Mahlzeit auswählen:</div>
                    <div className="flex-1">
                      <div>
                        <Select
                          options={mealTypeValues}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          unstyled
                          value={mealTypeValues.filter((item: any) => item.value === changedMealType)}
                          onChange={e => setChangedMealType(e?.value)}
                          isSearchable={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pl-20 pr-10 pb-40">
                <Button
                  disabled={changedMealType.length === 0}
                  className="w-full"
                  onClick={() => (moveType === 'recipe' ? moveRecipe() : moveManualItem())}
                >
                  Verschieben
                </Button>
              </div>
            </div>
          </div>
        ) : isManual ? (
          <div className="flex">
            <div className={styles.contentItem}>
              <div className={styles.description}>
                <div className="py-15 pl-5 pr-5">
                  <div
                    onClick={() => openPopup !== undefined && openPopup(ingredientType, ingridientItem)}
                    onKeyDown={() => openPopup !== undefined && openPopup(ingredientType, ingridientItem)}
                    aria-hidden="true"
                  >
                    <div className="flex">
                      <div className="rounded-3xl bg-calories py-1 mb-1 px-10 font-bold text-10 text-textColor">
                        {type}
                      </div>
                    </div>
                    <div className={styles.popupNutritionItem}>{ingridientItem.description}</div>
                    <div className="pt-10">
                      <div className={styles.category}>
                        <div className={styles.item}>
                          <div className="text-12">{ingridientItem.kcal_total}</div>
                          <div>KCAL</div>
                        </div>
                        <div className={styles.item}>
                          <div className="text-12">{ingridientItem.carbohydrates_total}</div>
                          <div>KH</div>
                        </div>
                        <div className={styles.item}>
                          <div className="text-12">{ingridientItem.protein_total}</div>
                          <div>EW</div>
                        </div>
                        <div className={styles.item}>
                          <div className="text-12">{ingridientItem.fat_total}</div>
                          <div>Fett</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isDelete && (
                    <>
                      <div className="pt-10 w-full z-150">
                        <Button className="py-1 text-12" onClick={() => openMovePopup('manual')}>
                          Eintrag verschieben
                        </Button>
                      </div>
                      <div
                        className="my-auto pr-20 pt-10 block md:hidden"
                        onClick={() => deleteItem()}
                        onKeyDown={() => deleteItem()}
                        aria-hidden="true"
                      >
                        <Button className="text-12 py-1">Löschen</Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className={styles.imageWrapper}>
                <img
                  src={
                    theme?.logo !== undefined && userData?.role !== 2
                      ? theme.logo
                      : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
                  }
                  alt=""
                  width={45}
                  height={45}
                  loading="lazy"
                  className={styles.image}
                />
              </div>
              {isDelete && (
                <div
                  className="my-auto pr-20 hidden md:block"
                  onClick={() => deleteItem()}
                  onKeyDown={() => deleteItem()}
                  aria-hidden="true"
                >
                  <TrashIcon width={30} height={30} className="text-accentColor mx-auto" />
                </div>
              )}
            </div>
          </div>
        ) : openPopup ? (
          <>
            <div className="flex">
              <div className={styles.contentItem}>
                <div className={styles.description}>
                  <div className="py-15 pl-5 pr-5">
                    <div
                      onClick={() =>
                        isDashBoard
                          ? openPopup(ingredientType, ingridientItem)
                          : openPopup(ingredientType, ingridientItem.name)
                      }
                      onKeyDown={() =>
                        isDashBoard
                          ? openPopup(ingredientType, ingridientItem)
                          : openPopup(ingredientType, ingridientItem.name)
                      }
                      aria-hidden="true"
                    >
                      <div className="flex">
                        {type === 'Rezept' ? (
                          <div className="rounded-3xl bg-lightRed py-1 mb-1 px-10 font-bold text-10 ">{type}</div>
                        ) : (
                          <div className="rounded-3xl bg-lightGreen py-1 mb-1 px-10 font-bold text-10 text-textColor">
                            {type}
                          </div>
                        )}
                      </div>
                      <div className={styles.popupNutritionItem}>{ingridientItem.name}</div>
                      <div className="font-extralight text-10 pb-5">
                        {ingridientItem.amount} {ingridientItem.piece}
                        {ingridientItem.piece !== 'g' &&
                        ingridientItem.piece !== 'ml' &&
                        ingridientItem.portion_g !== undefined ? (
                          `(${ingridientItem.portion_g}g)`
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className={styles.category}>
                        <div className={styles.item}>
                          <div className="text-12">{ingridientItem.kcal_total}</div>
                          <div>KCAL</div>
                        </div>
                        <div className={styles.item}>
                          <div className="text-12">{ingridientItem.carbohydrates_total}</div>
                          <div>KH</div>
                        </div>
                        <div className={styles.item}>
                          <div className="text-12">{ingridientItem.protein_total}</div>
                          <div>EW</div>
                        </div>
                        <div className={styles.item}>
                          <div className="text-12">{ingridientItem.fat_total}</div>
                          <div>Fett</div>
                        </div>
                      </div>
                    </div>
                    {isDelete && (
                      <div
                        className="my-auto pr-20 pt-10 block md:hidden"
                        onClick={() => deleteItem()}
                        onKeyDown={() => deleteItem()}
                        aria-hidden="true"
                      >
                        <Button className="text-12 py-1">Löschen</Button>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.imageWrapper}>
                  <img
                    src={
                      ingridientItem.imageUrl?.length > 0
                        ? ingridientItem.imageUrl
                        : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
                    }
                    alt=""
                    width={45}
                    height={45}
                    loading="lazy"
                    className={styles.image}
                  />
                </div>
                {isDelete && (
                  <div
                    className="my-auto pr-20 hidden md:block"
                    onClick={() => deleteItem()}
                    onKeyDown={() => deleteItem()}
                    aria-hidden="true"
                  >
                    <TrashIcon width={30} height={30} className="text-accentColor mx-auto" />
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="relative">
            <div className="flex">
              <div className={styles.contentItem}>
                <div className={styles.description}>
                  <div className="py-15 pl-5 pr-5">
                    <div
                      onClick={() => id !== 'shared' && jumpToRecipe()}
                      onKeyDown={() => id !== 'shared' && jumpToRecipe()}
                      aria-hidden="true"
                    >
                      <div className="flex">
                        <div className="rounded-3xl bg-accentColor text-buttonTextColor py-1 mb-1 px-10 font-bold text-10">
                          {type}
                        </div>
                      </div>
                      <div className={styles.popupNutritionItem}>{ingridientItem.name}</div>
                      <div className="font-extralight text-10 pb-5">
                        {getItemLabel()}
                        {/* {ingridientItem.amount} {ingridientItem.piece} ({getRecipePortionSize()}g) */}
                      </div>
                      <div className={styles.category}>
                        <div className={styles.item}>
                          <div className="text-12">{Math.round(parseFloat(ingridientItem.kcal_total))}</div>
                          <div>KCAL</div>
                        </div>
                        <div className={styles.item}>
                          <div className="text-12">{Math.round(parseFloat(ingridientItem.carbohydrates_total))}</div>
                          <div>KH</div>
                        </div>
                        <div className={styles.item}>
                          <div className="text-12">{Math.round(parseFloat(ingridientItem.protein_total))}</div>
                          <div>EW</div>
                        </div>
                        <div className={styles.item}>
                          <div className="text-12">{Math.round(parseFloat(ingridientItem.fat_total))}</div>
                          <div>Fett</div>
                        </div>
                      </div>
                    </div>
                    {isDelete && (
                      <>
                        <div className="pt-10 w-full z-150">
                          <Button className="py-1 text-12" onClick={() => openMovePopup('recipe')}>
                            Rezept verschieben
                          </Button>
                        </div>
                        <div
                          className="my-auto pr-20 pt-10 block md:hidden"
                          onClick={() => deleteItem()}
                          onKeyDown={() => deleteItem()}
                          aria-hidden="true"
                        >
                          <Button className="text-12 py-1">Löschen</Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.imageWrapper}>
                  <img
                    src={ingridientItem.imageUrl}
                    alt=""
                    width={45}
                    height={45}
                    loading="lazy"
                    className={styles.image}
                  />
                </div>
                {isDelete && (
                  <>
                    <div
                      className="my-auto pr-20 hidden md:block"
                      onClick={() => deleteItem()}
                      onKeyDown={() => deleteItem()}
                      aria-hidden="true"
                    >
                      <TrashIcon width={30} height={30} className="text-accentColor mx-auto" />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default IngridientPopupItem;
