import React, { useContext, useState } from 'react';
import { TrashIcon, PencilIcon } from '@heroicons/react/24/outline';
import { AuthContext } from 'providers/AuthProvider';
import firebase from 'services/firebase';
import { toast } from 'react-toast';
import NutritionItemOverlayDetails from 'components/NutritionItemOverlayDetails';
import Button from 'components/Button';

type Props = {
  foodObject: any;
  foodType: string;
  userId: any;
  planUid: string;
  mealIdentifier: string;
  dayId: any;
  setPlanObject: any;
};

const CoachingDashboardFoodItem: React.FC<Props> = ({
  foodObject,
  foodType,
  userId,
  planUid,
  mealIdentifier,
  dayId,
  setPlanObject,
}) => {
  const { theme, tenant } = useContext(AuthContext);

  const [deleteLoading, setDeleteLoading] = useState(false);

  const [showDetailsClass, setShowDetailsClass] = useState('hidden');

  const deleteItem = async () => {
    setDeleteLoading(true);

    try {
      const getPlan = await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(userId)
        .collection('plans')
        .doc(planUid)
        .get();

      const getPlanData = getPlan.data();

      if (getPlan === undefined || getPlanData === undefined) {
        toast.error('Es ist ein Fehler aufgetreten. Bitte versuche es später erneut!');
        setDeleteLoading(false);
        return;
      }

      const newColumns = {
        ...getPlanData,
        dayEntries: getPlanData?.dayEntries.map((entries: any) => {
          if (parseFloat(entries.id) !== dayId) return entries;
          return {
            ...entries,
            kcal_total: entries.kcal_total - foodObject.kcal_total < 0 ? 0 : entries.kcal_total - foodObject.kcal_total,
            carbohydrates_total:
              entries.carbohydrates_total - foodObject.carbohydrates_total < 0
                ? 0
                : entries.carbohydrates_total - foodObject.carbohydrates_total,
            protein_total:
              entries.protein_total - foodObject.protein_total < 0
                ? 0
                : entries.protein_total - foodObject.protein_total,
            fat_total: entries.fat_total - foodObject.fat_total < 0 ? 0 : entries.fat_total - foodObject.fat_total,
            [mealIdentifier]: {
              ...entries[mealIdentifier],
              kcal_total:
                entries[mealIdentifier].kcal_total - foodObject.kcal_total < 0
                  ? 0
                  : entries[mealIdentifier].kcal_total - foodObject.kcal_total,
              carbohydrates_total:
                entries[mealIdentifier].carbohydrates_total - foodObject.carbohydrates_total < 0
                  ? 0
                  : entries[mealIdentifier].carbohydrates_total - foodObject.carbohydrates_total,
              protein_total:
                entries[mealIdentifier].protein_total - foodObject.protein_total < 0
                  ? 0
                  : entries[mealIdentifier].protein_total - foodObject.protein_total,
              fat_total:
                entries[mealIdentifier].fat_total - foodObject.fat_total < 0
                  ? 0
                  : entries[mealIdentifier].fat_total - foodObject.fat_total,
              [foodType]: [
                ...entries[mealIdentifier][foodType].filter(
                  (item: any) => parseFloat(item.id) !== parseFloat(foodObject.id)
                ),
              ],
            },
          };
        }),
      };

      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(userId)
        .collection('plans')
        .doc(planUid)
        .update(newColumns);

      const newDayItem = newColumns?.dayEntries?.filter((item: any) => item.id === dayId)[0];

      setPlanObject(newDayItem);

      setDeleteLoading(false);
    } catch (e: any) {
      setDeleteLoading(false);
      toast.error('Es ist leider ein Fehler aufgetreten. Bitte versuchen Sie es später nochmal!');
    }
  };

  return (
    <div>
      <NutritionItemOverlayDetails
        overlayClass={showDetailsClass}
        setOverlayClass={setShowDetailsClass}
        title={
          foodType === 'manualItems' && foodObject.description && foodObject.description !== undefined
            ? foodObject.description
            : foodObject.name
        }
        foodObject={foodObject}
      >
        <div>Test</div>
      </NutritionItemOverlayDetails>

      <div className="pb-1">
        <div className="mx-10 p-10 mb-10 bg-lightGray border border-textColor rounded-xl">
          <div className="flex justify-between gap-10 pr-10 pt-10">
            <div className="w-1/5 flex-shrink-0">
              {foodObject.imageUrl !== undefined && foodObject.imageUrl.length > 0 ? (
                <img className="rounded-full w-50 h-50 object-cover" src={foodObject.imageUrl} alt="Food Item" />
              ) : (
                <img
                  className="rounded-full w-50 h-50 object-cover"
                  src={
                    foodObject.imageUrl !== undefined && foodObject.imageUrl?.length > 0
                      ? foodObject.imageUrl
                      : theme?.logo !== undefined
                      ? theme.logo
                      : 'https://firebasestorage.googleapis.com/v0/b/tortija-19187.appspot.com/o/default_images%2Flogo_default.png?alt=media&token=a75bcbeb-55a8-4792-af34-844f2f590097'
                  }
                  alt="Food Item"
                />
              )}
            </div>
            <div className="flex-1 flex flex-col justify-between">
              {foodType === 'manualItems' && foodObject.description && foodObject.description !== undefined ? (
                <div className="pb-10">
                  <div>{foodObject.description}</div>
                  <div className="text-10 opacity-80">Manuell</div>
                </div>
              ) : foodObject.name !== undefined ? (
                <div className="pb-10">
                  <div>{foodObject.name}</div>
                  <div className="text-10 opacity-80">
                    {foodObject.amount} {foodObject.piece}
                    {foodObject.piece !== 'g' && foodObject.piece !== 'ml' && foodObject.portion_g !== undefined ? (
                      ` (${foodObject.portion_g}g)`
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <div>Kein Name vergeben</div>
              )}

              <div className="pr-10 pb-5 mt-auto">
                <div className="flex justify-between opacity-50">
                  <div>
                    <div className="text-12 text-center">{foodObject?.kcal_total}</div>
                    <div className="text-8 font-extralight text-center">kcal</div>
                  </div>
                  <div>
                    <div className="text-12 text-center">{foodObject?.carbohydrates_total}</div>
                    <div className="text-8 font-extralight text-center">KH</div>
                  </div>
                  <div>
                    <div className="text-12 text-center">{foodObject?.protein_total}</div>
                    <div className="text-8 font-extralight text-center">EW</div>
                  </div>
                  <div>
                    <div className="text-12 text-center">{foodObject?.fat_total}</div>
                    <div className="text-8 font-extralight text-center">FT</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap gap-y-10 justify-between pt-5">
            <div>
              <Button
                isPending={deleteLoading}
                disabled={deleteLoading}
                className="text-12 py-5 border border-accentColor"
                buttonStyle="white"
                onClick={() => deleteItem()}
              >
                Löschen
              </Button>
            </div>
            {foodType === 'recipes' && (
              <div>
                <Button className="text-12 py-5" onClick={() => setShowDetailsClass('block')}>
                  Details
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachingDashboardFoodItem;
